angular.module('llax')
    .controller('PublicationTasksController', ['$scope', 'PublicationResource', 'growl', 'RepublishResource', '$timeout', '$dialogs',
        '$translate',
        function($scope, PublicationResource, growl, RepublishResource, $timeout, $dialogs, $translate) {

            $scope.existingPublicationsMap = {};

            $scope.loadExistingPublication = function(recipientId) {
                // FIXME: Enrich with publication type and destination information (channel or organization name and subDestination, if available)
                PublicationResource.query({recipient: recipientId}, function(response) {
                    $scope.existingPublicationsMap[recipientId] = response;
                });
            };

            $scope.toggleShowPublicationDetails = function(channel) {
                channel.showDetails.publications = !channel.showDetails.publications;
                if (channel.showDetails.publications) {
                    $scope.loadExistingPublication(channel.id);
                }
            };

            $scope.restartPublication = function(taskId, recipientId) {
                var message = $translate.instant('COMMUNICATION_CHANNELS.RELOAD_SUBSCRIPTION');
                var confirmDialog = $dialogs.confirm('', message);
                confirmDialog.result.then(function() {
                    RepublishResource.save({
                            taskId: taskId
                        },
                        function(response) {
                            growl.success("COMMUNICATION_CHANNELS.REPUBLICATION_STARTED_SUCCESSFULLY");
                            $timeout(function() {
                                $scope.loadExistingPublication(recipientId);
                            }, 1000);
                        },
                        function(response) {
                            growl.error("COMMUNICATION_CHANNELS.ERROR_STARTING_REPUBLICATION");
                        }
                    );
                });
            };
        }
    ]);
