angular.module('llax')
    .controller('ReleaseNotesController', function ($modal, $scope, ReleaseNotesResource) {

        var ReleaseNotesModalController = function($modalInstance, $scope, $rootScope) {
            ReleaseNotesResource.get(function(response) {
                $scope.releaseNotes = response;
            });
            $scope.close = function() {
                $modalInstance.close();
            };
        };

        var d = new Date();
        var year = d.getFullYear();
        $scope.footerCopyright = "\u00A9 2014-" + year;

        $scope.openReleaseNotes = function() {
            $modal.open({
                templateUrl: 'tpl/release-notes.tpl.html',
                controller: ReleaseNotesModalController,
                backdrop: true,
                windowClass: 'publish-modal'
            });
        };

    }
);
