angular.module('llax.realtimeProvider', [])
.service('RealtimeProvider', function($cookies, $log, $rootScope, AppConstants) {

    var EVENTS_PREFIX = "organizationEvents";
    var PATH_SEPARATOR = "/";

    // Deepstream constants
    // FIXME: These are needed because we don't know how to 'load' the constants defined in ds.js!?
    var deepstream = {
        CONSTANTS : {
            EVENT : {
                MAX_RECONNECTION_ATTEMPTS_REACHED: "MAX_RECONNECTION_ATTEMPTS_REACHED",
                CONNECTION_STATE_CHANGED: "connectionStateChanged"
            },
            CONNECTION_STATE: {
                OPEN: "OPEN"
            }
        }
    };

    var ds;
    var organizationId;

    var self = this;

    function login(userId, token, settings, callback) {

        ds.login({
            type: 'webhook',
            userId: userId,
            token: token
        }, function(success, data) {
            if (success) {
                $log.info("Successfully logged in to deepstream with clientData", data);
                organizationId = data.organizationId;
                callback();
            } else {
                $log.error("Could not connect to deepstream using settings:", settings);
            }
        });

    }

    this.connect = function(settings, callback) {

        $log.debug("settings:", JSON.stringify(settings));
        var url = settings.url;

        var userId = $rootScope.user.userId;
        var token = $cookies.get(AppConstants.AUTHENTICATION_TOKEN);
        if (_.isEmpty(token)) {
            $log.error("Cannot connect to deepstream, because login token is empty");
            return;
        }

        if (ds) {
            ds.off();
            ds.close();
        }

        ds = new DeepstreamClient.DeepstreamClient(url);
        ds.on('error', function(error, event, topic) {
           $log.error("Caught deepstream error:", error, event, topic);
        });
        ds.on(deepstream.CONSTANTS.EVENT.CONNECTION_STATE_CHANGED, function(connectionState) {
            $log.debug("connectionStateChanged:", connectionState);
        });
        ds.on(deepstream.CONSTANTS.EVENT.MAX_RECONNECTION_ATTEMPTS_REACHED, function(reconnectionAttempts) {
            $log.debug("Reconnection failed after maximum number of retry attempts reached");
            self.connect(settings, callback);
        });

        login(userId, token, settings, callback);

    };

    this.disconnect = function() {
        if (ds) {
            ds.off();
            ds.close();
            ds = null;
        }
    };

    this.isConnected = function() {
        var isConnected = ds ? ds.getConnectionState() === deepstream.CONSTANTS.CONNECTION_STATE.OPEN : false;
        return isConnected;
    };

    this.emit = function(event, identifier, type, data) {
        var eventData = {
            event: event,
            identifier: identifier,
            type: type,
            data: data
        };
        var eventName = _getEventName(event, identifier, type);
        $log.debug("emit: eventName:", eventName, ", eventData:", eventData);
        ds.event.emit(eventName, eventData);
    };

    this.subscribe = function(eventName, subscribeCallback) {
        ds.event.subscribe(eventName, subscribeCallback);
    };

    this.unsubscribe = function(eventName) {
        ds.event.unsubscribe(eventName);
    };

    function _getEventName(event, identifier, type) {
        var eventName = EVENTS_PREFIX +
            PATH_SEPARATOR +
            organizationId +
            PATH_SEPARATOR +
            type +
            PATH_SEPARATOR +
            event +
            (identifier ? PATH_SEPARATOR + identifier : "");
        return eventName;
    }

});
