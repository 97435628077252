angular.module('llax')
    .controller('ContactsController',
        function($controller, $location, $modal, $rootScope, $route, $routeParams, $scope, $timeout, $translate, $window, growl,
            Auth, CommunityResource, ContactsResourceByCursor, MultiMap, OrganizationsResource, OrganizationService, PublicationResource, TabContainer, HttpHeader) {

            var lastRoute = $route.current;

            $scope.$on('$locationChangeSuccess', function(event) {
                if ($route.current.$$route && $route.current.$$route.templateUrl.indexOf('/contacts') > 0) {
                    $route.current = lastRoute;
                }
            });

            $scope.chooseCommunity = function(community) {
                $scope.communityId = community.id;
                $scope.searchOrganizations($scope.searchQuery);
            };

            $scope.searchOrganizations = function(keyword) {
                OrganizationsResource.query({
                        keyword: keyword,
                        communityId: $scope.communityId
                    },
                    function(organizationsResponse) {
                        $scope.organizations = organizationsResponse;
                    },
                    function(response) {
                        $scope.status = response.status;
                    }
                );
            };

            $scope.communities = [];

            function loadCommunities(callback) {
                CommunityResource.query(function(response) {
                    $scope.communities = response.filter(function(community) {
                        return community.name !== "__DEFAULT_COMMUNITY__";
                    });
                    if ($scope.communities.length > 0) {
                        $scope.currentCommunity = $scope.communities[0];
                    }
                    if (callback) {
                        callback($scope.communities);
                    }
                });
            }

            function selectCommunity() {
                if ($scope.communities.length >= 1) {
                    $scope.communityId = $scope.communities[0].id;
                }
            }

            function onTabActivate(tab) {
                $scope.currentSubUrl = tab.suburl;

                if (tab.suburl === 'partners') {
                    $rootScope.reloadContacts();
                } else if (tab.suburl === 'community') {
                    $scope.organizations = null;
                    loadCommunities(function() {
                        selectCommunity();
                        $scope.searchOrganizations($scope.searchQuery);
                    });
                } else if (tab.suburl === 'search') {
                    $scope.communityId = null;
                    $scope.searchOrganizations($scope.searchQuery);
                }
            }

            function initTabs(hasCommunityPanel) {
                var tabContainer = new TabContainer($scope, $location, [{
                    title: $translate.instant('BUSINESS_PARTNER'),
                    suburl: 'partners',
                    template: 'tpl/my-contacts.tpl.html'
                }, {
                    title: $translate.instant('COMMUNITIES'),
                    suburl: 'community',
                    template: 'tpl/search-organisations.tpl.html',
                    isVisible: function() {
                        return hasCommunityPanel;
                    }
                }, {
                    title: $translate.instant('INVITATIONS'),
                    suburl: 'invitations',
                    template: $rootScope.getRoleSpecificTemplate('tpl/invitations.tpl.html')
                }, {
                    title: $translate.instant('SEARCH_BUSINESSES'),
                    suburl: 'search',
                    template: 'tpl/search-organisations.tpl.html'
                }],
                'partners', onTabActivate);
                tabContainer.openSubview($location.hash());
            }

            var ExecuteInvitation = function($rootScope, $scope, $modalInstance, ContactsResource, organization, growl) {

                $scope.organization = organization;
                $scope.focusOn = true;

                // Invitation type is fixed to CONTACT_REQUEST as of now!
                // Requesting membership in a community - either way - is not yet fully defined!
                var invitationType = 'CONTACT_REQUEST';

                $scope.invitation = {
                    organizationId: organization.id,
                    invitationType: invitationType
                };

                $scope.cancel = function() {
                    $modalInstance.dismiss('cancel');
                };

                $scope.invite = function(organization) {
                    ContactsResource.invite($scope.invitation,
                        function(invitationResponse) {
                            growl.success("INVITATION_SUCCESSFULLY_SENT");
                            $scope.$broadcast('contactDataChanged');
                        });
                    $modalInstance.close();
                };

            };

            $scope.inviteByEmail = function() {
                $modal.open({
                    templateUrl: 'tpl/send-email-invitation.tpl.html',
                    controller: 'ExecuteEmailInvitationController',
                    backdrop: true,
                    resolve: {
                        data: null
                    }
                });
            };

            $scope.invite = function(organization) {
                $modal.open({
                    templateUrl: 'tpl/send-invitation.tpl.html',
                    controller: ExecuteInvitation,
                    backdrop: true,
                    windowClass: 'publish-modal',
                    scope: $scope,
                    resolve: {
                        organization: function() {
                            return organization;
                        }
                    }
                });

            };

            // my contacts
            $controller('PublicationTasksController', {
                $scope: $scope,
                PublicationResource: PublicationResource
            });

            function groupByLetter(contacts) {
                var result = [];

                //create Map with First letter pointing to List of contacts:
                var multiMap = new MultiMap();
                for (var i = 0; i < contacts.length; i++) {
                    var contact = contacts[i];
                    if (!contact.deleted && !_.isNil(contact.name)) {
                        var firstLetter = contact.name.toUpperCase().substring(0, 1);
                        multiMap.add(firstLetter, contact);
                    }
                }

                var keySet = multiMap.keySet();
                for (var j = 0; j < keySet.length; j++) {
                    var entry = multiMap.get(keySet[j]);
                    result.push({
                        letter: keySet[j],
                        contacts: entry
                    });
                }

                return result;
            }

            // Function to load more contacts when scrolling to the bottom
            function loadMoreContacts() {
                if ($window.innerHeight + $window.scrollY >= document.body.offsetHeight) {
                    // User has scrolled to the bottom, load more contacts
                    $rootScope.loadContacts();
                }
            }

            // Attach scroll event listener to window
            angular.element($window).on('scroll', loadMoreContacts);

            // Clean up event listener when scope is destroyed
            $scope.$on('$destroy', function() {
                angular.element($window).off('scroll', loadMoreContacts);
            });

            $scope.contacts = null;
            $scope.groupedContacts = null;
            $rootScope.loadingContacts = false; // Flag to indicate if contacts are being loaded
            $rootScope.hasNextPage = true; // Flag to indicate if there's a next page

            var regex = /"[^"]*"|[^:#@.,*#!$%&\/(){}\[\]\s]+/g;

            var splitKeywords = function(searchKeyword) {
                if (_.isNil(searchKeyword)) {
                    return searchKeyword;
                }
                var matches = searchKeyword.match(regex);
                if (matches) {
                    return matches.map(function(item) {
                        // Remove surrounding double quotes if present
                        item = item.replace(/"/g, '');
                        // Check if the item contains an apostrophe
                        if (item.includes("'")) {
                            return item;
                        }
                        return item;
                    });
                } else {
                    return searchKeyword;
                }
            };

            $rootScope.loadContacts = function(searchKeyword, reloadAllContacts) {
                var pageSize = Math.floor($window.innerHeight / 40);
                if (!$rootScope.loadingContacts && $rootScope.hasNextPage) {
                    $rootScope.loadingContacts = true;
                    return ContactsResourceByCursor.createCursorAwareResource($scope.cursor).query({
                        limit: pageSize,
                        keywords: splitKeywords(searchKeyword),
                        sortby: 'name'
                    }, function(response, headers) {
                        $scope.cursor = headers(HttpHeader.CURSOR);
                        $rootScope.hasNextPage = !!$scope.cursor;
                        var contacts = _.reject(response, function(contact) {
                            return contact.state !== 'ESTABLISHED';
                        });

                        if ($scope.contacts && !reloadAllContacts) {
                            $scope.contacts = $scope.contacts.concat(contacts);
                        } else {
                            $scope.contacts = contacts;
                        }
                        $scope.groupedContacts = groupByLetter($scope.contacts);
                        $rootScope.contactsLoaded = true;
                        $rootScope.contactsMap = _.keyBy(contacts, 'id');
                        $rootScope.loadingContacts = false;
                    }, function(error) {
                        $rootScope.loadingContacts = false;
                        console.error("Error while loading contacts: ", error.message);
                    });
                }
            };

            $rootScope.reloadContacts = function(searchKeyword) {
                if(_.isEmpty(searchKeyword)) {
                    searchKeyword = null ;
                }
                $rootScope.loadingContacts = false;
                $rootScope.hasNextPage = true;
                $scope.contacts = null;
                $scope.groupedContacts = null;
                $scope.cursor = undefined;
                $rootScope.loadContacts(searchKeyword, true);
            };

            $scope.debouncedSearch = _.debounce(function(keywords) {
                $rootScope.reloadContacts(keywords);
                $scope.$apply();
            }, 300);

            $rootScope.searchContacts = function(searchKeyword) {
                $scope.debouncedSearch(searchKeyword);
            };

            $scope.hasCreatePermisson = Auth.hasAnyPermission(Auth.OBJECT_TYPE_CONTACT, 'create');

            $scope.hasEditPermisson = function(_contact) {
                var context = { contactRole : _contact.contactRole, customValues : _contact.customValues };
                return Auth.hasContactPermission('update', context);
            };

            $scope.hasDeletePermisson = function(_contact) {
                var context = { contactRole : _contact.contactRole, customValues : _contact.customValues };
                return Auth.hasContactPermission('delete', context);
            };

            $scope.hasLoginPermission = function(_contact) {
                var context = { contactRole : _contact.contactRole, customValues : _contact.customValues };
                return Auth.hasContactPermission('login', context);
            };

            $scope.createContact = function() {
                var addContactModal = $modal.open({
                    templateUrl: 'tpl/contact-editor.tpl.html',
                    controller: 'EditContactController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                contact: {}
                            };
                        }
                    }
                });
            };

            $scope.deleteContact = function(organization) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-contact-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                organization: organization
                            };
                        }
                    }
                });
            };

            $scope.$on('deleteContact', function(scope, organizationId) {
                ContactsResource.delete({
                        organizationId: organizationId
                    },
                    function(deletionResponse) {
                        growl.success("CONTACT_SUCCESSFULLY_DELETED");
                        $rootScope.reloadContacts();
                    }, function(error) {
                        if (error.data && error.data.message) {
                            growl.error(error.data.message, { variables: error.data.parameters });
                        }
                    });
            });

            $scope.$on('updateContact', function() {
                $rootScope.reloadContacts();
            });

            $scope.canLoginAsManagingUser = function(contact) {

                // Current organization must be a "managing" organization
                if (!$rootScope.organization.communityManager) {
                    return false;
                }

                // Current user must not be a "legacy" user
                if ($rootScope.user.legacyAccount) {
                    return false;
                }

                // Contact must be an invited buyer or supplier on this platform
                if (_.isNil(contact.organizationId) || (contact.invitationType !== 'INVITED_BUYER' && contact.invitationType !== 'INVITED_SUPPLIER')) {
                    return false;
                }

                // FIXME: Check if appropriate billing feature exists!

                return true;
            };

            $scope.loginAsManagingUser = function(contact) {

                var organizationId = contact.organizationId;
                var url = new URL(lax_rest_url_complete('contacts/managingUserLogin') + "/" + organizationId);

                growl.info('CONTACTS.LOGIN_AS_MANAGING_USER', {
                    variables: { organizationName: contact.name },
                    ttl: -1
                });

                $timeout(function() {
                    $window.open(url.toString(), '_self');
                }, 2000);

            };

            $scope.existingPublicationsMap = {};

            $scope.loadExistingPublication = function(recipientId) {
                $scope.existingPublicationsMap[recipientId] = PublicationResource.query({
                    recipient: recipientId
                });
            };

            $scope.toggleShowDetails = function(recipient) {
                if (_.isNil(recipient.organizationId)) {
                    return;
                }
                recipient.showDetails = !recipient.showDetails;
                if (recipient.showDetails) {
                    $scope.loadExistingPublication(recipient.organizationId);
                }
            };

            $scope.editContact = function(contact) {
                var editContactModal = $modal.open({
                    templateUrl: 'tpl/contact-editor.tpl.html',
                    controller: 'EditContactController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                contact: contact
                            };
                        }
                    }
                });
            };

            $scope.organizationImageUrl = function(id) {
                return lax_rest_url("organizations/" + id + "/image");
            };

            if ($routeParams.q) {
                $scope.searchQuery = $routeParams.q;
            }

            loadCommunities(function() {
                OrganizationService.$organization.subscribe(function(organization) {
                    $scope.organizationData = organization;
                    if (!_.isNil($scope.organizationData)) {
                        var hasCommunityPanel = !_.isEmpty($scope.communities) && $scope.organizationData.organizationRole === 'COMMUNITY_MANAGER';
                        initTabs(hasCommunityPanel);
                    }
                });
            });

        }
    );
