angular.module('llax.directives')
    .directive('assetFoldersList', function($log, $rootScope, $timeout, $translate, AssetFoldersService, uiGridConstants) {
        return {
            strict: 'E',
            priority: Number.MAX_SAFE_INTEGER,
            scope: {
                parentElementClass: '@',
                currentPath: '@',
                selectedItem: '=',
                items: '=',
                onItemDoubleClick: '=',
                onItemSelect: '=',
                onMultiItemSelected: '=',
                onSelectAll: '=',
                onClearSelection: '=',
                isImageFile: '=',
                getFileIcon: '=',
                getImageUrl: '='
            },
            template:
                '<div ' +
                    'id="asset-folders-list" ' +
                    'class="smooth gridStyle" ' +
                    'data-ui-grid="gridOptions" ' +
                    'data-ng-style="gridHeight()"' +
                    'data-ui-grid-edit ' +
                    'ui-grid-move-columns ' +
                    'data-ui-grid-resize-columns ' +
                    'data-ui-grid-cellNav ' +
                    'data-ui-grid-browser-tooltips ' +
                    'data-ui-grid-auto-resize ' +
                    'data-ui-grid-save-state ' +
                    'ui-grid-selection> ' +
                '</div>',
            controller: function($scope) {
                var ASSET_FOLDERS_GRID_STATES_KEY = "asset-folders-grid-states";
                var DEFAULT_GRID_STATE_KEY = "default-state";

                $scope.LINK_TYPE_FOLDER = AssetFoldersService.LINK_TYPE_FOLDER;
                $scope.LINK_TYPE_ASSET = AssetFoldersService.LINK_TYPE_ASSET;
                $scope.decodeFilename = AssetFoldersService.decodeFilename;

                $scope.gridApi = null;

                $scope.gridHeight = function () {
                    var parentElements = document.getElementsByClassName('items-container');
                    var height = parentElements[0].offsetHeight;

                    return {
                        'height': height + "px",
                        'min-height': '0px'
                    };
                };

                $scope.getCellClass = function(grid, row) {
                    if (!_.isNil($scope.selectedItem) && row.entity.name === $scope.selectedItem.name) {
                        return 'item-focused';
                    }
                };

                $scope.$watch('selectedItem', function() {
                    if (!_.isNil($scope.gridApi)) {
                        $timeout(function() {
                            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                        }, 0);
                    }
                });

                $scope.getContextMenuTarget = function(item) {
                    if (item.linkType === $scope.LINK_TYPE_ASSET) {
                        return 'assets-context-menu';
                    } else if (item.linkType === $scope.LINK_TYPE_FOLDER) {
                        return 'folders-context-menu';
                    } else {
                        $log.warn('getContextMenuTarget(): Link type not recognized', item);
                        return '';
                    }
                };

                $scope.columnDefs = [{
                    displayName: $translate.instant('NAME'),
                    name: 'name',
                    width: '*',
                    minWidth: 120,
                    enableCellEdit: false,
                    allowCellFocus: false,
                    cellClass: $scope.getCellClass,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents flex h-full cursor-pointer">' +
                            '<div ' +
                                'class="flex items-center overflow-hidden" ' +
                                'title="{{::row.entity.name}}" ' +
                                'data-ng-if="row.entity.linkType === grid.appScope.LINK_TYPE_FOLDER" ' +
                                'data-ng-dblclick="grid.appScope.onItemDoubleClick(row.entity)" ' +
                                'data-ng-click="grid.appScope.onItemSelect(row.entity)">' +
                                    '<div style="font-size: 20px; margin: 0 4px;">' +
                                        '<i style="color: initial;" class="syncons syncons-folder"></i>' +
                                    '</div>' +
                                    '<div class="overflow-hidden white-space-no-wrap text-ellipsis">' +
                                        '{{row.entity.name}}' +
                                    '</div>'+
                            '</div>' +
                            '<div ' +
                                'class="flex items-center overflow-hidden" ' +
                                'title="{{::row.entity.name}}" ' +
                                'data-ng-if="row.entity.linkType === grid.appScope.LINK_TYPE_ASSET" ' +
                                'data-ng-dblclick="grid.appScope.onItemDoubleClick(row.entity)" ' +
                                'data-ng-click="grid.appScope.onItemSelect(row.entity)">' +
                                '<div style="font-size: 20px; margin: 0 4px;">' +
                                    '<i data-ng-if="!grid.appScope.isImageFile(row.entity)" class="syncons {{grid.appScope.getFileIcon(row.entity.name)}}"></i>' +
                                    '<img ' +
                                        'style="width: 20px;" ' +
                                        'data-ng-if="grid.appScope.isImageFile(row.entity)" ' +
                                        'alt="{{row.entity.name}}" ' +
                                        'onload="this.style.opacity = 1;"' +
                                        'data-ng-src="{{grid.appScope.getImageUrl(row.entity)}}"/>' +
                                '</div>' +
                                '<div class="overflow-hidden white-space-no-wrap text-ellipsis">' +
                                    '{{row.entity.name}}' +
                                '</div>' +
                            '</div>' +
                        '</div>'
                }, {
                    displayName: $translate.instant('ASSET_FOLDER.CREATED'),
                    name: 'createdAt',
                    width: '*',
                    minWidth: 120,
                    enableCellEdit: false,
                    allowCellFocus: false,
                    cellClass: $scope.getCellClass,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents">{{row.entity.createdAt | date: \'medium\'}}</div>'
                }, {
                    displayName: $translate.instant('ASSET_FOLDER.CREATED_BY'),
                    name: 'createdBy',
                    width: '*',
                    minWidth: 120,
                    enableCellEdit: false,
                    allowCellFocus: false,
                    cellClass: $scope.getCellClass
                }];

                $scope.$on('notifyMultiSelectionChanged', function() {
                    _.forEach($scope.items, function(item, index){
                        if (item.multiSelected) {
                            $scope.gridApi.selection.selectRow(item);
                        } else {
                            $scope.gridApi.selection.unSelectRow(item);
                        }
                    });
                });

                $scope.gridOptions = {
                    data: 'items',
                    columnDefs: $scope.columnDefs,
                    enableRowSelection: true,
                    enableSelectAll: true,
                    enableColumnMenus: false,
                    enableGridMenu: true,
                    enableHorizontalScrollbar: 0,
                    enableVerticalScrollbar: 1,
                    rowHeight: 32,
                    rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" ' +
                                      'ui-grid-one-bind-id-grid="rowRenderIndex + \'-\' + col.uid + \'-cell\'" ' +
                                      'class="ui-grid-cell" ' +
                                      'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                                      'role="{{col.isRowHeader ? \'rowheader\' : \'gridcell\'}}" ' +
                                      'ui-grid-cell '+
                                      'data-context-menu ' +
                                      'data-context-menu-target="{{grid.appScope.getContextMenuTarget(row.entity)}}" ' +
                                      'data-on-context-menu-open="grid.appScope.onItemSelect(row.entity)">' +
                                '</div>',
                    onRegisterApi: function (gridApi) {
                        $scope.gridApi = gridApi;
                        $rootScope.initGridState($scope, $scope.gridApi, ASSET_FOLDERS_GRID_STATES_KEY, DEFAULT_GRID_STATE_KEY, $scope.columnDefs);

                        gridApi.selection.on.rowSelectionChanged($scope,function(row){

                            if (row.isSelected) {
                                row.entity.multiSelected = true;
                            } else {
                                row.entity.multiSelected = false;
                            }
                            $scope.onMultiItemSelected(row.entity);
                        });

                        gridApi.selection.on.rowSelectionChangedBatch($scope, function() {

                            var selectedRows = $scope.gridApi.selection.getSelectedRows();
                            if (selectedRows.length > 0) {
                                $scope.onSelectAll();
                            } else {
                                $scope.onClearSelection();
                            }
                        });

                        $timeout(function(){

                            _.forEach($scope.items, function(item, index){
                                if (item.multiSelected) {
                                    gridApi.selection.selectRow(item);
                                }
                            });
                        }, 0);
                    }
                };
            }
        };
    });