angular.module('llax')
    .controller('CodelistEditorController',
        function($scope, $rootScope, $window, $location, $translate, $timeout, $modal, $dialogs,
            $q, $filter, InfiniteScrollHelper, QueryCodelistResource, growl,
            uiGridEditConstants, CodelistRessource, ErrorCode) {

            var CODELIST_KEY = "codelistKey";

            var CODELIST_GRID_STATES_KEY = "codelist-grid-states";
            var DEFAULT_GRID_STATE_KEY = "default-state";

            $scope.showAddInput = false;

            var LocalStorage = $window.localStorage;

            $scope.entries = [];
            $scope.creating = false;
            $scope.rowEdit = false;
            $scope.editingRow = null;
            $scope.state = {};

            $scope.createCodelistKey = function(key) {
                if (key) {
                    CodelistRessource.create({"name": key }, function(codelist) {
                        $scope.showAddInput = false;
                        growl.success('CODELIST_EDITOR.CREATE_CODELIST_SUCCESS_MESSAGE', {
                            variables: {
                                codelist : key
                            },
                        });
                        $scope.keys.push(codelist);
                        $scope.keys = _.sortBy($scope.keys, 'name');
                        $scope.selectCodelistMap(codelist);
                    }, function(errorData) {
                        var errorMessage = $rootScope.getErrorMessage(errorData);
                        errorMessage = errorMessage || "CODELIST_EDITOR.CREATE_CODELIST_ERROR_MESSAGE";
                        growl.error(errorMessage, {
                            ttl: 3000
                        });
                    });
                }
            };

            $scope.columnDefs = [{
                field: 'code',
                displayName: $translate.instant('CODELIST_EDITOR.CODE'),
                headerTooltip: true,
                cellTooltip: true,
                cellClass: 'text-center',
                enableCellEdit: true,
                enableCellEditOnFocus: true,
                cellEditableCondition: keyCellEditableCondition,
                width: '*'
            }, {
                field: 'defaultLabel',
                displayName: $translate.instant('CODELIST_EDITOR.DEFAULT_LABEL'),
                headerTooltip: true,
                cellTooltip: true,
                cellEditableCondition: valueCellEditableCondition,
                width: '*'
            }];
            _.forEach($rootScope.supportedLanguages, function(lan) {
                $scope.columnDefs.push({
                    field: "localeLabels." + lan.key,
                    displayName: $translate.instant('LANGUAGE.' + lan.key.toUpperCase()),
                    headerTooltip: true,
                    cellTooltip: true,
                    cellEditableCondition: valueCellEditableCondition,
                    width: '*'
                });
            });

            $scope.columnDefs.push(
                {
                    field: 'actions',
                    displayName: '',
                    enableCellSelection: false,
                    allowCellFocus: false,
                    enableCellEdit: false,
                    enableHiding : false,
                    cellClass: 'text-right',
                    headerCellClass: 'text-right',
                    enableColumnResizing: false,
                    enableSorting: false,
                    pinnedRight: true,
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents">' +
                        '  <div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">' +
                        '<button data-ng-if="!row.entity.editing" type="button" class="btn btn-cell action-delete action-btn-slot" tabindex="-1" title="{{\'CODELIST_EDITOR.DELETE_ENTRY\' | translate}}" data-ng-click="grid.appScope.deleteEntry(row.entity)" data-ng-disabled="grid.appScope.rowEdit"> ' +
                        '<i class="syncons syncons-delete"></i> ' +
                        '</button>' +
                        '<button data-ng-if="row.entity.editing" type="button" class="btn btn-cell action-save action-btn-slot" tabindex="-1" title="{{\'CODELIST_EDITOR.SAVE_ENTRY\' | translate}}" data-ng-click="grid.appScope.saveEntry(row.entity)"> ' +
                        '<i class="syncons syncons-checkmark"></i> ' +
                        '</button>' +
                        '<button data-ng-if="row.entity.editing" type="button" class="btn btn-cell action-cancel action-btn-slot" tabindex="-1" title="{{\'CANCEL\' | translate}}" data-ng-click="grid.appScope.cancel(row.entity)"> ' +
                        '<i class="glyphicon glyphicon-remove"></i> ' +
                        '</button>' +
                        '  </div>' +
                        '</div>'
                }
            );

            $scope.gridOptions = {
                data: 'entries',
                columnDefs: $scope.columnDefs,
                enableColumnMenus: false,
                enableGridMenu: true,
                enableSorting: false,
                infiniteScrollUp: false,
                infiniteScrollDown: true,
                rowHeight: 34,
            };

            $scope.refreshData = function(searchTerm) {
                $scope.entries = $filter('filter')($scope.entriesCopy, searchTerm);
            };

            $scope.queryParams = {
                limit: 20
            };

            // Accessor to get/set entries, used in 'InfiniteScrollHelper'
            function entriesAccessor(entries) {
                if (!_.isUndefined(entries)) {
                    $scope.entries = entries;
                    $scope.entriesCopy = angular.copy(entries);
                }
                return $scope.entries;
            }

            // Error handler used in 'InfiniteScrollHelper'
            function errorHandler(errorStatus, errorData) {
                var errorCode = $rootScope.getErrorCode({ data: errorData });
                if (errorCode !== ErrorCode.UNSPECIFIED) {
                    var errorMessage = $rootScope.getErrorMessage({ data: errorData });
                    growl.error(errorMessage, {
                        ttl: 3000
                    });
                }
                $scope.status = errorStatus;
            }

            function keyCellEditableCondition () {
                var cell = $scope.gridApi.cellNav.getFocusedCell();

                if(!cell.row.entity.code){
                    return true;
                }
                return false;
            }

            function valueCellEditableCondition () {
                var cell = $scope.gridApi.cellNav.getFocusedCell();

                if ($scope.rowEdit && $scope.creating && cell.row.entity.editing) {
                    return true;
                }
                if ($scope.rowEdit && cell.row.entity.editing) {
                    return true;
                }
                if ($scope.rowEdit) {
                    return false;
                }

                return true;
            }

            function saveState () {
                var map = $scope.activeCodelistKey;
                var selection = $scope.gridApi.selection.getSelectedRows();
                var row = null;
                if ($scope.rowEdit && $scope.editingRow) {
                    row = $scope.editingRow;
                }
                $scope.state = {map: map, row: row, selection: selection};
            }

            function restoreState () {
                if ($scope.activeCodelistKey != $scope.state.map) {
                    return;
                }

                $timeout(function () {
                    if ($scope.state) {
                        var row = $scope.state.row;
                        var selection = $scope.state.selection;
                        _.forEach(selection, function (row) {
                            $scope.gridApi.selection.selectRow(_.find($scope.entries, {
                                code: row.code
                            }));
                        });

                        if (row) {
                            var newCell = _.find($scope.entries, {
                                code: row.code
                            });
                            if (newCell) {
                                newCell.editing = true;
                                newCell.oldDefaultLabel = row.defaultLabel;
                                newCell.oldLocaleLabels = angular.copy(row.localeLabels);
                            }
                        }
                    }
                });
            }

            function activateInitialMap() {

                CodelistRessource.getAllCodelists(function(codelistData) {
                    $scope.activeCodelistKey = null;
                    $scope.activeCodelistId = null;
                    $scope.keys = codelistData;
                    if (!_.isEmpty($scope.keys)) {
                        var storedActiveMap = LocalStorage.getItem(CODELIST_KEY);

                        var existingActiveMap = _.find($scope.keys, {name: storedActiveMap});

                        if (existingActiveMap) {
                            $scope.selectCodelistMap(existingActiveMap);
                        } else {
                            $scope.selectCodelistMap($scope.keys[0]);
                        }
                    } else {
                        $scope.entries = [];
                    }
                });

            }

            // The 'infinite scroll' helper object
            // Simple set the 'resource' object, scope and data and gridApi variable, and call 'addToGridOptions' to enable 'infinite scrolling'
            $scope.scrollHelper = new InfiniteScrollHelper(QueryCodelistResource, $scope, entriesAccessor, 'gridApi', errorHandler);
            $scope.scrollHelper.addToGridOptions($scope.gridOptions, function(gridApi) {

                $scope.gridApi = gridApi;
                gridApi.edit.on.beginCellEdit($scope,function(row, cell) {
                    $scope.rowEdit = true;
                    row.editing = true;
                    $scope.editingRow = row;
                    if (!row.oldLocaleLabels && !_.isEmpty(row.localeLabels)) {
                        row.oldLocaleLabels = angular.copy(row.localeLabels);
                    }
                    if (!row.oldDefaultLabel && row.defaultLabel) {
                        row.oldDefaultLabel = row.defaultLabel;
                    }
                });

                $rootScope.initGridState($scope, $scope.gridApi, CODELIST_GRID_STATES_KEY, DEFAULT_GRID_STATE_KEY, $scope.columnDefs);

            });

            $scope.gridHeight = function () {
                var w = angular.element($window);
                if (w.width() < 992) {
                    return;
                }
                var footer = angular.element('footer');
                var boxContent = angular.element('.box-content');
                var content = angular.element('#content');
                var boxContentPadding = parseInt(boxContent.css('padding-bottom'), 10);
                var contentPadding = parseInt(content.css('padding-bottom'), 10);
                var offset = 630;
                if (angular.element("#codelist-grid") && angular.element("#codelist-grid").offset()) {
                    offset = angular.element("#codelist-grid").offset().top + footer.innerHeight() + boxContentPadding + contentPadding;
                }
                var newHeight = w.height() - offset;

                if ($scope.gridApi) {
                    $scope.gridApi.grid.gridHeight = newHeight;
                }

                return {
                   'height': newHeight + "px"
                };
            };

            angular.element($window).bind('resize', function(){
                $scope.gridHeight();
                $scope.$digest();
              });

            $scope.selectCodelistMap = function (key) {
                if (!key || !key.id) {
                    return;
                }

                if($scope.rowEdit || $scope.creating) {
                    var promise = $scope.checkUnsavedChanges();
                    promise.then(function () {
                        $scope.activeCodelistKey = key.name;
                        $scope.activeCodelistId = key.id;
                        $scope.activeKey = key;

                        $scope.scrollHelper.reload(_.merge({
                            id: key.id
                        }, $scope.queryParams));

                        $scope.creating = false;
                        $scope.rowEdit = false;
                    });
                } else {
                    $scope.activeCodelistKey = key.name;
                    $scope.activeCodelistId = key.id;
                    $scope.activeKey = key;

                    $scope.scrollHelper.reload(_.merge({
                        id: key.id
                    }, $scope.queryParams));
                }
                $scope.state = {};

                if ($scope.gridApi && $scope.gridApi.selection) {
                    $scope.gridApi.selection.clearSelectedRows();
                }

                LocalStorage.setItem(CODELIST_KEY, $scope.activeCodelistKey);
            };

            $scope.saveEntry = function (row) {
                var entry = {
                    code: row.code,
                    defaultLabel : row.defaultLabel,
                    localeLabels : row.localeLabels
                };

                CodelistRessource.createEntry({ id: $scope.activeCodelistId }, [entry] ,function (result) {
                    $scope.scrollHelper.reload(_.merge({ id: $scope.activeCodelistId }, $scope.queryParams));
                    row.editing = false;
                    $scope.rowEdit = false;
                    var msg = $scope.creating ? "CODELIST_EDITOR.CREATE_ENTRY_SUCCESS_MESSAGE" : "CODELIST_EDITOR.UPDATE_ENTRY_SUCCESS_MESSAGE";
                    $scope.creating = false;
                    growl.success(msg, {
                        variables: {
                            code: entry.code
                        },
                        ttl: 5000
                    });
                }, function (response) {
                    var msg = $scope.creating ? "CODELIST_EDITOR.CREATE_ENTRY_ERROR_MESSAGE" : "CODELIST_EDITOR.UPDATE_ENTRY_ERROR_MESSAGE";
                    growl.error(msg);
                });
            };

            $scope.deleteCodelist = function(codelist, $event) {
                $event.stopPropagation();
                CodelistRessource.delete({ id: codelist.id } ,function (result) {
                    growl.success('CODELIST_EDITOR.DELETE_CODELIST_SUCCESS_MESSAGE', {
                        variables: {
                            codelist: codelist.name
                        },
                        ttl: 5000
                    });
                    activateInitialMap();
                }, function (response) {
                    growl.error("CODELIST_EDITOR.DELETE_CODELIST_ERROR_MESSAGE");
                });
            };

            $scope.deleteEntry = function (row) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-key-value-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = [{key:row.code, value : row.defaultLabel}];
                            return data;
                        }
                    }
                });
                modalInstance.result.then(function() {
                    var codes = [row.code];
                    CodelistRessource.deleteEntries( row.codelistId, codes).then(function (result) {
                        $scope.scrollHelper.reload(_.merge({ id: $scope.activeCodelistId }, $scope.queryParams));
                        growl.success('CODELIST_EDITOR.DELETE_ENTRY_SUCCESS_MESSAGE_SINGLE', {
                            variables: {
                                code: row.code,
                                codelist : $scope.activeCodelistKey
                            },
                            ttl: 5000
                        });
                    }).catch(function (response) {
                        growl.error("CODELIST_EDITOR.DELETE_ENTRY_ERROR_MESSAGE");
                    });
                });
            };

            $scope.deleteSelectedEntries = function() {

                var selectedEntries = $scope.gridApi.selection.getSelectedRows();

                if (_.isEmpty($scope.entries) || _.isEmpty(selectedEntries)) {
                    growl.warning("CODELIST_EDITOR.NO_ENTRIES_SELECTED");
                    return false;
                }

                var map = $scope.activeCodelistKey;
                var selection = _.map(selectedEntries, function(entry) {
                    return entry.code;
                });

                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-key-value-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = selectedEntries.map(function(e) {
                                return {key:e.code, value: e.defaultLabel};
                            });
                            return data;
                        }
                    }
                });
                modalInstance.result.then(function() {
                    CodelistRessource.deleteEntries( selectedEntries[0].codelistId, selection).then(function (result) {
                        $scope.scrollHelper.reload(_.merge({ id: $scope.activeCodelistId }, $scope.queryParams));
                        growl.success('CODELIST_EDITOR.DELETE_ENTRY_SUCCESS_MESSAGE_MULTIPLE', {
                            variables: {
                                count: selection.length,
                                codelist : $scope.activeCodelistKey
                            },
                            ttl: 5000
                        });
                    }).catch(function (response) {
                        growl.error("CODELIST_EDITOR.DELETE_ENTRY_ERROR_MESSAGE");
                    });
                });
            };

            $scope.createEntry = function() {
                $scope.creating = true;
                var entry = {
                    code: '',
                    defaultLabel : '',
                    localeLabels : {}
                };

                _.forEach($rootScope.supportedLanguages, function(l) {
                    entry.localeLabels[l.key] = '';
                });

                $scope.entries.unshift(entry);

                $timeout(function () {
                    $scope.gridApi.cellNav.scrollToFocus($scope.entries[0],$scope.columnDefs[0]);
                });
            };

            $scope.cancel = function(row) {
                if (!$scope.creating) {
                    row.localeLabels = angular.copy(row.oldLocaleLabels);
                    row.defaultLabel = row.oldDefaultLabel;
                } else {
                    $scope.entries = _.remove($scope.entries, function(entry) {
                        return entry.$$hashKey != row.$$hashKey;
                    });
                    $scope.creating = false;
                }

                $scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
            };

            $scope.checkUnsavedChanges = function() {
                var deffered = $q.defer();
                var row = $scope.editingRow;
                if (!row && !$scope.creating) {
                    return deffered.reject();
                }
                var isEqual = _.isEqual(row.localeLabels, row.oldLocaleLabels) && row.defaultLabel == row.oldDefaultLabel;

                if ($scope.rowEdit && !isEqual) {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        deffered.resolve();
                    }, function() {
                        deffered.reject();
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            };

            $scope.$on(uiGridEditConstants.events.END_CELL_EDIT, function(event) {
                var row = $scope.editingRow;
                var isEqual = _.isEqual(row.localeLabels, row.oldLocaleLabels) && row.defaultLabel == row.oldDefaultLabel;
                if (!$scope.creating && isEqual) {
                    $scope.cancel(row);
                }
            });

            $scope.$on(uiGridEditConstants.events.CANCEL_CELL_EDIT, function(event) {
                if (!$scope.creating) {
                    var newRow = _.find($scope.entries, {code: $scope.editingRow.code});
                    if (newRow) {
                        newRow.editing = false;
                    }
                    $scope.rowEdit = false;
                }
            });

            $scope.$on('$locationChangeStart', function(event, next) {
                if($scope.rowEdit || $scope.creating) {
                    event.preventDefault();
                    var promise = $scope.checkUnsavedChanges();
                    promise.then(function () {
                        $scope.rowEdit = false;
                        $scope.creating = false;
                        var url = _.replace($location.absUrl(), $location.path(), "");
                        url = _.replace(next, url, "");
                        $location.url(url);
                    });
                }
            });

            $rootScope.$on("infiniteScrollLoadMore",function() {
                saveState();
            });

            $rootScope.$on("infiniteScrollDataLoaded",function() {
                restoreState();
            });

            (function init() {
                activateInitialMap();
            })();
        }
    );
