angular.module('llax')
    .controller('EditorItemGroupingController',
        function($rootScope, $scope, $location, ItemResource,
            Auth, $filter, ExportFormatsResource, ExportMappingResource,
            $modal, SelectionResource, ExportItemResource, $routeParams, $window, growl, ReactBridge) {

            var columnDefs = [];
            $scope.groupingData = [];
            $scope.groupingAttributes = [];
            $scope.filterAttributes = [{}];

            function createTableDefinition() {
                $scope.groupingAttributes = $rootScope.dataModel.filteredLayoutAttributes('grouping');

                var browseAttributes = $rootScope.dataModel.filteredLayoutAttributes('browse');
                var categoryAttributes = $rootScope.dataModel.categoryAttributes($scope.item.category__);
                var layoutAttributes = _.intersection(browseAttributes, categoryAttributes);

                _.each(layoutAttributes, function(attr) {
                    var col = {
                        attribute: attr,
                        field: attr.name,
                        displayName: $rootScope.translateAttribute(attr),
                        headerTooltip: true,
                        width: '*',
                        minWidth: 120,
                        enableCellSelection: true
                    };
                    columnDefs.push(col);
                });

                $scope.groupingGridOptions = {
                    data: 'groupingData',
                    columnDefs: columnDefs,
                    enableColumnMenus: false,
                    onRegisterApi: function(gridApi) {
                        $scope.gridApi = gridApi;
                        gridApi.selection.clearSelectedRows();
                        function rowSelectionChangedFn(row, event) {
                            $scope.selectedGroupItems = $scope.gridApi.selection.getSelectedRows();
                            var customPublicationService =  $rootScope.getService("CustomPublicationSettings");
                            $scope.hasDepublishRights = !customPublicationService || customPublicationService.enableDepublication($scope.selectedGroupItems, $rootScope.user, $rootScope.organization);
                        }
                        gridApi.selection.on.rowSelectionChanged($scope, rowSelectionChangedFn);
                        gridApi.selection.on.rowSelectionChangedBatch($scope, rowSelectionChangedFn);
                    }
                };
            }

            $scope.getSelectedFullItems = function() {
                var selectedItems = $scope.gridApi ? $scope.gridApi.selection.getSelectedRows() : null;
                return selectedItems;
            };

            $scope.removeGroupingAttribute = function(attribute) {
                _.remove($scope.filterAttributes, attribute);
                if ($scope.filterAttributes.length === 0) {
                    $scope.addGroupingAttribute();
                }
            };

            $scope.addGroupingAttribute = function() {
                $scope.filterAttributes.push({});
            };

            $scope.updateItemList = function(queryAttributes) {
                var keywordQuery = '';
                _.each(queryAttributes, function(attr, index) {
                    if (index > 0) {
                        keywordQuery += ' AND ';
                    }
                    keywordQuery += attr.name + ':' + $scope.item[attr.name];
                });
                var queryParam = {
                    keyword: keywordQuery
                };
                ItemResource.query(queryParam, function(response) {
                    $scope.groupingData = response;
                }, function(errorResponse) {});
            };

            $scope.startMassUpdate = function(selection) {
                $scope.closeEditor({action: 'startMassUpdate', selection: selection});
            };

            $scope.hasCategoryPermission = function(action, category) {
                return Auth.hasAnyPermission(Auth.OBJECT_TYPE_ITEMS, action, {category__: category});
            };

            $scope.hasAnyActionPermission = function(action) {
                var category;
                if (_.isNil($routeParams.category) || $routeParams.category === 'NO_CATEGORY') {
                    category = null;
                } else {
                    category = $routeParams.category;
                }
                return $scope.hasCategoryPermission(action, category);
            };

            $scope.hasAnyCreatePermission = function() {
                return $scope.hasAnyActionPermission('create');
            };

            $scope.hasAnyDeletePermission = function() {
                return $scope.hasAnyActionPermission('delete');
            };

            $scope.hasAnyEditPermission = function() {
                return $scope.hasAnyActionPermission('edit');
            };

            $scope.hasAnyPublishPermission = function() {
                return $scope.hasAnyActionPermission('publish');
            };

            $scope.hasAnyReadPermission = function() {
                return $scope.hasAnyActionPermission('read');
            };

            $scope.depublishItems = function() {
                $scope.publishItems("DEPUBLISH");
            };

            $scope.hasExportPermission = function() {
                return $scope.hasAnyReadPermission() && Auth.hasAnyPermission(Auth.OBJECT_TYPE_UI, 'view.exports');
            };

            $scope.hasTasksPermission = function() {
                return Auth.hasAnyPermission(Auth.OBJECT_TYPE_UI, 'view.tasks');
            };

            $scope.hasMediaAssetExportPermission = function() {
                var selectedItems = $scope.getSelectedFullItems();
                var hasPermission = true;
               _.forEach(selectedItems, function(item, index, array) {
                   var context = { namingPattern: Auth.ANY, item: item };
                   if (!Auth.hasPermission('mediaAssetExport', 'show', context)) {
                       hasPermission = false;
                   }
               });
               return hasPermission;
            };

            $scope.checkItemPermission = function(action, item) {
                if (!$scope.hasItemPermission(action, item)) {
                    var primaryKey = item.primaryKey__;
                    var category = item.category__;
                    var variables = {
                        action: action
                    };
                    var message;
                    if (!_.isEmpty(primaryKey)) {
                        message = "ITEM_ACTION.FORBIDDEN_FOR_PRIMARY_KEY";
                        variables.primaryKey = primaryKey;
                    } else if (!_.isEmpty(category)) {
                        message = "ITEM_ACTION.FORBIDDEN_FOR_CATEGORY";
                        variables.category = category;
                    } else {
                        message = "ITEM_ACTION.FORBIDDEN";
                    }
                    growl.warning(message, {variables: variables});
                    return false;
                }
                return true;
            };

            $scope.hasItemPermission = function(action, item) {
                return Auth.hasItemPermission(action, item);
            };

            $scope.publishItems = function(config) {

                var mode = config || "PUBLISH";

                if (_.isEmpty($scope.selectedGroupItems)) {
                    growl.warning("NO_ITEMS_SELECTED");
                    return false;
                }

                var customPublicationService = $rootScope.getService("CustomPublicationSettings");
                var isAllowedInvalidItem = false;
                if (!_.isNil(customPublicationService) && !_.isNil(customPublicationService.allowInvalidItem)) {
                    isAllowedInvalidItem = customPublicationService.allowInvalidItem();
                }

                var item;
                for (var i = 0; i < $scope.selectedGroupItems.length; i++) {
                    item = $scope.selectedGroupItems[i];
                    if (isAllowedInvalidItem === false) {
                        if (item.validation_dirty__ || !_.isEmpty(item.validation__)) {
                            growl.warning("ITEM_INVALID_BEFORE_PUBLISHING");
                            return false;
                        }
                    }
                    if (!$scope.checkItemPermission('publish', item)) {
                        return false;
                    }
                }

                var primaryKeys = _.map($scope.selectedGroupItems, 'primaryKey__');
                openPublicationsModal({primaryKeys: primaryKeys}, mode);

            };

            function openPublicationsModal(itemSelection, mode) {
                if ($scope.selectedGroupItems.length >= 1) {

                    var modalInstance = $modal.open({
                        templateUrl: 'tpl/publish-to-recipients-modal.tpl.html',
                        controller: 'PublishItemsController',
                        backdrop: true,
                        windowClass: 'publish-modal',
                        resolve: {
                            itemSelection: function() {
                                return itemSelection;
                            },
                            mode: function() {
                                return mode;
                            },
                            publicationData: function() {
                                return {};
                            }
                        }
                    });
                } else {
                    growl.warning("NO_ITEMS_SELECTED");
                }
            }

            $scope.filteredExportFormats = function() {
                return $filter('showExportFormatsFilter')($scope.exportFormats , $scope.selectedGroupItems);
            };

            $scope.downloadItems = function(exportType) {
                var selectedKeys= $scope.getSelectedItemsKeys();
                if (!selectedKeys || selectedKeys.length === 0) {
                    growl.warning("NO_ITEMS_SELECTED");
                } else if (exportType.usesMapping || !_.isEmpty(exportType.attributes)) {
                    startMappingEditor(selectedKeys, exportType);
                } else {
                    $scope.createExportFromSelection(exportType);
                }
            };

            function startMappingEditor(selection, exportType) {
                ExportMappingResource.query(
                    function(response) {
                        $modal.open({
                            templateUrl: 'tpl/export-mapping-editor.tpl.html',
                            controller: 'ExportMappingController',
                            resolve: {
                                data: function() {
                                    return {
                                        profiles: response.data || [],
                                        selectedItems: selection,
                                        parentscope: $scope,
                                        exportType: exportType
                                    };
                                }
                            },
                            backdrop: true,
                            windowClass: 'publish-modal'
                        });
                    });
            }

            $scope.getSelectedItemsKeys = function() {
                return _.map($scope.selectedGroupItems, 'primaryKey__');
            };

            $scope.createExportFromSelection = function(exportType) {
                var selection = { primaryKeys : $scope.getSelectedItemsKeys() };
                SelectionResource.save(selection, function(response) {
                    ExportItemResource.get({
                        selectionId: response.selectionId,
                        type: exportType.contentType,
                        language: $rootScope.language
                    }, function(exportResponse) {
                        var location = $window.location.protocol + "//" + $window.location.host + "/exports";
                        growl.success("ITEM.EXPORT_SUCCESS", {
                            variables: {
                                link: location
                            },
                            ttl: 5000
                        });
                    });
                });
            };

            $scope.updateItems = function() {
                var selection = { primaryKeys : $scope.getSelectedItemsKeys() };
                var selectedItems = $scope.getSelectedItemsKeys();
                if (!selectedItems || selectedItems.length === 0) {
                    growl.warning("NO_ITEMS_SELECTED");
                } else {
                     var dialog = ReactBridge.mountDialog("MassUpdateDialog", "#react-mass-update-dialog-grouping-tab",
                    {
                        data: {
                            selection: selection,
                            selectedItems: selectedItems
                        },
                        item: $scope.item,
                        category: $routeParams.category,
                       onClose: function(toWait) {
                            // Unmount the dialog after waiting if toWait is true, otherwise unmount immediately
                            function unmount(){
                                dialog.unmount();
                            }
                            return toWait? ReactBridge.unmountWhenReady(unmount): dialog.unmount();
                        }
                    });
                }
            };

            $scope.showEditorAddTask = function(items) {
                var selectedItems = $scope.getSelectedItemsKeys();
                $modal.open({
                    templateUrl: 'tpl/tasks-create-task.tpl.html',
                    controller: 'CreateTaskController',
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        data: function() {
                            return {
                                taskListId: null,
                                items: selectedItems
                            };
                        }
                    }
                });
            };

            $scope.showMediaAssetExport = function(items) {
                var selectedItems = $scope.getSelectedItemsKeys();
                var selection = { primaryKeys : selectedItems };
                $modal.open({
                    templateUrl: 'tpl/media-asset-export.tpl.html',
                    controller: 'MediaAssetExportController',
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        selectedItems: function() {
                            return selectedItems;
                        },
                        selection: function() {
                            return selection;
                        }
                    }
                });
            };

            (function init() {
                createTableDefinition();
                $scope.exportFormats = [];
                ExportFormatsResource.query({
                    returnKeyValueFormat: false
                }, function(response) {
                    $scope.exportFormats = $scope.exportFormats.concat(response);
                });
            })();

        }
    );
