angular.module('llax.directives')
    .directive('mappingEditorResizer', function($document) {
        return function($scope, $element, $attrs) {
            $element.on('mousedown', function(event) {
                event.preventDefault();
                $document.on('mousemove', mousemove);
                $document.on('mouseup', mouseup);
                angular.element($attrs.iframeTarget + ' iframe').css('pointer-events', 'none');
            });

            function mousemove(event) {
                var maxHeight = window.innerHeight - 250;
                var minHeight = 180;
                var box = angular.element('.box-content');
                var y = box[0].offsetHeight - event.pageY;
                if (y < maxHeight && y > minHeight) {
                    $element.css({
                        bottom: y + 'px'
                    });
                    var t_elem = angular.element($attrs.resizerTarget);
                    t_elem.css({
                        height: (y + 35) + 'px'
                    });
                }
            }

            function mouseup() {
                $document.unbind('mousemove', mousemove);
                $document.unbind('mouseup', mouseup);
                angular.element($attrs.iframeTarget + ' iframe').css('pointer-events', 'auto');
            }
        };
    });