angular.module('llax')
    .controller('ShoppingCartController',
        function($routeParams, $scope, $location, $translate, ShoppingCartService) {

            $scope.query = {
                category: $routeParams.category
            };

            var columnDefs = [];

            $scope.$on('dataModelLoaded', function() {
                createCatalogTableColumnDefinition();
            });

            $scope.addSelectedItems = function() {
                var selectedItems = $scope.gridOptions.$gridScope.selectedItems;
                if (selectedItems) {
                    angular.forEach(selectedItems, function(item) {
                        ShoppingCartService.addItem(item);
                    });
                }
            };

            $scope.addToShoppingCart = function(item) {
                ShoppingCartService.addItem(item);
            };

            $scope.removeFromShoppingCart = function(item) {
                ShoppingCartService.removeItem(item);
            };

            $scope.clearShoppingCart = function() {
                var cartItems = $scope.shoppingCart.items;
                angular.forEach(cartItems, function(item) {
                    $scope.removeFromShoppingCart(item);
                });
            };

            function createCatalogTableColumnDefinition() {
                columnDefs = ShoppingCartService.getColumnDefinitions();

                    columnDefs.push({
                        displayName: $translate.instant('ACTIONS'),
                        headerTooltip: true,
                        name: 'actions',
                        enableCellEdit: false,
                        enableCellSelection: false,
                        enableSorting: false,
                        resizable: false,
                        width: 80,
                        cellTemplate: '<button type="button" class="btn btn-cell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" data-ng-click="toDetailPage(row.entity)"><i class="syncons syncons-item-detail"></i></button>' +
                            '<button type="button" class="btn btn-cell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" data-ng-click="removeFromShoppingCart(row.entity)"><i class="syncons syncons-delete"></i></button>'
                    }
                );

                $scope.gridOptions = {
                    data: 'shoppingCart.items',
                    showSelectionCheckbox: false,
                    enableCellSelection: false,
                    enableCellEdit: true,
                    enableColumnResize: true,
                    enableColumnMenus: false,
                    multiSelect: false,
                    selectWithCheckboxOnly: false,
                    columnDefs: columnDefs,
                    rowHeight: 34,
                    afterSelectionChange: $scope.afterSelectionChange
                };

                $scope.$on('uiGridEventEndCellEdit', function(event) {
                    ShoppingCartService.updateTotalPrice(event.targetScope.row.entity);
                });

            }

            $scope.toDetailPage = function(item){
                $location.path('/items/detail/' + item.primaryKey);
            };

            $scope.shoppingCart = ShoppingCartService.shoppingCart;

            createCatalogTableColumnDefinition();

        }
    );
