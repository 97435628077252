angular.module('llax')
    .controller('EditContactController',
        function($rootScope, $scope, $modalInstance, growl, data, ContactsResource) {

            $scope.contactAttributes = $rootScope.dataModel.getContactAttributes();
            $rootScope.prepareAttributes($scope.contactAttributes);
            $scope.editMode = false;
            $scope.readOnly = false;
            $scope.parentImagetoggle = true;
            $scope.invitationTypes = ["INVITED_BUYER", "INVITED_SUPPLIER", "INVITED_COMMUNITY_MEMBER"];
            $scope.hideContactIcon = false;

            if (data && data.contact.id) {
                $scope.contact = angular.copy(data.contact);
                $scope.editMode = true;
                $scope.readOnly = !_.isNil(data.contact.organizationId);
            } else {
                $scope.contactOrganization = {};
                $scope.contact = {};
            }

            $scope.close = function(contact) {
                // FIXME: Ignore "managedContact" in backend!
                delete contact.managedContact;
                if ($scope.editMode) {
                    ContactsResource.save(contact, function() {
                        growl.success("CONTACT_SUCCESSFULLY_UPDATED");
                        $rootScope.$broadcast('updateContact');
                        $modalInstance.close();
                    }, function(error) {
                        if (error.data && error.data.message) {
                            growl.error(error.data.message, { variables: error.data.parameters });
                        } else {
                            growl.error("SAVE_ERROR_MESSAGE");
                        }
                    });
                } else {
                    ContactsResource.create(contact, function() {
                        growl.success("CONTACT_SUCCESSFULLY_ADDED");
                        $rootScope.$broadcast('updateContact');
                        $modalInstance.close();
                    }, function(error) {
                        if (error.data && error.data.message) {
                            growl.error(error.data.message, { variables: error.data.parameters });
                        } else {
                            growl.error("SAVE_ERROR_MESSAGE");
                        }
                    });
                }
            };

            $scope.uploadFileForItem = function(attributeName) {
                return {
                    url: lax_rest_url('assets'),
                    filters: ['imageFilter'],
                    accept: 'image/jpeg,image/gif,image/png',
                    cropImage: true,
                    uploadComplete: function(response) {
                        var assets = [];
                        if (_.isObject(response)) { // HTML5 browsers
                            assets = response.map(function(asset) {
                                var result = {};
                                result.url = asset.url;
                                result.name = asset.path.substr(1); //remove trailing /
                                result.contentType = asset.contentType;
                                return result;
                            });
                        } else if (_.isString(response)) { //IE9
                            response = response.substr(6).slice(0, -7);
                            var asset = JSON.parse(response);
                            asset.name = asset.path.substr(1);
                            assets.push(asset);
                        }
                        if (assets && assets.length>0) {
                            $scope.contact.imageUrl = assets[0].url;
                        }
                    }
                };
            };

            $scope.cancel = function() {
                $modalInstance.close();
            };

            $scope.toggleContactIcon = function() {
                $scope.hideContactIcon = !$scope.hideContactIcon;
            };

            $scope.$on('toggleFileUpload', function(event, uploadId) {
                if(uploadId == "contact_upload") {
                    $scope.toggleContactIcon();
                }
            });
        });
