angular.module('llax')
    .controller('EditTaskController',
        function($rootScope, $scope, $translate, $routeParams, $dialogs, $controller, $location, growl, TaskResource) {

            $controller('EditorContainerController', {
                $scope: $scope
            });

            $scope.focusOn = true;
            $scope.attachment = undefined;
            $scope.filesSelected = false;
            $scope.fileUploadSettings = {
                showInlinepreviewInDropzone : true
            };

            $scope.hasEditPermission = function(task) {
                var canEdit = true;
                if (task && $rootScope.user && !_.isEmpty($rootScope.user.roles)) {
                    canEdit = $rootScope.user.roles.indexOf('admin') > -1 || $rootScope.user.userId == task.author;
                }
                return canEdit;
            };

            $scope.editorOptions = {
                basic: {
                    height: '5em',
                    removePlugins: 'elementspath',
                    toolbar: [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic']
                    }, {
                        name: 'paragraph',
                        items: ['BulletedList', 'NumberedList']
                    }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                    }]
                }
            };

            function initTask() {
                var taskTags = $rootScope.dataModel.getTaskTags();
                $scope.taskTags = taskTags;
                TaskResource.get({ taskId: $routeParams.taskId }, function(response) {
                    var task = response;
                    task.comments = loadComments(task.id);
                    $scope.task = task;
                    $scope.tagsCopy = angular.copy($scope.task.tags);
                    $scope.task.notes = decodeHtml($scope.task.notes);
                    $scope.taskLoadingFinished = true;
                });
            }

            $scope.closeTask = function(task) {
                if (task.transitions && !task.chosenTransition) {
                    growl.error("TASK.CLOSE_TASK.FAILED_MESSAGE");
                    return;
                }
                task.taskStatus = 'FINISHED';
                $scope.updateTask(task);
            };

            $scope.reopenTask = function(task) {
                task.taskStatus = 'OPEN';
                $scope.updateTask(task);
            };

            $scope.$on('itemEditorClosed', function(event, result) {
                if (result && result.taskOptions && result.taskOptions.closeTask) {
                    $scope.task.chosenTransition = result.taskOptions.transition;
                    $scope.closeTask($scope.task);
                    $location.path("/tasks");
                }
            });

            $scope.setAssignee = function(user) {
                $scope.task.assignee = user.userId;
            };

            $scope.tagsCopy = {};
            $scope.cancelFields = function(field) {
                if (field == 'tags') {
                    $scope.task.tags = angular.copy($scope.tagsCopy);
                }
            };

            $scope.updateTask = function() {
                TaskResource.save({
                    taskId: $scope.task.id
                }, $scope.task, function(response) {
                    $scope.task = response;
                    $scope.tagsCopy = angular.copy($scope.task.tags);
                    loadComments($scope.task.id);
                    growl.success("TASK.SAVE_TASK_SUCCESS_MESSAGE");
                });
            };

            $scope.$on('filesSelected', function() {
                $scope.filesSelected = true;
            });

            $scope.uploadAttachments = function() {
                return {
                    url: lax_rest_url('assets'),
                    reset: true,
                    uploadComplete: function(response) {
                        $scope.comment.assets = $scope.comment.assets || [];
                        var assets = _.map(response, function(asset) {
                            var result = {};
                            result.url = asset.url;
                            result.name = asset.path.substr(1);
                            result.contentType = asset.contentType;
                            return result;
                        });
                        $scope.comment.assets = $scope.comment.assets.concat(assets);
                        $scope.filesSelected = false;
                    }
                };
            };

            $scope.removeAttachement = function(asset) {
                _.remove($scope.comment.assets, {
                    url: asset.url
                });
            };

            $scope.$on("removeAttachement", function (event, eventData) {
                $scope.removeAttachement(eventData);
            });

            function loadComments(taskId) {
                TaskResource.getComments({
                    taskId: taskId
                }, function(response) {
                    $scope.task.comments = response;
                });
            }

            $scope.saveComment = function(comment, taskId) {
                if (_.isEmpty(comment.text)) {
                    growl.warning('TASK.MISSING_TASK_TITLE');
                    return false;
                }

                if (!$scope.filesSelected) {
                    comment.author = $rootScope.user.userId;
                    comment.taskId = taskId;

                    var pathParams = {
                        taskId: taskId,
                        commentId: comment.id
                    };

                    TaskResource.saveComment(pathParams, comment, function(response) {
                        $scope.comment = {};
                        $scope.$broadcast('clearAttributeValue');
                        growl.success("SAVE_SUCCESS_MESSAGE");
                        loadComments(taskId);
                    });
                } else {
                    var message = $translate.instant('NOTIFY.FILES_STILL_NOT_UPLOADED');
                    $dialogs.notify("", message);
                }
            };

            $scope.getTransitionText = function(task) {
                if (!task.transitions) {
                    return task.chosenTransition;
                }
                var chosenTransition = _.find(task.transitions, { key: task.chosenTransition });
                return chosenTransition ? chosenTransition.text : task.chosenTransition;
            };

            $scope.enableReopenTask = function(task) {
                if (_.isEmpty(task)) {
                    return false;
                }
                var customService =  $rootScope.getService('CustomTaskEditorSettings');
                return !customService || customService.enableReopenTask(task, $rootScope.user, $rootScope.organization);
            };

            (function init() {
                initTask();
            })();
        }
    );
