//Exception Interceptor that gives the possiblity to test for exceptions with Selenium
angular.module('llax.exceptions', ['llax.services', 'pascalprecht.translate'])
    .provider("$exceptionHandler", {
        $get: function(errorLogService) {
            return (errorLogService);
        }
    })
    .factory("errorLogService", function($log, $window) {
        function log(exception, cause) {
            $log.error.apply($log, arguments);
            console.log("JSError: ", exception);
            $("body").attr("JSError", exception.toString());
        }
        return (log);
    });
