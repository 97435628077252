angular.module('llax.directives')
    .directive('mappingEntriesGrid', function($timeout, $translate, TemplateMappingService, uiGridConstants) {
        return {
            strict: 'E',
            scope: {
                gridApi: '=',
                mappings: '=',
                templateType: '=',
                attributes: '=',
                mappingFunctions: '=',
                onFocusSpreadsheetCell: '=',
                onDeleteMappingEntry: '=',
                onConfigureSimpleMappingList: '=',
                onConfigureCustomCodeMappingFunction: '=',
                onConfigureMappingFunction: '='
            },
            template:
                '<div ' +
                    'id="mappings-grid" ' +
                    'class="smooth gridStyle" ' +
                    'data-ui-grid="gridOptions" ' +
                    'data-ng-style="gridHeight()"' +
                    'data-ui-grid-edit ' +
                    'ui-grid-move-columns ' +
                    'data-ui-grid-resize-columns ' +
                    'data-ui-grid-cellNav ' +
                    'data-ui-grid-browser-tooltips ' +
                    'data-ui-grid-auto-resize> ' +
                '</div>',
            link: function($scope) {
                var TYPE_LIST = 'LIST';

                $scope.columnDefs = [
                    {
                        displayName: $translate.instant('TEMPLATE_MAPPINGS.NAME'),
                        name: 'externalName',
                        enableCellEdit: true,
                        enableCellSelection: false,
                        allowCellFocus: true,
                        enableColumnResizing: false,
                        enableSorting: true,
                        enableHiding: false,
                        pinnedRight: false,
                        width: '*',
                        minWidth: 120,
                        editableCellTemplate:
                            '<div>' +
                                '<form name="inputForm">' +
                                    '<input type="text" ' +
                                           'class="form-control" ' +
                                           'data-ng-model="MODEL_COL_FIELD" ' +
                                           'lax-grid-input /> ' +
                                '</form>' +
                            '</div>'
                    },
                    {
                        displayName: $translate.instant('TEMPLATE_MAPPINGS.COLUMN'),
                        name: 'location',
                        enableCellEdit: true,
                        enableCellSelection: false,
                        allowCellFocus: true,
                        enableColumnResizing: false,
                        enableSorting: true,
                        sortingAlgorithm: function(firstValue, secondValue, firstRow, secondRow, direction) {
                            if (_.isEmpty(firstValue) || _.isEmpty(secondValue)) {
                                return 0;
                            } else {
                                var a1OfFirstValue = TemplateMappingService.getA1NotationFromLocationString(firstValue);
                                var a1OfSecondValue = TemplateMappingService.getA1NotationFromLocationString(secondValue);
                                return TemplateMappingService.convertFromA1Notation(a1OfFirstValue).col - TemplateMappingService.convertFromA1Notation(a1OfSecondValue).col;
                            }
                        },
                        enableHiding: false,
                        pinnedRight: false,
                        width: '*',
                        minWidth: 60,
                        cellTemplate:
                            '<div class="ui-grid-cell-content flex flex-row items-center h-full" style="padding: 7px;">' +
                                '{{grid.appScope.formatEntryLocation(row.entity.location)}}' +
                                '<button ' +
                                    'data-ng-if="row.entity.location" ' +
                                    'style="margin-left: auto; background: none; min-height: 10px; margin-bottom: 2px;" ' +
                                    'class="btn btn-sm p-0" ' +
                                    'data-ng-click="grid.appScope.onFocusSpreadsheetCell(row.entity.location)" ' +
                                    'title="'+ $translate.instant('TEMPLATE_MAPPINGS.SHOW_LOCATION') + '">' +
                                    '<i class="syncons syncons-location hover-color-primary"></i>' +
                                '</button>' +
                            '</div>',
                        editableCellTemplate:
                            '<div>' +
                                '<form name="inputForm">' +
                                    '<input type="text" ' +
                                            'class="form-control" ' +
                                            'data-ng-model="MODEL_COL_FIELD" ' +
                                            'lax-grid-input />' +
                                '</form>' +
                            '</div>'
                    },
                    {
                        displayName: $translate.instant('TEMPLATE_MAPPINGS.MODEL_ATTRIBUTE'),
                        name: 'internalName__',
                        enableCellEdit: true,
                        enableCellSelection: false,
                        allowCellFocus: true,
                        enableColumnResizing: false,
                        enableSorting: true,
                        sortingAlgorithm: function(firstValue, secondValue, firstRow, secondRow, direction) {
                            if (_.isEmpty(firstValue) || _.isEmpty(secondValue)) {
                                return 0;
                            } else {
                                if (!_.isNil(firstValue.translatedName)) {
                                    return firstValue.translatedName.localeCompare(secondValue.translatedName);
                                } else {
                                    return firstValue.name.localeCompare(secondValue.name);
                                }
                            }
                        },
                        enableHiding: false,
                        pinnedRight: false,
                        width: '*',
                        minWidth: 120,
                        cellTemplate: '<div class="ui-grid-cell-contents mapping-entries-cell flex items-center">{{row.entity.internalName__.name}}</div>',
                        editableCellTemplate:
                            '<form name="inputForm">' +
                                '<ui-select ' +
                                    'data-ng-init="$select.open = true" ' +
                                    'data-ng-model="MODEL_COL_FIELD" ' +
                                    'data-append-to-body="true" data-autofocus="true" data-theme="bootstrap" ' +
                                    'data-lax-grid-ui-select ' +
                                    'class="ui-grid-ui-select" ' +
                                    'data-on-select="grid.appScope.onInternalNameChange(row.entity, $select.selected)">' +
                                    '<ui-select-match>' +
                                        '<ui-select-match>{{$select.selected.name}}</ui-select-match>' +
                                    '</ui-select-match>' +
                                    '<ui-select-choices repeat="attr in grid.appScope.attributes | filter: $select.search">' +
                                        '<div ng-bind-html="attr.translatedName || attr.name | highlight: $select.search" title={{::attr.translatedName}}></div>' +
                                    '</ui-select-choices>' +
                                '</ui-select>' +
                            '</form>'
                    },
                    {
                        displayName: $translate.instant('TEMPLATE_MAPPINGS.MAPPING_FUNCTION'),
                        name: 'mappingFunction__',
                        enableCellEdit: true,
                        enableCellSelection: false,
                        allowCellFocus: true,
                        enableColumnResizing: false,
                        enableSorting: false,
                        enableHiding: false,
                        pinnedRight: false,
                        width: '*',
                        minWidth: 120,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents mapping-entries-cell flex flex-row items-center">' +
                                '<span style="white-space: no-wrap; overflow: hidden; text-overflow: ellipsis;">{{row.entity.mappingFunction__.translatedLabel}}</span>' +
                                '<span ' +
                                    'style="margin-left: auto;" ' +
                                    'data-mapping-function-hint ' +
                                    'data-mapping-function-definition="row.entity.mappingFunction__" ' +
                                    'data-mapping-entry="row.entity" ' +
                                    'data-mapping-parameters="row.entity.mappingFunctionParams"></span> ' +
                            '</div>',
                        editableCellTemplate:
                            '<form name="inputForm">' +
                                '<ui-select ' +
                                    'data-ng-init="$select.open = true" ' +
                                    'data-ng-model="MODEL_COL_FIELD" ' +
                                    'data-append-to-body="true" data-autofocus="true" data-theme="bootstrap" ' +
                                    'data-lax-grid-ui-select style="width: 300px; left: 0; right: 100px;" ' +
                                    'class="ui-grid-ui-select" ' +
                                    'data-on-select="grid.appScope.onMappingFunctionChange(row.entity, $select.selected)">' +
                                    '<ui-select-match>' +
                                        '<ui-select-match>{{$select.selected.name}}</ui-select-match>' +
                                    '</ui-select-match>' +
                                    '<ui-select-choices repeat="attr in grid.appScope.mappingFunctions | filter: $select.search">' +
                                        '<div ng-bind-html="attr.translatedLabel || attr.label | highlight: $select.search" title="{{::attr.translatedLabel}}"></div>' +
                                    '</ui-select-choices>' +
                                '</ui-select>' +
                            '</form>'
                    },
                    {
                        displayName: '',
                        name: 'config',
                        enableCellEdit: false,
                        enableCellSelection: false,
                        allowCellFocus: false,
                        enableColumnResizing: false,
                        enableSorting: false,
                        enableHiding: false,
                        pinnedRight: false,
                        width: '60',
                        minWidth: 30,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents flex items-center" style="justify-content: flex-end">' +
                                '<div data-ng-if="row.entity.mappingFunction" style="margin-right: 5px;">' +
                                    '<i data-ng-if="grid.appScope.getMappingFunctionType(row.entity.mappingFunction__) === \'simple_mappings\'" class="syncons syncons-options hover-color-primary cursor-pointer" data-ng-click="grid.appScope.onConfigureSimpleMappingList(row.entity)"></i>' +
                                    '<i data-ng-if="grid.appScope.getMappingFunctionType(row.entity.mappingFunction__) === \'code\'" class="syncons syncons-options hover-color-primary cursor-pointer" data-ng-click="grid.appScope.onConfigureCustomCodeMappingFunction(row.entity)"></i>' +
                                    '<i data-ng-if="grid.appScope.getMappingFunctionType(row.entity.mappingFunction__) === \'default\'" class="syncons syncons-options hover-color-primary cursor-pointer" data-ng-click="grid.appScope.onConfigureMappingFunction(row.entity)"></i>' +
                                '</div>' +
                                '<i class="syncons syncons-delete" data-ng-click="grid.appScope.onDeleteMappingEntry(row.entity)" style="cursor: pointer;"></i>' +
                            '</div>'
                    },
                ];

                $scope.gridOptions = {
                    data: 'mappings',
                    columnDefs: $scope.columnDefs,
                    enableColumnMenus: false,
                    enableHorizontalScrollbar: 1, // hide
                    enableVerticalScrollbar: 1, // show when needed
                    rowHeight: 32,
                    onRegisterApi: function(gridApi) {
                        $scope.gridApi = gridApi;
                        $scope.onTemplateTypeChange($scope.templateType);
                        $timeout(function() {
                            $scope.removeFocusClass();
                        });
                    }
                };

                $scope.formatEntryLocation = function(location) {
                    if (location === '') {
                        return location;
                    }

                    if ($scope.templateType === TYPE_LIST) {
                        var locationWithoutSheet = TemplateMappingService.getA1NotationFromLocationString(location);
                        return TemplateMappingService.convertToA1Notation(
                            TemplateMappingService.convertFromA1Notation(locationWithoutSheet).col + 1);
                    } else {
                        return TemplateMappingService.getA1NotationFromLocationString(location);
                    }
                };

                $scope.onInternalNameChange = function(rowEntity, selectedAttribute) {
                    if (_.isEmpty(selectedAttribute.value)) {
                        rowEntity.internalName = null;
                        rowEntity.internalName__ = null;
                    } else {
                        rowEntity.internalName = selectedAttribute.value;
                    }
                };

                $scope.onMappingFunctionChange = function(rowEntity, selectedMappingFunction) {
                    if (_.isEmpty(selectedMappingFunction.name)) {
                        rowEntity.mappingFunction = null;
                        rowEntity.mappingFunction__ = null;
                        rowEntity.mappingFunctionParams = null;
                    } else {
                        rowEntity.mappingFunctionParams = null;
                        rowEntity.mappingFunction = selectedMappingFunction.name;
                    }
                };

                $scope.onTemplateTypeChange = function(newType) {
                    if (newType === TYPE_LIST) {
                        $scope.columnDefs[1].displayName = $translate.instant('TEMPLATE_MAPPINGS.COLUMN');
                    } else {
                        $scope.columnDefs[1].displayName = $translate.instant('TEMPLATE_MAPPINGS.CELL');
                    }
                    $timeout(function(){
                        if (!_.isEmpty($scope.gridApi)) {
                            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                        }
                    }, 0);
                };

                $scope.getMappingFunctionType = function(mappingFunctionDefinition) {
                    return TemplateMappingService.getMappingFunctionType(mappingFunctionDefinition);
                };

                $scope.removeFocusClass = function() {
                    // Due to the grid not being fully visible in the UI, a UI glitch is happening when focusing the cell.
                    // For more info: https://github.com/angular-ui/ui-grid/issues/5980
                    $('.ui-grid-focuser').remove();
                };

                $scope.gridHeight = function () {
                    var newHeight = 32 * ($scope.mappings.length + 2);
                    return {
                        'height': newHeight + "px",
                        'min-height': '500px'
                    };
                };

                $scope.$watch('templateType', $scope.onTemplateTypeChange);
            }
        };
    });
