angular.module('llax.directives')
    .directive('bannerAd', function() {
        return {
            strict: 'E',
            scope: {
                adUrl: '@',
                adId: '@'
            },
            template:
                '<div class="ad-banner" data-ng-show="visible">' +
                    '<close-ad-button data-on-cancel="cancel" data-banner="true" data-ad-id="{{adId}}"></close-ad-button>' +
                    '<iframe ' +
                        'src="{{trustUrl(adUrl)}}"' +
                        'scrolling="no">' +
                    '</iframe>' +
                '</div>',
            controller: function($element, $sce, $scope) {
                $scope.visible = true;
                $scope.trustUrl = function(url) {
                    return $sce.trustAsResourceUrl(url);
                };

                window.addEventListener('message', function(event) {
                    // Adjust the iframe container height based upon the iframe sizes:
                    // the event 'message' is sent from the iframe.
                    var $divElement = $element[0].querySelector('div');
                    var $iframe = $element[0].querySelector('iframe');
                    $divElement.style.height = event.data.height + 'px';
                    $iframe.style.height = event.data.height + 'px';
                });

                $scope.cancel = function() {
                    $scope.visible = false;
                };
            }
        };
    });