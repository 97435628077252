angular.module('llax.services')
    .service('ReferenceAttributesService', function(FindItemsByPrimaryKeyResource, $http, $log, $parse, $rootScope, $q) {

        /**
         *
         * Returns a list of items based on the 'keywordQuery'. Each has 'thisShouldNotBeUsedAsAnAttributeName' property
         * which reflects the formatted item display name.
         *
         * Note that @item parameter can be null in case the filter is called from within Mass Update function.
         */
        this.loadAndFormatItemsToBeReferencedAsync = function(keywordQuery, parentItem, attribute) {

            var _this = this;
            var referenceableCategories = attribute.params.references;

            if (referenceableCategories !== undefined && referenceableCategories.length > 0) {
                for (var i = 0; i < referenceableCategories.length; i++) {
                    var category = $rootScope.escapeSearchTerm(referenceableCategories[i]);
                    if (i === 0) {
                        keywordQuery += ' AND (';
                    } else {
                        keywordQuery += ' OR ';
                    }
                    keywordQuery += 'category__:' + category;
                }
                keywordQuery += ')';
            }

            var queryParams = {
                keyword: keywordQuery
            };

            return $http.get(lax_rest_url('items'), {
                    params: queryParams
                })
                .then(function(res) {
                    angular.forEach(res.data, function(item) {
                        item.thisShouldNotBeUsedAsAnAttributeName = _this.formatReferenceItem(parentItem, item, attribute);
                    });
                    return res.data;
                });
        };

        /**
         *
         * Extends referenced items by the fields that are configured in the data model, for example, to be shown in a grid, while
         * keeping the transitional properties in the multi-reference attribute which are stored on the parent item.
         */
        this.extendReferencedItemsAsync = function(attribute, referencedItems) {
            var deferred = $q.defer();
            var primaryKeys = _.map(referencedItems, function(item) {
                return item.primaryKey__;
            });
            var fields = this.getAttributeFields(attribute);

            FindItemsByPrimaryKeyResource.find({}, {
                'items': primaryKeys,
                'fields': fields
            },
            function(itemsResult) {
                var extendedItems = [];
                for (var i = 0; i < referencedItems.length; i++) {
                    var referencedItem = referencedItems[i];

                    var completeItem = _.find(itemsResult, { primaryKey__: referencedItem.primaryKey__ });

                    var extendedItem = {};
                    if (!_.isNil(completeItem)) {
                        // We need to keep the 'transitional properties' of the multi-reference attribute, which
                        // are only stored on the parent item attribute (referencedItem in our case).
                        extendedItem = _.extend({}, completeItem, referencedItem);
                    } else {
                        // Item was not found in the backend, but we need to display it, and allow the
                        // user to delete the reference to it
                        extendedItem = _.extend({ deleted__: true }, completeItem, referencedItem);
                    }
                    extendedItems.push(extendedItem);
                }

                deferred.resolve(extendedItems);
            });

            return deferred.promise;
        };

        /**
         *
         * Returns a formatted single referenced item.
         */
        this.loadAndFormatItemAsync = function(referencedItemPrimaryKey, parentItem, attribute) {

            var deferred = $q.defer();
            var _this = this;

            FindItemsByPrimaryKeyResource.find({}, {
                'items': [referencedItemPrimaryKey],
            },
                function (itemResult) {
                    if (itemResult.length > 0) {
                        var referencedItem = itemResult[0];
                        var formattedItem = _this.formatReferenceItem(parentItem, referencedItem, attribute);
                        deferred.resolve(formattedItem);
                    } else {
                        $log.error('loadAndFormatItemAsync(): Could not find item with primary key %s', referencedItemPrimaryKey);
                        deferred.reject();
                    }
                });

            return deferred.promise;
        };

        /**
         *
         * Formats/Serializes an item based on the reference attribute (MultiReference/SingleReference) of the parent item.
         * Note that a filter function has higher precedence than filters array.
         *
         * Note that @item parameter can be null in case the filter is called from within Mass Update function.
         */
        this.formatReferenceItem = function(parentItem, referenceItem, attribute) {

            var filters = attribute.params.filter;
            var filterFunctionName = attribute.params.uiReferenceFilter;

            if (!_.isNil(filterFunctionName)) {
                filterFunction = $rootScope.getFilter(filterFunctionName);
                return filterFunction(referenceItem.primaryKey__, referenceItem, parentItem, attribute, $rootScope.user, $rootScope.organization);
            } else if (!_.isEmpty(filters)) {
                return formatByFilters(referenceItem, filters);
            } else {
                return foundItem.primaryKey__;
            }
        };

        function formatByFilters(item, displayNameFilterAttributes) {

            var displayNames = [];
            _.forEach(displayNameFilterAttributes, function(attributeName) {
                var referencedFilterAttribute = $rootScope.dataModel.attribute(attributeName);
                var referencedFilterAttributeType = referencedFilterAttribute.typeName;
                var formattedValue = $rootScope.formatAttributeValue(item, referencedFilterAttribute);

                if (_.isEmpty(formattedValue)) {
                    return;
                } else if (angular.isString(formattedValue)) {
                    displayNames.push(formattedValue);
                } else if (angular.isObject(formattedValue) && referencedFilterAttributeType === 'Dimensional') {
                    var key = $rootScope.getFirstFilteredDimensionKey(item, referencedFilterAttribute);
                    displayNames.push(formattedValue[key]);
                } else {
                    $log.error("Rendering attribute '%s' of type '%s' as a dropdown item name is not supported.",
                        referencedFilterAttribute.name, referencedFilterAttributeType);
                }
            });

            return displayNames.join(', ');
        }

        /**
         * Returns the list of filters which are defined for the Single/MultiReference attribute in the parent item.
         */
        this.getAttributeFields = function(attribute) {
            var valueAttribute = $rootScope.dataModel.attribute(attribute.params.valueAttribute);
            var fields = ["primaryKey__"].concat(attribute.params.filter || [], valueAttribute.members || []);
            return _.uniq(fields);
        };

        /**
         * Returns the names of transient attributes of a multi reference attribute (the attributes that are
         * stored on the link).
         */
        this.getTransientAttributes = function(attribute) {
            var valueAttribute = $rootScope.dataModel.attribute(attribute.params.valueAttribute);
            return valueAttribute.members;
        };

        this.getModelEval = function(model, attribute) {
            model = this.parseModelString(model, attribute.name);
            return $parse(model);
        };

        this.checkModelAndEval = function(model, attribute, referencedItem, $scope) {
            var modelEval = this.getModelEval(model, attribute);
            modelEval.assign($scope, referencedItem.primaryKey__);
            return modelEval($scope) || {};
        };

        this.parseModelString = function(model, attributeName) {
            if (_.isEqual("item[a.name]", model)) {
                model = "item['" + attributeName + "']";
            } else if (!_.includes(model, "'")) {
                model = model.replace(/\[/g, "['");
                model = model.replace(/\]/g, "']");
                model = model.replace(/\'\d+\'/g, function (x) {
                    return x.replace(/\'/g, "");
                });
            }
            return model;
        };
    });