angular.module('llax')
    .controller('ChatController',
        /*@ngInject*/
        function($rootScope, $scope, $http, $translate, $log, ChannelService) {

        $scope.newMessages = 0;
        $scope.connectedUserIds = [];
        $scope.otherUsersLoggedIn = function() {
            return !_.isEmpty($scope.connectedUserIds);
        };

        $scope.chatWindow = {
            visible: false,
            currentText: ""
        };

        $scope.messages = [];

        ChannelService.getRegistered(ChannelService.CHAT_EVENT).then(function(response) {
            $log.debug("getRegistered(CHAT):", response);
            $scope.connectedUserIds = _.filter(response, function(userId) {
                return userId != $rootScope.user.id;
            });
        });

        function generateUsersAsHTML() {
            var result = $translate.instant("CURRENTLY_ONLINE") + "<br>";
            result += "<ul>";
            angular.forEach($scope.connectedUserIds, function(userId) {
                var displayName = $rootScope.getUserName(userId);
                result += '<li><span style="white-space: nowrap">' + _.escape($rootScope.getUserName(userId)) + '</span></li>';
            });
            result += "</ul>";
            $scope.connectedUsersAsHTML = result;
        }

        $scope.$watchCollection("connectedUserIds", generateUsersAsHTML);
        $scope.$on("usersLoaded", generateUsersAsHTML);

        $scope.sendChatMessage = function() {

            var message = {
                author: $rootScope.user.userId,
                text: $scope.chatWindow.currentText
            };
            $scope.chatWindow.currentText = "";

            ChannelService.broadcast(ChannelService.CHAT_EVENT, null, message);

        };

        $scope.toggleChatWindow = function() {
            $scope.chatWindow.visible = !$scope.chatWindow.visible;
            $scope.newMessages = 0;
            $scope.hideOnlineUsersTooltip();
        };

        function addMessage(message) {
            if (_.isNil(message) || _.isEmpty(message)) {
                return;
            }
            if (message.author == $scope.user.userId) {
                message.direction = "sent";
            } else {
                message.direction = "receive";
            }
            $scope.messages.push(message);
            $scope.newMessages++;
            $scope.$broadcast('scrollTriggered');
        }

        $scope.$on('channelMessageReceived', function(event, eventData) {

            if (eventData.event !== ChannelService.CHAT_EVENT) {
                return;
            }

            var type = eventData.type;
            if (type === ChannelService.SUBSCRIBE_TYPE) {
                addMessage(eventData.data);
            } else if (type === ChannelService.LISTEN_TYPE) {

                var action = eventData.data.action;
                var userId = eventData.data.userId;
                if (userId && userId !== $scope.user.id) {
                    if (action === ChannelService.REGISTERED_ACTION) {
                        $scope.connectedUserIds.addToSet(userId);
                    } else if (action === ChannelService.UNREGISTERED_ACTION) {
                        $scope.connectedUserIds.removeFromSet(userId);
                    }
                }

            }

        });

        $scope.showOnlineUsersTooltip = function() {
            var calloutConfig = {
                id: 'callout_online_users',
                target: 'button-chat',
                placement: 'left',
                content: '',
                fixedElement: true,
                yOffset: -30 -20 * $scope.connectedUserIds.length,
                customRenderer: function() {
                    var attributeStart = '<div class="chat-tooltip">';
                    var attributeEnd = '</div>' + '<span class="hopscotch-arrow"></span>';
                    return attributeStart + $scope.connectedUsersAsHTML + attributeEnd;
                }
            };

            var calloutMgr = hopscotch.getCalloutManager();
            calloutMgr.removeAllCallouts();

            var calloutEle = calloutMgr.createCallout(calloutConfig);
            // Hack: override !important css rules, with inline precedence
            calloutEle.element.style.cssText += 'border: none !important; z-index: 1050;';
        };

        $scope.hideOnlineUsersTooltip = function() {
            var calloutMgr = hopscotch.getCalloutManager();
            calloutMgr.removeAllCallouts();
        };
    });
