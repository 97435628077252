angular.module('llax')
    .controller('EditSubscriptionsController',
        function($scope, $rootScope, $modalInstance, $translate, $q, channel, subscriptionDescription, ItemChangesQueueManager,
            ValidateItemService) {

            $scope.subscriptionDescription = subscriptionDescription;
            $scope.subscriptions = subscriptionDescription.subscriptions;

            // Validations per subscription
            var subscriptionValidations = [];

            // Function to call when subscription changes are validated
            function processValidateSubscription(subscriptionChanges, subscriptions, previousSubscriptions) {

                // Validate all changed subscription rows
                var validateItems = [];
                angular.forEach(subscriptionChanges, function(subscription, index) {

                  var currentSubscription = subscription;
                  currentSubscription.validation__ = [];

                  var previousSubscription = {};

                  if (!_.isEmpty(previousSubscriptions) && !_.isEmpty(previousSubscriptions[index])) {
                    previousSubscription = previousSubscriptions[index];
                    previousSubscription.validation__ = [];

                  }

                  if (!_.isEmpty(previousSubscriptions) && !$rootScope.isEqual(angular.copy(currentSubscription),angular.copy(previousSubscription))) {
                        // Clear all validation map entries for row, because we always validate the complete subscription
                        subscriptionValidations = subscriptionValidations || [];
                        subscriptionValidations[index] = {};

                        var validateItem = ValidateItemService.validateItem($scope,
                            $scope.item,
                            subscriptions[index],
                            previousSubscriptions[index],
                            null,
                            subscriptionValidations[index]);
                        validateItems.push(validateItem.$promise);
                        var allValidationKeys = [];
                        validateItem.$promise.then(function(res) {
                            _.forEach(res.validations, function(value, key) {
                                var val_keys = _.map(value, function(v) {
                                    return key + ':' + v.name;
                                });
                                allValidationKeys = allValidationKeys.concat(val_keys);

                            });
                            $scope.subscriptions[index].validation__ = allValidationKeys;
                        });
                    }

                });

                return $q.all(validateItems);
            }

            // Create and start queues for validating and saving changes
            var queueManager = new ItemChangesQueueManager();
            queueManager.createQueue('validateSubscriptionQueue', 600, processValidateSubscription);
            queueManager.createQueue('storeSubscriptionsQueue'); // The 'store' queue only listens to changes, so we can check if subscriptions were modified
            queueManager.start($scope, $scope.subscriptions, 'subscriptions');

            function getCellValidations(row, col) {

                if (!subscriptionValidations) {
                    return [];
                }

                var index = $scope.subscriptions.indexOf(row.entity);
                var validations = subscriptionValidations[index] || {};

                var attributeName = col.field;
                var cellValidations = validations[attributeName] || [];

                return cellValidations;
            }

            // Define validation methods for grid like 'getCellValidationMessages' and 'getRowValidationMessages'.
            ValidateItemService.defineGridValidationMethods($scope, getCellValidations);

            $scope.createSubscriptionTableColumnDefinition = function(channel) {

                var subscriptionAttributes = $scope.getSubscriptionAttributes(channel);
                var columnDefs = [];
                var length = subscriptionAttributes.length;
                var nameColumn = {
                    field: 'name',
                    displayName: $translate.instant('NAME'),
                    headerTooltip: true,
                    cellTooltip: true,
                    enableCellEdit: true,
                    enableCellSelection: true,
                    resizable: true
                };
                columnDefs.push(nameColumn);

                for (var i = 0; i < length; i++) {
                    var attr = subscriptionAttributes[i];
                    var col = {
                        attribute: attr,
                        field: attr.name,
                        headerTooltip: true,
                        cellTooltip: true,
                        enableCellEdit: true,
                        resizable: true
                    };
                    //based on the attribute type different kinds of cell editors are set on the column
                    $rootScope.generateCellEditors(attr, col, $scope, {
                        context: "subscription",
                        emptyDefault: true
                    });
                    columnDefs[i + 1] = col;
                }

                columnDefs.push({
                    field: 'oneTime',
                    displayName: $translate.instant('ONE_TIME'),
                    headerTooltip: true,
                    enableCellEdit: false,
                    cellTemplate: '<div class="datamodels-checkbox-container">' +
                        '<input class="datamodels-checkbox" type="checkbox" ' +
                        'data-ng-model="row.entity.oneTime" ' +
                        'data-ng-checked="row.entity.oneTime" ' +
                        'data-lax-grid-input />' +
                        '</div>'
                });

                columnDefs.push({
                    displayName: $translate.instant('ACTIONS'),
                    headerTooltip: true,
                    name: 'actions',
                    enableCellEdit: false,
                    enableCellSelection: false,
                    allowCellFocus: false,
                    cellClass: 'text-right',
                    headerCellClass: 'text-right',
                    enableColumnResizing: false,
                    enableSorting: false,
                    width: 150,
                    cellTemplate: '<div class="ui-grid-cell-contents">' +
                        '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">' +
                            '<span data-ng-if="grid.appScope.isRowInvalid(row)" class="icon validation-icon" title="Validations" ' +
                                'data-tooltip-append-to-body="true" ' +
                                'data-tooltip-placement="left" ' +
                                'data-tooltip-html-unsafe="{{ grid.appScope.getRowValidationMessages(row) }}" ' +
                                'data-tooltip-trigger="mouseenter">' +
                                '<i class="syncons syncons-error"></i>' +
                                '<span id="{{row.entity.primaryKey__}}" class="notification red">{{row.entity.validation__.length}}</span>' +
                            '</span>' +
                            '<button type="button" class="btn btn-cell action-btn-slot" data-ng-click="grid.appScope.removeRow(row)">' +
                                '<span class="syncons syncons-delete"></span>' +
                            '</button>' +
                        '</div>' +
                    '</div>'
                });

                $scope.columnDefs = columnDefs;
                $scope.gridOptions = {
                    data: 'subscriptions',
                    showSelectionCheckbox: false,
                    enableCellSelection: true,
                    enableRowSelection: true,
                    enableCellEdit: true,
                    enableColumnMenus: false,
                    enableColumnResize: true,
                    multiSelect: false,
                    selectWithCheckboxOnly: false,
                    columnDefs: $scope.columnDefs,
                    rowHeight: 34,
                    enableHorizontalScrollbar: 1, // hide
                    enableVerticalScrollbar: 1, // show when needed
                    onRegisterApi: function(gridApi) {
                        $scope.gridApi = gridApi;
                        gridApi.edit.on.beginCellEdit($scope, function(rowEntity, colDef) {
                            $scope.editedPrimaryKey = rowEntity.primaryKey__;
                        });
                    }
                };
            };

            $scope.createSubscriptionTableColumnDefinition(channel);

            $scope.addRow = function() {
                var newSubscription = {
                    'channelId__': subscriptionDescription.channelId,
                    'category__': subscriptionDescription.category__
                };
                $scope.subscriptions.push(newSubscription);
            };

            $scope.removeRow = function(row) {
                var index = $scope.subscriptions.indexOf(row.entity);
                $scope.subscriptions.splice(index, 1);
            };

            $scope.save = function() {
                $scope.subscriptionDescription.subscriptions = $scope.subscriptions;
                $modalInstance.close($scope.subscriptionDescription);
            };

            $scope.cancel = function() {
                // TODO: Add confirm dialog when subscriptions were changed
                var storeSubscriptionsQueue = queueManager.getQueue('storeSubscriptionsQueue');
                var modified = storeSubscriptionsQueue.hasQueuedItemChanges();

                $modalInstance.dismiss('cancel');
            };

            $scope.$on('$destroy', function() {
                queueManager.stop();
            });

        }
    );
