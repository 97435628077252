angular.module('llax')
    .controller('KeyValueMapsController',
        function($scope, $rootScope, $window, $location, $translate, $timeout, $modal, $dialogs, $q, KeyValueMapRessource, InfiniteScrollHelper, QueryKeyValueMapRessource, growl, uiGridEditConstants, ErrorCode) {

            var KEY_VALUE_MAP = "keyValueMap";

            var LocalStorage = $window.localStorage;

            $scope.entries = [];
            $scope.creating = false;
            $scope.rowEdit = false;
            $scope.editingRow = null;
            $scope.state = {};
            $scope.keyValueMapsLoading = false;

            $scope.columnDefs = [{
                field: 'key',
                displayName: $translate.instant('Key'),
                headerTooltip: true,
                cellTooltip: true,
                cellClass: 'text-center',
                enableCellEdit: true,
                enableCellEditOnFocus: true,
                cellEditableCondition: keyCellEditableCondition,
                width: '*'
            }, {
                field: 'value',
                displayName: $translate.instant('Value'),
                headerTooltip: true,
                cellTooltip: true,
                cellEditableCondition: valueCellEditableCondition,
                width: '*'
            },
            {
                field: 'actions',
                displayName: '',
                enableCellSelection: false,
                allowCellFocus: false,
                enableCellEdit: false,
                cellClass: 'text-right',
                headerCellClass: 'text-right',
                enableColumnResizing: false,
                enableSorting: false,
                pinnedRight: true,
                width: 60,
                cellTemplate: '<div class="ui-grid-cell-contents">' +
                    '  <div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">' +
                    '<button data-ng-if="!row.entity.editing" type="button" class="btn btn-cell action-delete action-btn-slot" tabindex="-1" title="Delete Entry" data-ng-click="grid.appScope.deleteEntry(row.entity)" data-ng-disabled="grid.appScope.rowEdit"> ' +
                    '<i class="syncons syncons-delete"></i> ' +
                    '</button>' +
                    '<button data-ng-if="row.entity.editing" type="button" class="btn btn-cell action-save action-btn-slot" tabindex="-1" title="Save Entry" data-ng-click="grid.appScope.saveEntry(row.entity)"> ' +
                    '<i class="syncons syncons-checkmark"></i> ' +
                    '</button>' +
                    '<button data-ng-if="row.entity.editing" type="button" class="btn btn-cell action-cancel action-btn-slot" tabindex="-1" title="Cancel" data-ng-click="grid.appScope.cancel(row.entity)"> ' +
                    '<i class="glyphicon glyphicon-remove"></i> ' +
                    '</button>' +
                    '  </div>' +
                    '</div>'
            }];

            $scope.gridOptions = {
                data: 'entries',
                rowHeight: 34,
                enableColumnMenus: false,
                enableSorting: false,
                columnDefs: $scope.columnDefs,
                infiniteScrollUp: false,
                infiniteScrollDown: true
            };

            $scope.queryParams = {
                limit: 20
            };

            // Accessor to get/set entries, used in 'InfiniteScrollHelper'
            function entriesAccessor(entries) {
                if (!_.isUndefined(entries)) {
                    $scope.entries = _.map(entries, function(entry) {
                        return { key:entry.key, value:entry.value };
                    });
                }
                $scope.keyValueMapsLoading = false;
                return $scope.entries;
            }

            // Error handler used in 'InfiniteScrollHelper'
            function errorHandler(errorStatus, errorData) {
                var errorCode = $rootScope.getErrorCode({ data: errorData });
                if (errorCode !== ErrorCode.UNSPECIFIED) {
                    var errorMessage = $rootScope.getErrorMessage({ data: errorData });
                    growl.error(errorMessage, {
                        ttl: 3000
                    });
                }
                $scope.status = errorStatus;
            }

            function keyCellEditableCondition () {
                var cell = $scope.gridApi.cellNav.getFocusedCell();

                if(cell.row.entity.key == ""){
                    return true;
                }
                return false;
            }

            function valueCellEditableCondition () {
                var cell = $scope.gridApi.cellNav.getFocusedCell();

                if ($scope.rowEdit && $scope.creating && cell.row.entity.editing) {
                    return true;
                }
                if ($scope.rowEdit && cell.row.entity.editing) {
                    return true;
                }
                if ($scope.rowEdit) {
                    return false;
                }

                return true;
            }

            function saveState () {
                var map = $scope.activeKeyValueMap;
                var selection = $scope.gridApi.selection.getSelectedRows();
                var row = null;
                if ($scope.rowEdit && $scope.editingRow) {
                    row = $scope.editingRow;
                }
                $scope.state = {map: map, row: row, selection: selection};
            }

            function restoreState () {
                if ($scope.activeKeyValueMap != $scope.state.map) {
                    return;
                }

                $timeout(function () {
                    if ($scope.state) {
                        var row = $scope.state.row;
                        var selection = $scope.state.selection;
                        _.forEach(selection, function (row) {
                            $scope.gridApi.selection.selectRow(_.find($scope.entries, {
                                key: row.key
                            }));
                        });

                        if (row) {
                            var newCell = _.find($scope.entries, {
                                key: row.key
                            });
                            newCell.editing = true;
                            newCell.oldValue = row.oldValue;
                        }
                    }
                });
            }

            function activateInitialMap() {
                KeyValueMapRessource.getAllMapNames(function(keys) {
                    $scope.keys = _.map(keys, function(k) {
                        return { name : k };
                    });
                    if ($scope.keys) {
                        var storedActiveMap = LocalStorage.getItem(KEY_VALUE_MAP);

                        var existingActiveMap = _.find($scope.keys, {name: storedActiveMap});

                        if (existingActiveMap) {
                            $scope.selectKeyValueMap(existingActiveMap);
                        } else {
                            $scope.selectKeyValueMap($scope.keys[0]);
                        }
                    }
                });

            }

            // The 'infinite scroll' helper object
            // Simple set the 'resource' object, scope and data and gridApi variable, and call 'addToGridOptions' to enable 'infinite scrolling'
            $scope.scrollHelper = new InfiniteScrollHelper(QueryKeyValueMapRessource, $scope, entriesAccessor, 'gridApi', errorHandler);
            $scope.scrollHelper.addToGridOptions($scope.gridOptions);

            $scope.gridHeight = function () {
                var w = angular.element($window);
                if (w.width() < 992) {
                    return;
                }
                var footer = angular.element('footer');
                var boxContent = angular.element('.box-content');
                var content = angular.element('#content');
                var boxContentPadding = parseInt(boxContent.css('padding-bottom'), 10);
                var contentPadding = parseInt(content.css('padding-bottom'), 10);
                var offset = angular.element("#key-value-maps-grid").offset().top + footer.innerHeight() + boxContentPadding + contentPadding;
                var newHeight = w.height() - offset;

                $scope.gridApi.grid.gridHeight = newHeight;

                return {
                   height: newHeight + "px"
                };
            };

            angular.element($window).bind('resize', function(){
                $scope.gridHeight();
                $scope.$digest();
              });

            $scope.selectKeyValueMap = function (key) {
                if (!key || !key.name) {
                    return;
                }

                if($scope.rowEdit || $scope.creating) {
                    var promise = $scope.checkUnsavedChanges();
                    promise.then(function () {
                        $scope.activeKeyValueMap = key.name;
                        $scope.activeKey = key;
                        $scope.keyValueMapsLoading = true;
                        $scope.scrollHelper.reload(_.merge({
                            map: key.name
                        }, $scope.queryParams));

                        $scope.creating = false;
                        $scope.rowEdit = false;
                    });
                } else {
                    $scope.activeKeyValueMap = key.name;
                    $scope.activeKey = key;
                    $scope.keyValueMapsLoading = true;
                    $scope.scrollHelper.reload(_.merge({
                        map: key.name
                    }, $scope.queryParams));
                }
                $scope.state = {};
                $scope.gridApi.selection.clearSelectedRows();
                LocalStorage.setItem(KEY_VALUE_MAP, $scope.activeKeyValueMap);
            };

            $scope.saveEntry = function (row) {
                KeyValueMapRessource.updateValue({
                    map: $scope.activeKeyValueMap,
                    key: row.key
                }, row.value ,function (result) {
                  $scope.keyValueMapsLoading = true;
                    $scope.scrollHelper.reload(_.merge({ map: $scope.activeKeyValueMap }, $scope.queryParams));
                    growl.success('Updated Entry');
                    row.editing = false;
                    $scope.rowEdit = false;
                    $scope.creating = false;
                }, function (response) {
                    growl.error("Error updating Entry");
                });
            };

            $scope.deleteEntry = function (row) {
                var map = $scope.activeKeyValueMap;
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-key-value-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = [row];
                            return data;
                        }
                    }
                });
                modalInstance.result.then(function() {
                    KeyValueMapRessource.delete({
                        map: map,
                        key: row.key
                    },function (result) {
                      $scope.keyValueMapsLoading = true;
                        $scope.scrollHelper.reload(_.merge({ map: $scope.activeKeyValueMap }, $scope.queryParams));
                        growl.success('Deleted Entry from ' + map);
                    }, function (response) {
                        growl.error("Error deleting Entry");
                    });
                });
            };

            $scope.deleteSelectedEntries = function() {

                var selectedEntries = $scope.gridApi.selection.getSelectedRows();

                if (_.isEmpty($scope.entries) || _.isEmpty(selectedEntries)) {
                    growl.warning("No Entries selected");
                    return false;
                }

                var map = $scope.activeKeyValueMap;
                var selection = _.map(selectedEntries, function(entry) {
                    return entry.key;
                });

                var modalInstance = $modal.open({
                    templateUrl: 'tpl/confirm-delete-key-value-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            var data = selectedEntries;
                            return data;
                        }
                    }
                });
                modalInstance.result.then(function() {
                    KeyValueMapRessource.delete({
                        map: map,
                        keys: selection
                    },function (result) {
                      $scope.keyValueMapsLoading = true;
                        $scope.scrollHelper.reload(_.merge({ map: $scope.activeKeyValueMap }, $scope.queryParams));
                        growl.success('Deleted ' + selection.length + ' Entries from ' + map);
                    }, function (response) {
                        growl.error("Error deleting Entries");
                    });
                });
            };

            $scope.createEntry = function() {
                $scope.creating = true;
                $scope.entries.unshift({ key: '', value: '' });

                $timeout(function () {
                    $scope.gridApi.cellNav.scrollToFocus($scope.entries[0],$scope.columnDefs[0]);
                });
            };

            $scope.cancel = function(row) {
                if (!$scope.creating) {
                    row.value = row.oldValue;
                } else {
                    $scope.entries = _.remove($scope.entries, function(entry) {
                        return entry.$$hashKey != row.$$hashKey;
                    });
                    $scope.creating = false;
                }

                $scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
            };

            $scope.checkUnsavedChanges = function() {
                var deffered = $q.defer();
                var row = $scope.editingRow;
                if (!row && !$scope.creating) {
                    return deffered.reject();
                }

                if ($scope.rowEdit && row.oldValue != row.value) {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        deffered.resolve();
                    }, function() {
                        deffered.reject();
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            };

            $scope.search = function(prefix) {
             $scope.queryParams.prefix = prefix;
             $scope.keyValueMapsLoading = true;
             $scope.scrollHelper.reload(_.merge({ map: $scope.activeKeyValueMap }, $scope.queryParams));
            };

            $scope.$on(uiGridEditConstants.events.END_CELL_EDIT, function(event) {
                var row = $scope.editingRow;
                if (!$scope.creating && row.value == row.oldValue) {
                    $scope.cancel(row);
                }
            });

            $scope.$on(uiGridEditConstants.events.CANCEL_CELL_EDIT, function(event) {
                if (!$scope.creating) {
                    var newRow = _.find($scope.entries, {key: $scope.editingRow.key});
                    if (newRow) {
                        newRow.editing = false;
                    }
                    $scope.rowEdit = false;
                }
            });

            $scope.$on('$locationChangeStart', function(event, next) {
                if($scope.rowEdit || $scope.creating) {
                    event.preventDefault();
                    var promise = $scope.checkUnsavedChanges();
                    promise.then(function () {
                        $scope.rowEdit = false;
                        $scope.creating = false;
                        var url = _.replace($location.absUrl(), $location.path(), "");
                        url = _.replace(next, url, "");
                        $location.url(url);
                    });
                }
            });

            $rootScope.$on("infiniteScrollLoadMore",function() {
                $scope.keyValueMapsLoading = true;
                saveState();
            });

            $rootScope.$on("infiniteScrollDataLoaded",function() {
                restoreState();
            });

            (function init() {
                activateInitialMap();
            })();
        }
    );
