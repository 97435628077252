angular.module('llax')
    .controller('EditorItemHierarchyController',
        function($rootScope, $scope, $location, $document, $window, $filter, $controller, ItemResource, ItemHierarchyResource, $log, $q) {

            var dataModelLayoutAttribute = getDataModelLayoutAttributes();

            $scope.itemHierarchyLayoutMapping = [];
            $scope.selectedItem = $scope.item;

            /** vis.js network-related properties  */
            var network = null;
            var nodes = [];
            var edges = [];

            var height = "calc(100vh - 226px  - 70px)";

            var selectedNode;
            var items = new Map();
            var nodeSet;
            var options = {
                nodes: {
                    shape: 'box',
                    size: 16,
                    font: {
                        size: 18,
                        color: 'black',
                        multi: true
                    },
                    borderWidth: 2,
                    margin: { top: 20, right: 20, bottom: 20, left: 20 }
                },
                edges: {
                    width: 2,
                    length: 2,
                    font: {
                        background: 'white',
                        size: 20
                    }
                },
                width: '100%',
                height: height,
                interaction: {
                    dragNodes: false,
                    dragView: true,
                },
                layout: {
                    hierarchical: {
                        direction: 'UD',
                        nodeSpacing: 300,
                        blockShifting: false,
                        sortMethod: 'directed'
                    }
                },
                physics:{
                    enabled: false
                }

            };

            // use "hierarchy" layout for displaying item info
            function getDataModelLayoutAttributes() {
                var attributesMap = new Map();
                var attributes = $rootScope.dataModel.filteredLayoutAttributes("hierarchy_item_info");
                $rootScope.prepareAttributes(attributes);
                _.forEach(attributes,function(h_attrs) {
                    attributesMap.set(h_attrs.name,h_attrs);
                });
                return attributesMap;
            }

            getItemHierarchy = function() {

                var itemToViewHierarchy = $rootScope.cleanupItem($scope.selectedItem);
                itemToViewHierarchy = $rootScope.stringifyDeepValues(itemToViewHierarchy);

                ItemHierarchyResource.post( {hierarchyName: $scope.currentHierarchy()},
                    itemToViewHierarchy,
                function(itemHierarchyResult) {
                    var jsonResult = itemHierarchyResult.toJSON();
                    $log.debug(jsonResult);
                    nodeSet = new Set();

                    for (var key in jsonResult) {
                        var val = jsonResult[key];

                        if (key == "items"){
                            for (var i=0; i<val.length; i++){
                                // check the type of item to accomdate
                                // items with only "primary key" as string
                                var itemObj = val[i];
                                if (typeof itemObj === 'string'){
                                    nodeSet.add(
                                        {
                                            "primaryKey": itemObj
                                        }
                                    );
                                } else if ( itemObj !== null && typeof itemObj === 'object'){
                                    nodeSet.add(itemObj);
                                } else {
                                    $log.warn("Unrecognised format of items!");
                                }
                            }
                        } else if (key == "relations"){
                            for (var j=0; j<val.length; j++) {
                                mapRelationToEdges(val[j]);
                            }
                        }
                    }

                    // fetch items and init graphs
                    fetchItems(nodeSet);
                }
                );
            };

            // init vis.js-recognisble edges from a list of edges
            mapRelationToEdges = function(relation) {
                if (relation.parentItem == null||
                    relation.childItem == null) {
                        $log.warn("The relation data is missing one or more " +
                        "of the following attributes: (parentItem, childItem)!");
                    }
                else {
                    var edge = {
                        from: relation.parentItem,
                        to: relation.childItem
                    };

                    if (relation.relationValue != null && relation.relationAttribute != null){
                        edge.label = relation.relationValue;
                        edge.title = relation.relationAttribute;
                    }
                    edges.push(edge);
                }
            };

            fetchItemsCallback = function() {
                $log.debug("Size of items ", items.size);
                // Rearranging the items by hierarchies
                var itemsByOrder = new Map();
                nodeSet.forEach(function(node){
                    if (node.primaryKey) {
                        itemsByOrder.set(node.primaryKey,items.get(node.primaryKey));
                    }
                });
                mapItemsToNodes(itemsByOrder);
                initGraph();
            };

            fetchItems = function(itemSet) {
                var promiseChain = [];
                $log.debug("Fetching all initial items for the graph...");
                if (!_.isUndefined(itemSet)) {
                    itemSet.forEach(function(item) {
                        promiseChain.push(asyncFetchItem(item).$promise);
                    });
                }
                $q.all(promiseChain)
                    .then(fetchItemsCallback);
            };

            asyncFetchItem = function(itemObject) {
                var itemKey = itemObject.primaryKey;
                var deferred = $q.defer();

                return ItemResource.get({}, {
                        'primaryKey': itemKey
                    },
                    function(itemResult) {
                        for (var key in itemResult) {
                            var val = itemResult[key];
                            if (_.isString(val)) {
                                try {
                                    if (_.isObject(val)) {
                                        itemResult[key] = JSON.parse(val);
                                    } else {
                                        itemResult[key] = val;
                                    }
                                } catch (e) {}
                            }
                        }
                        itemResult.level = itemObject.level;
                        $log.debug(itemResult);
                        if (itemResult.primaryKey__ == $scope.item.primaryKey__) {
                            // deep copy of current item to prevent unwanted changes on the item
                            var tempCurrentItem = JSON.parse(JSON.stringify($scope.item));
                            tempCurrentItem.level = itemObject.level;
                            items.set($scope.item.primaryKey__, tempCurrentItem);
                        } else {
                            items.set(itemResult.primaryKey__, itemResult);
                        }
                        deferred.resolve(itemResult);
                    });
                };

            // init vis.js-recognisble nodes from a list of Items
            mapItemsToNodes = function(items) {
                items.forEach(function(item, itemKey, map) {
                    var labelString = $rootScope.getItemTitle("hierarchy", item);
                    // default label
                    if (labelString == "") {
                        labelString = itemKey.substring(0, itemKey.indexOf(':'));
                        labelString = (labelString.length==0)? itemKey : labelString;
                        labelString = labelString+"\n"+$rootScope.translateCategory(item.category__);
                    }
                    var node = {
                        id: itemKey,
                        label: labelString
                    };
                    if (item.level !== null) {
                        node.level = item.level;
                    }
                    nodes.push(node);
                });
            };

            // updates the current item-info panel view upon selection of a node
            updateitemHierarchyLayoutMapping = function() {
                $scope.itemHierarchyLayoutMapping = [];
                dataModelLayoutAttribute.forEach(function(value, key, map) {
                    // FIXME: use another way to detect various data types...
                    if (!_.isUndefined($scope.selectedItem[key])) {
                        var attributeData = {};
                        attributeData.attribute = value;
                        attributeData.value = $scope.selectedItem[key];
                        $scope.itemHierarchyLayoutMapping.push(attributeData);
                    }
                });
                $log.debug($scope.itemHierarchyLayoutMapping);
            };

            // called whenever a node is selected
            onNodeChosen = function(itemKey) {
                var chosenItem = items.get(itemKey);
                if (chosenItem == null) {
                    $log.warn("You just selected a node " + itemKey + " which cannot be found!");
                    return;
                }
                $scope.selectedItem = chosenItem;
                if (itemKey == $scope.item.primaryKey__){
                    // update the item accordingly for unsaved changes
                    tempCurrentItem = JSON.parse(JSON.stringify($scope.item));
                    tempCurrentItem.level = chosenItem.level;
                    items.set($scope.item.primaryKey__, tempCurrentItem);
                }
                updateitemHierarchyLayoutMapping();
            };

            initGraph = function() {
                var container = document.getElementById('visualization');
                var data = {
                    nodes: nodes,
                    edges: edges
                };
                network = new vis.Network(container, data, options);
                network.on("click", function (params) {
                    params.event = "[original event]";
                    var selectedNodeKey = this.getNodeAt(params.pointer.DOM);

                    if (selectedNodeKey == undefined){
                        return;
                    }
                    $log.debug('You have clicked on ' + this.getNodeAt(params.pointer.DOM));
                    onNodeChosen(selectedNodeKey);
                });

                // programatically select the first node to be the current item
                if (nodes.length != 0){
                    network.selectNodes([$scope.item.primaryKey__]);
                    onNodeChosen($scope.item.primaryKey__);
                }
            };

            renderItemHierarchy = function(){
                $log.info("Switched to hierarchy view '" + $scope.currentHierarchy() + "'");
                getItemHierarchy();
            };

            (function init() {
                renderItemHierarchy();
            })();
    });
