angular.module('llax.directives')
    .directive('assetFoldersTree', function(AssetFoldersResource, AssetFoldersService) {
        return {
            strict: 'E',
            scope: {
                traverseToFolder: '=',
                currentlyViewedPath: '=',

                // The following will be passed to child 'Folder':
                onItemMovedSuccessfully: '=',
                onAllItemsMovedSuccessfully: '=',
                onItemMovedError: '='
            },
            templateUrl: 'tpl/asset-folders/directive-asset-folders-tree.tpl.html',
            controller: function($scope) {
                $scope.LINK_TYPE_FOLDER = AssetFoldersService.LINK_TYPE_FOLDER;
                $scope.LINK_TYPE_ASSET = AssetFoldersService.LINK_TYPE_ASSET;
                $scope.decodeFilename = AssetFoldersService.decodeFilename;

                $scope.rootFolder = [];

                $scope.load = function() {
                    AssetFoldersResource().getRootFolderContents(function (response) {

                        if (_.isEmpty($scope.rootFolder)) {
                            $scope.rootFolder = AssetFoldersService.sortAssetItems(response);
                        } else {
                            var currentItems = $scope.rootFolder;
                            var onlyNewItems = _.differenceBy(response, currentItems, function(_item) {
                                return _item.name;
                            });
                            var onlyDeletedItems = _.differenceBy(currentItems, response, function(_item) {
                                return _item.name;
                            });

                            // Clear out deleted items
                            currentItems = _.filter(currentItems, function(_item) {
                                return !_.some(onlyDeletedItems, { name: _item.name});
                            });

                            // Append new items
                            currentItems = _.concat(currentItems, onlyNewItems);
                            $scope.rootFolder = AssetFoldersService.sortAssetItems(currentItems);
                        }
                    });
                };

                $scope.$on(AssetFoldersService.EVENT_CHILDS_UPDATED, function(event, data) {
                    // One of the child folders has been deleted or created.
                    if (data.path === '') {
                        // Trigger a refresh
                        $scope.load();
                    }
                });

                $scope.load();
            }
        };
    })
    .directive('folder', function($timeout, AssetFoldersResource, AssetFoldersService, RecursiveDirectiveHelper) {
        return {
            strict: 'E',
            scope: {
                item: '=',
                parentPath: '@',
                traverseToFolder: '=',
                currentlyViewedPath: '=',
                onItemMovedSuccessfully: '=',
                onAllItemsMovedSuccessfully: '=',
                onItemMovedError: '='
            },
            templateUrl: 'tpl/asset-folders/directive-folder-tree-entry.tpl.html',
            compile: function(element) {
                return RecursiveDirectiveHelper.compile(element);
            },
            controller: function($scope) {
                $scope.LINK_TYPE_FOLDER = AssetFoldersService.LINK_TYPE_FOLDER;
                $scope.decodeFilename = AssetFoldersService.decodeFilename;

                $scope.getCurrentPath = function() {
                    if (_.isEmpty($scope.parentPath)) {
                        return $scope.item.name;
                    } else {
                        return $scope.parentPath + '/' + $scope.item.name;
                    }
                };

                $scope.openFolder = function($event, item) {
                    if($event.stopPropagation) {
                        $event.stopPropagation();
                    }

                    item.isOpen = true;

                    var path = $scope.getCurrentPath();

                    AssetFoldersResource(path).getFolderContents(function (response) {
                        $timeout(function() {

                            if (_.isEmpty(item.childItems)) {
                                item.childItems = AssetFoldersService.sortAssetItems(response);
                            } else {
                                var currentItems = item.childItems;
                                var onlyNewItems = _.differenceBy(response, currentItems, function(_item) {
                                    return _item.name;
                                });
                                var onlyDeletedItems = _.differenceBy(currentItems, response, function(_item) {
                                    return _item.name;
                                });

                                // Clear out deleted items
                                currentItems = _.filter(currentItems, function(_item) {
                                    return !_.some(onlyDeletedItems, { name: _item.name});
                                });

                                // Append new items
                                currentItems = _.concat(currentItems, onlyNewItems);
                                item.childItems = AssetFoldersService.sortAssetItems(currentItems);
                            }
                        }, 0);
                    });
                };

                $scope.viewFolder = function($event, item) {
                    $scope.openFolder($event, item);
                    $scope.traverseToFolder($scope.parentPath, item.name);
                };

                $scope.closeFolder = function($event, item) {
                    $event.stopPropagation();
                    item.isOpen = false;
                };

                $scope.$watch('currentlyViewedPath', function(newPath, oldPath) {
                    if ($scope.getCurrentPath() === newPath) {
                        $scope.item.active = true;
                        $scope.openFolder({}, $scope.item);
                    } else {
                        $scope.item.active = false;
                    }
                });

                $scope.$on(AssetFoldersService.EVENT_UPDATED, function(event, data) {
                    // Current rendered folder has been updated, and currently
                    // we only support updating the name.
                    if (data.path === $scope.getCurrentPath()) {
                        $scope.item.name = data.newName;
                    }
                });

                $scope.$on(AssetFoldersService.EVENT_CHILDS_UPDATED, function(event, data) {
                    // One of the child folders has been deleted or created.
                    if (data.path === $scope.getCurrentPath()) {
                        // Trigger a refresh
                        $scope.openFolder({}, $scope.item);
                    }
                });

                $scope.onItemDragStart = function($event) {
                    // Note: this will be called on the DRAGGED item.
                    var parentPath = AssetFoldersService.getParentFolderPath($scope.getCurrentPath());

                    var draggedItems = [$scope.item];
                    AssetFoldersService.prepareDragAndDropEvent($event, draggedItems, parentPath);
                };

                $scope.onItemDragFinished = function(droppedItems, droppedOnItem) {
                    // Note: this will be called on the item being DROPPED on.

                    var parentPath = AssetFoldersService.getParentFolderPath($scope.getCurrentPath());

                    AssetFoldersService.handleDropEventAsync(droppedItems, droppedOnItem, parentPath, $scope.onItemMovedSuccessfully)
                        .then(function(){
                            $scope.multiSelectedItems = [];
                            $scope.onAllItemsMovedSuccessfully($scope.getCurrentPath());
                        })
                        .catch(function(error) {
                            $scope.onItemMovedError(error);
                        });
                };
            }
        };
    })
    .filter('onlyFolders', function(AssetFoldersService) {
        // Although we are not mutating the filtered collection, we have to
        // use _.memoize to cache the objects in order to prevent AngularJS from going into a mad loop,
        // where it thinks the filter is returning a new result on each $digest.
        return _.memoize(function(items) {
            return _.filter(items, function(item) {
                return item.linkType === AssetFoldersService.LINK_TYPE_FOLDER;
            });
        });
    });
