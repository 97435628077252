angular.module('llax.directives')
    .directive('codeBlockMappingParameter', function($timeout) {
        return {
            strict: 'E',
            scope: {
                bindModel: '=ngModel',
                codeMirrorConfigs: '='
            },
            template: '<div data-ui-codemirror="{ onLoad: onEditorLoaded }" data-ng-model="bindModel" data-ui-codemirror-opts="codeMirrorConfigs"></div>',
            controller: function($scope) {
                $scope.onEditorLoaded = function(editor) {
                    $timeout(function() {
                        editor.refresh();
                        editor.setCursor({ line: 1, ch: 0 });
                    }, 0);
                };
            }
        };
    })
    .directive('textMappingParameter', function() {
        return {
            strict: 'E',
            scope: {
                bindModel: '=ngModel',
                defaultValue: '='
            },
            template: '<input placeholder="{{::defaultValue}}" type="text" class="form-control" data-ng-model="bindModel" />',
        };
    })
    .directive('multiTextMappingParameter', function() {
        return {
            strict: 'E',
            scope: {
                bindModel: '=ngModel'
            },
            template:
                '<ui-select ' +
                    'data-ng-model="tempModel" ' +
                    'data-append-to-body="true" ' +
                    'data-theme="bootstrap" ' +
                    'data-multiple ' +
                    'data-tagging="true" ' +
                    'data-tagging-label="" ' +
                    'data-on-select="onParamSelected($select.selected)" ' +
                    'data-on-remove="onParamSelected($select.selected)" ' +
                    'class="ui-grid-ui-select">' +
                        '<ui-select-match>' +
                            '{{::$item}}' +
                        '</ui-select-match>' +
                        '<ui-select-choices repeat="item as item in bindModel">' +
                            '<div>{{::item}}</div>' +
                        '</ui-select-choices>' +
                '</ui-select>',
            controller: function($scope) {
                $scope.tempModel = {};

                $scope.onParamSelected = function(selected) {
                    if (!_.isNil(selected)) {
                        $scope.bindModel = selected;
                    } else {
                        $scope.bindModel = null;
                    }
                };

                $scope.onChange = function() {
                    $scope.tempModel = $scope.bindModel;
                };

                $scope.$watch('options', $scope.onChange);
                $scope.$watch('bindModel', $scope.onChange);
            }
        };
    })
    .directive('selectMappingParameter', function() {
        return {
            strict: 'E',
            scope: {
                bindModel: '=ngModel',
                options: '=',
            },
            template:
                '<ui-select ' +
                    'data-ng-model="tempModel" ' +
                    'data-append-to-body="true" ' +
                    'data-theme="bootstrap" ' +
                    'data-on-select="onParamSelected($select.selected)" ' +
                    'data-on-remove="onParamSelected($select.selected)" ' +
                    'class="ui-grid-ui-select">' +
                        '<ui-select-match>' +
                            '{{$select.selected.name}}' +
                        '</ui-select-match>' +
                        '<ui-select-choices repeat="option in options| filter: $select.search">' +
                            '<div ng-bind-html="option.translatedName || option.name"></div>' +
                        '</ui-select-choices>' +
                '</ui-select>',
            controller: function($scope) {
                $scope.tempModel = {};

                $scope.onParamSelected = function(selected) {
                    if (!_.isNil(selected)) {
                        $scope.bindModel = selected.value;
                    } else {
                        $scope.bindModel = null;
                    }
                };

                $scope.onChange = function() {
                    $scope.tempModel = _.find($scope.options, { value: $scope.bindModel });
                };

                $scope.$watch('options', $scope.onChange);
                $scope.$watch('bindModel', $scope.onChange);
            }
        };
    })
    .directive('multiSelectMappingParameter', function() {
        return {
            strict: 'E',
            scope: {
                bindModel: '=ngModel',
                options: '='
            },
            template:
                '<ui-select ' +
                    'data-ng-model="tempModel" ' +
                    'data-append-to-body="true" ' +
                    'data-theme="bootstrap" ' +
                    'data-multiple ' +
                    'data-on-select="onParamSelected($select.selected)" ' +
                    'data-on-remove="onParamSelected($select.selected)" ' +
                    'class="ui-grid-ui-select">'+
                        '<ui-select-match>' +
                            '{{$item.name}}' +
                        '</ui-select-match>' +
                        '<ui-select-choices repeat="option in options | filter: $select.search">' +
                            '<div ng-bind-html="option.translatedName || option.name"></div>' +
                        '</ui-select-choices>' +
                '</ui-select>',
            controller: function($scope) {
                $scope.tempModel = {};

                $scope.onParamSelected = function(selected) {
                    $scope.bindModel = _.map(selected, function(entry) { return entry.value; });
                };

                $scope.onChange = function() {
                    if (_.isArray($scope.bindModel)) {
                        $scope.tempModel =
                            _.intersectionWith(
                                $scope.options,
                                $scope.bindModel,
                                function(firstEntry, secondEntry) {
                                    return firstEntry.value === secondEntry;
                                }
                            );
                    }
                };

                $scope.$watch('options', $scope.onChange);
                $scope.$watch('bindModel', $scope.onChange);
            }
        };
    })
    .directive('booleanMappingParameter', function() {
        return {
            strict: 'E',
            scope: {
                paramName: '=',
                bindModel: '=ngModel'
            },
            template:
                '<input class="param-radio" id="{{::paramName}}_true_radio_btn" name="{{::paramName}}" type="radio" data-ng-value="true" data-ng-model="bindModel" />' +
                '<label class="param-radio-label" for="{{::paramName}}_true_radio_btn" data-translate>True</label>' +
                '<input class="param-radio" id="{{::paramName}}_false_radio_btn" name="{{::paramName}}" type="radio" data-ng-value="false" data-ng-model="bindModel" />' +
                '<label class="param-radio-label" for="{{::paramName}}_false_radio_btn" data-translate>False</label>'
        };
    });