angular.module('llax.services')
    .service('TemplateMappingService', function($log, $rootScope) {

        var RUBY_CONTENT_TYPES = [
            'application/x-ruby',
            'text/ruby',
            'ruby'
        ];

        this.PARAM_TYPES = {
            BOOLEAN: 'Boolean',
            CODE: 'Code',
            MULTI_SELECT: 'MultiSelect',
            MULTI_TEXT: 'MultiText',
            SELECT: 'Select',
            TEXT: 'Text',
        };

        this.getFilteredLayoutAttributes = function() {
            return _.map($rootScope.dataModel.filteredLayoutAttributes('mapping'), function(attr) {
                return {
                    name: attr.label,
                    translatedName: $rootScope.translateAttribute(attr.label),
                    value: attr.name
                };
            });
        };

        this.getAllModelAttributes = function() {
            return _.map($rootScope.dataModel.allAttributes(), function(attr) {
                return {
                    name: attr.label,
                    translatedName: $rootScope.translateAttribute(attr.label),
                    value: attr.name
                };
            }).filter(function(attr) {
                return !_.isNil(attr.name);
            });
        };

        /*
        * Returns the mapping function type based on its parameters types:
        * 'code': A code-based mapping function, if containing only one param with `contentType` `ruby`.
        * 'simple-mappings': Simple mappings, if containing only one param with `selectFrom` `simple-mappings`.
        * 'default': For everything else.
        */
        this.getMappingFunctionType = function(mappingFunctionDefinition) {

            // TODO: Don't just check the first parameter!
            // Not for 'contentType' nor for 'selectFrom'!
            // Parameters can be defined at ANY position!
            if (!_.isEmpty(mappingFunctionDefinition.parameters) && mappingFunctionDefinition.parameters.length === 1) {

                if (_.includes(RUBY_CONTENT_TYPES, mappingFunctionDefinition.parameters[0].contentType)) {
                    return 'code';
                } else if (!_.isEmpty(mappingFunctionDefinition.parameters[0].selectFrom) &&
                           this.parseSelectFrom(mappingFunctionDefinition.parameters[0]) === 'simple_mappings') {
                    return 'simple_mappings';
                }

            }

            return 'default';
        };

        this.getParameterType = function(parameter) {
            if (_.includes(RUBY_CONTENT_TYPES, parameter.contentType)) {
                return this.PARAM_TYPES.CODE;
            }

            if (!_.isEmpty(parameter.selectFrom)) {
                if (parameter.selectMultiple) {
                    return this.PARAM_TYPES.MULTI_SELECT;
                } else {
                    return this.PARAM_TYPES.SELECT;
                }
            }

            switch (parameter.type) {
                case 'String':
                    if (parameter.selectMultiple) {
                        return this.PARAM_TYPES.MULTI_TEXT;
                    } else {
                        return this.PARAM_TYPES.TEXT;
                    }
                break;
                case 'Boolean':
                    return this.PARAM_TYPES.BOOLEAN;

                default:
                    return this.PARAM_TYPES.TEXT;
            }
        };

        this.parseSelectFrom = function(parameter) {
            if (!_.isEmpty(parameter.selectFrom) && !_.isEmpty(parameter.selectFrom[0]) && parameter.selectFrom[0] === 'object') {
               return parameter.selectFrom[1];
            } else {
                $log.error('Error parsing parameter\'s source selectFrom array', parameter);
                return null;
            }
        };

        this.getSimpleMappingParameterName = function(mappingFunctionDefinition) {
            var _this = this;
            var simpleMappingParameter = _.find(mappingFunctionDefinition.parameters, function(parameter) {
                return !_.isNil(parameter.selectFrom) && _this.parseSelectFrom(parameter) === 'simple_mappings';
            });

            if (!_.isNil(simpleMappingParameter)) {
                return simpleMappingParameter.name;
            } else {
                return null;
            }
        };

        /*
        * Returns the A1 notation location from the location string, which is formatted as:
        * SHEET_NAME!A1_NOTATION_LOCATION
        */
        this.getA1NotationFromLocationString = function(location) {
            var stringParts = location.split('!');
            if (stringParts.length == 2) {
                return stringParts[1];
            } else {
                $log.warn('getA1NotationFromLocationString: wasn\'t able to get location from location string:' + location);
                return location;
            }
        };

        /*
        * Returns the sheet name from the location string, which is formatted as:
        * SHEET_NAME!A1_NOTATION_LOCATION
        */
        this.getSheetNameFromLocationString = function(location) {
            var stringParts = location.split('!');
            if (stringParts.length == 2) {
                return stringParts[0];
            } else {
                $log.warn('getSheetNameFromLocationString: wasn\'t able to get sheet name from location string:' + location);
                return null;
            }
        };

        /*
        * Converts a column index to an A1 notation, for example: column index 27 -> AA
        */
        this.convertToA1Notation = function(index) {
            index = index - 1;
            var orderA = 'A'.charCodeAt(0);
            var orderZ = 'Z'.charCodeAt(0);
            var length = orderZ - orderA + 1;

            var string = "";
            while(index >= 0) {
                string = String.fromCharCode(index % length + orderA) + string;
                index = Math.floor(index / length) - 1;
            }
            return string;
        };

        /*
        * Converts a cell location in A1 notation to its individual row and column indices.
        */
        this.convertFromA1Notation = function(cellLocation) {
            var match = cellLocation.match(/(^[A-Z]+)|([0-9]+$)/gm);

            if (match.length != 2) {
                throw new Error('convertFromA1Notation: Invalid cell location was provided.');
            }

            var colA1 = match[0];
            var rowA1 = match[1];

            return {
                row: rowA1ToIndex(rowA1),
                col: colA1ToIndex(colA1)
            };
        };

        function colA1ToIndex(colA1) {
            if (typeof colA1 !== 'string' || colA1.length > 2) {
                throw new Error("convertFromA1Notation: Invalid cell location was provided.");
            }

            var A = "A".charCodeAt(0);

            var number = colA1.charCodeAt(colA1.length-1) - A;
            if (colA1.length == 2) {
                number += 26 * (colA1.charCodeAt(0) - A + 1);
            }
            return number;
        }

        function rowA1ToIndex(rowA1) {
            return rowA1 - 1;
        }
    });
