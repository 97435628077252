angular.module('llax')
    .controller('TemplateMappingSelectorController',
    function(ExportMappingResource, ExportMappingService, $modalInstance, $rootScope, $scope, data) {

        $scope.templates = [];
        $scope.selectedTemplateCustomAttributes = [];
        $scope.customValues = {};

        $scope.form = {
            selectedTemplate: null
        };

        $scope.onSelectedTemplateChange = function(template) {
            if(!_.isEmpty(template.templateCustomAttributes)) {
                $scope.customValues = {};
                $scope.selectedTemplateCustomAttributes = _.map(template.templateCustomAttributes, function(attributeName) {
                    return $rootScope.dataModel.attribute(attributeName);
                });
                $rootScope.prepareAttributes($scope.selectedTemplateCustomAttributes, true);
            } else {
                $scope.selectedTemplateCustomAttributes = [];
                $scope.customValues = {};
            }
        };

        $scope.runExport = function() {
            // TODO:
            //   -> Problem: What if the user wants to "store" the values as default for additional exports?
            var exportMapping = angular.copy($scope.form.selectedTemplate);
            exportMapping.customValues = $scope.customValues;
            data.parentScope.createExportForExportMapping(exportMapping);
            $modalInstance.close();
        };

        $scope.cancel = function() {
            $modalInstance.close();
        };

        $scope.uploadFileForItem = ExportMappingService.getUploadFileForItemConfiguration($scope);

        function init() {
            ExportMappingResource.getTemplateMappings({},
                function(response) {
                    $scope.templates = response.data;
                },
                function(response) {
                    growl.error('TEMPLATE_MAPPINGS.LOAD_FAILED');
                });
        }

        init();
    });