angular.module('llax.directives')
    .directive('assetDropOverlay', function() {
        /* Drop overlay for asset folders. The drop zone distinguishes between two type of dropped items:
         * 1- An asset/folder item as a moving functionality.
         * 2- An external file to be uploaded.
         *
         * We can distinguish between the two types using the dataTransfer event object, where asset/folder dragged items, should have
         * a specific 'draggable-item' type attached to the event.
         */
        return {
            restrict: 'A',
            scope: false,
            link: function($scope, $element, $attrs){
                $element.bind({
                    dragenter: function($event) {
                        $event.preventDefault(); // needed to fire the drop event

                        // Display drop zone overly only for external files:
                        if($event.originalEvent.dataTransfer.types &&
                           $event.originalEvent.dataTransfer.types.includes('draggable-item')) {
                            return;
                        }

                        $element.dropTarget = $event.target;
                        var dropContainerElement = document.getElementsByClassName($attrs.dropZoneContainerClass);
                        angular.element(dropContainerElement).addClass($attrs.overClass);
                    },
                    dragleave: function($event) {

                        if ($event.target === $element.dropTarget) {
                            $element.dropTarget = undefined;
                            var dropContainerElement = document.getElementsByClassName($attrs.dropZoneContainerClass);
                            angular.element(dropContainerElement).removeClass($attrs.overClass);
                        }
                    },
                    dragover: function($event) {
                        $event.preventDefault(); // needed to fire the drop event
                    },
                    drop: function($event) {

                        if($event.originalEvent.dataTransfer.types &&
                           $event.originalEvent.dataTransfer.types.includes('draggable-item')) {
                            $event.preventDefault();
                            $event.stopPropagation();

                            var droppedItem = $event.originalEvent.dataTransfer.getData('application/json');
                            droppedItem = JSON.parse(droppedItem);

                            // onItemDragFinished should be defined in the scope of the caller directive/controller.
                            $scope.onItemDragFinished(droppedItem);
                            return;
                        }

                        $element.dropTarget = undefined;
                        var dropContainerElement = document.getElementsByClassName($attrs.dropZoneContainerClass);
                        angular.element(dropContainerElement).removeClass($attrs.overClass);
                    }
                });
            }
        };
    })
    .directive('draggableAssetItem', function() {
        return {
            restrict: 'A',
            replace: false,
            /*
             * Those are the scope attributes needed for the draggableAssetItem.
             * Commented out to prevent race conditions with other directives on the same element, but they do still exist in the shared scope
             * of the other directives.
             *
             * scope: {
             *     item: '&',
             *     onItemDragStart: '&',
             *     onItemDragFinished: '&'
             * },
             */
            link: function($scope, $element) {
                $element.bind({
                    dragstart: function($event) {
                        $event.stopPropagation();

                        // A hack to disable dropping effect on other drop zones in the same viewport.
                        $event.originalEvent.dataTransfer.setData('draggable-item', '');
                        $event.originalEvent.dataTransfer.effectAllowed = "move";
                        $event.originalEvent.dataTransfer.dropEffect = "none";
                        $event.target.style.cursor = 'move';
                        $event.target.classList.add('item-multi-dragged');

                        // Preparing dragged item/items is left for the scope, we just need to pass the current item being dragged.
                        $scope.onItemDragStart($event, $scope.item);
                    },
                    dragenter: function($event) {
                        $element.addClass('item-drop-indicator');
                    },
                    dragleave: function($event) {
                        $element.removeClass('item-drop-indicator');
                    },
                    dragend: function($event) {
                        $event.target.style.cursor = 'pointer';
                        $event.target.classList.remove('item-multi-dragged');
                        $element.removeClass('item-drop-indicator');
                    },
                    drop: function($event) {
                        $event.preventDefault();
                        $event.stopPropagation();

                        var droppedItem = $event.originalEvent.dataTransfer.getData('application/json');
                        droppedItem = JSON.parse(droppedItem);
                        $scope.onItemDragFinished(droppedItem, $scope.item);

                        $element.removeClass('item-drop-indicator');
                    },
                    dragover: function($event) {
                        $event.preventDefault();
                    }
                });
            }
        };
    });