angular.module('llax.services')
    .service('SimpleMappingsService', function($log, $rootScope, $q, CodelistRessource, SimpleMappingsResource) {

        var LINKABLE_ATTRIBUTE_TYPES = ['Enum', 'EnumSet', 'OpenEnum', 'OpenEnumSet', 'Codelist', 'Dimensional', 'Physical'];

        this.linkAttributesToSimpleMappingAsync = function(simpleMapping, modelAttributeNames) {
            var deferred = $q.defer();

            simpleMapping.linkedAttributes = modelAttributeNames;

            SimpleMappingsResource.update({
                id: simpleMapping.id
            }, simpleMapping, function(response) {
                deferred.resolve(response);
            }, function(error) {
                $log.error(error);
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.getLinkableAttributes = function($scope, callback) {

            if ($rootScope.isDataModelLoaded) {
                callback(this.filterLinkableAttributes($rootScope.dataModel.allAttributes()));
            } else {
                var _this = this;
                $scope.$on('dataModelLoaded', function() {
                    callback(_this.filterLinkableAttributes($rootScope.dataModel.allAttributes()));
                });
            }
        };

        this.filterLinkableAttributes = function(attributes) {
            return _.filter(attributes, function(attribute) {
                return _.includes(LINKABLE_ATTRIBUTE_TYPES, attribute.typeName);
            });
        };

        this.getCodeLists = function(callback) {
            CodelistRessource.getAllCodelists({}, function(response) {
                callback(response);
            }, function(response) {
                growl.error('SIMPLE_MAPPING.LOAD_FAILED');
            });
        };

        this.getCodeListValuesAsync = function(codelistName) {
            var deferred = $q.defer();
            CodelistRessource.getCodelist({
                id: codelistName
            }, function(response) {
                var options = _.map(response, function(entry) {
                    return {
                        key: entry.code,
                        value: entry.defaultLabel
                    };
                });
                deferred.resolve(options);
            }, function(response) {
                $log.error(response);
                deferred.reject(response);
            });

            return deferred.promise;
        };

        this.getAttributeOptionsAsync = function(modelAttribute) {
            var deferred = $q.defer();

            if (modelAttribute.typeName === 'Codelist') {
                return this.getCodeListValuesAsync(modelAttribute.params.codelist);
            }

            if (!_.isNil(modelAttribute.params.valueAttribute)) {
                modelAttribute = $rootScope.dataModel.getReferencedOptionAttribute(modelAttribute);
            }

            var optionsKey = $rootScope.dataModel.getAttributeOptionsParam(modelAttribute);
            var options = modelAttribute.params[optionsKey];

            if (_.isString(options)) { // i.e. options list
                var optionList = $rootScope.dataModel.optionList(options);
                options = optionList.options;
            }

            deferred.resolve(options);
            return deferred.promise;
        };

        this.getAllAttributeOptionsAsync = function(modelAttributes) {
            var _this = this;
            var optionsPromises = [];
            _.forEach(modelAttributes, function(attribute) {
                optionsPromises.push(_this.getAttributeOptionsAsync(attribute));
            });

            return $q.all(optionsPromises)
                .then(function(optionsResponses) {
                    var options = [];
                    _.forEach(optionsResponses, function(result) {
                        options = _.concat(options, result);
                    });

                    return options;
                });
        };

        this.createSimpleMappingEntriesAsync = function(simpleMappingId, entries) {
            var deferred = $q.defer();
            SimpleMappingsResource.createEntries(
                { id: simpleMappingId },
                entries,
                function(response) {
                    deferred.resolve(response);
                },
                function(error) {
                    deferred.error(error);
                });

            return deferred.promise;
        };

        this.importModelAttributesIntoSimpleMappingAsync = function(modelAttributes, simpleMappingId) {
            var _this = this;
            var deferred = $q.defer();

            this.getAllAttributeOptionsAsync(modelAttributes)
                .then(function(options) {

                    var simpleMappingEntries = _.map(options, function(option) {
                        return {
                            key: option.key,
                            value: ''
                        };
                    });

                    if (!_.isEmpty(options)) {
                        _this.createSimpleMappingEntriesAsync(simpleMappingId, simpleMappingEntries)
                            .then(function(){
                                deferred.resolve();
                            })
                            .catch(function(error){
                                $log.error(error);
                                deferred.reject(error);
                            });
                    } else {
                        deferred.resolve();
                    }
                });

            return deferred.promise;
        };

    });