angular.module('llax')
    .controller('DataModelCustomizationController',
        function($rootScope, $scope, growl, $dialogs, $translate, $modal,
                 DataModelCustomizationConstants, HttpStatus, ResetDataModelResource, UploadCustomDataModelResource) {

            $scope.focusOn = true;
            $scope.attribute = {};
            var attributeMap = {};

            if ($rootScope.isDataModelLoaded) {
                init();
            } else {
                $rootScope.reloadDataModel();
            }

            $scope.$on('dataModelLoaded', function() {
                init();
            });

            $scope.$on('layoutUpdated', function(scope, layout) {
                $scope.selectedLayout = layout;
            });

            $scope.$on('filterUpdated', function(scope, isFiltered) {
                $scope.isFiltered = isFiltered;
            });

            function extractNames(items) {
                return _.map(items, "name");
            }

            function getLayoutList() {
                $scope.layoutsMap = {};
                var layouts = $rootScope.dataModel.getRaw().layouts;
                var layoutList = _.map(layouts, function(value, layoutKey) {
                    var sections = angular.copy($rootScope.dataModel.sectionAttributes(layoutKey));
                    sections = _.filter(sections, function(section) {
                        return (section.attributes && section.attributes.length > 0) || section.model_defined === false;
                    });
                    $scope.layoutsMap[layoutKey] = sections;
                    _.forEach($scope.layoutsMap[layoutKey], function(section) {
                        section.type = "section";
                        section.label = $rootScope.translateSection(section);
                        _.forEach(section.attributes, function(attribute) {
                            attribute.type = "attribute";
                            attribute.label = $rootScope.translateAttribute(attribute);
                        });
                    });
                    return layoutKey;
                });
                return layoutList;
            }

            function getAttributeList() {
                var allAttributes = $rootScope.dataModel.allAttributes();
                var attributeList = [];
                for (var i = 0; i < allAttributes.length; i++) {
                    var attrib = allAttributes[i];
                    var hasCategory = $rootScope.dataModel.attributeHasCategory(attrib.name);
                    if (attrib.label) {
                        attributeList.push({
                            name: attrib.name,
                            label: $rootScope.translateAttribute(attrib),
                            type: "attribute",
                            has_category: hasCategory,
                            params : attrib.params,
                            typeName : attrib.typeName,
                            baseClass : attrib.baseClass

                        });
                    }
                    attributeMap[attrib.name] = attrib;
                }
                return attributeList;
            }

            function getCategoryList() {
                var allCategories = $rootScope.dataModel.allCategories(true);
                var categoryList = [];
                _.forEach(allCategories, function(category) {
                    categoryList.push({
                        name: category.name,
                        label: $rootScope.translateCategory(category),
                        parent: category.parent,
                        type: "category",
                        attribute_status: category.attribute_status,
                        modification_status: category.modification_status,
                        attributes: _.forEach($scope.dataModel.categoryAttributes(category.name), function(attribute) {
                            attribute.type = "attribute";
                            attribute.label = $rootScope.translateAttribute(attribute);
                        })
                    });
                });
                return categoryList;
            }

            $scope.selectLayout = function(layout) {
                $scope.currentLayout = $scope.layoutsMap[layout];
                $rootScope.$broadcast('layoutUpdated', layout);
                if ($scope.isFiltered) {
                    $scope.toggleAlreadyAssigned($scope.isFiltered);
                }
            };

            function getOrCreateLayout(layoutId, action) {
                var diff = $scope.customizedDatamodelDiff;
                diff.layouts = diff.layouts || [];
                var layout;

                if (action !== 'SORT') {
                    layout = _.find(diff.layouts, {id: layoutId});
                }

                if (!layout) {
                    layout = {
                        id: layoutId,
                        action: action,
                        sections: []
                    };
                    diff.layouts.push(layout);
                }
                return layout;
            }

            function getOrCreateSection(layout, sectionId, action) {
                var section = _.find(layout.sections, {id: sectionId});

                if (!section) {
                    section = {
                        id: (sectionId === "null" ? null : sectionId),
                        action: action,
                        attributes: []
                    };
                    layout.sections.push(section);
                }
                return section;
            }

            function getOrCreateCategory(categoryName, action) {
                var diff = $scope.customizedDatamodelDiff;
                diff.categories = diff.categories || [];

                var category = _.find(diff.categories, {
                    id: categoryName
                });

                if (!category) {
                    var modelCategory = _.find($scope.categoryList, {
                        name: categoryName
                    });
                    category = {
                        id: categoryName,
                        label: modelCategory.label,
                        parent: modelCategory.parent,
                        action: action,
                        attributes: []
                    };
                    diff.categories.push(category);
                }
                category.id = _.includes(categoryName, '::') ? categoryName.split('::')[1] : categoryName;
                return category;
            }

            $scope.toggleAlreadyAssigned = function(isFiltered) {
                if (isFiltered) {
                    $scope.attributeList = _.filter($scope.completeAttributeList, function(element) {
                        return !_.includes($rootScope.dataModel.layoutAttributeNames($scope.selectedLayout),
                            element.name);
                    });
                } else {
                    $scope.attributeList = $scope.completeAttributeList;
                }
                $rootScope.$broadcast('filterUpdated', isFiltered);
            };

            $scope.options = {
                accept: function(sourceNode, destNodes, destIndex) {
                    var sourceData = sourceNode.$modelValue;
                    var destData = destNodes.$modelValue;
                    var destType = destNodes.$element.attr('data-type');

                    var attr = _.find(destData, {
                        name: sourceData.name
                    });

                    return (sourceData.type === destType && (destNodes.isParent(sourceNode) ||
                        !destNodes.isParent(sourceNode) &&
                        _.isUndefined(attr)));
                },
                dropped: function(event) {
                    var sourceNode = event.source.nodeScope;
                    var sourceNodeType = event.source.nodesScope.$element.attr('data-type');
                    var destNodes = event.dest.nodesScope;
                    var layout = destNodes.selectedLayout;

                    if (sourceNodeType === 'attribute') {
                        if (!destNodes.$nodeScope) return false;

                        var sectionOrCategory = destNodes.$treeScope.$element.attr('id').split('tree-root-')[1];
                        var attribute = sourceNode.$modelValue;
                        var section = destNodes.$nodeScope.$modelValue;

                        if (destNodes.isParent(sourceNode)) {
                            addAttributeSortOrder(layout, section, destNodes.$modelValue);
                        } else {
                            var item = destNodes.$nodeScope.$modelValue;

                            if (sectionOrCategory === 'categories') {
                                addAttributeToCategory(item.name, attribute);
                            }
                            if (sectionOrCategory === 'layouts') {
                                addAttributeToSection(layout, item.name, attribute);
                                addAttributeSortOrder(layout, section, destNodes.$modelValue);
                            }
                        }
                    } else if (sourceNodeType === 'section') {
                        addSectionSortOrder(layout, destNodes.$modelValue);
                    }
                }
            };

            function getRootNodesScope(id) {
                return angular.element('#' + id).scope();
            }

            $scope.toggle = function(scope) {
                scope.toggle();
            };

            $scope.collapseAll = function(treeId) {
                var scope = getRootNodesScope(treeId);
                scope.collapseAll();
            };

            $scope.expandAll = function(treeId) {
                var scope = getRootNodesScope(treeId);
                scope.expandAll();
            };

            // ATTRIBUTES
            $scope.isVisible = function(item, query) {
                if (_.isNil(query)) {
                    return true;
                }
                query = query.toLocaleLowerCase();
                return (_.includes(item.label.toLocaleLowerCase(), query) || _.includes(item.name.toLocaleLowerCase(), query));
            };

            $scope.addAttribute = function() {
                var editor = $modal.open({
                    templateUrl: 'tpl/editor-add-attribute.tpl.html',
                    controller: 'CustomizationEditAttributeController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                attribute : {}
                            };
                        }
                    }
                });
                editor.result.then(function(attribute) {
                    if (!_.isEmpty(attribute)) {
                        $scope.createNewAttribute(attribute);
                    }
                });
            };
            $scope.createNewAttribute = function (attribute) {
                if (attribute.label && attribute.type) {
                    attribute.id = _.camelCase(attribute.label);
                    attribute.action = 'ADD';

                    var newListAttribute = {
                        name: attribute.id,
                        label: attribute.label,
                        type: 'attribute'
                    };

                    if (_.includes(DataModelCustomizationConstants.CODELIST_TYPES, attribute.type)) {
                        if (!attribute.codelist) {
                            growl.error("CUSTOMIZATION.CREATE_ERROR_MESSAGE");
                            return;
                        }
                        var params = attribute.params || {};
                        params.codelist = attribute.codelist;
                        params.type_name = attribute.type;

                        newListAttribute.params = params;
                        newListAttribute.typeName = attribute.type;

                        attribute.params = params;
                        attribute.typeName = attribute.type;
                    }

                    $scope.completeAttributeList.push(newListAttribute);
                    $scope.customizedDatamodelDiff.attributes.push(attribute);
                    $scope.attribute = {};
                    growl.success("CUSTOMIZATION.CREATE_SUCCESS_MESSAGE");
                } else {
                    growl.error("CUSTOMIZATION.CREATE_ERROR_MESSAGE");
                }
            };

            $scope.editAttribute = function(attr) {
                var editor = $modal.open({
                    templateUrl: 'tpl/editor-add-attribute.tpl.html',
                    controller: 'CustomizationEditAttributeController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                attribute : attr,
                                mode : 'edit'
                            };
                        }
                    }
                });
                editor.result.then(function(attribute) {
                    if (!_.isEmpty(attribute)) {
                        $scope.updateAttribute(attribute);
                    }
                });
            };

            $scope.updateAttribute = function(attribute, label) {
                var newAttribute = {
                    id: attribute.name,
                    label: attribute.label,
                    action: 'UPDATE',
                    type : attribute.type
                };
                if (attribute.params) {
                    newAttribute.params = attribute.params;
                }
                $scope.customizedDatamodelDiff.attributes.push(newAttribute);
            };

            $scope.updateAttributeInSection = function(parentNode, layoutId, sectionId, attribute) {
                addAttributeToSection(layoutId, sectionId, attribute);
                parentNode.attribute_status = parentNode.attribute_status ? parentNode.attribute_status : [];
                parentNode.attribute_status[attribute.name] = 'ADD';
            };

            $scope.removeAttribute = function(parentNode, attribute) {
                var sectionOrCategory = $rootScope.dataModel.hasCategory(parentNode.name) ? 'category' : 'section';
                if (sectionOrCategory === 'category') {
                    removeAttributeFromCategory(parentNode.name, attribute);
                } else {
                    removeAttributeFromSection(parentNode.name, attribute);
                }
                parentNode.attribute_status = parentNode.attribute_status ? parentNode.attribute_status : [];
                parentNode.attribute_status[attribute.name] = 'REMOVED';
            };

            function addAttributeSortOrder(layout, section, attributes) {
                var orderedList = extractNames(attributes);
                layout = getOrCreateLayout(layout, 'UPDATE');
                var foundSection = _.find(layout.sections, {id: section.name, action: 'SORT'});
                if (foundSection) {
                    foundSection.order = orderedList;
                } else {
                    var newSection = {
                        id: section.name,
                        action: "SORT",
                        order: orderedList
                    };
                    layout.sections.push(newSection);
                }
            }

            // SECTIONS
            $scope.addSection = function() {
                if (_.isEmpty($scope.selectedLayout)) {
                    growl.warning("CUSTOMIZATION.SELECT_LAYOUT_FIRST");
                } else {
                    var editor = $modal.open({
                        templateUrl: 'tpl/editor-add-section.tpl.html',
                        controller: 'DataModelCustomizationController',
                        backdrop: true,
                        resolve: {
                            section: function() {
                                return {};
                            }
                        }
                    });
                    editor.result.then(function(section) {
                        $scope.createNewSection(section);
                    });
                }
            };

            $scope.createNewSection = function(section) {
                if (section.label) {
                    var scope = getRootNodesScope('tree-root-layouts');
                    var sectionId = _.toUpper(_.snakeCase(section.label));
                    var newSection = {
                        name: sectionId,
                        label: section.label,
                        attributes: [],
                        type: 'section'
                    };
                    scope.$nodesScope.$modelValue.push(newSection);
                    addSectionToLayout($scope.selectedLayout, sectionId, section.label);
                    growl.success("SAVE_SUCCESS_MESSAGE");
                } else {
                    growl.error("SAVE_ERROR_MESSAGE");
                }
            };

            $scope.updateSection = function(layoutId, section) {
                var layout = getOrCreateLayout(layoutId, 'UPDATE');
                layout.sections.push({
                    id: section.name,
                    label: section.label,
                    action: 'UPDATE',
                    attributes: []
                });

                var updateSection = _.find($scope.currentLayout, {
                    name: section.name
                });
                if (!_.isNull(updateSection)) {
                    updateSection.modification_status = null;
                }
            };

            $scope.removeSection = function(layoutId, section) {
                if (section.attributes.length > 0) {
                    var header = 'Section is not empty';
                    var message = 'Delete Section "' + section.name + '" with all ' + section.attributes.length +
                        ' items from Layout "' + layoutId + '"?';

                    var confirmDialog = $dialogs.confirm(header, message);
                    confirmDialog.result.then(function() {
                        removeSection(layoutId, section.name);
                    });
                } else {
                    removeSection(layoutId, section.name);
                }
            };

            function removeSection(layoutId, sectionId) {
                var layout = getOrCreateLayout(layoutId, 'UPDATE');
                var section = getOrCreateSection(layout, sectionId, 'REMOVE');
                var removedSection = _.find($scope.currentLayout, {
                    name: sectionId
                });
                removedSection.modification_status = 'DELETED';
            }

            function addSectionToLayout(layoutId, sectionId, sectionLabel) {
                var layout = getOrCreateLayout(layoutId, 'UPDATE');
                var section = getOrCreateSection(layout, sectionId, 'ADD');
                section.label = sectionLabel;
            }

            function addSectionSortOrder(layout, sections) {
                var orderedList = extractNames(sections);
                layout = getOrCreateLayout(layout, 'SORT');
                layout.order = orderedList;
            }

            function addAttributeToSection(layoutId, sectionId, attribute) {
                var layout = getOrCreateLayout(layoutId, 'UPDATE');
                var section = getOrCreateSection(layout, sectionId, 'UPDATE');

                var entryFound = _.find(section.attributes, {id: attribute.name});

                if (entryFound) {
                    entryFound.action = 'ADD';
                } else {
                    section.attributes.push({
                        id: attribute.name,
                        action: 'ADD'
                    });
                }
            }

            function removeAttributeFromSection(sectionId, attribute) {
                var layoutId = $scope.selectedLayout;
                var layout = getOrCreateLayout(layoutId, 'UPDATE');
                var section = getOrCreateSection(layout, sectionId, 'UPDATE');

                var indexOfAddElement = -1;
                var attributeHasAction = _.find(section.attributes, function(element, index) {
                    var result = element.id === attribute.name && element.action === 'ADD';
                    indexOfAddElement = index;
                    return result;
                });

                if (attributeHasAction) {
                    section.attributes.splice(indexOfAddElement, 1);
                } else {
                    section.attributes.push({
                        id: attribute.name,
                        action: 'REMOVE'
                    });
                }
            }

            // CATEGORIES
            $scope.addCategory = function() {
                var editor = $modal.open({
                    templateUrl: 'tpl/editor-add-category.tpl.html',
                    controller: 'DataModelCustomizationController',
                    backdrop: true,
                    resolve: {
                        category: function() {
                            return {};
                        }
                    }
                });
                editor.result.then(function(category) {
                    $scope.createNewCategory(category);
                });
            };

            $scope.createNewCategory = function(category) {
                if (category.label) {
                    var id = _.upperFirst(_.camelCase(category.label));
                    category = {
                        id: id,
                        label: category.label,
                        parent: 'PIM::Item',
                        action: 'ADD',
                        attributes: []
                    };
                    $scope.customizedDatamodelDiff.categories.push(category);
                    $scope.categoryList.push({
                        name: category.id,
                        label: category.label,
                        attributes: []
                    });
                    growl.success("SAVE_SUCCESS_MESSAGE");
                } else {
                    growl.error("SAVE_ERROR_MESSAGE");
                }
            };

            $scope.updateCategory = function(category) {
                var categoryName = category.name.split('::')[1];
                var newCategory = {
                    id: categoryName,
                    label: category.label,
                    parent: category.parent,
                    action: 'UPDATE',
                    attributes: []
                };
                $scope.customizedDatamodelDiff.categories.push(newCategory);
            };

            $scope.updateAttributeInCategory = function(parentNode, categoryName, attribute) {
                addAttributeToCategory(categoryName, attribute);
                parentNode.attribute_status = parentNode.attribute_status ? parentNode.attribute_status : [];
                parentNode.attribute_status[attribute.name] = 'ADD';
            };

            $scope.removeCategory = function(category) {
                if (category.attributes.length > 0) {
                    var header = 'Category is not empty';
                    var message = 'Delete Category "' + category.name + '" with all ' + category.attributes.length +
                        ' Attributes?';

                    var confirmDialog = $dialogs.confirm(header, message);
                    confirmDialog.result.then(function() {
                        removeCategory(category);
                    });
                } else {
                    removeCategory(category);
                }
            };

            function removeCategory(category) {
                var removedCategory = _.find($scope.categoryList, {
                    name: category.name
                });
                removedCategory.modification_status = 'DELETED';
            }

            function addAttributeToCategory(categoryName, attribute) {
                var category = getOrCreateCategory(categoryName, 'UPDATE');
                category.attributes.push({
                    id: attribute.name,
                    action: 'ADD'
                });
            }

            function removeAttributeFromCategory(categoryId, attribute) {
                var category = getOrCreateCategory(categoryId, 'UPDATE');

                var indexOfAddElement = -1;
                var attributeHasAction = _.find(category.attributes, function(element, index) {
                    var result = element.id === attribute.name && element.action === 'ADD';
                    indexOfAddElement = index;
                    return result;
                });
                if (attributeHasAction) {
                    category.attributes.splice(indexOfAddElement, 1);
                } else {
                    category.attributes.push({
                        id: attribute.name,
                        action: 'REMOVE'
                    });
                }
            }

            // CUSTOMIZED_DATAMODEL
            $scope.saveCustomizedDatamodel = function() {
                var customizedDatamodel = $scope.customizedDatamodelDiff;

                UploadCustomDataModelResource.save({}, customizedDatamodel,
                    function(data) {
                        growl.success("SAVE_SUCCESS_MESSAGE");
                        $scope.errorMessage = undefined;
                    },
                    function(response) {
                        $scope.status = response.status;
                        if (response.status === HttpStatus.BAD_REQUEST) {
                            $scope.errorMessage = $translate.instant(response.data.message, response.data.parameters);
                        } else {
                            growl.error("SAVE_ERROR_MESSAGE");
                        }
                    }
                );
            };

            $scope.resetDatamodel = function() {
                var header = 'CUSTOMIZATION.RESET_DATAMODEL';
                var message = 'CUSTOMIZATION.RESET_DATAMODEL_TEXT';

                var confirmDialog = $dialogs.confirm(header, message);

                confirmDialog.result.then(function() {
                    ResetDataModelResource.save({}, function() {
                        growl.success("DATAMODEL_RESET_SUCCESS_MESSAGE");
                        $rootScope.reloadDataModel();
                    }, function(response) {
                        growl.error("DATAMODEL_RESET_ERROR_MESSAGE");
                    });
                });
            };

            function init() {
                $scope.customizedDatamodelDiff = {
                    attributes: [],
                    layouts: [],
                    categories: []
                };
                $scope.attributeList = getAttributeList();
                $scope.completeAttributeList = angular.copy($scope.attributeList);
                $scope.categoryList = getCategoryList();
                $scope.layoutList = getLayoutList();

                if (!_.isEmpty($scope.selectedLayout)) {
                    $scope.selectLayout($scope.selectedLayout);
                }
            }

        }
    )
    .controller('CustomizationEditAttributeController',
        function($scope, $modalInstance, data, $modal, CodelistRessource, DataModelCustomizationConstants) {

            $scope.attribute = data.attribute || {};
            $scope.isEdit = data.mode == 'edit';
            $scope.types = DataModelCustomizationConstants.SUPPORTED_TYPES;
            $scope.codelistTypes = DataModelCustomizationConstants.CODELIST_TYPES;
            $scope.codelists = [];
            CodelistRessource.getAllCodelists(function (codelistData) {
                _.forEach(codelistData, function (codelist) {
                    $scope.codelists.push(codelist.name);
                });
            });
            $scope.close = function(attr) {
                $modalInstance.close(attr);
            };
            $scope.openEditor = function(attr) {
                var key;
                _.forEach(attr.params, function(val,k) {
                    if (_.isArray(val)) {
                        key = k;
                    }
                });

                var config = {
                    key : key,
                    levelRestrictions : {
                        levelLimit : 1,
                        types : {
                            "0" : {
                                type :'array'
                            },
                            "1" : {
                                type :'object',
                                keys : ['key','value']
                            }
                        }
                    }
                };

                var editor = $modal.open({
                    templateUrl: 'tpl/json-editor.tpl.html',
                    controller: 'JsonEditorController',
                    windowClass: 'manage-datamodel-modal',
                    resolve: {
                        jsonEditorData: function() {
                            return {
                                json : attr.params,
                                config : config
                            };
                        }
                    }
                });
                editor.result.then(function(params) {
                    if (!_.isNil(params)) {
                        attr.params = params;
                    }
                });
            };

    });
