angular.module('llax')
    .controller('OrganizationsController', function($rootScope, $scope, growl, OrganizationService, BillingResource) {
        $scope.hideOrganizationIcon = false;

        function prepareOrganizationAttributes() {
            // Set renderer and translations for organization attributes
            $scope.organizationAttributes = $rootScope.dataModel.getOrganizationAttributes();
            $rootScope.prepareAttributes($scope.organizationAttributes);
        }

        function getPlanBillingProfiles() {
            BillingResource.getPlanBillingProfiles(
                function(response) {
                    $rootScope.planBillingProfiles = response;
                }
            );
        }

        $scope.saveOrganizationData = function(organizationData) {

          // Remove all unchanged customValue entries
            var newCustomValues = organizationData.customValues;
            var oldCustomValues = $scope.originalOrganizationData.customValues;
            if (!_.isEmpty(newCustomValues) && !_.isEmpty(oldCustomValues)) {
                newCustomValues = _.omitBy(newCustomValues, function(value, key) {
                    var oldValue = oldCustomValues[key];
                    return _.isEqual(value, oldValue);
                });
                organizationData = angular.copy(organizationData);
                organizationData.customValues = newCustomValues;
            }

            OrganizationService.save(organizationData,
                function(response) {
                    growl.success("SAVE_SUCCESS_MESSAGE");
                    $rootScope.$broadcast('organizationUpdated');
                },
                function(response) {
                    $rootScope.error = response.data;
                    $scope.status = response.status;
                    growl.error("SAVE_ERROR_MESSAGE");
                });
        };

        $scope.uploadOrganizationImage = function() {
            return {
                url: lax_rest_url('organization/image'),
                filters: ['imageFilter'],
                accept: 'image/jpeg,image/gif,image/png',
                queueLimit: 1,
                cropImage: true,
                uploadComplete: function(response) {
                    OrganizationService.update({ hasImage: true});
                    OrganizationService.update({ imageUrl: lax_rest_url('organization/image')});
                    $rootScope.$broadcast('updateOrganizationData');
                    growl.success("UPLOAD_SUCCESS_MESSAGE");
                }
            };
        };

        function updateOrganizationData() {
            var data = OrganizationService.getOrganization(true);
            $scope.organizationData = angular.copy(_.last(data.q).value);
        }

        $scope.$on('updateOrganizationData', function() {
            updateOrganizationData();
        });

        $scope.toggleOrganizationIcon = function() {
            $scope.hideOrganizationIcon = !$scope.hideOrganizationIcon;
        };

        $scope.$on('toggleFileUpload', function(event, uploadId) {
            if(uploadId == "organization_upload") {
                $scope.toggleOrganizationIcon();
            }
        });

        (function initOrganizationData() {
            if (!$rootScope.planBillingProfiles) {
                getPlanBillingProfiles();
            }
            OrganizationService.$organization.subscribe(function(organization) {
                $scope.originalOrganizationData = organization;
                $scope.organizationData = angular.copy(organization);
            });
            if ($rootScope.isDataModelLoaded) {
                prepareOrganizationAttributes();
            } else {
                $scope.$on('dataModelLoaded', function() {
                    prepareOrganizationAttributes();
                });
            }
        })();
    });
