angular.module('llax')
.controller('ItemReviewController',
    function($scope, $rootScope, $routeParams, growl, ReviewerReviewResource) {

        function prepareReviewAttributes() {
            // Set renderer and translations for review attributes
            $scope.reviewAttributes = $rootScope.dataModel.getReviewAttributes();
            $rootScope.prepareAttributes($scope.reviewAttributes);
            var customService =  $rootScope.getService('CustomItemReviewSettings');
            if (customService) {
                $scope.review = customService.itemReviewTemplate($scope.item, $scope.validations, $rootScope.user, $rootScope.organization) || {};
            }
        }

        $scope.review = {};
        prepareReviewAttributes();
        $scope.review.customValues = {};

        _.forEach($scope.reviewAttributes, function(reviewAttribute) {
           if (!_.isEmpty(reviewAttribute.defaultValue)) {
               $scope.review.customValues[reviewAttribute.name] = reviewAttribute.defaultValue;
           }
        });

        $scope.reviewStates = ['APPROVED', 'REVIEWED', 'REJECTED', 'RECEIVED'];
        $scope.primaryKey = $routeParams.primaryKey || $scope.item.primaryKey__;

        $scope.translateReviewOption = function(option) {
            return $rootScope.translateValue("REVIEW.", option);
        };

        $scope.sendItemReview = function(review, primaryKey) {
            review.primaryKey = primaryKey;
            ReviewerReviewResource.save({}, review,
                function(response) {
                    growl.success("SAVED_REVIEW_SUCCESS_MESSAGE");

                    if (_.isFunction($scope.toggleItemReview)) {
                        // Some dependers (item-detail-controller) on ItemReviewController
                        // might not need to define a 'toggleItemReview()'
                        $scope.toggleItemReview();
                    }
                },
                function(response) {
                    $rootScope.error = response.data;
                    $scope.status = response.status;
                    growl.error("SAVED_REVIEW_ERROR_MESSAGE");
                });
        };

    }
);
