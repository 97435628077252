angular.module('llax')
.controller('RegistrationController',
    function ($location, $log, $rootScope, $route, $routeParams, $scope, $timeout, $translate, $q,
        HttpHeader, HttpStatus, SignupResource, StatusResource, UserResource, OrganizationResource) {

    $rootScope.isInRegistrationProcess = true;
    $scope.invitationError = null;
    $scope.HttpStatus = HttpStatus;
    $scope.loading = false;

    $scope.INVITATIONS_TYPE = {
        USER: 'user',
        ORGANIZATION: 'organization'
    };

    $scope.MODE = {
        LOADING: 'LOADING',
        COLLEAGUE: 'COLLEAGUE',
        ORGANIZATION: 'PARTNER_ORGANIZATION',
        NEW: 'NEW'
    };
    $scope.currentMode = $scope.MODE.LOADING;

    $scope.ORGANIZATION_SIGNUP_STEPS = {
        IDENTIFY_EMAIL: 'IDENTIFY_EMAIL',
        CREATE_USER: 'CREATE_USER',
        CREATE_ORG_ONLY: 'CREATE_ORG_ONLY',
        SUCCESS: 'SUCCESS'
    };

    $scope.COLLEAGUE_SIGNUP_STEPS = {
        IDENTIFY_EMAIL: 'IDENTIFY_EMAIL',
        CONFIRM: 'CONFIRM',
        CREATE_USER: 'CREATE_USER',
        SUCCESS: 'SUCCESS'
    };

    // Trying to pull registration params from the url queries
    $scope.invitationReference = $routeParams.ref;
    $scope.organizationName = $routeParams.organizationName;
    $scope.email = $routeParams.email;
    $scope.type = $routeParams.type;

    // Form and HTTP request map
    $scope.signupData = {
        companyName: $scope.organizationName
    };

    checkStatusAsync()
        .then(function() {
            init();
        })
        .catch(function() {
            // Nothing to do, registration is not allowed
            redirectToStartPage();
        });

    $scope.signup = function(signupData) {

        $scope.signupClicked = true;
        $scope.signupError = {};
        signupData.invitationReference = $scope.invitationReference;

        SignupResource.save({}, signupData,
            function(signupResponse) {
                $scope.signupComplete = true;
                $('span#signUpId').attr('data-lax-signup', signupResponse.id);
                $scope.signupData = {};
            },
            function(response) {
                $log.error(response);
                $scope.signupError = response;

                if (!_.isNil(response.data.data) && !_.isNil(response.data.data[0])) {
                    $scope.signupErrorKey = response.data.key;
                    $scope.signupErrorValue = {
                        value: response.data.data[0]
                    };
                } else if (!_.isNil(response.data.message)) {
                    $scope.signupErrorKey = response.data.message;
                } else {
                    $scope.signupErrorKey = 'SIGNUP.GENERIC_ERROR';
                }
            }
        ).$promise.finally(function() {
            $scope.signupClicked = false;
        });

    };

    $scope.notYourAccountClicked = function() {
        $scope.currentOrganizationSignupStep = $scope.ORGANIZATION_SIGNUP_STEPS.IDENTIFY_EMAIL;
    };

    $scope.acceptColleagueInvitation = function() {

        SignupResource.save({}, {
            invitationReference: $scope.invitationReference,
            companyName: $scope.organizationName,
            email: $scope.email
        }, function(result) {
            $scope.currentColleagueSignupStep = $scope.COLLEAGUE_SIGNUP_STEPS.SUCCESS;
            $timeout(function() {
                redirectToStartPage();
            }, 4000);
        }, function(error) {
            $log.error(error);
            if (error.data.message) {
                $scope.invitationError = $translate.instant(error.data.message);
            } else {
                $scope.invitationError = $translate.instant('SIGNUP.ERROR_GENERIC');
            }
        });
    };

    $scope.identifyEmailAddress = function() {

        if (_.isEmpty($scope.signupData.email)) {
            return;
        }

        checkUserIfExistsAsync($scope.signupData.email, $scope.invitationReference)
            .then(function(result) {

                if (result.userExists && !result.supportsMultiAccounts) {
                    $scope.invitationError = $translate.instant('SIGNUP.ERROR_ACCOUNT_IS_LEGACY_IMPLEMENTATION');
                } else if (result.userExists) {
                    $scope.currentOrganizationSignupStep = $scope.ORGANIZATION_SIGNUP_STEPS.CREATE_ORG_ONLY;
                } else {
                    $scope.currentOrganizationSignupStep = $scope.ORGANIZATION_SIGNUP_STEPS.CREATE_USER;
                }
            })
            .catch(function(error) {

                $log.error(error);
                if (error.status === HttpStatus.NOT_FOUND) {
                    $scope.invitationError = $translate.instant('SIGNUP.ERROR_INVITATION_EXPIRED');
                } else {
                    $scope.invitationError = $translate.instant('SIGNUP.ERROR_GENERIC');
                }
            });
    };

    $scope.addNewOrganizationToAccount = function() {

        SignupResource.save({}, {
            invitationReference: $scope.invitationReference,
            companyName: $scope.signupData.companyName,
            email: $scope.signupData.email,
            companyEmail: $scope.signupData.companyEmail,
            companyWebsite: $scope.signupData.companyWebsite,
            companyAddress: $scope.signupData.companyAddress
        }, function() {
            $scope.currentOrganizationSignupStep = $scope.ORGANIZATION_SIGNUP_STEPS.SUCCESS;
            $timeout(function() {
                redirectToStartPage();
            }, 4000);
        }, function(error) {
            $log.error(error);
        });
    };

    $scope.shouldDisableOrganizationName = function() {
        // We disable the organization/company name when it's provided in the invitation URL
        return !_.isNil($scope.organizationName);
    };

    function checkUserIfExistsAsync(email, invitationReference) {

        var deferred = $q.defer();

        UserResource.existsUser({
            // FIXME: emailOrUsername: encodeURIComponent(email),
            emailOrUsername: email,
            invitationReference: invitationReference
        }, function(result) {
            deferred.resolve(result);
        }, function(error) {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    function checkOrganizationIfExistsAsync(invitationReference) {

        var deferred = $q.defer();

        OrganizationResource.existsOrganization({
            invitationReference: invitationReference
        }, function(result) {
            deferred.resolve(result);
        }, function(error) {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    function redirectToStartPage() {
        $location.path('/');
    }

    /**
     * Check if registration is allowed (no SSO).
     */
    function checkStatusAsync() {

        var deferred = $q.defer();
        StatusResource.get({},
            function(statusResponse, headers) {
                var isSSO = !_.isEmpty(headers(HttpHeader.SSO_POST)) || !_.isEmpty(headers(HttpHeader.SSO_REDIRECT));
                if (isSSO) {
                    deferred.reject();
                } else {
                    deferred.resolve();
                }
            },
            function(errorResponse) {
                var isSSO = !_.isEmpty(errorResponse.headers(HttpHeader.SSO_POST)) || !_.isEmpty(errorResponse.headers(HttpHeader.SSO_REDIRECT));
                if (isSSO) {
                    deferred.reject();
                } else {
                    deferred.resolve();
                }
            }
        );

        return deferred.promise;
    }

    function init() {

        if (_.isEmpty($scope.invitationReference)) {

            // No invitation reference => traditional signup
            $scope.currentMode = $scope.MODE.NEW;

        } else if ($scope.type === $scope.INVITATIONS_TYPE.ORGANIZATION) {

            checkOrganizationIfExistsAsync($scope.invitationReference)
                .then(function(result) {
                    if (result.organizationExists) {
                        // Organization exists, then convert the current invitation
                        // to a COLLEAGUE invitation.
                        $route.updateParams({ type: $scope.INVITATIONS_TYPE.USER });
                    } else {
                        $scope.currentMode = $scope.MODE.ORGANIZATION;
                        $scope.currentOrganizationSignupStep = $scope.ORGANIZATION_SIGNUP_STEPS.IDENTIFY_EMAIL;
                    }
                })
                .catch(function(error) {

                    $log.error(error);
                    if (error.status === HttpStatus.NOT_FOUND) {
                        $scope.invitationError = $translate.instant('SIGNUP.ERROR_INVITATION_EXPIRED');
                    } else {
                        $scope.invitationError = $translate.instant('SIGNUP.ERROR_GENERIC');
                    }
                });

        } else {
            // If the invitation type is for a colleague, we need
            // to find first if the user exists or not
            $scope.currentMode = $scope.MODE.COLLEAGUE;
            $scope.loading = false;
            $scope.currentColleagueSignupStep = $scope.COLLEAGUE_SIGNUP_STEPS.IDENTIFY_EMAIL;
            // figure out if user exists or not
            checkUserIfExistsAsync($scope.email, $scope.invitationReference)
                .then(function(result) {

                    if (result.userExists && !result.supportsMultiAccounts) {
                        $scope.invitationError = $translate.instant('SIGNUP.ERROR_ACCOUNT_IS_LEGACY_IMPLEMENTATION');
                    } else if (result.userExists) {
                        $scope.currentColleagueSignupStep = $scope.COLLEAGUE_SIGNUP_STEPS.CONFIRM;
                    } else {
                        $scope.signupData.email = $scope.email;
                        $scope.currentColleagueSignupStep = $scope.COLLEAGUE_SIGNUP_STEPS.CREATE_USER;
                    }
                })
                .catch(function(error) {

                    $log.error(error);
                    if (error.status === HttpStatus.NOT_FOUND) {
                        $scope.invitationError = $translate.instant('SIGNUP.ERROR_INVITATION_EXPIRED');
                    } else {
                        $scope.invitationError = $translate.instant('SIGNUP.ERROR_GENERIC');
                    }
                });
        }
    }

});
