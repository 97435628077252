angular.module('llax')
    .controller('SentRequestsController',
        function($rootScope, $scope, $location, $translate, SentRequestResource, $modal) {

            function loadRequests() {
                SentRequestResource.query({},
                    function(requests) {
                        $scope.requests = _.map(requests, function(request) {
                            request.date = new Date(request.date);
                            request.progressTooltip = $translate.instant('REQUEST.PROGRESS.TOOLTIP', {count: request.alreadyAnswered, total: request.numberOfRecipients});
                            return request;
                        });
                    });
            }

            $scope.getDownloadLink = function(request) {
                return lax_rest_url('requestforinformation/download/' + request.id);
            };

            var CatalogAnswers = /*@ngInject*/ function($rootScope, $scope, $modalInstance, $translate, requestId) {

                $scope.loadAnswers = function(requestId) {
                    SentRequestResource.query({
                            requestId: requestId
                        },
                        function(requests) {
                            $scope.requests = requests;
                        });
                };

                $scope.loadAnswers(requestId);

                $scope.close = function() {
                    $modalInstance.dismiss('cancel');
                };
                $scope.translate = function(string) {
                    return $translate.instant(string);
                };
            };

            $scope.viewAnswers = function(requestId) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/catalog-answers-modal.tpl.html',
                    controller: CatalogAnswers,
                    resolve: {
                        requestId: function() {
                            return requestId;
                        }
                    }
                });
            };

            $scope.newRequestInformation = function() {
                $location.path("request-information");
            };

            (function init() {
                $rootScope.loadContacts();
                loadRequests();
            })();
        });
