angular.module('llax')
    .controller('ActivityStreamController', ['$rootScope', '$scope', '$http', '$injector', '$translate', '$sce', 'growl', 'MonitoringResource', 'NewsResource', 'TaskListsResource', 'ChannelService', 'TaskResource',
        function($rootScope, $scope, $http, $injector, $translate, $sce, growl, MonitoringResource, NewsResource, TaskListsResource, ChannelService, TaskResource) {

            $scope.showTask = false;
            $scope.showNews = false;
            $scope.showActivities = false;
            $scope.showAll = true;
            $scope.activityList = [];
            $scope.loadingActivities = true;
            $scope.news = {
                body: '',
                maxLength: 150,
                placeholder: $translate.instant('ACTIVITY.NEWS_PLACEHOLDER'),
                charactersRemaining: $translate.instant('ACTIVITY.CHARACTERS_REMAINING')
            };

            //IMPORTANT: Replace $scope.activityStreamTime value to ActivityStreamResource GET after backend support is implemented. currentTime here is just a placeholder. ActivityStreamTime service is in services.js
            var currentTime = new Date();
            $scope.activityStreamTime = currentTime.setHours(0);
            if ($rootScope.user.notificationsLastRead) {
                $scope.activityStreamTime = new Date($rootScope.user.notificationsLastRead);
            }

            var taskResourceUrl = lax_rest_url('taskLists'),
                taskLoaded = false,
                newsLoaded = false,
                activitiesLoaded = false;

            $scope.getTemplates = function() {
                $http.get('tpl/activities.tpl.html')
                    .then(function(response) {
                        $injector.get('$compile')(response.data);
                    });
            };
            $scope.initActivities = function() {
                $scope.getTemplates();
            };

            $rootScope.$on('channelMessageReceived', function(event, eventData) {
                var entry;
                if (eventData.event === ChannelService.TASK_ASSIGNED_EVENT && eventData.data.task && eventData.data.task.assignee === $rootScope.userId) {
                    addActivity(createTaskActivity(eventData.data.task));
                } else if (eventData.event === ChannelService.NEWS_STREAM_CHANGED_EVENT) {
                    entry = eventData.data.entry;
                    addActivity(createMonitoringActivity(entry));
                }
            });

            $rootScope.$on('selfTaskCreated', function(evt, task) {
                if (task) {
                    addActivity(createTaskActivity(task));
                }
            });

            $scope.checkIfAllActivitiesLoaded = function() {
                if (taskLoaded && newsLoaded && activitiesLoaded) {
                    $scope.loadingActivities = false;
                }
            };

            $rootScope.retrieveAllActivities = function() {
                loadTasks($scope.activityList);
                loadNews($scope.activityList);
                loadActivities($scope.activityList);
            };

            function createTaskActivity(task) {
                var dueDate = (task.dueDate === undefined) ? '' : new Date(task.dueDate);
                var template = (task.author) ? 'userCreatedTask' : 'fileImportedTask';
                var imageUrl = $rootScope.userHasImage(task.author) ? $rootScope.getUserImageUrl(task.author) : '';
                var taskActivity = {
                    'class': 'tasks',
                    'template': template,
                    'createdAt': task.createDate,
                    'data': {
                        'author': $rootScope.getUserName(task.author),
                        'title': task.title,
                        'id': task.id,
                        'imageUrl': imageUrl,
                        'description': $sce.trustAsHtml(task.notes),
                        'assignedDate': $rootScope.formatDateToSimpleString(task.createDate),
                        'dueDate': dueDate
                    }
                };
                return taskActivity;
            }

            function createMonitoringActivity(element) {
                var imageUrl = $rootScope.userHasImage(element.author) ? $rootScope.getUserImageUrl(element.author) : '';
                var template = element.module.toLowerCase();
                return {
                    'class': template,
                    'template': template,
                    'createdAt': element.timestamp,
                    'data': {
                        'author': element.author,
                        'imageUrl': imageUrl,
                        'description': element.description,
                        'receivedDate': $rootScope.formatDateToSimpleString(element.timestamp)
                    }
                };
            }

            function addActivity(activity) {
                //IMPORTANT: send POST request to update activity stream time.
                $rootScope.newActivities++;
                $rootScope.newNotifications = true;
                $scope.activityList.push(activity);
            }

            function loadTasks(activityList) {
                TaskResource.query({taskId: "mine"}, function(tasks) {
                    tasks.forEach(function(task) {
                        if (task.taskStatus == "OPEN") {
                            activityList.push(createTaskActivity(task));
                        }
                    });
                    $rootScope.newActivities += retrieveUnseenActivities(activityList, "tasks");
                });
                taskLoaded = true;
                $scope.checkIfAllActivitiesLoaded();
            }

            function loadActivities(activityList) {
                loadMonitoringItems(activityList, "activity", "ACTIVITY", function() {
                    $rootScope.newActivities += retrieveUnseenActivities($scope.activityList, "activity");
                    activitiesLoaded = true;
                });
            }

            function loadNews(activityList) {
                loadMonitoringItems(activityList, "news", "NEWS", function() {
                    $rootScope.newActivities += retrieveUnseenActivities($scope.activityList, "news");
                    newsLoaded = true;
                });
            }

            function loadMonitoringItems(activityList, template, module, callback) {

                MonitoringResource.query({
                    module: module
                }, function(response) {
                    var news = response.map(function(element) {
                        return createMonitoringActivity(element);
                    });

                    for (var i = 0; i < news.length; i++) {
                        activityList.push(news[i]);
                    }

                    callback();

                    $scope.checkIfAllActivitiesLoaded();

                });
            }

            $scope.postNews = function(news) {
                if (news) {
                    NewsResource.save(news, function() {
                        growl.success("News Posted successfully");
                    });
                }
            };

            $scope.$watchCollection('activityList', function() {
                $scope.activityList.sort(compareByCreatedDate);
            });

            function compareByCreatedDate(a, b) {
                if (a.createdAt > b.createdAt)
                    return -1;
                if (a.createdAt < b.createdAt)
                    return 1;
                return 0;
            }
            $scope.toggleTask = function() {
                $scope.showTask = true;
                $scope.showNews = false;
                $scope.showActivities = false;
                $scope.showAll = false;
            };

            $scope.toggleNews = function() {
                $scope.showTask = false;
                $scope.showNews = true;
                $scope.showActivities = false;
                $scope.showAll = false;
            };
            $scope.toggleActivities = function() {
                $scope.showTask = false;
                $scope.showNews = false;
                $scope.showActivities = true;
                $scope.showAll = false;
            };
            $scope.toggleShowAll = function() {
                $scope.showTask = false;
                $scope.showNews = false;
                $scope.showActivities = false;
                $scope.showAll = true;
            };

            $scope.showClass = function(activity_class) {
                if ($scope.showAll) {
                    return true;
                } else if (activity_class == 'tasks') {
                    return $scope.showTask;
                } else if (activity_class == 'news') {
                    return $scope.showNews;
                } else if (activity_class == 'activity') {
                    return $scope.showActivities;
                }
            };

            function retrieveUnseenActivities(array, type) {
                var unseenCounter = 0;
                for (var i = 0; i < array.length - 1; i++) {
                    if (array[i].class === type) {
                        if (array[i].createdAt > $scope.activityStreamTime) {
                            unseenCounter++;
                        }
                    }
                }
                return unseenCounter;
            }

            (function init() {
                $scope.initActivities();
            })();

        }
    ]);
