function QueryParser(attributesMap_, EnumAttributeService, $log, $translate) {

    var translate = $translate.instant;

    var attributesByName = {};
    var attributesByLabel = {};

    function init(attributesMap) {

        var key, label;

        for (var attributeName in attributesMap) {

            var attribute = attributesMap[attributeName];
            if (_.isEmpty(attribute)) {
                continue;
            }
            attribute = angular.copy(attribute);
            attributesByName[attribute.name] = attribute;

            // Locale translation is preferred, so only add default label, if it does not exist yet!
            label = attribute.label;
            if (!_.isEmpty(label) && !_.has(attributesByLabel, label.toLocaleLowerCase())) {
                attributesByLabel[label.toLocaleLowerCase()] = attribute;
            }

            key = "attribute." + attribute.name;
            label = translate(key);
            if (label !== key) {
                attributesByLabel[label.toLocaleLowerCase()] = attribute;
            }

            loadAttributeOptionsAsync(attribute);
        }

    }
    init(attributesMap_);

    function transformAttributeName(attributeNameOrLabel) {
        if (_.isEmpty(attributeNameOrLabel)) {
            return attributeNameOrLabel;
        }
        attributeNameOrLabel = _.trim(attributeNameOrLabel);
        var attribute = _.find(attributesByName, ['name', attributeNameOrLabel]);
        if (_.isEmpty(attribute)) {
            attribute = _.find(attributesByLabel, ['label', attributeNameOrLabel]);
        }
        if (_.isEmpty(attribute)) {
            attribute = _.find(attributesByName, ['name', attributeNameOrLabel.toLocaleLowerCase()]);
        }
        if (_.isEmpty(attribute)) {
            attribute = attributesByLabel[attributeNameOrLabel.toLocaleLowerCase()];
        }

        var result = !_.isEmpty(attribute) ? attribute.name : attributeNameOrLabel;

        return result;
    }

    function transformValue(attribute, value) {

        if (_.isEmpty(attribute) || _.isEmpty(attribute.optionsByLabel)) {
            return value;
        }

        value = _.trim(value);

        var option = attribute.optionsByLabel[value];
        var result = value;

        if (_.isEmpty(option)) {
            option = attribute.optionsByLabel[value.toLocaleLowerCase()];
        }
        if (!_.isEmpty(option)) {
            result = option;
        }
        return result;
    }

    this.isLegal = function(query) {

        if (_.isEmpty(query)) {
            return true;
        }

        var illegalQueryStartRegExp = /^\s*((AND|OR)\s+|:|=|,|\))/;
        if (query.search(illegalQueryStartRegExp) >= 0) {
            return false;
        }
        var illegalQueryEndRegExp = /(\s+(AND|OR)|:|=|,|\()\s*$/;
        if (query.search(illegalQueryEndRegExp) >= 0) {
            return false;
        }

        return true;
    };

    this.parseQuery = function(query) {

        if (_.isEmpty(query)) {
            return query;
        }

        // Remove all surplus spaces
        query = query.replace(/\s+(?= )/g, '');

        // Construct new query
        var result = "";

        // Split the query into terms
        var queryTerms = query.split(/\s+(AND|OR)\s+|(\(|\))/);
        for (var i = 0; i < queryTerms.length; i++) {

            var queryTerm = queryTerms[i];
            if (queryTerm === 'AND' || queryTerm === 'OR') {
                result += ' ' + queryTerm + ' ';
                continue;
            } else if (queryTerm === '(' || queryTerm === ')') {
                result += queryTerm;
                continue;
            } else if (_.isEmpty(queryTerm)) {
                continue;
            }

            // Find attributeName, dimension, operator and value parts
            var searchRegExp = /^([^<>=:\[]+)(\[[^<>=:]*\])?\s?(<|>|<=|>=|=|:)\s?([^<>=]+)$/;
            var searchResult = searchRegExp.exec(queryTerm);
            if (searchResult === null) {
                result += queryTerm;
                continue;
            }

            var fullMatch = searchResult[0];
            var attributeName = _.trim(searchResult[1]);
            var dimension = _.trim(_.defaultTo(searchResult[2], ""));
            var operator = _.trim(searchResult[3]);
            var value = _.trim(searchResult[4]);

            if (operator === '=') {
                operator = ':';
            }

            attributeName = transformAttributeName(attributeName);
            var attribute = attributesByName[attributeName];
            if (!_.isEmpty(attribute)) {
                value = transformValue(attribute, value);
            }

            var newPart = attributeName + dimension + operator + value;
            result += newPart;

        }

        return result;
    };

    function loadAttributeOptionsAsync(attribute) {
        return EnumAttributeService.getAttributeOptionsAsync(attribute)
            .then(function(options) {

                if (_.isEmpty(options)) {
                    return;
                }

                var optionsByLabel = {};
                var key, label;
                for (var i = 0; i < options.length; i++) {

                    var option = options[i];

                    // Locale translation is preferred, so only add default label, if it does not exist yet!
                    label = option.value;
                    if (!_.isEmpty(label) && !_.has(optionsByLabel, label.toLocaleLowerCase())) {
                        optionsByLabel[label.toLocaleLowerCase()] = option.key;
                    }

                    key = "attribute." + attribute.name + ".option." + option.key;
                    label = translate(key);
                    if (label === key) {
                        key = "option." + option.key;
                        label = translate(key);
                    }
                    if (label !== key) {
                        optionsByLabel[label.toLocaleLowerCase()] = option.key;
                    }

                }
                attribute.optionsByLabel = optionsByLabel;
            })
            .catch(function(error) {
                $log.error('QueryParser: Could not load options for', attribute);
            });
    }

}

