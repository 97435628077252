angular.module('llax')
    .controller('MassUpdateController',
        function($log, $rootScope, $scope, $modalInstance, $filter, $timeout, $q, $routeParams, growl, data, AssetFoldersService,
            Auth, GroupAttributeService, MassUpdateResource, SelectionResource, ReferenceAttributesService) {

            $scope.treeFilter = $filter('uiTreeFilter');
            $scope.supportedFields = ['name', 'label'];

            $scope.isAttributesLoading = true;
            $scope.temporaryModelForTypeahead = {};
            $scope.formattedSingleReferences = {};
            $scope.allCategories = [];
            $scope.attributeStates = {};
            $scope.isItemEditable = true;
            $scope.disableDeleteConfirmation = true;
            GroupAttributeService.initializeScope($scope);

            _.forEach($rootScope.dataModel.allCategories(), function(category) {
                if (Auth.hasAnyPermission(Auth.OBJECT_TYPE_ITEMS, 'edit', {category__: category.name})) {
                    $scope.allCategories.push(category);
                }
            });

            $scope.onBeforeStepChange = function(event) {
                if (event.toStepId === 2 && $scope.selectedAttributesList.length < 1) {
                    growl.warning('MASS_UPDATE_EDITOR.NO_ATTRIBUTES_SELECTED');
                    return false;
                }
                return true;
            };

            $scope.onAfterStepChange = function(event) {
                if (event.toStepId === 2) {
                    angular.forEach($scope.data.selectedItems, function(primaryKey) {
                        $scope.selectedItems[primaryKey] = true;
                    });
                    var itemToSave = $rootScope.cleanupItem($scope.item);
                    itemToSave = $rootScope.stringifyDeepValues(itemToSave);

                    var itemData = {};
                    _.forEach($scope.attributesEditedMap, function(value, key) {
                        if (value) {
                            if (isDimensional(key)) {
                                _.forEach(value, function(d_v, d_k) {
                                    if (value[d_k]) {
                                        if (!itemData[key]) {
                                            itemData[key] = {};
                                        }
                                        if (_.hasIn(itemToSave, [key, d_k])) {
                                            itemData[key][d_k] = itemToSave[key][d_k];
                                        } else {
                                            itemData[key][d_k] = null;
                                        }
                                    }
                                });
                            } else {
                                itemData[key] = itemToSave[key] || null;
                            }
                        }
                    });

                    if ($scope.data.selection.primaryKeys) {
                        $scope.data.selection.primaryKeys = _.filter($scope.data.selection.primaryKeys, function(primaryKey) {
                            return $scope.selectedItems[primaryKey];
                        });
                    }

                    SelectionResource.save($scope.data.selection, function(response) {
                        $scope.massUpdateOptions = {
                            selectionId: response.selectionId,
                            itemData: itemData
                        };
                        if (response.numberOfItems > 0) {
                            $scope.numberOfItems = response.numberOfItems;
                            $scope.estimatedNumberOfItems = null;
                        } else if (response.numberOfItems < 0) {
                            $scope.numberOfItems = null;
                            $scope.estimatedNumberOfItems = -response.numberOfItems;
                        } else {
                            $scope.numberOfItems = null;
                            $scope.estimatedNumberOfItems = null;
                        }
                    });
                }
                return true;
            };

            $scope.runMassUpdate = function() {

                 MassUpdateResource.save($scope.massUpdateOptions, function(response) {
                     growl.success('MASS_UPDATE_EDITOR.RUN.SUCCESS_MESSAGE');
                     $modalInstance.close();
                 }, function(response) {
                     growl.error('MASS_UPDATE_EDITOR.RUN.FAILED_MESSAGE');
                 });
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            function getFilteredAttributesDeferred() {
                var deferred = $q.defer();
                var filteredAttributes;
                var allAttributes = $scope.dataModel.filteredLayoutAttributes('mass_update', $scope.item);
                if (!_.isNil($routeParams.category)) {
                    var attrs = $rootScope.dataModel.categoryAttributes($routeParams.category);
                    allAttributes = _.intersectionBy(allAttributes, attrs, 'name');
                }
                $rootScope.prepareAttributes(allAttributes);
                var categoryAttribute = $scope.dataModel.attribute('category__');
                categoryAttribute.params = {customRenderer: 'attribute_category.html'};

                if (allAttributes.length > 0) {
                    filteredAttributes = _.chain(allAttributes)
                        .uniq()
                        .filter(function(attribute) {
                            return !$scope.dataModel.potentialPrimaryKeyPart(attribute.name) &&
                                !$scope.dataModel.isMetaAttribute(attribute.name);
                        })
                        .push(categoryAttribute)
                        .forEach(function(attribute) {
                            $rootScope.setInputRenderer(attribute);
                            attribute.label = $scope.translateAttribute(attribute);
                            attribute.description = $rootScope.translateAttributeDescription(attribute);

                            if (attribute.params && attribute.params.values) {
                                angular.forEach(attribute.params.values, function(value) {
                                    value.translatedValue = $rootScope.translateOption(value, attribute);
                                });
                            }
                        }).value();
                    deferred.resolve(filteredAttributes);
                }
                return deferred.promise;
            }

            $scope.$watch('item', function(newValue, oldValue) {
                var key = Object.keys(newValue).forEach(function(key) {
                    if (newValue[key] != oldValue[key]) {
                        if (isDimensional(key)) {
                            _.forEach(newValue[key], function(d_v, d_k) {
                                if (newValue[key][d_k]) {
                                    if (!$scope.attributesEditedMap[key]) {
                                        $scope.attributesEditedMap[key] = {};
                                    }
                                    $scope.attributesEditedMap[key][d_k] = true;
                                }
                            });
                        } else {
                            $scope.attributesEditedMap[key] = true;
                        }
                    }
                });
            }, true);

            (function init() {
                $scope.data = data;
                $scope.item = {};
                $scope.selectedAttributesList = [];
                $scope.selectedItems = {};
                $scope.attributesEditedMap = {};
                $timeout(function() {
                    getFilteredAttributesDeferred().then(function(attributes) {
                        $scope.attributeList = attributes;
                        if ($scope.selectedAttributesList.length > 0) {
                            $scope.attributeList = $scope.attributeList.filter(function(at) {
                                return $scope.selectedAttributesList.indexOf(at) < 0;
                            });
                        }
                        $scope.isAttributesLoading = false;
                    });
                }, 0);
            })();

            $scope.addToReferenceList = function(childItem, a, label, model) {
                var reference = $scope.item[a.name];

                reference.push(childItem);
                $scope.gridOptionsMap[a.name].data = $scope.item[a.name];

                gridResizeHeight($scope.item[a.name], $scope.gridOptionsMap[a.name], $scope.gridApiMap[a.name].grid);

                //add found element to $scope.item
                if (reference.length < 10) {
                    $scope.gridOptionsMap[a.name].minRowsToShow++;
                }

                //clear input field, when item is added
                delete $scope.temporaryModelForTypeahead[a.name];

            };

            $scope.addSingleReference = function(referencedItem, a, label, model) {
                ReferenceAttributesService.loadAndFormatItemAsync(referencedItem.primaryKey__, null, a)
                    .then(function(result) {
                        $timeout(function() {
                            ReferenceAttributesService.checkModelAndEval(model, a, referencedItem, $scope);
                            // "Hack" to bind a descriptive String including all filter elements to the item
                            $scope.temporaryModelForTypeahead[a.name] = result;
                        }, 0);
                    });
            };

            $scope.findItems = function(keywordQuery , attribute) {
                return ReferenceAttributesService.loadAndFormatItemsToBeReferencedAsync(keywordQuery, null, attribute);
            };

            $scope.loadAndFormatSingleReferences = function(item, attribute) {
                ReferenceAttributesService.loadAndFormatItemAsync(item[attribute.name], item, attribute)
                    .then(function(result) {
                        $scope.formattedSingleReferences[attribute.name] = result;
                    });
            };

            $scope.queryAdditionalCategories = function(query, attribute, leaf, limit) {
                var dataModel = _.get(attribute.params, 'additionalModule');
                var extension = _.get(attribute.params, 'extension');
                leaf = leaf || null;
                var result = $rootScope.queryCategories(query, dataModel, extension, leaf, limit);
                return result;
            };

            $scope.gridOptionsMap = {};
            $scope.gridApiMap = {};
            $scope.showGrid = {};

            $scope.initGridOptions = function(attributeDefinition, item, name, model, config) {
                if(!item[name]) {
                    item[name] = [];
                }
                var data = item[name];

                var memberAttributes = $rootScope.dataModel.getMemberAttributes(attributeDefinition, true);
                var options = $rootScope.getOptions($scope, attributeDefinition, memberAttributes, data, config);

                if (data && data.length > 0) {
                    if (data.length >= 10) {
                        options.minRowsToShow = 10;
                    } else {
                        options.minRowsToShow = data.length + 1;
                    }
                } else {
                    options.minRowsToShow = (attributeDefinition.defaultValue) ? attributeDefinition.defaultValue.length : 1;
                }

                options.onRegisterApi = function(gridApi) {
                    if (gridApi.cellNav) {
                        gridApi.cellNav.on.viewPortKeyDown($scope, function($event, rowCol) {
                            if ($event.keyCode === 32) {
                                $event.preventDefault();
                            }
                        });
                    }
                    $scope.gridApiMap[name] = gridApi;
                };

                $scope.gridOptionsMap[name] = options;

                $timeout(function() {
                    $scope.showGrid[name] = true;
                }, 100);

                return true;
            };

            function gridResizeHeight(newData, options, grid) {

                // Initialize scrollbars (TODO: move to controller??)
                // uiGridCtrl.scrollbars = [];
                if (!_.isEmpty(newData)) {
                    if (newData.length >= 10) {
                        options.minRowsToShow = 10;
                    } else {
                        options.minRowsToShow = newData.length;
                    }
                } else {
                    options.minRowsToShow = 0;
                }

                // Figure out the new height
                var contentHeight = options.minRowsToShow * options.rowHeight + (options.rowHeight * 0.5);
                var headerHeight = grid.options.hideHeader ? 0 : options.headerRowHeight;
                var footerHeight = grid.options.showFooter ? options.footerRowHeight : 0;
                var scrollbarHeight = grid.options.enableScrollbars ? gridUtil.getScrollbarWidth() : 0;

                // Calculates the maximum number of filters in the columns
                var maxNumberOfFilters = 0;
                angular.forEach(grid.options.columnDefs, function (col) {
                    if (col.hasOwnProperty('filter')) {
                        if (maxNumberOfFilters < 1) {
                            maxNumberOfFilters = 1;
                        }
                    } else if (col.hasOwnProperty('filters')) {
                        if (maxNumberOfFilters < col.filters.length) {
                            maxNumberOfFilters = col.filters.length;
                        }
                    }
                });

                var filterHeight = maxNumberOfFilters * headerHeight;
                var newHeight = headerHeight + contentHeight + footerHeight + scrollbarHeight + filterHeight;
                var cssClass = ".grid" + grid.id;
                var myElement = angular.element( document.querySelector(cssClass));
                myElement.css('height', newHeight + 'px');

                grid.gridHeight = $scope.gridHeight = newHeight;

                // Run initial canvas refresh
                grid.refreshCanvas();

            }

            $scope.getGridData = function(model, attribute) {
                return $scope.gridOptionsMap[attribute.name];
            };

            $scope.getShowGrid = function(model, attribute) {
                return $scope.showGrid[attribute.name];
            };

            $scope.addElementToTableModel = function(attributeDefinition, model) {
                if (!_.isArray($scope.item[attributeDefinition.name])) {
                    $scope.item[attributeDefinition.name] = [];
                }
                var rows = $scope.item[attributeDefinition.name];
                rows.push({});
                if (rows.length < 10) {
                    $scope.gridOptionsMap[attributeDefinition.name].minRowsToShow++;
                }

                $scope.gridApiMap[attributeDefinition.name].grid.options.data = $scope.item[attributeDefinition.name];

                gridResizeHeight($scope.item[attributeDefinition.name], $scope.gridOptionsMap[attributeDefinition.name], $scope.gridApiMap[attributeDefinition.name].grid);

                $timeout(function() {
                    var gridApi = $scope.gridApiMap[attributeDefinition.name];
                    if (!gridApi) {
                        return;
                    }
                    var lastElement = rows[rows.length - 1];
                    if (gridApi.cellNav) {
                        gridApi.cellNav.scrollToFocus(lastElement, $scope.gridOptionsMap[attributeDefinition.name].columnDefs[0]);
                    } else {
                        gridApi.core.scrollTo(lastElement, $scope.gridOptionsMap[attributeDefinition.name].columnDefs[0]);
                    }
                }, 0);
            };

            $scope.removeRow = function(row, attributeName) {
                var rows = $scope.item[attributeName];
                if (rows.length > 0 && rows.length <= 10) {
                    $scope.gridOptionsMap[attributeName].minRowsToShow--;
                }
                _.remove(rows, row.entity);
            };

            $scope.uploadFileForItem = function(attributeName, sectionName, type) {
                var config = {};
                switch (type) {
                    case "Image":
                        config = {
                            autoUpload: true,
                            filters: ['imageFilter'],
                            accept: 'image/jpeg,image/gif,image/png'
                        };
                        var maxFileSize = $scope.dataModel.sectionAttributeParam($scope.currentLayout, sectionName, attributeName,
                            'maxFileSize');
                        if (maxFileSize) {
                            config.filters.push('maxFileSizeFilter');
                            config.maxFileSize = maxFileSize;
                        }
                        break;
                    case "Document":
                        config = {
                            autoUpload: true
                        };
                        break;
                }

                var attribute = $scope.dataModel.attribute(attributeName);
                var publicAsset = !(attribute && attribute.params.confidential === true);

                var uploadUrl = AssetFoldersService.getDefaultUploadUrl();
                return angular.extend({
                    url: uploadUrl,
                    reset: true,
                    formData: [],
                    noNameEncoding: true,
                    useFilename: true,
                    uploadComplete: function(response) {
                        if (publicAsset) {
                            AssetFoldersService.getPublicAssetUrlAsync(response, response.path)
                                .then(function(linkedPublicAsset) {
                                    $scope.item[attributeName] = linkedPublicAsset.publicAssetUrl;
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else {
                            $scope.item[attributeName] = response.privateAssetUrl;
                        }
                    },
                    onErrorItem: function(item, response, status, headers) {
                        $log.error(response);

                        if (!_.isNil(response.errorCode)) {
                            growl.error(response.message, { variables : { name: item.file.name } });
                        } else {
                            growl.error("ASSET_FOLDER.ERROR_OCCURRED");
                        }
                    },
                    onFileSelected: function(linkedAsset, linkedAssetPath, done) {
                        if (publicAsset) {
                            AssetFoldersService.getPublicAssetUrlAsync(linkedAsset, linkedAssetPath)
                                .then(function(linkedPublicAsset) {
                                    done(linkedPublicAsset);
                                }, function(error) {
                                    $log.error(error);
                                });
                        } else {
                            done(linkedAsset);
                        }
                    }
                }, config);
            };

            $scope.hasAttributeState = function(attribute, stateName) {
                if (_.isEmpty($scope.attributeStates)) {
                    return false;
                }
                var states = $scope.attributeStates[(_.isObject(attribute) ? attribute.name : attribute)];
                return !_.isEmpty(states) && _.includes(states, stateName);
            };

            $scope.isAttributeReadonly = function(attribute) {

                // Item is not editable
                if (!$scope.isItemEditable) {
                    return true;
                }

                // Attribute was set to 'readonly'
                if (_.isObject(attribute) && attribute.readonly) {
                    return true;
                }

                // Attribute was added to list of readonly attributes (legacy function, rather use 'states' by now!)
                if (!_.isEmpty($scope.item.readonlyAttributes__) &&
                    _.includes($scope.item.readonlyAttributes__, (_.isObject(attribute) ? attribute.name : attribute))) {
                    return true;
                }

                // Attribute state 'readonly' is set
                return $scope.hasAttributeState(attribute, 'readonly');
            };

            $scope.isAttributeHidden = function(attribute) {
                if (!$scope.isAttributeVisible(attribute)) {
                    return true;
                }
                return $scope.hasAttributeState(attribute, 'hidden');
            };

            // TODO Dummy function to ignore loading of further attributes
            $scope.updateAdditionalCategoryAttributes = function() {};

            function isDimensional(attrName) {
                var a = $rootScope.dataModel.attribute(attrName);
                return a.typeName == 'Dimensional';
            }

        }
    );
