angular.module('llax')
    .controller('NavigationController',
        function($dialogs, $location, $log, $rootScope, $route, $scope, $translate, $timeout, $window, growl,
            Auth, BillingResource, ChannelService, OrganizationService, SessionDataService,
            UsageLimitsResource, UserResource) {

            $scope.usageLimitStatus = "";
            // Used for collapsing main navigation in mobile
            $scope.isNavCollapsed = false;
            $scope.isProfileCollapsed = false;
            $scope.allUserOrganizations = [];
            $scope.filteredUserOrganizations = [];
            $scope.userOrganizationFilter = { value: '' };

            // IMPORTANT: Replace $scope.activityStreamTime value to ActivityStreamResource GET after backend support is implemented. currentTime here is just a placeholder. ActivityStreamTime service is in services.js
            var currentTime = new Date();
            $scope.activityStreamTime = currentTime.setHours(0);

            function updateUsageLimits() {
                    var status = "";
                    UsageLimitsResource.get(function(response) {
                        $rootScope.usageLimits = response;
                        $rootScope.usageLimits.limits.ITEMS = Math.abs($rootScope.usageLimits.limits.ITEMS);
                        var itemCount = $rootScope.usageLimits.usages.ITEMS;
                        var itemLimit = $rootScope.usageLimits.limits.ITEMS;
                        if (itemCount >= itemLimit) {
                            status = 'itemLimitExceeded';
                        } else if (itemCount > (itemLimit * 0.9)) {
                            status = 'nearItemLimit';
                        }
                        $scope.usageLimitStatus = status;
                    });
            }

            $scope.getUsageLimitLabel = function() {
                var label = "";
                if ($scope.usageLimitStatus === 'nearItemLimit') {
                    label = $translate.instant('USAGE_LIMIT.NEAR_ITEM_LIMIT', { usage: $rootScope.usageLimits.usages.ITEMS, limit: $rootScope.usageLimits.limits.ITEMS });
                } else if ($scope.usageLimitStatus === 'itemLimitExceeded') {
                    label = $translate.instant('USAGE_LIMIT.ITEM_LIMIT_EXCEEDED', { usage: $rootScope.usageLimits.usages.ITEMS, limit: $rootScope.usageLimits.limits.ITEMS });
                }
                return label;
            };

            $scope.isActive = function(route) {
                return route === $location.path();
            };

            $scope.getWebApps = function() {
                var webApps = _.isNil($scope.organization) ? {} : ($scope.organization.webApps || {});
                return _.keys(webApps);
            };

            $scope.logout = function() {

                if ($rootScope.loggingIn || $rootScope.loggingOut) {
                    return;
                }

                // Block the main `ng-view` until we have logged out, to prevent flickering of the
                // previous loaded route in the login page, `loggingOut` will be reset upon the browser reload.
                $rootScope.loggingOut = true;
                $scope.ignoreInvalidToken = true;

                $log.info("Logging out");

                try {

                    ChannelService.disconnect();
                    SessionDataService.clearKeys();
                    $rootScope.destroyAllNotifications();
                    OrganizationService.logout();
                    doLogout().finally(function() {

                        $scope.ignoreInvalidToken = false;

                        angular.element('body, #content').removeClass('sidebar-minified');

                        // Reload system settings which also reloads any customUi settings and re-sets the design
                        $rootScope.initializeSettings();

                        try {
                            // Stupid "hopscotch" throws exceptions!
                            // Why is that here anyway and why did no one test it correctly?
                            hopscotch.endTour();
                        } catch (e) {
                        }
                        hopscotch.getCalloutManager().removeAllCallouts();

                        $rootScope.refreshTranslations();

                        // TODO: It should be "safe" to redirect to /login,
                        // because actually the associated controller should take care of proper
                        // "redirection"!"
                        if (!$rootScope.singleSignOn) {
                            // Reset the currently display AngularJs view/route, so we won't have any left overs or dashboard flickering
                            $location.path('/login').replace();
                            $route.reload();
                            // Then, reload the web app instance
                            window.location.href = '/login';
                        }

                    });

                } catch (error) {
                    $rootScope.loggingOut = false;
                    $scope.ignoreInvalidToken = false;
                }

            };

            $scope.$on('InvalidToken', function() {
                if ($scope.ignoreInvalidToken) {
                    return;
                }
                $log.warn('Unauthorized');
                $scope.logout();
            });

            if ($location.path().startsWith('/confirm') || $location.path().startsWith('/register')) {
                doLogout();
            }

            function doLogout() {
                return Auth.logout(function(response, headers) {

                    $rootScope.user = {
                        isLoggedIn: false
                    };
                    $rootScope.userId = null;
                    $rootScope.users = null;

                });
            }

            $scope.getSupportLink = function() {
                return lax_rest_url('support');
            };

            $scope.toggleActivityStream = function() {
                $rootScope.retrieveAllActivities();
                //IMPORTANT: Replace $scope.activityStreamTime value to ActivityStreamResource GET after backend support is implemented. currentTime here is just a placeholder. ActivityStreamTime service is in services.js
                var currentTime = new Date();
                //IMPORTANT: ActivityStreamResource POST method call here. This updates the stream time and marks the activities as "seen".
                $scope.activityStreamTime = currentTime;
                console.log("Activity Stream Toggled! Stream time is now -->" + $scope.activityStreamTime);
                $rootScope.newActivities = 0;
                $rootScope.newNotifications = false;
                $rootScope.activityStreamVisible = !$rootScope.activityStreamVisible;
                var presentDate = new Date();
                var reqDateVal = presentDate.toISOString();
                UserResource.updateNotificationsLastRead({ lastRead: reqDateVal }, function(response) {
                    $rootScope.user.notificationsLastRead = response.notificationsLastRead;
                    if ($rootScope.user.notificationsLastRead) {
                        $scope.activityStreamTime = new Date($rootScope.user.notificationsLastRead);
                    }
                });
            };

            $scope.hide = function(){
                $rootScope.activityStreamVisible = false;
            };

            $scope.$on('UserIsLoggedIn', function() {
                OrganizationService.$organization.subscribe(function(organization) {
                    $scope.organization = organization;
                });
                if (_.isNil($rootScope.planBillingProfiles)) {
                    BillingResource.getPlanBillingProfiles({}, function(response) {
                        $rootScope.planBillingProfiles = {};
                        _.forEach(response, function(profile) {
                            $rootScope.planBillingProfiles[profile.plan] = {
                                label: profile.productName,
                                limits: profile.limits
                            };
                        });
                        if (_.isEmpty($scope.organization.orderedProfile)) {
                            $scope.organization.orderedProfile = response[0].plan;
                        }
                    });
                }
                if (_.isNil($rootScope.usageLimits)) {
                    updateUsageLimits();
                }
            });

            $scope.$on('updateOrganizationUsageLimit', function(event, count) {
                OrganizationService.update({ itemCount: $scope.organization.itemCount + count });
                updateUsageLimits();
            });

            $scope.$on('dataModelLoaded', function() {
                $scope.documentationUrl = $rootScope.translate("DOCUMENTATION.URL", null);
                $scope.documentationTitle = $rootScope.translate("DOCUMENTATION.TITLE", null);
                $scope.showDashboardSettings = !_.isEmpty($rootScope.dataModel.getDashboardAttributes());
                $scope.showDashboardAdd = !_.isNil($rootScope.dataModelHash);
            });

            $scope.$on('toggleSidebarNavigation', function() {
                $scope.$broadcast('toggleSidebar');
            });

            $scope.gotoDashboard = function(mode) {
                $location.url('dashboard').search('mode', mode);
            };

            $scope.getUserOrganizations = function() {
                UserResource.getAccountOrganizations(function (result) {

                    var accountOrganizations = result.toJSON();

                    OrganizationService.getOrganization()
                        .subscribe(function(currentOrganization) {

                            // Subscribe function will be called on each change on the organization, so let's
                            // wait until we have one loaded
                            if (!_.isNil(currentOrganization)) {

                                var userOrganizations = [];
                                _.forEach(accountOrganizations, function(accountOrganization, organizationId) {

                                    var account = _.get(accountOrganization, "account");
                                    var organization = _.get(accountOrganization, "organization");

                                    // Use the actual "current" organization instead
                                    if (organization.id == currentOrganization.id) {
                                        currentOrganization.isCurrent = true;
                                        organization = currentOrganization;
                                    }

                                    // Mark organization according to the type of account
                                    organization.systemAccount = account.systemAccount;
                                    organization.managingAccount = account.managingAccount;

                                    if (organization.isCurrent) {
                                        userOrganizations.unshift(organization);
                                    } else {
                                        userOrganizations.push(organization);
                                    }

                                });

                                $scope.allUserOrganizations = userOrganizations;

                                // Force a digest cycle to update the UI
                                $timeout(function() {
                                    $scope.filteredUserOrganizations = userOrganizations;
                                }, 0);
                            }

                        });
                });
            };

            $scope.deleteAccount = function($event, organization) {

                // Since the "li" element is already assigned a "ng-click" attribute, we need to prevent the "delete" event from being propagated!
                $event.preventDefault();
                $event.stopPropagation();

                if (organization == $rootScope.organization.id) {
                    return;
                }

                var message;
                if (organization.managingAccount) {
                    message = $translate.instant('USER.DELETE_MANAGING_ACCOUNT', { organizationName: organization.name });
                } else if (organization.systemAccount) {
                    message = $translate.instant('USER.DELETE_SYSTEM_ACCOUNT', { organizationName: organization.name });
                } else {
                    $log.warn("Account for organization " + organization.id + " is neither a managing nor a system acccount");
                    return;
                }

                var confirmDialog = $dialogs.confirm('', message);
                confirmDialog.result.then(function() {

                    UserResource.deleteAccount({
                        organizationId: organization.id
                    }, function() {
                        growl.success('USER.ACCOUNT_DELETED');
                        $scope.getUserOrganizations();
                    });

                });

            };

            $scope.switchOrganization = function(newOrganization) {

                if (newOrganization.id == $rootScope.organization.id) {
                    return;
                }

                growl.info('USER.SWITCHING_ORGANIZATION', {
                    variables: { organizationName: newOrganization.name },
                    ttl: -1
                });

                $timeout(function() {
                    UserResource.switchOrganization({
                        organizationId: newOrganization.id
                    }, function(result) {
                        var host = _.get(result.user, 'customSite');
                        $window.location.hostname = host;
                        $window.location.href = "/";
                    });
                }, 2000);

            };

            $scope.filterUserOrganizations = function() {
                var searchText = _.toLower($scope.userOrganizationFilter.value);
                $scope.filteredUserOrganizations = _.filter($scope.allUserOrganizations, function(organization) {
                    if (!_.isEmpty(organization.gln)) {
                        return _.includes(_.toLower(organization.name), searchText) || _.includes(_.toLower(organization.gln), searchText);
                    } else {
                        return _.includes(_.toLower(organization.name), searchText);
                    }
                });
            };

            $scope.goToOrganizationProfile = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $location.path('/organization').replace();
            };

            $scope.goToUserProfile = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $location.path('/user').replace();
            };

            $scope.getAdditionalMenuUrl= function(urlOrUrls) {

                if (_.isString(urlOrUrls)) {
                    return $translate.instant(urlOrUrls);
                } else {
                    var languageSpecificUrl = urlOrUrls[$rootScope.language];

                    if (!_.isEmpty(languageSpecificUrl)) {
                        return languageSpecificUrl;
                    } else {
                        $log.info("Could not get additional menu url '%s', falling back by using the first found key", JSON.stringify(urlOrUrls));
                        var firstKey = _.keys(urlOrUrls)[0];
                        return $translate.instant(urlOrUrls[firstKey]);
                    }
                }
            };

        })
    .directive('clickOutside', function ($document) {
        return {
            restrict: 'A',
            scope: {
                clickOutside: '&'
            },
            link: function (scope, el, attr) {
                $document.on('click', function (e) {
                    var $parentElement = document.getElementById(el[0].id);
                    var $buttonElement = document.getElementById('activity-feed');
                    if (!_.isNil($parentElement) &&
                        !$parentElement.contains(e.target) &&
                        !el[0].contains(e.target) &&
                        !$buttonElement.contains(e.target)) {
                        scope.$apply(function () {
                            scope.$eval(scope.clickOutside);
                        });
                    }
                });
            }
        };
    });
