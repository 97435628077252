angular.module('llax.services')
    .service('MultiDimensionalAttributeService', function($translate) {

        this.validateMultiDimensionalEntry = function(row, rows, multiDimensionalAttribute) {
            row.errors = [];

            var areKeysUnique = this.validateUniqueKeys(row.entity, _.map(rows, 'entity'), multiDimensionalAttribute.keyMembers);
            if (!areKeysUnique) {
                row.errors.push($translate.instant('MULTI_DIMENSIONAL.NOT_UNIQUE_VALIDATION_MESSAGE'));
            }

            var atLeastOneKeyAttributeProvided = this.checkAtLeastOnePropertyProvided(row.entity, multiDimensionalAttribute.keyMembers);
            if (!atLeastOneKeyAttributeProvided) {
                row.errors.push($translate.instant('MULTI_DIMENSIONAL.NO_KEYS_VALIDATION_MESSAGE'));
            }

            var atLeastOneValueAttributeProvided = this.checkAtLeastOnePropertyProvided(row.entity, multiDimensionalAttribute.valueMembers);
            if (!atLeastOneValueAttributeProvided) {
                row.errors.push($translate.instant('MULTI_DIMENSIONAL.NO_VALUES_VALIDATION_MESSAGE'));
            }

            if (atLeastOneValueAttributeProvided &&
                atLeastOneKeyAttributeProvided &&
                areKeysUnique) {
                return true;
            } else {
                return false;
            }
        };

        this.validateUniqueKeys = function(entry, allEntries, keyMembers) {
            var _this = this;
            var entriesWithTheSameKeys = _.filter(allEntries, function(rowEntry) {
                return _.isEqualWith(entry, rowEntry, function(firstEntry, secondEntry) {
                    return _this.multiDimensionalEntriesComparator(firstEntry, secondEntry, keyMembers);
                });
            });

            if (entriesWithTheSameKeys.length > 1) {
                return false;
            } else {
                return true;
            }
        };

        this.multiDimensionalEntriesComparator = function(firstEntry, secondEntry, compareProperties) {
            var isEqual = true;
            _.forEach(compareProperties, function(property) {
                var firstValue = _.toString(firstEntry[property]);
                var secondValue = _.toString(secondEntry[property]);

                if (firstValue != secondValue) {
                    isEqual = false;
                    return false; // Break early
                }
            });
            return isEqual;
        };

        this.checkAtLeastOnePropertyProvided = function(entity, properties) {
            var atLeastOnePropProvided = false;
            _.forEach(properties, function(property) {
                if (!_.isEmpty(entity[property])) {
                    atLeastOnePropProvided = true;
                    return false; //break early
                }
            });

            return atLeastOnePropProvided;
        };

        this.isKeyAttribute = function(memberAttribute, multiDimensionalAttribute) {
            return _.includes(multiDimensionalAttribute.keyMembers, memberAttribute.name);
        };
    });