angular.module('llax')
    .controller('TaskListController',
        function($rootScope, $scope, $location, $routeParams, $modal, $controller, $window, $timeout, $translate, growl, HttpHeader, QueryTasksResource, TaskResource, TaskListsResource, Auth) {

            $controller('EditorContainerController', {
                $scope: $scope
            });

            var LocalStorage = $window.localStorage;
            var TASK_SETTINGS = 'tasks.settings';
            var TRANSLATION_UNASSIGNED = 'TASK.UNASSIGNED';

            function setLocalStorageSettings(key, value) {
                var settings = angular.fromJson(LocalStorage.getItem(TASK_SETTINGS)) || {};
                settings[$scope.user.userId] = (settings[$scope.user.userId] || {});
                if (_.isNil(value)) {
                    delete settings[$scope.user.userId].value;
                } else {
                    settings[$scope.user.userId][key] = value;
                }
                LocalStorage.setItem(TASK_SETTINGS, angular.toJson(settings));
            }

            $scope.taskLists = [];
            $scope.filteredTasks = [];
            $scope.newTaskList = {};
            $scope.loadingTasks = false;
            $scope.filesSelected = false;
            $scope.editNewTaskList = false;
            $scope.limit = 20;

            $scope.usersWithUnassigned = angular.copy($rootScope.users);
            if (angular.isArray($scope.usersWithUnassigned)) {
                $scope.usersWithUnassigned.push({
                    userId: '',
                    displayName: $translate.instant(TRANSLATION_UNASSIGNED)
                });
            }

            var timeout;
            $scope.$watch('query.assignee', function(newVal, oldVal){
                var label = 'assignee';
                var prefix = 'tasks';
                if (newVal !== oldVal) {
                    if (!_.isUndefined(timeout)) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(function () {
                        if (!_.isNil(newVal)) {
                            $location.displayUrl(prefix).search(label, newVal);
                        }else {
                            $location.search(label, null);
                        }
                    }, 1);
                }
            });

            $scope.hasTaskListDeletePermisson = function(list) {
                return Auth.hasTaskListPermission('delete', list);
            };

            $scope.dueDateFilter = function(data) {
                return !($scope.query.dueDate && data.dueDate && data.dueDate > Date.parse($scope.query.dueDate));
            };

            $scope.taskStatusFilter = function(data) {
                var taskList = $scope.getTaskList(data);
                if (taskList) {
                    return !(!$scope.query.showClosed && data.taskStatus !== 'OPEN');
                } else {
                    return true;
                }
            };

            function formatDateDiff(dateDiff) {
                var vdayDiff;
                var vhourDiff;
                var vminDiff;
                var key = '';

                vdayDiff = Math.floor(dateDiff / 1000 / 84600);
                vhourDiff = Math.floor(dateDiff / 1000 / 60 / 60);
                vminDiff = Math.floor(dateDiff / 1000 / 60);

                var params = {
                    DAYS: vdayDiff,
                    HOURS: vhourDiff,
                    MINUTES: vminDiff
                };

                if (vhourDiff <= 0) {
                    key = 'TIME.MINUTES';
                } else if (vhourDiff < 24) {
                    key = 'TIME.HOURS';
                } else {
                    key = 'TIME.DAYS';
                }

                return $rootScope.translate(key, key, params, "messageformat");
            }

            $scope.timePassed = function(createDate) {
                var currentTimestamp = new Date().getTime();
                var dateDiff = currentTimestamp - createDate;

                return formatDateDiff(dateDiff);
            };
            $scope.getTasksCount = function(taskList) {
                if (!taskList || !taskList.tasks || taskList.tasks.length == 0) {
                    return '0';
                }
                var tasks = _.filter(taskList.tasks, { taskStatus: 'OPEN' });
                return (tasks.length >= $scope.limit && !taskList.params.allTasksLoaded) ?
                    tasks.length + '+' :
                    tasks.length;
            };

            $scope.resetQuery = function() {
                $scope.query = {};
                _.forEach($routeParams, function(value, key) {
                    $location.search(key, null);
                });
                // Clear query in local storage
                setLocalStorageSettings('query', $scope.query);
            };

            $scope.updateQuery = function(key, value, takeValue) {
                if (value) {
                    var val = 'true';
                    if (takeValue) {
                        val = value;
                    }
                    $location.search(key, val);
                } else {
                    $location.search(key, null);
                }
                // Set query in local storage
                setLocalStorageSettings('query', $scope.query);
            };

            $scope.loadMore = function() {
                if ($scope.loadingTasks || !$scope.activeTaskList || $scope.activeTaskList.params.allTasksLoaded || $scope.locationChangeStart) {
                    $scope.locationChangeStart = false;
                    return;
                }
                var queryParams = getQuery();
                loadTasks($scope.activeTaskList, {
                    append: true,
                    queryParams: queryParams
                });
            };

            $scope.loadComments = function(taskId) {
                TaskResource.getComments({
                    taskId: taskId
                }, function(response) {
                    $scope.task.comments = response;
                });
            };

            $scope.getTaskList = function(task) {
                return _.find($scope.taskLists, {
                    id: task.taskListId
                });
            };

            $scope.selectAllTasks = function(tasks) {
                _.forEach(tasks, function(task) {
                    task.checked = true;
                });
            };

            $scope.closeSelectedTasks = function(tasks, $event) {
                var filteredTasks = _.filter(tasks, 'checked');
                if (filteredTasks.length < 1) {
                    growl.warning('TASK.NO_TASKS_SELECTED');
                } else {
                    _.forEach(filteredTasks, function(task) {
                        $scope.closeTask(task);
                    });
                }
            };

            $scope.$on('itemEditorClosed', function(event, result) {
                if (result && result.taskOptions && result.taskOptions.closeTask) {
                    $scope.task.chosenTransition = result.taskOptions.transition;
                    $scope.closeTask($scope.task);
                }
            });

            $scope.$on('deleteTaskList', function(event, taskList) {
                if (!taskList) return;
                TaskListsResource.delete({
                    taskListId: taskList.id
                }, function(response) {
                    loadTaskLists();
                    growl.success('TASK.DELETE_TASKLIST.SUCCESS');
                }, function(err) {
                    growl.error("TASKLISTS.DELETE.ERROR_MESSAGE_NOT_EMPTY");
                });
            });

            $scope.createTask = function(taskListId) {
                $location.displayUrl("/tasks/new");
                $location.search('taskListId', taskListId);
                var taskModal = $modal.open({
                    templateUrl: 'tpl/tasks-create-task.tpl.html',
                    controller: 'CreateTaskController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                taskLists: $scope.taskLists,
                                taskListId: taskListId
                            };
                        }
                    }
                });
                taskModal.result.then(function(task) {
                    $scope.selectTaskList(_.find($scope.taskLists, { id: task.taskListId }));
                }, function() {
                    console.log('Modal dismissed at: ' + new Date());
                }).finally(function() {
                    $location.displayUrl("/tasks");
                });
            };

            $scope.createTaskList = function(newTaskList) {
                if (!newTaskList.title) {
                    growl.warning("TASK.MISSING_TASK_TITLE");
                } else {
                    var pathParams = {
                        taskListId: newTaskList.id
                    };

                    TaskListsResource.save(pathParams, newTaskList, function(response) {
                        $scope.newTaskList = {};
                        $scope.editNewTaskList = false;
                        growl.success("TASK.ADDED_TASK_LIST_SUCCESS_MESSAGE");
                        loadTaskLists();
                    });
                }
            };

            $scope.deleteTaskList = function(list) {
                $modal.open({
                    templateUrl: 'tpl/confirm-delete-tasklist-modal.tpl.html',
                    controller: 'ModalInstanceCtrl',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                list: list
                            };
                        }
                    }
                });
            };

            $scope.$on('$locationChangeStart', function(event, newUrl) {
                $scope.locationChangeStart = true;
                if ($scope.activeTaskList) {
                    loadTasks($scope.activeTaskList, { queryParams: getQuery(), force: true });
                }
            });

            function loadTaskLists() {
                return TaskListsResource.query({}, function(response) {
                    $scope.taskLists = _.map(response, function(list) {
                        list.params = {
                            allTasksLoaded: false
                        };
                        list.tasks = loadTasks(list, { queryParams: getQuery(), force: true });
                        return list;
                    });
                });
            }

            $scope.selectTaskList = function(taskList) {
                $scope.activeTaskList = taskList || $scope.taskLists[0];
                setLocalStorageSettings('selectedTaskList', $scope.activeTaskList.id);
                var query = getQuery();
                for (var k in query) {
                    if (_.isBoolean(query[k])) {
                        query[k] = query[k] ? "true" : null;
                    }
                }
                var search = angular.extend(query, {'selectedTaskList' : $scope.activeTaskList.id});
                $location.search(search);
            };

            $scope.closeTask = function(task) {
                if (task.transitions && !task.chosenTransition) {
                    growl.error("TASK.CLOSE_TASK.FAILED_MESSAGE");
                    return;
                }
                task.taskStatus = 'FINISHED';
                updateTask(task);
            };

            $scope.getLocalUserName = function(userId){
                if (userId === '') {
                    return $translate.instant(TRANSLATION_UNASSIGNED);
                }
                return $rootScope.getUserName(userId);
            };

            function updateTask(task) {
                TaskResource.save({
                    taskId: task.id
                }, task, function(response) {
                    task = response;
                    var taskList = $scope.getTaskList(task);
                    loadTasks(taskList, { force: true });
                    growl.success("TASK.SAVE_TASK_SUCCESS_MESSAGE");
                });
            }

            function getQuery() {
                var query = {};
                query = $location.search();
                query = angular.extend(
                    {},
                    query,
                    _($scope.query)
                        .omitBy(_.isNil) // remove null and undefined params
                        .omitBy(function(val){ return val === false; }) //remove falsy params
                        .omitBy(function (val, key) { //remove empty string params only if it's not assignee
                            if (key !== 'assignee' && _.isEmpty(val)) {
                                return true;
                            }
                        })
                        .value()
                );
                return query;
            }

            function loadTasks(taskList, config) {
                if (!taskList) {
                    return;
                }
                $scope.loadingTasks = true;
                config = config || {};
                var queryParams = angular.copy(config.queryParams) || {};
                if(!_.isNil(config.queryParams) && !config.queryParams.showClosed){
                        queryParams.taskStatus = 'OPEN';
                }

                queryParams.limit = config.limit || $scope.limit;
                if (config.force) {
                    taskList.params.allTasksLoaded = false;
                    taskList.params.cursor = '';
                }

                QueryTasksResource.createCursorAwareResource(taskList.id, taskList.params.cursor)
                    .query(queryParams, function(response, headers) {
                        var cursor = headers(HttpHeader.MESSAGE_CURSOR);
                        taskList.params.cursor = cursor || '';

                        if (response.length === 0) {
                            taskList.params.allTasksLoaded = true;
                            taskList.tasks = config.force ? [] : taskList.tasks;
                        } else if (config.force || _.isEmpty(taskList.params.cursor)) {
                            taskList.tasks = response;
                        } else if (config.append && taskList.tasks) {
                            taskList.tasks = _.unionWith(taskList.tasks, response, function(arrVal, othVal) {
                                return arrVal.id === othVal.id;
                            });
                        }
                        _.forEach(taskList.tasks, function(t) {
                            if (!_.isEmpty(t.assignee)) {
                                var user = $rootScope.getUser(t.assignee);
                                if (user) {
                                    t.displayName = user.displayName;
                                }
                            }
                        });

                        $scope.locationChangeStart = false;
                        $scope.loadingTasks = false;
                    });
            }

            (function init() {
                $rootScope.loadDataModel();
                var settings = angular.fromJson(LocalStorage.getItem(TASK_SETTINGS));
                if (settings) {
                    settings = (settings[$scope.user.userId] || {});
                    selectedTaskList = _.find($scope.taskLists, { id: settings.selectedTaskList });
                    $scope.query = settings.query || {};
                }
                loadTaskLists().$promise.then(function(response) {
                    var selectedTaskList;
                    if (settings) {
                        selectedTaskList = _.find($scope.taskLists, { id: settings.selectedTaskList });
                    }
                    $scope.selectTaskList(selectedTaskList);
                });

                var queryParamNames = ['assignee', 'author', 'dueDate', 'title'];

                _.forEach(queryParamNames, function(id) {
                    if ($routeParams[id]) {
                        $scope.query[id] = $routeParams[id];
                    }
                });
                if ($routeParams.taskListId) {
                    $scope.createTask($routeParams.taskListId);
                }
                if ($routeParams.taskId) {
                    $scope.editTask($routeParams.taskId);
                }
            })();
        });
