angular.module('llax.directives')
    .directive('popupAd', function($modal) {
        return {
            strict: 'E',
            scope: {
                adUrl: '@',
                adId: '@'
            },
            template:
                '<div></div>',
            controller: function($scope) {
                $modal.open({
                    template:
                        '<div class="ad-popup">' +
                            '<close-ad-button data-on-cancel="cancel" data-ad-id={{adId}}></close-ad-button>' +
                            '<iframe ' +
                                'src="{{trustUrl(adUrl)}}"' +
                                'style="">' +
                            '</iframe>' +
                        '</div>',
                    controller: function($modalInstance, $sce, $scope, data) {
                        $scope.adUrl = data.adUrl;
                        $scope.adId = data.adId;

                        $scope.trustUrl = function(url) {
                            return $sce.trustAsResourceUrl(url);
                        };

                        $scope.cancel = function() {
                            $modalInstance.dismiss('cancel');
                        };
                    },
                    size: 'lg',
                    windowClass: 'ad-popup-modal',
                    backdrop: true,
                    resolve: {
                        data: function () {
                            return {
                                adUrl: $scope.adUrl,
                                adId: $scope.adId
                            };
                        }
                    }
                });
            }
        };
    });