angular.module('llax.directives')
    .directive('catalogSingleReferenceAttribute', function() {
        return {
            restrict: 'E',
            scope: {
                attribute: '=',
                item: '='
            },
            templateUrl: 'tpl/catalog/directive-catalog-single-reference-attribute.tpl.html',
            controller: function($scope, $timeout, ReferenceAttributesService) {
                $scope.formattedSingleReference = "";

                $scope.loadAndFormatSingleReference = function() {
                    var item = $scope.item;
                    var attribute = $scope.attribute;
                    return ReferenceAttributesService.loadAndFormatItemAsync(item[attribute.name], item, attribute)
                        .then(function(result) {
                            $timeout(function(){
                                $scope.formattedSingleReference = result;
                            }, 0);
                        });
                };
            }
        };
    });
