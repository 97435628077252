angular.module('llax')
    .controller('FetchItemController',
        function($http, $injector, $location, $modal, $rootScope, $routeParams, $scope, $timeout, $window, growl, uiGridConstants,
                 CountItemsResource, ErrorCode, ExportFormatsResource, ExportItemResource, ExportMappingResource,
                 HttpHeader, InputTemplatesService, QueryItemResource, SelectionResource, ValidateItemService, WatchlistService, ReactBridge) {

            var CUSTOM_PUBLICATION_SERVICE = "CustomPublicationSettings";

            $scope.canDepublish = true;
            $scope.selectionId = "";
            $scope.loadingItems = false;

            // This keeps track of the queries running through this controller.
            // It is used to make sure only the most recent request is handled.
            $scope.queryNumber = 0;

            $scope.sortOrder = {};

            $scope.exportFormats = [];
            ExportFormatsResource.query({
                returnKeyValueFormat: false
            }, function(response) {
                $scope.exportFormats = $scope.exportFormats.concat(response);
            });

            $scope.allVisibleItemsSelected = function() {
                var arr = _.map($scope.items, 'primaryKey__');
                var allSelected;
                if (WatchlistService.hasWatchlistItems()) {
                    allSelected = _.isEqual(arr.sort(), WatchlistService.getWatchlistPrimaryKeys().sort());
                } else {
                    allSelected = $scope.gridApi ? $scope.gridApi.selection.getSelectAllState() : false;
                }
                return allSelected;
            };

            $scope.itemsSelected = function() {
                if (!$scope.gridApi) {
                    return false;
                }
                if (!_.isUndefined($scope.gridApi.selection)) {
                    return ($scope.gridApi.selection.getSelectedCount() > 0);
                }
            };

            $scope.getSelectedItems = function() {

                // FIXME:
                // We should 'keep' the list of selected items accessible at all time via $scope.selectedItems.
                // That way, this method would not need to be called one million times!

                var selectedItems;
                if ($scope.allItemsSelected) {
                    selectedItems = 'ALL';
                } else {
                    selectedItems = $scope.getSelectedFullItems();
                    selectedItems = selectedItems ? _.map(selectedItems, 'primaryKey__') : [];
                }

                return selectedItems;
            };

            $scope.canDepublish = function() {

                var canDepublish = true;
                var customPublicationService =  $rootScope.getService(CUSTOM_PUBLICATION_SERVICE);
                if (customPublicationService) {
                    var selectedItems = $scope.allItemsSelected ? null : $scope.getSelectedFullItems();
                    canDepublish = customPublicationService.enableDepublication(selectedItems, $rootScope.user, $rootScope.organization);
                }

                return canDepublish;
            };

            $scope.getSelectedFullItems = function() {
                var selectedItems = $scope.gridApi ? $scope.gridApi.selection.getSelectedRows() : null;
                return selectedItems;
            };

            $scope.createItemSelection = function() {
                var selection = {};
                if ($scope.itemsSelected() && !$scope.allItemsSelected) {
                    var selectedItems = $scope.getSelectedItems();
                    selection.primaryKeys = selectedItems;
                } else if ($scope.allItemsSelected || $scope.allVisibleItemsSelected()) {
                    selection.itemsQuery = $scope.createItemRestrictions();
                }
                return selection;
            };

            function startMappingEditor(selection, exportType) {
                ExportMappingResource.query(
                    function(response) {
                        $modal.open({
                            templateUrl: 'tpl/export-mapping-editor.tpl.html',
                            controller: 'ExportMappingController',
                            resolve: {
                                data: function() {
                                    return {
                                        profiles: response.data || [],
                                        selectedItems: selection,
                                        parentscope: $scope,
                                        exportType: exportType,
                                        selectedFullItems : $scope.getSelectedFullItems()
                                    };
                                }
                            },
                            backdrop: true,
                            size: 'lg',
                            windowClass: ''
                        });
                    });
            }

            function startTemplateMappingSelector(selection) {
                $modal.open({
                    templateUrl: 'tpl/template-mapping/modal-template-mapping-selector.tpl.html',
                    controller: 'TemplateMappingSelectorController',
                    resolve: {
                        data: function () {
                            return {
                                parentScope: $scope
                            };
                        }
                    },
                    size: 'lg',
                    backdrop: true
                });
            }

            $scope.validateItems = function(event) {
                // FIXME: Allow validation of selection!
                var selectedItems = $scope.getSelectedItems();
                if (selectedItems && selectedItems.length > 0 && selectedItems !== 'ALL') {
                    ValidateItemService.validateItemSelection(selectedItems,
                        function (process) {
                            // FIXME: In order to know the exact number of items to validate
                            // we would need to load the process and the right context.
                            // For now, let's only take the number of selected items!
                            growl.info("ITEMS_VALIDATION_STARTED", {
                                variables: {
                                    noOfItems: selectedItems.length
                                }
                            });
                        },
                        function(response) {
                            growl.error("ITEMS_VALIDATION_START_FAILED", {
                                ttl: -1
                            });
                        }
                    );
                } else {
                    event.preventDefault();
                    event.stopPropagation();
                }
            };

            $scope.downloadItems = function(exportType) {
                var selectedItems = $scope.getSelectedItems();

                if (!selectedItems || selectedItems.length === 0) {
                    growl.warning("NO_ITEMS_SELECTED");
                } else if (exportType === 'template') {
                    startTemplateMappingSelector(selectedItems);
                } else if (exportType.usesMapping || !_.isEmpty(exportType.attributes)) {
                    startMappingEditor(selectedItems, exportType);
                } else {
                    $scope.createExportFromSelection(exportType);
                }
            };

            $scope.createExportFromSelection = function(exportType) {
                var selection = $scope.createItemSelection();
                if (selection.itemsQuery && selection.itemsQuery.selectionId) {
                    exportItems(selection.itemsQuery.selectionId, exportType.contentType);
                } else {
                    SelectionResource.save(selection, function(response) {
                        exportItems(response.selectionId, exportType.contentType);
                    });
                }
            };

            function exportItems(selectionId, contentType) {
                ExportItemResource.get({
                    selectionId: selectionId,
                    type: contentType,
                    language: $rootScope.language
                }, function(exportResponse) {
                    var location = $window.location.protocol + "//" + $window.location.host + "/exports";
                    growl.success("ITEM.EXPORT_SUCCESS", {
                        variables: {
                            link: location
                        },
                        ttl: 5000
                    });
                });
            }

            $scope.createExportForExportMapping = function(exportMapping) {
                var selection = $scope.createItemSelection();
                ExportItemResource.save({
                    exportMapping: exportMapping,
                    selection: selection,
                    language: $rootScope.language
                }, function() {
                    var location = $window.location.protocol + "//" + $window.location.host + "/exports";
                    growl.success("ITEM.EXPORT_SUCCESS", {
                        variables: {
                            link: location
                        },
                        ttl: 5000
                    });
                });
            };

            $scope.doDownloadItems = function(selectionId, exportType) {
                // we need to use $http instead of resource here, as ngResource is not able to
                // deserialize binary data

                $http({
                    url: lax_rest_url_complete('items/exports/' + selectionId + '?type=' + exportType.key),
                    method: "GET",
                    headers: {'Content-Type': 'application/json'},
                    responseType: 'arraybuffer'
                })
                .then(function(reponse) {
                    var hiddenElement = document.createElement('a');

                    document.body.appendChild(hiddenElement);
                    hiddenElement.href = URL.createObjectURL(new Blob([reponse.data], {
                        type: exportType.responseType
                    }));
                    hiddenElement.download = 'ItemExport.xls';
                    hiddenElement.click();
                    hiddenElement.remove();
                });
            };

            $scope.createItemRestrictions = function() {
                var queryParser = $rootScope.dataModel.queryParser('search');

                var keywordQuery = '';
                if ($scope.query && !_.isNil($scope.query.keyword)) {
                    keywordQuery += $rootScope.escapeSearchTerm($scope.query.keyword);
                } else if ($routeParams.q) {
                    if (keywordQuery !== '') {
                        keywordQuery += ' AND ';
                    }
                    keywordQuery += $rootScope.escapeSearchTerm($routeParams.q.split("+").join(" "));
                }

                if (!_.isUndefined(queryParser)) {
                    if (!queryParser.isLegal(keywordQuery)) {
                        return;
                    }
                    keywordQuery = queryParser.parseQuery(keywordQuery);
                }

                var itemsQuery = {};

                if ($routeParams.selectionId) {
                    itemsQuery.selectionId = $routeParams.selectionId;
                } else {
                    itemsQuery = {
                        keyword: (keywordQuery || undefined),
                        recipient: ($routeParams.recipient || undefined),
                        compliant: ($routeParams.compliant || undefined),
                        category: ($rootScope.escapeSearchTerm($routeParams.category) || undefined),
                        publicationTaskId: ($routeParams.publicationTaskId || undefined),
                        withPublicationTaskChecksums: ($routeParams.withPublicationTaskChecksums || undefined),
                        publicationTaskStates: ($routeParams.publicationTaskStates || undefined),
                        publicationDestination: ($routeParams.publicationDestination || undefined),
                        publicationStatus: ($routeParams.publicationStatus || undefined),
                        taskId: ($routeParams.taskId || undefined),
                        tags: ($routeParams.tags || undefined),
                        errorKey: ($rootScope.escapeSearchTerm($routeParams.errorKey) || undefined),
                        warningKey: ($rootScope.escapeSearchTerm($routeParams.warningKey) || undefined),
                        reviewErrorKey: ($rootScope.escapeSearchTerm($routeParams.reviewErrorKey) || undefined),
                        reviewWarningKey: ($rootScope.escapeSearchTerm($routeParams.reviewWarningKey) || undefined),
                        reviewer: ($routeParams.reviewer || undefined),
                        reviewStatus: ($routeParams.reviewStatus || undefined),
                        bulkSubscriptionId: ($routeParams.bulkSubscriptionId || undefined),
                        bulkSearchId: ($routeParams.bulkSearchId || undefined)
                    };

                }

                return itemsQuery;
            };

            $scope.updateItems = function(itemSelection) {
                var selection = {};
                var selectedItems = [];
                if (itemSelection) {
                    selection.primaryKeys = _.map(itemSelection, 'primaryKey__');
                    selectedItems = selection.primaryKeys;
                } else {
                    selection = $scope.createItemSelection();
                    selectedItems = $scope.getSelectedItems();
                }
                if (!selectedItems || selectedItems.length === 0) {
                    growl.warning("NO_ITEMS_SELECTED");
                } else {
                    // startMassUpdateEditor(selection, selectedItems);
                    var dialog = ReactBridge.mountDialog("MassUpdateDialog", "#react-mass-update-dialog",
                    {
                        data: {
                            selection: selection,
                            selectedItems: selectedItems
                        },
                        item: $scope.item,
                        category: $routeParams.category,
                       onClose: function(toWait) {
                            // Unmount the dialog after waiting if toWait is true, otherwise unmount immediately
                            function unmount(){
                                dialog.unmount();
                            }
                            return toWait? ReactBridge.unmountWhenReady(unmount): dialog.unmount();
                        }
                    });
                }
            };

            function loadWatchlistItems() {
                var selectedItems = {
                    primaryKeys: WatchlistService.getWatchlistPrimaryKeys()
                };
                SelectionResource.save(selectedItems,
                    function(response) {
                        $location.search('selectionId', response.selectionId);
                    }
                );
            }

            $scope.doFetchItems = function(config, fields) {

                // Loading items without fields does not make sense
                if (_.isEmpty(fields)) {
                    return;
                }

                if ($routeParams.selectionId === true) {
                    loadWatchlistItems();
                    return;
                }

                config = config || {};

                if (!config.append) {
                    $scope.cursor = null;
                    $scope.page = 1;
                    $scope.searchFinished = false;
                }
                fields = fields.concat($rootScope.dataModel.ADDITIONAL_FIELDS || []);

                fields = fields.concat($rootScope.dataModel.filterPrimaryKeyPartAttributes());

                $scope.restrictions = $scope.createItemRestrictions();
                if (!$scope.restrictions) { return; }

                $scope.loadingItems = true;

                $scope.pageParams = {
                    fields: fields || null,
                    page: ($scope.page || null),
                    count: 40
                };

                var queryParams = angular.copy($scope.restrictions);
                var queryService = $rootScope.getService('CustomItemQueryService');
                if (queryService) {
                    queryParams.keyword = _.invoke(queryService, 'filterQuery', queryParams.keyword, $rootScope.user, $rootScope.organization) || queryParams.keyword;
                }

                angular.extend(queryParams, $scope.pageParams, $scope.sortOrder);
                // Update the queryNumber for the upcoming request.
                $scope.queryNumber += 1;
                var queryNumber = $scope.queryNumber;

                var queryItemResource = QueryItemResource.createCursorAwareResource($scope.cursor);
                if (queryParams.keyword && queryParams.keyword.length > 2000) {
                    var options = {};
                    options.queryParams = queryParams;
                    var keyword = queryParams.keyword;
                    delete queryParams.keyword;
                    queryItemResource.search(queryParams, keyword, successFn, errorFn);
                } else {
                    queryItemResource.query(queryParams, successFn, errorFn);
                }

                function successFn(res, headers) {

                    // This ensures that changes only happen, if the returned request
                    // is the most recent one. I another request has been started before
                    // this one returns, $scope.queryNumber will have been increased
                    // and we exit this handler.
                    if (queryNumber < $scope.queryNumber) {
                        return;
                    }

                    $scope.errorMessage = null;
                    $scope.errorCode = ErrorCode.NONE;
                    $scope.status = res.$promise.$$state.status;

                    if (res.length === 0) {

                        // Stop reloading if no items were found
                        $scope.cursor = null;
                        $scope.numberFound = 0;
                        $scope.maxNumberFound = 0;
                        $scope.searchFinished = true;

                    } else {

                        var cursor = headers(HttpHeader.ITEM_CURSOR);
                        $scope.numberFound = parseInt(headers(HttpHeader.ITEMS_FOUND)) || 0;
                        $scope.maxNumberFound = parseInt(headers(HttpHeader.ITEMS_MAX)) || 0;

                        if (cursor) {
                            if (cursor === $scope.cursor) {
                                $scope.searchFinished = true;
                            }
                            $scope.cursor = cursor;
                        } else {
                            $scope.searchFinished = true;
                        }

                    }

                    if (config.append === true && !$routeParams.selectionId) {
                        $scope.items = $scope.items.concat(res);
                        if ($scope.gridApi) {
                            $timeout(function() {
                                $scope.gridApi.infiniteScroll.saveScrollPercentage();
                                $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                                if (WatchlistService.hasWatchlistItems()) {
                                    _.forEach(WatchlistService.getWatchlistPrimaryKeys(), function(entry) {
                                        $scope.gridApi.selection.selectRow(_.find($scope.items, {primaryKey__: entry}));
                                    });
                                }
                                if ($scope.allVisibleItemsSelected()) {
                                    $scope.gridApi.selection.selectAllRows();
                                }
                            });
                        }
                    } else {
                        $scope.items = res;
                        if ($scope.gridApi) {
                            $timeout(function() {
                                $scope.gridApi.core.scrollToIfNecessary(0, 0);
                                $scope.gridApi.infiniteScroll.resetScroll(false, true);
                                $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                                if (WatchlistService.hasWatchlistItems()) {
                                    _.forEach(WatchlistService.getWatchlistPrimaryKeys(), function(entry) {
                                        $scope.gridApi.selection.selectRow(_.find($scope.items, {primaryKey__: entry}));
                                    });
                                }
                                if ($scope.allVisibleItemsSelected()) {
                                    $scope.gridApi.selection.selectAllRows();
                                }
                                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                            }, 50);
                        }
                    }
                    $scope.loadingItems = false;
                    $scope.$emit('checkUsageLimits');
                }

                function errorFn(response) {
                    $scope.errorCode = $rootScope.getErrorCode(response);
                    if ($scope.errorCode === ErrorCode.SEARCH_QUERY_TOO_LONG) {
                        if ($rootScope.hasSettingFeature('BULK_SEARCH')) {
                            var queryValues = _.replace($scope.query.keyword, / OR /g, "\n");
                            var valueCount = (_.countBy(queryValues)['\n'] || 0) + 1;
                            $scope.newBulkSearch(queryValues, valueCount);
                        } else {
                            growl.error("ERROR_LOADING_ITEMS");
                        }
                    } else if ($scope.errorCode !== ErrorCode.SEARCH_QUERY_INVALID) {
                        growl.error("ERROR_LOADING_ITEMS");
                    }
                    $scope.errorMessage = $rootScope.getErrorMessage(response);
                    if ($scope.gridApi) {
                        $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                    }
                    $scope.status = response.status;
                    $scope.loadingItems = false;
                    $scope.searchFinished = true;
                }

        };

        $scope.countItems = function () {
            var itemsQuery = $scope.createItemRestrictions();
            delete itemsQuery.primaryKeys;
            $scope.countingItems = true;
            CountItemsResource.count({ itemsQuery: itemsQuery }, function(response) {
                $scope.numberFound = response.numberOfItems;
                $scope.maxNumberFound = response.numberOfItems;
            }).$promise.finally(function() {
                $scope.countingItems = false;
            });
        };

        $scope.$on('fetchItems', function(event, config, fields) {
            if(!fields) {
                fields = $rootScope.dataModel.filteredLayoutAttributeNames($scope.currentLayout);
            }
            $scope.doFetchItems(config, fields);
        });

    });
