angular.module('llax.directives.itemList', [])
.directive('updateOnResize', ['$window', function($window) {

    function link(scope, element, attrsctrl) {

        var w = angular.element($window),
            itemsPerPage = 20;

        // Calculate how much items the items-grid can show without paging
        function calcCapacity(){
            try {
                // this can fail if the grid is not yet fully rendert
                var gridOffsetTop = angular.element(element).offset().top,
                    footerHeight = angular.element('footer').innerHeight(),
                    boxContentPadding = parseInt(angular.element('.box-content').css('padding-bottom'), 10),
                    contentPadding = parseInt(angular.element('#content').css('padding-bottom'), 10),
                    offset = gridOffsetTop + footerHeight + boxContentPadding + contentPadding,
                    myheight = w.height() - offset,
                    gridCapacity = 5 * Math.ceil(myheight / 34 / 5);  // 5 more
                return gridCapacity;
            }
            catch(e) {
                // fallback calculation based on full window height
                return 5 * Math.ceil(w.height() / 34 / 5);
            }
        }

        function checkCapacity() {
            var capacity = calcCapacity();
            return (capacity > scope.items.length && scope.page < capacity / itemsPerPage);
        }

        // After increasing window size, try to fetch more items to fill the grid and thus enable scrolling
        w.bind('resize', function () {
            if (!scope.loadingItems && checkCapacity()) {
                scope.loadMore();
            }
        });

        // Make sure, grid will get filled initially (or after deletion of items)
        scope.$watch(function() {
            return scope.items ? scope.items.length : 0;
        },
        function onChange(newLength, oldLength) {
            if (checkCapacity()) {
                scope.loadMore();
            }
        },
        true);
    }

    return {
        link: link
    };
}]);
