angular.module('llax')
    .controller('OrderController', ['$rootScope', '$routeParams', '$scope', '$http', '$timeout', '$translate',
        'growl', '$modal', '$controller', '$location', 'ShoppingCartResource', 'ShoppingCartService', 'ItemResource',

        function($rootScope, $routeParams, $scope, $http, $timeout, $translate, growl, $modal, $controller,
            $location, ShoppingCartResource, ShoppingCartService, ItemResource) {

            $controller('ShoppingCartController', {
                $scope: $scope
            });

            $scope.reAddToShoppingCart = function(shoppingCartItem) {
                ItemResource.get({
                    primaryKey: shoppingCartItem.primaryKey
                }, function(item) {
                    if (item) {
                        $scope.addToShoppingCart(item);
                    }
                }, function(error) {

                });
            };

            var columnDefs = ShoppingCartService.getColumnDefinitions();

            columnDefs.push({
                displayName: $scope.translate('ACTIONS'),
                enableCellEdit: false,
                enableCellSelection: false,
                resizable: true,
                width: '80',
                cellTemplate: '<button type="button" class="btn btn-cell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" data-ng-click="reAddToShoppingCart(row.entity)"><i class="syncons syncons-marketplace"></i></button>'
            });

            $scope.orderColumDefs = columnDefs;

            $scope.gridOptions = {
                data: 'order.items',
                showSelectionCheckbox: false,
                enableCellSelection: false,
                enableCellEdit: true,
                enableColumnResize: true,
                enableColumnMenus: false,
                multiSelect: false,
                selectWithCheckboxOnly: false,
                columnDefs: 'orderColumDefs',
                rowHeight: '34',
                rowTemplate: '<div data-ng-style="{ \'cursor\': row.cursor }" ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngCell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}} {{col.cellClass}}"><div class="ngVerticalBar" ng-style="{height: rowHeight}" ng-class="{ ngVerticalBarVisible: !$last }">&nbsp;</div><div ng-cell></div></div>',
                afterSelectionChange: $scope.afterSelectionChange
            };

            $scope.loadOrder = function(id) {

                ShoppingCartResource.get({
                    id: id
                }, function(response) {
                    $scope.order = response;
                });
            };
            if ($routeParams.orderId !== undefined) {
                $scope.loadOrder($routeParams.orderId);
            }
        }
    ]);

angular.module('llax')
    .controller('OrdersController', ['$rootScope', '$routeParams', '$scope', '$http', '$timeout', 'ItemResource',
        'FavoritesResource', "OrdersService", '$translate', 'growl', '$modal', '$controller', '$location', 'ShoppingCartResource',

        function($rootScope, $routeParams, $scope, $http, $timeout, ItemResource, FavoritesResource, OrdersService,
            $translate, growl, $modal, $controller, $location, ShoppingCartResource) {

            $scope.columnDefs = $scope.columnDefs || [];

            function createCatalogTableColumnDefinition() {

                $scope.columnDefs = [];
                var columnDefs = [];

                columnDefs.push({
                    field: "orderDate",
                    displayName: $translate.instant('SHOPPING_CART.ORDER_DATE'),
                    headerTooltip: true,
                    enableCellEdit: false,
                    resizable: true,
                    cellFilter: 'date'
                });
                columnDefs.push({
                    field: "items.length",
                    displayName: $translate.instant('SHOPPING_CART.ORDER_POSITIONS'),
                    headerTooltip: true,
                    enableCellEdit: false,
                    resizable: true
                });
                columnDefs.push({
                    field: "total",
                    displayName: $translate.instant('SHOPPING_CART.ORDER_TOTAL'),
                    headerTooltip: true,
                    enableCellEdit: false,
                    resizable: true,
                    cellFilter: 'currency'
                });
                columnDefs.push({
                    displayName: $translate.instant('ACTIONS'),
                    headerTooltip: true,
                    enableCellEdit: false,
                    enableCellSelection: false,
                    resizable: true,
                    width: '80',
                    cellTemplate: '<button class="btn btn-cell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" data-ng-click="redirectToOrderDetail(row.entity)"><i class="syncons syncons-edit"></i></button>'
                });

                $scope.columnDefs = columnDefs;
            }

            createCatalogTableColumnDefinition();

            $scope.orders = [];

            ShoppingCartResource.query({}, function(response) {
                $scope.orders = response.filter(function(order) {
                    return order.orderDate !== undefined;
                });
                angular.forEach($scope.orders, function(order) {
                    if (order.items) {
                        var total = 0;
                        angular.forEach(order.items, function(item) {
                            var totalForItem = parseFloat(item.totalPrice);
                            if (totalForItem) {
                                total += totalForItem;
                            }
                        });
                        order.total = total;
                    }
                });
            });

            $scope.gridOptions = {
                data: 'orders',
                showSelectionCheckbox: false,
                enableCellSelection: false,
                enableCellEdit: true,
                enableColumnResize: true,
                enableColumnMenus: false,
                multiSelect: false,
                selectWithCheckboxOnly: false,
                columnDefs: $scope.columnDefs,
                rowHeight: '34',
                rowTemplate: '<div ng-style="{ \'cursor\': row.cursor }" ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngCell row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}} {{col.cellClass}}"><div class="ngVerticalBar" ng-style="{height: rowHeight}" ng-class="{ ngVerticalBarVisible: !$last }">&nbsp;</div><div ng-cell></div></div>',
                afterSelectionChange: $scope.afterSelectionChange
            };

            $rootScope.redirectToOrderDetail = function(order) {
                $location.path('/orders/detail/' + order.id);
            };

            $scope.itemsSelected = function() {
                if ($scope.gridOptions.$gridScope !== undefined && $scope.gridOptions.$gridScope.selectedItems !== undefined) {
                    return ($scope.gridOptions.$gridScope.selectedItems.length >= 1);
                }
            };
        }
    ]);
