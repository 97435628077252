angular.module('llax')
    .controller('SubscriptionsController', function($controller, $location, $modal, $rootScope, $scope, $timeout, $translate, growl, HttpStatus, SubscriptionResource) {

            $rootScope.loadDataModel();

            $scope.loadExistingSubscriptions = function(channelId) {
                $scope.subscriptions = SubscriptionResource.query({
                    channelId: channelId
                });
            };

            $scope.toggleShowSubscriptionDetails = function(channel) {
                channel.showDetails.subscriptions = !channel.showDetails.subscriptions;
                if (channel.showDetails.subscriptions) {
                    $scope.loadExistingSubscriptions(channel.id);
                }
            };

            $scope.newSubscription = function(channel) {
                var subscriptionDescription = {
                    'category__': $scope.getSubscriptionCategory(channel),
                    'channelId': channel.id,
                    'subscriptions': []
                };
                $scope.subscriptionValidations = [];
                SubscriptionResource.query({
                        'channelId': channel.id
                    },
                    function(response) {
                        for (var i = 0; i < response.length; i++) {
                            var subscription = response[i];
                            var validations = {};
                            if (subscription.validation__ && subscription.validation__.length > 0) {
                                for (var ii = 0; ii < subscription.validation__.length; ii++) {
                                    var validation = subscription.validation__[ii];
                                    var error = validation.split(":");
                                    validations[error[0]] = [];
                                    validations[error[0]].push(error[1]);
                                }
                            }
                            $scope.subscriptionValidations[i] = validations;
                            subscriptionDescription.subscriptions[i] = subscription;
                        }
                    },
                    function(response) {
                        $rootScope.error = response.data;
                        $scope.status = response.status;
                        growl.error("SUBSCRIPTIONS.LOAD_ERROR_MESSAGE");
                    }
                );
                var modal = $modal.open({
                    templateUrl: 'tpl/edit-subscription.tpl.html',
                    controller: 'EditSubscriptionsController',
                    windowClass: 'communicationchannel-modal',
                    scope: $scope,
                    resolve: {
                        subscriptionDescription: function() {
                            return subscriptionDescription;
                        },
                        channel: function() {
                            return channel;
                        }
                    }
                });
                modal.result.then(function(subscriptionDescription) {
                    $scope.saveSubscription(subscriptionDescription);
                }).finally(function() {
                    $location.displayUrl("/communicationchannels");
                });

                $location.displayUrl("/communicationchannels/" + channel.id + "/subscriptions");
            };

            $scope.saveSubscription = function(subscriptionDescription) {
                SubscriptionResource.save({}, subscriptionDescription,
                    function(subscription) {
                        growl.success("SAVE_SUCCESS_MESSAGE");
                        // $modalInstance.close(subscription);
                    },
                    function(response) {
                        $scope.status = response.status;
                        if (response.status === HttpStatus.BAD_REQUEST) {
                            $scope.errorMessage = $translate.instant(response.data.message, response.data.parameters);
                        }
                    }
                );
            };
        }
);
