angular.module('llax')
    .controller('ImportSimpleMappingsController',
        function ($modalInstance, $scope, data, growl, SheetJSService, SimpleMappingsResource) {
            $scope.importing = false;

            $scope.cancel = function () {
                if (!$scope.importing) {
                    $modalInstance.dismiss('cancel');
                }
            };

            $scope.importFile = function(fileName, fileAsArrayBuffer) {
                $scope.importing = true;
                var workbook = SheetJSService.readFile(fileAsArrayBuffer);
                if (!_.isEmpty(workbook.SheetNames)) {
                    var sheetName = workbook.SheetNames[0];
                    var sheet = workbook.Sheets[sheetName];
                    var columns = _.map(SheetJSService.getRowCells(sheet, 0), function(cell) {
                        return cell.value;
                    });
                    var sheetData = SheetJSService.sheetToJson(sheet);
                    SimpleMappingsResource.createEntries({
                        id: data.simpleMappingId
                    }, _.map(sheetData, function(entry) {
                        return {
                            key: entry[columns[0]],
                            value: entry[columns[1]],
                        };
                    }), function(response) {
                        $scope.importing = false;
                        $scope.cancel();
                        data.onSimpleMappingImported(data.simpleMappingId);
                    }, function(response) {
                        $scope.importing = false;
                        growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                    });
                } else {
                    $scope.importing = false;
                    growl.error('SIMPLE_MAPPING.IMPORT_FILE_INVALID');
                }
            };

            $scope.onFileDrop = function(file) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    var arrayBuffer = e.target.result;
                    $scope.importFile(file.name, arrayBuffer);
                };

                reader.readAsArrayBuffer(file);
            };
        }
    ).directive('simpleMappingImporter', function() {
        return {
            restrict: 'E',
            scope: {
                onDrop: '='
            },
            replace: true,
            templateUrl: 'tpl/simple-mapping/simple-mappings-uploader.tpl.html',
            link: function($scope, $element, attrs){
                function dragenter($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    $element.addClass('dropping');
                }

                function dragover($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }

                function dragleave($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    $element.removeClass('dropping');
                }

                function drop($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    $element.removeClass('dropping');
                    var files = $event.originalEvent.dataTransfer.files;
                    var file = files[0];
                    $scope.onDrop(file);
                }

                $element.bind('dragenter', dragenter);
                $element.bind('dragover', dragover);
                $element.bind('dragleave', dragleave);
                $element.bind('drop', drop);

                function change($event){
                    var files = $event.target.files;
                    var file = files[0];
                    $scope.onDrop(file);
                }

                var $input = $element[0].querySelector('input');
                $input.onchange = change;
                var $button = $element[0].querySelector('button');
                $button.onclick = function() {
                    $input.click();
                };
            }
        };
    });