angular.module('llax.services')
    .service('AdditionalCategoryService', function($rootScope, $log, $q, DataModelRetrievalResource) {

        var _this = this;
        this.memo = {};
        this.statusMap = {};
        this.resolves = [];
        this.rejects = [];

        /**
         * Memoizes the additional category loading call (any parallel calls will have the first result):
         * 1- Checks IndexedDB.
         * 2- if not found, fetches the additional category from the backend.
         */
        this.loadAdditionalCategory = function(additionalCategoryNameOrCode,
                                               additionalModule) {

            if (_.isEmpty(additionalCategoryNameOrCode) || _.isEmpty(additionalModule)) {
                return $q.when({});
            }

            var memoKey = [additionalCategoryNameOrCode, additionalModule].join(':');
            if (_this.memo[memoKey]) {
                return $q.when(_this.memo[memoKey]());
            }

            if (_this.statusMap[memoKey] === "pending") {
                return new Promise(function(_res, _rej) {
                    _this.resolves.push(_res);
                    _this.rejects.push(_rej);
                });
            }

            var deferred = $q.defer();
            try {
                _this.statusMap[memoKey] = "pending";

                var currentDataModelHash = $rootScope.organization.dataModelHash;

                $rootScope.dataModel
                    .getCachedAdditionalCategory(additionalCategoryNameOrCode, additionalModule, currentDataModelHash)
                    .then(function(result) {

                        if (_.isEmpty(result) || result.dataModelHash !== currentDataModelHash || result.translatedLanguage !== $rootScope.language) {

                            // Also ensure the cache is busted when the data model hash is different (if exists)
                            $rootScope.dataModel
                                    .deleteCachedAdditionalCategory(additionalCategoryNameOrCode, additionalModule)
                                    .then(function() {

                                        _this.fetchAndCacheAdditionalCategory(additionalCategoryNameOrCode, additionalModule)
                                            .then(function(result) {
                                                result.translatedLanguage = $rootScope.language;
                                                _this.statusMap[memoKey] = "success";
                                                _this.memo[memoKey] = function get() {
                                                    return result;
                                                };

                                                _this.resolves.forEach(function(resolve) {
                                                    resolve(result);
                                                });

                                                deferred.resolve(_this.memo[memoKey]());

                                            });

                                    });
                        } else {

                            _this.statusMap[memoKey] = "success";
                            _this.memo[memoKey] = function get() {
                                return result;
                            };

                            _this.resolves.forEach(function(resolve) {
                                resolve(result);
                            });

                            deferred.resolve(_this.memo[memoKey]());
                        }
                    });

            } catch (err) {
                $log.error('AdditionalCategoryService: Could not load additional category entry', memoKey, err);
                _this.statusMap[memoKey] = "error";
                _this.rejects.forEach(function(reject) {
                    reject(err);
                });
                throw err;
            }

            return deferred.promise;
        };

        this.fetchAndCacheAdditionalCategory = function(additionalCategoryNameOrCode, additionalModule) {

            return DataModelRetrievalResource.query({
                method: 'categories',
                q: additionalCategoryNameOrCode,
                dataModel: additionalModule,
                limit: 1
            }).$promise.then(function(result) {

                result = result[0];
                result.additionalCategoryNameOrCode = additionalCategoryNameOrCode;
                result.additionalModule = additionalModule;

                var currentDataModelHash = $rootScope.organization.dataModelHash;
                result.dataModelHash = currentDataModelHash;

                $rootScope.dataModel.insertCachedAdditionalCategory(additionalCategoryNameOrCode, additionalModule, currentDataModelHash, result);
                return result;

            });
        };
    });