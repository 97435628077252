angular.module('llax')
    .controller('ExportMappingController',
        function($rootScope, $scope, $modalInstance, growl, data, ExportMappingResource, ExportMappingService, $timeout, $filter, UsersService, $routeParams, $parse) {
            $scope.attributesList = [];
            $scope.allAttributes = [];
            $scope.customValues = {};
            $scope.exportAttributes = [];

            $scope.exportProfiles = data.profiles;
            $scope.privateProfiles = false;
            $scope.publicProfiles = false;
            $scope.vm = {
                filteredAttributes: [],
                query: '',
                saveFilledExportAll: false,
                saveFilledExport: false,
                showOnlySelected: false
            };
            $scope.allAttributesConditionFlag = false;

            var parentscope = data.parentscope;
            $scope.exportType = data.exportType;

            $scope.sortableOptions = {
                handle: '> .export-attributes-handle',
                start: function() {
                    $scope.sort = {
                        type: '',
                        reverse: null
                    };
                }
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.clearSearchQuery = function () {
                $scope.vm.query = "";
            };

            $scope.toggleSelectAll = function(attributes, selectAll) {
                _.forEach(attributes, function(entry) {
                    entry.selected = selectAll;
                });
            };

            $scope.allSelected = function(entries) {
                return _.every(entries, 'selected');
            };

            function getCurrentMapping(reset) {
                return _($scope.attributesList)
                    .filter('selected')
                    .map(function(entry) {
                        return {
                            internalName: entry.internalName,
                            externalName: entry.externalName || null
                        };
                    })
                    .value();
            }

            function getCurrentProfile() {
                var allFieldExportFlag = $scope.vm.saveFilledExportAll;

                var mappings = getCurrentMapping();
                if (_.isEmpty(mappings) && $scope.exportType.usesMapping) {
                    growl.warning("EXPORT.EMPTY_MAPPING");
                    return false;
                }

                var profile;
                if (isProfileEqual($scope.activeExportProfile, mappings, $scope.customValues)) {
                    profile = $scope.activeExportProfile;
                } else if ($scope.allAttributes.length === $scope.getSelectedCount() &&
                    _.isEmpty($scope.customValues)) {
                        activeProfile = $scope.activeExportProfile;

                    if (allFieldExportFlag || (activeProfile && activeProfile.allAttribute === true)) {
                        $scope.allAttributesConditionFlag = true;
                        profile = {
                            name: 'internal__',
                            contentType: $scope.exportType.contentType,
                            customValues: $scope.customValues,
                            mappings: mappings,
                            privateMapping: true,
                            allAttribute: true
                        };
                    } else {
                        profile = {
                            name: 'NO_MAPPING'
                        };
                    }
                } else {
                    profile = {
                        name: 'internal__',
                        contentType: $scope.exportType.contentType,
                        customValues: $scope.customValues,
                        mappings: mappings,
                        privateMapping: true
                    };
                }

                return profile;
            }

            function isProfileEqual(profile, mappings, customValues) {

                if (!profile) {
                    return false;
                }

                // Check for changed mappings
                if (!_.isEqual(profile.mappings, mappings)) {
                    return false;
                }

                // Check for changed custom values
                if (!_.isEqual(profile.customValues || {}, customValues || {})) {
                    return false;
                }

                return true;
            }

            $scope.onProfileNameChange = function() {
                $scope.editActiveExportProfile = true;
            };

            $scope.runExport = function() {
                if ($scope.vm.saveFilledExport) {
                    var validationResult = false;
                    if (!$scope.activeExportProfile || $scope.editActiveExportProfile) {
                        validationResult = $scope.updateExportProfile($scope.vm.tmpProfile);
                    } else if ($scope.activeExportProfile && !$scope.editActiveExportProfile) {
                        validationResult = $scope.updateExportProfile($scope.activeExportProfile);
                    }

                    if (!validationResult) {
                        return;
                    }
                }

                var profile = null;
                if (!$scope.exportType.usesMapping && _.isEmpty($scope.exportType.attributes)) {
                    profile = { name: 'NO_MAPPING' };
                } else {
                    profile = getCurrentProfile();
                    if (!profile) {
                        return;
                    }
                }

                var key = profile.key ? profile.key : profile.name;

                if (profile.name === 'NO_MAPPING') {
                    ExportMappingResource.activate({},
                        null,
                        function(response) {
                            parentscope.createExportFromSelection($scope.exportType);
                            $modalInstance.close();
                        }
                    );
                } else if (profile.name != 'internal__' && $scope.allAttributesConditionFlag === false && !_.isEqual(profile.userId, $scope.$parent.user.userId)) {
                    ExportMappingResource.activate({},
                        key,
                        function(response) {
                            parentscope.createExportFromSelection($scope.exportType);
                            $modalInstance.close();
                        }
                    );
                } else {
                    ExportMappingResource.save({},
                        profile,
                        function(response) {
                            ExportMappingResource.activate({},
                                key,
                                function(response) {
                                    $timeout(function() {
                                        parentscope.createExportFromSelection($scope.exportType);
                                        $modalInstance.close();
                                    }, 250);
                                },
                                function(errorResponse) {
                                    growl.error("UPLOADED_MAPPING_FAILED");
                                }
                            );
                        },
                        function(errorResponse) {
                            growl.error("UPLOADED_MAPPING_FAILED");
                            $modalInstance.close();
                        }
                    );
                }
            };

            function getAttributes() {
                var layout = (data.exportType ? data.exportType.layout : null ) || 'mapping';
                return _.map($rootScope.dataModel.filteredLayoutAttributes(layout), function(attr) {
                    var dontFilter = $rootScope.dataModel.sectionAttributeParam('mapping', {}, null, attr.name, 'dontFilter');
                    return {
                        internalName: attr.name,
                        label: attr.label,
                        dontFilter: dontFilter
                    };
                });
            }

            $scope.toggleOnlySelected = function() {
                if ($scope.vm.showOnlySelected) {
                    $scope.attributesList = $scope.getOnlySelected();
                } else {
                    $scope.attributesList = $scope.allAttributes;
                }
            };

            $scope.getOnlySelected = function() {
                return _.filter($scope.attributesList, 'selected');
            };

            $scope.getSelectedCount = function() {
                return _.filter($scope.attributesList, 'selected').length;
            };

            $scope.updateExportProfile = function(profile) {

                if (profile) {
                    var isNewProfile = !profile.mappings;

                    if (isNewProfile) {
                        profile.privateMapping = !$scope.vm.publicExportMapping;
                        profile.userId = $scope.$parent.user.userId;
                        profile.key =  $scope.vm.publicExportMapping ? profile.name : profile.userId + "/" + profile.name;

                        var existing = _.some($scope.exportProfiles, {
                            name: profile.name
                        });

                        if (existing) {
                            growl.error('EXPORT.PROFILE_SAVED.ERROR_CONFLICT_MESSAGE',{variables: {profileName: profile.name}});
                            angular.element("#export-profile-name-input").focus();
                            return false;
                        }
                    }
                    profile.mappings = getCurrentMapping();
                    profile.contentType = $scope.exportType.contentType;
                    if (!_.isEmpty($scope.customValues)) {
                        profile.customValues = $scope.customValues;
                    }
                    var emptyMapping = _.isEmpty(profile.mappings);
                    if (emptyMapping && !profile.usesMapping) {
                        growl.warning("EXPORT.EMPTY_MAPPING");
                        return false;
                    }

                    var allFieldExportFlag = $scope.vm.saveFilledExportAll;
                    var activeProfile = $scope.activeExportProfile;

                    if (allFieldExportFlag || (activeProfile && activeProfile.allAttribute === true)) {
                        profile.allAttribute = true;
                    }

                    saveExportProfile(profile, isNewProfile);
                    return true;
                } else {
                    growl.warning('EXPORT.EMPTY_PROFILE_NAME');
                    return false;
                }
            };

            $scope.deleteExportProfile = function(profile, fromUpdate) {
                ExportMappingResource.delete({}, {
                        mappingName: profile.name
                    },
                    function(response) {
                        if (!fromUpdate) {
                            _.remove($scope.exportProfiles, {
                                'name': profile.name
                            });
                            growl.success("EXPORT.PROFILE_DELETED.SUCCESS_MESSAGE");
                            $scope.activateExportProfile();
                        }
                    },
                    function(response) {
                        var message = _.isEqual(response.data.message,'EXPORT.PROFILE_DELETED.ERROR_PERMISSION') ? response.data.message : 'EXPORT.PROFILE_DELETED.ERROR_MESSAGE';
                        growl.error(message);
                    }
                );
            };

            function saveExportProfile(profile, isNewProfile) {
                ExportMappingResource.save({},
                    profile,
                    function(response) {
                        if (isNewProfile) {
                            $scope.exportProfiles.push(response);
                        } else {
                            angular.extend(_.find($scope.exportProfiles, {
                                'name': response.name
                            }), response);
                        }
                        $scope.activateExportProfile(response);
                        growl.success('EXPORT.PROFILE_SAVED.SUCCESS_MESSAGE');
                    },
                    function(response) {
                        var message = _.isEqual(response.data.message,'EXPORT.PROFILE_SAVED.ERROR_PERMISSION') ? response.data.message : 'EXPORT.PROFILE_SAVED.ERROR_MESSAGE';
                        growl.error(message);
                    }
                );
            }

            $scope.activateExportProfile = function(profile) {
                    $scope.layoutAttributes = getAttributes();
                    $scope.attributesList = getFilteredAttributes(profile);
                    $scope.allAttributes = $scope.attributesList;
                    if (profile) {
                        $scope.activeExportProfile = profile;
                        $scope.vm.publicExportMapping = profile && !profile.privateMapping ? !profile.privateMapping : false;
                        $scope.notCurrentUsersMapping = profile.userId && profile.key ? !_.isEqual(profile.userId, $scope.$parent.user.userId) : false;
                        if (!_.isEmpty(profile.customValues)) {
                            prepareCustomValues(profile);
                        }
                        if ($scope.vm.showOnlySelected) {
                            $scope.attributesList = _.filter($scope.attributesList, {
                                selected: true
                            });
                        }
                    } else {
                        $scope.activeExportProfile = null;
                        $scope.vm.publicExportMapping = null;
                        $scope.notCurrentUsersMapping = null;
                        $scope.attributesList = _.forEach(getFilteredAttributes(profile), function(attribute){attribute.selected = true;});
                        prepareCustomValues();
                    }

                if ($scope.getSelectedCount() === $scope.attributesList.length) {
                    $scope.selectAllForMapping = true;
                } else {
                    $scope.selectAllForMapping = false;
                }
                $scope.sort = {
                    type: '',
                    reverse: null
                };
                $scope.editActiveExportProfile = false;
                $scope.tmpProfile = null;
                updateDropdown();
            };

            $scope.checkInput = function(profile) {
                if(!profile.name){
                    $scope.editActiveExportProfile = false;
                }
            };

            function getFilteredAttributes(activeProfile) {
                var filteredAttributes = [];
                if (activeProfile) {
                    filteredAttributes = _(activeProfile.mappings)
                        .map(function(attr) {
                            return {
                                internalName: attr.internalName,
                                label: $rootScope.translateAttribute($rootScope.dataModel.attribute(attr.internalName)),
                                externalName: attr.externalName || null,
                                selected: true
                            };
                        })
                        .unionBy($scope.layoutAttributes, 'internalName')
                        .filter(function(attribute) {
                            var layoutAttribute = _.find($scope.layoutAttributes, {'internalName': attribute.internalName});

                            if (_.isNil(layoutAttribute)) {
                                return false;
                            } else {
                                $rootScope.prepareAttribute(layoutAttribute);
                                // Always set layoutAttributes as selected if the export type doesn't useMappings
                                if (layoutAttribute.selected || !$scope.exportType.usesMapping) {
                                    attribute.selected = true;
                                }
                                return true;
                            }
                        })
                        .value();
                } else {
                    var categories = [];

                    if (!_.isNil($routeParams.category)) {
                        categories = [$routeParams.category];
                    } else if (!_.isEmpty(data.selectedFullItems) && data.selectedItems != "ALL" && _.isNil($routeParams.selectionId)) {
                        categories = _.reduce(data.selectedFullItems,function(filtered, option) {
                            if (!_.isNil(option.category__)) {
                               filtered.push(option.category__);
                            }
                            return filtered;
                          }, []);
                          categories = _.uniq(categories);
                    }

                    if (!_.isEmpty(categories)) {
                        _.forEach(categories, function(category) {
                            var attrs = $rootScope.dataModel.categoryAttributes(category);
                            filteredAttributes = filteredAttributes.concat(attrs);
                        });
                        filteredAttributes = _.map(angular.copy(filteredAttributes), function(attr) {
                            return {
                                internalName: attr.name,
                                label: attr.label,
                            };
                        });
                        var dontFilterAttrs = _.filter(angular.copy($scope.layoutAttributes), { 'dontFilter': true });
                        filteredAttributes = filteredAttributes.concat(dontFilterAttrs);
                        filteredAttributes = _.uniqBy(filteredAttributes, 'internalName');
                        filteredAttributes = _.intersectionBy($scope.layoutAttributes, filteredAttributes, 'internalName');
                    } else {
                        filteredAttributes = _.map(angular.copy($scope.layoutAttributes), function(attr) {
                            return {
                                internalName: attr.internalName,
                                label: attr.label,
                            };
                        });
                    }
                }
                return filteredAttributes;
            }

            function updateDropdown() {
                $scope.privateProfiles = false;
                $scope.publicProfiles = false;

                var profilesWithOutInternal = _.filter($scope.exportProfiles,
                    function(profile) {
                        return !_.includes(profile.key, "internal__");
                    }
                );

                if ($scope.showInternalProfile) {
                    $scope.privateProfiles = true;
                } else {
                    $scope.privateProfiles = _.some(profilesWithOutInternal, {
                        'userId': $scope.$parent.user.userId,
                        'privateMapping': true
                    });
                }

                var publicProfiles = _.some(profilesWithOutInternal, {
                    'privateMapping': false
                });
                var oldProfiles = _.filter(profilesWithOutInternal,
                    function(profile) {
                        return !_.includes(profile.key, "/");
                    }
                );
                $scope.publicProfiles = publicProfiles || oldProfiles.length > 0;
            }

            $scope.checkUserMapping = function(userId) {
                if(_.isEmpty(userId)){
                    return true;
                } else {
                    return _.isEqual(userId, $scope.$parent.user.userId);
                }
            };

            function prepareCustomValues(profile) {
                profile = profile || {};
                var customValues = profile.customValues || {};
                _.forEach($scope.exportAttributes, function(attr) {
                    if (profile.contentType == $scope.exportType.contentType) {
                        $scope.customValues[attr.name] = customValues[attr.name];
                        if (attr.typeName == "Document") {
                            var elementScope = angular.element("." + attr.name + "-upload-input").scope();
                            if (!_.isEmpty(elementScope)) {
                                elementScope.inputValue = customValues[attr.name];
                                elementScope.enterURL(customValues[attr.name]);
                            }
                        }
                    } else {
                        $scope.customValues[attr.name] = null;
                        $scope.$broadcast('clearAttributeValue', attr.name);
                    }
                });
            }

            $scope.uploadFileForItem = ExportMappingService.getUploadFileForItemConfiguration($scope);

            function init() {
                ExportMappingResource.getActive({},
                    function(response) {
                        var activeProfile = response.data;
                        $scope.layoutAttributes = getAttributes();
                        $scope.exportAttributes = _.map(data.exportType.attributes, function(attributeName) {
                            return $rootScope.dataModel.attribute(attributeName);
                        });
                        $rootScope.prepareAttributes($scope.exportAttributes, true);
                        if (activeProfile !== "") {
                            $scope.activeExportProfile = _.find($scope.exportProfiles, {
                                'key': activeProfile.key
                            });
                            $scope.showInternalProfile = _.includes($scope.activeExportProfile.key,'internal__');
                            $scope.vm.publicExportMapping = !$scope.activeExportProfile.privateMapping && !$scope.showInternalProfile ? !$scope.activeExportProfile.privateMapping : false;
                            $scope.notCurrentUsersMapping = $scope.activeExportProfile.userId && $scope.activeExportProfile.key ? !_.isEqual($scope.activeExportProfile.userId, $scope.$parent.user.userId) : false;
                            $scope.attributesList = getFilteredAttributes(activeProfile);
                        } else {
                            $scope.attributesList = _.forEach(getFilteredAttributes(activeProfile), function(attribute){attribute.selected = true;});
                        }
                        $scope.allAttributes = $scope.attributesList;
                        prepareCustomValues(activeProfile);
                        updateDropdown();
                        $scope.sort = {
                            type: '',
                            reverse: null
                        };
                    },
                    function(response) {
                        growl.error('UPLOADED_MAPPING_FAILED');
                    }
                );
            }
            init();
        }
    );
