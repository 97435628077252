angular.module('llax')
    .factory('DashletRegistry', function($rootScope) {

        var dashlets = [];

        var defaultWidgets = [];

        var fillInDefaults = function(dashlet) {
            dashlet.size = dashlet.size || {
                width: '50%',
                height: '280px'
            };
            dashlet.dataAttrName = dashlet.dataAttrName || 'value';
            return dashlet;
        };

        var dashletRegistry = {
            addAll: function(dashlets_) {

                dashlets_ = dashlets_.map(function(dashlet) {
                    return fillInDefaults(dashlet);
                });

                _.forEach(dashlets_, function(dashlet) {
                    if (window.location.hostname !== 'localhost' && dashlet.localOnly) {
                        return;
                    }
                    var existingDashlet = _.find(dashlets, { name: dashlet.name });
                    if (existingDashlet) {
                        // replace dashlet in list
                       dashlets.map(function(_dashlet) {
                           if (_dashlet.name === dashlet.name){
                               return dashlet;
                           }
                           return _dashlet;
                       });
                    } else {
                        dashlets.push(dashlet);
                    }
                });
            },
            getDashlets: function() {
                return dashlets;
            },
            addToDefaultDashlets: function(widgets) {
                defaultWidgets = defaultWidgets.concat(widgets);
            },
            getDefaultDashlets: function() {
                return defaultWidgets;
            }

        };
        return dashletRegistry;
    })
    .service('CustomDashletRegistry', function() {
        //This is a Dummy Service, that has to be redefined by the customer
        // needed for injection in DashboardController, if no custom Registry is defined
        return {
            load: function() {
            }
        };
    });

// Dashlet Factories:
angular.module('llax')
.factory('CategoriesOverviewFactory', function($location, $rootScope, DashboardService, PieChartFactory, StatisticsResource) {

    var getCategoriesOverviewDataLoader = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                response = response.filter(function(elem) {
                    return elem.count > 0;
                });

                var NO_CATEGORY = "NO_CATEGORY";
                var noCategoriesEntry = response.filter(function(elem) {
                    return elem.key == NO_CATEGORY;
                })[0];

                // Always add a "NO_CATEGORY" entry
                if (!noCategoriesEntry) {
                    response.push({
                        categoryName: NO_CATEGORY,
                        occurence: 0
                    });
                }

                var data = response.map(function(elem) {
                    return {
                        label: elem.key,
                        data: elem.count
                    };
                });

                callback(data);

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__categories_statistic';
            var dimensionAttributes = config.dimensionAttributes || null;

            _.defaults(config, {
                labelTranslator: function(label) {
                    return $rootScope.translateCategory($rootScope.dataModel.category(label));
                },
                linkFunction: function(label) {
                    var link = DashboardService.getBrowseLink({
                        'category': label
                    }, dimensionAttributes);
                    return link;
                },
                plotClick: function(plot) {
                    var location = DashboardService.getBrowseLocation({
                        'category': plot.series.label,
                    }, dimensionAttributes);
                    $location.search(location).path("/browse");
                },
                dataLoader: getCategoriesOverviewDataLoader(statisticName, dimensionAttributes),
                showLegend: true
            });

            return PieChartFactory.create(config);
        }
    };
})
.factory('CategoriesListFactory', function($rootScope, DashboardService, GenericDashletFactory, StatisticsResource) {

    var getLoadCategoriesList = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var data = _.map(response, function(elem) {
                    elem.text = $rootScope.translateCategory(elem.key);
                    elem.link = DashboardService.getBrowseLink({
                        'category': elem.key,
                    }, attributes);
                    return elem;
                });
                callback({
                   data: data
                });

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__categories_statistic';
            var dimensionAttributes = config.dimensionAttributes || null;

            _.defaults(config, {
                fetchData: getLoadCategoriesList(statisticName, dimensionAttributes)
            });

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('ComplianceStatisticsFactory', function($location, DashboardService, PieChartFactory, StatisticsResource) {

    var complianceBrowseLocation = function(compliant, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return DashboardService.getBrowseLocation({
            'compliant': compliant,
        }, attributes);
    };

    var getComplianceDataLoader = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var compliantsData = {
                    compliant: 0,
                    notCompliant: 0
                };

                _.each(response, function(elem) {
                    switch (elem.key) {
                    case 'compliant':
                        compliantsData.compliant += elem.count;
                        break;
                    case 'non_compliant':
                        compliantsData.notCompliant += elem.count;
                        break;
                    }
                });

                var data;
                if (compliantsData.compliant === 0 && compliantsData.notCompliant === 0) {
                    data = [];
                } else {
                    data = [{
                        label: 'Compliant Items',
                        data: compliantsData.compliant
                    }, {
                        label: 'NOT Compliant Items',
                        data: compliantsData.notCompliant
                    } ];
                }

                callback(data);

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__compliance_statistic';
            var dimensionAttributes = config.dimensionAttributes || null;
            var plotFunction = config.plotFunction || function(plot) {
                var compliant = plot.seriesIndex == 0 ? "true" : "false";
                var location = complianceBrowseLocation(compliant, dimensionAttributes);
                $location.search(location).path("/browse");
            };

            _.defaults(config, {
                colors: ["#7AD09E", "#FA9C9C"],
                plotClick: plotFunction,
                dataLoader: getComplianceDataLoader(statisticName, dimensionAttributes)
            });

            return PieChartFactory.create(config);
        }
    };
})
.factory('ValidationsOverviewFactory', function($rootScope, GenericDashletFactory, DashboardService, StatisticsResource) {

    var getValidationsOverviewList = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var data = response.map(function(element) {
                    element.text = $rootScope.translateValidationLabel(element.key);
                    element.link = DashboardService.getBrowseLink({
                        'errorKey': element.key,
                    }, attributes);
                    return element;
                });

                callback({
                    data: data
                });

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__validations_overview';
            var dimensionAttributes = config.dimensionAttributes || null;
            _.defaults(config, {
                fetchData: getValidationsOverviewList(statisticName, dimensionAttributes)
            });

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('WarningsOverviewFactory', function($rootScope, GenericDashletFactory, DashboardService, StatisticsResource) {

    var getValidationsWarningList = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var data = response.map(function(element) {
                    element.text = $rootScope.translateValidationLabel(element.key);
                    element.link = DashboardService.getBrowseLink({
                        'warningKey': element.key,
                    }, attributes);
                    return element;
                });

                callback({
                    data: data
                });

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__validation_warnings';
            var dimensionAttributes = config.dimensionAttributes || null;
            _.defaults(config, {
                fetchData: getValidationsWarningList(statisticName, dimensionAttributes)
            });

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('ReviewErrorsOverviewFactory', function($rootScope, GenericDashletFactory, DashboardService, StatisticsResource) {

    var getReviewErrorsOverviewList = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var data = response.map(function(element) {
                    element.text = $rootScope.translateValidationLabel(element.key);
                    element.link = DashboardService.getBrowseLink({
                        'reviewErrorKey': element.key,
                    }, attributes);
                    return element;
                });

                callback({
                    data: data
                });

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__review_errors';
            var dimensionAttributes = config.dimensionAttributes || null;
            _.defaults(config, {
                fetchData: getReviewErrorsOverviewList(statisticName, dimensionAttributes)
            });

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('ReviewWarningsOverviewFactory', function($rootScope, GenericDashletFactory, DashboardService, StatisticsResource) {

    var getReviewWarningList = function(name, attributes) {

        if (_.isUndefined(attributes)) {
            attributes = _.slice(arguments, 1);
        } else if (_.isString(attributes)) {
            attributes = [attributes];
        }

        return function(callback) {
            var dimension = DashboardService.getDimensionValue(attributes);
            StatisticsResource.query({
                name: name,
                dimension: dimension
            }, function(response) {

                var data = response.map(function(element) {
                    element.text = $rootScope.translateValidationLabel(element.key);
                    element.link = DashboardService.getBrowseLink({
                        'reviewWarningKey': element.key,
                    }, attributes);
                    return element;
                });

                callback({
                    data: data
                });

            }, function(errorResponse) {
                callback({});
            });
        };
    };

    return {
        create: function(config) {

            config = config || {};
            var statisticName = config.statisticName || '__review_warnings';
            var dimensionAttributes = config.dimensionAttributes || null;
            _.defaults(config, {
                fetchData: getReviewWarningList(statisticName, dimensionAttributes)
            });

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('LatestItemsDashletFactory', function($rootScope, GenericDashletFactory, ItemResource) {
    return {
        create: function(config) {

            config = config || {};

            config.fetchData = function(callback) {

                var model = {};

                model.getItemDescription = function(item) {
                    return $rootScope.getItemTitle('dashlet', item);
                };

                model.getItemClass = function(item) {
                    if (item.createdAt__ == item.updatedAt__) {
                        return "widget-item-new";
                    } else {
                        return "widget-item-updated";
                    }
                };

                ItemResource.query({
                    primaryKey: 'latest',
                    count: 20
                }, function(response) {
                    model.items = response;
                    callback(model);
                });
            };

            return GenericDashletFactory.create(config);
        }
    };

})
.factory('RubyOutputDashletFactory', function(WidgetDataModel, RubyScriptService) {
    function RubyOutputService() {}

    RubyOutputService.prototype = Object.create(WidgetDataModel.prototype);
    RubyOutputService.prototype.constructor = WidgetDataModel;

    angular.extend(RubyOutputService.prototype, {
        init: function() {
            var me = this;
            var widgetAttributes = me.widgetScope.widget.attrs; // this is where the custom Settings of the widget instance are stored!

            var model = {
                attributes: widgetAttributes
            };

            me.widgetScope.$watch("widget.attrs", function(newValue, oldValue) {
                executeRubyScript();
            }, true);

            function executeRubyScript() {
                if (widgetAttributes && widgetAttributes.script) {
                    RubyScriptService.executeScript(widgetAttributes.script)
                        .then(function(data) {
                            model.data = data;
                            me.updateScope(model);
                        });
                }
            }

            executeRubyScript();
        }
    });
    return RubyOutputService;
})
.factory('DocumentationDashletFactory', function($rootScope, WidgetDataModel) {
    function DocumentationDashletService() {}

    DocumentationDashletService.prototype = Object.create(WidgetDataModel.prototype);
    DocumentationDashletService.prototype.constructor = WidgetDataModel;

    angular.extend(DocumentationDashletService.prototype, {
        init: function() {
            var me = this;
            var widgetAttributes = me.widgetScope.widget.attrs; // this is where the custom Settings of the widget instance are stored!

            var model = {
                attributes: widgetAttributes,
                url: $rootScope.translate("DOCUMENTATION.DASHLET.URL")
            };

            function setSize (data) {
                var newHeight = data ? data.height : parseInt(me.widgetScope.widget.size.height, 10);
                newHeight = newHeight - 35; // widget-content padding
                model.height = newHeight + 'px';
            }
            setSize();

            me.widgetScope.$on('widgetResized', function(event, data) {
                setSize(data);
            });

            me.updateScope(model);
        }
    });
    return DocumentationDashletService;
})
.factory('ItemsChangedPerDayFactory', function(GenericDashletFactory) {
    return {
        create: function(barConfig) {
            var config = angular.copy(barConfig || {});

            config.fetchData = function(callback) {
                var model = {
                    data: [],
                };

                var barDataLoadedCallback = function(barData) {
                    var options = barConfig.options;
                    if (barData.xaxisTicks) {
                        options.xaxis.ticks = barData.xaxisTicks;
                    }

                    model.data = barData.data;
                    model.options = options;
                    model.plotclick = barConfig.plotClick;
                    callback(model);
                };

                barConfig.dataLoader = barConfig.loadStatisticsData;
                barConfig.dataLoader(barDataLoadedCallback);
            };

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('StatisticsPieChartFactory', function($rootScope, StatisticsResource, PieChartFactory, $location, $translate) {
    return {
        create: function(pieConfig) {

            var loadStatisticsData = function(callback) {

                StatisticsResource.query({
                    name: pieConfig.statisticsKey,
                    limit: pieConfig.count || 20
                }, function(statistics) {

                    statistics = statistics.filter(function(elem) {
                        return elem.count > 0;
                    });

                    var data = statistics
                        .map(function(elem, index) {
                            return {
                                label: elem.key,
                                data: elem.count
                            };
                        });

                    if (data.length === 1) {
                        data.push({
                            label: "WIDGET.OTHER_VALUES"
                        });
                    }

                    callback(data);
                }, function(errorResponse) {
                    callback({});
                });
            };

            pieConfig.dataLoader = loadStatisticsData;

            return PieChartFactory.create(pieConfig);
        }
    };
})
.factory('PieChartFactory', function($rootScope, $translate, GenericDashletFactory) {
    return {
        create: function(pieConfig) {

            var config = angular.copy(pieConfig || {});

            var translateFunction = config.labelTranslator || $translate.instant;

            var colors = config.colors || ["#7AD09E", "#93EBE4", "#EAD5FA", "#FA9C9C", "#F8D972", "#4777AF"];

            config.fetchData = function(callback) {

                var model = {
                    data: []
                };

                model.plotclick = function(event, pos, obj) {
                    if (!obj || !config.plotClick) {
                        return;
                    }
                    $rootScope.$apply(function() {
                        config.plotClick(obj);
                    });
                };

                model.options = {
                    series: {
                        pie: {
                            show: true,
                            radius: 1,
                            label: {
                                show: true,
                                radius: 1 / 2,
                                formatter: function(label, series) {
                                    return '<div class="pie">' + series.data[0][1] + '</div>';
                                }
                            }
                        }
                    },
                    grid: {
                        hoverable: true,
                        clickable: true
                    },
                    legend: {
                        show: config.showLegend || false,
                        labelFormatter: function(label, series) {
                            var link = "#";
                            if (config.linkFunction) {
                                link = config.linkFunction(label);
                            }
                            return '<a href ="' + link + '">' + translateFunction(label) + ' (' + series.data[0][1] +
                                ')</a>';
                        }
                    }
                };

                model.loading = true;

                var pieDataLoadedCallback = function(pieData) {

                    pieData = pieData.map(function(elem, index) {
                        elem.color = colors[index % colors.length];
                        return elem;
                    });

                    model.loading = false;
                    model.dataExisting = pieData.length > 0;
                    model.data = pieData;

                    callback(model);
                };

                pieConfig.dataLoader(pieDataLoadedCallback);

            };

            return GenericDashletFactory.create(config);
        }
    };
})
.factory('GenericDashletFactory', function($interval, WidgetDataModel) {
    return {
        create: function(config) {

            config = config || {};

            function GenericDashletFactory() {}

            GenericDashletFactory.prototype = Object.create(WidgetDataModel.prototype);
            GenericDashletFactory.prototype.constructor = WidgetDataModel;

            function genericLinkFunction(key) {
                return "/browse?q=" + key;
            }

            angular.extend(GenericDashletFactory.prototype, {
                init: function() {

                    var me = this;

                    function dataFetchedCallback(model) {
                        me.updateScope(model);
                    }
                    config.fetchData(dataFetchedCallback);
                },

                destroy: function() {
                    WidgetDataModel.prototype.destroy.call(this);
                }
            });

            return GenericDashletFactory;
        }
    };
})
.factory('NewsDashletFactory', function($rootScope, WidgetDataModel,$translate) {
    function NewsDashletService() {}

    NewsDashletService.prototype = Object.create(WidgetDataModel.prototype);
    NewsDashletService.prototype.constructor = WidgetDataModel;
    angular.extend(NewsDashletService.prototype, {
        init: function() {
            var me = this;
            var widgetAttributes = me.widgetScope.widget.attrs; // this is where the custom Settings of the widget instance are stored!

            var model = {
                attributes: widgetAttributes,
                updateUrl: function(index) {
                this.attributes.selectedUrl = $rootScope.translate("DASHLET.NEWS.URL." + index,"");
                }
            };

            function setSize (data) {
                var newHeight = data ? data.height : parseInt(me.widgetScope.widget.size.height, 10);
                newHeight = newHeight - 35; // widget-content padding
                model.height = newHeight + 'px';
            }
            setSize();
            me.widgetScope.$on('widgetResized', function(event, data) {
                setSize(data);
            });

            me.updateScope(model);
        }
    });
    return NewsDashletService;
})
.factory('PublicationsByDestinationsFactory', function($q, $rootScope, $translate, CommunicationChannelService, GenericDashletFactory, ReviewsAndPublicationsDashletService, StatisticsResource) {
    function getGridOptions() {
        var columnDefs = [{
            field: 'destination',
            displayName: $translate.instant('WIDGET.PUBLICATIONS.DESTINATION'),
            headerTooltip: true,
            width: '*',
            cellTemplate:
                '<div class="ui-grid-cell-contents grid-cell-bordered" data-ng-class="{\'widget-grid-sub-destination\': row.entity.shouldBeDrawnAsSubDestination }">' +
                    '{{row.entity.destination}}' +
                '</div>'
        },
        {
            field: 'total_published',
            displayName: $translate.instant('WIDGET.PUBLICATIONS.ITEMS_PUBLISHED'),
            headerTooltip: true,
            width: '*',
            headerCellClass: 'text-center',
            cellTemplate:
            '<div class="stats-grid-cell ui-grid-cell-contents grid-cell-bordered grid-cell-p-0">' +
                '<div class="widget-dummy-cell" data-ng-if="row.entity.isDummyEntry">' +
                    '-' +
                '</div>' +
                '<div data-ng-if="!row.entity.isDummyEntry">' +
                    '<div style="width:{{row.entity.total_published_bar_width}}%" class="total_published APPROVED stats-grid-cell-bar"></div>' +
                    '<span style="vertical-align: sub;" data-ng-show="row.entity.published == 0">{{row.entity.published}}</span>' +
                    '<a class="stats-grid-cell-a" data-ng-show="row.entity.published> 0"' +
                        'data-ng-href="/browse?publicationDestination={{row.entity.destinationId}}" target="_self">' +
                        '{{row.entity.published}}' +
                    '</a>' +
                '</div>' +
            '</div>'
        },
        {
            field: 'total_dirty_publications',
            displayName: $translate.instant('WIDGET.PUBLICATIONS.ITEMS_DIRTY'),
            headerTooltip: true,
            width: '*',
            headerCellClass: 'text-center',
            cellTemplate:
            '<div class="stats-grid-cell ui-grid-cell-contents grid-cell-bordered grid-cell-p-0">' +
                '<div class="widget-dummy-cell" data-ng-if="row.entity.isDummyEntry">' +
                    '-' +
                '</div>' +
                '<div data-ng-if="!row.entity.isDummyEntry">' +
                    '<div style="width:{{row.entity.total_dirty_published_bar_width}}%" class="REVIEWED stats-grid-cell-bar"></div>' +
                    '<span style="vertical-align: sub;" data-ng-show="row.entity.dirty == 0">{{row.entity.dirty}}</span>' +
                    '<a class="stats-grid-cell-a" data-ng-show="row.entity.dirty> 0"' +
                        'data-ng-href="/browse?publicationDestination={{row.entity.destinationId}}&publicationStatus=DIRTY" target="_self">' +
                        '{{row.entity.dirty}}' +
                    '</a>' +
                '</div>' +
            '</div>'
        },
        {
            field: 'active_publications',
            displayName: $translate.instant('WIDGET.PUBLICATIONS.ITEMS_ACTIVE'),
            headerTooltip: true,
            width: '*',
            headerCellClass: 'text-center',
            cellTemplate:
            '<div class="stats-grid-cell ui-grid-cell-contents grid-cell-bordered grid-cell-p-0">' +
                '<div class="widget-dummy-cell" data-ng-if="row.entity.isDummyEntry">' +
                    '-' +
                '</div>' +
                '<div data-ng-if="!row.entity.isDummyEntry">' +
                    '<div class="stats-grid-cell-bar"></div>' +
                    '<span style="vertical-align: sub;" data-ng-show="row.entity.active == 0">{{row.entity.active}}</span>' +
                    '<a class="stats-grid-cell-a" data-ng-show="row.entity.active> 0"' +
                        'data-ng-href="/browse?publicationDestination={{row.entity.destinationId}}&publicationStatus=ACTIVE" target="_self">' +
                        '{{row.entity.active}}' +
                    '</a>' +
                '</div>' +
            '</div>'
        }
    ];

        return {
            data: 'gridModel',
            enableColumnMenus: false,
            columnDefs: columnDefs,
            enableColumnResize: true,
            rowHeight: 34
        };
    }

    return {
        create: function(config, resource) {
            config = config || {};
            config.fetchData = function(callback) {
                var model = {
                    publications: [], // Actual publications
                    gridModel: [],    // Actual publications + dummy parent channel entries
                    gridDataVar: 'gridModel',
                    gridOptions: getGridOptions()
                };

                CommunicationChannelService.loadPublicationDestinations({
                    'publicationMode' : 'PUBLISH'
                }).then(function(response) {
                    var allQueries = [];
                    var dimensionMap = {};

                    if (response.communicationChannels) {
                        allQueries = ReviewsAndPublicationsDashletService.getReviewStatisticsForChannels(response.communicationChannels, dimensionMap);
                    }

                    if (response.organizations) {
                        allQueries = allQueries.concat(ReviewsAndPublicationsDashletService.getReviewStatisticsForOrganizations(response.organizations, dimensionMap));
                    }

                    StatisticsResource.query({
                        name: '__publications_statistic'
                    }, function(response) {
                        var publicationEntry = [];
                        response.forEach(function(item) {
                            var dimension = item.dimension; // format: <channelId[:<subdest>]>
                            var destination = dimensionMap[dimension];
                            if (!_.isNil(destination)) {
                                var existingItem = publicationEntry.find(function(element) {
                                    return element.destinationId == dimension;
                                });

                                if (existingItem) {
                                    // If the found item was a formerly created dummy entry, convert it to a regular one
                                    if (existingItem.isDummyEntry) {
                                        existingItem.isDummyEntry = false;
                                        existingItem.active = 0;
                                        existingItem.published = 0;
                                        existingItem.dirty = 0;

                                        StatisticsResource.query({
                                            name: '__compliance_statistic'
                                        }, function(response) {
                                            var published = _.find(response, { 'key': 'published' });
                                            existingItem.total_published_bar_width = ((existingItem.published/published.count) * 100).toFixed(1);
                                            existingItem.total_dirty_published_bar_width = ((existingItem.dirty/published.count) * 100).toFixed(1);
                                        });

                                    }
                                    existingItem[item.key] = item.count;
                                } else {
                                    var newItem = {
                                        destination: destination.name,
                                        destinationId: dimension,
                                        active : 0,
                                        published:0,
                                        dirty: 0
                                    };
                                    newItem[item.key] = item.count;
                                    if (destination.subDestinationKey) {
                                        newItem.shouldBeDrawnAsSubDestination = true;
                                        if(!ReviewsAndPublicationsDashletService.isParentChannelInCollection(publicationEntry, destination)) {
                                            // Add dummy parent channel entry, if there isn't one.
                                            publicationEntry.push({
                                                destination: destination.name,
                                                destinationId: destination.id,
                                                isDummyEntry: true
                                            });
                                        }
                                        newItem.destination = destination.subDestinationLabel + ' (' + destination.subDestinationKey + ')';
                                    }

                                    StatisticsResource.query({
                                        name: '__compliance_statistic'
                                    }, function(response) {
                                        var published = _.find(response, { 'key': 'published' });
                                        newItem.total_published_bar_width = ((newItem.published/published.count) * 100).toFixed(1);
                                        newItem.total_dirty_published_bar_width = ((newItem.dirty/published.count) * 100).toFixed(1);
                                    });

                                    publicationEntry.push(newItem);
                                }
                            }
                        });

                        publicationEntry.sort(function(a,b) {
                            destId1 = String(a.destinationId);
                            destId2 = String(b.destinationId);
                            return destId1.localeCompare(destId2);
                        });

                        model.publications = publicationEntry;
                        model.gridModel = publicationEntry;
                        callback(model);
                    });
                });
            };
            return GenericDashletFactory.create(config);
        }
    };
})
.factory('ReviewsFactory', function($q, $rootScope, $translate, CommunicationChannelService, GenericDashletFactory, ReviewsAndPublicationsDashletService, StatisticsResource) {
    var reviewStatusMap = {
        RECEIVED: 'REVIEW.RECEIVED',
        REVIEWED: 'REVIEW.REVIEWED',
        APPROVED: 'REVIEW.APPROVED',
        REJECTED: 'REVIEW.REJECTED'
    };

    function getGridOptions() {
        var columnDefs = [{
            field: 'reviewer',
            displayName: $translate.instant('WIDGET.REVIEWS.REVIEWER'),
            headerTooltip: true,
            cellTooltip: true,
            width: '*',
            cellTemplate:
                '<div class="ui-grid-cell-contents grid-cell-bordered" data-ng-class="{\'widget-grid-sub-destination\': row.entity.shouldBeDrawnAsSubDestination }">' +
                    '{{row.entity.reviewer}}' +
                '</div>'
        }];

        _.forEach(reviewStatusMap, function(label, status) {
            var href;
            if (status === 'total_published') {
                href = '/browse?publicationDestination={{row.entity.destinationId}}';
            } else {
                href = '/browse?reviewer={{row.entity.destinationId}}&&reviewStatus=' + status.toUpperCase();
            }

            var cellTemplate =
                '<div class="stats-grid-cell ui-grid-cell-contents grid-cell-bordered grid-cell-p-0">' +
                    '<div class="widget-dummy-cell" data-ng-if="row.entity.isDummyEntry">' +
                        '-' +
                    '</div>' +
                    '<div data-ng-if="!row.entity.isDummyEntry">' +
                        '<div style="width:{{row.entity.' + status + '_bar_width}}%" class="' + status + ' stats-grid-cell-bar"></div>' +
                        '<span style="vertical-align: sub;" data-ng-show="row.entity.' + status + ' == 0">{{row.entity.' + status + '}}</span>' +
                        '<a class="stats-grid-cell-a" data-ng-show="row.entity.' + status + ' > 0"' +
                            'data-ng-href="' + href + '" target="_self">' +
                            '{{row.entity.' + status + '}}' +
                        '</a>' +
                    '</div>' +
                '</div>';
            columnDefs.push({
                field: status,
                displayName: $translate.instant(label),
                headerTooltip: true,
                width: '*',
                headerCellClass: 'text-center',
                cellTemplate : cellTemplate
            });
        });

        return {
            data: 'gridModel',
            enableColumnMenus: false,
            columnDefs: columnDefs,
            enableColumnResize: true,
            rowHeight: 34
        };
    }

    return {
        create: function(config, resource) {
            config = config || {};
            config.fetchData = function(callback) {
                var model = {
                    reviewsByDimension: [], // Actual publications
                    gridModel: [],          // Actual publications + dummy parent channel entries
                    gridDataVar: 'gridModel',
                    gridOptions: getGridOptions()
                };
                var columnDefs = model.gridOptions.columnDefs;

                CommunicationChannelService.loadPublicationDestinations({
                    'publicationMode' : 'PUBLISH'
                }).then(function(response) {
                    var allQueries = [];
                    var dimensionMap = {};
                    var reviewEntries = [];

                    if (response.communicationChannels) {
                        allQueries = ReviewsAndPublicationsDashletService.getReviewStatisticsForChannels(response.communicationChannels, dimensionMap);
                    }
                    if (response.organizations) {
                        allQueries = allQueries.concat(ReviewsAndPublicationsDashletService.getReviewStatisticsForOrganizations(response.organizations, dimensionMap));
                    }

                    $q.all(allQueries).then(function(allResponses) {
                        _.forEach(allResponses, function(statisticsByDimensions) {
                            if (!_.isEmpty(statisticsByDimensions)) {
                                var dimension = statisticsByDimensions[0].dimension;
                                var destination = dimensionMap[dimension];
                                var dimensionEntry = {
                                    reviewer: destination.name,
                                    destinationId: dimension,
                                    total_reviews: 0,
                                    APPROVED: 0,
                                    RECEIVED: 0,
                                    REJECTED: 0,
                                    REVIEWED: 0
                                };

                                if (destination.subDestinationKey) {
                                    dimensionEntry.shouldBeDrawnAsSubDestination = true;
                                    if(!ReviewsAndPublicationsDashletService.isParentChannelInCollection(reviewEntries, destination)) {
                                        // Add dummy parent channel entry, if there isn't one.
                                        reviewEntries.push({
                                            reviewer: destination.name,
                                            destinationId: destination.id,
                                            isDummyEntry: true
                                        });
                                    }
                                    dimensionEntry.reviewer = destination.subDestinationLabel + ' (' + destination.subDestinationKey + ')';
                                }
                                _.forEach(statisticsByDimensions, function(statistic) {
                                    dimensionEntry[statistic.key] = statistic.count;
                                });

                                _.forEach(dimensionEntry, function(columnValue, columnKey) {
                                    if (columnKey in reviewStatusMap) {
                                        dimensionEntry[columnKey + '_bar_width'] = ((columnValue/dimensionEntry.total_published) * 100).toFixed(1);
                                    }
                                });

                                reviewEntries.push(dimensionEntry);
                            }
                        });

                        var sumForEachReviewStatus = ReviewsAndPublicationsDashletService.calculateSumOfReviewsStatus(reviewEntries, reviewStatusMap);

                        var COLUMN_REVIEWER = 0;
                        columnDefs[COLUMN_REVIEWER].displayName += ' (' + model.reviewsByDimension.length + ')';

                        _.forEach(columnDefs, function(column) {
                            if (column.field in reviewStatusMap && column.field in sumForEachReviewStatus) {
                                column.displayName += ' (' + sumForEachReviewStatus[column.field] + ')';
                            }
                        });

                        reviewEntries.sort(function(a,b) {
                            destId1 = String(a.destinationId);
                            destId2 = String(b.destinationId);
                            return destId1.localeCompare(destId2);
                        });

                        model.reviewsByDimension = reviewEntries;
                        model.gridModel = reviewEntries;

                        callback(model);
                    });
                });
            };
            return GenericDashletFactory.create(config);
        }
    };
});

// Dashlet Services and Wrappers
angular.module('llax')
    .service('ReviewsAndPublicationsDashletService', function(StatisticsResource) {
        function getReviewStatisticsQuery(dimension) {
            return StatisticsResource.query({
                name: '__review_statistic',
                dimension: dimension
            }).$promise;
        }

        this.getReviewStatisticsForChannels = function(channels, dimensionMap) {
            var promises = [];
            _.forEach(channels, function(channel) {
                var key = channel.id;
                if (!_.isNil(channel.subDestinationKey)) {
                    key = channel.id + ':' + channel.subDestinationKey;
                }
                dimensionMap[key] = channel;
                promises.push(getReviewStatisticsQuery(key));
            });
            return promises;
        };

        this.getReviewStatisticsForOrganizations = function(organizations, dimensionMap) {
            var promises = [];
            _.forEach(organizations, function(organization) {
                var key = organization.organizationId;
                dimensionMap[key] = organization;
                promises.push(getReviewStatisticsQuery(key));
            });
            return promises;
        };

        this.calculateSumOfReviewsStatus = function(reviews, reviewStatusMap) {
            return _.reduce(reviews, function(result, review) {
                _.forEach(reviewStatusMap, function(reviewLabel, reviewStatus) {
                    if (reviewStatus in review) {
                        if (!result[reviewStatus]) {
                            result[reviewStatus] = 0;
                        }
                        result[reviewStatus] += review[reviewStatus];
                    }
                });
                return result;
            }, {});
        };

        this.isParentChannelInCollection = function(publications, destination) {
            var isPresent = false;
            _.forEach(publications, function(publication) {
                if (publication.destinationId == destination.id) {
                    isPresent = true;
                }
            });
            return isPresent;
        };
    });

angular.module('llax')
    .directive('wtLatestItems', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/latest-items-widget.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtDocumentation', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/documentation-widget.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtRubyOutput', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/ruby-widget.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtComplianceStatistics', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/compliancestatistics.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtPieChart', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/generic-pie-chart-widget.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtGenericListView', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/generic-list-view.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtNews', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/news-widget.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('wtMyTasks', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/my-tasks.tpl.html',
            scope: {
                value: '=value'
            }
        };
    })
    .directive('gridWidget', function() {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'tpl/dashlets/publications-by-destinations.tpl.html',
            scope: {
                value: '=value'
            },
            link: function(scope, elem, attrs, ngModelCtrl) {
                scope.$watch('value', function(newValue, oldValue) {
                    if (!_.isNil(newValue) && !_.isNil(scope.value) && !_.isNil(scope.value.gridDataVar)) {
                        if (!_.isEmpty(scope.value[scope.value.gridDataVar])) {
                            scope[scope.value.gridDataVar] = scope.value[scope.value.gridDataVar];
                            scope.gridOptions = scope.value.gridOptions;
                            scope.value.dataExisting = true;
                        } else {
                            scope.value.dataExisting = false;
                        }
                    }
                }, true);
            }
        };
    })
    .directive('widget', function($timeout, $window) {
        return {
            restrict: 'A',
            priority: 1,
            link: function(scope, $element, attrs) {
                var widgetContainer = jQuery($element).find('.widget-content');
                var containerScope = angular.element(widgetContainer).scope();
                // overriding vertical resize functionality
                containerScope.grabSouthResizer = function(e) {
                    var widgetElm = $element.find('.widget');

                    // ignore middle- and right-click
                    if (e.which !== 1) {
                        return;
                    }
                    e.stopPropagation();
                    e.originalEvent.preventDefault();

                    // get the starting horizontal position
                    var initY = e.clientY;

                    // Get the current width of the widget and dashboard
                    var pixelWidth = widgetElm.width();
                    var pixelHeight = widgetElm.height();

                    // create marquee element for resize action
                    var $marquee = angular.element('<div class="widget-resizer-marquee" style="height: ' + pixelHeight + 'px; width: ' + pixelWidth + 'px;"></div>');
                    widgetElm.append($marquee);

                    // updates marquee with preview of new height
                    var mousemove = function(e) {
                        var curY = e.clientY;
                        var pixelChange = curY - initY;
                        var newHeight = pixelHeight + pixelChange;
                        $marquee.css('height', newHeight + 'px');
                    };

                    // sets new widget width on mouseup
                    var mouseup = function(e) {
                        // remove listener and marquee
                        jQuery($window).off('mousemove', mousemove);
                        $marquee.remove();

                        // calculate height change
                        var curY = e.clientY;
                        var pixelChange = curY - initY;

                        var diff = pixelChange;
                        var widgetContainer = widgetElm.find('.widget-content');
                        var height = parseInt(widgetContainer.outerHeight(), 10);
                        var newHeight = (height + diff);
                        var roundHeighht = 20 * Math.round(newHeight / 20);

                        var chartEl = widgetContainer.find('.chart-div');
                        if (chartEl[0]) {
                            var divHt = chartEl.height();
                            chartEl.css('height',(divHt+diff)+ 'px');
                        }

                        containerScope.widget.setHeight(roundHeighht + 'px');

                        containerScope.$emit('widgetChanged', containerScope.widget);
                        containerScope.$apply(); // make AngularJS to apply style changes

                        containerScope.$broadcast('widgetResized', {
                            height: roundHeighht
                        });
                    };
                    jQuery($window).on('mousemove', mousemove).one('mouseup', mouseup);
                };

                // overriding horizantal resize functionality
                containerScope.grabResizer = function(e) {
                    var widget = containerScope.widget;
                    var widgetElm = $element.find('.widget');

                    // ignore middle- and right-click
                    if (e.which !== 1) {
                        return;
                    }

                    e.stopPropagation();
                    e.originalEvent.preventDefault();

                    // get the starting horizontal position
                    var initX = e.clientX;

                    // Get the current width of the widget and dashboard
                    var pixelWidth = widgetElm.width();
                    var pixelHeight = widgetElm.height();
                    var widgetStyleWidth = widget.containerStyle.width;
                    var widthUnits = widget.widthUnits;
                    var unitWidth = parseFloat(widgetStyleWidth);

                    // create marquee element for resize action
                    var $marquee = angular.element('<div class="widget-resizer-marquee" style="height: ' + pixelHeight + 'px; width: ' + pixelWidth + 'px;"></div>');
                    widgetElm.append($marquee);

                    // determine the unit/pixel ratio
                    var transformMultiplier = unitWidth / pixelWidth;

                    // updates marquee with preview of new width
                    var mousemove = function(e) {
                        var curX = e.clientX;
                        var pixelChange = curX - initX;
                        var newWidth = pixelWidth + pixelChange;
                        $marquee.css('width', newWidth + 'px');
                    };

                    // sets new widget width on mouseup
                    var mouseup = function(e) {
                        // remove listener and marquee
                        jQuery($window).off('mousemove', mousemove);
                        $marquee.remove();

                        // calculate change in units
                        var curX = e.clientX;
                        var pixelChange = curX - initX;
                        var unitChange = Math.round(pixelChange * transformMultiplier * 100) / 100;

                        // add to initial unit width
                        var newWidth = unitWidth * 1 + unitChange;
                        var roundWidth = 2 * Math.round(newWidth / 2);
                        widget.setWidth(roundWidth + widthUnits);
                        containerScope.$emit('widgetChanged', widget);
                        containerScope.$apply();
                        containerScope.$broadcast('widgetResized', {
                            width: roundWidth
                        });
                    };

                    jQuery($window).on('mousemove', mousemove).one('mouseup', mouseup);
                };
            }
        };
    });
