angular.module('llax')
    .controller('UserManagementController',
        function($rootScope, $scope, $translate, $timeout, $dialogs, $modal, $q, ExportUsersResource, UsersService, UsersResource, growl, InviteByEmailResource, UserResource, UsageLimitsResource, RolesService, UsersGroupService, ChannelService) {

            $scope.inviteByEmail = function(userAccount) {
                if (userAccount.restrictionFilter && userAccount.restrictionQuery) {
                    growl.error("USER_MANAGEMENT.ONLY_ONE_RESTRICTION.ERROR");
                    return false;
                }

                var invitation = {
                    invitationType: "INVITED_COLLEAGUE",
                    invitedEmailAddress: userAccount.invitationEmail,
                    roles: userAccount.roles,
                    restrictionFilter: userAccount.restrictionFilter,
                    restrictionQuery: userAccount.restrictionQuery
                };

                InviteByEmailResource.save(invitation, function() {
                    growl.success("USER_MANAGEMENT.INVITATION_SENT");
                    $scope.newAccount = {};
                    initUsers();
                });
            };

            $scope.createUser = function() {
                var createUserModal = $modal.open({
                    templateUrl: 'tpl/user-create-user.tpl.html',
                    controller: 'CreateUserController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                allRoles: $scope.roles,
                                filters: $scope.filters
                            };
                        }
                    }
                });
                createUserModal.result.then(function(task) {

                }, function() {
                    console.log('Modal dismissed at: ' + new Date());
                }).finally(function() {
                    initUsers();
                });
            };

            $scope.editUser = function(user) {
                var editUserModal = $modal.open({
                    templateUrl: 'tpl/user-management-modal.tpl.html',
                    controller: 'EditUserAccountController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                filters: $scope.filters,
                                userAccount: user,
                                allRoles: $scope.roles,
                                allGroups: $scope.groups
                            };
                        }
                    }
                });
                editUserModal.result.then(function(result) {
                    if (result) {
                        initUsers();
                    }
                });
            };

            $scope.removeUser = function(user) {
                var message = $translate.instant('USER_MANAGEMENT.REMOVE_USER');
                var confirmDialog = $dialogs.confirm('', message);
                confirmDialog.result.then(function() {
                    UsersResource.setActive({
                        accountId: user.id,
                        active: false
                    }, function() {
                        UsersService.setActive(user, false);
                        growl.success("USER_MANAGEMENT.MESSAGE_USER_REMOVED");
                        initUsers();
                    });
                });
            };

            $scope.downloadUsers = function () {
                ExportUsersResource.get({}, function (response) {
                    $timeout(function () {
                        var a = document.createElement('a');
                        a.href = window.URL.createObjectURL(response.blob);
                        a.download = response.filename;
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(a.href);
                    }, 0);
                }, function (error) {
                    console.error(error);
                });
            };

            $scope.removeInvitation = function(pendingInvitation) {
                var header = 'INVITATION.DELETE_INVITATION.LABEL';
                var message = 'INVITATION.DELETE_INVITATION.TEXT';

                var confirmDialog = $dialogs.confirm(header, message);
                confirmDialog.result.then(function() {
                    InviteByEmailResource.delete({
                        invitationId: pendingInvitation.id
                    }, function(response) {
                        growl.success('INVITATION_DELETED');
                        initUsers();
                    });
                });
            };

            $scope.updateInvitation = function(pendingInvitation) {
                InviteByEmailResource.save(pendingInvitation, function(response) {
                    growl.success('INVITATION_UPDATED');
                    initUsers();
                });
            };

            $scope.getLabel = function(restrictionFilter) {
                var filter = _.find($scope.filters, { name: restrictionFilter });
                if (filter) {
                    return filter.label;
                }
            };

            $scope.buyUserLicenses = function() {
                var buyUserLicensesModal = $modal.open({
                    templateUrl: 'tpl/user-buy-licenses.tpl.html',
                    controller: 'BuyUserLicensesController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                            };
                        }
                    }
                });
                buyUserLicensesModal.result.then(function(userLimit) {
                    $scope.licensesUsed.userLimit = userLimit;
                }, function() {
                    console.log('Modal dismissed at: ' + new Date());
                });
            };

            $scope.licensesUsed = {};

            function getRoles() {
                RolesService.getAllRoles().then(function(roles) {
                    $scope.roles = _.map(roles, function(elem) {
                        return {
                            id: elem,
                            text: elem
                        };
                    });
                });
            }

            function getGroups() {
                UsersGroupService.getAll().then(function(groups) {
                        $scope.groups = groups;
                        $scope.isLoading = false;
                },
                function(response) {
                });
            }

            function getFilters() {
                UserResource.filters(function(filters) {
                    $scope.filters = _.map(filters, function(filter) {
                        return {
                            name: filter.name,
                            label: filter.label || filter.name
                        };
                    });
                    $scope.filters.unshift({
                        name: '',
                        label: $translate.instant('MAKE_SELECTION')
                    });
                });
            }

            function getPendingInvitations() {
                return InviteByEmailResource.query({
                    state: 'PENDING',
                    type: 'INVITED_COLLEAGUE'
                },
                function(pendingInvitations) {
                    $scope.pendingInvitations = pendingInvitations;
                }).$promise;
            }

            function initUsers() {
                UsersService.getUsers().subscribe(
                    function (x) {
                        updateUsers();
                    },
                    function (err) {
                        console.log('Error: ' + err);
                    },
                    function () {});
                    updateUsers();
            }

            function updateUsers() {
                $rootScope.users = UsersService.getUsersSnapshot();
                getPendingInvitations().then(function() {
                    updateNumberOfUsers();
                });
            }

            function updateNumberOfUsers() {
                UsageLimitsResource.get(function(response) {
                    activeUsers = _.filter($rootScope.users, function(user) { return user.active && !user.systemAccount && !user.managingAccount; });
                    $scope.licensesUsed = {
                        numberOfUsers: _.size(activeUsers) + _.size($scope.pendingInvitations),
                        userLimit: response.limits.USERS
                    };
                });
            }

            $scope.$on('userAccountUpdated', function(event, userAccount) {
                updateUsers();
            });
            $rootScope.$on('channelMessageReceived', function(event, eventData) {
                if (hopscotch.getCurrTour()) {
                    return;
                }
                if (eventData.event === ChannelService.USERGROUPS_CHANGED) {
                    getRoles();
                    initUsers();
                    getGroups();
                }
            });

            (function init() {
                getFilters();
                getRoles();
                initUsers();
                getGroups();
            })();
    })
    .controller('UserGroupsController',
        function($rootScope, $scope, $location, $translate, UsersService, UsersGroupService, ChannelService) {

            function initGrid() {
                $scope.isLoading = true;
                var columnDefs = [{
                    field: 'groupName',
                    displayName: $translate.instant('USER_GROUP.GROUP_NAME'),
                    headerTooltip: true,
                    cellTooltip: true,
                    cellClass: 'text-center',
                    cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                        '<a data-ng-href="user-group-add?groupId={{row.entity.id}}">\n' +
                        '  <span>{{COL_FIELD}}</span>\n' +
                        '</a>\n' +
                        '</div>'
                }, {
                    field: 'userIds',
                    displayName: $translate.instant('USER_GROUP.USER_COUNT'),
                    headerTooltip: true,
                    cellTooltip: true,
                    cellClass: 'text-center',
                    cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                        '  <span>{{COL_FIELD.length}}</span>\n' +
                        '</div>'
                }, {
                    field: 'roles',
                    displayName:$translate.instant('USER_MANAGEMENT.ROLES'),
                    headerTooltip: true,
                    cellTooltip: true,
                    cellClass: 'text-center',
                    cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                        '  <span class="badge badge-rectangle ml" data-ng-repeat="role in COL_FIELD">{{role}}</span>\n' +
                        '</div>'
                }];

                $scope.gridOptions = {
                    data: 'groups',
                    enableColumnMenus: false,
                    columnDefs: columnDefs
                };
                UsersGroupService.getAll().then(function(groups) {
                    $scope.groups = groups;
                    $scope.isLoading = false;
                },
                function(response) {
                });
            }

            $scope.createGroup = function() {
                $location.path('user-group-add');
            };

            $rootScope.$on('channelMessageReceived', function(event, eventData) {
                if (hopscotch.getCurrTour()) {
                    return;
                }
                if (eventData.event === ChannelService.USERGROUPS_CHANGED) {
                    initGrid();
                }
            });
            (function init() {
                initGrid();
            })();
    })
    .controller('AddUserGroupController',
        function($rootScope, $scope, $location, $dialogs, $modal, UsersGroupService, growl, UsersService) {

            $scope.group = {};
            $scope.group.userIds = [];
            $scope.group.roles = [];
            $scope.users = [];
            $scope.roles = [];
            $scope.editMode = false;
            $scope.groupCopy = {};
            var params = $location.search();
            $scope.isGroupChanged = true;

            if (!_.isEmpty(params)) {
                UsersGroupService.getAll().then(function(groups) {
                    if (_.isArray(groups)) {
                        var group = _.find(groups, function(g) {
                            return g.id == params.groupId;
                        });
                        if (!_.isNil(group)) {
                            UsersService.getUsers().subscribe(function (x) {
                                var users = UsersService.getUsersSnapshot();
                                $scope.group.roles = group.roles || [];
                                $scope.groupCopy = angular.copy(group);
                                $scope.groupCopy.roles = $scope.groupCopy.roles || [];
                                $scope.users = _.filter(users, function(p) {
                                    return _.includes(group.userIds, p.id);
                                });
                                $scope.group.id = group.id;
                                $scope.group.groupName = group.groupName;
                                $scope.editMode = true;
                            });
                        }
                    }
                });
            }
            $scope.addMember = function() {
                var memberModal = $modal.open({
                    templateUrl: 'tpl/add-group-member.tpl.html',
                    controller: 'AddMemberController',
                    backdrop: true,
                    windowClass: 'group-editor',
                    resolve: {
                        addedUsers: function() {
                            return $scope.users;
                        }
                    }
                });
                memberModal.result.then(function(members) {
                    if (members && _.isArray(members)) {
                        _.forEach(members, function(user, key) {
                            $scope.users.push(user);
                        });
                        $scope.detectChanges();
                    }
                }, function() {
                }).finally(function() {
                });
            };

            $scope.addrole = function() {
                var roleModal = $modal.open({
                    templateUrl: 'tpl/add-group-role.tpl.html',
                    controller: 'AddRoleController',
                    backdrop: true,
                    resolve: {
                        addedRoles: function() {
                            return angular.copy($scope.group.roles);
                        }
                    }
                });
                roleModal.result.then(function(roles) {
                    if (roles && _.isArray(roles)) {
                        _.forEach(roles, function(role, key) {
                            $scope.group.roles.push(role.id);
                        });
                        $scope.detectChanges();
                    }
                }, function() {
                }).finally(function() {
                });
            };

            $scope.removeUser = function(user) {
                var idx = $scope.users.indexOf(user);
                $scope.users.splice(idx,1);
                $scope.detectChanges();
            };

            $scope.removeRole = function(role) {
                var idx = $scope.group.roles.indexOf(role);
                $scope.group.roles.splice(idx,1);
                $scope.detectChanges();
            };

            $scope.saveGroup = function() {
                $scope.group.userIds = $scope.users.map(function(user) {
                    return user.id.toString();
                });
                $scope.group.organizationId = $rootScope.organization.id;

                if (!$scope.editMode) {
                    UsersGroupService.create($scope.group)
                    .then(function(response) {
                            growl.success("USER_GROUP.ADD_SUCCESS");
                            $location.path('user-groups').search('');
                        },
                        function(errResponse) {
                            showErrorMessage(errResponse);
                    });
                } else {
                    UsersGroupService.update($scope.group)
                    .then(function(response) {
                            growl.success("USER_GROUP.UPDATE_SUCCESS");
                            $location.path('user-groups').search('');
                        },
                        function(errResponse) {
                            showErrorMessage(errResponse);
                    });
                }
                function showErrorMessage(errResponse) {
                    var error = _.get(errResponse,'data.key') || "SAVE_ERROR_MESSAGE";
                    var val = _.get(errResponse,'data.data[0]') || "";
                    growl.error(error, { variables: { value: val } });
                }
            };

            $scope.goBack = function() {

                if (!$scope.isGroupChanged && $scope.editMode) {
                    var confirmDialog = $dialogs.confirm('MODAL.CONFIRM_HEADER', 'MODAL.DISMISS_WITH_UNSAVED_CHANGES');
                    confirmDialog.result.then(function() {
                        $location.path('user-groups').search('');
                    }).finally(function() {

                    });
                } else {
                    $location.path('user-groups').search('');
                }
            };

            $scope.deleteGroup = function() {
                var confirmDialog = $dialogs.confirm('CONFIRM_DELETE', 'USER_GROUP.REMOVE_GROUPS');
                confirmDialog.result.then(function() {
                    UsersGroupService.delete({ id: $scope.group.id })
                    .then(function(response) {
                        UsersGroupService.reloadUsersAndGroups();
                        growl.success("USER_GROUP.DELETE_SUCCESS");
                        $location.path('user-groups').search('');
                    },
                    function(errResponse) {
                    });
                }).finally(function() {

                });
            };

            function CompareArraysByProperty(a1,a2,param) {
                var isEqual = true;
                if (_.isArray(a1) && _.isArray(a2) && (a1.length == a2.length) && _.isString(param)) {
                    _.forEach(a1, function(el, key) {
                        var a2El = _.find(a2, function(o) { return o[param] == el[param]; });
                        if (!a2El) {
                            isEqual = false;
                        }
                    });
                } else {
                    isEqual = false;
                }
                return isEqual;
            }
            $scope.detectChanges = function() {
                if (!_.isEmpty($scope.groupCopy) && $scope.editMode) {
                    $scope.isGroupChanged = CompareArraysByProperty($scope.groupCopy.users, $scope.users, 'id') &&
                                            ($scope.groupCopy.roles.sort().toString() == $scope.group.roles.sort().toString()) &&
                                            ($scope.groupCopy.groupName === $scope.group.groupName);
                }
            };
    })
    .controller('AddMemberController',
        function($rootScope, $scope, addedUsers, UsersService, $modalInstance) {
            $scope.users = [];
            var users = UsersService.getUsersSnapshot();
            if (_.isNil(users)) {
                UsersService.getUsers().subscribe(
                    function (x) {
                        updateUsers();
                    });
            } else {
                updateUsers();
            }

            function updateUsers() {
                $scope.users = UsersService.getUsersSnapshot() || [];
                _.forEach($scope.users, function(user) {
                    var existUser = _.find(addedUsers, function(u) { return u.id == user.id; });
                    if (!_.isEmpty(existUser)) {
                        user.checked = true;
                        user.disable = true;
                    }
                    user.fullName = user.fullName || user.userId || '';
                });
                $scope.users = _.sortBy($scope.users, function(user) {
                    return user.fullName.toLowerCase();
                });
            }
            $scope.addUsers = function() {
                var users = $scope.users.filter(function(user) {
                    return user.checked && !user.disable;
                });
                $modalInstance.close(users);
            };

            $scope.cancel = function() {
                $modalInstance.close(null);
            };
    })
    .controller('AddRoleController',
        function($rootScope, $scope, addedRoles, RolesService, $modalInstance) {
            RolesService.getAllRoles().then(function(roles) {
                $scope.roles = _.map(roles, function(elem) {
                    var exists = _.isArray(addedRoles) && addedRoles.indexOf(elem) > -1;
                    return {
                        id: elem,
                        text: elem,
                        disable: exists,
                        checked: exists
                    };
                });
                $scope.roles = _.sortBy($scope.roles, 'text');
            });
            $scope.addRoles = function() {
                var roles = $scope.roles.filter(function(role) {
                    return role.checked && !role.disable;
                });
                $modalInstance.close(roles);
            };

            $scope.cancel = function() {
                $modalInstance.close(null);
            };
    });
