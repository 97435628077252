angular.module('llax.services')
    .service('ExportMappingService', function($log, $parse, $rootScope, growl) {

        this.getUploadFileForItemConfiguration = function($scope) {
            var _this = this;
            return function(attributeName, sectionName, type, model) {
                var config = {};
                switch (type) {
                    case "Image":
                        config = {
                            autoUpload: true,
                            filters: ['imageFilter'],
                            accept: 'image/jpeg,image/gif,image/png,image/svg+xml,image/bmp,image/tiff,image/webp,application/pdf'
                        };
                        var maxFileSize = $rootScope.dataModel.sectionAttributeParam($rootScope.currentLayout, sectionName, attributeName, 'maxFileSize');
                        if (maxFileSize) {
                            config.filters.push('maxFileSizeFilter');
                            config.maxFileSize = maxFileSize;
                        }
                        break;
                    case "Document":
                        config = {
                            autoUpload: true
                        };
                        break;
                }

                model = _this.checkModel(model, attributeName);

                return angular.extend({
                    url: lax_rest_url('assets'),
                    reset: true,
                    formData: [],
                    uploadComplete: function(response) {
                        var modelEval = $parse(model);
                        if (_.isArray(response) && response.length > 0) {
                            modelEval.assign($scope, response[0].url);
                        } else if (response.hasOwnProperty('privateAssetUrl')) {
                            modelEval.assign($scope, response.privateAssetUrl);
                        }
                    },
                    onErrorItem: function(item, response, status, headers) {
                        $log.error(response);

                        if (!_.isNil(response.errorCode)) {
                            growl.error(response.message, { variables : { name: item.file.name } });
                        } else {
                            growl.error("ASSET_FOLDER.ERROR_OCCURRED");
                        }
                    },
                    onFileSelected: function(linkedAsset, linkedAssetPath, done) {
                        done(linkedAsset);
                    }
                }, config);
            };
        };

        this.checkModel = function(model, attributeName) {
            if (_.isEqual("customValues[a.name]", model)) {
                model = "customValues['" + attributeName + "']";
            } else if (!_.includes(model, "'")) {
                model = model.replace(/\[/g, "['");
                model = model.replace(/\]/g, "']");
                model = model.replace(/\'\d+\'/g, function (x) {
                    return x.replace(/\'/g, "");
                });
            }
            return model;
        };
    });