angular.module('llax')
.controller('SessionsController',
    function ($controller, $location, $log, $modal, $rootScope, $scope, $timeout, growl,
        Auth, LoginResource, ResendMailResource, ResetPasswordResource, StatusResource,
        UserPreferencesResource) {

    $controller('NavigationController', {$scope: $scope});

    var MAX_LOGIN_RETRIES = 10;
    var inRevalidationMode = $location.path() === '/revalidateAccount';

    function redirectToStartPage() {
        $location.path('/');
    }

    function redirectToDeeplink(link) {
        $location.path(link);
    }

    $scope.goToRegistration = function(invitationReference, email, organizationName) {
        $location.path('/register');
    };

    var ExecuteResetPassword = /*@ngInject*/ function($scope, $modalInstance, data) {

        $scope.resetPasswordData = {
            email: data.email
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.resetPassword = function(resetPasswordData) {
            $scope.emailSent = false;

            if (!$scope.resetPasswordPending) {

                $scope.resetPasswordPending = true;

                ResetPasswordResource.save({}, resetPasswordData,
                    function(resetPasswordResponse) {
                        $('button#reset-password-submit').attr('data-reset-password', resetPasswordResponse.id);
                        $scope.emailSent = true;
                        $scope.resetPasswordPending = false;
                        $scope.status = undefined;
                        $scope.resetPasswordData = {};
                    },
                    function(response) {
                        //error callback
                        $scope.resetPasswordPending = false;
                        $scope.status = response.status;
                    });
            }
        };
    };

    $scope.openResetPasswordModal = function(email) {
        $modal.open({
            templateUrl: 'tpl/reset-password.tpl.html',
            controller: ExecuteResetPassword,
            resolve: {
                data: function() {
                    return {
                        email: email
                    };
                }
            }
        });
    };

    $scope.resendConfirmationMail = function(email) {
        $modal.open({
            templateUrl: 'tpl/resend-confirmation-mail.tpl.html',
            controller: ExecuteResendMail,
            resolve: {
                data: function() {
                    return {
                        email: email
                    };
                }
            }
        });
    };

    var ExecuteResendMail = function($scope, $modalInstance, data) {

        $scope.resendMailData = {
            email: data.email
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.resendMail = function(resendMailData) {
            $scope.emailSent = false;
            if (!$scope.resendMailPending) {
                $scope.resendMailPending = true;

                ResendMailResource.save(resendMailData,
                    function(resendMailResponse) {
                        $scope.emailSent = true;
                        $('button#resend-mail-submit').attr('data-resend-confirmation-mail', resendMailResponse.id);
                        $scope.resendMailPending = false;
                        $scope.resendMailData = {};
                        $scope.resendMailData.email = null;
                    },
                    function(response) {
                        //error callback
                        $scope.resendMailPending = false;
                    });
            }
        };
    };

    $scope.loginClicked = false;
    $scope.login = function(login, password) {

        $scope.loginClicked = true;
        $scope.error = null;

        $log.debug("logging in as:", login);

        var userdata = {
            'login': login,
            'password': password
        };
        LoginResource.save({}, userdata,
            function(loginResponse) {
                $log.debug("loginResponse:", loginResponse);
                retryLogin(loginResponse, 0);
                UserPreferencesResource.get({}, function(response) {
                    response = response.toJSON();
                    angular.forEach(response, function(value, key) {
                        localStorage.setItem(key, angular.toJson(value));
                    });
                });
            },
            function(errorResponse) {
                $log.debug("login errorResponse:", errorResponse);
                $scope.error = {
                    status: errorResponse.status || 0,
                    message: (errorResponse.data ? (errorResponse.data.message || errorResponse.data.data || errorResponse.statusText) : 'LOGIN_REQUEST_TIMEOUT')
                };
                $scope.loginClicked = false;
            }
        );
    };

    function retryLogin(loginResponse, retryCount) {

        if (retryCount > 0) {
            $log.info("retryLogin: #" + retryCount);
        }

        StatusResource.get({},
            function(statusResponse) {
                doLogin(loginResponse);
            },
            function(statusErrorResponse) {

                if (retryCount >= MAX_LOGIN_RETRIES) {
                    $scope.loginClicked = false;
                    $scope.error = {
                        status: 0,
                        message: 'LOGIN_REQUEST_TIMEOUT'
                    };
                    return;
                }

                $timeout(function() {
                    retryLogin(loginResponse, retryCount + 1);
                }, 3000);

            }
        );

    }

    function doLogin(loginResponse) {

        $scope.error = null;

        $rootScope.userId = loginResponse.id;
        $rootScope.$broadcast('updateUserData');
        $rootScope.initOrganization()
            .then(function() {
                if ($rootScope.deeplink) {
                    redirectToDeeplink($rootScope.deeplink);
                } else {
                    redirectToStartPage();
                    if (inRevalidationMode === true) {
                        growl.success("REVALIDATION.SUCCESSFUL");
                    }
                }

                $scope.loginClicked = false;
            });

    }

    if (Auth.isLoggedIn) {
        $log.info("Already logged in");
        redirectToStartPage();
        return;
    }

    StatusResource.get({},
        function(statusResponse, headers) {
            // When 'get' returns successfully we are already logged in
            $log.info("Redirecting to start page");
            redirectToStartPage();
        }
    );

});
