angular.module('llax')
.controller('JsonEditorController',
    function($scope, $modalInstance, jsonEditorData) {

        $scope.config = jsonEditorData.config || {};
        $scope.data = jsonEditorData;
        var configKey = _.get($scope.config,'key');
        var canEditConfigKey = _.get($scope.config,'editKey');
        if (configKey) {
            $scope.json = jsonEditorData.json[configKey] || jsonEditorData.json;
        } else {
            $scope.json = jsonEditorData.json;
        }

        $scope.allTypes = {
            "s" : "string",
            "o" : "object",
            'a' : "array"
        };
        $scope.allActions = {
            "a" : "append",
            "i" : "insert",
            'r' : "remove",
            'l' : "load"
        };

        $scope.close = function() {
            $modalInstance.close();
        };

        $scope.save = function() {
            $scope.saveJson();
            $modalInstance.close(jsonEditorData.json);
        };

        $scope.saveJson = function() {
            var data_i = $scope.jsonData[0];
            var data_o = getEleVal(data_i);
            if (configKey && canEditConfigKey && data_o) {
                delete jsonEditorData.json[configKey];
                jsonEditorData.json[data_i.key] = data_o;
            } else if (configKey && data_o) {
                jsonEditorData.json[configKey] = data_o;
            }  else {
                jsonEditorData.json = data_o;
            }
        };

        function getEleVal(element) {
            var output;
            if (element.type == $scope.allTypes.a) {
                output = [];
            } else if (element.type == $scope.allTypes.o) {
                output = {};
            } else if (element.type == $scope.allTypes.s) {
                return element.value;
            }
            _.forEach(element.elements, function(e) {
                if (element.type == $scope.allTypes.a) {
                    output.push(getEleVal(e));
                } else if (element.type == $scope.allTypes.o) {
                    output[e.key] = getEleVal(e);
                }
            });
            return output;
        }

        function prepareElement(obj) {
            if (!_.isNil(obj)) {
                obj.show = true;
                obj.allowedTypes = _.values($scope.allTypes);
                obj.allowedActions = _.values($scope.allActions);
            }
        }

        var level_0 = {
            type : getType($scope.json),
            expanded : true,
            level : 0,
            show : true,
            allowedActions : [$scope.allActions.i],
            allowedTypes : [$scope.allTypes.o]
        };

        if (configKey && canEditConfigKey) {
            level_0.canEditRow = true;
            level_0.canEditKey = true;
        }
        addRestrictions($scope.allActions.l, level_0, $scope.json);
        level_0.key = configKey || level_0.type;
        var elements = [level_0];
        elements[0].elements = prepareElements($scope.json, null, elements[0]);
        prepareOperations(level_0);

        function getType(ob) {
            if (_.isArray(ob)) {
                return $scope.allTypes.a;
            } if (_.isPlainObject(ob)) {
                return $scope.allTypes.o;
            } if (_.isString(ob)) {
                return $scope.allTypes.s;
            } else {
                return $scope.allTypes.a;
            }
        }

        function prepareElements(obj,l,parent) {
            l = l || 1;
            var innerElements = [];
            var isParentArray = _.isArray(obj);

            _.forEach(obj, function(j,i) {
                var element = {
                    expanded : true,
                    level : l,
                    canEditKey : true,
                    canEditRow : true,
                    key : i,
                    isParentArray : isParentArray
                };

                prepareElement(element);
                var prepareNext = false;

                if (parent) {
                    element.parent = parent;
                }

                if (_.isArray(j)) {
                    element.type = $scope.allTypes.a;
                    prepareNext = true;
                } else if (_.isPlainObject(j)) {
                    element.type = $scope.allTypes.o;
                    prepareNext = true;
                } else {
                    element.type = $scope.allTypes.s;
                    element.value = j;
                    prepareNext = false;
                }
                if (addRestrictions($scope.allActions.l, element, j)) {
                    return;
                }

                innerElements.push(element);
                elements.push(element);

                if (prepareNext) {
                    var preparedElements = prepareElements(j, l+1, element);
                    if (!_.isUndefined(preparedElements)) {
                        element.elements = preparedElements;
                    } else {
                        var rIdx1 = elements.indexOf(element);
                        elements.splice(rIdx1,1);
                        var rIdx2 = innerElements.indexOf(element);
                        innerElements.splice(rIdx2,1);
                    }
                }
                prepareOperations(element);
            });
            return innerElements;
        }

        $scope.jsonData = elements;
        $scope.getElementValue = function(t) {
            var length = t.elements ? t.elements.length : '0';
            if (t.type == $scope.allTypes.a) {
                return '[' + length +']';
            } else if (t.type == $scope.allTypes.o) {
                return '{' + length +'}';
            } else {
                return t.value;
            }
        };
        $scope.toggleJson = function(el) {
            el.expanded = !el.expanded;
            toggleRows(el.elements,el.expanded);
        };
        function toggleRows(els,flag) {
            _.forEach(els, function(elm) {
                elm.show = flag;
                if (!_.isUndefined(elm.elements) && elm.expanded) {
                    toggleRows(elm.elements,flag);
                }
            });
        }
        $scope.changeType = function(el,type) {
            el.expanded = false;
            el.type = type;
            if (type != $scope.allTypes.s) {
                removeElementsFromMain(el.elements);
                el.elements = [];
                var defaultItem = getDefaultItem(el,el.level+1);
                defaultItem.parent = el;
                if (type == $scope.allTypes.a) {
                    defaultItem.key = 0;
                    defaultItem.isParentArray = true;
                }
                if (el.level != 0) {
                    var insertIdx = $scope.jsonData.indexOf(el) + 1;
                    $scope.jsonData.splice(insertIdx, 0, defaultItem);
                } else {
                    el.key = el.type;
                }
                el.elements.push(defaultItem);
                el.expanded = true;
            } else {
                el.insertOperation = el.value ? false : true;
                removeElementsFromMain(el.elements);
                delete el.elements;
            }
            prepareOperations(el);
        };

        function rearrangIndex(els) {
            _.forEach(els, function(el,i) {
                el.key = i;
            });
        }

        $scope.append = function(e) {
            var defaultItem = getDefaultItem(e,e.level);
            defaultItem.parent = e.parent;
            var appendIdx;
            if (_.isEmpty(e.elements)) {
                appendIdx = $scope.jsonData.indexOf(e) + 1;
            } else {
                appendIdx = getIndexRecursive(e.elements);
            }

            $scope.jsonData.splice(appendIdx, 0, defaultItem);
            e.parent.elements.splice(e.key + 1, 0, defaultItem);
            if (defaultItem.parent && defaultItem.parent.type == $scope.allTypes.a) {
                var cidx = e.parent.elements.indexOf(e);
                defaultItem.key = cidx + 1;
                defaultItem.isParentArray = true;
                rearrangIndex(e.parent.elements);
            }
            addRestrictions($scope.allActions.a,defaultItem);
            prepareOperations(defaultItem);
        };
        $scope.insert = function(e, defaultValues) {
            var defaultItem = getDefaultItem(e,e.level+1);
            angular.extend(defaultItem,defaultValues);
            defaultItem.parent = e;
            e.elements = e.elements || [];
            if (!e.expanded) {
                $scope.toggleJson(e);
            }
            var insertIdx;
            if (_.isEmpty(e.elements)) {
                insertIdx = $scope.jsonData.indexOf(e) + 1;
            } else {
                insertIdx = getIndexRecursive(e.elements);
            }
            if (e.level == 0 && insertIdx == 0) {
                insertIdx = 1;
                removeElementsFromMain(e.elements);
                e.elements = [];
            }
            if (e.type == $scope.allTypes.a) {
                defaultItem.key = e.elements.length;
                defaultItem.isParentArray = true;
            }
            $scope.jsonData.splice(insertIdx, 0, defaultItem);
            e.elements.push(defaultItem);
            addRestrictions($scope.allActions.i,defaultItem);
            prepareOperations(defaultItem);
        };
        function getIndexRecursive(els) {
            var lastEle = els[els.length -1];
            if (lastEle && lastEle.elements) {
                return getIndexRecursive(lastEle.elements);
            } else {
                var idx = $scope.jsonData.indexOf(lastEle);
                return idx > -1 ? idx + 1 : 0;
            }
        }
        $scope.remove = function(e) {
            removeElementsFromMain(e.elements);
            var idx = $scope.jsonData.indexOf(e);
            $scope.jsonData.splice(idx,1);
            var idx1 = e.parent.elements.indexOf(e);
            e.parent.elements.splice(idx1,1);
            if (e.isParentArray) {
                rearrangIndex(e.parent.elements);
            }
            if (e.parent.elements.length == 0) {
                e.parent.expanded = false;
            }
        };
        function removeElementsFromMain(els) {
            _.forEach(els, function(el) {
                if (!_.isEmpty(el.elements)) {
                    removeElementsFromMain(el.elements);
                }
                var idx = $scope.jsonData.indexOf(el);
                if (idx > -1) {
                    $scope.jsonData.splice(idx,1);
                }
            });
        }
        $scope.checkDuplicateKey = function(el) {
            if (!el.parent) {
                return;
            }
            var noDup = _.every(el.parent.elements, function(e) {
                return e.key != el.newKey;
            });
            el.hasKeyError = !noDup;
        };

        $scope.isJsonInEditMode = function() {
            return !_.every($scope.jsonData, function(d) {
                return !(d.insertOperation || d.editRow);
            });
        };

        function prepareOperations(e) {
            if (!_.isEmpty(e.allowedActions) || !_.isEmpty(e.allowedTypes)) {
                e.hideActions = _.isEmpty(e.allowedActions);
                e.hideTypes = _.isEmpty(e.allowedTypes) || (e.type == e.allowedTypes);
                e.hideMenuBtn = e.hideActions && e.hideTypes;
            } else {
                e.hideMenuBtn = true;
            }
        }
        function getDefaultItem(el,l) {
            var de = {
                type : "string",
                level : l,
                canEditKey : true,
                canEditRow : true,
                insertOperation : true
            };
            prepareElement(de);
            return de;
        }

        function addRestrictions(restriction_on, element, object) {
            if (_.isEmpty(restriction_on) || _.isEmpty(element) || !_.isPlainObject(element) || _.isEmpty($scope.config.levelRestrictions)) {
                return false;
            }
            var l = element.level;
            var levelLimit = _.get($scope.config,'levelRestrictions.levelLimit');
            if (levelLimit && l > (levelLimit+1)) {
                return true;
            }

            var level_type = _.get($scope.config,'levelRestrictions.types.'+ l +'.type');
            var level_keys_restriction = _.get($scope.config,'levelRestrictions.types.'+ l +'.keys');
            var parent_level_keys_restriction = _.get($scope.config,'levelRestrictions.types.'+ (l-1) +'.keys');

            if (restriction_on == $scope.allActions.l) {
                if (level_type) {
                    element.allowedTypes = [level_type];
                }
                if (level_type && element.type !=level_type) {
                    element.type = level_type;
                    return true;
                }
                if (levelLimit && l > levelLimit) {
                    element.allowedTypes = [];
                }
                if (!_.isEmpty(parent_level_keys_restriction)) {
                    element.allowedActions = [];
                    element.canEditKey = false;
                }
                if (!_.isEmpty(level_keys_restriction)) {
                    element.allowedActions = [$scope.allActions.r,$scope.allActions.a];
                    _.forEach(object, function(val,key) {
                        if (level_keys_restriction.indexOf(key) > -1) {
                            object[key] = val || '';
                        } else {
                            delete object[key];
                        }
                    });
                }
            } else {
                if (level_type) {
                    element.allowedTypes = [level_type];
                    if (level_type != $scope.allTypes.s) {
                        element.type = level_type;
                        element.insertOperation = false;
                        element.expanded = true;
                        if (!_.isEmpty(level_keys_restriction)) {
                            element.allowedActions = [$scope.allActions.r,$scope.allActions.a];
                            _.forEach(level_keys_restriction, function(k) {
                                $scope.insert(element, {
                                    key : k,
                                    canEditKey : false,
                                    allowedActions : []
                                });
                            });
                        } else {
                            $scope.insert(element);
                        }
                    }
                }
                if (levelLimit && l >= levelLimit) {
                    element.allowedTypes = [];
                }
            }
        }

    }
);
