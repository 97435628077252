angular.module('llax')
    .controller('DashboardController',
        function($dialogs, $location, $modal, $rootScope, $scope, $translate, $window,
            ValidationsOverviewFactory,WarningsOverviewFactory, growl,
            RubyOutputDashletFactory, LatestItemsDashletFactory, DashletRegistry, CustomDashletRegistry,
            CategoriesOverviewFactory, CategoriesListFactory, InputTemplatesService, DashboardResource,
            DocumentationDashletFactory, NewsDashletFactory, ComplianceStatisticsFactory, DashboardService, $timeout, MyTasksFactory,
            PublicationsByDestinationsFactory, ReviewErrorsOverviewFactory, ReviewsFactory, ReviewWarningsOverviewFactory) {

            $rootScope.loadDataModel();
            DashboardService.loadSettings({}, function(res) {
                InputTemplatesService.loadTemplates(initDashlets);
            });

            function initDashlets(hasSettingsChanged) {

                CustomDashletRegistry.load();

                DashletRegistry.addAll([{
                    name: 'tasks',
                    imageUrl: 'images/dashlets/my-tasks-overview.png',
                    title: 'LABEL.MY_TASKS',
                    description: $translate.instant('DESCRIPTION.MY_TASKS'),
                    directive: 'wt-my-tasks',
                    dataModelType: MyTasksFactory.create({
                        user: $rootScope.user
                    })
                },{
                    name: 'publicationsByDestination',
                    imageUrl: 'images/dashlets/publications-overview.png',
                    title: 'WIDGET.PUBLICATIONS.TITLE',
                    description: 'WIDGET.PUBLICATIONS.DESCRIPTION',
                    directive: 'grid-widget',
                    dataModelType: PublicationsByDestinationsFactory.create({
                        user: $rootScope.user
                    }),
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                }, {
                    name: 'reviews',
                    imageUrl: 'images/dashlets/reviews-overview.png',
                    title: 'WIDGET.REVIEWS.TITLE',
                    description: 'WIDGET.REVIEWS.DESCRIPTION',
                    directive: 'grid-widget',
                    dataModelType: ReviewsFactory.create({
                        user: $rootScope.user
                    }),
                    size: {
                        width: '100%',
                        height: '280px'
                    },
                }, {
                    name: 'complianceStatistics',
                    imageUrl: "images/dashlets/compliance-overview.png",
                    title: 'WIDGET.COMPLIANCESTATISTICS',
                    description: "WIDGET.COMPLIANCESTATISTICS.DESCRIPTION",
                    directive: 'wt-compliance-statistics',
                    dataAttrName: 'value',
                    dataModelType: ComplianceStatisticsFactory.create({
                        statisticName: '__compliance_statistic'
                    }),
                    size: {
                        width: '100%',
                        height: '475px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'validationsOverview',
                    imageUrl: "images/dashlets/validations-overview.png",
                    title: "WIDGET.VALIDATIONSOVERVIEW",
                    description: "WIDGET.VALIDATIONSOVERVIEW.DESCRIPTION",
                    directive: 'wt-generic-list-view',
                    dataAttrName: 'value',
                    dataModelType: ValidationsOverviewFactory.create({
                        statisticName: '__validation_overview'
                    }),
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'warningsOverview',
                    imageUrl: "images/dashlets/warnings-overview.png",
                    title: 'WIDGET.WARNINGSOVERVIEW',
                    description: "WIDGET.WARNINGSOVERVIEW.DESCRIPTION",
                    directive: 'wt-generic-list-view',
                    dataAttrName: 'value',
                    dataModelType: WarningsOverviewFactory.create({
                        statisticName: '__validation_warnings'
                    }),
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                },  {
                    name: 'reviewErrorsOverview',
                    imageUrl: "images/dashlets/validations-overview.png",
                    title: 'WIDGET.REVIEWERRORSOVERVIEW',
                    description: "WIDGET.REVIEWERRORSOVERVIEW.DESCRIPTION",
                    directive: 'wt-generic-list-view',
                    dataAttrName: 'value',
                    dataModelType: ReviewErrorsOverviewFactory.create({
                        statisticName: '__review_errors'
                    }),
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'reviewWarningsOverview',
                    imageUrl: "images/dashlets/warnings-overview.png",
                    title: 'WIDGET.REVIEWWARNINGSOVERVIEW',
                    description: "WIDGET.REVIEWWARNINGSOVERVIEW.DESCRIPTION",
                    directive: 'wt-generic-list-view',
                    dataAttrName: 'value',
                    dataModelType: ReviewWarningsOverviewFactory.create({
                        statisticName: '__review_warnings'
                    }),
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'categoriesOverview',
                    imageUrl: "images/dashlets/categories-overview.png",
                    title: 'WIDGET.CATEGORIESOVERVIEW',
                    description: "WIDGET.CATEGORIESOVERVIEW.DESCRIPTION",
                    directive: 'wt-pie-chart',
                    dataAttrName: 'value',
                    dataModelType: CategoriesOverviewFactory.create(),
                    size: {
                        width: '100%',
                        height: '320px'
                    }
                }, {
                    name: 'categoriesList',
                    imageUrl: "images/dashlets/categories-list.png",
                    title: 'WIDGET.CATEGORIESLIST',
                    description: "WIDGET.CATEGORIESLIST.DESCRIPTION",
                    directive: 'wt-generic-list-view',
                    dataAttrName: 'value',
                    dataModelType: CategoriesListFactory.create(),
                    size: {
                        width: '100%',
                        height: '320px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'latestItemsWidget',
                    title: 'WIDGET.LASTITEMS',
                    imageUrl: "images/dashlets/latest-items.png",
                    description: "WIDGET.LASTITEMS.DESCRIPTION",
                    directive: 'wt-latest-items',
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    dataAttrName: 'value',
                    dataModelType: LatestItemsDashletFactory.create(),
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'news',
                    imageUrl: "images/dashlets/news.png",
                    title: 'WIDGET.NEWS.WIDGETTITLE',
                    attrs: {
                            selectedUrl: $rootScope.translate("DASHLET.NEWS.URL.0"),
                            selectedIndex:0
                    },
                    description: "WIDGET.NEWS.DESCRIPTION",
                    directive: 'wt-news',
                    dataAttrName: 'value',
                    dataModelType: NewsDashletFactory,
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    controller: 'WidgetSpecificSettingsCtrl',
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/news-widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'whatsNext',
                    imageUrl: "images/dashlets/whats-next.png",
                    title: 'WIDGET.DOCUMENTATION.TITLE',
                    description: "WIDGET.DOCUMENTATION.DESCRIPTION",
                    directive: 'wt-documentation',
                    dataAttrName: 'value',
                    dataModelType: DocumentationDashletFactory,
                    size: {
                        width: '100%',
                        height: '280px'
                    },
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }, {
                    name: 'rubyOutputWidget',
                    title: 'WIDGET.RUBYOUTPUT',
                    imageUrl: "images/dashlets/ruby-widget.png",
                    directive: 'wt-ruby-output',
                    description: "WIDGET.RUBYOUTPUT.DESCRIPTION",
                    size: {
                        width: '50%',
                        height: '280px'
                    },
                    dataAttrName: 'value',
                    localOnly: true,
                    dataModelType: RubyOutputDashletFactory,
                    settingsModalOptions: {
                        templateUrl: 'tpl/dashlets/ruby-widget-settings.tpl.html',
                        controller: 'WidgetSpecificSettingsCtrl',
                        backdrop: false
                    }
                }]);

                var defaultDashlets = _.invoke(CustomDashletRegistry,'getDefaultDashlets');
                if (!_.isNil(defaultDashlets) && DashletRegistry.getDefaultDashlets().length === 0) {
                    DashletRegistry.addToDefaultDashlets(defaultDashlets);
                }

                if (DashletRegistry.getDefaultDashlets().length === 0) {
                    DashletRegistry.addToDefaultDashlets([{
                        name: 'complianceStatistics'
                    }, {
                        name: 'validationsOverview'
                    }, {
                        name: 'warningsOverview'
                    }, {
                        name: 'whatsNext'
                    }
                    ]);
                }
                if (hasSettingsChanged && $scope.dashboardOptions.storage) {
                    var storage = $scope.dashboardOptions.storage[$scope.dashboardOptions.storageId];
                    try {
                        storage = JSON.parse(storage);
                    } catch(e) {
                        storage = null;
                    }
                    if (storage && _.isArray(storage.widgets)) {
                        var dashlets = DashletRegistry.getDashlets();
                        var hasChanges;
                        _.forEach(storage.widgets, function(widget) {
                            var widgetDefinition = _.find(dashlets, {name: widget.name});
                            $scope.dashboardOptions.addWidget(widgetDefinition);
                            for(var key in widget) {
                                if (widgetDefinition.hasOwnProperty(key) && widget[key] != widgetDefinition[key]) {
                                    widget[key] = widgetDefinition[key];
                                    hasChanges = true;
                                }
                            }
                        });
                        if (hasChanges) {
                            $scope.dashboardOptions.storage.setItem( $scope.dashboardOptions.storageId, JSON.stringify(storage) );
                        }
                    }
                    $scope.dashboardOptions = null;
                    $timeout(function() {
                        setDashboardOptions();
                    });
                } else {
                    setDashboardOptions();
                }
            }

            function setDashboardOptions() {

                var customStorage = {
                    getItem: function (key) {
                        return $window.localStorage.getItem(key);
                    },

                    setItem: function (key, value) {
                        $window.localStorage.setItem(key, value);

                        var widgetsParsed = JSON.parse(value);
                        var updatedWidgets = widgetsParsed.widgets;
                        updatedWidgets.push({ 'dashboardConfigurationsMigrated': true });
                        saveDashboardConfig(updatedWidgets);
                    },

                    removeItem: function (key) {
                        $window.localStorage.removeItem(key);
                    }
                };

                $scope.dashboardOptions = {
                    widgetButtons: false,
                    hideToolbar: true,
                    widgetDefinitions: DashletRegistry.getDashlets(),
                    defaultWidgets: DashletRegistry.getDefaultDashlets(),
                    storage: customStorage,
                    storageId: $rootScope.user.userId,
                    hideWidgetName: true
                };

                DashboardResource.get({}, function (response) {

                    $scope.dashboardConfiguration = response;
                    var dashboardMigration = false;
                    if (!_.isEmpty($scope.dashboardConfiguration)) {
                        var migratedObject = $scope.dashboardConfiguration[$scope.dashboardConfiguration.length - 1];
                        dashboardMigration = migratedObject.dashboardConfigurationsMigrated;
                    }

                    if (!_.isEmpty($scope.dashboardConfiguration) && dashboardMigration === true) {
                        var widgetsFromServer = $scope.dashboardConfiguration.slice(0, $scope.dashboardConfiguration.length - 1);
                        if($scope.dashboardOptions.loadWidgets){
                            $scope.dashboardOptions.loadWidgets(widgetsFromServer);
                        }
                    } else {
                        var localStorageDashboardConfig = $window.localStorage.getItem($scope.dashboardOptions.storageId);
                        var parsedLocalStorageDashboardConfig = JSON.parse(localStorageDashboardConfig);
                        if(parsedLocalStorageDashboardConfig){
                            var widgets = parsedLocalStorageDashboardConfig.widgets;

                            widgets.push({ 'dashboardConfigurationsMigrated': true });
                            saveDashboardConfig(widgets);
                        }

                    }
                }, function (error) {
                    growl.error("DASHBOARD_ERROR");
                });

            }

            function saveDashboardConfig(dashboardConfig) {
                DashboardResource.save(dashboardConfig);
            }

            $scope.manageDashboardsModels = function() {
                var modal = $modal.open({
                    templateUrl: 'tpl/manage-dashboards.tpl.html',
                    controller: 'ManageDashBoardsController',
                    windowClass: 'manage-dashboards-modal',
                    scope: $scope
                });

                modal.result['finally'](function() {
                    $location.url($location.path());
                });
            };

            $scope.openDashboardSettings = function() {
                var attributes = $rootScope.dataModel.getDashboardAttributes();
                $rootScope.prepareAttributes(attributes);
                var customSettings = DashboardService.getSettings() || {};
                var dashboardSettingsModal = $modal.open({
                    templateUrl: 'tpl/dashboard-settings.tpl.html',
                    controller: 'DashboardSettingsController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return {
                                attributes: attributes,
                                customSettings: customSettings
                            };
                        }
                    }
                });
                dashboardSettingsModal.result.then(function(result) {
                    if (result) {
                        initDashlets(true);
                    }
                }, function() {

                }).finally(function() {
                    $location.url($location.path());
                });
            };

            openModels($location.search());

            $scope.$on('$routeUpdate', function(scope, route) {
                openModels(route.params);
            });

            function openModels(params) {
                if (params.mode == 'add') {
                    $scope.manageDashboardsModels();
                } else if (params.mode == 'settings') {
                    $scope.openDashboardSettings();
                }
            }

            $scope.resetDashboard = function() {
                var confirmDialog = $dialogs.confirm('DASHBOARD.RESTORE_DEFAULTS', 'DASHBOARD.CONFIRM_RESTORE_PARAGRAPH');
                confirmDialog.result.then(function() {
                    $scope.dashboardOptions
                        .storage
                        .removeItem($scope.dashboardOptions.storageId);

                    DashboardResource.save([], function() {
                        $window.location.reload();
                    });
                });
            };
        }
);
//Generic controller for Widgets that have a dedicated Settings Dialog
// Settings are stored in widget.attrs
angular.module('llax')
    .controller('WidgetSpecificSettingsCtrl', ['$rootScope', '$scope', '$modalInstance', 'widget',
        function($rootScope, $scope, $modalInstance, widget) {
            // add widget to scope
            $scope.widget = widget;

            // set up result object
            $scope.result = jQuery.extend(true, {}, widget);

            $scope.ok = function() {
                $modalInstance.close($scope.result);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.getUrlIndexByTitle = function(settings,title){
                for (i = 0; i < settings.length; i++){
                    if (settings[i].title == title){
                        return i;
                    }
                }
            };

            $scope.updateUrl = function(index){
                $scope.result.attrs.selectedUrl = $rootScope.translate("DASHLET.NEWS.URL."+index,"");
                $scope.result.attrs.selectedIndex = index;
            };

            $scope.getNewsOptions = function(){
            return $scope.getOptions("NEWS", ["title", "url"]);
            };

            // awaits string array of key to search for
            //searchs for dashlet settings in translation properties
            $scope.getOptions = function(name, keys) {
                var tempOptions = {};
                var options = [];
                for (var i = 0; ($rootScope.translate("DASHLET." + name + "." + _.toUpper(keys[0]) + "." + i, "")) != ""; i++) {
                    for (var j = 0; j < keys.length; j++) {
                        var key = keys[j];
                        tempOptions[key] = $rootScope.translate("DASHLET." + name + "." + _.toUpper(key) + "." + i);
                    }
                    options.push(tempOptions);
                    tempOptions = {};
                }
                return options;
            };

        }
    ]);

angular.module('llax')
    .controller('ManageDashBoardsController', ['$rootScope', '$scope', '$modalInstance', 'DashletRegistry',
        function($rootScope, $scope, $modalInstance, DashletRegistry) {
            $scope.widgetDefinitions = DashletRegistry.getDashlets();
            $scope.getWidgetCount = function (widgetName) {
                var count = 0;
                if (widgetScope.$parent) {
                    count = _.countBy(widgetScope.$parent.widgets, function (w) {
                        return w.name == widgetName;
                    }).true;
                }

                return count ? count : 0;
            };
            $scope.close = function() {
                $modalInstance.close();
            };
        }
    ])
    .controller('DashboardSettingsController', ['$rootScope', '$scope', '$modalInstance','$window', 'data','DashboardService',
        function($rootScope, $scope, $modalInstance, $window, data, DashboardService) {
            $scope.userAttributes = data.attributes;
            $scope.customSettings = Object.assign({},data.customSettings);
            $scope.allCategories = $rootScope.dataModel.allCategories();
            $rootScope.translateAllCategories($scope.allCategories);
            $scope.cancel = function() {
                $modalInstance.close();
            };
            $scope.save = function() {
                DashboardService.saveSettings($scope.customSettings);
                $modalInstance.close($scope.customSettings);
            };
        }
]);
