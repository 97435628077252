angular.module('llax.services')
    .service('EnumAttributeService', function($log, $q, $rootScope, CodelistRessource, DataModelCustomizationConstants, OrganizationService,
        UrlRetrievalService) {

        this.isDynamicEnumAttribute = function(attribute) {
            return _.has(attribute.params, 'dynamicValuesUrl');
        };

        this.isCodelistAttribute = function(attribute) {
            return _.includes(DataModelCustomizationConstants.CODELIST_TYPES, attribute.typeName);
        };

        /**
         * Returns an Enum options and mutate the attribute with the options and other filter parameters.
         */
        this.getAttributeOptionsAsync = function(attribute, forceReloadDynamicEnum) {

            var deferred = $q.defer();
            var self = this;

            if (_.has(attribute, 'options') && !forceReloadDynamicEnum) {
                deferred.resolve(attribute.options);
                return deferred.promise;
            } else if (!_.has(attribute, 'params')) {
                deferred.resolve(null);
                return deferred.promise;
            }

            if (self.isDynamicEnumAttribute(attribute)) {
                if (forceReloadDynamicEnum || _.isNil(attribute.params.values)) {

                    if (!attribute.loadingDynamicValues) {
                        attribute.loadingDynamicValues = true;
                        $log.debug("Reloading option values for DynamicEnum attribute '%s'", attribute.name);
                        UrlRetrievalService.get(attribute.params.dynamicValuesUrl).then(function(data) {
                            attribute.params.values = data;
                            delete attribute.options;
                            $log.debug("Option values loaded for DynamicEnum attribute '%s'", attribute.name);
                            self.getAttributeOptionsFromOptionsParamAsync(attribute, forceReloadDynamicEnum)
                                .then(function(options) {
                                    deferred.resolve(options);
                                }).catch(function(error) {
                                    $log.error("Failure when trying to load option values for '%s' '%s'", attribute.name, error);
                                    deferred.resolve(null);
                                });
                        }).finally(function() {
                            attribute.loadingDynamicValues = false;
                        });
                    } else {
                        $log.debug("Option values loaded for DynamicEnum attribute '%s' are still being loaded from previous calls", attribute.name);
                        deferred.resolve(null);
                    }

                } else {
                    self.getAttributeOptionsFromOptionsParamAsync(attribute)
                        .then(function(options) {
                            deferred.resolve(options);
                        });
                }
            } else if (self.isCodelistAttribute(attribute)) {
                self.getCodelistOptionsAsync(attribute)
                    .then(function(options) {
                        $rootScope.translateAllOptions(attribute, options);
                        deferred.resolve(options);
                    });
            } else {
                self.getAttributeOptionsFromOptionsParamAsync(attribute)
                    .then(function(options) {
                        deferred.resolve(options);
                    });
            }

            return deferred.promise;
        };

        this.getAttributeOptionsFromOptionsParamAsync = function(attribute, forceReloadDynamicEnum) {

            var self = this;
            var deferred = $q.defer();
            var options;
            var optionsParam = $rootScope.dataModel.getAttributeOptionsParam(attribute);
            if (_.isEmpty(optionsParam)) {

                // Get options from referenced attribute, if no options were defined
                var referencedOptionAttribute = $rootScope.dataModel.getReferencedOptionAttribute(attribute);
                if (_.has(referencedOptionAttribute, 'params')) {

                    $rootScope.prepareAttribute(referencedOptionAttribute);
                    self.getAttributeOptionsAsync(referencedOptionAttribute, forceReloadDynamicEnum)
                        .then(function(options) {
                            if (!_.isEmpty(options)) {

                                attribute.options = options;

                                // Set optionsFilter and createNewOption, overriding the corresponding values of the referenced attribute
                                attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter || referencedOptionAttribute.optionsFilter;
                                attribute.createNewOption = attribute.params.skipValuesValidation || referencedOptionAttribute.createNewOption;

                                deferred.resolve(options);
                            } else {
                                deferred.resolve(null);
                            }

                        });

                } else {
                    deferred.resolve(null);
                }

            } else {

                options = _.get(attribute.params, optionsParam);
                var areOptionsLoading = false;
                if (_.isString(options)) {

                    // Get options from optionList, including filtering by groups
                    attribute.optionList = options;
                    var groupNames = _.get(attribute.params, optionsParam + 'OptionGroups');

                    areOptionsLoading = true;
                    $rootScope.dataModel.optionListOptionsAsync(attribute.optionList, groupNames)
                        .then(function(loadedOptions) {

                            attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;
                            if (_.isArray(loadedOptions)) {
                                attribute.options = loadedOptions;
                                attribute.createNewOption = attribute.params.skipValuesValidation;
                                attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;
                            }

                            if (!_.isEmpty(loadedOptions)) {
                                $rootScope.translateAllOptions(attribute, loadedOptions);
                                deferred.resolve(loadedOptions);
                            } else {
                                deferred.resolve(null);
                            }
                        });
                }

                if (_.isArray(options)) {
                    attribute.options = options;
                    attribute.createNewOption = attribute.params.skipValuesValidation;
                    attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;
                }

                if (!_.isEmpty(options) && !areOptionsLoading) {
                    $rootScope.translateAllOptions(attribute, options);
                    deferred.resolve(options);
                } else if (!areOptionsLoading) {
                    deferred.resolve(null);
                }
            }

            return deferred.promise;
        };

        this.getCodelistOptionsAsync = function(attribute) {

            var deferred = $q.defer();

            CodelistRessource.getPairs({
                name: attribute.params.codelist,
                limit:0
            }, function(data) {
                attribute.params.values = data;
                attribute.createNewOption = attribute.params.skipValuesValidation;
                attribute.optionsFilter = attribute.params.uiOptionsFilter || attribute.params.uiCodelistFilter;
                deferred.resolve(data);
            });

            return deferred.promise;
        };

        /**
         * Tries to filter attribute options, if:
         *  1- the attribute has 'optionsFilter' defined.
         *  2- the attribute 'params' has an 'uiParamsFilter' defined (legacy).
         *
         * FIXME: 'currentValue' is never used.
         *
         * @param item
         * @param attribute The enum attribute.
         * @param collectionEntry The collection entry, in case the attribute is used as a sub/member attribute of another composite attribute.
         * @param currentValue (DEPRECATED) Used for search functionality.
         * @param options The enum attribute options which are going to be filtered.
         */
        this.filterOptions = function(item, attribute, collectionEntry, currentValue, options) {

            var filteredOptions;
            if (!_.isEmpty(attribute.optionsFilter)) {
                filteredOptions = applyOptionsFilter(item, attribute, collectionEntry, currentValue, options, attribute.optionsFilter);
            } else if (!_.has(attribute.params, 'uiParamsFilter') && attribute.typeName === 'Dimensional') {

                // For compatibility: Unless a filter was applied, set user's UI language first, if it matches any option key
                var languageOption = _.find(options, { key: $rootScope.language });
                if (!_.isEmpty(languageOption)) {
                    filteredOptions = _.concat([languageOption], _.filter(options, function(option) {
                        return option.key !== languageOption.key;
                    }));
                } else {
                    filteredOptions = options;
                }

            } else {
                filteredOptions = options;
            }

            return filteredOptions;
        };

        /**
         * Invokes the AngularJS (customDirective) data model defined filter on the enum options.
         *
         * @param item
         * @param attribute The enum attribute.
         * @param collectionEntry The collection entry, in case the attribute is used as a sub/member attribute of another composite attribute.
         * @param currentValue (DEPRECATED) Used for search functionality.
         * @param options The enum attribute options which are going to be filtered.
         * @param optionsFilter The filter name to be invoked (AngularJS filter name).
         */
        function applyOptionsFilter(item, attribute, collectionEntry, currentValue, options, optionsFilter) {

            if (_.isNil(optionsFilter)) {
                return options;
            }

            var filter = $rootScope.getFilter(optionsFilter);
            if (_.isNil(filter)) {
                return options;
            }

            var user = $rootScope.user;
            var organization = OrganizationService.getOrganizationSnapshot();

            var keyBy = _.keyBy(options, 'key');
            var keys = _.keys(keyBy);
            var filteredKeys = filter(keys, item, attribute, user, organization, collectionEntry);

            if (!$rootScope.isEqual(keys, filteredKeys)) {

                if (attribute.typeName === 'Boolean') {
                    // In case of Boolean attribute, we only want to disable the option
                    // not returned by the filter, and not hide it completely.
                    options = _.map(options, function(option) {
                        if (!_.includes(filteredKeys, option.key)) {
                            option.disabled = true;
                        }
                        return option;
                    });
                } else {
                    // For other attribute types, pick only matching keys
                    var picked = _.pick(keyBy, filteredKeys);
                    options = _.values(picked);
                }
            }

            return options;
        }
    });