angular.module('llax.directives')
    .directive('hintTooltip', function($log, $timeout) {
        return {
            strict: 'E',
            scope: {
                hintId: '=',
                hintContent: '=',
                showHintIf: '=',
                hintIcon: '=',
                iconColor: '='
            },
            template:
                '<span '+
                    'id="hint-{{hintId}}" ' +
                    'data-ng-if="showHintIf" ' +
                    'data-ng-mouseenter="showTooltip()" ' +
                    'data-ng-mouseleave="hideTooltip()" ' +
                    'class="cursor-pointer">' +
                        '<i style="color: {{iconColor}};" class="{{hintIcon}}"></i>' +
                '</span>',
            controller: function($scope) {
                $scope.showTooltip = function() {
                    if (angular.isArray($scope.hintContent)) {
                        var ul = '<ul>';
                        _.forEach($scope.hintContent, function(hint) {
                            ul += '<li>' + hint + '</li>';
                        });
                        ul += '</ul>';
                        renderTooltip(ul);
                    } else if (_.isString($scope.hintContent)) {
                        renderTooltip('<p>' + $scope.hintContent + '</p>');
                    } else {
                        $log.error('hintTooltip: Could not determine hintContent type: ', $scope.hintContent);
                    }
                };

                $scope.hideTooltip = function removeTooltip() {
                    $timeout(function() {
                        var calloutMgr = hopscotch.getCalloutManager();
                        calloutMgr.removeCallout('callout_' + $scope.hintId);
                    }, 200);
                };

                function renderTooltip(content) {
                    function customRendererFn() {
                        var attributeStart = '<div class="tooltip-hint">';
                        var attributeEnd = '</div>' +
                            '<span class="hopscotch-arrow"></span>';
                        return attributeStart + content + attributeEnd;
                    }

                    var calloutId = 'callout_' + $scope.hintId;
                    var calloutConfig = {
                        id: calloutId,
                        target: 'hint-' + $scope.hintId,
                        placement: 'left',
                        content: '',
                        customRenderer: customRendererFn
                    };

                    var calloutMgr = hopscotch.getCalloutManager();
                    calloutMgr.removeCallout(calloutId);

                    var calloutEle = calloutMgr.createCallout(calloutConfig);
                    // Hack: override !important css rules, with inline precedence
                    calloutEle.element.style.cssText += 'border: none !important; z-index: 1050;';
                }

            }
        };
    });