angular.module('llax')
.controller('ConfirmDialogController', ['$scope', '$modalInstance','$translate', 'header', 'msg', 'notification', 'options',
    function ($scope, $modalInstance, $translate, header, msg, notification,options) {

            $scope.header = (header !== undefined) ? header : $translate.instant('MODAL.CONFIRM_HEADER');
            $scope.variables = _.get(options,'variables') || {};
            $scope.msg = (msg !== undefined) ? msg : $translate.instant('MODAL.CONFIRM_REQUIRED', $scope.variables);
            $scope.notification = notification;

            $scope.no = function() {
                $modalInstance.dismiss('no');
            };

            $scope.yes = function() {
                $modalInstance.close('yes');
            };
        }
    ]);
