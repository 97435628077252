angular.module('llax')
    .controller('MappingFunctionDefaultController',
        function($log, $modal, $modalInstance, $rootScope, $scope, $timeout, $q, data, growl, onFunctionParametersSaved, SimpleMappingsResource, TemplateMappingService) {
            $scope.mappingFunction = data.mappingFunction__;
            $scope.params = data.mappingFunctionParams || {};
            $scope.tempParamsModel = {};
            $scope.mappingAttributes = [];
            $scope.simpleMappings = [];
            $scope.editorOptions = {
                lineWrapping : true,
                lineNumbers: true,
                mode: 'ruby',
                autoRefresh: true,
            };

            $scope.PARAM_TYPES = TemplateMappingService.PARAM_TYPES;
            $scope.getParameterType = TemplateMappingService.getParameterType;

            $scope.getSelectFromObject = function(parameter) {
                return TemplateMappingService.parseSelectFrom(parameter);
            };

            $scope.getSelectFromValues = function(parameter) {
                // Defines the 'source' to select the values from.
                // type 'object': Used for data model defined objects which includes:
                //    'simple_mappings' -> Select any simple-mapping
                //    'attributes' -> Select any attribute in the data model
                // FIXME: Future uses:
                //    'mapping_attributes' -> Select any attribute in the current mapping layout
                //    'users' -> Select any user
                //    'contacts' -> Select any contact
                //    'codelists' -> Select any codelist

                if (_.isString(parameter)) {
                    parameter = getParameterMetaData(parameter);
                }

                switch($scope.getSelectFromObject(parameter)) {
                    case 'attributes':
                        return $scope.mappingAttributes;
                    case 'simple_mappings':
                        return $scope.simpleMappings;
                    default:
                        $log.error('Unrecognized parameter\'s selectFrom array', param);
                        return [];
                }
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.save = function() {
                // Prevent saving if not all required parameters are selected:
                var requiredParameters = _.filter($scope.mappingFunction.parameters, function(parameter) {
                    // Required parameters are the ones which are not optional and don't have a default value
                    return !parameter.optional && _.isNil(parameter.defaultValue);
                });
                var actualParameters = _.filter(_.keys($scope.params), function(parameterName) {
                    return !_.isEmpty($scope.params[parameterName]);
                });

                var missingParameters =
                    _.differenceWith(
                        requiredParameters,
                        actualParameters,
                        function(firstSetEntry, secondSetEntry) {
                            return firstSetEntry.name === secondSetEntry;
                        }
                    );

                if (missingParameters.length === 0) {
                    onFunctionParametersSaved(data, $scope.params);
                    $scope.cancel();
                } else {
                    growl.error('TEMPLATE_MAPPINGS.FILL_REQUIRED_PARAMS');
                }
            };

            $scope.onEditSimpleMappingClick = function(param) {
                $modal.open({
                    templateUrl: 'tpl/simple-mapping/modal-simple-mapping.tpl.html',
                    controller: 'SimpleMappingsController',
                    resolve: {
                        data: function() {
                            return {
                                entry: param,
                                onSimpleMappingSelected: $scope.onSimpleMappingFromSimpleMappingsModalSelected
                            };
                        }
                    },
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'template-export-simple-mapping-modal'
                });
            };

            $scope.onSimpleMappingFromSimpleMappingsModalSelected = function(param, chosenSimpleMapping) {
                $scope.params[param.name] = chosenSimpleMapping.name;
            };

            function onLoaded() {
                $scope.mappingAttributes = TemplateMappingService.getFilteredLayoutAttributes();

                _.forEach($scope.mappingFunction.parameters, function(parameter) {
                    parameter.uiType = $scope.getParameterType(parameter);

                    var paramValue = $scope.params[parameter.name];
                    if (_.isNil(paramValue) && !_.isNil(parameter.defaultValue)) {
                        $scope.params[parameter.name] = parameter.defaultValue;
                    }
                });
            }

            function getParameterMetaData(paramName) {
                return _.find($scope.mappingFunction.parameters, { name: paramName });
            }

            function loadSimpleMappings(resolve, reject) {
                SimpleMappingsResource.getAll(function(response) {
                    $scope.simpleMappings = _.map(response, function(simpleMapping) {
                        return {
                            name: simpleMapping.name,
                            value: simpleMapping.name
                        };
                    });
                    resolve();
                }, function(response) {
                    growl.error('SIMPLE_MAPPING.LOAD_FAILED');
                    reject();
                });
            }

            function init() {
                $q.all([
                    $q(function(resolve, reject) {
                        loadSimpleMappings(resolve, reject);
                    }),
                    $q(function(resolve, reject) {
                        if ($rootScope.isDataModelLoaded) {
                            resolve();
                        } else {
                            $scope.mappingAttributes = [];
                            $scope.$on('dataModelLoaded', function() {
                                resolve();
                            });
                        }
                    })
                ]).then(function() {
                    onLoaded();
                });
            }

            init();
        }
    );