angular.module('llax')
    .controller('RequestsController',
        function($rootScope, $scope, $routeParams, $location, ReceivedRequestResource, SentRequestResource, growl) {

            function loadRequestFromSupplier() {
                SentRequestResource.query({
                        requestId: $routeParams.requestId
                    },
                    function(request) {
                        if (request != undefined && request.length > 0) {
                            //On receiver Side there is always only one element in the list for a request
                            $scope.requestFromSupplier = request[0];
                        }
                    },
                    function(response) {});
            }

            loadRequestFromSupplier();

            $scope.sendAnswer = function() {
                var data = $scope.requestFromSupplier.questions;
                ReceivedRequestResource.save({
                        requestId: $routeParams.requestId
                    }, {
                        questions: data
                    },
                    function(response) {
                        $location.path("received-requests");
                        growl.success("REQUEST.ANSWER_CONFIRMATION");

                    },
                    function(response) {});

            };

            $scope.cancel = function() {
                $location.path("received-requests");
            };
        }
    );
