angular.module('llax')
    .controller('PrefillSimpleMappingsController',
        function ($modalInstance, $rootScope, $scope, data, growl, SimpleMappingsResource, SimpleMappingsService) {
            $scope.MODE_PREFILL = 'PREFILL';
            $scope.MODE_LINK = 'LINK';

            if (data.linkMode) {
                $scope.mode = $scope.MODE_LINK;
            } else {
                $scope.mode = $scope.MODE_PREFILL;
            }

            $scope.simpleMapping = data.simpleMapping;
            $scope.importing = false;
            $scope.linkableAttributes = data.linkableAttributes;
            $scope.prefillEntities = [];
            $scope.form = { prefillEntities: [] };

            $scope.cancel = function () {
                if ($scope.importing) {
                    return;
                }
                $modalInstance.dismiss('cancel');
            };

            $scope.importFromCodelist = function() {
                $scope.importing = true;
                if ($scope.mode === $scope.MODE_PREFILL) {
                    SimpleMappingsService.importModelAttributesIntoSimpleMappingAsync($scope.form.prefillEntities, $scope.simpleMapping.id)
                        .then(function(response) {
                            var modelAttributes = _.map($scope.form.prefillEntities, 'name');
                            return SimpleMappingsService.linkAttributesToSimpleMappingAsync($scope.simpleMapping, modelAttributes);
                        }).then(function(response) {
                            data.onSimpleMappingImported($scope.simpleMapping.id);
                            $scope.importing = false;
                            $scope.cancel();
                        }).catch(function(error) {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        });
                } else {
                    var modelAttributes = _.map($scope.form.prefillEntities, 'name');
                    SimpleMappingsService.linkAttributesToSimpleMappingAsync($scope.simpleMapping, modelAttributes)
                        .then(function() {
                            data.onSimpleMappingImported($scope.simpleMapping.id);
                            $scope.importing = false;
                            $scope.cancel();
                        }).catch(function() {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        });
                }
            };

            function prepareLinkableAttributes(linkableAttributes) {
                return _.map(linkableAttributes, function(attribute) {
                    attribute.translatedLabel = $rootScope.translateAttribute(attribute.label);
                    return attribute;
                });
            }

            function init() {
                $scope.prefillEntities =
                    _.concat($scope.prefillEntities,
                             prepareLinkableAttributes($scope.linkableAttributes));

                if (!_.isEmpty($scope.simpleMapping.linkedAttributes) && $scope.mode === $scope.MODE_LINK) {
                    $scope.form.prefillEntities = _.filter($scope.prefillEntities, function(attribute){
                        return _.includes($scope.simpleMapping.linkedAttributes, attribute.name);
                    });
                }
            }

            init();
        }
    );