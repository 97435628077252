angular.module('llax')
    .controller('TemplateMappingListController',
        function($dialogs, $location, $log, $scope, $translate, $window, ExportMappingResource, growl) {
            var TYPE_LIST = 'LIST';
            var TYPE_PAGE = 'PAGE';
            $scope.isLoading = true;
            $scope.templates = [];

            var columnDefs = [{
                field: 'name',
                displayName: $translate.instant('TEMPLATE_MAPPINGS.NAME'),
                headerTooltip: true,
                cellTooltip: true,
                cellTemplate:
                    '<div class="ui-grid-cell-contents">' +
                        '<a data-ng-href="export-templates/{{grid.appScope.getEncodedURLComponentString(row.entity.key)}}">' +
                        '  <span>{{COL_FIELD}}</span>' +
                        '</a>' +
                    '</div>'
            }, {
                field: 'templateType',
                displayName: $translate.instant('TEMPLATE_MAPPINGS.TYPE'),
                headerTooltip: true,
                cellTemplate:
                    '<div class="ui-grid-cell-contents">' +
                        '<span data-ng-if="row.entity.templateType === \'' + TYPE_LIST + '\'" data-translate>TEMPLATE_MAPPINGS.TYPE.LIST</span>' +
                        '<span data-ng-if="row.entity.templateType === \'' + TYPE_PAGE + '\'" data-translate>TEMPLATE_MAPPINGS.TYPE.PAGE</span>' +
                    '</div>'
            }, {
                field: 'createdBy',
                displayName: $translate.instant('CREATED_BY'),
                headerTooltip: true,
                cellTooltip: true
            }, {
                field: 'createdAt',
                displayName: $translate.instant('CREATED_AT'),
                headerTooltip: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.createdAt | date: \'shortDate\'}}</div>'
            }, {
                field: 'updatedBy',
                displayName: $translate.instant('UPDATED_BY'),
                headerTooltip: true,
                cellTooltip: true
            }, {
                field: 'updatedAt',
                displayName: $translate.instant('UPDATED_AT'),
                headerTooltip: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.updatedAt | date: \'shortDate\'}}</div>'
            }, {
                field: 'privateMapping',
                displayName: $translate.instant('EXPORT.PRIVATE'),
                headerTooltip: true,
                cellTemplate: '<div class="ui-grid-cell-contents flex justify-center"><i class="syncons syncons-checkmark" data-ng-if="row.entity.privateMapping"></i></div>'
            }, {
                displayName: $translate.instant('ACTIONS'),
                headerTooltip: true,
                name: 'actions',
                enableCellEdit: false,
                enableCellSelection: false,
                allowCellFocus: false,
                pinnedRight: true,
                enableColumnResizing: false,
                enableSorting: false,
                enableHiding: false,
                resizable: false,
                width: 120,
                cellTemplate:
                    '<div class="ui-grid-cell-contents flex flex-row-reverse items-center">' +
                        '<button ' +
                            'style="padding: 0; background: none; min-height: 10px;" ' +
                            'class="btn btn-sm" ' +
                            'data-ng-click="grid.appScope.deleteTemplate(row.entity)" ' +
                            'title="{{\'TEMPLATE_MAPPINGS.DELETE_TEMPLATE\'}}">' +
                            '<span class="syncons syncons-delete"></span>' +
                        '</button>' +
                        '<button ' +
                            'style="padding: 0; background: none; min-height: 10px; margin: 0 5px 0px 5px;" ' +
                            'class="btn btn-sm" ' +
                            'data-ng-click="grid.appScope.editTemplate(row.entity)" ' +
                            'title="{{\'TEMPLATE_MAPPINGS.EDIT_TEMPLATE\'}}">' +
                            '<span class="syncons syncons-edit hover-color-primary"></span>' +
                        '</button>' +
                    '</div>'
            }];

            $scope.gridOptions = {
                data: 'templates',
                enableColumnMenus: false,
                columnDefs: columnDefs,
                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                }
            };

            $scope.createExportTemplate = function() {
                $location.path('export-templates/_new');
            };

            $scope.deleteTemplate = function(template) {
                var confirmDialog = $dialogs.confirm('CONFIRM_DELETE', 'TEMPLATE_MAPPINGS.CONFIRM_DELETE');
                confirmDialog.result.then(function(){
                    ExportMappingResource.delete({}, {
                        mappingName: template.name
                    },
                    function(response) {
                        growl.success("SUCCESS");
                        init();
                    },
                    function(response) {
                        growl.error("TEMPLATE_MAPPINGS.DELETE_TEMPLATE_FAILED");
                    });
                });
            };

            $scope.editTemplate = function(template) {
                $location.path('export-templates/' + template.key);
            };

            $scope.getEncodedURLComponentString = function(urlComponent) {
                return encodeURIComponent(urlComponent);
            };

            $scope.gridHeight = function () {
                var w = angular.element($window);
                if (w.width() < 992) {
                    return;
                }
                var footer = angular.element('footer');
                var boxContent = angular.element('.box-content');
                var content = angular.element('#content');
                var boxContentPadding = parseInt(boxContent.css('padding-bottom'), 10);
                var contentPadding = parseInt(content.css('padding-bottom'), 10);
                var offset = 630;
                if (angular.element("#templates-grid") && angular.element("#templates-grid").offset()) {
                    offset = angular.element("#templates-grid").offset().top + footer.innerHeight() + boxContentPadding + contentPadding;
                }
                var newHeight = w.height() - offset;

                if ($scope.gridApi) {
                    $scope.gridApi.grid.gridHeight = newHeight;
                }

                return {
                   'height': newHeight + "px"
                };
            };

            function init() {
                ExportMappingResource.getTemplateMappings(
                    function(response) {
                        $scope.isLoading = false;
                        $scope.templates = response.data;
                    },
                    function(response) {
                        $log.error(response);
                        growl.error('TEMPLATE_MAPPINGS.LOAD_FAILED');
                    });
            }

            init();
        }
    );
