angular.module('llax')
    .controller('LayoutTemplateLibraryCtrl',
        function($rootScope, $scope, $translate, $modalInstance, growl, ContactsResource, CommunicationChannelResource,
            DeleteItemsResource, layoutBlockTemplates) {

            $scope.layoutBlockTemplates = layoutBlockTemplates;
            $scope.language = $rootScope.language;
            $scope.selected = {};

            $scope.addBlock = function() {
                $modalInstance.close($scope.selected);
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.select = function(template) {
                $scope.selected = template;
            };

        });
