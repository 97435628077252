angular.module('llax.directives')
.directive('apiBasedCodelist', function factory($http, $log) {
    var directiveDefinitionObject = {
        restrict: 'E',
        scope: {
            attribute: '=attribute',
            item: '='
        },
        replace: false,
        templateUrl: 'APISelect',
        link: function(scope, element, attrs) {

            var searchUrl = '/api/v1/datamodel/retrieval/options';

            // initialise scope
            scope.api_search_results = [];
            scope.selection = {};
            if (scope.item && scope.item.$promise) {
                scope.item.$promise.then(function() {
                    var code = scope.item[scope.attribute.name];
                    scope.selection.selected = {
                        id: scope.item[scope.attribute.name],
                        title: ''
                    };
                    // try to fetch a given option by searching for its id
                    $http
                        .get(searchUrl, {
                            params: {
                                q: code
                            }
                        })
                        .then(function(response) {
                            var selectedEntry = _.find(response.data, function(entry) {
                                return (entry.id == code);
                            });

                            if (selectedEntry) {
                                scope.selection.selected = selectedEntry;
                            } else {
                                $log.warn('found no options with id ' + code);
                            }
                            scope.api_search_results = response.data;
                        });
                });
            }

            scope.$on('clearAttributeValue', function(a) {
                if (_.isNil(scope.item[scope.attribute.name])) {
                    scope.selection.selected = null;
                }
            });

            // Set the value for the attribute on selection
            scope.onSelected = function(selectionItem, model) {
                scope.item[scope.attribute.name] = selectionItem.id;
            };

            // For every entered search term, perform an api call
            scope.refresh = function(term) {
                if (!_.isEmpty(term) && term.length < 3) {
                    //scope.api_search_results = [];
                    return scope.api_search_results;
                }
                return $http.get(searchUrl, {
                        params: {
                            q: term
                        }
                    })
                    .then(function(response) {
                        scope.api_search_results = response.data;
                    });
            };
        }
    };
    return directiveDefinitionObject;
});
