angular.module('llax')
    .controller('PublishItemsController',
        function($log, $rootScope, $scope, $modalInstance, $translate, $q, $locale, $timeout, itemSelection, mode, publicationData, growl,
            CommunicationChannelService, DefaultItemResource,
            JobResource, SelectionResource, PublicationMode, PublicationResource,
            DePublicationResource) {

            $scope.loading = true;

            var itemsQuery = itemSelection.primaryKeys ? itemSelection.primaryKeys :
                _.omit(itemSelection.itemsQuery, _.isEmpty);

            $scope.data = {
                mode: mode,
                itemsQuery: itemsQuery
            };

            $scope.planOptions = {
                action: 'IMMEDIATELY',
                job: {
                    schedule: {}
                }
            };

            $scope.settings = {
                allRecipientsSelected: false
            };

            $scope.allOrganizationsSelected = false;

            $scope.ok = function() {
                $modalInstance.close();
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.plans = $rootScope.getPreparedCommunicationPlans();

            function loadPublicationData() {
                if (!_.isEmpty(publicationData)) {
                    var job = publicationData.job;
                    $scope.planOptions.job = job || $scope.planOptions.job;
                    $scope.planOptions.job.schedule = $scope.planOptions.job.schedule || {};
                    var sch = $scope.planOptions.job.schedule;
                    if (!_.isNil(sch.startDateTime)) {
                        sch.startDateTime = new Date(sch.startDateTime);
                    }
                    if (!_.isNil(sch.endDateTime)) {
                        sch.endDateTime = new Date(sch.endDateTime);
                    }
                    if (!_.isNil(sch.nextRunDateTime)) {
                        sch.nextRunDateTime = new Date(sch.nextRunDateTime);
                    }
                    $scope.planOptions.action = "SCHEDULE_PLAN";
                    $scope.planOptions.actionRepeat = !_.isNil(sch.repeatType || sch.repeatEvery || sch.endDateTime);
                    if (!_.isEmpty(job.destinationOrganizationIds)) {
                        _.forEach($scope.organizations, function(org) {
                            org.isSelected = job.destinationOrganizationIds.indexOf(org.organizationId) > -1;
                        });
                    }
                    if (!_.isEmpty(job.destinationChannelIds)) {
                        _.forEach($scope.communicationChannels, function(ch) {
                            ch.isSelected = job.destinationChannelIds.indexOf(ch.id) > -1;
                        });
                    }
                    if (!_.isEmpty(job.channelSubDestinations)) {
                        _.forEach(job.channelSubDestinations, function(sd) {
                            var str = sd.split(':');
                            var ch = _.find($scope.communicationChannels, function(c) {
                                return c.id == str[0] && c.subDestinationKey == str[1];
                            });
                            if (!_.isEmpty(ch)) {
                                ch.isSelected = true;
                            }
                        });
                    }
                } else {
                    // If only *one* destination is available, set it as selected.
                    if ($scope.organizations.length === 1 && $scope.communicationChannels.length === 0) {
                        $scope.organizations[0].isSelected = true;
                    } else if ($scope.communicationChannels.length === 1 && $scope.organizations.length === 0) {
                        $scope.communicationChannels[0].isSelected = true;
                    }
                }
            }

            function loadPublicationDestinations() {
                var customPublicationService = $rootScope.getService("CustomPublicationSettings");
                var config = {
                    publicationMode: $scope.data.mode
                };
                return CommunicationChannelService.loadPublicationDestinations(config)
                    .then(function(result) {

                        // Filter the publication targets based on the publication mode
                        if (!_.isNil(customPublicationService) && !_.isNil(customPublicationService.filterPublicationTargets)) {
                            // Add destinationType to communicationChannels
                            var communicationChannelsWithType = _.map(result.communicationChannels, function(channel) {
                                channel.destinationType = 'COMMUNICATION_CHANNEL';
                                return channel;
                            });

                            // Add destinationType to organizations
                            var organizationsWithType = _.map(result.organizations, function(org) {
                                org.destinationType = 'IN_PLATFORM';
                                return org;
                            });
                            var publicationTargets = communicationChannelsWithType.concat(organizationsWithType);
                            var filteredPublicationTargets = customPublicationService.filterPublicationTargets(publicationTargets, $scope.data.mode, $rootScope.user, $rootScope.organization);

                            $scope.communicationChannels = _.filter(filteredPublicationTargets, function(target) {
                                return target.destinationType === 'COMMUNICATION_CHANNEL';
                            });

                            $scope.organizations = _.filter(filteredPublicationTargets, function(target) {
                                return target.destinationType === 'IN_PLATFORM';
                            });

                        } else {
                            $scope.communicationChannels = result.communicationChannels;
                            $scope.organizations = result.organizations;
                        }

                        if (_.isEmpty($scope.communicationChannels)) {
                            $scope.loading = false;
                        } else {
                            // If at least one communication channel has a plan with a publication category and item, we
                            // fill the `$scope.item` with default values from the plan's category default item.
                            var publicationCategoryName = _.reduce($scope.communicationChannels, function(foundCategoryName, channel) {
                                var categoryName = $scope.getPublicationItemCategoryOfChannel(channel);
                                if (!_.isEmpty(categoryName)) {
                                    foundCategoryName = categoryName;
                                }

                                return foundCategoryName;
                            }, null);

                            if (!_.isEmpty(publicationCategoryName)) {
                                var selectedPublicationMode;
                                if (!_.isEmpty(publicationData)) {
                                    var job = publicationData.job;
                                    if (!_.isEmpty(job.publicationValues) && !_.isEmpty(job.publicationValues.publication_mode)) {
                                        selectedPublicationMode = job.publicationValues.publication_mode;
                                    }
                                }
                                $scope.setPublicationItem(publicationCategoryName, selectedPublicationMode);
                            } else {
                                $scope.item = {};
                                $scope.loading = false;
                            }
                        }
                    });
            }
            loadPublicationDestinations().then(loadPublicationData);

            $scope.hasPublicationAttributes = function(planName) {
                var publicationAttributes = $scope.getPublicationAttributes(planName);
                return publicationAttributes.length > 0;
            };

            $scope.getPublicationAttributes = function(planName) {
                var plan = $scope.plans[planName];
                return plan ? plan[(mode === 'PUBLISH' ? 'publicationAttributes' : 'unpublicationAttributes')] : [];
            };

            $scope.getPublicationItemCategoryOfChannel = function(channel) {
                var plan = $scope.plans[channel.plan];
                var categoryType = (mode === 'PUBLISH' ? 'publicationCategory' : 'unpublicationCategory');
                var categoryName = (plan ? plan[categoryType] : null);
                return categoryName;
            };

            $scope.setPublicationItem = function(categoryName, selectedPublicationMode) {
                var defaultItem = $rootScope.dataModel.getDefaultItem(categoryName);
                if (defaultItem) {
                    $scope.item = angular.extend({}, angular.copy(defaultItem), $scope.item);
                    if (!_.isNil(selectedPublicationMode)) {
                        $scope.item.publication_mode = selectedPublicationMode;
                    }
                    $scope.loading = false;
                } else {
                    DefaultItemResource.get({
                        category: categoryName
                    }, function(defaultItem) {
                        $rootScope.dataModel.setDefaultItem(categoryName, defaultItem.toJSON());
                        $scope.item = angular.extend({}, defaultItem.toJSON(), $scope.item);
                        $scope.loading = false;
                    });
                }
            };

            $scope.onBeforeStepChange = function(event) {
                var recipients = $scope.organizations.concat($scope.communicationChannels);
                var anySelected = _.some(recipients, 'isSelected');
                if (anySelected) {

                    // Before moving to the scheduling step, check if it's enabled by the 'CustomPublicationSettings'.
                    var customPublicationService =  $rootScope.getService("CustomPublicationSettings");
                    if (!_.isNil(customPublicationService) && !_.isNil(customPublicationService.enablePublicationSchedule)) {
                        var selectedPublicationDestinations = getSelectedPublicationDestinations($scope.organizations, $scope.communicationChannels);

                        var isScheduleEnabled = customPublicationService.enablePublicationSchedule(itemSelection.primaryKeys, selectedPublicationDestinations, $rootScope.user, $rootScope.organization);

                        if (!isScheduleEnabled) {
                            // In case the schedule is disabled, skip the next step, and publish the items immediately.
                            $log.info('Publications scheduling is disabled by \'enablePublicationSchedule()\' settings in \'CustomPublicationSettings\'.');
                            $scope.confirmPublishItems();
                            return false;
                        }
                    }

                    return true;
                } else {
                    growl.warning('NO_ORGANIZATIONS_SELECTED');
                    return false;
                }
            };

            $scope.confirmPublishItems = function(items) {

                var publicationDestinations = getSelectedPublicationDestinations($scope.organizations, $scope.communicationChannels);

                if (publicationDestinations.length >= 1) {
                    if ($scope.planOptions.action === 'IMMEDIATELY') {
                        $rootScope.$broadcast('confirmPublishItems', itemSelection, mode, publicationDestinations, $scope.item);
                        $scope.ok();
                    } else {

                        if (!_.isNil(publicationData) && !_.isNil(publicationData.selectionId)) {
                            runJob(publicationData.selectionId, mode, publicationDestinations);
                        } else {
                            itemSelection = (itemSelection.itemsQuery && itemSelection.itemsQuery.primaryKeys) ?
                                { primaryKeys: itemSelection.itemsQuery.primaryKeys } :
                                itemSelection;
                            SelectionResource.save(itemSelection, function(response) {
                                runJob(response.selectionId, mode, publicationDestinations);
                            }, function(errorResponse) {
                                growl.error($rootScope.getErrorMessage(errorResponse, 'PUBLICATION_FAILED'));
                            });
                        }

                    }
                } else {
                    growl.warning("NO_ORGANIZATIONS_SELECTED");
                }
            };

            function getCurrentJob(selectionId, mode, publicationValues, organizationDestinations, channelDestinations,
                publicationDestinations) {
                var selectedOrganizations = _.map(organizationDestinations, 'destinationId');
                var subDestinations = _.chain(publicationDestinations)
                    .filter(function(destination) {
                        return destination.subDestination !== undefined;
                    })
                    .map(function(element) {
                        return element.destinationId + ':' + element.subDestination;
                    })
                    .value();
                var selectedChannels = _.chain(channelDestinations)
                    .filter(function(channel) {
                        return subDestinations.indexOf(channel.destinationId + ':' + channel.subDestination) == -1;
                    })
                    .map('destinationId')
                    .value();

                var job = angular.extend($scope.planOptions.job, {
                    type: 'PUBLICATION',
                    publicationType: mode === 'DEPUBLISH' ? 'DELETE' : 'ADD',
                    destinationChannelIds: selectedChannels,
                    destinationOrganizationIds: selectedOrganizations,
                    channelSubDestinations: subDestinations,
                    publicationValues: publicationValues,
                    selectionId: selectionId
                });
                job.schedule.active = true;
                if (!$scope.planOptions.actionRepeat) {
                    job.schedule = { active: true, startDateTime: job.schedule.startDateTime };
                }

                return job;
            }

            function runJob(selectionId, mode, publicationDestinations) {
                var organizationDestinations = _.filter(publicationDestinations, function(dest) {
                    return dest.destinationType === "IN_PLATFORM";
                });

                var channelDestinations = _.filter(publicationDestinations, function(dest) {
                    return dest.destinationType === "COMMUNICATION_CHANNEL";
                });

                var job = getCurrentJob(selectionId, mode, $scope.item, organizationDestinations, channelDestinations, publicationDestinations);
                JobResource.save(job,
                    function(response) {
                        growl.info('PUBLICATION_STARTED');
                        $scope.ok();
                    },
                    function(errorResponse) {
                        growl.error($rootScope.getErrorMessage(errorResponse,'PUBLICATION_FAILED'));
                    });
            }

            function getFilteredDestinations() {
                var ele_org = document.getElementById('organizationsList');
                var ele_ch = document.getElementById('communicationChannelList');
                var scope_org = angular.element(ele_org).scope();
                var scope_ch = angular.element(ele_ch).scope();
                var destinations = [];
                if (scope_org && !_.isEmpty(scope_org.filteredOrganizations)) {
                    destinations = scope_org.filteredOrganizations;
                }
                if (scope_ch && !_.isEmpty(scope_ch.filteredChannels)) {
                    destinations = destinations.concat(scope_ch.filteredChannels);
                }
                return destinations;
            }

            function getSelectedPublicationDestinations(organizations, communicationChannels) {
                var mapOrganizationPublication = function(organization) {
                    return {
                        destinationId: organization.organizationId,
                        destinationType: "IN_PLATFORM"
                    };
                };
                var mapChannelPublication = function(channel) {
                    return {
                        destinationId: channel.id,
                        destinationType: "COMMUNICATION_CHANNEL",
                        subDestination: channel.subDestinationKey
                    };
                };

                var organizationDestinations = _(organizations)
                    .filter('isSelected')
                    .map(mapOrganizationPublication)
                    .value();
                var channelDestinations = _(communicationChannels)
                    .filter('isSelected')
                    .map(mapChannelPublication)
                    .value();

                return organizationDestinations.concat(channelDestinations);
            }

            $scope.flterChanged = function() {
                $timeout(function() {
                    $scope.selectionUpdated(true);
                });
            };

            $scope.selectionUpdated = function(searchTextChanged) {
                var recipients = getFilteredDestinations();
                allRecipientsSelected = _.every(recipients, function(r) {
                    return r.isSelected;
                });
                if (!searchTextChanged && allRecipientsSelected != $scope.settings.allRecipientsSelected) {
                    var allRecipients = $scope.organizations.concat($scope.communicationChannels);
                    for (var i = 0; i < allRecipients.length; i++) {
                        if (recipients.indexOf(allRecipients[i]) < 0) {
                            allRecipients[i].isSelected = false;
                        }
                    }
                }
                $scope.settings.allRecipientsSelected = allRecipientsSelected;
            };

            $scope.selectAllRecipients = function(isSelected) {
                var recipients = getFilteredDestinations();
                var allRecipients = $scope.organizations.concat($scope.communicationChannels);
                for (var i = 0; i < allRecipients.length; i++) {
                    allRecipients[i].isSelected = false;
                }
                for (var j = 0; j < recipients.length; j++) {
                    recipients[j].isSelected = isSelected;
                }
            };

            function doPublishItems(mode, dataToPublish) {
                if (mode === PublicationMode.DEPUBLISH) {
                    DePublicationResource.depublish({}, dataToPublish,
                        function() {
                            growl.success("DEPUBLICATION_STARTED");
                        },
                        function(response) {
                            $scope.status = response.status;
                            growl.error("DEPUBLICATION_FAILED");
                        });
                } else {
                    PublicationResource.publish({}, dataToPublish,
                        function() {
                            growl.success("PUBLICATION_STARTED");
                        },
                        function(response) {
                            $scope.status = response.status;
                            growl.error($rootScope.getErrorMessage(response, "PUBLICATION_FAILED"));
                        });
                }
            }

            $scope.$on('confirmPublishItems', function(scope, itemSelection, mode, publicationDestinations, publicationValues) {
                var dataToPublish = {
                    destinations: publicationDestinations,
                    publicationValues: publicationValues
                };

                if (itemSelection.itemsQuery && itemSelection.itemsQuery.selectionId) {
                    dataToPublish.selectionId = itemSelection.itemsQuery.selectionId;
                    doPublishItems(mode, dataToPublish);
                } else {
                    var selection = (itemSelection.itemsQuery && itemSelection.itemsQuery.primaryKeys) ?
                        { primaryKeys: itemSelection.itemsQuery.primaryKeys } :
                        itemSelection;
                    SelectionResource.save(selection, function(response) {
                        dataToPublish.selectionId = response.selectionId;
                        doPublishItems(mode, dataToPublish);
                    }, function(errorResponse) {
                        growl.error($rootScope.getErrorMessage(errorResponse, "PUBLICATION_FAILED"));
                    });
                }
            });

            $scope.onWizardInit = function () {

                var customPublicationService = $rootScope.getService("CustomPublicationSettings");
                if (!_.isNil(customPublicationService)) {
                    var selectedPublicationDestinations = getSelectedPublicationDestinations($scope.organizations, $scope.communicationChannels);
                    if (!_.isNil(customPublicationService.filterPublicationTargets)) {
                      selectedPublicationDestinations = customPublicationService.filterPublicationTargets(selectedPublicationDestinations, $scope.data.mode, $rootScope.user, $rootScope.organization);
                    }
                    if (!_.isNil(customPublicationService.selectPublicationTargets) && !_.isNull(customPublicationService.selectPublicationTargets(itemSelection.primaryKeys, selectedPublicationDestinations, $rootScope.user, $rootScope.organization))) {
                        setTimeout(function() {
                            $scope.$broadcast('goToStep', 1);
                        }, 30);
                    }
                }

            };
        });
