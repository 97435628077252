angular.module('llax')
    .controller('EditReportController',
        function($rootScope, $scope, $modalInstance, $filter, JobResource, ItemsSearchFilterResource, ExportFormatsResource,
            SelectionResource, growl, data) {

            if (data) {
                $scope.job = {
                    name: data.name,
                    type: data.type,
                    active: data.schedule.active,
                    date: $filter('date')(data.schedule.startDateTime, 'medium'),
                    status: data.schedule.status,
                    recipients: data.emailRecipients.join(', ')
                };
                if (data.format) {
                    $scope.job.mapping = data.format.split("__mappingname__=")[1];
                }
                if (data.schedule.repeatEvery) {
                    $scope.job.repeat = data.schedule.repeatEvery + ' ' + data.schedule.repeatType + ' from ' +
                        data.schedule.repeatHour + ':' + data.schedule.repeatMinute;
                }
                if (data.selectionId) {
                    SelectionResource.get({
                        selectionId: data.selectionId
                    }, function(response) {
                        var query = _.filter(response.itemsQuery, function(value, key) {
                            return !_.isNil(value);
                        });
                        $scope.job.query = query.join(', ');
                    });
                }
            }

            $scope.report = {
                name: '',
                itemsQuery: {},
                emailRecipients: []
            };

            $scope.planOptions = {
                action: 'SCHEDULE_PLAN',
                job: {
                    schedule: {}
                }
            };

            $scope.ok = function() {
                $modalInstance.close();
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.onBeforeStepChange = function(event, report) {
                var valuesSet = !_.isEmpty(report.itemsQuery.category) || !_.isEmpty(report.filter);
                if (valuesSet) {
                    return true;
                } else {
                    growl.warning("ITEM_LIBRARY_REPORT.NO_CRITERIA_SET");
                    return false;
                }
            };

            $scope.onAfterStepChange = function(event) {
                return true;
            };

            $scope.updateItemsQuery = function(option) {
                $scope.report.itemsQuery = _.assignInWith($scope.report.itemsQuery, option.itemsQuery,
                    function(dstValue, srcValue) {
                        return _.isNil(srcValue) ? dstValue : srcValue;
                    });
            };

            $scope.addRecipient = function() {
                $scope.report.emailRecipients.push({
                    sendTo: "",
                    editing: true
                });
            };

            $scope.editRecipient = function(recipient) {
                recipient.editing = true;
            };
            $scope.deleteRecipient = function(recipient) {
                $scope.report.emailRecipients = _.without($scope.report.emailRecipients, recipient);
            };

            function getCurrentJob(selectionId, config) {
                var job = angular.extend($scope.planOptions.job, {
                    type: 'EXPORT',
                    selectionId: selectionId,
                    format: config.format,
                    emailRecipients: _.map(config.emailRecipients, 'sendTo')
                });
                job.schedule.active = true;
                return job;
            }

            $scope.confirmCreateReport = function(reportConfig) {
                if (!$scope.planOptions.job.name) {
                    growl.warning('ITEM_LIBRARY_REPORT.REPORT_NAME_MISSING');
                    return;
                }
                var itemSelection = {
                    itemsQuery: reportConfig.itemsQuery
                };
                SelectionResource.save(itemSelection, function(response) {
                    var job = getCurrentJob(response.selectionId, reportConfig);
                    JobResource.save(job,
                        function(response) {
                            growl.info('ITEM_LIBRARY_REPORT.CREATE_REPORT_SUCCESSFULL');
                            $scope.ok();
                        },
                        function(errorResponse) {
                            growl.error('ITEM_LIBRARY_REPORT.CREATE_REPORT_FAILED');
                        });
                });
            };

            (function init() {
                ItemsSearchFilterResource.query({}, function(response) {
                    $scope.filters = _.map(response, function(entry) {
                        return {
                            key: entry.name,
                            itemsQuery: entry.itemsQuery,
                            userId: entry.userId
                        };
                    });
                });
                ExportFormatsResource.query({
                    includeExportMappings: true
                }, function(response) {
                    $scope.mappingProfiles = _.filter(response, function(entry) {
                        return _.startsWith(entry.value, 'Excel');
                    });
                });
                JobResource.query({}, function(response) {
                    $scope.jobs = response;
                });
                $scope.categories = _.map($rootScope.dataModel.getRaw().categories, function(entry) {
                    return {
                        key: entry.name
                    };
                });
            })();
        }
    );
