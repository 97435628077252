angular.module('llax')
    .controller('errorDetailsController', ['$rootScope', '$scope', '$document', '$location', '$sce', '$modalInstance', '$translate', '$filter', '$parse', 'growl', 'row',
        function ($rootScope, $scope, $document, $location, $sce, $modalInstance, $translate, $filter, $parse, growl, row) {
            $scope.error = row;
            $scope.showDetails = false;
            $scope.userName = $rootScope.getUserSalutation($rootScope.user);
            $scope.mailToLink = "mailto:" + getSupportEmail() + "?subject=Import%20Error%3A%20" + $scope.error.errorType +
                "&body=Dear%20Support%20Team%2C%0D%0A%0D%0Athe%20following%20Error%20was%20experienced%20when%20importing%20a%20file%3A%0D%0A%0D%0A" + $scope.error.message +
                "%0D%0A" + JSON.stringify($scope.error.errorParameters) + "%0D%0A%0D%0APlease%20let%20me%20know%20how%20to%20solve%20this%20issue.%0D%0A%0D%0AKind%20Regards%0D%0A%0D%0A" + $scope.userName;

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.upgradePlan = function () {
                $modalInstance.close();
                $location.path("/profile");
            };

            //TO-DO: Implement restarting of an import
            // $scope.restartImport = function() {
            // };

            $scope.toggleDetails = function () {
                $scope.showDetails = !$scope.showDetails;
            };

            function getSupportEmail() {
                if (!_.isNil($rootScope.customSiteSupportEmail)) {
                    return $rootScope.customSiteSupportEmail;
                } else {
                    return $rootScope.systemSettings.SUPPORT_EMAIL;
                }
            }

        }
    ]);
