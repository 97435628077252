angular.module('llax')
    .controller('CatalogController',
        function($controller, $filter, $location, $log, $modal, $rootScope, $routeParams, $scope, $timeout, $window,
            SessionDataService, uiGridConstants, WatchlistService, ReactBridge, $q, ItemResource) {

            var ONLINE_CATALOG_GRID_STATES_KEY = "online-catalog-grid-states";
            var DEFAULT_GRID_STATE_KEY = "default-state";

            $controller('FetchItemController', {
                $scope: $scope
            });
            $controller('ShoppingCartController', {
                $scope: $scope
            });

            $rootScope.loadDataModel();

            $scope.page = $routeParams.page || 1;
            $scope.query = {
                category: $routeParams.category
            };
            $scope.loadingItems = true;
            $scope.selectedItem = null;
            $scope.items = [];
            $scope.currentLayout = "catalog";
            $scope.currentLayoutAttributes = [];

            var LOCAL_STORAGE_SHOW_PREVIEW_KEY = 'onlineCatalog.showItemPreview';
            $scope.previewLayout = "catalog_preview";
            $scope.previewCoverImageAttributeName = null;
            $scope.previewLayoutAttributes = [];
            $scope.showItemPreview = true;
            $scope.allItemsSelected = false;
            setItemPreviewPerLocalStorage();
            loadState();

            $scope.$on('dataModelLoaded', function() {
                initCatalogGrid();
                $scope.fetchItems();
            });

            $scope.$on('itemSearchQueryChanged', function(event, scope) {
                initCatalogGrid();
                $scope.fetchItems();
            });

            if ($routeParams.enableShoppingCart) {
                SessionDataService.put("shoppingCart", true);
                $location.search("enableShoppingCart", null);
            }

            $scope.loadMore = function() {
                if (!$scope.loadingItems && !$scope.searchFinished) {
                    $scope.page++;
                    $scope.loadingItems = true;
                    $scope.fetchItems({
                        append: true
                    });
                }
            };

            $scope.fetchItems = function(config) {
                var tableLayoutFields = $rootScope.dataModel.filteredLayoutAttributeNames($scope.currentLayout);
                var previewLayoutFields = $rootScope.dataModel.filteredLayoutAttributeNames($scope.previewLayout);
                var fields = _.union(tableLayoutFields, previewLayoutFields);
                $scope.doFetchItems(config, fields);
            };

            function initCatalogGrid() {

                var columnDefs = [];
                $scope.currentLayoutAttributes = $rootScope.dataModel.filteredLayoutAttributes($scope.currentLayout);
                for (var i = 0; i < $scope.currentLayoutAttributes.length; i++) {
                  attribute = $scope.currentLayoutAttributes[i];
                  setSectionAttributeParam(attribute, 'iconSize', '1em');
                  setSectionAttributeParam(attribute, 'valuesFormat', ['label']);
                }

                columnDefs = $scope.createCatalogTableColumnDefinition($scope.currentLayoutAttributes, true, $scope);

                $scope.gridOptions = {
                    data: 'items',
                    enableColumnMenus: false,
                    enableGridMenu: true,
                    columnDefs: columnDefs,
                    rowHeight: 34,
                    infiniteScrollDown: true,
                    rowTemplate:
                        '<div ' +
                            'data-ng-click="grid.appScope.onItemRowClick(row)"' +
                            'data-ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" ' +
                            'data-ui-grid-one-bind-id-grid="rowRenderIndex + \'-\' + col.uid + \'-cell\'" ' +
                            'class="ui-grid-cell" ' +
                            'data-ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'for-preview-selected\': grid.appScope.selectedItem === row.entity }" ' +
                            'role="{{col.isRowHeader ? \'rowheader\' : \'gridcell\'}}" ' +
                            'data-ui-grid-cell>' +
                        '</div>',
                    onRegisterApi: function(gridApi) {
                        gridApi.infiniteScroll.on.needLoadMoreData($scope, $scope.loadMore);
                        $scope.gridApi = gridApi;
                        $rootScope.initGridState($scope, $scope.gridApi, ONLINE_CATALOG_GRID_STATES_KEY, DEFAULT_GRID_STATE_KEY, columnDefs);

                        gridApi.core.on.renderingComplete($scope, function() {
                            if ($scope.selectedItem) {
                                $timeout(function() {
                                    gridApi.core.scrollTo($scope.selectedItem);
                                }, 100);
                            }
                        });

                        gridApi.selection.clearSelectedRows();
                        gridApi.selection.on.rowSelectionChanged($scope, function(row, event) {
                            if (row.isSelected) {
                                WatchlistService.addWatchlistItem(row.entity);
                            } else {
                                WatchlistService.removeWatchlistItem(row.entity);
                            }
                        });
                        gridApi.selection.on.rowSelectionChangedBatch($scope, function(rows, event) {
                            _.forEach(rows, function(row) {
                                if (row.isSelected) {
                                    WatchlistService.addWatchlistItem(row.entity);
                                } else {
                                    WatchlistService.removeWatchlistItem(row.entity);
                                }
                            });
                        });
                    }
                };
            }

            function initPreviewSection() {
                var previewLayout = $rootScope.dataModel.layout($scope.previewLayout);
                if (!_.isNil(previewLayout)) {
                    $scope.previewCoverImageAttributeName = previewLayout.sections[0].preview_image_attribute;
                    $scope.previewLayoutAttributes = $rootScope.dataModel.filteredLayoutAttributes($scope.previewLayout);
                } else {
                    $log.error("OnlineCatalog: Couldn't find a suitable preview layout: " + $scope.previewLayout);
                }
            }

            function setSectionAttributeParam(attribute, paramName, defaultValue) {
                $rootScope.setSectionAttributeParam($scope.currentLayout, null, attribute, paramName);
            }

            $scope.refreshTags = function(item) {
                for (var i = 0; i < $scope.items.length; i++) {
                    var current = $scope.items[i];
                    if (current.primaryKey__ == item.primaryKey__) {
                        $scope.items[i].tags__ = item.tags__;
                    }
                }
            };

            $scope.compareItems = function(primaryKeys) {
                var selectedItems = {};
                var itemsRequests = [];
                _.forEach(primaryKeys, function(primaryKey) {
                    var deferred = $q.defer();
                    ItemResource.get({}, {
                            'primaryKey': primaryKey
                        },
                        function(itemResult) {
                            for (var key in itemResult) {
                                // try to parse JSON like Strings into JSON Objects
                                var val = itemResult[key];
                                if (val !== undefined && typeof val == "string" && (val.startsWith("{") || val.startsWith("["))) {
                                    try {
                                        itemResult[key] = JSON.parse(val);
                                    } catch (e) {}
                                }
                            }
                            deferred.resolve(itemResult);
                        });
                    itemsRequests.push(deferred.promise);
                });
                $q.all(itemsRequests).then(function(items){
                    selectedItems = items;
                var dialog = ReactBridge.mountDialog("CompareItemsDialog", "#react-compare-items-dialog",
                {
                    items: selectedItems,
                    rootScope: $rootScope,
                    currentLayout: $scope.currentLayout,
                    onClose: function() {
                        dialog.unmount();
                    }
                });
            });
            };

            $scope.onItemRowClick = function(row) {
                $scope.selectedItem = row.entity;
                $scope.selectedItem.coverImage = $scope.getItemCoverImage($scope.selectedItem);
            };

            $scope.getItemCoverImage = function(item) {
                var image = null;
                var imageAlternativeText = null;
                if (!_.isNil($scope.previewCoverImageAttributeName)) {
                    image = item[$scope.previewCoverImageAttributeName];
                    imageAlternativeText = $scope.previewCoverImageAttributeName;
                } else {
                    // Falling back to having the first image attribute as a cover image.
                    var imageAttribute = _.find($scope.currentLayoutAttributes, function(attribute) {
                        return attribute.typeName === 'Image';
                    });
                    image = item[imageAttribute.name];
                    imageAlternativeText = imageAttribute.name;
                }

                if (!_.isNil(image)) {
                    return {
                        src: image,
                        alt: imageAlternativeText
                    };
                } else {
                    return undefined;
                }
            };

            $scope.showOrHideItemPreview = function() {
                $scope.showItemPreview = !$scope.showItemPreview;
                $window.localStorage.setItem(LOCAL_STORAGE_SHOW_PREVIEW_KEY, $scope.showItemPreview);
                $timeout(function() {
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
                }, 0);
            };

            $scope.createCatalogTableColumnDefinition = function(attributes, generateControlColumns, $scope) {

                var columnDefs = [];
                if (attributes) {
                    var catalogAttributes = attributes;

                    var length = catalogAttributes.length;
                    var attr = null;
                    for (var i = 0; i < length; i++) {
                        attr = catalogAttributes[i];
                        var col = {
                            attribute: attr,
                            name: attr.name,
                            headerTooltip: true,
                            width: '*',
                            minWidth: 120,
                            resizable: true
                        };
                        //attribute type different kinds of cell editors are set on the column
                        $rootScope.generateCellEditors(attr, col, $scope, {editable: false});
                        columnDefs[i] = col;
                    }
                    if (generateControlColumns) {

                        columnDefs.push({
                            displayName: $scope.translate('ACTIONS'),
                            headerTooltip: true,
                            name: 'actions',
                            enableCellSelection: false,
                            allowCellFocus: false,
                            cellClass: 'text-right',
                            headerCellClass: 'text-right',
                            enableColumnResizing: false,
                            enableSorting: false,
                            pinnedRight: true,
                            width: 100,
                            cellTemplate: '<div class="ui-grid-cell-contents">' +
                                '  <div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">' +
                                '    <button type="button" tabindex="-1" class="btn btn-cell action-btn-slot row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" title="{{\'ITEM.DETAIL_PAGE\' | translate}}" data-ng-click="grid.appScope.redirectToItemDetail(row.entity)"><i class="syncons syncons-item-detail"></i></button>' +
                                '    <button type="button" tabindex="-1" data-session-if="shoppingCart" class="btn btn-cell action-btn-slot row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-item-detail-btn" title="{{\'SHOPPING_CART.ADD_TO_SHOPPING_CART\' | translate}}" data-ng-click="grid.appScope.addToShoppingCart(row.entity)"><i class="syncons syncons-marketplace"></i></button>' +
                                '  </div>' +
                                '</div>'
                        });
                    }
                }
                return columnDefs;
            };

            $scope.redirectToItemDetail = function(item) {
                $scope.setOnlineCatalogState(item);
                if ($location.search().withPublicationTaskChecksums) {
                    $location.path('/items/detail/' + item.primaryKey__).search('checksum', item.checksum__);
                } else {
                    $location.path('/items/detail/' + item.primaryKey__);
                }
            };

            $scope.setOnlineCatalogState = function(item) {
                $rootScope.onlineCatalogState = {
                    cursor: $scope.cursor,
                    items: $scope.items,
                    selectedItem: item,
                    numberFound: $scope.numberFound,
                    maxNumberFound: $scope.maxNumberFound,
                    searchFinished: $scope.searchFinished,
                    query: $scope.query
                };
            };

            $scope.$on('$routeChangeStart', function($event, next, current) {
                // Delete any online catalog state if we are navigating outside
                // of online catalogs scope.
                if (next.$$route.controller != 'ItemDetailController') {
                    delete $rootScope.onlineCatalogState;
                }
            });

            function setItemPreviewPerLocalStorage() {
                if (!_.isNil($window.localStorage.getItem(LOCAL_STORAGE_SHOW_PREVIEW_KEY))) {
                    var shouldPreview = $window.localStorage.getItem(LOCAL_STORAGE_SHOW_PREVIEW_KEY);
                    try {
                        $scope.showItemPreview = JSON.parse(shouldPreview);
                    } catch(e) {
                        $log.error('OnlineCatalog: Failed to parse local storage key ' + LOCAL_STORAGE_SHOW_PREVIEW_KEY);
                    }
                }
            }

            function loadState() {
                if (!_.isNil($rootScope.onlineCatalogState)) {
                    var onlineCatalogState = $rootScope.onlineCatalogState;
                    $scope.items = onlineCatalogState.items;
                    $scope.cursor = onlineCatalogState.cursor;
                    $scope.selectedItem = onlineCatalogState.selectedItem;
                    $scope.numberFound = onlineCatalogState.numberFound;
                    $scope.maxNumberFound = onlineCatalogState.maxNumberFound;
                    $scope.searchFinished = onlineCatalogState.searchFinished;
                    $scope.query = onlineCatalogState.query;
                }
            }

            function prepareLoadedState() {
                _.forEach($scope.items, function() {
                    if (WatchlistService.hasWatchlistItems()) {
                        _.forEach(WatchlistService.getWatchlistPrimaryKeys(), function(entry) {
                            $scope.gridApi.selection.selectRow(_.find($scope.items, {primaryKey__: entry}));
                        });
                    }
                    if ($scope.allVisibleItemsSelected()) {
                        $scope.gridApi.selection.selectAllRows();
                    }
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                });
            }

            $scope.hasSelectedItems = function() {
                return $scope.allItemsSelected || WatchlistService.hasWatchlistItems();
            };

            $scope.$on('selectedItemsUpdated', function(event, items) {
                if (items === 'ALL') {
                    $scope.gridApi.selection.selectAllVisibleRows();
                    $scope.allItemsSelected = true;
                } else if (items === 'NONE') {
                    $scope.gridApi.selection.clearSelectedRows();
                    $scope.allItemsSelected = false;
                    WatchlistService.clearWatchlist();
                    if ($routeParams.selectionId) {
                        $location.search('selectionId', null);
                    }
                }
            });

            $scope.newBulkSearch = function(queryValues, queryTooLong) {
                $scope.query= "";
                $rootScope.$broadcast('buckSearchPopupOpend');
                $modal.open({
                    templateUrl: 'tpl/bulk-search.tpl.html',
                    controller: 'bulkSearchController',
                    backdrop: true,
                    windowClass: 'bulk-search-modal',
                    resolve: {
                        queryValues: function() {
                            return queryValues;
                        },
                        queryTooLong: function() {
                            return queryTooLong;
                        }
                    }
                });
            };

            $scope.showLastBulkSearch = function() {
                delete $routeParams.tags;
                delete $routeParams.taskId;
                delete $scope.query.taskId;
                $location.url($location.path() + "?tags=bulkSearch:" + $rootScope.bulkSearch.id + "&bulkSearchId=" + $rootScope.bulkSearch.id);
            };

            (function init() {

                // Load preserved state, if any, instead of doing a full fetch.
                if (!_.isNil($rootScope.onlineCatalogState)) {
                    $scope.loadingItems = false;
                    $timeout(function(){
                        prepareLoadedState();
                    }, 500);
                } else {
                    $scope.fetchItems();
                }

                initCatalogGrid();
                initPreviewSection();
            })();
        }
    );
