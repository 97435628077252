angular.module('llax')
    .controller('AssetFoldersModalCreateFolderController', function ($modalInstance, $scope, AssetFoldersResource, data, growl) {
        $scope.data = data;
        $scope.path = data.path;
        $scope.onDone = data.onDone;
        $scope.viewModel = {};
        $scope.saving = false;

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.createFolder = function() {
            $scope.saving = true;
            AssetFoldersResource($scope.path).createFolder({
                name: $scope.viewModel.name
            }, function(response) {
                growl.success('ASSET_FOLDER.UPDATED_SUCCESSFULLY');
                $scope.saving = false;
                $scope.onDone();
                $scope.cancel();
            }, function(error) {
                $scope.saving = false;
                if (error.data.message) {
                    growl.error(error.data.message);
                } else {
                    growl.error('ASSET_FOLDER.ERROR_OCCURRED');
                }
            });
        };
    });
