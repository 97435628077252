
navigator.sendBeacon = navigator.sendBeacon || function (url, data) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, false);
    xhr.send(data);
};

if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}

if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.indexOf(str, this.length - str.length) !== -1;
    };
}

String.prototype.camelCase = function(capitalizeFirstLetter) {
    var str = capitalizeFirstLetter ? this.substr(0,1).toUpperCase() + this.substr(1).toLowerCase() : this.toLowerCase();
    return str.replace(/\s+(.)/g, function(mtch, grp) {
        return grp.toUpperCase();
    });
};

Array.prototype.contains = function(obj) {
    var i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
};

Array.prototype.addToSet = function(value) {
    var index = this.indexOf(value);
    if (index == -1) {
        this.push(value);
        return true;
    } else {
        return false;
    }
};
Array.prototype.removeFromSet = function(value) {
    var index = this.indexOf(value);
    if (index > -1) {
        this.splice(index, 1);
        return true;
    } else {
        return false;
    }
};
Array.prototype.removeByContent = Array.prototype.removeFromSet;

Array.prototype.diff = function(a) {
    return this.filter(function(i) {
        return a.indexOf(i) < 0;
    });
};

if (typeof Object.keys != 'function') {
    Object.keys = function(o) {
        if (o !== Object(o)) {
            throw new TypeError('Object.keys called on a non-object');
        }
        var k = [];
        for (var p in o) {
            if (Object.prototype.hasOwnProperty.call(o, p)) {
                k.push(p);
            }
        }
        return k;
    };
}

function setCookie(name, value) {
    var c_name = diffEscape(name);
    var c_date = new Date();
    c_date.setDate(c_date.getDate() + 365);
    var c_value = diffEscape(value) + "; expires=" + c_date.toUTCString();
    document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
    var c_value = document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1) {
        c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start == -1) {
        c_value = null;
    } else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}

function applyDefaults(object, source, guard) {

    var objectTypes = {
        'boolean': false,
        'function': true,
        'object': true,
        'number': false,
        'string': false,
        'undefined': false
    };

    var index, iterable = object,
        result = iterable;
    if (!iterable) return result;
    var args = arguments,
        argsIndex = 0,
        argsLength = typeof guard == 'number' ? 2 : args.length;
    while (++argsIndex < argsLength) {
        iterable = args[argsIndex];
        if (iterable && objectTypes[typeof iterable]) {
            var ownIndex = -1,
                ownProps = objectTypes[typeof iterable] && Object.keys(iterable),
                length = ownProps ? ownProps.length : 0;

            while (++ownIndex < length) {
                index = ownProps[ownIndex];
                if (typeof result[index] == 'undefined') result[index] = iterable[index];
            }
        }
    }
    return result;
}

function lax_rest_url(s) { return "//" + window.location.host + "/api/v1/" + s; }
function lax_rest_url_escaped(s) { return "//" + window.location.host.replace(":", "\\:") + "/api/v1/" + s; }
function lax_rest_url_complete(s) { return window.location.protocol + "//" + window.location.host + "/api/v1/" + s; }
function lax_base_url_escaped(s) { return "//" + window.location.host.replace(":", "\\:") + "/" + s; }

/* BEGIN CKEDITOR placeholder functions */
function removeAllOptions(combo) {
    combo = getSelect(combo);
    while (combo.getChild(0) && combo.getChild(0).remove()) { /*jsl:pass*/
    }
}

function getSelect(obj) {
    if (obj && obj.domId && obj.getInputElement().$)        // Dialog element.
        return obj.getInputElement();
    else if (obj && obj.$)
        return obj;
    return false;
}

function addOption(combo, optionText, optionValue, documentObject, index) {
    combo = getSelect(combo);
    var oOption;
    if (documentObject)
        oOption = documentObject.createElement("OPTION");
    else
        oOption = document.createElement("OPTION");

    if (combo && oOption && oOption.getName() == 'option') {
        if (CKEDITOR.env.ie) {
            if (!isNaN(parseInt(index, 10)))
                combo.$.options.add(oOption.$, index);
            else
                combo.$.options.add(oOption.$);

            oOption.$.innerHTML = optionText.length > 0 ? optionText : '';
            oOption.$.value = optionValue;
        } else {
            if (index !== null && index < combo.getChildCount())
                combo.getChild(index < 0 ? 0 : index).insertBeforeMe(oOption);
            else
                combo.append(oOption);

            oOption.setText(optionText.length > 0 ? optionText : '');
            oOption.setValue(optionValue);
        }
    } else
        return false;

    return oOption;
}
/* END CKEDITOR placeholder functions */

function decodeHtml(html) {
    if (!html || typeof html !== 'string') {
        return html;
    } else {
        var mapObj = {
            '&lt;':"<",
            '&gt;':">"
        };
        html = html.replace(/&lt;|&gt;/gi, function(matched){
            return mapObj[matched];
        });
        return html;
    }
}
