angular.module('llax.directives')
    .directive('fileThumbnail', function($compile, $filter, $injector, $log, $q, $timeout, AssetFoldersService) {

        function checkURLWithGetRequestAsync(url, signal, onSuccess) {

            if (_.isNil(url)) {
                var deferred = $q.defer();
                deferred.resolve();
                return deferred.promise;
            }

            var baseUrl = lax_rest_url('getUrlInfo');
            var requestUrl = baseUrl + '?url=' + encodeURIComponent(url);
            return fetch(requestUrl, {
                method: 'GET',
                signal: signal
            })
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch');
                }
            })
            .then(function(data) {
                // Assuming data contains the necessary information
                var mimeType = data.contentType ? data.contentType.split(';')[0] : '';
                onSuccess(mimeType, data.url);
            })
            .catch(function(error) {
                if (error && error.status !== 0) {
                    $log.error('Error occurred:', error);
                } else {
                    $log.info('Request aborted');
                }
            });
        }

        function setPreview(contentType, url, $scope, $element) {

            var isDrawable = $filter('isFileType')({ type: contentType }, 'drawable');

            if (isDrawable) {
                $timeout(function() {
                    $scope.loading = false;
                    $scope.error = false;

                    $timeout(function() {
                        if (!_.isNil($element)) {
                            $element.find('img').on('load', function() {
                                if (!_.isNil($scope.onLoad)) {
                                    $scope.onLoad();
                                }

                                // Allow a smooth transition when we have the image
                                $timeout(function() {
                                    $element.find('img').css('opacity', 1);
                                }, 0);
                            });

                            $element.find('img').on('error', function(event, source, lineno, colno, error) {
                                $log.debug('Could not preview file in <img>', url, error);
                                this.style.display = 'none';

                                $timeout(function() {
                                    $scope.error = true;
                                }, 0);
                            });

                            $scope.dataOrUrl = url;

                        } else {
                            $scope.dataOrUrl = url;

                            if (!_.isNil($scope.onLoad)) {
                                $scope.onLoad();
                            }
                        }
                    }, 0);
                }, 0);
            } else {
                // Draw file icon
                $timeout(function() {
                    // By default, we will get the icon by mime type
                    $scope.fileIcon = AssetFoldersService.getFileIconByMimeType(contentType);
                    var isMimeType = _.includes(contentType, "/");
                    if (!isMimeType) {
                        // if not a mime type, we try with file extension
                        $scope.fileIcon = AssetFoldersService.getFileIcon(contentType);
                    }
                    $scope.loading = false;

                    // Allow a smooth transition when we have loaded the image
                    $timeout(function() {
                        $element.find('a').css('opacity', 1);
                    }, 0);

                    if (!_.isNil($scope.onLoad)) {
                        $scope.onLoad();
                    }
                }, 0);
            }
        }

        function preview(url, $scope, $element) {

            if (_.isNil(url)) {
                return;
            }

            $scope.loading = true;
            $scope.error = false;
            $scope.dataOrUrl = null;
            $scope.fileIcon = null;

            // First try to figure out the content type from the URL, if it has a file extension, then
            // provide the URL to the <img> directly, bypassing cross-origin restrictions
            var fileType = $filter('fileType')(url);
            if (!_.isEmpty(fileType)) {
                fileType = fileType.toLowerCase();
                setPreview(fileType, url, $scope, $element);
            } else {
                checkURLWithGetRequestAsync(url, $scope.requestController.signal, function(contentType, url) {
                        $scope.loading = false;
                        return setPreview(contentType, url, $scope, $element);
                    });
                }
        }

        return {
            strict: 'E',
            scope: {
                url: '=',
                onLoad: '=',
                imgClass: '@',
                iconClass: '@',
                loaderClass: '@',
                layout: '@',
                enableFilePreview: '@'
            },
            templateUrl: 'tpl/file-thumbnail.tpl.html',

            link: function(scope, element, attrs) {

                var customFilePreview = $injector.has('customFileThumbnailDirective');

                if (customFilePreview) {
                    var cssClasses = attrs.class;
                    var html = '<custom-file-thumbnail ' +
                                    'class="' + cssClasses + '" ' +
                                    'data-url="url" ' +
                                    'data-img-class="{{::imgClass}}" ' +
                                    'data-icon-class="{{::iconClass}}" ' +
                                    'data-loader-class="{{::loaderClass}}" ' +
                                    'data-default-preview="defaultPreview" ' +
                                    'data-on-load="onLoad" ' +
                                    'data-layout="{{::layout}}">' +
                                '</custom-file-thumbnail>';

                    // Attaching the defaultPreview to the custom directive scope so it can be re-used.
                    scope.defaultPreview = preview;

                    var newElement = $compile(html)(scope);
                    element.replaceWith(newElement);
                }
            },

            controller: function($element, $modal, $rootScope, $scope) {

                var customFilePreview = $injector.has('customFileThumbnailDirective');
                if (customFilePreview) {
                    // There is nothing to do in this controller, responsibility
                    // is set on the custom directive.
                    return;
                }

                $scope.dataOrUrl = null;
                $scope.fileIcon = null;
                $scope.error = false;

                $scope.requestController = new AbortController();

                preview($scope.url, $scope, $element);

                $scope.$watch('url', function(value, oldValue) {
                    var url = value;

                    if (!_.isNil(url) && value != oldValue) {
                        // Abort any previous requests in case they are still loading.
                        $scope.requestController.abort();

                        $timeout(function() {
                            // A new instance to track the fetch request.
                            $scope.requestController = new AbortController();

                            preview(url, $scope, $element);
                        }, 0);
                    }
                });

                $scope.showImage = function() {
                    $rootScope.additionalModalOpen = true;
                    $modal.open({
                        templateUrl: 'tpl/editorShowImage.tpl.html',
                        controller: 'showImageController',
                        windowClass: 'editor-show-image',
                        backdrop: true,
                        resolve: {
                            filePreviewData: function () {
                                return {
                                    url: $scope.dataOrUrl,
                                    isImage: true
                                };
                            }
                        }
                    });
                };

            }
        };
    });