angular.module('llax')
    .controller('provideLinkController', ['$rootScope', '$scope', '$document', '$modalInstance', 'CheckURLRessource', '$http', '$translate', '$filter', '$parse', 'growl', 'attribute', 'item', 'model', 'uploader', 'FileUploader', 'filePreviewData',
        function($rootScope, $scope,$document, $modalInstance, CheckURLRessource, $http, $translate, $filter, $parse, growl, attribute, item, model, uploader, FileUploader, filePreviewData) {
            $scope.a = attribute;
            $scope.item = item;
            if (_.isEqual("item[a.name]", model) || _.isEmpty(model)) {
                model = "item['" + attribute.name + "']";
            }
            var modelEval = $parse(model);
            var modelValue = modelEval($scope);
            var oldValue = angular.copy(modelValue);
            $scope.url = oldValue;
            $scope.urlError = false;

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
                $document.off('keydown', keyPressed);
            };

            $scope.enterURL = function (link) {
                //check if link is reachable
                CheckURLRessource.get({url: link},
                function(result) {
                    $scope.urlError = false;
                    if (!filePreviewData) {
                        filePreviewData = {};
                    }
                    var url = link;
                    var type = url.split('.').pop().split(/\?|#/).shift();
                    if(_.includes(url, "/image")){
                        type = 'image';
                    }
                    filePreviewData.url = url;
                    filePreviewData.isImage = false;
                    filePreviewData.path = url.slice(url.lastIndexOf('/'));
                    switch (type) {
                        case 'svg':
                            filePreviewData.contentType = "image/svg+xml";
                            if (!Modernizr.svg) {
                                filePreviewData.notSupported = true;
                            }
                            break;
                        case 'webp':
                            filePreviewData.isImage = true;
                            filePreviewData.contentType = "image/webp";
                            if (Modernizr.webp.toString() !== 'true') {
                                filePreviewData.notSupported = true;
                            }
                            break;
                        case 'tif':
                        case 'xlsx':
                        case 'xls':
                        case 'tiff':
                            filePreviewData.contentType = "image/tiff";
                            filePreviewData.notSupported = true;
                            break;
                        case 'pdf':
                            filePreviewData.contentType = "application/pdf";
                            break;
                        case "jpg":
                        case "jpeg":
                        case "gif":
                        case "png":
                        case "bmp":
                        case "tif":
                        case "tiff":
                        case "image":
                            filePreviewData.isImage = true;
                            filePreviewData.alt = $filter('fileName', filePreviewData.url);
                            break;
                        default:
                            filePreviewData.alt = $filter('fileName', filePreviewData.url);
                            break;
                    }
                    modelEval.assign($scope, $scope.url);
                    $modalInstance.close(filePreviewData);
                    $document.off('keydown', keyPressed);

                }, function(error){
                    modelEval.assign($scope, link);
                    $scope.urlError = true;
                    switch(error.status){
                        case 400:
                            growl.error("INPUT.MUST_BE_A_VALID_URL_FORMAT");
                            break;
                        default:
                            growl.error('UPLOAD.PROVIDE_LINK_ERROR');
                            break;
                    }
                    return;
                });
            };

            function keyPressed($event) {
                if ($event.keyCode == 13) {
                    $event.preventDefault();
                    $scope.enterURL($scope.url);
                }
            }
            $document.on('keydown', keyPressed);

        }]);
