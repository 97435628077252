angular.module('llax.directives')
    .directive('spreadsheetViewerWrapper', function(growl, $log) {
        return {
            restrict: 'A',
            scope: {
                fileAsArrayBuffer: '=',
                fileName: '=',
                assetsUrl: '=',
                onLoad: '=',
                onSheetChange: '=',
                onCellChange: '='
            },
            link: function ($scope, $element) {
                var spreadsheetViewerInstance = null;
                $scope.loaded = false;
                $element.context.addEventListener('activeSheetChanged', activeSheetChanged);
                load();

                function load() {
                    window.SpreadsheetViewer.SpreadsheetViewer({
                        container: $element.context,
                        assetsUrl: $scope.assetsUrl
                    })
                    .then(function(instance) {
                        instance.configure({
                            licenseKey: 'evaluation',
                            themeStylesheet: 'light'
                        });
                        instance.loadWorkbook($scope.fileAsArrayBuffer, 0, $scope.fileName);
                        exposeInstanceApi(instance);
                    })
                    .catch(function(error) {
                        growl.error('TEMPLATE_MAPPINGS.TEMPLATE_COULD_NOT_BE_LOADED');
                        $log.error(error.message);
                    });
                }

                function exposeInstanceApi(instance) {
                    instance.selectSheet = selectSheet;
                    instance.focusSpreadsheetCell = focusSpreadsheetCell;
                    spreadsheetViewerInstance = instance;
                }

                function onLoad() {
                    var iframeDocument = $element.context.children[0].contentDocument;
                    var sheets = _.map(iframeDocument.getElementsByClassName('sv-tab'), function(spreadsheetViewerTab) {
                        return spreadsheetViewerTab.innerText;
                    });
                    $scope.onLoad(spreadsheetViewerInstance, sheets);
                }

                function activeSheetChanged(event) {
                    // First fire of 'activeSheetChanged' is actually the file being loaded.
                    if (!$scope.loaded) {
                        $scope.loaded = true;

                        // We can only register a 'cellSelectionChanged' event after first load of the file.
                        $element.context.addEventListener('cellSelectionChanged', cellSelectionChanged);

                        onLoad();
                    } else {
                        $scope.onSheetChange(event);
                    }
                }

                function cellSelectionChanged(event) {
                    $scope.onCellChange(event);
                }

                function selectSheet(sheetName) {
                    var iframeDocument = $element.context.children[0].contentDocument;
                    var buttonElement = _.find(iframeDocument.getElementsByClassName('sv-tab'), function(button) {
                        return button.innerText === sheetName;
                    });

                    if (!_.isNil(buttonElement)) {
                        buttonElement.click();
                    } else {
                        $log.error('spreadsheetViewerWrapper directive: trying to change sheet `'+ sheetName + '`, but it was not found.');
                    }
                }

                function focusSpreadsheetCell(row, col) {
                    /* `this` is the Spreadsheet Instance: https://github.com/handsontable/spreadsheet-viewer/wiki/JavaScript-API */
                    this.selectCells([row, col, row, col]);
                }

                function blurSreadsheetDocument() {
                    // We can blur the whole spread sheet document by triggering a mousedown on the html element.
                    if (!_.isNil(spreadsheetViewerInstance)) {
                        var iframeDocument = $element.context.children[0].contentDocument;
                        var iframeHtml = iframeDocument.getElementsByTagName('html');
                        iframeHtml[0].dispatchEvent(new Event('mousedown'));
                    }
                }

                // We need this hack to blur the spreadsheet active elements and remove any keyboard listeners that
                // the spreadsheet might have hijacked like DELETE, BACKSPACE and ARROW KEYS, especially when we are out of the
                // spread sheet scope.
                document.addEventListener('click', blurSreadsheetDocument, true);

                $scope.$on('$destroy', function() {
                    document.removeEventListener('click', blurSreadsheetDocument, true);
                });
            }
        };
    });