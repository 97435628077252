angular.module('llax')
    .controller('CompareItemsController',
        function($rootScope, $scope, $modalInstance, $q, primaryKeys, AdditionalCategoryAttributeService, EnumAttributeService, PhysicalAttributeService, ItemResource) {

            $scope.TEMPLATE_DIMENSIONAL = 'DIMENSIONAL';
            $scope.TEMPLATE_IMAGE = 'IMAGE';
            $scope.TEMPLATE_TABLE = 'TABLE';
            $scope.TEMPLATE_TEXT = 'TEXT';

            $scope.attributes = [];
            $scope.items = {
                first: null,
                second: null
            };
            $scope.collections = {
                first: {},
                second: {}
            };
            $scope.filter = {
                showDifferencesOnly: false,
                showFilledOnly: true
            };
            $scope.loading = true;
            $scope.itemsWithDifferingCategories = false;

            function loadAdditionalSectionAttributes(layout, item) {
                var deferred = $q.defer();

                AdditionalCategoryAttributeService.loadAdditionalSectionAttributes(layout, item, function(additionalSectionAttributes) {
                    var sections = $rootScope.dataModel.filteredSections(layout, item, additionalSectionAttributes);
                    var rowLength = 0;
                    angular.forEach(sections, function(section) {
                        section.rows = [];
                        var row = [];
                        angular.forEach(section.attributes, function(attribute, index) {

                            $rootScope.setInputRenderer(attribute);
                            attribute.label = $scope.translateAttribute(attribute);

                            $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'readonly', null, $scope.item);
                            $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'labelWidth', 4, $scope.item);
                            $rootScope.setSectionAttributeParam($scope.currentLayout, section.name, attribute, 'inputWidth', 8, $scope.item);

                            attribute.isComplexType = (attribute.typeName == 'Collection' || attribute.typeName ==
                                'MultiDimensional' || attribute.typeName == 'MultiReference');
                            if (attribute.isComplexType) {
                                attribute.inputWidth += attribute.labelWidth;
                                attribute.labelWidth = 0;
                            }

                            rowLength += (attribute.labelWidth + attribute.inputWidth);
                            row.push(attribute);

                            if (rowLength >= 12) {
                                section.rows.push(row);
                                rowLength = 0;
                                row = [];
                            }

                        });
                    });
                    deferred.resolve(sections);
                });
                return deferred.promise;
            }

            $scope.getInputTemplate = function(attribute) {
                var rendererData = getAttributeRenderer(attribute);
                if (rendererData.rendererParams) {
                    attribute.rendererData.rendererParams = rendererData.rendererParams;
                }
                return rendererData.renderer;
            };

            $scope.categoryHasAttribute = function(category, attribute) {
                var categoryAttributeNames = $rootScope.dataModel.categoryAttributeNames(category);
                var found = false;
                for (var i = 0; i <= categoryAttributeNames.length; i++) {
                    if (attribute.name == categoryAttributeNames[i]) {
                        found = true;
                        break;
                    }
                }
                return found;
            };

            function isAttributeAlreadyAdded(array, attribute) {
                var added = false;
                for (var i = 0; i < array.length; i++) {
                    if (array[i].name == attribute.name) {
                        added = true;
                        break;
                    }
                }
                return added;
            }

            function mergeAttributes(sectionsA, sectionsB) {
                var attributes = [];
                var sections = (sectionsA.length > sectionsB.length) ? sectionsA : sectionsB;

                angular.forEach(sections, function(section, sectionIndex) {
                    var secA = sectionsA[sectionIndex];
                    var secB = sectionsB[sectionIndex];
                    var rows = (secA.rows.length > secB.rows.length) ? secA.rows : secB.rows;

                    angular.forEach(rows, function(row, rowIndex) {
                        angular.forEach(row, function(attr, attrIndex) {
                            var attrA = (secA.rows[rowIndex]) ? secA.rows[rowIndex][attrIndex] : undefined;
                            var attrB = (secB.rows[rowIndex]) ? secB.rows[rowIndex][attrIndex] : undefined;

                            if (attrA != undefined && !isAttributeAlreadyAdded(attributes, attrA)) {
                                attributes.push(attrA);
                            }
                            if (attrB !== undefined && !isAttributeAlreadyAdded(attributes, attrB)) {
                                attributes.push(attrB);
                            }
                        });
                    });
                });
                return attributes;
            }

            $scope.onlyDefinedCategoryAttributes = function(attr) {
                if (_.isNil($scope.items.first) || _.isNil($scope.items.second)) {
                    return false;
                }

                if (attr.additionalModule ||
                    ($scope.categoryHasAttribute($scope.items.first.category__, attr) &&
                     $scope.categoryHasAttribute($scope.items.second.category__, attr))) {
                    return true;
                }

                return false;
            };

            $scope.onlyDifferences = function(attr) {
                if (!$scope.filter.showDifferencesOnly) {
                    return true;
                }
                return $scope.isNotEqual($scope.items.first, $scope.items.second, [attr.name]);
            };

            $scope.onlyFilled = function(attr) {
                if (!$scope.filter.showFilledOnly) {
                    return true;
                }
                // Show attribute if it is filled in at least in one of the items
                return !_.isNil($scope.items.first[attr.name]) || !_.isNil($scope.items.second[attr.name]);
            };

            $scope.stringValues = {first: {}, second: {}};

            $scope.attributeToString = function(item, attribute, options, comparedItem) {
                var values;
                if (attribute.template == 'String' ||
                    attribute.template == 'Integer' ||
                    attribute.template == 'Date' ||
                    attribute.template == 'Float') {
                    $scope.stringValues[comparedItem][attribute.name] = item[attribute.name];

                } else if (attribute.template == 'Enum' || attribute.template == 'OpenEnum') {
                    EnumAttributeService.getAttributeOptionsAsync(attribute)
                        .then(function(options) {
                            var value = "";
                            if (_.isArray(options)) {
                                for (var i = 0; i < options.length; i++) {
                                    if (options[i].key == item[attribute.name]) {
                                        value = options[i].value;
                                    }
                                }
                            }
                            $scope.stringValues[comparedItem][attribute.name] = value;
                        });

                } else if (attribute.template == 'Physical') {
                    var value = item[attribute.name];
                    if (value) {
                        EnumAttributeService.getAttributeOptionsAsync(attribute)
                            .then(function(units) {
                                $scope.stringValues[comparedItem][attribute.name] = PhysicalAttributeService.formatValue(value, units);
                            });
                    } else {
                        $scope.stringValues[comparedItem][attribute.name] = "";
                    }
                } else if (attribute.template == 'Boolean') {
                    values = attribute.params.values;
                    for (var k = 0; k < values.length; k++) {
                        if (values[k].key == item[attribute.name]) {
                            if (values[k].value == 'True') {
                                $scope.stringValues[comparedItem][attribute.name] = '\u2713';
                            } else if (values[k].value == 'False') {
                                $scope.stringValues[comparedItem][attribute.name] = '\u2718';
                            } else {
                                $scope.stringValues[comparedItem][attribute.name] = values[k].value;
                            }
                        }
                    }
                } else if (attribute.template == 'Dimensional' || attribute.template == 'MultiLineDimensional') {
                    $scope.stringValues[comparedItem][attribute.name + '-' + options.key] =
                        (item[attribute.name]) ? item[attribute.name][options.key] : "";
                } else if (attribute.template == 'EnumSet' || attribute.template == 'OpenEnumSet') {
                    var result = "";
                    if (item[attribute.name] && item[attribute.name].length > 0) {
                        for (var l = 0; l < item[attribute.name].length; l++) {
                            result += item[attribute.name] + " ";
                        }
                    }
                    $scope.stringValues[comparedItem][attribute.name] = result;
                }
                else {
                    $scope.stringValues[comparedItem][attribute.name] = _.toString(item[attribute.name]);
                }
            };

            $scope.isNotEqual = function(first, second, keys) {
                return !$scope.isEqual(first, second, keys);
            };

            $scope.isEqual = function(first, second, keys) {
                if (first == second) {
                    return true;
                }
                if (_.isEmpty(first) && _.isEmpty(second)) {
                    return true;
                }
                var key = (keys || []).shift();
                if (!_.isNil(key)) {

                    if (!_.isNil(first)) {
                        first = first[key];
                    }

                    if (!_.isNil(second)) {
                        second = second[key];
                    }

                    return $scope.isEqual(first, second, keys);
                }

                return _.isEqual(first, second);
            };

            $scope.close = function() {
                $modalInstance.close();
            };

            $scope.defaults = function(value) {
                if (_.isNil(value)) {
                    return "-";
                } else {
                    return value;
                }
            };

            $scope.prepareAttributeTemplates = function(attributes) {
                _.forEach(attributes, function(attribute) {
                    switch(attribute.template) {
                        case 'Image':
                            attribute.comparisonTemplate = $scope.TEMPLATE_IMAGE;
                        break;
                        case 'Dimensional':
                        case 'MultiLineDimensional':
                            attribute.comparisonTemplate = $scope.TEMPLATE_DIMENSIONAL;
                        break;
                        case 'MultiDimensional':
                        case 'Collection':
                        case 'MultiReference':
                            attribute.comparisonTemplate = $scope.TEMPLATE_TABLE;
                            $scope.prepareTableAttribute(attribute);
                        break;
                        default:
                            attribute.comparisonTemplate = $scope.TEMPLATE_TEXT;
                    }
                });
            };

            $scope.prepareTableAttribute = function(attribute) {
                attribute.members = $rootScope.dataModel.getMemberAttributes(attribute);
                $scope.collections.first[attribute.name] = angular.copy($scope.items.first[attribute.name] || []);
                $scope.collections.second[attribute.name] = angular.copy($scope.items.second[attribute.name] || []);
                var firstItemEntriesLength = $scope.collections.first[attribute.name].length;
                var secondItemEntriesLength = $scope.collections.second[attribute.name].length;
                if (firstItemEntriesLength || secondItemEntriesLength) {
                    var maxLength = Math.max.apply(Math, [firstItemEntriesLength, secondItemEntriesLength]);

                    if (maxLength != firstItemEntriesLength) {
                        for (var k = 0; k < maxLength - firstItemEntriesLength; k++) {
                            $scope.collections.first[attribute.name].push({ });
                        }
                    }

                    // Comparing entries of the second collection with the first collection
                    for (var i = 0; i < maxLength; i++) {
                        $scope.collections.first[attribute.name][i] = $scope.collections.first[attribute.name][i] || {};
                        $scope.collections.second[attribute.name][i] = $scope.collections.second[attribute.name][i] || {};

                        var firstItemEntry = $scope.collections.first[attribute.name][i];
                        var secondItemEntry = $scope.collections.second[attribute.name][i];
                        for (var j = 0; j < attribute.members.length; j++) {
                            var memberAttribute = attribute.members[j].name;
                            var has_attr = _.isUndefined(firstItemEntry[memberAttribute]) && _.isUndefined(secondItemEntry[memberAttribute]);
                            secondItemEntry[memberAttribute] = {
                                isEqual: (has_attr || firstItemEntry[memberAttribute] === secondItemEntry[memberAttribute]),
                                val: $scope.defaults(secondItemEntry[memberAttribute])
                            };
                        }
                    }
                }
            };

            $scope.init = function() {
                var itemsRequests = [];
                _.forEach(primaryKeys, function(primaryKey) {
                    var deferred = $q.defer();
                    ItemResource.get({}, {
                            'primaryKey': primaryKey
                        },
                        function(itemResult) {
                            for (var key in itemResult) {
                                // try to parse JSON like Strings into JSON Objects
                                var val = itemResult[key];
                                if (val !== undefined && typeof val == "string" && (val.startsWith("{") || val.startsWith("["))) {
                                    try {
                                        itemResult[key] = JSON.parse(val);
                                    } catch (e) {}
                                }
                            }
                            deferred.resolve(itemResult);
                        });

                    itemsRequests.push(deferred.promise);
                });

                $q.all(itemsRequests).then(function(items){
                    $scope.items.first = items[0];
                    $scope.items.second = items[1];

                    if ($scope.items.first.category__ !== $scope.items.second.category__) {
                        $scope.itemsWithDifferingCategories = true;
                    }

                    $q.all([
                        loadAdditionalSectionAttributes('edit', $scope.items.first),
                        loadAdditionalSectionAttributes('edit', $scope.items.second)
                    ]) .then(function(itemSections) {
                        $scope.attributes = mergeAttributes(itemSections[0], itemSections[1]);
                        $scope.prepareAttributeTemplates($scope.attributes);
                        $scope.loading = false;
                    });
                });
            };

            $scope.init();
        }
    ).filter("dimensionalDiffFilter", function() {
        return function(keys, isDiffFiltering, attribute, firstItem, secondItem) {
            if (!isDiffFiltering) {
                return keys;
            }

            // Filter out keys that are equal in both items, while taking into account
            // that undefined or null values are equal to each other.
            return _.reduce(keys, function(result, keyObject) {
                var firstItemAttribute = firstItem[attribute.name];
                var secondItemAttribute = secondItem[attribute.name];
                var firstValue, secondValue;

                if (!_.isNil(firstItemAttribute)) {
                    firstValue = firstItemAttribute[keyObject.key];
                }

                if (!_.isNil(secondItemAttribute)) {
                    secondValue = secondItemAttribute[keyObject.key];
                }

                if ((!_.isNil(firstValue) || !_.isNil(secondValue)) && !_.isEqual(firstValue, secondValue)) {
                    result.push(keyObject);
                }

                return result;
            }, []);
        };
    })
    .filter("dimensionalFilledFilter", function() {
        return function(keys, isFilledOnly, attribute, firstItem, secondItem) {
            if (!isFilledOnly) {
                return keys;
            }

            var firstItemAttribute = firstItem[attribute.name];
            var secondItemAttribute = secondItem[attribute.name];
            // Filter out keys that are not filled
            return _.reduce(keys, function(result, keyObject) {

                var firstValue;
                if (!_.isNil(firstItemAttribute)) {
                    firstValue = firstItemAttribute[keyObject.key];
                }
                var secondValue;
                if (!_.isNil(secondItemAttribute)) {
                    secondValue = secondItemAttribute[keyObject.key];
                }

                if (!_.isNil(firstValue) || !_.isNil(secondValue)) {
                    result.push(keyObject);
                }

                return result;
            }, []);
        };
    });
