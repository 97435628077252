angular.module('llax')
    .service('DateTimeHelperService', function() {

        this.convertToTimestamp = function(input) {

            if (isNaN(input)) {
                // It's an ISO date string
                var dateTime = new Date(input);
                return this.getTimestampFromDate(dateTime);
            } else if (_.isDate(input)) {
                // It's a date object
                return this.getTimestampFromDate(input);
            } else {
                // We default to having the timestamp as is
                return input;
            }
        };

        // Convert a date to timestamp without seconds (last 3 digits)
        this.getTimestampFromDate = function(date) {
            date.setSeconds(0, 0);
            return Math.floor(date.getTime() / 1000);
        };

        // Convert a timestamp without seconds (last 3 digits) to a date object
        this.getDateFromTimestamp = function(timestamp) {
            timestamp = timestamp + '000';
            return new Date(_.toNumber(timestamp));
        };
    });