angular.module('llax.routes', [])
    .provider("Routes", function() {
        return {
            $get: function() {
                return [{
                    url: '/_features',
                    templateUrl: 'tpl/blank.tpl.html',
                    controller: 'FeatureToggleCtrl'
                }, {
                    url: '/admin-settings',
                    templateUrl: 'tpl/admin-settings.tpl.html',
                    controller: 'AdminSettingsController',
                    rights: ["view.adminsettings"]
                }, {
                    url: '/answer-request/:requestId',
                    templateUrl: 'tpl/request-from-supplier.tpl.html',
                    controller: 'RequestsController',
                    rights: ["view.requestsforinformation"]
                }, {
                    urls: [
                        "/browse",
                        "/compare/:firstPrimaryKey*?/:secondPrimaryKey*?"
                    ],
                    templateUrl: 'tpl/browse.tpl.html',
                    controller: 'ItemsController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    rights: ["view.browse"],
                    reloadOnSearch: false
                }, {
                    urls: [
                        "/edit/:primaryKey*?/attribute/:attribute?",
                        "/edit/:primaryKey*?/section/:section?",
                        "/edit/:primaryKey*?"
                    ],
                    templateUrl: 'tpl/browse.tpl.html',
                    controller: 'ItemsController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    //rights: ["edit.items"]
                }, {
                    urls: [
                        "/copy/:primaryKey*?",
                        "/copy/:primaryKey*?/section/:section?",
                        "/copy/:primaryKey*?/attribute/:attribute?"
                    ],
                    templateUrl: 'tpl/browse.tpl.html',
                    controller: 'ItemsController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    rights: ["copy.items"]
                }, {
                    url: "/catalog",
                    templateUrl: 'tpl/catalog.tpl.html',
                    controller: 'CatalogController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    rights: ["view.catalog"],
                    reloadOnSearch: false
                }, {
                    url: "/codelist-editor",
                    templateUrl: 'tpl/codelist_editor.tpl.html',
                    controller: 'CodelistEditorController',
                    rights: ["view.datamodelcustomization"]
                }, {
                    urls: [
                        "/communicationchannels",
                        "/communicationchannels/:id?",
                        "/communicationchannels/:id?/subscriptions"
                    ],
                    templateUrl: 'tpl/communicationchannels.tpl.html',
                    controller: 'CommunicationChannelsController',
                    rights: ["view.communicationchannels"]
                }, {
                    url: '/confirm/:key/:opaque',
                    templateUrl: 'tpl/confirm.tpl.html',
                    controller: 'ConfirmationController',
                    insecure: true
                }, {
                    url: "/contacts",
                    templateUrl: 'tpl/contacts.tpl.html',
                    controller: 'ContactsController',
                    rights: ["view.contacts"]
                }, {
                    url: '/customization',
                    templateUrl: 'tpl/datamodel-customization.tpl.html',
                    controller: 'DataModelCustomizationController',
                    rights: ["view.datamodelcustomization"]
                }, {
                    urls: [
                        "/dashboard",
                        "/"
                    ],
                    templateUrl: 'tpl/dashboard.tpl.html',
                    controller: 'DashboardController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    rights: ["view.dashboard"],
                    reloadOnSearch: false
                }, {
                    url: "/datamodelexchange",
                    templateUrl: 'tpl/datamodelexchange.tpl.html',
                    controller: 'DataModelExchangeController',
                    rights: ["view.datamodelexchange"],
                    organizationRolesDenied: ["INVITED_BUYER", "INVITED_SUPPLIER"]
                }, {
                    url: '/items/detail/:primaryKey*',
                    templateUrl: 'tpl/itemdetail.tpl.html',
                    controller: 'ItemDetailController',
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    rights: ["view.itemdetails"]
                }, {
                    url: '/exports',
                    templateUrl: 'tpl/react.tpl.html',
                    controller: ["ReactBridge", function(ReactBridge) {
                        ReactBridge.mountPage('ExportPage', '.react-node');
                    }],
                    rights: ["view.exports"]
                }, {
                    url: "/enhanced-content",
                    templateUrl: 'tpl/react.tpl.html',
                    controller: ["ReactBridge", function(ReactBridge) {
                        ReactBridge.mountPage('EnhancedContentPage', '.react-node');
                    }],
                    settingFeature: 'ENHANCED_CONTENT'
                }, {
                    url: '/key-value-maps',
                    templateUrl: 'tpl/key-value-maps.tpl.html',
                    controller: 'KeyValueMapsController',
                    rights: ["view.datamodelcustomization"]
                }, {
                    url: '/reports',
                    templateUrl: 'tpl/reports.tpl.html',
                    controller: 'ReportsController',
                    rights: ["view.exports"]
                }, {
                    url: '/login',
                    templateUrl: 'tpl/login.tpl.html',
                    controller: 'SessionsController',
                    insecure: true
                }, {
                    url: "/monitor",
                    templateUrl: 'tpl/react.tpl.html',
                    controller: ["ReactBridge", function(ReactBridge) {
                        ReactBridge.mountPage('MonitorPage', '.react-node');
                    }],
                    rights: ["view.monitoring"]
                }, {
                    url: "/orders",
                    templateUrl: 'tpl/orders.tpl.html',
                    controller: 'OrdersController',
                    rights: ["view.shoppingcart"]
                }, {
                    url: '/orders/detail/:orderId',
                    templateUrl: 'tpl/orders-detail.tpl.html',
                    controller: 'OrderController',
                    rights: ["view.shoppingcart"]
                }, {
                    url: "/organization",
                    templateUrl: 'tpl/organization.tpl.html',
                    controller: 'OrganizationsController',
                    rights: ["view.organizationdata"]
                }, {
                    url: "/profile",
                    templateUrl: 'tpl/billing-prices.tpl.html',
                    controller: 'BillingSignupController',
                    rights: ["view.billing"]
                }, {
                    url: "/profile/signupsuccess",
                    templateUrl: 'tpl/signup-callback-received.tpl.html',
                    controller: 'BillingCallbackController',
                    rights: ["view.billing"]
                }, {
                    url: "/profile/upgrade",
                    templateUrl: 'tpl/billing-signup.tpl.html',
                    controller: 'BillingSignupController',
                    rights: ["view.billing"]
                }, {
                    url: "/publications",
                    templateUrl: 'tpl/publications.tpl.html',
                    controller: 'PublicationOverviewController',
                    rights: ["publish.items"]
                }, {
                    url: '/received-requests',
                    templateUrl: 'tpl/received-requests.tpl.html',
                    controller: 'ReceivedRequestInformationsController',
                    rights: ["view.requestsforinformation"]
                }, {
                    url: '/register',
                    templateUrl: 'tpl/register/register.tpl.html',
                    controller: 'RegistrationController',
                    insecure: true
                }, {
                    url: '/request-information',
                    templateUrl: 'tpl/send-request.tpl.html',
                    controller: 'SendRequestsController',
                    rights: ["view.requestsforinformation"]
                }, {
                    url: '/revalidateAccount',
                    templateUrl: 'tpl/login.tpl.html',
                    controller: 'SessionsController',
                    insecure: true
                }, {
                    url: '/resetpassword/confirm/:key/:opaque',
                    templateUrl: 'tpl/confirm-reset-password.tpl.html',
                    controller: 'ResetPasswordController',
                    insecure: true
                }, {
                    url: '/sent-requests',
                    templateUrl: 'tpl/sent-requests.tpl.html',
                    controller: 'SentRequestsController',
                    rights: ["view.requestsforinformation"]
                }, {
                    url: "/shopping-cart",
                    templateUrl: 'tpl/shopping-cart.tpl.html',
                    controller: 'ShoppingCartController',
                    rights: ["view.shoppingcart"]
                }, {
                    url: "/support",
                    templateUrl: 'tpl/blank.tpl.html',
                    controller: 'SupportCtrl'
                }, {
                    urls: [
                        "/tasks",
                        "/tasks/new"
                    ],
                    templateUrl: 'tpl/tasklists.tpl.html',
                    controller: 'TaskListController',
                    rights: ["view.tasks"],
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    licensedFeature: 'TASKS',
                    reloadOnSearch: false
                }, {
                    url: "/tasks/edit/:taskId",
                    templateUrl: 'tpl/tasks-edit-task.tpl.html',
                    controller: 'EditTaskController',
                    rights: ["view.tasks"],
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")]
                }, {
                    url: "/upload",
                    templateUrl: 'tpl/upload.tpl.html',
                    controller: 'UploadsController',
                    rights: ["view.upload"]
                }, {
                    url: "/user",
                    templateUrl: 'tpl/user.tpl.html',
                    controller: 'UsersController',
                    rights: ["view.userdetails"]
                }, {
                    url: "/user-management",
                    templateUrl: 'tpl/user-management.tpl.html',
                    controller: 'UserManagementController',
                    rights: ["view.usermanagement"]
                }, {
                    url: "/user-groups",
                    templateUrl: 'tpl/user-groups.tpl.html',
                    controller: 'UserGroupsController',
                    rights: ["view.usermanagement"]
                }, {
                    url: "/user-group-add",
                    templateUrl: 'tpl/add-user-group.tpl.html',
                    controller: 'AddUserGroupController',
                    rights: ["view.usermanagement"]
                }, {
                    url: "/webapp/:path",
                    templateUrl: 'tpl/webapp.tpl.html',
                    controller: 'WebAppController',
                    licensedFeature: 'WEB_APPS'
                }, {
                    url: "/export-templates/:name*",
                    templateUrl: 'tpl/template-mapping/template-mapping-editor.tpl.html',
                    controller: 'TemplateMappingEditorController',
                    licensedFeature: 'EXPORT_TEMPLATES',
                    rights: ["view.exportTemplates"],
                    dependencies: ['assets/spreadsheet-viewer/client-library/clientLibrary.js']
                }, {
                    url: "/export-templates",
                    templateUrl: 'tpl/template-mapping/template-mappings-list.tpl.html',
                    controller: 'TemplateMappingListController',
                    licensedFeature: 'EXPORT_TEMPLATES',
                    rights: ["view.exportTemplates"]
                }, {
                    url: "/simple-mappings",
                    templateUrl: 'tpl/simple-mapping/simple-mappings.tpl.html',
                    controller: 'SimpleMappingsController',
                    licensedFeature: 'EXPORT_TEMPLATES',
                    rights: ["view.exportTemplates"],
                    resolve: {
                        data: function() {
                            return {};
                        },
                        $modalInstance: function() {
                            return {};
                        }
                    }
                }, {
                    url: "/asset-folders",
                    templateUrl: 'tpl/asset-folders/asset-folders-grid.tpl.html',
                    controller: 'AssetFoldersController',
                    rights: ["view.assetFolders"],
                    dependencies: [lax_rest_url("datamodel/customDirectives.js")],
                    resolve: {
                        modalParams: function() {
                            return null;
                        },
                        $modalInstance: function() {
                            return null;
                        }
                    }
                }, {
                    url: "/bulk-subscriptions",
                    templateUrl: 'tpl/react.tpl.html',
                    controller: ["ReactBridge", function(ReactBridge) {
                        ReactBridge.mountPage('BulkSubscriptionsPage', '.react-node');
                    }]
                }];
            }
        };
    });
