angular.module('llax')
    .controller('EditorContainerController', ['$rootScope', '$scope', '$routeParams', '$location', '$modal','$timeout',
        function($rootScope, $scope, $routeParams, $location, $modal, $timeout) {

            $scope.showItems = function(task) {
                if (task.items && task.items.length === 1) {
                    $scope.task = task;
                    $scope.showEditor(task.items[0], task);
                } else {
                    $location.path("/browse").search("taskId", task.id);
                }
            };

            $scope.getBrowseLink = function(task) {
                if (!task) {
                    return "";
                }
                return 'browse?taskId=' + task.id;
            };

            $scope.openBrowseLink = function(task) {
                $location.path("/browse").search("taskId", task.id);
            };

            $scope.showEditor = function(primaryKey, task) {
                var path = $location.path();
                var editor = $modal.open({
                    templateUrl: 'tpl/editor.tpl.html',
                    controller: 'EditorController',
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: 'item-editor',
                    resolve: {
                        params: function() {
                            return {
                                itemPrimaryKey: primaryKey,
                                task: task
                            };
                        }
                    }
                });
                editor.result.then(function(response) {
                    // Start the editor with another item
                    if (!_.isNil(response.showItemPrimaryKey)) {
                        $timeout(function() {
                            $scope.showEditor(response.showItemPrimaryKey,task);
                        }, 1);
                    }
                }).finally(function() {
                    $location.displayUrl(path);
                });
            };

        }
    ]);
