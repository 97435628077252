angular.module('llax')
    .controller('BuyUserLicensesController',
        function($modalInstance, $rootScope, $scope, $timeout, growl,
                 AdditionalLicenseType, BillingResource, CurrencyService, UsageLimitsResource) {

            $scope.additionalLicenseProfiles = [];
            $scope.billingAddress = {};
            $scope.orderedPlanProfile = {};
            $scope.selected = {};

            $scope.cancel = function() {
                UsageLimitsResource.get(function(response) {
                    $modalInstance.close(response.limits.USERS);
                });
            };

            $scope.getMonthlyPrice = function(profile) {
                var monthlyPrice;
                if (_.isNil(profile)) {
                    monthlyPrice = {};
                } else if (!_.isEmpty(profile.monthlyPrice)) {
                    monthlyPrice = profile.monthlyPrice;
                } else if (_.isNil(profile.monthlyPrice) && !_.isNil(profile.monthlyPriceMultiplier)) {
                    monthlyPrice = _.mapValues(_.clone($scope.orderedPlanProfile.monthlyPrice), function(value) {
                        return profile.monthlyPriceMultiplier * value;
                    });
                } else {
                    // FIXME: If no prices are available, ask to contact sales (e.g. for 'enterprise' users)!
                    monthlyPrice = {};
                }
                return monthlyPrice;
            };

            $scope.getYearlyPrice = function (profile) {
                return _.mapValues($scope.getMonthlyPrice(profile), function (value) {
                    return value * 12;
                });
            };

            $scope.executeUpgrade = function(form) {

                if (!form.payment_profile_agreement_terms.$viewValue) {
                    growl.error("Required fields missing");
                    return false;
                }

                BillingResource.addAdditionalLicense({
                    additionalLicense: $scope.selected.profile.name,
                    currency: $rootScope.currentCurrency,
                    billingAddress: $scope.billingAddress
                }, function() {
                    growl.success('USER_MANAGEMENT.LICENSES_ADDED');
                    UsageLimitsResource.get(function(response) {
                        $modalInstance.close(response.limits.USERS);
                    });
                });

            };

            $scope.validateStep = function(step, form) {
                var result = true;
                if (step.stepId === "step2" && form.$invalid) {
                    result = false;
                    growl.error("Required fields missing");
                }
                return result;
            };

            $scope.setFocus = function(step) {
                var toStepId = "step" + (step.toStepId + 1);
                var inputs = angular.element('#' + toStepId + ' :input');
                var firstInput = inputs[0];

                $timeout(function() {
                    firstInput.focus();
                }, 10);
            };

            (function init() {

                BillingResource.getOrderedPlanProfile(function(response) {
                    $scope.orderedPlanProfile = response;
                });

                BillingResource.getAdditionalLicenseProfiles({
                    licenseType: AdditionalLicenseType.ADDITIONAL_USERS
                },function(response) {
                    $scope.additionalLicenseProfiles = response;
                    if (response.length > 0) {
                        $scope.selected.profile = response[0];
                    }
                });

                BillingResource.getBillingAddress(function(response) {
                    $scope.billingAddress = response;
                });

                // FIXME: Set this in 'application-controller'!
                if (!$rootScope.currentCurrency) {
                    var country = $rootScope.user.country || 'US';
                    $rootScope.currentCurrency = CurrencyService.getCurrencyKey(country);
                }

            })();

        });
