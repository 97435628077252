angular.module('llax')
    .controller('ReceivedRequestInformationsController', ['$rootScope', '$scope', '$routeParams', '$location', '$translate',
        'ReceivedRequestResource',
        function($rootScope, $scope, $routeParams, $location, $translate, ReceivedRequestResource) {

            function loadRequests() {
                ReceivedRequestResource.query({},
                    function(requests) {
                        $scope.requests = requests.map(function(request) {
                            //create "Date" Object from Date String
                            if (request.date !== undefined) {
                                request.date = new Date(request.date);
                            }
                            return request;
                        });
                    },
                    function(response) {}
                );
            }

            loadRequests();
        }
    ]);
