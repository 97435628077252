angular.module('llax')
    .controller('UsersController', ['$rootScope', '$scope', '$routeParams', '$location', 'UserImageResource', 'SingleSignOnResource', 'UserResource', 'growl',
        function($rootScope, $scope, $routeParams, $location, UserImageResource, SingleSignOnResource, UserResource, growl) {

            $scope.hideUserIcon = false;
            $scope.passwordData = {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            };

            (function init() {
                SingleSignOnResource.get('protocol', {},
                    function (response) {
                        $scope.isSsoUser = response.protocol ? true : false;
                    });
            })();

            function prepareUserAttributes() {
              // Set renderer and translations for organization attributes
              $scope.userAttributes = $rootScope.dataModel.getUserAttributes();
              $rootScope.prepareAttributes($scope.userAttributes);
            }

            $scope.saveUser = function() {
                UserResource.save($scope.tempUser, function(response) {
                    growl.success("USER.SAVE_SUCCESS");
                    $scope.getUser().then(function() {
                        $rootScope.$broadcast('userAccountUpdated', $rootScope.user);
                    });
                }, function(response) {
                    growl.error("USER.SAVE_ERROR");
                });
            };

            $scope.createTempUser = function() {
                $scope.tempUser = angular.copy($rootScope.user);
                if ($rootScope.isDataModelLoaded) {
                    prepareUserAttributes();
                } else {
                    $rootScope.$on('dataModelLoaded', function() {
                        prepareUserAttributes();
                    });
                }
            };

            $scope.getUser = function() {
                return UserResource.get({}, function(response) {
                    $rootScope.user = $rootScope.user || {};
                    angular.extend($rootScope.user, response);
                }, function(response) {
                    console.log(response.status);
                }).$promise;
            };

            $scope.uploadUserImage = function() {
                return {
                    url: lax_rest_url('user/image'),
                    filters: ['imageFilter'],
                    accept: 'image/jpeg,image/gif,image/png',
                    queueLimit: 1,
                    cropImage: true,
                    uploadComplete: function(response) {
                        $scope.hideUserIcon = true;
                        $rootScope.$broadcast('updateUserData');
                        growl.success("UPLOAD_SUCCESS_MESSAGE");
                    }
                };
            };

            $scope.toggleUserIcon = function() {
                $scope.hideUserIcon = !$scope.hideUserIcon;
            };

            $scope.changePassword = function(passwordData) {
                if($scope.newPasswordForm.$invalid) {
                    return;
                }

                UserResource.changePassword({
                    newPassword: passwordData.newPassword,
                    oldPassword: passwordData.oldPassword
                }, function(response) {
                    growl.success("USER.CHANGE_PASSWORD_SUCCESS");
                    $scope.resetPasswordForm();
                }, function(response) {
                    if (response.status === 400) {
                        growl.error("USER.OLD_PASSWORD_WRONG");
                    } else {
                        growl.error("USER.SAVE_ERROR");
                    }
                });
            };

            $scope.resetPasswordForm = function() {
                $scope.newPasswordForm.$setPristine();
                $scope.passwordData = {};
            };

            function updateUserData() {
                $scope.getUser();
                $rootScope.loadContacts();
            }

            $scope.$on('updateUserData', function() {
                updateUserData();
            });

            $scope.$on('toggleFileUpload', function(event, uploadId) {
                if(uploadId == "user_upload") {
                    $scope.toggleUserIcon();
                }
            });

        }
    ]);
