angular.module('llax')
    .controller('BillingSignupController',
        function($rootScope, $scope, $location, $routeParams, $timeout, growl, BillingResource, OrganizationService, CurrencyService) {

            $rootScope.customer = {};
            $scope.billingAddress = {};

            $scope.selectedProfile = {
                product: $routeParams.product,
                title: $routeParams.title
            };

            $scope.validateStep = function(step, form) {
                var result = true;
                if (form.$invalid) {
                    result = false;
                    growl.error("Required fields missing");
                }
                return result;
            };

            $scope.executeUpgrade = function(form) {
                if (!form.payment_profile_agreement_terms.$viewValue) {
                    growl.error("Required fields missing");
                    return false;
                }
                BillingResource.changePlan({
                    plan: $scope.selectedProfile.product,
                    currency: $rootScope.currentCurrency,
                    billingAddress: $scope.billingAddress
                }, function(response) {
                    OrganizationService.getOrganization(true);
                    $location.path("/profile");
                },
                function(error) {
                    console.log("ERROR ", error);
                });
            };

            $scope.cancelSubscription = function() {
                var profile = $scope.config[0];
                BillingResource.changePlan({
                    plan: profile.name,
                    currency: $rootScope.currentCurrency,
                    billingAddress: $scope.billingAddress
                }, function(response) {
                    OrganizationService.getOrganization(true);
                    $location.path("/profile");
                },
                function(error) {
                    console.log("ERROR ", error);
                });
            };

            function getOrderedProfile(profiles) {
                if (!profiles) {
                    return;
                }
                return _.find(profiles, {
                    name: $scope.selectedProfile.product
                });
            }

            $scope.isActiveProfile = function(profile) {
                if (_.isUndefined($scope.organization) || _.isUndefined($scope.organization.orderedProfile)) {
                    return profile.name === "solo";
                }
                return angular.uppercase(profile.name) === angular.uppercase($scope.organization.orderedProfile);
            };

            $scope.setFocus = function(step) {
                var toStepId = "step" + (step.toStepId + 1);
                var inputs = angular.element('#' + toStepId + ' :input');
                var firstInput = inputs[0];

                $timeout(function() {
                    firstInput.focus();
                }, 10);
            };

            $scope.setCurrency = function(key) {
                $rootScope.currentCurrency = key;
            };

            (function init() {
                OrganizationService.$organization.subscribe(function(organization) {
                    $scope.organization = angular.copy(organization);
                });

                BillingResource.getPlanBillingProfiles(
                    function(response) {
                        $scope.config = response;
                        $scope.orderedProfile = getOrderedProfile($scope.config);
                    }
                );

                BillingResource.getBillingAddress(function(response) {
                    $scope.billingAddress = response;
                });

                if (!$rootScope.currentCurrency) {
                    var country = $rootScope.user.country || 'US';
                    $rootScope.currentCurrency = CurrencyService.getCurrencyKey(country);
                }
            })();
        });
