angular.module('llax')
.controller('ResetPasswordController', ['$rootScope','$scope', '$http', '$location', 'ConfirmResetPasswordResource', 'StatusResource', '$routeParams', '$route',
    function ($rootScope, $scope, $http, $location, ConfirmResetPasswordResource, StatusResource, $routeParams, $route) {

            $scope.keyValid = true;
            $scope.confirmed = false;
            $scope.status = '';

            $scope.redirectToLogin = function() {
                $location.path('/login').replace();
            };

            ConfirmResetPasswordResource.get({}, $routeParams,
                function(loginResponse) {
                    console.log("confirm-success");
                    $scope.keyValid = true;
                },
                function(response) {
                    $scope.keyValid = false;
                    console.log("confirm-failed");
                });

            $scope.confirmResetPassword = function(resetPasswordData) {
                ConfirmResetPasswordResource.save($routeParams, resetPasswordData,
                    function(response) {
                        $scope.keyValid = true;
                        $scope.confirmed = true;
                    },
                    function(response) {
                        scope.status = response.status;
                        if (response.status == '404') {
                            $scope.keyValid = false;
                        } else {
                            $scope.keyValid = true;
                        }
                    });
            };
        }
    ]);
