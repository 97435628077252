angular.module('llax')
    .controller('ExecuteEmailInvitationController',
        function($rootScope, $scope, $modalInstance, $translate, InviteByEmailResource, ContactsResource, growl, data) {

            $scope.invitationTypes = ["INVITED_BUYER", "INVITED_SUPPLIER", "INVITED_COMMUNITY_MEMBER"];
            $scope.focusOn = true;

            if (_.isUndefined(data)) {
                var invitationType = localStorage.getItem('invitationType') || "INVITED_SUPPLIER";
                $scope.invitation = {
                    invitationType: invitationType
                };
            } else {
                $scope.invitation = data;
            }

            $scope.customAttributes = $rootScope.dataModel.getContactAttributes();
            $rootScope.prepareAttributes($scope.customAttributes);

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.saveInvitation = function(invitation) {
                var invitationType = invitation.invitationType;
                if (!_.isEmpty(invitationType)) {
                    localStorage.setItem('invitationType',invitationType);
                }
                InviteByEmailResource.save(invitation,
                    function(invitationResponse) {
                        growl.success("INVITATION_SUCCESSFULLY_SENT");
                        $scope.invitationId = invitationResponse.id;
                    });
                $modalInstance.close();
            };

            $scope.saveContactRequest = function(contactRequest) {
                var contactData = {
                    id: contactRequest.id,
                    customValues: contactRequest.customValues,
                    organizationId: contactRequest.organizationId
                };
                ContactsResource.save(contactData,
                    function(contactRequestResponse) {
                        growl.success("INVITATION_SUCCESSFULLY_SENT");
                        $scope.invitationId = contactRequestResponse.id;
                    });
                $modalInstance.close();
            };
        });
