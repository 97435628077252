angular.module('llax.directives')
    .directive('closeAdButton', function(UpsellingAdsService) {
        return {
            strict: 'E',
            scope: {
                onCancel: '=',
                adId: '@',
                banner: '@'
            },
            template:
                '<button id="close-ad-button" type="button" class="close ad-close-button" ' +
                    'data-ng-class="{\'ad-close-button-banner\': banner, \'ad-close-button-popup\': !banner}" ' +
                    'data-ng-click="cancel()" data-aria-hidden="true">&times;' +
                '</button>',
            controller: function($scope) {
                $scope.cancel = function() {
                    UpsellingAdsService.setAdAsSeen($scope.adId);
                    $scope.onCancel();
                };
            }
        };
    });