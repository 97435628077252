angular.module('llax')
    .controller('SearchCategoryController',
        function($rootScope, $scope, $location) {

        $scope.queryLeafCategories = function(value, limit) {
            return $rootScope.queryCategories(value, null, null, true, limit);
        };

        $scope.searchCategory = null;

        $scope.browseCategory = function(category) {
            $location.path('/browse').search('category', category.id);
            $scope.searchCategory = null;
        };

    });
