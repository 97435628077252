angular.module('llax')
    .controller('bulkSearchController',
        function($location, $modalInstance, $rootScope, $routeParams, $scope, $timeout, $window, growl,
            BulkSearchResource, ErrorCode, queryValues, queryTooLong) {

            $scope.queryValues = queryValues;
            $scope.queryTooLong = queryTooLong;
            var LocalStorage = $window.localStorage;

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.startBulkSearch = function() {
                $modalInstance.dismiss('done');
                var date = new Date();
                var name = 'BulkSearch_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() +
                    '_' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds();
                var queryList = prepareQueryValues($scope.queryValues);
                BulkSearchResource.save({ name: name, attributeName: $scope.queryAttribute }, queryList, function(response) {
                    setCurrentBulkSearch(response);
                }, function(errorResponse) {

                    var errorCode = $rootScope.getErrorCode(errorResponse);
                    if (errorCode === ErrorCode.BULK_SEARCH_STILL_ACTIVE) {

                        var errorMessage = $rootScope.getErrorMessage(errorResponse);
                        growl.warning(errorMessage);

                        var bulkSearchId = $rootScope.getErrorParameter(errorResponse, 'activeBulkSearchId');
                        BulkSearchResource.get({
                            bulkSearchId: bulkSearchId
                        }, function(response) {
                            setCurrentBulkSearch(response);
                        });

                    } else {
                        growl.error("BULK_SEARCH.ERROR");
                    }
                });
            };

            function setCurrentBulkSearch(bulkSearch) {
                $rootScope.bulkSearchLoading = !bulkSearch.finished;
                LocalStorage.setItem("BulkSearch", JSON.stringify(bulkSearch));
                var bulksearchId = bulkSearch.id;
                $rootScope.bulkSearch = bulkSearch;
                var category = "";
                if ($routeParams.category) {
                    category = "&category=" + $routeParams.category;
                }
                $location.url($location.path() + "?tags=bulkSearch:" + bulksearchId + "&bulkSearchId=" + bulksearchId + category);
            }

            $scope.getBulkSearchAttributes = function() {
                var attributes = $rootScope.dataModel.filteredLayoutAttributes('filter');
                if (_.isEmpty(attributes)) {
                    attributes = $rootScope.dataModel.allAttributes();
                }
                return attributes;
            };

            $scope.attributeSelected = function(attribute) {
                $scope.queryAttribute = attribute.name;
            };

            $scope.queryValuesOnPaste = function(evt) {

                var clipboardData = (evt.clipboardData || evt.originalEvent.clipboardData || window.clipboardData);
                var pastedData;
                try {
                    pastedData = clipboardData.getData('Text') || clipboardData.getData('text/plain');
                } catch (e) {
                    $log.error("Could not access clipboard", e);
                    return;
                }

                pastedData = _.trim(pastedData);
                if (_.isEmpty(pastedData)) {
                    return;
                }

                var textSelectionStart = evt.originalEvent.target.selectionStart;
                var textSelectionEnd = evt.originalEvent.target.selectionEnd;

                var pre = '';
                var post = '';

                if (textSelectionStart > 0) {
                    pre = $scope.queryValues.substring(0, textSelectionStart);
                }
                if (textSelectionEnd > 0 && textSelectionEnd < $scope.queryValues.length) {
                    post = $scope.queryValues.substring(textSelectionEnd);
                }

                // entry above not working correct
                if ($scope.queryValues && textSelectionEnd == 0 && textSelectionStart == 0) {
                    post = '\n' + $scope.queryValues;
                }

                var rows = _.words(pastedData, /[^\n\r]+/g);
                var modifiedData = _.join(rows, '\n');

                // identify entry at line end?
                if ($scope.queryValues && textSelectionStart > $scope.queryValues.length) {
                    $scope.queryValues = pre + '\n' + modifiedData;
                } else {
                    $scope.queryValues = pre + modifiedData + post;
                }

                evt.originalEvent.preventDefault();
                evt.preventDefault();

                // set cursor to correct position
                $timeout(function() {
                    evt.originalEvent.target.selectionEnd = textSelectionEnd + modifiedData.length;
                    evt.originalEvent.target.selectionStart = evt.originalEvent.target.selectionEnd;
                }, 1);

            };

            prepareQueryValues = function(values) {
                return _.words(values, /([^\s,]+[^,\r\n\t]*[^\s,]+|[^\s,]+)/g);
            };

        }
    );
