angular.module('llax.services')
    .service('UpsellingAdsService', function($rootScope, $window) {

        var LocalStorage = $window.localStorage;
        var ADS_STORAGE_KEY = 'seenUpsellingAds';
        var self = this;

        this.pickRandomAd = function(ads) {
            return _.sample(ads);
        };

        /**
         * Returns the eligible ads to be shown (available, and if seen, it fulfills the business requirements to be shown).
         * FIXME: Refactor when 'Set' is available.
         *
         * The format of returned ads is as follows:
         * {
         *     "id": "string",
         *     "name": "string",
         *     "type": "POPUP | BANNER"
         * }
         */
        this.getEligibleAds = function(availableAds) {

            var seenAds = this.getSeenAds();
            var eligibleAds = _.reduce(availableAds, function(result, availableAd){
                var seenAd = self.getAdIfSeen(seenAds, availableAd.id);
                if (!_.isNil(seenAd)) {
                    var dateSeen = seenAd.date;
                    if (self.shouldShowAd(dateSeen)) {
                        result.push(availableAd);
                    }
                } else {
                    // Ad is never seen before.
                    result.push(availableAd);
                }

                return result;
            }, []);

            return eligibleAds;
        };

        this.getSeenAds = function() {

            var seenAds = LocalStorage.getItem(ADS_STORAGE_KEY);
            if (!_.isNil(seenAds)) {
                seenAds = JSON.parse(seenAds);
            } else {
                seenAds = [];
            }

            return seenAds;
        };

        this.getAdIfSeen = function(seenAds, adId) {

            var seenAd = _.find(seenAds, function(seenAd) {
                return seenAd === adId || seenAd.id === adId;
            });

            if (_.isString(seenAd)) {
                // For backward compatibility of stored seen ads, if it's a string
                // we assume that it was never seen before and it will get updated
                // down the flow when we store it again as seen.
                seenAd = {
                    id: seenAd,
                    date: null
                };
            }
            return seenAd;
        };

        this.setAdAsSeen = function(newlySeenAdId) {

            var seenAds = this.getSeenAds();
            var newlySeenAd = {
                id: newlySeenAdId,
                date: new Date(Date.now())
            };

            if (_.isNil(seenAds)) {
                seenAds = [];
            }

            var found = false;
            seenAds = _.map(seenAds, function(seenAd) {
                if (newlySeenAdId === seenAd || newlySeenAdId === seenAd.id) {
                    found = true;
                    return newlySeenAd;
                } else {
                    return seenAd;
                }
            });

            if (!found) {
                seenAds.push(newlySeenAd);
            }

            LocalStorage.setItem(ADS_STORAGE_KEY, JSON.stringify(seenAds));
        };

        /**
         * Returns `true` if an ad is eligible to be shown based on some business requirements, otherwise returns `false`.
         * For now, an eligible ad is an ad that is:
         *  1- Never seen before, i.e. lastSeenDate is null. Or,
         *  2- Its lastSeenDate exceeds `marketingCampaignsFrequencyInterval` organization/community parameter.
         */
        this.shouldShowAd = function(lastSeenDate) {

            if (_.isNil(lastSeenDate)) {
                return true;
            }

            if (_.isString(lastSeenDate)) {
                lastSeenDate = new Date(lastSeenDate);
            }

            var adsFrequencyInterval = $rootScope.organization.marketingCampaignsFrequencyInterval || 0;
            if (adsFrequencyInterval === 0) {
                return true;
            }

            var currentDate = Date.now();
            var ONE_DAY = 24 * 60 * 60 * 1000;
            var diffDays = Math.round(Math.abs((lastSeenDate - currentDate) / ONE_DAY));

            return diffDays > adsFrequencyInterval;
        };
    });