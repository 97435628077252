angular.module('llax')
    .controller('ManageDataModelsController',
        function($rootScope, $scope, $modalInstance, $translate, $dialogs, growl, DataModelTemplateResource, OrganizationService, ChannelService) {

            var organizationData = OrganizationService.getOrganizationSnapshot();

            $scope.tableColumns = [];
            $scope.uploadDatamodel = true;

            function loadDataModels() {
                $scope.datamodels = DataModelTemplateResource.getOwnTemplates();
            }
            loadDataModels();

            function loadTableColumns() {
                var tableColumns = [];
                tableColumns.push({
                    label: $translate.instant('ACTIVATEABLE'),
                    name: 'activateable'
                });
                tableColumns.push({
                    label: $translate.instant('PUBLIC'),
                    name: 'publicTemplate'
                });
                if (organizationData.communityManager) {
                    tableColumns.push({
                        label: $translate.instant('COMMUNITY'),
                        name: 'communityTemplate'
                    });
                }
                tableColumns.push({
                    label: $translate.instant('DATAMODEL_FOR_INVITEES'),
                    name: 'datamodelForInvitees'
                }, {
                    label: $translate.instant('NAME'),
                    name: 'name'
                }, {
                    label: $translate.instant('VERSION'),
                    name: 'version'
                }, {
                    name: 'updatedAt',
                    label: $translate.instant('UPDATED_AT'),
                    cellFilter: "date:'medium'"
                });
                $scope.tableColumns = tableColumns;
            }

            function createTableDefinition() {
                var tableColumns = $scope.tableColumns;
                var length = tableColumns.length;
                var columnDefs = [];
                for (var i = 0; i < length; i++) {
                    var attr = tableColumns[i];
                    var col;
                    if ( attr.name == 'communityTemplate' || attr.name == 'datamodelForInvitees') {
                        col = {
                            field: attr.name,
                            displayName: attr.label,
                            headerTooltip: true,
                            cellTooltip: true,
                            enableCellEdit: false,
                            width: '10%',
                            cellTemplate: '<div class="datamodels-checkbox-container">' +
                                '<input class="datamodels-checkbox" data-ng-disabled="row.entity.activateable===false" data-ng-model="row.entity.' + attr.name + '" type="checkbox" ' +
                                ' data-ng-checked="row.entity.' + attr.name + '" data-ng-change="grid.appScope.updateDatamodel(row.entity)">' +
                                '</div>'
                        };
                    } else if (attr.name == 'publicTemplate'){
                            col = {
                                field: attr.name,
                                displayName: attr.label,
                                headerTooltip: true,
                                cellTooltip: true,
                                enableCellEdit: false,
                                width: '10%',
                                cellTemplate: '<div class="datamodels-checkbox-container">' +
                                    '<input class="datamodels-checkbox" data-ng-disabled="!grid.appScope.organization.visible || row.entity.activateable===false" data-ng-model="row.entity.' + attr.name + '" type="checkbox" ' +
                                    ' data-ng-checked="row.entity.' + attr.name + '" data-ng-change="grid.appScope.updateDatamodel(row.entity)">' +
                                    '</div>'
                            };
                    } else if (attr.name == 'activateable'){
                            col = {
                                field: attr.name,
                                displayName: attr.label,
                                headerTooltip: true,
                                cellTooltip: true,
                                enableCellEdit: false,
                                width: '10%',
                                cellTemplate: '<div class="datamodels-checkbox-container">' +
                                    '<input class="datamodels-checkbox" data-ng-disabled="true" data-ng-model="row.entity.' + attr.name + '" type="checkbox" ' +
                                    ' data-ng-checked="row.entity.' + attr.name + '">' +
                                    '</div>'
                            };
                    } else if (attr.name == 'name') {
                        col = {
                            field: attr.name,
                            displayName: attr.label,
                            headerTooltip: true,
                            cellTooltip: true,
                            enableCellEdit: false,
                            cellTemplate: '<div class="text-center" style="margin-top: 4px;">' +
                                '<span ng-bind="grid.appScope.getLabel(row.entity)">Demo Model</span>' +
                                '<span style="font-size: smaller;">{{COL_FIELD}}</span>' +
                                '</div>'
                        };
                    } else {
                        col = {
                            field: attr.name,
                            displayName: attr.label,
                            headerTooltip: true,
                            cellTooltip: true,
                            enableCellEdit: false
                        };
                        if (attr.cellFilter) {
                            col.cellFilter = attr.cellFilter;
                        }
                    }
                    columnDefs[i] = col;
                }

                columnDefs.push({
                    displayName: $translate.instant('ACTIONS'),
                    headerTooltip: true,
                    field: 'actions',
                    width: 76,
                    cellTemplate: '<div class="text-right">' +
                        '<button type="button" class="btn btn-cell" data-ng-disabled="row.entity.activateable===false && row.entity.invalid===false" data-ng-click="grid.appScope.deleteDatamodel(row.entity)"><span class="syncons syncons-delete"></span></button>' +
                        '</div>'
                });

                $scope.getLabel = function(row) {
                    var label = "";
                    if (!_.isEmpty(row.labels)) {
                        var langKey = localStorage.getItem("languageKey");
                        label = row.labels[langKey] || row.labels[''] || row.name;
                        label = label + '   -  ';
                    }
                    return label;
                };

                $scope.columnDefs = columnDefs;
                $scope.datamodelsGridOptions = {
                    data: 'datamodels',
                    columnDefs: $scope.columnDefs,
                    enableColumnMenus: false
                };
            }

            loadTableColumns();
            createTableDefinition();

            $scope.updateDatamodel = function(datamodel) {
                DataModelTemplateResource.save(datamodel, function() {
                    growl.success("DATA_MODEL.UPDATED_SUCCESSFULLY_MESSAGE");
                    loadDataModels();
                },
                function(err) {
                    growl.error("DATA_MODEL.UPDATE_ERROR_MESSAGE");
                });
                console.log(datamodel);
            };

            $scope.close = function() {
                $modalInstance.close();
            };

            $scope.uploadFile = function() {
                return {
                    url: lax_rest_url('assets?public=true'),
                    reset: true,
                    uploadComplete: function(response) {
                        var template = response.map(function(asset) {
                            var result = {};
                            result.url = asset.url;
                            result.gathering = asset.gatheringKey;
                            result.path = asset.path;
                            result.name = asset.path.substr(1); //remove trailing /
                            return result;
                        })[0];
                        DataModelTemplateResource.save(template, function() {
                            loadDataModels();
                        });
                        growl.success("UPLOAD_SUCCESS_MESSAGE");
                    }
                };
            };

            $scope.deleteDatamodel = function(datamodel) {
                var message = $translate.instant('DATAMODEL.DELETE');
                var confirmDialog = $dialogs.confirm('', message);
                confirmDialog.result.then(function() {
                    DataModelTemplateResource.remove({
                        id: datamodel.id
                    }, function() {
                        loadDataModels();
                    });
                });
            };

            $scope.$on('laxGridStopCellEdit', function (event, row, col) {
                $scope.updateDatamodel(row.entity);
            });

            $scope.$on('channelMessageReceived', function(event, eventData) {
                if (eventData.event === ChannelService.DATA_MODEL_ACTIVATEABLE_EVENT) {
                    growl.success("DATA_MODEL_TEMPLATE_ACTIVATED_SUCCESS", {
                            variables: { templatename: eventData.data.name },
                            ttl: 3000
                        });

                    loadDataModels();
                } else if (eventData.event === ChannelService.DATA_MODEL_TEMPLATE_INVALID_EVENT) {
                    growl.error("DATA_MODEL_TEMPLATE_ACTIVATED_FAILURE" , {
                        variables: { templatename: eventData.data.name, errorMessage: eventData.data.error}
                    });

                    loadDataModels();
                }
            });
        }
    );
