angular.module('llax')
.controller('ConfirmationController', ['$scope', '$routeParams', 'ConfirmResource', '$location', '$route', '$timeout' ,
    function($scope, $routeParams, ConfirmResource, $location, $route,  $timeout) {

            ConfirmResource.save({
                key: $routeParams.key,
                opaque: $routeParams.opaque
            }).$promise.then(function() {
                $scope.confirmedSuccessfully = true;

                $timeout(function() {
                    $location.path('/login').replace();
                    $route.reload();
                }, 4000);

            }, function(response) {
                //error Response
                $scope.errorInConfirmation = true;
                $scope.status = response.status;
            });

        }
    ]);
