angular.module('llax')
.controller('EditorViewController', ['$rootScope', '$scope', 'growl', '$dialogs', '$translate', '$window',
    function($rootScope, $scope, growl, $dialogs, $translate, $window) {

            $scope.view = {
                'userId': $scope.user.id,
                'name': ''
            };

            $scope.userViews = [];
            $scope.currentUserViews = [];
            var LocalStorage = $window.localStorage;

            $scope.disabledSections = [];
            $scope.disabledAttributes = [];
            $scope.isAllAttributesDisabled = false;
            $scope.currentlySelectedView = null;

            $scope.onAddView = false;
            $scope.onEditView = false;
            $scope.onEnterViewName = false;
            $scope.onViewSelected = false;

            $scope.loadUserViews = function() {
                if (LocalStorage.getItem("userviews")) {
                    var currentUserViews = [];
                    $scope.userViews = JSON.parse(LocalStorage.getItem("userviews"));
                    if ($scope.userViews.length > 0) {
                        for (i = 0; i < $scope.userViews.length; i++) {
                            if ($scope.userViews[i].userId == $scope.user.id) {
                                currentUserViews.push($scope.userViews[i]);
                            }
                        }
                    }
                    $scope.currentUserViews = currentUserViews;
                } else {
                    LocalStorage.setItem("userviews", JSON.stringify($scope.userViews));
                }
            };

            $scope.loadUserViews();

            $scope.saveCurrentView = function(currentView) {
                LocalStorage.setItem("currentView", JSON.stringify(currentView));
            };

            $scope.loadCurrentView = function() {
                var currentView = JSON.parse(LocalStorage.getItem("currentView"));
                if (currentView) {
                    if (currentView.userId != $scope.user.id) {
                        resetView();
                    } else {
                        $scope.currentlySelectedView = currentView;
                        $scope.viewSelected = true;
                        $scope.disabledAttributes = currentView.fields;
                    }
                }
            };

            $scope.loadCurrentView();

            $scope.showSettings = function() {
                $scope.onShowSettings = ($scope.onShowSettings) ? false : true;
            };

            $scope.createView = function() {
                $scope.onAddView = ($scope.onAddView) ? false : true;
                $scope.onShowSettings = false;
                $scope.disabledAttributes = [];
                $scope.viewSelected = false;
            };

            $scope.editView = function(view) {
                $scope.disabledAttributes = angular.copy(view.fields);
                if ($scope.onEditView == false) {
                    $scope.onEditView = true;
                }
                $scope.currentlyEditedView = view;
                $scope.onShowSettings = false;
            };

            $scope.enterViewName = function() {
                $scope.onEnterViewName = ($scope.onEnterViewName) ? false : true;
            };

            $scope.selectView = function(view) {
                $scope.disabledAttributes = view.fields;
                $scope.currentlySelectedView = view;
                $scope.viewSelected = true;
                $scope.onShowSettings = false;

                $scope.saveCurrentView(view);
            };

            $scope.resetView = function() {
                LocalStorage.removeItem("currentView");
                $scope.disabledAttributes = [];
                $scope.currentlySelectedView = null;
                $scope.viewSelected = false;
                $scope.onShowSettings = false;
            };

            $scope.cancelView = function() {
                if ($scope.onEnterViewName) {
                    $scope.onEnterViewName = false;
                    $scope.view.name = "";
                } else {
                    if (!$scope.viewSelected) {
                        $scope.disabledAttributes = [];
                    }
                    $scope.onAddView = false;
                    $scope.onEditView = false;
                }
            };

            $scope.saveView = function(name) {
                var isNew = !name;
                name = name || $scope.view.name;
                var attributes = $scope.disabledAttributes;
                var newAttributes = [];

                for (var i = 0; i < attributes.length; i++) {
                    newAttributes.push(attributes[i]);
                }

                var view = {
                    'userId': $scope.user.id,
                    'name': name,
                    'fields': newAttributes
                };

                $scope.onAddView = false;
                $scope.onEditView = false;
                $scope.onEnterViewName = false;
                $scope.view.name = "";
                $scope.isAllAttributesDisabled = false;
                $scope.disabledAttributes = [];
                $scope.disableSections = [];
                if (isNew || ($scope.currentlySelectedView && $scope.currentlySelectedView.name == name)) {
                    $scope.selectView(view);
                }
                saveView(view, isNew);
            };

            $scope.deleteView = function(view) {
                var message = $translate.instant('VIEW.CONFIRM_DELETE_VIEW');
                var confirmDialog = $dialogs.confirm('', message);
                confirmDialog.result.then(function() {
                    deleteView(view);
                    $scope.onEditView = false;
                    if ($scope.currentlySelectedView && $scope.currentlySelectedView.name == name) {
                        resetView();
                    }
                });
            };

            function deleteView(view) {
                var idx = $scope.userViews.indexOf(view);
                var idxCurrent = $scope.currentUserViews.indexOf(view);
                if (idx > -1) {
                    $scope.userViews.splice(idx, 1);
                    $scope.currentUserViews.splice(idxCurrent, 1);
                    LocalStorage.setItem("userviews", JSON.stringify($scope.userViews));
                }
                $scope.currentlyEditedView = null;
            }

            function saveView(view, isNew) {
                if (isNew) {
                    $scope.currentUserViews.push(view);
                    $scope.userViews.push(view);
                } else {
                    var userView = _.find($scope.userViews, {name:view.name});
                    userView.fields = view.fields;
                }
                if ($scope.viewSelected) {
                    $scope.disabledAttributes = angular.copy(view.fields);
                }
                LocalStorage.setItem("userviews", JSON.stringify($scope.userViews));
                $scope.currentlyEditedView = null;
            }

            function resetView() {
                LocalStorage.removeItem("currentView");
                $scope.disabledAttributes = [];
                $scope.currentlySelectedView = null;
                $scope.viewSelected = false;
                $scope.onShowSettings = false;
            }

            $scope.toggleSelection = function(attribute) {
                var idx = $scope.disabledAttributes.indexOf(attribute.name);
                if (idx > -1) {
                    $scope.disabledAttributes.splice(idx, 1);
                } else {
                    $scope.disabledAttributes.push(attribute.name);
                }
            };

            $scope.isAttributeVisible = function(attribute) {
                if ($scope.onAddView || $scope.onEditView) {
                    return true;
                }
                return _.isEmpty($scope.disabledAttributes) ||
                    !_.includes($scope.disabledAttributes, (_.isObject(attribute) ? attribute.name : attribute));
            };

            $scope.disableAllAttributes = function() {
                if ($scope.isAllAttributesDisabled) {
                    $scope.disabledAttributes = [];
                    $scope.disabledSections = [];
                } else {
                    var sections = $scope.sections;
                    for (var i = 0; i < sections.length; i++) {
                        var attributes = sections[i].attributes;
                        for (var j = 0; j < attributes.length; j++) {
                            if ($scope.disabledAttributes.indexOf(attributes[j].name) == -1) {
                                $scope.disabledAttributes.push(attributes[j].name);
                            }
                        }
                        if ($scope.disabledSections.indexOf(sections[i].name) == -1) {
                            $scope.disabledSections.push(sections[i].name);
                        }
                    }
                }
                $scope.isAllAttributesDisabled = !$scope.isAllAttributesDisabled;
            };

            $scope.disableSection = function(section) {
                var sections = $scope.sections;
                var attributes = [];
                if ($scope.disabledSections.indexOf(section.name) > -1) {
                    for (var i = 0; i < sections.length; i++) {
                        if (section.name == sections[i].name) {
                            attributes = sections[i].attributes;
                            var index = -1;
                            for (var j = 0; j < attributes.length; j++) {
                                index = $scope.disabledAttributes.indexOf(attributes[j].name);
                                $scope.disabledAttributes.splice(index, 1);
                            }
                            index = $scope.disabledSections.indexOf(sections[i].name);
                            $scope.disabledSections.splice(index, 1);
                            break;
                        }
                    }
                } else {
                    for (var k = 0; k < sections.length; k++) {
                        if (section.name == sections[k].name) {
                            attributes = sections[k].attributes;
                            for (var l = 0; l < attributes.length; l++) {
                                if ($scope.disabledAttributes.indexOf(attributes[l].name) == -1) {
                                    $scope.disabledAttributes.push(attributes[l].name);
                                }
                            }
                            if ($scope.disabledSections.indexOf(sections[k].name) == -1) {
                                $scope.disabledSections.push(sections[k].name);
                            }
                            break;
                        }
                    }
                }
            };
        }
    ]);
