angular.module('llax.translations', ['llax.services'])
    .constant('TranslationConfig', {
        'DEFAULT_LANGUAGE': 'en',
        'DEFAULT_LOCALE': 'en-iso',
        'DEFAULT_SUPPORTED_LANGUAGES': [{
            key: 'en',
            translated: 'English'
        }],
        'LANGUAGE_KEY': 'languageKey'
    })
    .config(function($translateProvider, $windowProvider, TranslationConfig, tmhDynamicLocaleProvider) {

        $translateProvider
            .useLoader('translationLoader')
            .useSanitizeValueStrategy('escapeParameters')
            .addInterpolation('$translateMessageFormatInterpolation')
            .uniformLanguageTag('java')
            .preferredLanguage(TranslationConfig.DEFAULT_LANGUAGE)
            .determinePreferredLanguage(function() {
                var storedLanguage = _.get($windowProvider.$get().localStorage, TranslationConfig.LANGUAGE_KEY);

                // Local storage stores the values in a serialized manner so a null is actually a 'null'
                if (_.isNil(storedLanguage) || storedLanguage === 'null') {
                    return $translateProvider.resolveClientLocale();
                } else {
                    return storedLanguage;
                }
            });

//        tmhDynamicLocaleProvider.localeLocationPattern('/assets/angular-i18n/js/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.localeLocationPattern('/assets/i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.defaultLocale(TranslationConfig.DEFAULT_LOCALE);

    })
    .factory('translationLoader', function($log, $q, $rootScope, LocalCacheService, OpenETagLoader, TranslationResource, $window) {
        return function(options) {
            var storage = $window.localStorage;
            var deferred = $q.defer();

            var languageKey = options.key;
            var KEY_TRANSLATION_TAG = 'translationsTag_';
            var translationsTagKey = KEY_TRANSLATION_TAG + languageKey;

            var translationLoader = new OpenETagLoader();
            LocalCacheService.waitForDBInitialization().then(function() {
                translationLoader
                .loadDataByResource(TranslationResource, { languageKey: languageKey },
                                    getTagFunctionAsync,
                                    getDataFunctionAsync,
                                    setTranslations,
                                    restoreTranslations,
                                    errorTranslations);
            });

            function setTranslations(tag, map, loader) {

                clearAllTranslationsTag();

                LocalCacheService.clearAllByStoreNameAsync(LocalCacheService.STORES.translations.objectStoreName)
                    .then(function () {
                        return LocalCacheService.insertEntryAsync(LocalCacheService.STORES.translations.objectStoreName,
                                                                  languageKey,
                                                                  { languageKey: languageKey, map: map });
                    })
                    .then(function () {
                        $rootScope.requestedLanguage = languageKey;
                        $rootScope.loadedLanguage = map.languageCode;
                        $rootScope.language = map.languageCode;

                        $log.info("Replaced entries in store '%s' with tag '%s': %o", LocalCacheService.STORES.translations.objectStoreName, tag, map);
                        deferred.resolve(map.translations);
                        $rootScope.setTranslationsAsLoaded();
                        storage.setItem(translationsTagKey, tag);
                        map = map.translations;
                    })
                    .catch(function (error) {
                        $log.error("Error during IndexedDB operations:", error);
                        deferred.reject(error);
                    });
            }

            function restoreTranslations(tag, map, loader) {
                $rootScope.setTranslationsAsLoaded();
                $rootScope.language = languageKey;
                $rootScope.loadedLanguage = map.languageCode;
                $log.info("Restored translations for language '%s' with tag '%s' from LocalCacheService: %o", languageKey, tag, map);
                if (!_.isNil(map.map.translations)) {
                    deferred.resolve(map.map.translations);
                } else {
                    deferred.resolve(map);
                }
            }

            function errorTranslations(errorReason, loader) {
                LocalCacheService.deleteEntriesByIndexAsync(LocalCacheService.STORES.translations.objectStoreName,
                                                            LocalCacheService.STORES.translations.key,
                                                            languageKey)
                    .then(function () {
                        deferred.reject(errorReason);
                    });
            }
            function clearAllTranslationsTag() {
                var removableKeys = [];
                for (var keyIndex = 0; keyIndex < storage.length; keyIndex++){
                    var key = storage.key(keyIndex);
                    if (storage.hasOwnProperty(key)) {
                        if (key.match(KEY_TRANSLATION_TAG)) {
                            $log.info("Removing unused translation local storage key: '%s'", key);
                            removableKeys.push(key);
                        }
                    }
                }

                for (var removableKeyIndex = 0; removableKeyIndex < removableKeys.length; removableKeyIndex++) {
                    localStorage.removeItem(removableKeys[removableKeyIndex]);
                }
            }

            function getTagFunctionAsync() {
                var tag = storage.getItem(translationsTagKey);
                return $q.when(tag);
            }

            function getDataFunctionAsync() {
                var storeName = LocalCacheService.STORES.translations.objectStoreName;
                var _languageKey = [languageKey];
                return LocalCacheService.getEntryAsync(storeName, _languageKey);
            }

            return deferred.promise;
        };
    })
    .run(function($http, $log, $rootScope, $timeout, $translate, $window, TranslationConfig, tmhDynamicLocale) {

        var LocalStorage = $window.localStorage;
        var translationsCache = {};
        var translationsLoaded = false;

        $rootScope.requestedLanguage = null;
        $rootScope.loadedLanguage = null;

        $rootScope.supportedLanguages = TranslationConfig.DEFAULT_SUPPORTED_LANGUAGES;
        $rootScope.refreshSupportedLocales = function() {
            $http.get(lax_rest_url('supportedLocales')).then(function(response) {
                var supportedLanguages = [];
                _.forEach(response.data, function(translated, key) {
                    supportedLanguages.push({
                      key: key,
                      translated: translated
                  });
              });
              $rootScope.supportedLanguages = supportedLanguages;
            });
        };
        $rootScope.refreshSupportedLocales();

        $rootScope.checkTranslationsLoaded = function() {
            return translationsLoaded;
        };

        $rootScope.refreshTranslations = function() {
            $timeout(function() {
                // Block the UI until we refresh the translations
                translationsLoaded = false;
                $translate.refresh();
            }, 0);
        };

        $rootScope.setTranslationsAsLoaded = function() {
            translationsLoaded = true;
        };

        $rootScope.changeLanguage = function(language) {

            if (!translationsLoaded || language == $rootScope.requestedLanguage) {
                return;
            }

            $log.info("User changed language to '%s'", language);
            LocalStorage.setItem(TranslationConfig.LANGUAGE_KEY, language);

            // Setting the language in the local storage and reloading the window
            // will trigger '$translate.onReady' to set the correct language
            $timeout(function() {
                window.location.reload();
            }, 1);

        };

        $translate.onReady(function() {

            var storedLanguage = LocalStorage.getItem(TranslationConfig.LANGUAGE_KEY);
            var requestedLanguage = $rootScope.requestedLanguage;
            var loadedLanguage = $rootScope.loadedLanguage;

            // When $translate service is ready, check the 'stored' language.
            // If it does not match either the requested or the loaded language,
            // explicitly use the 'stored' language in $translate
            if (!_.isNil(storedLanguage) && storedLanguage !== 'null' && storedLanguage !== requestedLanguage && storedLanguage !== loadedLanguage) {
                $timeout(function() {
                    useLanguage(storedLanguage);
                }, 1);
            } else {
                setLanguage(loadedLanguage, requestedLanguage);
            }

        });

        function useLanguage(language) {
            return $translate.use(language).then(function(usedLanguage) {
                setLanguage(usedLanguage);
            });
        }

        function setLanguage(loadedLanguage, requestedLanguage) {

            translationsCache = {};
            translationsLoaded = true;

            $rootScope.language = loadedLanguage;
            $rootScope.lookupLanguages = getLookupLanguages($rootScope.language, TranslationConfig.DEFAULT_LANGUAGE);
            setLocale(requestedLanguage || loadedLanguage);
            LocalStorage.setItem(TranslationConfig.LANGUAGE_KEY, requestedLanguage || loadedLanguage);

        }

        // Set i18n locale for angular filters, like date and numbers
        function setLocale(locale) {

            var localeParts = getLocaleParts(locale);
            if (_.isNil(localeParts.country) && !_.isNil(localeParts.defaultCountry)) {
                locale = localeParts.language + '-' + localeParts.defaultCountry;
            } else {
                locale = localeParts.locale;
            }
            tmhDynamicLocale.set(locale);

        }

        function getLocaleParts(locale) {

            locale = _.replace(_.toLower(locale), '_', '-');
            var split = _.split(locale, '-');
            var language = split[0];
            var country = split[1];

            var defaultLocale = $translate.resolveClientLocale();
            defaultLocale = _.replace(_.toLower(defaultLocale), '_', '-');
            var defaultSplit = _.split(defaultLocale, '-');
            var defaultLanguage = defaultSplit[0];
            var defaultCountry = defaultSplit[1];

            return {
                locale: locale,
                language: language,
                country: country,
                defaultLocale: defaultLocale,
                defaultLanguage: defaultLanguage,
                defaultCountry: defaultCountry
            };
        }

        function getLookupLanguages(lookupLanguage, defaultLanguage) {

            var lookupLanguages = [];

            // Update language and default language to conform the Java locale format (<language>_<country-region>)
            lookupLanguage = _.replace(lookupLanguage, '-', '_');
            defaultLanguage = _.replace(defaultLanguage, '-', '_');

            var split = _.split(lookupLanguage, '_');
            var language = split[0];
            var country = split[1];
            var variant = split[2];

            // Add language itself
            if (!_.isEmpty(lookupLanguage)) {
                lookupLanguages.push(lookupLanguage);
            }

            // Add language without variant, if possible
            if (!_.isEmpty(variant)) {
                lookupLanguages.push(language + '_' + country);
            }

            // Add language without country, if possible
            if (!_.isEmpty(country)) {
                lookupLanguages.push(language);
            }

            // Add default language, if necessary
            if (!_.includes(lookupLanguages, defaultLanguage)) {
                lookupLanguages.push(defaultLanguage);
            }

            return lookupLanguages;
        }

        $rootScope.$on('$localeChangeError', function(event, errorLocale) {

            var alternativeLocale;
            var localeParts = getLocaleParts(errorLocale);

            if (localeParts.language === TranslationConfig.DEFAULT_LANGUAGE) {
                if (errorLocale !== TranslationConfig.DEFAULT_LOCALE) {
                    alternativeLocale = TranslationConfig.DEFAULT_LOCALE;
                }
            } else {
                if (_.isNil(localeParts.country) && !_.isNil(localeParts.defaultCountry)) {
                    alternativeLocale = TranslationConfig.DEFAULT_LANGUAGE + '-' + localeParts.defaultCountry;
                } else if (!_.isNil(localeParts.country)) {
                    alternativeLocale = localeParts.language;
                } else {
                    alternativeLocale = TranslationConfig.DEFAULT_LOCALE;
                }
            }

            if (alternativeLocale) {
                $log.info("Locale definition for '%s' does not exist; Trying locale '%s'", errorLocale, alternativeLocale);
                tmhDynamicLocale.set(alternativeLocale);

            } else {
                $log.error("Could not activate locale '%s'", errorLocale);
            }

        });

        /**
         * Returns the translation for the specified key. If no translation was found,
         * returns 'defaultValue', if specified, else the key itself.
         * Uses 'interpolateParams' for interpolation.
         */
        $rootScope.translate = function(key, defaultValue, interpolateParams, interpolationType) {
            var result = _translate(key, interpolateParams, interpolationType);
            if (result === key && !_.isUndefined(defaultValue)) {
                result = defaultValue;
            }
            return result;
        };

        /**
         * Returns the translation for a key which is created by prepending 'prefix'
         * to 'value'. If no translation was found, returns 'defaultValue',
         * if specified, else 'value'.
         */
        $rootScope.translateValue = function(prefix, value, defaultValue) {
            var key = prefix + value;
            defaultValue = defaultValue || value;
            return $rootScope.translate(key, defaultValue);
        };

        function getTranslationFromObject(object, type, defaultValueFn) {

            // Ignore non-objects or 'empty' objects
            if (!_.isObject(object) || _.isEmpty(object)) {
                return defaultValueFn(object);
            }

            var result;

            // Try translations from translation map first
            var translationMap = _.get(object, type + 's');

            if (!_.isEmpty(translationMap)) {

                // Find the first non empty translation using the lookup languages hierarchy
                _.forEach($rootScope.lookupLanguages, function(lookupLanguage) {
                    result = _.get(translationMap, lookupLanguage);
                    if (!_.isEmpty(result)) {
                        return false;
                    }
                });

            }

            // Use default label/icon or key
            result = result || defaultValueFn(object);

            return result;
        }

        function getDefaultAttributeLabel(attribute) {
            if (angular.isObject(attribute)) {
                return $rootScope.translateValue("attribute.", attribute.name, attribute.label);
            } else {
                return $rootScope.translateValue("attribute.", attribute);
            }
        }

        $rootScope.translateAttribute = function(attribute) {
            return getTranslationFromObject(attribute, 'label', getDefaultAttributeLabel);
        };

        function getDefaultAttributeDescription(attribute) {
            var attributeName;
            var defaultDescription;
            if (angular.isObject(attribute)) {
                attributeName = attribute.name;
                defaultDescription = attribute.description;
            } else {
                attributeName = attribute;
                defaultDescription = null;
            }
            var key = "attribute." + attributeName + ".description";
            var result = $rootScope.translate(key, defaultDescription);
            return key != result ? result : null;
        }

        $rootScope.translateAttributeDescription = function(attribute) {
            return getTranslationFromObject(attribute, 'description', getDefaultAttributeDescription);
        };

        $rootScope.translateAllCategories = function(categories) {
            _.forEach(categories, function(category) {
                category.translatedLabel = category.translatedLabel || $rootScope.translateCategory(category);
            });
            return categories;
        };

        $rootScope.translateCategory = function(category) {
            if (angular.isObject(category)) {
                return $rootScope.translateValue("category.", category.name, category.label);
            } else {
                return $rootScope.translateValue("category.", category);
            }
        };

        $rootScope.translateHierarchy = function(hierarchy) {
            if (angular.isObject(hierarchy)) {
                return $rootScope.translateValue("hierarchy.", hierarchy.name, hierarchy.label);
            } else {
                return $rootScope.translateValue("hierarchy.", hierarchy);
            }
        };

        $rootScope.translateAllSections = function(sections) {
            _.forEach(sections, function(section) {
                section.translatedLabel = section.translatedLabel || $rootScope.translateSection(section);
            });
            return sections;
        };

        $rootScope.translateSection = function(section) {
            if (angular.isObject(section)) {
                return $rootScope.translateValue("section.", section.name, section.label);
            } else {
                return $rootScope.translateValue("section.", section);
            }
        };

        $rootScope.translateWebApp = function(webApp) {
            return $rootScope.translateValue("webapp.", webApp, webApp);
        };

        $rootScope.getTranslatedOptions = function(attribute) {
            attribute.translatedOptions = attribute.translatedOptions || _getTranslatedOptions(attribute);
            return attribute.translatedOptions;
        };

        function _getTranslatedOptions(attribute) {
            var options = $rootScope.dataModel.getAttributeOptions(attribute);
            var result = _.map(options, function(option) {
               return {
                    key: option.key,
                    value: $rootScope.translateOption(option, attribute)
               };
            });
            return result;
        }

        $rootScope.translateOption = function(option, attribute, defaultValueFn) {
            return _translateOption(option, attribute, 'option', defaultValueFn || getDefaultOptionValue);
        };
        $rootScope.translatePhysical = $rootScope.translateOption;

        $rootScope.translateOptionIcon = function(option, attribute) {
            return _translateOption(option, attribute, 'icon', getDefaultOptionIcon);
        };

        function getDefaultOptionValue(option, options) {
            if (_.isObject(option)) {
                return _.get(option, 'value', _.get(option, 'key', ""));
            } else if (_.isArray(options)) {
                return findValueForKey(option, options);
            } else {
                return _.toString(option);
            }
        }

        function getDefaultOptionIcon(option) {
            if (_.isObject(option)) {
                return _.get(option, 'icon', null);
            } else {
                return null;
            }
        }

        function _translateOption(option, attribute, type, defaultValueFn) {

            // Ignore 'empty' options
            if (_.isEmpty(option)) {
                return defaultValueFn(option);
            }

            var result;

            // Take the translation defined on the option itself, if possible
            var propertyPrefix = type == 'option' ? 'label' : type;
            if (_.has(option, 'serviceName') || _.has(option, propertyPrefix + 's') || _.has(option, propertyPrefix) || _.has(option.value, 'labels')) {

                // Try translations from translation map first
                var translationMap = (angular.isObject(option.value) && _.has(option.value, 'labels'))?
                                         _.get(option.value, 'labels'):
                                         _.get(option, propertyPrefix + 's');

                if (!_.isEmpty(translationMap)) {
                    // Find the first non empty translation using the lookup languages hierarchy
                    _.forEach($rootScope.lookupLanguages, function(lookupLanguage) {
                        result = _.get(translationMap, lookupLanguage);
                        if (!_.isEmpty(result)) {
                            return false;
                        }
                    });

                }

                // Use default label/icon or key
                result = result || _.get(option, propertyPrefix) || defaultValueFn(option);

                return result;
            }

            // Ensure, attribute is a data model defined object
            if (_.isEmpty(attribute)) {
                return defaultValueFn(option);
            }
            if (_.isString(attribute)) {
                attribute = $rootScope.dataModel.attribute(attribute);
                if (!_.isObject(attribute)) {
                    return defaultValueFn(option);
                } else {
                    return _translateOption(option, attribute, type, defaultValueFn);
                }
            }

            var attributeName = attribute.name;
            var optionKey = _.isObject(option) ? option.key : option;

            var key;

            // Try translation by attribute
            key = "attribute." + attributeName + "." + type + "." + optionKey;
            result = $rootScope.translate(key);
            if (result !== key) {
                return result;
            }

            // Get options and retrieve the translation from it
            var options;
            var optionParam = $rootScope.dataModel.getAttributeOptionsParam(attribute);
            if (_.isEmpty(optionParam)) {

                // Try the referenced attribute, if defined
                var referencedOptionAttribute = $rootScope.dataModel.getReferencedOptionAttribute(attribute);
                if (!_.isEmpty(referencedOptionAttribute)) {
                    return $rootScope.translateOption(option, referencedOptionAttribute, defaultValueFn);
                }

                // No options exist
                options = null;

            } else {

                options = _.get(attribute.params, optionParam);
                if (_.isString(options)) {

                    // Try translation by optionList
                    var optionList = options;
                    key = "optionList." + optionList + "." + type + "." + optionKey;
                    result = $rootScope.translate(key);
                    if (result !== key) {
                        return result;
                    }

                    // Find matching option from optionList, if possible
                    options = $rootScope.dataModel.optionListOptions(optionList);
                    option = findEntry(option, options) || option;

                } else if (!_.isArray(options)) {
                    // No options exist
                    options = null;
                }

            }

            // Determine the default value
            var defaultValue = defaultValueFn(option, options);

            // Try the option without the attribute name
            key = type + "." + optionKey;
            result = $rootScope.translate(key, defaultValue);

            return result;
        }

        function findEntry(key, entries) {
            return _.find(entries, function(entry) {
                return (entry.key == key);
            });
        }

        function findValueForKey(key, entries) {
            var result = findEntry(key, entries);
            return (result ? result.value : null) || key;
        }

        $rootScope.translateAttributeValidations = function(attributeName, validations) {
            angular.forEach(validations, function(validation) {
                $rootScope.translateAttributeValidation(attributeName, validation);
            });
        };

        $rootScope.translateAttributeValidation = function(attributeName, validation) {
            validation.translatedMessage = $rootScope.translateValidationMessage(validation);
            validation.translatedLabel = $rootScope.translateValidationLabel(attributeName + ":" + validation.name);
        };

        $rootScope.translateMappingFunction = function(mappingFunction) {
            var mappingFunctionName = angular.isObject(mappingFunction) ? mappingFunction.name : mappingFunction;
            var defaultValue = angular.isObject(mappingFunction) ? mappingFunction.label : null;
            return $rootScope.translate("mappingFunction." + mappingFunctionName + ".label", defaultValue || mappingFunctionName);
        };

        $rootScope.translateMappingFunctionDescription = function(mappingFunction) {
            var mappingFunctionName = angular.isObject(mappingFunction) ? mappingFunction.name : mappingFunction;
            var defaultValue = angular.isObject(mappingFunction) ? mappingFunction.description : null;
            return $rootScope.translate("mappingFunction." + mappingFunctionName + ".description", defaultValue || null);
        };

        $rootScope.translateMappingFunctionParam = function(mappingFunction, parameter) {
            var mappingFunctionName = angular.isObject(mappingFunction) ? mappingFunction.name : mappingFunction;
            var parameterName = angular.isObject(parameter) ? parameter.name : parameter;
            var defaultValue = angular.isObject(parameter) ? parameter.label : null;
            return $rootScope.translate("mappingFunction." + mappingFunctionName + ".parameter." + parameterName + ".label", defaultValue || parameterName);
        };

        $rootScope.translateMappingFunctionParamDescription = function(mappingFunction, parameter) {
            var mappingFunctionName = angular.isObject(mappingFunction) ? mappingFunction.name : mappingFunction;
            var parameterName = angular.isObject(parameter) ? parameter.name : parameter;
            var defaultValue = angular.isObject(parameter) ? parameter.description : null;
            return $rootScope.translate("mappingFunction." + mappingFunctionName + ".parameter." + parameterName + ".description", defaultValue || null);
        };

        $rootScope.translatePlaceholder = function(attributeName) {
            return $rootScope.translate('attribute.' + attributeName + '.placeholder', '');
        };

        /**
         * Deprecated!
         */
        $rootScope.translateError = function(error) {
            return $rootScope.translateValidationLabel(error);
        };

        $rootScope.translateValidationLabel = function(validationKey) {

            if (_.isEmpty(validationKey)) {
                return "";
            }

            var index = validationKey.indexOf(':');
            var attributeName;
            var validationName;
            if (index >= 0) {
                attributeName = validationKey.slice(0, index);
                validationName = validationKey.slice(index + 1);
            } else {
                attributeName = null;
                validationName = validationKey;
            }

            var attributeLabel =
                (attributeName ? $rootScope.translateAttribute(attributeName) : null);

            // FIXME: Define a prefix for the validation label (eg. 'validationlabel')
            var key = _.toUpper(validationName);
            var result = $rootScope.translate(key, key, {
                value: attributeLabel
            });

            return result.replace(/\s+/g, ' ');
        };

        $rootScope.translateValidationMessage = function(validationMessage) {

            if (_.isEmpty(validationMessage)) {
                return "";
            } else if (!_.isObject(validationMessage)) {
                return validationMessage;
            }

            var key = "validationmessage." + validationMessage.name;
            var msgId = validationMessage.params ? validationMessage.params.msg_id : null;
            if (msgId) {
                key += "." + msgId;
            }

            var result = "";
            if (!_.isEmpty(validationMessage.params)) {

                var prefix = "";
                if (validationMessage.path.length > 1) {

                    var prefixKey = "VALIDATION_MESSAGE_LOCATION_PREFIX";

                    validationMessage.params.err_field = $rootScope.translateAttribute(validationMessage.path[
                        validationMessage.path.length - 1]);
                    if (_.isObject(validationMessage.path[1])) {
                        prefixKey += '.MULTI_DIMENSIONAL';
                    } else if (_.isNumber(validationMessage.path[1])) {
                        validationMessage.params.err_row = validationMessage.path[1] + 1;
                        var attributeName = validationMessage.path[0];
                        var attribute = $rootScope.dataModel.attribute(attributeName);
                        if (attribute.typeName === 'Group') {
                               var errorIndex = validationMessage.path[validationMessage.path.length - 3];
                               if (_.isNumber(errorIndex)) {
                                   validationMessage.params.err_row = errorIndex + 1;
                               }
                            }
                        if (validationMessage.path.length > 3) {
                            var attName = validationMessage.path[3];
                            var subAtt = attribute.memberAttributes.find(function(memAtt) {
                                return memAtt.name === attName;
                            });
                            prefixKey = setPrefixKeyForAttributeType(prefixKey, subAtt.typeName);
                        } else {
                            prefixKey += '.ENUM_SET';
                        }
                    } else {
                        prefixKey += '.DIMENSIONAL';
                    }
                    prefix = $rootScope.translate(prefixKey, prefixKey, validationMessage.params, "messageformat");

                }

                var translation = $rootScope.translate(key, key, validationMessage.params, "messageformat");
                if (translation === key) {
                    result = validationMessage.message;
                } else {
                    prefix = _.isEmpty(prefix) ? '' : prefix + ' ';
                    result = prefix + translation;
                }

            } else {
                result = $rootScope.translate(key, key, null, null);
                if (result === key) {
                    result = validationMessage.message;
                }
            }

            return result;
        };

        // Helper function to set the prefix key based on the type of the attribute
        function setPrefixKeyForAttributeType(prefixKey, typeName) {
            switch (typeName) {
                case "MultiDimensional":
                    prefixKey += '.MULTI_DIMENSIONAL';
                    return prefixKey;

                case "Collection":
                    prefixKey += '.COLLECTION';
                    return prefixKey;

                case "Enum":
                    prefixKey += '.ENUM_SET';
                    return prefixKey;

            }
        }

        function _translate(key, interpolateParams, interpolationType) {
            var result;
            if (_.isEmpty(interpolateParams)) {
                result = translationsCache[key];
                if (_.isEmpty(result)) {
                    result = $translate.instant(key);
                    if (result !== key) {
                        translationsCache[key] = result;
                    }
                }
            } else {
                result = $translate.instant(key, interpolateParams, interpolationType);
            }
            return result;
        }

    });
