angular.module('llax')
    .controller('MappingFunctionCustomCodeController',
        function($modalInstance, $scope, $timeout, data, onFunctionParametersSaved) {
            $scope.mappingFunction = data.mappingFunction__;
            $scope.params = data.mappingFunctionParams || {};
            $scope.editorOptions = {
                lineWrapping : true,
                lineNumbers: true,
                mode: 'ruby',
                autoRefresh: true,
            };

            $scope.onEditorLoaded = function(editor) {
                $timeout(function() {
                    editor.refresh();
                    editor.setCursor({ line: 1, ch: 0 });
                }, 0);
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            $scope.save = function() {
                //TODO: check that all required parameters are selected.

                onFunctionParametersSaved(data, $scope.params);
                $scope.cancel();
            };
        }
    );