angular.module('llax.directives')
    .constant('MEDIA_TYPE_ONLINE_CATALOG', {
        IMAGE: 'IMAGE',
        DOCUMENT: 'DOCUMENT'
    })
    .directive('thumbsGallery', function() {
        return {
            restrict: 'E',
            scope: {
                media: '=',
                itemTitle: '='
            },
            templateUrl: 'tpl/catalog/directive-thumbs-gallery.tpl.html',
            controller: function($scope, $timeout, $modal, AssetFoldersService, MEDIA_TYPE_ONLINE_CATALOG) {
                $scope.TYPES = MEDIA_TYPE_ONLINE_CATALOG;
                $scope.getFileIcon = AssetFoldersService.getFileIcon;
                $scope.thumbsSwiper = null;
                $scope.mediaSwiper = null;
                $scope.mouseHoveringOverImage = false;

                $scope.openMediaModal = function() {

                    if ($scope.media.length === 0) {
                        return;
                    }

                    $modal.open({
                        templateUrl: 'tpl/catalog/modal-media.tpl.html',
                        controller: function($modalInstance, $scope, data, AssetFoldersService, MEDIA_TYPE_ONLINE_CATALOG) {

                            $scope.TYPES = MEDIA_TYPE_ONLINE_CATALOG;
                            $scope.media = angular.copy(data.media);
                            $scope.itemTitle = data.itemTitle;
                            $scope.getFileIcon = AssetFoldersService.getFileIcon;
                            $scope.activeMediaType = $scope.TYPES.IMAGE;
                            setFilteredMedia();

                            $scope.close = function() {
                                $modalInstance.close();
                            };

                            $scope.setAsActive = function(medium) {
                                if (!_.isNil($scope.activeMedia)) {
                                    $scope.activeMedia.isActive = false;
                                }

                                $scope.activeMedia = medium;
                                $scope.activeMedia.isActive = true;
                            };

                            $scope.setAsActive($scope.media[0]);

                            $scope.setActiveMediaType = function(newActiveType) {
                                $scope.activeMediaType = newActiveType;
                                setFilteredMedia();
                            };

                            function setFilteredMedia() {
                                $scope.filteredMedia = _.filter($scope.media, function(medium) {
                                    return medium.type === $scope.activeMediaType;
                                });
                            }
                        },
                        windowClass: '',
                        backdrop: true,
                        size: 'lg',
                        resolve: {
                            data: function () {
                                return {
                                    itemTitle: $scope.itemTitle,
                                    media: $scope.media
                                };
                            }
                        }
                    });
                };

                $scope.reInitializeThumbsSwiper = function() {
                    if (!_.isNil($scope.thumbsSwiper)) {
                        $scope.thumbsSwiper.update();
                    }
                };

                $scope.reInitializeMediaSwiper = function() {
                    if (!_.isNil($scope.mediaSwiper)) {
                        $scope.mediaSwiper.update();
                    }
                };

                $scope.setMouseHoveringOverImage = function(isHovering) {
                    $scope.mouseHoveringOverImage = isHovering;
                };

                $timeout(function() {
                    // This is the only way to load the following dependencies and have a reference to them with the
                    // current bundling configurations and requirejs.
                    requirejs([
                        'assets/swiper/swiper-bundle.min.js',
                        'assets/swiper/Drift.min.js'], function(Swiper) {
                        $scope.thumbsSwiper = new Swiper(".thumbs-slider", {
                            loop: false,
                            spaceBetween: 10,
                            slidesPerView: 6,
                            freeMode: true,
                            watchSlidesProgress: true,
                            navigation: {
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            },
                        });
                        $scope.mediaSwiper = new Swiper(".media-slider", {
                            loop: false,
                            thumbs: {
                                swiper: $scope.thumbsSwiper,
                            },
                        });
                    });
                }, 200);
            }
        };
    })
    .directive('catalogZoomableImage', function($timeout){
        return {
            restrict: 'E',
            scope: {
                src: '=',
                width: '@',
                onZoomedImageClick: '='
            },
            replace: true,
            template:
                '<div class="image-zoomer h-full" data-ng-click="onZoomedImageClick()">' +
                    '<file-thumbnail ' +
                        'class="h-full" ' +
                        'data-url="src" ' +
                        'data-img-class="w-full h-full main-slider-image image-bordered" ' +
                        'data-icon-class="swiper-file-preview" ' +
                        'data-on-load="onLoad"' +
                        'data-loader-class="position-relative" ' +
                        'data-layout="catalog" ' +
                        '></file-thumbnail>' +
                '</div>',
            link: function($scope, $element) {
                $scope.onLoad = function() {
                    $timeout(function() {
                        var imageElement = $element.find('img')[0];

                        if (!_.isNil(imageElement)) {
                            // We show the zoom panel using the URL in the image element
                            // in case it has changed in a custom file-thumbnail directive.
                            imageElement.setAttribute("data-zoom", imageElement.src);
                            new Drift(imageElement, {
                                paneContainer: document.querySelector('.primary-section'),
                                hoverBoundingBox: true
                            });
                        }
                    }, 500);
                };
            }
        };
    });
