angular.module('llax')
    .controller('EditUserAccountController',
        function($rootScope, $scope, $modalInstance, $q, growl, data, RolesService, UsersResource, UsersGroupService) {
            $scope.filters = data.filters;
            $scope.userAccount = angular.copy(data.userAccount);

            $scope.selectedGroups = [];
            $scope.effectiveRoles = [];
            $scope.groups = [];
            $scope.updateEffectiveRoles = function(groups) {
                $scope.selectedGroups = groups;
                if (_.isArray(groups)) {
                    var allroles = _.concat(_.flattenDeep(_.map(groups,'roles')), _.get(data,'userAccount.roles') || []);
                    $scope.effectiveRoles = _.union(allroles);
                }
            };

            $scope.updateUser = function() {
                var request_payload = {};
                request_payload.restrictionQuery = $scope.userAccount.restrictionQuery;
                request_payload.restrictionFilter = $scope.userAccount.restrictionFilter;
                request_payload.groupIds = _.map($scope.selectedGroups,'id');
                request_payload.roles = $scope.userAccount.roles;
                UsersResource.updateUser({
                    accountId: $scope.userAccount.id,
                    payload: request_payload
                }, function(response) {
                    UsersGroupService.reloadUsersAndGroups();
                    $modalInstance.close(true);
                    growl.success("USER_MANAGEMENT.USER_UPDATE_SUCCESS");
                });
            };

            function getRoles() {
                RolesService.getAllRoles().then(function(roles) {
                    $scope.roles = _.map(roles, function(elem) {
                        return {
                            id: elem,
                            text: elem
                        };
                    });
                });
            }

            function prepareGroups() {
                if (_.isArray(data.allGroups)) {
                    $scope.groups = data.allGroups;
                    if (data.userAccount && _.isArray(data.userAccount.groupIds)) {
                        $scope.selectedGroups = _.filter(data.allGroups, function(group) {
                            return data.userAccount.groupIds.indexOf(group.id) > -1;
                        });
                        $scope.updateEffectiveRoles($scope.selectedGroups);
                    }
                }
            }

            $scope.close = function(userAccount) {
                $modalInstance.close();
            };

            (function init() {
                if (_.isArray(data.allRoles)) {
                    $scope.roles = data.allRoles;
                } else {
                    getRoles();
                }
                prepareGroups();
            })();
        });
