angular.module('llax')
    .controller('ModalInstanceCtrl',
        function($rootScope, $scope, $translate, $modalInstance, growl, ContactsResource, CommunicationChannelResource,
            DeleteItemsResource, data) {

            $scope.data = data;
            $scope.isSelected = false;

            $scope.ok = function() {
                $modalInstance.close();
            };

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.confirmDeleteItem = function() {
                function renderMessage(data) {
                    if (!data.selectionId) {
                        return;
                    }
                    if (data.primaryKeys && data.primaryKeys.length == 1) {
                        growl.success('ITEM_DELETED');
                    } else {
                        growl.success('ITEMS_DELETED');
                    }
                }
                var data = {
                    "selectionId": $scope.data.selectionId
                };
                DeleteItemsResource.pass({}, data,
                    function() {
                        $scope.ok();
                        renderMessage($scope.data);
                    });
            };

            $scope.confirmDeleteContact = function(organizationId) {
                $rootScope.$broadcast('deleteContact', organizationId);
                $scope.ok();
            };

            $scope.confirmDeleteAttributeValue = function() {
                $modalInstance.close(true);
            };

            $scope.confirmDeactivateChannel = function(channel) {
                $rootScope.$broadcast('deactivateChannel', channel);
                $scope.ok();
            };

            $scope.confirmDeleteChannel = function(channel) {
                $rootScope.$broadcast('deleteChannel', channel);
                $scope.ok();
            };

            $scope.confirmDeleteTaskList = function(taskList) {
                $rootScope.$broadcast('deleteTaskList', taskList);
                $scope.ok();
            };

            $scope.confirmDeleteKeyValue = function() {
                $modalInstance.close(true);
            };

        });
