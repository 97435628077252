    angular.module('llax')
        .controller('SimpleMappingsController',
            function ($dialogs, $filter, $http, $log, $modal, $modalInstance, $rootScope, $scope, $timeout, $translate,
                $window, data, growl, SheetJSService, SimpleMappingsResource, SimpleMappingsService, uiGridConstants) {
                $scope.MODE_SCREEN = 'screen';
                $scope.MODE_MODAL = 'modal';
                $scope.simpleMappings = [];
                $scope.currentSimpleMapping = null;
                $scope.createNewState = false;
                $scope.editState = {};
                $scope.simpleMappingsInEditState = {};
                $scope.newEntity = {
                    name: ''
                };
                $scope.allLinkableAttributes = [];

                $scope.mode = $scope.MODE_MODAL;
                if (_.isEmpty($modalInstance)) {
                    $scope.mode = $scope.MODE_SCREEN;
                }

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                };

                $scope.export = function() {
                    if (!_.isNil($scope.currentSimpleMapping) && !_.isEmpty($scope.currentSimpleMapping.entries)) {
                        SheetJSService.exportXLSX(
                            _.map($scope.currentSimpleMapping.entries, function(entry) {
                                return _.pick(entry, ['key', 'value']);
                            }),
                            $scope.currentSimpleMapping.name,
                            $scope.currentSimpleMapping.name
                        );
                    }
                };

                $scope.import = function() {
                    $modal.open({
                        templateUrl: 'tpl/simple-mapping/modal-import-simple-mappings.tpl.html',
                        controller: 'ImportSimpleMappingsController',
                        resolve: {
                            data: function() {
                                return {
                                    simpleMappingId: $scope.currentSimpleMapping.id,
                                    onSimpleMappingImported: $scope.onSimpleMappingImported
                                };
                            }
                        },
                        size: 'lg',
                        backdrop: true,
                        windowClass: 'import-simple-mapping-modal'
                    });
                };

                $scope.prefill = function() {
                    $modal.open({
                        templateUrl: 'tpl/simple-mapping/modal-prefill-simple-mappings.tpl.html',
                        controller: 'PrefillSimpleMappingsController',
                        resolve: {
                            data: function() {
                                return {
                                    simpleMapping: $scope.currentSimpleMapping,
                                    onSimpleMappingImported: $scope.onSimpleMappingImported,
                                    linkableAttributes: $scope.allLinkableAttributes
                                };
                            }
                        },
                        size: 'lg',
                        backdrop: true,
                        windowClass: 'prefill-simple-mapping-modal'
                    });
                };

                $scope.link = function() {
                    $modal.open({
                        templateUrl: 'tpl/simple-mapping/modal-prefill-simple-mappings.tpl.html',
                        controller: 'PrefillSimpleMappingsController',
                        resolve: {
                            data: function() {
                                return {
                                    simpleMapping: $scope.currentSimpleMapping,
                                    onSimpleMappingImported: $scope.onSimpleMappingImported,
                                    linkableAttributes: $scope.allLinkableAttributes,
                                    linkMode: true
                                };
                            }
                        },
                        size: 'lg',
                        backdrop: true,
                        windowClass: 'prefill-simple-mapping-modal'
                    });
                };

                $scope.onSimpleMappingImported = function(importedSimpleMapping) {
                    growl.success('SIMPLE_MAPPING.IMPORTED_SUCCESSFULLY');
                    init(importedSimpleMapping);
                };

                $scope.onCurrentMappingsChange = function() {
                    $scope.currentSimpleMapping.entries = [];
                    SimpleMappingsResource.getEntries({
                        id: $scope.currentSimpleMapping.id
                    }, function(response) {
                        $scope.currentSimpleMapping.entries = response;
                        $scope.refreshData();
                    }, function(response) {
                        growl.error('SIMPLE_MAPPING.LOAD_FAILED');
                    });
                };

                $scope.setSimpleMappingAsSelected = function(simpleMapping) {
                    _.forEach($scope.simpleMappings, function(list) {
                        list.selected = false;
                        if ($scope.editState[list.id]) {
                            $scope.cancelEditState(list);
                        }
                    });

                    simpleMapping.selected = true;
                    $scope.currentSimpleMapping = simpleMapping;
                    $scope.onCurrentMappingsChange();
                };

                $scope.toggleCreateState = function() {
                    $scope.newEntity.name = null;
                    $scope.createNewState = !$scope.createNewState;
                };

                $scope.startEditState = function(simpleMapping) {
                    $scope.editState[simpleMapping.id] = true;
                    $scope.simpleMappingsInEditState[simpleMapping.id] = angular.copy(simpleMapping);
                };

                $scope.cancelEditState = function(simpleMapping) {
                    $scope.editState[simpleMapping.id] = false;
                    if ($scope.simpleMappingsInEditState[simpleMapping.id]) {
                        simpleMapping.name = $scope.simpleMappingsInEditState[simpleMapping.id].name;
                    }
                };

                $scope.cancelCreatingNewList = function() {
                    $scope.createNewState = false;
                    $scope.newEntity.name = '';
                };

                $scope.useSelectedMappingList = function() {
                    data.onSimpleMappingSelected(data.entry, $scope.currentSimpleMapping);
                    $modalInstance.close();
                };

                $scope.refreshData = function (searchTerm) {
                    $scope.enrichEntriesWithDefaultValues($scope.currentSimpleMapping);
                    if (_.isEmpty(searchTerm)) {
                        $scope.filteredEntries = $scope.currentSimpleMapping.entries;
                    } else {
                        $scope.filteredEntries = $filter('filter')($scope.currentSimpleMapping.entries, searchTerm);
                    }

                    $scope.gridOptions.columnDefs = generateColumnDefinitions();
                    if (!_.isNil($scope.gridApi)) {
                        $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
                    }
                };

                $scope.removeSimpleMappingFromList = function(simpleMapping) {
                    $scope.simpleMappings = _.remove($scope.simpleMappings, function(entry) {
                        return simpleMapping.id != entry.id;
                    });

                    if (!_.isEmpty($scope.simpleMappings)) {
                        $scope.setSimpleMappingAsSelected($scope.simpleMappings[0]);
                    } else {
                        $scope.currentSimpleMapping = null;
                    }
                };

                $scope.createNewSimpleMapping = function() {
                    if (_.isEmpty($scope.newEntity.name)) {
                        return;
                    }

                    SimpleMappingsResource.create({
                        name: $scope.newEntity.name
                    }, function(response) {
                        $scope.createNewState = false;
                        $scope.simpleMappings.push(response);
                        $scope.setSimpleMappingAsSelected(response);
                        growl.success('SIMPLE_MAPPING.CREATE_SUCCESSFUL');
                    }, function(response) {
                        if (response.data.errorCode) {
                            growl.error(response.data.message, {
                                variables: {
                                    mappingName: $scope.newEntity.name
                                },
                                ttl: -1
                            });
                        } else {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        }
                    });
                };

                $scope.updateSimpleMapping = function(simpleMapping) {
                    if (_.isEmpty(simpleMapping.name)) {
                        return;
                    }

                    SimpleMappingsResource.update({
                        id: simpleMapping.id
                    },{
                        id: simpleMapping.id,
                        name: simpleMapping.name
                    }, function(response) {
                        $scope.editState[simpleMapping.id] = false;
                        growl.success('SIMPLE_MAPPING.UPDATE_SUCCESSFUL');
                    }, function(response) {
                        if (response.data.errorCode) {
                            growl.error(response.message);
                        } else {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        }
                    });
                };

                $scope.deleteSimpleMapping = function(simpleMapping) {
                    var confirmDialog = $dialogs.confirm('CONFIRM_DELETE', 'TEMPLATE_MAPPINGS.CONFIRM_DELETE');
                    confirmDialog.result.then(function(){
                        $scope.confirmDeleteSimpleMapping(simpleMapping);
                    });
                };

                $scope.confirmDeleteSimpleMapping = function(simpleMapping) {
                    SimpleMappingsResource.delete({
                        id: $scope.currentSimpleMapping.id
                    }, function(response) {
                        growl.success('SIMPLE_MAPPING.DELETE_SUCCESSFUL');
                        $scope.removeSimpleMappingFromList(simpleMapping);
                    }, function(response) {
                        growl.error('SIMPLE_MAPPING.DELETE_FAILED');
                    });
                };

                $scope.removeEntryFromEntries = function(entry) {
                    $scope.currentSimpleMapping.entries = _.remove($scope.currentSimpleMapping.entries, function(_entry) {
                        return entry.key != _entry.key;
                    });
                    $scope.refreshData();
                };

                $scope.createEntry = function () {
                    var entry = {
                        key: '',
                        value: '',
                        _new: true
                    };

                    $scope.currentSimpleMapping.entries.unshift(entry);
                    $scope.refreshData();

                    $timeout(function () {
                        $scope.gridApi.cellNav.scrollToFocus($scope.currentSimpleMapping.entries[0], $scope.columnDefs[0]);
                    });
                };

                $scope.saveEntry = function(entry) {
                    if (entry._new) {
                        SimpleMappingsResource.createEntry({
                            id: $scope.currentSimpleMapping.id,
                            key: entry.key
                        }, {
                            key: entry.key,
                            value: entry.value
                        }, function(response) {
                            growl.success('SAVE_SUCCESS_MESSAGE');
                            $timeout(function() {
                                entry._new = false;
                                entry._dirty = false;
                            });
                        }, function(response) {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        });
                    } else {
                        SimpleMappingsResource.updateEntry({
                            id: $scope.currentSimpleMapping.id,
                            key: entry.key
                        }, {
                            key: entry.key,
                            value: entry.value
                        }, function(response) {
                            growl.success('SAVE_SUCCESS_MESSAGE');
                            $timeout(function() {
                                entry._new = false;
                                entry._dirty = false;
                            });
                        }, function(response) {
                            growl.error('SIMPLE_MAPPING.SAVE_FAILED');
                        });
                    }
                };

                $scope.deleteEntry = function (entry) {
                    if (entry._new) {
                        $scope.removeEntryFromEntries(entry);
                    } else {
                        SimpleMappingsResource.deleteEntry({
                            id: $scope.currentSimpleMapping.id,
                            key: entry.key
                        }, function(response) {
                            growl.success('SIMPLE_MAPPING.DELETE_SUCCESSFUL');
                            $scope.removeEntryFromEntries(entry);
                        }, function(response) {
                            growl.error('SIMPLE_MAPPING.DELETE_FAILED');
                        });
                    }
                };

                $scope.deleteSelectedEntries = function () {
                    var selectedEntries = $scope.gridApi.selection.getSelectedRows();

                    if (_.isEmpty($scope.currentSimpleMapping.entries) || _.isEmpty(selectedEntries)) {
                        return false;
                    }

                    var confirmDialog = $dialogs.confirm('CONFIRM_DELETE', 'TEMPLATE_MAPPINGS.CONFIRM_DELETE');
                    confirmDialog.result.then(function(){
                        $scope.confirmDeleteSelectedEntries($scope.currentSimpleMapping.id, _.map(selectedEntries, function(entry) { return entry.key; }));
                    });
                };

                $scope.confirmDeleteSelectedEntries = function(simpleMappingId, keys) {
                    // FIXME: Solved in angular +1.6.4, as they interpret the specs such that the DELETE request has no body in prior versions.
                    $http({
                        method: 'DELETE',
                        url: lax_rest_url('simpleMappings') + '/' + simpleMappingId + '/entries',
                        data: keys,
                        headers: {'Content-Type': 'application/json;charset=utf-8'}
                    }).then(function(response) {
                        growl.success('SIMPLE_MAPPING.DELETE_SUCCESSFUL');
                        $scope.onCurrentMappingsChange();
                    }, function(response) {
                        $log.error(response);
                        growl.error('SIMPLE_MAPPING.DELETE_FAILED');
                    });
                };

                $scope.gridHeight = function () {
                    if ($scope.mode === $scope.MODE_SCREEN) {
                        var w = angular.element($window);
                        if (w.width() < 992) {
                            return;
                        }
                        var footer = angular.element('footer');
                        var boxContent = angular.element('.box-content');
                        var content = angular.element('#content');
                        var boxContentPadding = parseInt(boxContent.css('padding-bottom'), 10);
                        var contentPadding = parseInt(content.css('padding-bottom'), 10);
                        var offset = 630;
                        if (angular.element("#simple-mappings-grid") && angular.element("#simple-mappings-grid").offset()) {
                            offset = angular.element("#simple-mappings-grid").offset().top + footer.innerHeight() + boxContentPadding + contentPadding;
                        }
                        var newHeight = w.height() - offset;

                        if ($scope.gridApi) {
                            $scope.gridApi.grid.gridHeight = newHeight;
                        }

                        return {
                            'height': newHeight + "px"
                        };
                    }
                };

                $scope.showLinkedAttributesTooltip = function() {

                    if (_.isEmpty($scope.currentSimpleMapping.linkedAttributes)) {
                        return;
                    }

                    var linkedAttributesList = [];
                    _.forEach($scope.currentSimpleMapping.linkedAttributes, function(attribute){
                        linkedAttributesList.push(
                            '<li>' +
                            $scope.translateAttributeByName(attribute) +
                            '</li>'
                        );
                    });

                    function customRendererFn() {
                        var attributeStart = '<div class="linked-attributes-tooltip">' + '<ul class="m-0">';
                        var attributeEnd = '</ul>' +
                                           '</div>' +
                                           '<span class="hopscotch-arrow"></span>';
                        return attributeStart + _.join(linkedAttributesList, '') + attributeEnd;
                    }

                    var calloutConfig = {
                        id: 'callout_' + $scope.currentSimpleMapping.id, //normalize id
                        target: 'linked_attributes_btn',
                        placement: 'bottom',
                        content: '',
                        customRenderer: customRendererFn
                    };

                    var calloutMgr = hopscotch.getCalloutManager();
                    calloutMgr.removeAllCallouts();

                    var calloutEle = calloutMgr.createCallout(calloutConfig);
                    // Hack: override !important css rules, with inline precedence
                    calloutEle.element.style.cssText += 'border: none !important; z-index: 1049;';
                };

                $scope.hideTooltip = function() {
                    var calloutMgr = hopscotch.getCalloutManager();
                    calloutMgr.removeAllCallouts();
                };

                $scope.translateAttributeByName = function(attributeName) {
                    var attribute = _.find($scope.allLinkableAttributes, ['name', attributeName]);

                    if (!_.isNil(attribute)) {
                        return $rootScope.translateAttribute(attribute.label);
                    } else {
                        return attributeName;
                    }
                };

                $scope.gridOptions = {
                    data: 'filteredEntries',
                    columnDefs: generateColumnDefinitions(),
                    enableHorizontalScrollbar: 1,
                    enableVerticalScrollbar: 1,
                    enableColumnMenus: false,
                    rowHeight: 34,
                    onRegisterApi: function (gridApi) {
                        $scope.gridApi = gridApi;
                        gridApi.edit.on.afterCellEdit($scope, afterEntriesCellEdit);
                    }
                };

                $scope.enrichEntriesWithDefaultValues = function(simpleMapping) {
                    if (!_.isNil(simpleMapping.linkedAttributes)) {
                        simpleMapping.entries = _.map(simpleMapping.entries, function(entry) {

                            for (var i = 0; i < simpleMapping.linkedAttributes.length; i++) {
                                var translatedValue = $rootScope.translateOption(entry.key, simpleMapping.linkedAttributes[i], function() { return null; });

                                if (!_.isNil(translatedValue)) {
                                    entry.defaultValue = translatedValue;
                                    break;
                                }
                            }

                            return entry;
                        });
                    } else {
                        return;
                    }
                };

                function init(simpleMapping) {
                    SimpleMappingsResource.getAll(function(response) {
                        $scope.simpleMappings = response;
                        if ($scope.simpleMappings.length > 0) {
                            if (!_.isNil(simpleMapping)) {
                                var simpleMappingToSelect = _.find($scope.simpleMappings, function(_simpleMapping){
                                    return _simpleMapping.name === simpleMapping || _simpleMapping.id == simpleMapping;
                                });
                                $scope.setSimpleMappingAsSelected(simpleMappingToSelect);
                            } else {
                                $scope.setSimpleMappingAsSelected($scope.simpleMappings[0]);
                            }
                        }
                    }, function(response) {
                        growl.error('SIMPLE_MAPPING.LOAD_FAILED');
                    });

                    SimpleMappingsService.getLinkableAttributes($scope, function(attributes) {
                        $scope.allLinkableAttributes = attributes;
                    });
                }

                function generateColumnDefinitions() {
                    var columnDefs = [{
                        field: 'key',
                        displayName: $translate.instant('SIMPLE_MAPPING.KEY'),
                        headerTooltip: true,
                        cellTooltip: true,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        width: '*',
                        editableCellTemplate:
                            '<div> ' +
                            '  <form name="inputForm"> ' +
                            '    <input type="text" ' +
                            '      class="form-control" ' +
                            '      data-ng-model="MODEL_COL_FIELD" ' +
                            '      lax-grid-input>' +
                            '  </form>' +
                            '</div>'
                    }];

                    if (!_.isNil($scope.currentSimpleMapping) && !_.isEmpty($scope.currentSimpleMapping.linkedAttributes)) {
                        columnDefs.push({
                            field: 'defaultValue',
                            displayName: $translate.instant('SIMPLE_MAPPING.LABEL'),
                            headerTooltip: true,
                            cellTooltip: true,
                            enableCellEdit: false,
                            width: '*'
                        });
                    }

                    columnDefs.push({
                        field: 'value',
                        displayName: $translate.instant('SIMPLE_MAPPING.VALUE'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellEditableCondition: true,
                        width: '*',
                        editableCellTemplate:
                            '<div> ' +
                            '  <form name="inputForm"> ' +
                            '    <input type="text" ' +
                            '      class="form-control" ' +
                            '      data-ng-model="MODEL_COL_FIELD" ' +
                            '      lax-grid-input>' +
                            '  </form>' +
                            '</div>'
                    });

                    columnDefs.push({
                        field: 'actions',
                        displayName: '',
                        enableCellSelection: false,
                        allowCellFocus: false,
                        enableCellEdit: false,
                        enableHiding: false,
                        cellClass: 'text-right',
                        headerCellClass: 'text-right',
                        enableColumnResizing: false,
                        enableSorting: false,
                        pinnedRight: true,
                        width: 80,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents">' +
                                '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">' +
                                    '<button data-ng-if="row.entity._dirty" type="button" class="btn btn-cell action-save action-btn-slot" tabindex="-1" title="{{\'CODELIST_EDITOR.SAVE_ENTRY\' | translate}}" data-ng-click="grid.appScope.saveEntry(row.entity)"> ' +
                                        '<i class="glyphicon glyphicon-floppy-disk"></i> ' +
                                    '</button>' +
                                    '<button type="button" class="btn btn-cell action-delete action-btn-slot" tabindex="-1" title="{{\'CODELIST_EDITOR.DELETE_ENTRY\' | translate}}" data-ng-click="grid.appScope.deleteEntry(row.entity)"> ' +
                                        '<i class="syncons syncons-delete"></i> ' +
                                    '</button>' +
                                '</div>' +
                            '</div>'
                    });

                    return columnDefs;
                }

                function afterEntriesCellEdit(rowEntity, colDef, newValue, oldValue) {
                    $scope.$apply(function(scope) {
                      rowEntity._dirty = true;
                    });
                }

                if (!_.isNil(data.simpleMappingName)) {
                    init(data.simpleMappingName);
                } else {
                    init();
                }
            }
        );