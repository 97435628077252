angular.module('llax.directives')
    .directive('catalogAttribute', function() {
        return {
            restrict: 'E',
            scope: {
                attribute: '=',
                item: '=',
                rootItem: '=',
                minimalist: '=',
                hideIfEmpty: '=',
                hideLabel: '=?'
            },
            templateUrl: 'tpl/catalog/directive-catalog-attribute.tpl.html',
            controller: function($rootScope, $scope, $timeout, EnumAttributeService, ReferenceAttributesService, uiGridConstants, $q) {
                $scope.translateAttribute = $rootScope.translateAttribute;
                $scope.getValuesFormat = $rootScope.getValuesFormat;
                $scope.translateOption = $rootScope.translateOption;
                $scope.dynamicColumns = {};
                $scope.dynamicDesktopData = [];
                $scope.dynamicMobileData = [];
                $scope.collapse = [];
                $scope.hideLabel = $scope.hideLabel || false;
                $scope.shouldBeHidden = $scope.hideIfEmpty && _.isEmpty($scope.item[$scope.attribute.name]);

                // FIX ME: aliasing `a` as `attribute` for legacy implementation in the HTML templates
                $scope.a = $scope.attribute;

                $scope.initializeEnumOptions = function(a, values) {

                    if (_.isEmpty(a.options)) {
                        EnumAttributeService.getAttributeOptionsAsync(a, false)
                            .then(function(allAttributeOptions) {

                                // Cache the loaded options by attaching it to the attribute in case of re-renders
                                a.options = allAttributeOptions;

                                prepareSelectedAttributeOptions(a, allAttributeOptions, values);
                            });
                    } else {
                        prepareSelectedAttributeOptions(a, a.options, values);
                    }
                };

                function prepareSelectedAttributeOptions(a, allAttributeOptions, values) {

                    $scope.selectedOptions = [];

                    if (!_.isEmpty(allAttributeOptions) && !_.isEmpty(values)) {
                        // Let's filter the options based on any defined data model filter AND add any options
                        // needed in case of Open Enums/Codelists
                        var options = $rootScope.getContextFilteredOptions(null, $scope, null, a, values);

                        // We only need the actual selected options as in the attribute value(s)
                        options = _.filter(options, function(option) {
                            return _.includes(values, option.key);
                        });

                        // We need to force a re-render on the next digest cycle
                        $timeout(function() {
                            $scope.selectedOptions = options;
                        }, 0);
                    }
                }

                $scope.getColumns = function(attributeDefinition, item) {

                    var deferreds = [];
                    var columns = [];
                    var memberAttributes = $rootScope.dataModel.getMemberAttributes(attributeDefinition);
                    angular.forEach(memberAttributes, function(memberAttribute) {

                        var deferred = EnumAttributeService.getAttributeOptionsAsync(memberAttribute)
                            .then(function() {
                                if (!$scope.isAttributeHidden(memberAttribute)) {
                                    var column = {
                                        name: memberAttribute.name,
                                        typeName: memberAttribute.typeName,
                                        label: $rootScope.translateAttribute(memberAttribute)
                                    };
                                    columns.push(column);
                                }
                            });

                        deferreds.push(deferred);

                    });

                    $q.all(deferreds)
                        .then(function() {
                            $scope.dynamicColumns[attributeDefinition.name] = columns;
                            $scope.getData(attributeDefinition, item);
                        });
                };

                // FIX ME: We have an implicit dependency on $scope.a in data-ng-compiled-include of GroupDetail template.
                // This should be fixed by moving the data-ng-compiled-include to the directive scope.
                $scope.getData = function(attributeDefinition, data) {
                    var items = data[attributeDefinition.name];

                    items = sortItems(attributeDefinition, items);

                    var desktopItems = [];
                    $scope.dynamicMobileData[attributeDefinition.name] = [];
                    angular.forEach(items, function(item) {
                        var desktopItem = {},
                            mobileItems = [];
                        angular.forEach($scope.dynamicColumns[attributeDefinition.name], function(columnValue, columnKey) {
                            var memberAttribute = $rootScope.dataModel.attribute(columnValue.name);
                            var value = item[columnValue.name];
                            var translatedValue = getTranslatedValue(item, memberAttribute);

                            var mobileItem = {};
                            mobileItem.label = columnValue.label;
                            mobileItem.value = "";
                            desktopItem[columnKey] = "";
                            if (value != 'undefined') {
                                desktopItem[columnKey] = translatedValue ? translatedValue : value;
                                mobileItem.value = translatedValue ? translatedValue : value;
                            }
                            mobileItems.push(mobileItem);
                        });
                        desktopItems.push(desktopItem);
                        $scope.dynamicMobileData[attributeDefinition.name].push(mobileItems);
                    });
                    $scope.dynamicDesktopData[attributeDefinition.name] = desktopItems;
                };

                $scope.getAttributeDimensions = function(attribute) {
                    var dimensionsKey = $rootScope.dataModel.getAttributeOptionsParam(attribute);
                    var dimensions = attribute.params[dimensionsKey];

                    if (_.isString(dimensions)) { // i.e. options list
                        var optionList = $rootScope.dataModel.optionList(dimensions);
                        dimensions = optionList.options;
                    }

                    return dimensions;
                };

                $scope.loadGroupData = function (attribute) {
                    var groups = $scope.item[attribute.name];
                    return groups;
                };

                $scope.loadGroupMembers = function (attribute) {
                    return $rootScope.dataModel.attribute(attribute.params.valueAttribute).members;
                };

                $scope.loadMemberAttribute = function (memberName) {
                    return $rootScope.dataModel.attribute(memberName);
                };

                $scope.collapseGroup = function (group, id) {
                    var key = group + "_" + id;
                    if (_.isBoolean($scope.collapse[key])) {
                        $scope.collapse[key] = !$scope.collapse[key];
                    } else {
                        $scope.collapse[key] = true;
                    }
                };

                function getTranslatedValue(item, memberAttribute) {
                    var translation;
                    var attributeType = memberAttribute.typeName;

                    if (attributeType === 'Dimensional') {
                        var dimensionKey = $rootScope.getFirstFilteredDimensionKey(item, memberAttribute);
                        var dimensionValues = item[memberAttribute.name];
                        if (!_.isNil(dimensionKey) && !_.isNil(dimensionValues)) {
                            translation = dimensionValues[dimensionKey];
                        }
                    } else {
                        translation = $rootScope.formatAttributeValue(item, memberAttribute);
                    }

                    return translation;
                }

                $scope.isAttributeHidden = function(attribute) {
                    if (!_.isNil(attribute)) {
                        var attributeName = attribute.name;

                        // We try to get the attribute states from the rootItem
                        // where the item might reference a group attribute, instead of the actual
                        // root item where the state resides
                        var attributeStates = {};
                        if (!_.isNil($scope.rootItem)) {
                            attributeStates = $scope.rootItem.attributeStates__;
                        } else {
                            attributeStates = $scope.item.attributeStates__;
                        }

                        if (!_.isEmpty(attributeStates) && attributeStates.contains((attributeName + ":hidden"))) {
                            return true;
                        }

                        return false;
                    }
                };

                /*
                 * Sort attribute entries as if they were rendered by angular-ui-grid, since in online catalog
                 * we render collection-like attributes simply as HTML tables.
                 */
                function sortItems(attributeDefinition, items) {

                    if (_.isEmpty(items)) {
                        return [];
                    }

                    var sortingOptions = [];
                    var memberAttributes = $rootScope.dataModel.getMemberAttributes(attributeDefinition);

                    _.forEach(memberAttributes, function(memberAttribute, index) {
                        var sortingOption = $rootScope.getColumnSortingOptions(attributeDefinition, memberAttribute, index);

                        // We only want to sort the attributes which actually has explicitly a sorting defined
                        // in the data model
                        if (!_.isNil(sortingOption.sort) && !_.isNil(sortingOption.sortingAlgorithm)) {
                            sortingOption.forAttribute = memberAttribute.name;
                            sortingOptions.push(sortingOption);
                        }

                    });

                    // Sort the sorting options :D based on their priority (lower priority gets sorted first)
                    sortingOptions = sortingOptions.sort(function(sortingOptionA, sortingOptionB) {
                        return sortingOptionB.sort.priority - sortingOptionA.sort.priority;
                     });

                    _.forEach(sortingOptions, function(sortingOption) {

                        var sortingDirection = uiGridConstants.ASC;
                        if (!_.isNil(sortingOption.sort) && !_.isNil(sortingOption.sort.direction)) {
                            sortingDirection = sortingOption.sort.direction;
                        }

                        items.sort(function(a, b) {
                            // We also need to map the row entities as they are structured
                            // using angular-ui-grid.
                            var aRowEntity = {
                                entity: a
                            };
                            var bRowEntity = {
                                entity: b
                            };
                            return sortingOption.sortingAlgorithm(
                                a[sortingOption.forAttribute],
                                b[sortingOption.forAttribute],
                                aRowEntity,
                                bRowEntity,
                                sortingDirection);
                        });
                    });

                    return items;
                }

            }
        };
    });
