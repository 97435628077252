angular.module('llax')
    .controller('TemplateMappingEditorController', function ($location, $modal, $rootScope, $routeParams, $scope,
        $timeout, ExportMappingResource, growl, SheetJSService, TemplateMappingService) {

        var EXCEL_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var ZIP_CONTENT_TYPE = 'application/zip';

        $scope.TYPE_LIST = 'LIST';
        $scope.TYPE_PAGE = 'PAGE';

        $scope.sheets = [];
        $scope.gridApi = null;
        $scope.mappingFunctions = [];
        $scope.filteredLayoutAttributes = [];
        $scope.allAttributes = [];
        $scope.preview = false;
        $scope.filteredMappings__ = [];
        $scope.sheetJSFile = null;
        $scope.spreadsheetViewerInstance = null;

        // Load non-AMD dependencies
        requirejs([
            'assets/codemirror/js/codemirror.js',
        ], function(codemirror) {
            window.CodeMirror = codemirror;

            define('assets/lib/codemirror', [], function() { return window.CodeMirror; });

            // Once we've defined Codemirror, we can load any language mode
            requirejs(['assets/codemirror/js/ruby.js']);
        });

        $scope.addMappingEntry = function(id) {
            $scope.template.mappings.unshift({
                id: getLastEntryId(),
                externalName: '',
                location: '',
                internalName: '',
                mappingFunction: ''
            });
            $scope.filteredMappings__ = filterMappingsOnSheetName();
        };

        $scope.saveTemplate = function() {
            if (_.isEmpty($scope.template.name)) {
                growl.error('TEMPLATE_MAPPINGS.TEMPLATE_NAME_REQUIRED');
                return;
            }

            var mergedMappings =
                _($scope.template.mappings)
                .keyBy('id')
                .merge(_.keyBy($scope.filteredMappings__, 'id'))
                .values()
                .value();

            // TODO: Add a field to let the user decide if mapping should be "private" or not!
            var importExportEntity = {
                name: $scope.template.name,
                contentType: contentTypeForTemplateType($scope.template.templateType),
                userId: $rootScope.userId,
                templateType: $scope.template.templateType,
                templateSheets: $scope.sheets,
                templateUrl: $scope.template.templateUrl,
                templateCustomAttributes: $scope.template.templateCustomAttributes,
                mappings: _.map(
                    mergedMappings,
                    function(mapping) {
                        return {
                            internalName: mapping.internalName,
                            externalName: mapping.externalName,
                            location: mapping.location,
                            mappingFunction: mapping.mappingFunction__ ? mapping.mappingFunction__.name : '',
                            mappingFunctionParams: mapping.mappingFunctionParams
                        };
                    }
                ),
                privateMapping: $scope.template.privateMapping || false
            };

            // TODO: Ensure mapping does not yet exist!
            // I.e. check e.g. "createdAt" and "createdBy" (set on save)
            // if it is and name was not changed -> fine
            // if it is NOT set OR name was changed -> Load new name to see if it exists and ask user to overwrite!

            ExportMappingResource.save({},
                importExportEntity,
                function (response) {
                    growl.success("SUCCESS");
                },
                function (errorResponse) {
                    growl.error("TEMPLATE_MAPPINGS.SAVING_MAPPING_FAILED");
                }
            );
        };

        $scope.onConfigureSimpleMappingList = function(entry) {
            var simpleMappingParamName = TemplateMappingService.getSimpleMappingParameterName(entry.mappingFunction__);
            $modal.open({
                templateUrl: 'tpl/template-mapping/modal-simple-mapping.tpl.html',
                controller: 'SimpleMappingsController',
                resolve: {
                    data: function() {
                        return {
                            entry: entry,
                            simpleMappingName: _.get(entry.mappingFunctionParams, simpleMappingParamName),
                            onSimpleMappingSelected: $scope.onSimpleMappingSelected
                        };
                    }
                },
                size: 'lg',
                backdrop: true,
                windowClass: 'template-export-simple-mapping-modal'
            });
        };

        $scope.onConfigureMappingFunction = function(entry) {
            $modal.open({
                templateUrl: 'tpl/template-mapping/modal-mapping-function-default.tpl.html',
                controller: 'MappingFunctionDefaultController',
                resolve: {
                    data: function () {
                        return entry;
                    },
                    onFunctionParametersSaved: function() {
                        return $scope.onFunctionParametersSaved;
                    }
                },
                size: 'md',
                backdrop: true,
                windowClass: 'template-export-code-list-modal'
            });
        };

        $scope.onConfigureCustomCodeMappingFunction = function(entry) {
            $modal.open({
                templateUrl: 'tpl/template-mapping/modal-mapping-function-custom-code.tpl.html',
                controller: 'MappingFunctionCustomCodeController',
                resolve: {
                    data: function () {
                        return entry;
                    },
                    onFunctionParametersSaved: function() {
                        return $scope.onFunctionParametersSaved;
                    }
                },
                size: 'md',
                backdrop: true,
                windowClass: 'template-export-code-list-modal'
            });
        };

        $scope.getUploadConfiguration = function() {
            return {
                url: lax_rest_url('assets'),
                reset: true,
                uploadComplete: function (response) {
                    $timeout(function() {
                        $scope.preview = true;
                    }, 0);
                }
            };
        };

        $scope.onSimpleMappingSelected = function(mappingEntry, simpleMapping) {
            mappingEntry.mappingFunctionParams = {};
            mappingEntry.mappingFunctionParams[mappingEntry.mappingFunction__.parameters[0].name] = simpleMapping.name;
        };

        $scope.onFunctionParametersSaved = function(entry, params) {
            entry.mappingFunctionParams = {};
            entry.mappingFunctionParams = angular.copy(params);
        };

        $scope.onFocusSpreadsheetCell = function(locationString) {
            if (!_.isEmpty(locationString)) {
                var a1NotationLocation = TemplateMappingService.getA1NotationFromLocationString(locationString);
                var numberBasedLocation = TemplateMappingService.convertFromA1Notation(a1NotationLocation);
                $scope.spreadsheetViewerInstance.focusSpreadsheetCell(numberBasedLocation.row, numberBasedLocation.col);
            }
        };

        $scope.resetFile = function() {
            $scope.preview = false;
            $scope.filteredMappings__.length = 0;
            $scope.sheets = [];
            $scope.template.templateUrl = null;
            $scope.template.templateSheets = null;
            $scope.template.sheet = null;
            $scope.templateFileName = null;
            $scope.templateFileArrayBuffer = null;
            $scope.filteredMappings__ = filterMappingsOnSheetName();
        };

        $scope.goBack = function() {
            $location.path('export-templates').search('');
        };

        $scope.enterFocusMode = function() {
            $timeout(function() {
                $rootScope.$broadcast('toggleSidebar');
            }, 500);
        };

        $scope.handsontableOnChange = function(fileName, fileAsArrayBuffer) {
            $timeout(function() {
                $scope.preview = true;
                $scope.templateFileArrayBuffer = fileAsArrayBuffer;
                $scope.templateFileName = fileName;
            }, 0);
        };

        $scope.onTemplateLoad = function(spreadsheetViewerInstance, sheets) {
            $timeout(function() {
                $scope.spreadsheetViewerInstance = spreadsheetViewerInstance;

                if (_.isEmpty($scope.template.templateSheets)) {
                    _.forEach(sheets, function(sheetName) {
                        $scope.sheets.push({
                            name: sheetName,
                            headerRow: 1,
                            dataRow: 2
                        });
                    });
                } else {
                    // Merge old sheets with new ones extracted from the template
                    $scope.sheets = _.filter($scope.template.templateSheets, function(sheet) {
                        return sheets.indexOf(sheet.name) != -1;
                    });
                }

                if ($scope.sheets.length != 0) {
                    $scope.template.sheet = $scope.sheets[0];
                }

                $scope.$emit('template-file-loaded');
            }, 0);
        };

        $scope.onSheetChange = function() {
            $scope.filteredMappings__ = filterMappingsOnSheetName();
        };

        $scope.onDropdownSheetChange = function() {
            $scope.spreadsheetViewerInstance.selectSheet($scope.template.sheet.name);
            $scope.onSheetChange();
        };

        $scope.onTemplateSheetChange = function(e) {
            $timeout(function() {
                $scope.template.sheet = $scope.sheets[e.detail.sheet];
                $scope.onSheetChange();
            }, 0);
        };

        $scope.onTemplateCellChange = function(event) {
            var sheetName = _.get($scope.template.sheet, 'name');
            var templateFileCol = event.detail.range[1];
            var templateFileRow = event.detail.range[0];
            var mappingsRowCol = $scope.gridApi.cellNav.getFocusedCell();

            // If the 'location' cell of the mapping entries is selected/focused, then fill the cell's location.
            if (mappingsRowCol != null && mappingsRowCol.col.colDef.name === 'location') {
                $timeout(function() {
                    $scope.filteredMappings__[mappingsRowCol.row.index].location =
                        sheetName +
                        '!' +
                        TemplateMappingService.convertToA1Notation(templateFileCol + 1) + (templateFileRow + 1);
                }, 0);
            }
        };

        $scope.uploadSheetViaHandsontable = function() {
            $timeout(function() {
                document.getElementById('handsontableFileUploader').click();
            }, 0);
        };

        $scope.fillHeader = function() {
            if ($scope.preview && $scope.templateFileArrayBuffer && !_.isNil($scope.template.sheet) && _.isNumber($scope.template.sheet.headerRow)) {
                var workbook = SheetJSService.readFile($scope.templateFileArrayBuffer);

                var sheet = workbook.Sheets[$scope.template.sheet.name];
                var headerCells = SheetJSService.getRowCells(sheet, $scope.template.sheet.headerRow - 1);
                _.forEach(headerCells, function(headerCell) {
                    var locationString = $scope.template.sheet.name + '!' + headerCell.location;

                    // Prevent duplication of mapping entry based on its location
                    var existingLocation = _.find($scope.template.mappings, { location: locationString });
                    if (_.isNil(existingLocation)) {
                        $scope.template.mappings.push({
                            id: getLastEntryId(),
                            externalName: headerCell.value,
                            location: locationString,
                            internalName: '',
                            mappingFunction: ''
                        });
                    }
                });
                $scope.filteredMappings__ = filterMappingsOnSheetName();
            }
        };

        $scope.uploadFile = function() {
            return {
                url: lax_rest_url('assets'),
                reset: true,
                uploadComplete: function(response) {
                    var template = response.map(function(asset) {
                        var result = {};
                        result.url = asset.url;
                        result.gathering = asset.gatheringKey;
                        result.path = asset.path;
                        result.name = asset.path.substr(1); //remove trailing /
                        return result;
                    })[0];

                    $scope.template.templateUrl = template.url;

                    loadTemplateFile($scope.template.templateUrl);
                }
            };
        };

        $scope.onDeleteMappingEntry = function(entry) {
            $scope.template.mappings = _.filter($scope.template.mappings, function(mapping) {
                return mapping.id != entry.id;
            });
            $scope.filteredMappings__ = filterMappingsOnSheetName();
        };

        $scope.addResetMappingFunctionOption = function() {
            $scope.mappingFunctions.unshift({
                label: 'None'
            });
        };

        $scope.addResetModelAttributeOption = function() {
            $scope.filteredLayoutAttributes.unshift({
                name: 'None'
            });
        };

        $scope.onLoad = function() {
            $scope.filteredLayoutAttributes = TemplateMappingService.getFilteredLayoutAttributes();
            $scope.allAttributes = TemplateMappingService.getAllModelAttributes();
            $scope.addResetModelAttributeOption();
            if (!_.isEmpty($rootScope.dataModel.getMappingFunctions())) {
                $scope.mappingFunctions = _.values($rootScope.dataModel.getMappingFunctions());
                $scope.translateMappingFunctions($scope.mappingFunctions);
                $scope.addResetMappingFunctionOption();
            } else {
                $scope.mappingFunctions = [];
            }
            getTemplate();
        };

        $scope.translateMappingFunctions = function(mappingFunctions) {
            _.forEach(mappingFunctions, function(mappingFunction) {
                mappingFunction.translatedLabel = $rootScope.translateMappingFunction(mappingFunction);
                mappingFunction.translatedDescription = $rootScope.translateMappingFunctionDescription(mappingFunction);

                _.forEach(mappingFunction.parameters, function(parameter){
                    parameter.translatedLabel = $rootScope.translateMappingFunctionParam(mappingFunction, parameter);
                    parameter.translatedDescription = $rootScope.translateMappingFunctionParamDescription(mappingFunction, parameter);
                });
            });
        };

        $scope.$on('$destroy', function(){
            $rootScope.$broadcast('toggleSidebar');
        });

        function loadTemplateFile(templateUrl) {
            if (_.isNil(templateUrl)) {
                return;
            }

            var templateName = extractTemplateNameFromUrl(templateUrl);

            var request = new XMLHttpRequest();
            request.responseType = 'blob';
            request.onload = function() {
                var reader = new FileReader();

                reader.onload = function (e) {
                    var arrayBuffer = e.target.result;
                    $scope.handsontableOnChange(templateName, arrayBuffer);
                };

                reader.readAsArrayBuffer(request.response);
            };
            request.open("GET", templateUrl, true);
            request.send();
        }

        function extractTemplateNameFromUrl(url) {
            return url.substring(url.lastIndexOf('/') + 1);
        }

        function init() {
            if ($rootScope.isDataModelLoaded) {
                $scope.onLoad();
            } else {
                $scope.$on('dataModelLoaded', function() {
                    $scope.onLoad();
                });
            }
        }

        function getTemplate() {
            if (_.isEmpty($routeParams.name) || $routeParams.name === '_new') {
                $scope.template = {
                    templateType: $scope.TYPE_LIST,
                    templateCustomAttributes: [],
                    mappings: []
                };
            } else {

                var key = $routeParams.name;
                ExportMappingResource.get({
                    key: key
                },function(response) {

                    $scope.template = response.data;
                    $scope.template.mappings = prepareMappings($scope.template.mappings);

                    if ($scope.template.templateUrl) {
                        loadTemplateFile($scope.template.templateUrl);
                    }

                    $scope.filteredMappings__ = filterMappingsOnSheetName();
                }, function(response) {
                    growl.error('TEMPLATE_MAPPING.LOAD_FAILED');
                });
            }
        }

        function filterMappingsOnSheetName() {
            return _.filter($scope.template.mappings, function(mapping) {
                if (mapping.location === '') {
                    return true;
                } else if (_.isNil($scope.template.sheet) || _.isNil($scope.template.sheet.name)) {
                    return true;
                } else {
                    var sheetName = TemplateMappingService.getSheetNameFromLocationString(mapping.location);
                    if (_.isNil(sheetName)) {
                        sheetName = $scope.template.sheet.name;
                    }
                    return sheetName === $scope.template.sheet.name;
                }
            });
        }

        function prepareMappings(mappings) {
            return _.map(mappings, function(map, index) {
                if (map.internalName) {
                    map.internalName__ = _.find($scope.filteredLayoutAttributes, function(attribute) {
                        return map.internalName === attribute.value;
                    });
                }

                if (map.mappingFunction) {
                    map.mappingFunction__ = _.find($scope.mappingFunctions, function(mappingFunction) {
                        return mappingFunction.name === map.mappingFunction;
                    });
                }

                map.id = index + 1;
                return map;
            });
        }

        function getLastEntryId() {
            var maxIdEntry = _.maxBy($scope.template.mappings, 'id');

            if (maxIdEntry) {
                return maxIdEntry.id + 1;
            } else {
                return 0;
            }
        }

        function contentTypeForTemplateType(type) {
            if (type == $scope.TYPE_LIST) {
                return EXCEL_CONTENT_TYPE;
            } else if (type == $scope.TYPE_PAGE) {
                return ZIP_CONTENT_TYPE;
            }
            return null;
        }

        init();
    });
