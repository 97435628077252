angular.module('llax')
    .controller('AssetFoldersRenameFolderController', function ($modalInstance, $scope, AssetFoldersResource, AssetFoldersService, data, growl) {
        var LINK_TYPE_ASSET = AssetFoldersService.LINK_TYPE_ASSET;
        $scope.path = data.path;
        $scope.name = data.name;
        $scope.linkType = data.linkType;
        $scope.viewModel = {
            name: $scope.name
        };
        $scope.saving = false;
        $scope.onDone = data.onDone;

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };

        $scope.renameFolder = function () {
            $scope.saving = true;
            var path = '';

            // Just add a '/' in case of root folder
            if ($scope.path) {
                path = $scope.path + '/' + $scope.name;
            } else {
                path = $scope.name;
            }

            var renameFn = AssetFoldersResource(path).renameFolder;
            if ($scope.linkType == LINK_TYPE_ASSET) {
                renameFn = AssetFoldersResource(path).renameAsset;
            }

            renameFn({
                name: $scope.viewModel.name
            }, function (response) {
                $scope.saving = false;
                growl.success('ASSET_FOLDER.UPDATED_SUCCESSFULLY');
                $scope.onDone(path, $scope.viewModel.name);
                $scope.cancel();
            }, function (error) {
                $scope.saving = false;

                if (error.data.message) {
                    growl.error(error.data.message, { variables: { name: $scope.viewModel.name }});
                } else {
                    growl.error('ASSET_FOLDER.ERROR_OCCURRED');
                }
            });
        };
    });
