(function () {
    'use strict';

var vendorDependencies = [
        'ngRoute',
        'ngCookies',
        'ui.grid',
        'ui.grid.autoResize',
        'ui.grid.cellNav',
        'ui.grid.edit',
        'ui.grid.exporter',
        'ui.grid.infiniteScroll',
        'ui.grid.moveColumns',
        'ui.grid.pinning',
        'ui.grid.resizeColumns',
        'ui.grid.saveState',
        'ui.grid.selection',
        'pascalprecht.translate',
        'ngAnimate',
        'ngSanitize',
        'ui.select',
        'ui.bootstrap',
        'ui.bootstrap.datetimepicker',
        'angular-growl',
        'ui.dashboard',
        'angularFileUpload',
        'ngImgCrop',
        'ui.tree',
        'ui.tree-filter',
        'tmh.dynamicLocale',
        'infinite-scroll',
        'ui.codemirror',
        angularDragula(angular)
    ],
    appDependencies = [
        'llax.routes',
        'llax.templates',
        'llax.exceptions',
        'llax.services',
        'llax.directives',
        'llax.directives.itemList',
        'llax.filters',
        'llax.app'
    ];

for (var i = 0; i < appDependencies.length; i++) {
    try {
        angular.module(appDependencies[i]);
    } catch (e) {
        angular.module(appDependencies[i], []);
    }
}
var dependencies = vendorDependencies.concat(appDependencies);
window.animationClassNameFilter = /animate-if|slide-up/;
angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);

window.app = angular.module('llax', dependencies)
    .constant('AppConstants', {
        'AUTHENTICATION_TOKEN': 'AUTHENTICATION_TOKEN'
    })
    .constant('HttpHeader', {
        'ACCEPT': 'Accept',
        'ACCEPT_LANGUAGE': 'Accept-Language',
        'CONTENT_TYPE': 'Content-type',
        'CURSOR': 'x-cursor',
        'ETAG': 'ETag',
        'IF_NONE_MATCH': 'If-None-Match',
        'ERROR': 'X-Error',
        'ITEM_CURSOR': 'x-item-cursor',
        'ITEMS_FOUND': 'x-items-found',
        'ITEMS_MAX': 'x-items-max',
        'LOGGING_IN': 'X-SyncManager-LoggingIn',
        'LOGGED_IN': 'X-SyncManager-LoggedIn',
        'MESSAGE_CURSOR': 'x-message-cursor',
        'SSO_POST': 'X-SyncManager-SSO-POST',
        'SSO_REDIRECT' : 'X-SyncManager-SSO-Redirect',
        'SYNC_MANAGER': 'X-SyncManager'
    })
    .constant('HttpStatus', {
        'OK': 200,
        'SEE_OTHER': 303,
        'NOT_MODIFIED': 304,
        'BAD_REQUEST': 400,
        'UNAUTHORIZED': 401,
        'PAYMENT_REQUIRED': 402,
        'FORBIDDEN': 403,
        'NOT_FOUND': 404,
        'CONFLICT': 409,
        'PRECONDITION_FAILED': 412,
        'DATA_MODEL_INVALID': 420,
        'RETRY_LATER': 422
    })
    .constant('HttpTimeout', 65)
    .constant('ErrorCode', {
        'UNSPECIFIED': -1,
        'NONE': 0,
        'SEARCH_QUERY_INVALID': 301,
        'SEARCH_QUERY_TOO_LONG': 302,
        'BULK_SEARCH_STILL_ACTIVE': 401
    })
    .constant('AdditionalLicenseType', {
        'ADDITIONAL_USERS': 'ADDITIONAL_USERS',
        'ADDITIONAL_ITEMS': 'ADDITIONAL_ITEMS',
        'DATA_MODELS': 'DATA_MODELS',
        'FEATURES': 'FEATURES'
    })
    .constant('DataModelCustomizationConstants', {
        'CODELIST_TYPES': ["Codelist", "OpenCodelist", "CodelistSet", "OpenCodelistSet"],
        'SUPPORTED_TYPES': ["String", "Integer", "Float", "Codelist", "OpenCodelist", "CodelistSet", "OpenCodelistSet"]
    })
    .constant('DefaultUiConfig', {
        'login_logo_src': '../images/byrd-logo-claim-w.png',
        'login_logo_alternative_src': '../images/byrd-logo-claim-alternative.png',
        'login_logo_name': 'BYRD Logo',
        'login_logo_bc_src': '../images/logo-orbital.png',
        'login_logo_bc_name': 'Bayard Consulting Logo',
        'login_logo_bc_url': 'https://www.bayard-consulting.com/',
        'copyright_bc_name': 'Bayard Consulting',
        'copyright_bc_url': 'https://www.bayard-consulting.com/',
        'navbar_logo_href': '/dashboard',
        'navbar_logo_src': '../images/byrd-logo-w.png',
        'navbar_logo_name': 'BYRD Logo'
    })
    .config(function($animateProvider,
                     $compileProvider,
                     $controllerProvider,
                     $filterProvider,
                     $httpProvider,
                     $locationProvider,
                     $provide,
                     $routeProvider,
                     $translateProvider,
                     datepickerConfig,
                     datepickerPopupConfig,
                     growlProvider,
                     HttpHeader,
                     HttpStatus,
                     HttpTimeout,
                     RoutesProvider) {

        app.controller = $controllerProvider.register;
        app.directive = $compileProvider.directive;
        app.filter = $filterProvider.register;
        app.factory = $provide.factory;
        app.service = $provide.service;

        $animateProvider.classNameFilter(animationClassNameFilter);

        // angular-growl-2 config
        growlProvider.globalTimeToLive({success: 3000, warning: 5000, info: 3000});
        growlProvider.globalDisableCountDown(true);
        growlProvider.globalPosition('top-center');
        growlProvider.globalReversedOrder(true);

        var authFunction = function($q, $http, Auth, $rootScope, neededRights, organizationRolesDenied, callback) {
            var deferred = $q.defer();
            Auth.userDetails().then(function(response) {
                if (organizationRolesDenied && Auth.hasDeniedOrganizationRoles(organizationRolesDenied)) {
                    deferred.reject();
                } else {
                    var user = response;

                    $rootScope.$broadcast('UserIsLoggedIn');

                    if (neededRights && !Auth.hasRights(neededRights)) {
                        deferred.reject(); // you could optionally pass error data here
                    } else {
                        if (_.isFunction(callback)) {
                            callback(deferred);
                        } else {
                            deferred.resolve(user);
                        }
                    }
                }
            }, function errorCallback(response) {
                Auth.isLoggedIn = false;
                    $rootScope.user = {
                        isLoggedIn: false
                    };
                deferred.reject();
            });
            return deferred.promise;
        };

        var dependencyResolver = function($q, $rootScope, deferred, dependencies) {
            if ($rootScope.user && $rootScope.user.isLoggedIn) {
                if (!$rootScope.inputTemplatesRegistered) {
                    requirejs.undef(dependencies[0], function() {
                        deferred.resolve();
                    });
                }

                // Extend the dependencies load timeout because of datamodel related scripts.
                requirejs.config({
                    waitSeconds: HttpTimeout, // Default is 7 seconds.
                });

                // jquery is loaded on its own, we have to provide it to requirejs
                // for any jquery dependencies that gets loaded using requirejs during
                // the app lifetime.
                define('jquery', [], function() { return window.jQuery; });

                // TODO: Add etag 'caching'!
                requirejs(dependencies, function() {
                    deferred.resolve();
                });
            } else {
                console.log("Not authorized, avoiding require", dependencies, $rootScope.user);
            }
        };

        var licensedFeatureResolver = function($q, Auth, feature) {
            var deferred = $q.defer();
            Auth.hasLicensedFeature(feature).then(function() {
                deferred.resolve(feature);
            }, function() {
                deferred.reject();
            });
            return deferred.promise;
        };

        var settingFeatureResolver = function($q, Auth, settingFeature) {
            var deferred = $q.defer();
            Auth.hasSettingFeature(settingFeature).then(function() {
                deferred.resolve(settingFeature);
            }, function() {
                deferred.reject();
            });
            return deferred.promise;
        };

        function registerRoute(route) {

            var routeConfig = {
                templateUrl: route.templateUrl,
                controller: route.controller,
                resolve: route.resolve || {}
            };

            if (!_.isUndefined(route.reloadOnSearch)) {
                routeConfig.reloadOnSearch = route.reloadOnSearch;
            }

            if (!route.insecure) {
                var authorizeFunction;
                if (route.dependencies) {
                    authorizeFunction = /* @ngInject */ function($q, $http, Auth, $rootScope) {
                        return authFunction($q, $http, Auth, $rootScope, route.rights, route.organizationRolesDenied, function(deferred) {
                            dependencyResolver($q, $rootScope, deferred, route.dependencies);
                        });
                    };
                } else {
                    authorizeFunction = /* @ngInject */ function($q, $http, Auth, $rootScope) {
                        return authFunction($q, $http, Auth, $rootScope, route.rights, route.organizationRolesDenied);
                    };
                }
                routeConfig.resolve.authorize = authorizeFunction;
            }

            if (route.licensedFeature) {
                routeConfig.resolve.licensedFeature = /* @ngInject */ function($q, Auth) {
                    return licensedFeatureResolver($q, Auth, route.licensedFeature);
                };
            }

            if (route.settingFeature) {
                routeConfig.resolve.settingFeature = /* @ngInject */ function($q, Auth) {
                    return settingFeatureResolver($q, Auth, route.settingFeature);
                };
            }

            if (route.urls) {
                angular.forEach(route.urls, function(url) {
                    $routeProvider.when(url, routeConfig);
                });
            } else {
                $routeProvider.when(route.url, routeConfig);
            }

        }

        var routes = RoutesProvider.$get();

        angular.forEach(routes, function(route) {
            registerRoute(route);
        });

        $routeProvider.otherwise({redirectTo: '/'});

        $locationProvider.html5Mode(true);

        $provide.factory('httpInterceptor', function($cookies, $location, $q, $rootScope, $translate, growl, AppConstants, HttpTimeout) {
            return {
                request: function(config) {

                    // Actual timeout on AppEngine is 60s, but sometimes it 'gracefully' allows a couple of seconds more.
                    config.timeout = HttpTimeout * 1000;

                    // Add the current "loggingIn" status as header
                    if ($rootScope.loggingIn) {
                        config.headers[HttpHeader.LOGGING_IN] = true;
                    }

                    // Set the accepted language to use
                    if ($rootScope.language) {
                        config.headers[HttpHeader.ACCEPT_LANGUAGE] = $rootScope.language;
                    }

                    return config;
                },
                response: function(response) {

                    // Reset any error
                    $rootScope.error = null;

                    // Reset the "loggingIn" status if the request was a successful login request
                    var loggedIn = response.headers(HttpHeader.LOGGED_IN);
                    if (loggedIn) {
                        $rootScope.loggingIn = false;
                    } else if ($rootScope.handleSSOResponse(response)) {
                        return $q.reject(response);
                    }

                    return response;
                },
                responseError: function(response) {

                    if (response.status === HttpStatus.UNAUTHORIZED) {

                        $cookies.remove(AppConstants.AUTHENTICATION_TOKEN);
                        if (!$rootScope.loggingIn && !$rootScope.loggingOut) {
                            if ($location.path() !== '/login' &&
                                $location.path() !== '/register' &&
                                $location.path() !== '/revalidateAccount' &&
                                !$location.path().startsWith('/resetpassword')) {
                                $rootScope.deeplink = $location.path();
                                $rootScope.$broadcast('InvalidToken');
                            }
                        }

                    } else if (response.status === HttpStatus.PAYMENT_REQUIRED) {

                        console.log("Payment required: ", response.data);
                        var details = "";
                        var exceedances = response.data.exceedances;
                        for (var key in exceedances) {
                            if (details.length > 0) {
                                details += ", ";
                            }
                            details += $translate.instant('USAGE_LIMIT.' + key, {exceedance: exceedances[key].value});
                        }
                        growl.warning($translate.instant('PAYMENT_REQUIRED', {details: details}), {ttl: 15000});

                    } else if (response.status !== HttpStatus.NOT_MODIFIED) {

                        // TODO: Logging "response" as object is not feasible in scripted environments, e.g. in tests.
                        // There instead we should use this:
                        // console.log("JSError response:", JSON.stringify(response, null, 2));
                        console.log("JSError response:", response);
                        jQuery("body").attr("JSError", response.data);

                    }

                    return $q.reject(response);
                }
            };
        });

        $httpProvider.defaults.headers.common[HttpHeader.SYNC_MANAGER] = true;
        $httpProvider.interceptors.push('httpInterceptor');
        $httpProvider.useLegacyPromiseExtensions = false;
        $httpProvider.useApplyAsync(true);

        // Default datepicker configuration
        datepickerConfig.startingDay = 1;
        datepickerPopupConfig.datepickerPopup = 'mediumDate';
        datepickerPopupConfig.showButtonBar = true;

        // "Decorate" the original popup wrap directive to add a better close handling
        $provide.decorator('datepickerPopupWrapDirective', function($delegate, $timeout) {
            var directive = $delegate[0];
            var _link = directive.link;
            directive.compile = function() {
                return function(scope, element, attrs) {

                    // Apply original directive
                    _link.apply(this, arguments);

                    // Close datepicker on click on outer "ul" element, otherwise the picker could stay open due to lost focus
                    function click(evt) {
                        var tagName = evt.target.tagName.toLowerCase();
                        if (tagName == "ul" && scope.isOpen) {
                            $timeout(function() {
                                scope.close();
                            }, 1);
                        }
                    }
                    element.bind('click', click);

                    // Close datepicker on click on "ui-grid" element
                    element.bind('focusout',function(evt){
                        if(!_.isNil(event.relatedTarget) && _.includes(event.relatedTarget.className, "ui-grid")){
                            scope.close();
                        }
                    });

                    // Close datepicker on "tab"/"shift-tab", otherwise the picker would stay open due to lost focus
                    var _keydown = scope.keydown;
                    scope.keydown = function(evt) {
                        if (evt.which === 9 && scope.isOpen) {
                            var tagName = evt.target.tagName.toLowerCase();
                            if ((tagName == "table" && (evt.shiftKey || !scope.showButtonBar)) ||
                                (tagName == "button" && !evt.shiftKey && evt.target.classList.contains('btn-success'))) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                scope.close();
                                return true;
                            }
                        }
                        return _keydown(evt);
                    };

                };
            };
            return $delegate;
        });

        $provide.decorator('modalWindowDirective', function($delegate, $rootScope, $modalStack, $timeout, ChannelService, $dialogs, $document) {
            var directive = $delegate[0];
            var link = function myLinkFnOverride(scope, element, attrs) {

                element.addClass(attrs.windowClass || '');
                scope.size = attrs.size;

                $timeout(function () {
                    // trigger CSS transitions
                    scope.animate = true;
                    if (!element[0].querySelectorAll('[autofocus]').length) {
                        element[0].focus();
                    }
                });

            };

            directive.compile = function() {
                return function(scope, element, attrs) {
                    link.apply(this, arguments);
                };
            };

            return $delegate;
        });

        $provide.decorator('$log', ['$delegate', function ($delegate) {

            var originalDebug = $delegate.debug;

            // Disable `$log.debug` calls when it's not explicitly enabled
            $delegate.debug = function () {

                if (!window.__DEBUG_LOGS_ENABLED) {
                    return;
                }

                originalDebug.apply(null, arguments);
            };

            return $delegate;
        }]);

    })
    .run(function($rootScope, $route, $location, $document) {

        var BACKSPACE_KEYCODE = 8;
        var original = $location.path;
        $location.displayUrl = function(path) {
            if (path === $location.path()) {
                return;
            }
            var lastRoute = $route.current;
            var cancelListener = $rootScope.$on('$locationChangeSuccess', function() {
                $route.current = lastRoute;
                cancelListener();
            });
            return original.apply($location, [path]);
        };

        // feature for disabling backspace navigation (see LAX-3421)
        $document.bind("keydown keypress", function(evt) {
            if (evt.keyCode === BACKSPACE_KEYCODE) {

                if (!_.isNil(evt.target.editor)) {
                    // CodeMirror & ProseMirror editors
                    return;
                }

                var allowed = /INPUT|SELECT|TEXTAREA/i;
                if (!allowed.test(evt.target.tagName) || evt.target.disabled || evt.target.readOnly) {
                    evt.preventDefault();
                }
            }
        });

    });

}());
