try {
  templateModule = angular.module('llax.templates');
} catch (e) {
  templateModule = angular.module('llax.templates', []);
}
templateModule
    .run(["$templateCache", function($templateCache) {
        $templateCache.put("template/accordion/accordion-group.html",
            "<div class=\"panel panel-default\">\n" +
            "  <div class=\"panel-heading\" ng-click=\"toggleOpen()\">\n" +
            "    <h4 class=\"panel-title\">\n" +
            "      <a href class=\"accordion-toggle\"  accordion-transclude=\"heading\"><span ng-class=\"{'text-muted': isDisabled}\">{{heading}}</span></a>\n" +
            "    </h4>\n" +
            "  </div>\n" +
            "  <div class=\"panel-collapse\" collapse=\"!isOpen\">\n" +
            "     <div class=\"panel-body\" ng-transclude></div>\n" +
            "  </div>\n" +
            "</div>\n" +
            "");

        // bring back default checkbox behavior for row selection in ui-grid
        $templateCache.put('ui-grid/selectionRowHeaderButtons',
            "<input type=\"checkbox\" id=\"row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-checkbox\" class=\"ui-grid-selection-row-header-button\" data-ng-checked=\"row.isSelected\" data-ng-click=\"selectButtonClick(row, $event)\" tabindex=\"-1\">"
        );
        $templateCache.put('ui-grid/selectionSelectAllButtons',
        "<div role=\"checkbox button\" tabindex=\"-1\" class=\"ui-grid-selection-row-header-buttons ui-grid-select-all\" data-ng-click=\"headerButtonClick($event)\" data-ng-keydown=\"headerButtonKeyDown($event)\"><input type=\"checkbox\" data-ng-model=\"grid.selection.selectAll\" data-ng-click=\"headerButtonClick($event)\" data-ng-keydown=\"headerButtonKeyDown($event)\"></div>"
        );

        // ui-bootstrap timepicker:
        // add arrow keys to change hours/minutes
        // hide arrow buttons
        $templateCache.put("template/timepicker/timepicker.html",
            "<div class=\"row mv\">\n" +
            "  <div class=\"col-lg-12\">\n" +
            "    <table data-ng-init=\"showMeridian = false\">\n" +
            "      <tbody>\n" +
            "        <tr class=\"text-center\" data-ng-if=\"showSpinners\">\n" +
            "          <td><a ng-click=\"incrementHours()\" class=\"btn btn-link\"><span class=\"glyphicon glyphicon-chevron-up\"></span></a></td>\n" +
            "          <td>&nbsp;</td>\n" +
            "          <td><a ng-click=\"incrementMinutes()\" class=\"btn btn-link\"><span class=\"glyphicon glyphicon-chevron-up\"></span></a></td>\n" +
            "          <td ng-show=\"showMeridian\"></td>\n" +
            "        </tr>\n" +
            "        <tr>\n" +
            "          <td style=\"width:50px;\" class=\"form-group\" ng-class=\"{'has-error': invalidHours}\">\n" +
            "            <input type=\"text\" ng-model=\"hours\" ng-blur=\"updateHours()\" class=\"form-control text-center\" ng-mousewheel=\"incrementHours()\" data-on-arrow-keys data-arrow-up=\"incrementHours()\" data-arrow-down=\"decrementHours()\" ng-readonly=\"readonlyInput\" regex-validator=\"\\b([1-9]|1[0-9]|2[0-3])\\b\">\n" +
            "          </td>\n" +
            "          <td>:</td>\n" +
            "          <td style=\"width:50px;\" class=\"form-group\" ng-class=\"{'has-error': invalidMinutes}\">\n" +
            "            <input type=\"text\" ng-model=\"minutes\" ng-blur=\"updateMinutes()\" class=\"form-control text-center\" ng-mousewheel=\"incrementMinutes()\" data-on-arrow-keys data-arrow-up=\"incrementMinutes()\" data-arrow-down=\"decrementMinutes()\" ng-readonly=\"readonlyInput\" regex-validator=\"\\b([1-9]|[1-5][0-9]|59)\\b\">\n" +
            "          </td>\n" +
            "          <td data-ng-if=\"showMeridian\"><button type=\"button\" class=\"btn btn-default text-center\" ng-click=\"toggleMeridian()\">{{meridian}}</button></td>\n" +
            "        </tr>\n" +
            "        <tr class=\"text-center\" data-ng-if=\"showSpinners\">\n" +
            "          <td><a ng-click=\"decrementHours()\" class=\"btn btn-link\"><span class=\"glyphicon glyphicon-chevron-down\"></span></a></td>\n" +
            "          <td>&nbsp;</td>\n" +
            "          <td><a ng-click=\"decrementMinutes()\" class=\"btn btn-link\"><span class=\"glyphicon glyphicon-chevron-down\"></span></a></td>\n" +
            "          <td ng-show=\"showMeridian\"></td>\n" +
            "        </tr>\n" +
            "      </tbody>\n" +
            "    </table>\n" +
            "  </div>\n" +
            "</div>\n" +
            "");

            // ui-grid grid menu button template, overridden to show a tooltip to the button.
            // Original template can be found here ->
            //    https://github.com/angular-ui/ui-grid/blob/master/packages/core/src/templates/ui-grid/ui-grid-menu-button.html
            $templateCache.put("ui-grid/ui-grid-menu-button",
                '<div class="ui-grid-menu-button" title="{{::\'CUSTOMIZE_TABLE\' | translate }}">' +
                '   <div role="button" ' +
                '        ui-grid-one-bind-id-grid="\'grid-menu\'" ' +
                '        ui-grid-one-bind-aria-label="i18n.aria.buttonLabel" ' +
                '        tabindex="0" ' +
                '        class="ui-grid-icon-container" ' +
                '        ng-click="toggleMenu()" ' +
                '        ng-keydown="toggleOnKeydown($event)" ' +
                '        aria-expanded="{{shown}}" ' +
                '        aria-haspopup="true"> ' +
                '      <i class="ui-grid-icon-menu" ' +
                '         ui-grid-one-bind-aria-label="i18n.aria.buttonLabel">&nbsp;</i>' +
                '    </div>' +
                '    <div ui-grid-menu menu-items="menuItems"></div>' +
                '</div>"');
    }]);

// ui-select search input wouldn't focus, gets blocked by ngAnimate
// https://github.com/angular-ui/ui-select/issues/1560#issuecomment-228033704
angular.module('ui.select')
    .run(['$animate', function($animate) {
        var origEnabled = $animate.enabled;
        $animate.enabled = function(elem) {
            if (arguments.length !== 1) {
                return origEnabled.apply($animate, arguments);
            } else if (origEnabled(elem)) {
                return animationClassNameFilter.test(elem.classNames);
            }
            return false;
        };
    }]);
