angular.module('llax.directives')
    // FIXME: Tooltip functionality should be replaced in favor for 'hint-tooltip-directive.js'
    .directive('mappingFunctionHint', function($timeout, $translate) {
        return {
            strict: 'E',
            scope: {
                mappingFunctionDefinition: '=',
                mappingEntry: '=',
                mappingParameters: '='
            },
            template:
                '<span '+
                    'id="mapping-function-hint-{{mappingEntry.id}}" ' +
                    'data-ng-if="shouldShowHint && isValidSimpleMappingFunction" ' +
                    'data-ng-mouseenter="showIsValidTooltip()" ' +
                    'data-ng-mouseleave="hideTooltip()" ' +
                    'class="cursor-pointer" ' +
                    'style="color: gray;">' +
                        '<i style="color: green;" class="glyphicon glyphicon-ok-circle"></i>' +
                '</span>' +
                '<span ' +
                    'id="mapping-function-hint-{{mappingEntry.id}}" ' +
                    'data-ng-if="shouldShowHint && !isValidSimpleMappingFunction" ' +
                    'data-ng-mouseenter="showIsInvalidTooltip()" ' +
                    'data-ng-mouseleave="hideTooltip()" ' +
                    'class="cursor-pointer" ' +
                    'style="color: gray;">' +
                    '<i style="color: red;" class="glyphicon glyphicon-remove-circle"></i>' +
                '</span>',
            link: function($scope) {
                $scope.shouldShowHint = false;
                $scope.isValidSimpleMappingFunction = false;

                $scope.$watch('mappingParameters', function(newParameters) {
                    $scope.mappingParameters = newParameters;
                    update($scope.mappingFunction, newParameters);
                });

                $scope.$watch('mappingFunctionDefinition', function(newMappingFunction) {
                    $scope.mappingFunction = newMappingFunction;
                    update(newMappingFunction, $scope.mappingParameters);
                });

                $scope.showIsValidTooltip = function() {
                    $scope.showTooltip('<p>' + $translate.instant('TEMPLATE_MAPPINGS.ALL_PARAMS_SET') + '</p>');
                };

                $scope.showIsInvalidTooltip = function() {
                    var missingParams = getMissingParameters($scope.mappingFunctionDefinition, $scope.mappingParameters);
                    var content = '<p>' + $translate.instant('TEMPLATE_MAPPINGS.PARAMS_NOT_SET') + '</p>';
                    content += '<ul>';

                    _.forEach(missingParams, function(param) {
                        content += '<li>' + param.name + '</li>';
                    });
                    content += '</ul>';
                    $scope.showTooltip(content);
                };

                $scope.showTooltip = function(content) {
                    function customRendererFn() {
                        var attributeStart = '<div class="mapping-function-params-hint">';
                        var attributeEnd = '</div>' +
                            '<span class="hopscotch-arrow"></span>';
                        return attributeStart + content + attributeEnd;
                    }

                    var calloutId = 'callout_' + $scope.mappingEntry.id;
                    var calloutConfig = {
                        id: calloutId,
                        target: 'mapping-function-hint-' + $scope.mappingEntry.id,
                        placement: 'left',
                        content: '',
                        customRenderer: customRendererFn
                    };

                    var calloutMgr = hopscotch.getCalloutManager();
                    calloutMgr.removeCallout(calloutId);

                    var calloutEle = calloutMgr.createCallout(calloutConfig);
                    // Hack: override !important css rules, with inline precedence
                    calloutEle.element.style.cssText += 'border: none !important; z-index: 1049;';
                };

                $scope.hideTooltip = function removeTooltip() {
                    $timeout(function() {
                        var calloutMgr = hopscotch.getCalloutManager();
                        calloutMgr.removeCallout('callout_' + $scope.mappingEntry.id);
                    }, 200);
                };

                function update(mappingFunctionDefinition, parameters) {
                    if (!_.isEmpty(mappingFunctionDefinition)) {
                        $scope.shouldShowHint = true;

                        var missingParameters = getMissingParameters(mappingFunctionDefinition, parameters);
                        if (missingParameters.length === 0) {
                            $scope.isValidSimpleMappingFunction = true;
                        } else {
                            $scope.isValidSimpleMappingFunction = false;
                        }
                    } else {
                        // No mapping function is selected, no need for hint
                        $scope.shouldShowHint = false;
                    }
                }

                function getMissingParameters(mappingFunctionDefinition, parameters) {
                    var requiredParameters = _.filter(mappingFunctionDefinition.parameters, function(parameter) {
                        return !parameter.optional && _.isNil(parameter.defaultValue);
                    });
                    var actualParameters = _.filter(_.keys(parameters), function(parameterName) {
                        return !_.isEmpty(parameters[parameterName]);
                    });

                    return _.differenceWith(
                            requiredParameters,
                            actualParameters,
                            function(firstSetEntry, secondSetEntry) {
                                return firstSetEntry.name === secondSetEntry;
                            }
                        );
                }
            }
        };
    });