angular.module('llax')
    .controller('CreateUserController',
        function($modalInstance, $scope, $translate, data, growl, InviteByEmailResource, UserResource) {

            function getRoles() {
                RolesService.getAllRoles().then(function(roles) {
                    $scope.roles = _.map(roles, function(elem) {
                        return {
                            id: elem,
                            text: elem
                        };
                    });
                });
            }

            function getFilters() {
                UserResource.filters(function(filters) {
                    $scope.filters = _.map(filters, function(filter) {
                        return {
                            name: filter.name,
                            label: filter.label || filter.name
                        };
                    });
                    $scope.filters.unshift({
                        name: '',
                        label: $translate.instant('MAKE_SELECTION')
                    });
                });
            }

            $scope.inviteByEmail = function(userAccount) {

                var invitation = {
                    invitationType: "INVITED_COLLEAGUE",
                    invitedEmailAddress: userAccount.invitationEmail,
                    roles: userAccount.roles,
                    restrictionFilter: userAccount.restrictionFilter,
                    restrictionQuery: userAccount.restrictionQuery
                };

                InviteByEmailResource.save(invitation, function() {
                    growl.success("USER_MANAGEMENT.INVITATION_SENT");
                    $scope.newAccount = {};
                    $modalInstance.close(null);
                });
            };

            $scope.cancel = function() {
                $modalInstance.close(null);
            };

            (function init() {
                $scope.newAccount = {};

                if (_.isArray(data.allRoles)) {
                    $scope.roles = data.allRoles;
                } else {
                    getRoles();
                }
                if (_.isArray(data.filters)) {
                    $scope.filters = data.filters;
                } else {
                    getFilters();
                }
            })();
        });
