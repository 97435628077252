angular.module('llax')
    .controller('CreateTaskController',
        function($rootScope, $scope, $translate, $routeParams, $dialogs, growl, $modalInstance, TaskResource, TaskListsResource, data) {
            $scope.focusOn = true;
            $scope.attachment = undefined;
            $scope.filesSelected = false;
            $scope.fileUploadSettings = {
                showInlinepreviewInDropzone : true
            };

            $scope.editorOptions = {
                basic: {
                    height: '5em',
                    removePlugins: 'elementspath',
                    toolbar: [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic']
                    }, {
                        name: 'paragraph',
                        items: ['BulletedList', 'NumberedList']
                    }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                    }]
                }
            };

            function initTask() {
                var taskTags = $rootScope.dataModel.getTaskTags();
                $scope.taskTags = taskTags;
                var task = {};
                if (data.taskListId) {
                    task.taskListId = parseInt(data.taskListId, 10);
                } else if ($routeParams.taskListId) {
                    task.taskListId = parseInt($routeParams.taskListId, 10);
                }
                if (data.items) {
                    task.items = data.items;
                }
                if (data.selectionId) {
                    task.selectionId = data.selectionId;
                    delete task.items;
                }
                return task;
            }

            function loadTaskLists() {
                if (_.isEmpty(data.taskLists)) {
                    return TaskListsResource.query({});
                } else {
                    return data.taskLists;
                }
            }

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.setAssignee = function(user) {
                $scope.task.assignee = user.userId;
            };

            $scope.saveTask = function(task) {
                if (!task.title) {
                    growl.warning("TASK.MISSING_TASK_TITLE");
                } else {
                    var pathParams = {
                        taskId: task.id
                    };

                    TaskResource.save(pathParams, task, function(response) {
                        var newTask = response;
                        var hasAttachment = !_.isUndefined($scope.attachment);
                        if ($rootScope.userId == task.assignee)  {
                            $rootScope.$broadcast('selfTaskCreated', newTask);
                        }
                        if (!$scope.filesSelected) {
                            if (hasAttachment) {
                                var attachment = $scope.attachment;
                                var pathParams = {
                                    taskId: newTask.id
                                };
                                var comment = {
                                    assets: attachment,
                                    author: newTask.author,
                                    text: "",
                                    taskId: newTask.id
                                };

                                TaskResource.saveComment(pathParams, comment, function(response) {
                                    $scope.errorMessage = undefined;
                                });
                            }
                            $modalInstance.close(newTask);
                            growl.success("TASK.ADD_TASK_SUCCESS_MESSAGE");
                        } else {
                            var message = $translate.instant('NOTIFY.FILES_STILL_NOT_UPLOADED');
                            $dialogs.notify("", message);
                        }
                    });
                }
            };

            $scope.$on('filesSelected', function() {
                $scope.filesSelected = true;
            });

            $scope.uploadFileForItem = function(attributeName) {
                return {
                    url: lax_rest_url('assets'),
                    uploadComplete: function(response) {
                        $scope.attachment = $scope.attachment || [];
                        var assets = [];
                        if (_.isObject(response)) { // HTML5 browsers
                            assets = response.map(function(asset) {
                                var result = {};
                                result.url = asset.url;
                                result.name = asset.path.substr(1); //remove trailing /
                                result.contentType = asset.contentType;
                                return result;
                            });
                        } else if (_.isString(response)) { //IE9
                            response = response.substr(6).slice(0, -7);
                            var asset = JSON.parse(response);
                            asset.name = asset.path.substr(1);
                            assets.push(asset);
                        }
                        $scope.attachment = $scope.attachment.concat(assets);
                        $scope.filesSelected = false;
                    }
                };
            };

            function loadComments(taskId) {
                TaskResource.getComments({
                    taskId: taskId
                }, function(response) {
                    $scope.task.comments = response;
                });
            }

            $scope.saveComment = function(comment, taskId) {
                if (_.isEmpty(comment.text)) {
                    growl.warning('TASK.MISSING_TASK_TITLE');
                    return false;
                }

                if (!$scope.filesSelected) {
                    comment.author = $rootScope.user.userId;
                    comment.taskId = taskId;

                    var pathParams = {
                        taskId: taskId,
                        commentId: comment.id
                    };

                    TaskResource.saveComment(pathParams, comment, function(response) {
                        growl.success("SAVE_SUCCESS_MESSAGE");
                        loadComments(taskId);
                    });
                } else {
                    var message = $translate.instant('NOTIFY.FILES_STILL_NOT_UPLOADED');
                    $dialogs.notify("", message);
                }
            };

            (function init() {
                $scope.taskLists = loadTaskLists();
                $scope.task = initTask();
            })();
        }
    );
