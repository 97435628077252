angular.module('llax.directives')
    .directive('catalogMultiReferenceAttribute', function() {
        return {
            restrict: 'E',
            scope: {
                attribute: '=',
                item: '='
            },
            templateUrl: 'tpl/catalog/directive-catalog-multi-reference-attribute.tpl.html',
            controller: function($filter, $rootScope, $scope, ReferenceAttributesService) {
                $scope.dynamicColumns = {};
                $scope.dynamicDesktopData = {};
                $scope.dynamicMobileData = {};

                $scope.loadItemsForReference = function() {
                    var attribute = $scope.attribute;
                    var item = $scope.item;
                    var attributeName = attribute.name;
                    var referencedItems = item[attributeName];

                    if (_.isEmpty(referencedItems)) {
                        return;
                    }

                    var fields = ReferenceAttributesService.getAttributeFields(attribute);

                    var columns = [];
                    angular.forEach(fields, function(attrName) {
                        var memberAttribute = $rootScope.dataModel.attribute(attrName);
                        var column = {
                            name: memberAttribute.name,
                            typeName: memberAttribute.typeName,
                            label: $rootScope.translateAttribute(memberAttribute)
                        };
                        columns.push(column);
                    });
                    $scope.dynamicColumns[attribute.name] = columns;

                    ReferenceAttributesService.extendReferencedItemsAsync(attribute, referencedItems)
                        .then(function(result) {
                            $scope.prepareGridData(attribute, result);
                        });
                };

                $scope.prepareGridData = function(attributeDefinition, data) {
                    var desktopItems = [];
                    $scope.dynamicMobileData[attributeDefinition.name] = [];

                    angular.forEach(data, function(item) {
                        var desktopItem = {},
                            mobileItems = [];

                        angular.forEach($scope.dynamicColumns[attributeDefinition.name], function(columnValue, columnKey) {
                            var memberAttribute = $rootScope.dataModel.attribute(columnValue.name);
                            var value = item[columnValue.name];
                            var translatedValue = getTranslatedValue(item, memberAttribute);

                            var mobileItem = {};
                            mobileItem.label = columnValue.label;
                            mobileItem.value = "";
                            desktopItem[columnKey] = "";

                            if (value != 'undefined') {
                                desktopItem[columnKey] = translatedValue ? translatedValue : value;
                                mobileItem.value = translatedValue ? translatedValue : value;
                            }

                            mobileItems.push(mobileItem);
                        });

                        desktopItems.push(desktopItem);
                        $scope.dynamicMobileData[attributeDefinition.name].push(mobileItems);
                    });

                    $scope.dynamicDesktopData[attributeDefinition.name] = desktopItems;
                };

                function getTranslatedValue(item, memberAttribute) {
                    var translation;
                    var attributeType = memberAttribute.typeName;

                    if (attributeType === 'Dimensional') {
                        var dimensionKey = $rootScope.getFirstFilteredDimensionKey(item, memberAttribute);
                        var value = item[memberAttribute.name];
                        if (!_.isNil(value)) {
                            translation = value[dimensionKey];
                        }
                    } else {
                        translation = $rootScope.formatAttributeValue(item, memberAttribute);
                    }

                    return translation;
                }
            }
        };
    });
