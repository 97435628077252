angular.module('llax')
    .controller('FeatureToggleCtrl', ['$location', '$routeParams', '$scope', 'FeatureToggleService',
        function($location, $routeParams, $scope, FeatureToggleService) {
            if ($routeParams.add) {
                FeatureToggleService.enableFeature($routeParams.add);
            }
            if ($routeParams.remove) {
                FeatureToggleService.disableFeature($routeParams.remove);
            }
            $location.path('/');
        }
    ]);
