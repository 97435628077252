angular.module('llax')
    .controller('InvitationsController',
        function($location, $scope, $rootScope, $translate, $dialogs, $modal, $q, ContactsResource, growl, InvitationResource,
            InviteByEmailResource, TabContainer) {

            $scope.query = {
                keyword: '',
                type: '',
                closedVisible: false
            };

            $scope.requestTypeFilters = [{
                key: '',
                value: $translate.instant('INVITATIONS.ALL_INVITATIONS')
            }, {
                key: 'CONTACT_REQUEST',
                value: $translate.instant('INVITATIONS.IN_PLATFORM')
            }, {
                key: 'EMAIL_INVITATION',
                value: $translate.instant('INVITATIONS.EMAIL_INVITATIONS')
            }];

            function initTabContainer() {
                var tabContainer = new TabContainer($scope, $location, [{
                    suburl: "pending",
                    title: $translate.instant('PENDING_INVITATIONS'),
                    template: 'tpl/pending-invitations.tpl.html'
                }, {
                    suburl: "requested",
                    title: $translate.instant('CONTACT_REQUESTS'),
                    template: 'tpl/requested-invitations.tpl.html'
                }], 'pending');
            }

            function loadPending() {
                InvitationResource.query({
                    state: 'PENDING'
                    },
                    function(pendingInvitations) {
                        $scope.pendingInvitations = pendingInvitations;
                    });
            }

            function loadEmailInvitations() {
                return InviteByEmailResource.query({
                    type: ['INVITED_BUYER', 'INVITED_COMMUNITY_MEMBER', 'INVITED_SUPPLIER']
                });
            }

            function loadContactInvitations() {
                return ContactsResource.query({
                        state: ['INVITED', 'REJECTED', 'ESTABLISHED']
                    }
                );
            }

            function getDefaultState(state) {
                var states = {
                    INVITED: "PENDING"
                };
                return _.isUndefined(states[state]) ? state : states[state];
            }

            function loadAllInvitations() {
                $q.all([
                    loadEmailInvitations().$promise,
                    loadContactInvitations().$promise
                ]).then(function(data) {
                    var emailInvitations = _.map(data[0], function(contact) {
                        return {
                            date: contact.date,
                            email: contact.invitedEmailAddress,
                            organizationId: contact.invitingOrganizationId,
                            user: $rootScope.getUserName(contact.user),
                            invitationState: getDefaultState(contact.invitationState),
                            invitationType: contact.invitationType,
                            invitationText: contact.text,
                            requestType: 'EMAIL_INVITATION',
                            originalEntry__: contact
                        };
                    });
                    var contactRequests = _.map(data[1], function(contact) {
                        return {
                            name: contact.name,
                            address: contact.address,
                            email: contact.email,
                            date: contact.date,
                            acceptanceDate: contact.acceptanceDate,
                            organizationId: contact.organizationId,
                            invitationText: contact.text,
                            invitationState: getDefaultState(contact.state),
                            invitationType: contact.invitationType,
                            imageUrl: contact.imageUrl,
                            requestType: 'CONTACT_REQUEST',
                            originalEntry__: contact
                        };
                    });

                    $scope.contactRequests = _.orderBy(emailInvitations.concat(contactRequests),
                        ['date', 'invitationType'],
                        ['desc', 'asc']);
                });
            }

            $scope.isPending = function(invitation) {
                return invitation.invitationState === 'PENDING';
            };

            $scope.isClosed = function(invitation) {
                return invitation.invitationState === 'ACCEPTED' ||
                    invitation.invitationState === 'REJECTED' ||
                    invitation.invitationState === 'ESTABLISHED';
            };

            $scope.showClosed = function(invitation) {
                if (!$scope.isClosed(invitation)) {
                    return true;
                }
                return $scope.query.closedVisible && $scope.isClosed(invitation);
            };

            $scope.getStatusTitle = function(state) {
                var text;
                if (state === 'PENDING') {
                    text = $translate.instant('WAITING_FOR_ACCEPTANCE');
                } else if (state === 'ACCEPTED' || state === 'ESTABLISHED') {
                    text = $translate.instant('INVITATION_ACCEPTED');
                } else if (state === 'REJECTED') {
                    text = $translate.instant('INVITATION_REJECTED');
                }
                return text;
            };

            $scope.processInvitation = function(organization, action) {
                InvitationResource.save({
                        organizationId: organization.organizationId,
                        action: action
                    },
                    function(response) {
                        loadPending();
                    },
                    function(response) {
                        loadPending();
                    });
            };

            $scope.updateInvitation = function(contact) {
                var invitation = contact.originalEntry__;
                InviteByEmailResource.save(invitation, function(response) {
                    growl.success('INVITATION_UPDATED');
                    loadAllInvitations();
                });
            };

            $scope.deleteInvitation = function(contact) {
                var header = 'INVITATION.DELETE_INVITATION.LABEL';
                var message = 'INVITATION.DELETE_INVITATION.TEXT';
                var invitation = contact.originalEntry__;

                var confirmDialog = $dialogs.confirm(header, message);
                confirmDialog.result.then(function() {
                    InviteByEmailResource.delete({
                        invitationId: invitation.id
                    }, function(response) {
                        growl.success('INVITATION_DELETED');
                        loadAllInvitations();
                    });
                });
            };

            $scope.cancelRequest = function(organizationId) {
                ContactsResource.remove({
                        organizationId: organizationId
                    },
                    function(deletionResponse) {
                        growl.success("CONTACT_REQUEST_SUCCESSFULLY_CANCELED");
                        $scope.$broadcast('contactDataChanged');
                    },
                    function(response) {
                        growl.success("CONTACT_REQUEST_CANCEL_ERROR");
                    });
            };

            $scope.inviteByEmail = function() {
                $modal.open({
                    templateUrl: 'tpl/send-email-invitation.tpl.html',
                    controller: 'ExecuteEmailInvitationController',
                    backdrop: true,
                    resolve: {
                        data: {}
                    }
                });
            };

            $scope.editInvitation = function(invitation) {
                $modal.open({
                    templateUrl: 'tpl/edit-invitation.tpl.html',
                    controller: 'ExecuteEmailInvitationController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return invitation.originalEntry__;
                        }
                    }
                });
            };

            $scope.editContactRequest = function(contactRequest) {
                $modal.open({
                    templateUrl: 'tpl/edit-contact-request.tpl.html',
                    controller: 'ExecuteEmailInvitationController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return contactRequest.originalEntry__;
                        }
                    }
                });
            };

            (function init() {
                initTabContainer();
                loadAllInvitations();
                loadPending();
            })();
        }
    );
