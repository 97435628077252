/*ngInject*/
function DataModel(dataModelMap, $log, $rootScope, $translate, $q, $window, Auth, DataModelResource, EnumAttributeService, LocalCacheService) {

    var self = this;
    var LocalStorage = $window.localStorage;

    this.ADDITIONAL_FIELDS = [
        "audited__",
        "category__",
        "channel__",
        "checksum__",
        "primaryKey__",
        "publishedAt__",
        "publications_dirty__",
        "reviewCountApproved__",
        "reviewCountReceived__",
        "reviewCountRejected__",
        "reviewCountReviewed__",
        "reviewCountTotal__",
        "reviews__",
        "supplierPrimaryKey__",
        "supplier__",
        "tags__",
        "validation__",
        "validation_warnings__",
        "validation_dirty__",
        "attributeStates__",
        "readonlyAttributes__",
        "reviewErrors__",
        "reviewWarnings__",
        "reviewRemarks__"
    ];

    // Maps a layout name to a list of attribute names and attributes
    var layoutAttributesMap = {};

    // Maps a layout name to a list of sorted sections, including sorted and complete attribute objects
    var layoutSectionsMap = {};

    // Maps a category name to a list of attribute names and attributes
    var categoryAttributesMap = {};

    // Maps query parsers to layout names
    var queryParsersMap = {};

    // Defines the categories overview menu
    var categoriesOverview = {};

    // Maps a layout "function" to a layout name
    var layoutMapping = {};

    // Maps an additional category name or code to a category
    var additionalCategoriesMap = {};

    // Maps an additional attribute name to an attribute
    var additionalAttributesMap = {};

    // List of organization attributes
    var organizationAttributes;

    // List of user attributes
    var userAttributes;

    // List of contact attributes
    var contactAttributes;

    // List of review attributes
    var reviewAttributes;

    // List of dashboard attributes
    var dashboardAttributes;

    this.name = function() {
      return dataModelMap.name;
    };

    this.label = function() {
        return dataModelMap.label;
    };

    this.clearDefaultItems = function() {
        for (var i = 0; i < LocalStorage.length; i++) {
            var key = LocalStorage.key(i);
            if (key.includes("defaultItem:")) {
                LocalStorage.removeItem(key);
            }
        }
    };

    this.getDefaultItemKey = function (category, orgId, userId) {
        return "defaultItem:" + $rootScope.organization.dataModelHash + ":" + category + ":" + orgId + ":" + userId;
    };

    this.setDefaultItem = function (category, item, orgId, userId) {
        LocalStorage.setItem(this.getDefaultItemKey(category, orgId, userId), JSON.stringify(item));
    };

    this.getDefaultItem = function (category, orgId, userId) {
        var defaultItem = LocalStorage.getItem(this.getDefaultItemKey(category, orgId, userId));

        if (!_.isNil(defaultItem)) {
            try {
                defaultItem = JSON.parse(defaultItem);
            } catch(e) {
                $log.error("couldn't parse cached default item", e);
                return null;
            }
        }

        return defaultItem;
    };

    this.setCategoriesOverview = function(categoriesOverview_) {
        if (!_.isNil(categoriesOverview_)) {
            categoriesOverview = categoriesOverview_;
        }
    };

    this.setLayoutMapping = function(layoutMapping_) {
        if (!_.isNil(layoutMapping_)) {
            layoutMapping = layoutMapping_;
        }
    };

    this.getRaw = function() {
        return dataModelMap;
    };

    this.getTaskTags = function() {
        return dataModelMap.taskTags;
    };

    this.getMappingFunctions = function() {
        return dataModelMap.mappingFunctions;
    };

    this.mappingFunction = function(name) {
        return _.get(dataModelMap.mappingFunctions, name);
    };

    this.hasOptionList = function(optionListName) {
        return _.has(dataModelMap.optionLists, optionListName);
    };

    this.optionList = function(optionListName) {
        return _.get(dataModelMap.optionLists, optionListName);
    };

    /**
     *
     * DEPRECATED in favor of `optionListOptionsAsync`. The callers expect a returned array of options, or
     * a promise of loading these options.
     */
    this.optionListOptions = function(optionListName, groupNames) {

        // Check and get option list for name
        var optionList = this.optionList(optionListName);
        if (!_.isObject(optionList)) {
            return null;
        }

        var options = optionList.options;

        // Handle service option lists, if necessary
        if (!_.isEmpty(optionList.serviceName) && !optionList.optionsLoaded) {
            options = getServiceOptionListOptions(optionList, groupNames);
        }

        // Filter by groups
        if (!_.isEmpty(options) && !_.isEmpty(groupNames)) {
            options = getGroupFilteredOptions(options, optionList.groups, groupNames);
        }

        return options;
    };

    /**
     *
     * DEPRECATED in favor of `getServiceOptionListOptionsAsync`.
     */
    function getServiceOptionListOptions(optionList, groupNames) {

        var options;

        // Options are currently loading, so add code after loading is done
        if (optionList.optionsLoadingPromise) {
            options = [];
            optionList.optionsLoadingPromise.then(function(x) {

                // Add matching option list options to options array
                addMatchingOptions(optionList.options, options, groupNames);

            });
            return options;
        }

        // Check if options were cached
        var dataModelHash = $rootScope.organization.dataModelHash;
        var cacheKey = 'dataModel.optionList.' + optionList.name;
        var cachedOptionList = JSON.parse(LocalStorage.getItem(cacheKey));

        if (!_.isNil(cachedOptionList)) {

            // Only return options, if they match the data model hash
            if (cachedOptionList.dataModelHash == dataModelHash) {

                options = cachedOptionList.options;
                $log.info("Loaded " + options.length + " option(s) for option list '" + optionList.name + "' from local storage");

                // Update option list
                optionList.options = options;
                optionList.optionsLoaded = true;

                return options;
            }

            // Delete invalid cache entry
            $log.info("Invalidated cache for option list '" + optionList.name + "'");
            LocalStorage.removeItem(cacheKey);

        }

        // Create and attach empty options array
        options = [];
        optionList.options = [];
        optionList.optionsLoaded = false;

        // Save the promise
        optionList.optionsLoadingPromise = DataModelResource().getOptionListOptions({
            optionListName: optionList.name,
            serviceName: optionList.serviceName
        }, {},
        function(response) {

            _.forEach(response, function(option) {
               optionList.options.push({
                   name: option.name,
                   serviceName: option.serviceName || '',
                   key: option.name,
                   value: option.label,
                   label: option.label,
                   labels: option.labels,
                   icon: option.icon,
                   icons: option.icons,
                   groups: option.groups
               });
            });
            $log.info("Loaded " + optionList.options.length + " option(s) for option list '" + optionList.name + "' from server");

            // Cache options
            cachedOptionList = {
                dataModelHash: dataModelHash,
                options: optionList.options
            };

            // FIXME: We still have a couple of places that call the deprecated `optionListOptions()`, for now we are going to skip
            // caching the option list in the local storage, until we completely get rid of `optionListOptions()`.
            $log.info("Should have cached option(s) for option list '" + optionList.name + "' in local storage");

            // Translate all options
            $rootScope.translateAllOptions(null, optionList.options);

            // Add matching option list options to options array
            addMatchingOptions(optionList.options, options, groupNames);

        }, function(responseError) {
            $log.error(responseError);
            optionList.error = responseError;
        }).$promise;

        // Cleanup after loading
        optionList.optionsLoadingPromise.finally(function() {
            optionList.optionsLoaded = true;
            delete optionList.optionsLoadingPromise;
        });

        return options;
    }

    /**
     *
     * Returns a promise of loading the intended options whether from the cached storage or the DataModelResource.
     */
    this.optionListOptionsAsync = function(optionListName, groupNames) {

        var deferred = $q.defer();

        // Check and get option list for name
        var optionList = this.optionList(optionListName);
        if (!_.isObject(optionList)) {
            deferred.resolve(null);
        }

        var options = optionList.options;

        // Handle service option lists, if necessary
        if (!_.isEmpty(optionList.serviceName) && !optionList.optionsLoaded) {
            getServiceOptionListOptionsAsync(optionList, groupNames)
                .then(function(options) {
                    // Filter by groups
                    if (!_.isEmpty(options) && !_.isEmpty(groupNames)) {
                        options = getGroupFilteredOptions(options, optionList.groups, groupNames);
                    }

                    deferred.resolve(options);
                }).catch(function(error) {
                    $log.error("Could not load options for '" + optionListName + "'");
                    $log.error(error);
                    deferred.reject();
                });
        }

        // If options are already loaded
        if (!_.isEmpty(options)) {
            // Filter by groups if needed
            if (!_.isEmpty(groupNames)) {
                options = getGroupFilteredOptions(options, optionList.groups, groupNames);
            }
            deferred.resolve(options);
        }

        return deferred.promise;
    };

    function getServiceOptionListOptionsAsync(optionList, groupNames) {

        var deferred = $q.defer();
        var options;

        // Options are currently loading, so add code after loading is done
        if (optionList.optionsLoadingPromise) {
            options = [];
            optionList.optionsLoadingPromise.then(function(x) {

                // Add matching option list options to options array
                addMatchingOptions(optionList.options, options, groupNames);
                deferred.resolve(options);

            });
            return deferred.promise;
        }

        // Check if options were cached, else we'll load them then cache them
        var dataModelHash = $rootScope.organization.dataModelHash;
        var cacheKey = getOptionListCacheKey(optionList.name);

        getCachedOptionListAsync(cacheKey, dataModelHash)
            .then(function(cachedOptionList) {
                if (!_.isNil(cachedOptionList)) {
                    options = cachedOptionList.options;
                    $log.debug("Loaded " + options.length + " option(s) for option list '" + optionList.name + "' from local cache");

                    // Update option list
                    optionList.options = options;
                    optionList.optionsLoaded = true;

                    //return options to the root promise
                    deferred.resolve(options);
                } else {
                    // Delete invalid cache entries (if any)
                    $log.debug("Invalidating cache for option list '" + optionList.name + "' (if any)");
                    deleteCachedOptionListAsync(cacheKey);

                    fetchAndCacheOptionListAsync(optionList, dataModelHash, groupNames, cacheKey)
                        .then(function(options) {
                            deferred.resolve(options);
                        }).catch(function() {
                            deferred.resolve([]);
                        });
                }
            })
            .catch(function() {
                // There was an error checking the cache, let's fetch the options
                // and try to cache them anyway.
                fetchAndCacheOptionListAsync(optionList, dataModelHash, groupNames, cacheKey)
                        .then(function(options) {
                            deferred.resolve(options);
                        }).catch(function() {
                            deferred.resolve([]);
                        });
            });

        // Cleanup after loading
        deferred.promise.finally(function() {
            optionList.optionsLoaded = true;
            delete optionList.optionsLoadingPromise;
        });

        optionList.optionsLoadingPromise = deferred.promise;

        return deferred.promise;
    }

    function getGroupFilteredOptions(options, optionListGroups, groupNames) {

        if (_.isEmpty(options) || _.isEmpty(groupNames)) {
            return options;
        }

        // Filter either by option list groups or by groups defined in options
        options = _.filter(options, function(option) {

            if (!_.isEmpty(optionListGroups)) {

                // Check if option is included in any matching option list group
                return _.some(optionListGroups, function(groupOptions, groupName) {
                    return _.includes(groupNames, groupName) && _.includes(groupOptions, option.key);
                });

            } else if (!_.isEmpty(option.groups)) {

                // Check if any option group matches
                return _.some(option.groups, function(groupName) {
                    return _.includes(groupNames, groupName);
                });

            } else {
                // No groups exist, so don't include option
                return false;
            }

        });

        return options;
    }

    function addMatchingOptions(sourceOptions, options, groupNames) {

        if (_.isEmpty(sourceOptions)) {
            return;
        }

        var addAll = _.isEmpty(groupNames);
        _.forEach(sourceOptions, function(option) {

            // Add option, if either no group names were specified,
            // or any of its groups matches any group name
            if (addAll || _.some(option.groups, function(g) { return _.includes(groupNames, g); })) {
                options.push(option);
            }

        });

    }

    this.getAttributeOptions = function(attribute) {

        if (_.isEmpty(attribute)) {
            return null;
        }

        if (_.isString(attribute)) {

            // Get attribute for name, if necessary
            attribute = this.attribute(attribute);
            return this.getAttributeOptions(attribute, context);

        } else if (_.has(attribute, 'params')) {

            var optionsParam = this.getAttributeOptionsParam(attribute);
            if (_.isEmpty(optionsParam)) {

                // Get options from referenced attribute, if no options were defined
                var referencedOptionAttribute = this.getReferencedOptionAttribute(attribute);
                if (!_.isEmpty(referencedOptionAttribute)) {
                    return this.getAttributeOptions(referencedOptionAttribute);
                }

            } else if (_.isString(optionsParam)) {

                var options = _.get(attribute.params, optionsParam);
                if (_.isString(options)) {

                    // Get options from optionList, including filtering by groups
                    var optionList = options;
                    var groupNames = _.get(attribute.params, optionsParam + 'OptionGroups');
                    return this.optionListOptions(optionList, groupNames);

                } else if (_.isArray(options)) {

                    // Options is expected to be an array of (key, value) objects
                    return options;

                }

            }

        }

        // No options exist

        return null;
    };

    /**
     * Returns the name of the parameter containg options for this attribute
     * or 'null' if the attribute does not contain options.
     */
    this.getAttributeOptionsParam = function(attribute) {
        return getParamName(attribute, [ 'values', 'keys' ]);
    };

    /**
     * Returns the name of the referenced option attribute
     * or 'null' if no such attribute is set.
     */
    this.getReferencedOptionAttribute = function(attribute) {
        var paramName = getParamName(attribute, [ 'valueAttribute', 'keyAttribute' ]);
        var referencedAttributeName = _.get(attribute.params, paramName);
        return this.attribute(referencedAttributeName);
    };

    function getParamName(attribute, paramNames) {
        return _.find(paramNames, function(paramName) {
            var value = _.get(attribute.params, paramName);
            return !_.isNil(value);
        });
    }

    this.getOrganizationAttributes = function() {
        if (organizationAttributes === undefined) {
            if (dataModelMap.organizationAttributes === undefined) {
                return [];
            }
            var attributes = [];
            angular.forEach(dataModelMap.organizationAttributes, function(attributeName) {
                var attribute = this.attribute(attributeName);
                if (attribute) {
                    attributes.push(attribute);
                }
            }, this);
            organizationAttributes = attributes;
        }
        return organizationAttributes;
    };

    this.getUserAttributes = function() {
        if (userAttributes === undefined) {
            if (dataModelMap.userAttributes === undefined) {
                return [];
            }
            var attributes = [];
            angular.forEach(dataModelMap.userAttributes, function(attributeName) {
                var attribute = this.attribute(attributeName);
                if (attribute) {
                    attributes.push(attribute);
                }
            }, this);
            userAttributes = attributes;
        }
        return userAttributes;
    };

    this.getContactAttributes = function() {
        if (contactAttributes === undefined) {
            if (dataModelMap.contactAttributes === undefined) {
                return [];
            }
            var attributes = [];
            angular.forEach(dataModelMap.contactAttributes, function(attributeName) {
                var attribute = this.attribute(attributeName);
                if (attribute) {
                    attributes.push(attribute);
                }
            }, this);
            contactAttributes = attributes;
        }
        return contactAttributes;
    };

    this.getReviewAttributes = function() {
        if (reviewAttributes === undefined) {
            if (dataModelMap.reviewAttributes === undefined) {
                return [];
            }
            var attributes = [];
            angular.forEach(dataModelMap.reviewAttributes, function(attributeName) {
                var attribute = this.attribute(attributeName);
                if (attribute) {
                    attributes.push(attribute);
                }
            }, this);
            reviewAttributes = attributes;
        }
        return reviewAttributes;
    };

    this.getDashboardAttributes = function() {
        if (dashboardAttributes === undefined) {
            if (dataModelMap.dashboardAttributes === undefined) {
                return [];
            }
            var attributes = [];
            angular.forEach(dataModelMap.dashboardAttributes, function(attributeName) {
                var attribute = this.attribute(attributeName);
                if (attribute) {
                    attributes.push(attribute);
                }
            }, this);
            dashboardAttributes = attributes;
        }
        return dashboardAttributes;
    };

    function addToMapList(map, key, value) {
        var list = map[key];
        if (!list) {
            list = [];
            map[key] = list;
        }
        if (list.indexOf(value) < 0) {
            list.push(value);
        }
    }

    this.getCategoriesToShow = function() {
        var categories;
        if (categoriesOverview && categoriesOverview.showCategories) {
            var dataModel = this;
            categories = categoriesOverview.showCategories.map(function(categoryName) {
                return dataModel.category(categoryName);
            });
        } else {
            categories = this.allCategories();
        }
        categories = _.filter(categories, function(category) {
            return !_.isEmpty(category) && Auth.hasAnyPermission(Auth.OBJECT_TYPE_ITEMS, ['edit', 'read'], {category__: category.name});
         });
        return categories;
    };

    this.getCategoryMenuEntryTypes = function() {
        var menuEntries = [];
        if (categoriesOverview && !_.isEmpty(categoriesOverview.showEntries)) {
            menuEntries = ['item_selection'].concat(categoriesOverview.showEntries);
        } else if (categoriesOverview && categoriesOverview.showSearchBox) {
            menuEntries = ['item_selection', 'all', 'uncategorized', 'visible_categories', 'search_box'];
        } else {
            menuEntries = ['item_selection', 'all', 'uncategorized', 'visible_categories'];
        }
        return menuEntries;
    };

    this.getCategoryMenuEntries = function() {
        var entryTypes = this.getCategoryMenuEntryTypes();
        var entries = {};
        for (var i = 0; i < entryTypes.length; i++) {
            var type = entryTypes[i];
            if (type === 'visible_categories') {
                var categories = this.getCategoriesToShow();
                for (var j = 0; j < categories.length; j++) {
                    var category = categories[j];
                    entries['category_' + category.name] = category;
                }
            } else {
                entries[type] = null;
            }
        }
        return entries;
    };

    this.getItemAdditionalCategories = function(item) {

        var additionalCategories = {};
        angular.forEach(item, function(categoryName, attributeName) {
            var attribute = this.attribute(attributeName);
            if (attribute && attribute.typeName === 'AdditionalCategory' && categoryName) {
                var additionalModule = attribute.params.additionalModule;
                if (!additionalModule) {
                    $log.warn("Additional category attribute '" + attributeName + "' does not have an additional module specified");
                    return;
                }
                var extension = attribute.params.extension || '';
                additionalModule = extension + ':' + additionalModule;
                addToMapList(additionalCategories, additionalModule, categoryName);
            }
        }, this);

        return additionalCategories;
    };

    this.getMissingAdditionalCategories = function(additionalCategories) {

        if (_.isEmpty(additionalCategories)) {
            return {};
        }

        var missingCategories = {};
        _.forEach(additionalCategories, function(categoryNames, additionalModule) {
            _.forEach(categoryNames, function(categoryName) {

                var category = getAdditionalCategory(additionalModule, categoryName);
                if (!category) {
                    addToMapList(missingCategories, additionalModule, categoryName);
                }

            });
        });

        return missingCategories;
    };

    this.addAdditionalCategories = function(additionalCategoriesPerModule) {

        _.forEach(additionalCategoriesPerModule, function(additionalCategories, additionalModule) {
            _.forEach(additionalCategories, function(additionalCategory) {

                // Add module name
                additionalCategory.additionalModule = additionalModule;

                // Cache category for name
                var key = getAdditionalCategoryKey(additionalModule, additionalCategory.name);
                additionalCategoriesMap[key] = additionalCategory;

                // Cache category for code
                if (additionalCategory.code && additionalCategory.code != additionalCategory.name) {
                    key = getAdditionalCategoryKey(additionalModule, additionalCategory.code);
                    additionalCategoriesMap[key] = additionalCategory;
                }

            });
        });

    };

    this.getMissingAdditionalAttributes = function(additionalCategories) {

        if (_.isEmpty(additionalCategories)) {
            return {};
        }

        var missingAttributes = {};
        _.forEach(additionalCategories, function(categoryNames, additionalModule) {
            _.forEach(categoryNames, function(categoryName) {

                var category = getAdditionalCategory(additionalModule, categoryName);
                if (!category) {
                    $log.debug("Unknown additional category '" + categoryName + "' for module '" + additionalModule + "'");
                    return;
                }

                var attributes = getAdditionalCategoryAttributes(additionalModule, category);
                _.forEach(attributes, function(attributeName) {
                   if (!additionalAttributesMap[attributeName]) {
                       addToMapList(missingAttributes, additionalModule, attributeName);
                   }
                });

            });
        });

        return missingAttributes;
    };

    function getAdditionalCategoryKey(moduleName, categoryName) {
        return moduleName + ":" + categoryName;
    }

    function getAdditionalCategory(moduleName, categoryName) {
        var key = getAdditionalCategoryKey(moduleName, categoryName);
        return additionalCategoriesMap[key];
    }

    function getAdditionalCategoryAttributes(moduleName, category) {
        if (_.isNil(category)) {
            return [];
        }
        var attributes = [];
        addAdditionalCategoryAttributes(attributes, moduleName, category);
        return attributes;
    }

    function addAdditionalCategoryAttributes(attributes, moduleName, category) {
        if (_.isNil(category)) {
            return;
        }
        if (category.parent) {
            var parentCategory = getAdditionalCategory(moduleName, category.parent);
            addAdditionalCategoryAttributes(attributes, moduleName, parentCategory);
        }
        if (!_.isEmpty(category.attributes)) {
            _.forEach(category.attributes, function(attribute) {
                if (!_.includes(attributes, attribute)) {
                    attributes.push(attribute);
                }
            });
        }
    }

    this.addAdditionalAttributes = function(additionalAttributesPerModule) {
        angular.forEach(additionalAttributesPerModule, function(additionalAttributes, additionalModule) {
            angular.forEach(additionalAttributes, function(additionalAttribute) {
                additionalAttribute.additionalModule = additionalModule;
                cleanupAttributeParams(additionalAttribute);
                additionalAttributesMap[additionalAttribute.name] = additionalAttribute;
            });
        });
    };

    function cleanupAttributeParams(additionalAttribute) {

        var params = additionalAttribute.params;
        if (_.isEmpty(params)) {
            return;
        }

        _.forEach(params, function(paramValue, paramKey) {
            if (!_.isPlainObject(paramValue)) {
                return;
            }
            paramValue = _.map(paramValue, function(value, key) {
                return {
                    'key': key,
                    'value': value
                };
            });
            params[paramKey] = paramValue;
        });

    }

    this.addAdditionalSectionAttributes = function(result, sectionName, attributeName, categoryName, predicate) {

        if (_.isEmpty(categoryName)) {
            return;
        }

        if (!_.isObject(result)) {
            $log.warn("First parameter 'result' is not an object");
            return;
        }

        var attribute = this.attribute(attributeName);
        if (_.isNil(attribute)) {
            $log.warn("Additional category attribute '" + attributeName + "' is not defined");
            return;
        } else if (attribute.typeName !== 'AdditionalCategory') {
            $log.warn("Attribute '" + attributeName + "' is not of type 'AdditionalCategory'");
            return;
        }

        var additionalModule = attribute.params.additionalModule;
        if (_.isEmpty(additionalModule)) {
            $log.warn("Additional attribute '" + attributeName + "' does not have an additional module specified");
            return;
        }

        var extension = attribute.params.extension || '';
        additionalModule = extension + ':' + additionalModule;

        addAdditionalSectionCategoryAttributes(result,
                                               sectionName,
                                               additionalModule,
                                               categoryName,
                                               attribute,
                                               predicate);

    };

    function addAdditionalSectionCategoryAttributes(result,
                                                    sectionName,
                                                    additionalModule,
                                                    categoryName,
                                                    additionalAttribute,
                                                    predicate) {

        var category = getAdditionalCategory(additionalModule, categoryName);
        if (_.isNil(category)) {
            var additionalCategoryName = additionalAttribute.params.additionalCategory;
            if (categoryName != additionalCategoryName) {
                $log.warn("Category '" + categoryName +
                        "' specified in attribute '" +
                        additionalAttribute.name +
                        "' is not defined in additional module '" +
                        additionalModule + "'");
            }
            return;
        }

        attributes = result[sectionName] || [];
        if (!_.isEmpty(category.attributes)) {
            _.forEach(category.attributes, function(attribute) {

                if (self.isMetaAttribute(attribute) ||_.includes(attributes, attribute)) {
                    return;
                }

                if (_.isFunction(predicate)) {
                    var ret = predicate(attribute);
                    if (!ret) {
                        return;
                    } else if (_.isObject(ret)) {
                        attribute = _.merge(ret, {
                            'name': attribute
                        });
                    } else {
                        attribute = {
                            'name': attribute
                        };
                    }
                }

                attributes.push(attribute);

            });
        }
        result[sectionName] = attributes;

        if (!_.isEmpty(category.parent)) {
            addAdditionalSectionCategoryAttributes(result,
                                                   sectionName,
                                                   additionalModule,
                                                   category.parent,
                                                   additionalAttribute,
                                                   predicate);
        }

    }

    /**
     * Using `layout filters`, a data model could overwrite the 'hidden' and 'readonly' states
     * of a given attribute.
     *
     * @param predicate: a function that takes the attribute object as an argument and returns an object as in:
     *  {
     *      readonly?: boolean
     *      hidden?: boolean
     *  }
     */
    this.mutateSectionAttribute = function(result, sectionName, attributeName, predicate) {

        if (!_.isObject(result)) {
            $log.warn("First parameter 'result' is not an object");
            return;
        }

        var attribute = this.attribute(attributeName);
        if (_.isNil(attribute)) {
            $log.warn("Attribute '" + attributeName + "' is not defined");
            return;
        }

        var attributes = result[sectionName] || [];
        if (_.isFunction(predicate)) {
            var ret = predicate(attribute);
            if (!ret) {
                return;
            } else if (_.isObject(ret)) {
                attribute = _.merge(ret, {
                    'name': attribute.name
                });
            } else {
                attribute = {
                    'name': attribute.name
                };
            }

            attribute.isLayoutFilterMutation = true;
            attributes.push(attribute);
        }

        result[sectionName] = attributes;
    };

    this.hasAttributes = function() {
        return dataModelMap.attributes !== undefined && Object.keys(dataModelMap.attributes).length > 0;
    };

    this.hasAttribute = function(attributeName) {
        return dataModelMap.attributes[attributeName] !== undefined || additionalAttributesMap[attributeName] !== undefined;
    };

    this.hasAttributeType = function(attributeName, typeName) {
      var attribute = this.attribute(attributeName);
      return !_.isNil(attribute) && attribute.typeName == typeName;
    };

    this.attribute = function(attributeName) {
        return dataModelMap.attributes[attributeName] || additionalAttributesMap[attributeName];
    };

    this.getMemberAttributes = function(attribute, returnNames) {
        var memberAttributes = [];
        var memberAttributeNames = [];

        if (attribute.typeName == "Collection") {
            memberAttributeNames = self.attribute(attribute.params.valueAttribute).members;
        } else if (attribute.typeName == "MultiDimensional") {
            memberAttributeNames = [].concat(attribute.keyMembers, attribute.valueMembers);
        } else if (attribute.typeName == "Group") {
            memberAttributeNames = self.attribute(attribute.params.valueAttribute).members;
        } else if (attribute.typeName == "MultiReference") {
            memberAttributeNames = [].concat(
                attribute.params.filter,
                self.attribute(attribute.params.valueAttribute).members
            );
        }

        angular.forEach(memberAttributeNames, function(attributeName) {
            memberAttributes.push(self.attribute(attributeName));
        });

        if (returnNames) {
            return _.map(memberAttributes, 'name');
        } else {
            return memberAttributes;
        }
    };

    this.potentialPrimaryKeyPart = function(attributeName) {
        var attribute = dataModelMap.attributes[attributeName];
        return attribute !== undefined && attribute.params !== undefined && attribute.params.potentialPrimaryKeyPart === true;
    };

    this.isMetaAttribute = function(attribute) {
        var attributeName;
        if (angular.isObject(attribute)) {
            attributeName = attribute.name;
        } else {
            attributeName = attribute;
        }
        return attributeName.endsWith('__');
    };

    /**
     * Returns all attributes defined in the active data model, i.e. without additional attributes.
     */
    this.allAttributes = function() {
        var attributes = [];
        for ( var attributeName in dataModelMap.attributes) {
            attributes.push(this.attribute(attributeName));
        }
        return attributes;
    };

    this.filterPrimaryKeyPartAttributes = function() {
        var attributes = [];
        for (var attribute in dataModelMap.attributes) {
            if (this.potentialPrimaryKeyPart(attribute)) {
                attributes.push(attribute);
            }
        }
        return attributes;
    };

    this.attributeIsRemovedInCategory = function(category, attributeName) {
        return category.attribute_status !== undefined && category.attribute_status[attributeName] == 'REMOVED';
    };

    this.attributeIsRemovedInSection = function(section, attributeName) {
        return section.attribute_status !== undefined && section.attribute_status[attributeName] == 'REMOVED';
    };

    this.hasLayouts = function() {
        return dataModelMap.layouts !== undefined && Object.keys(dataModelMap.layouts).length > 0;
    };

    this.hasLayout = function(layoutName, item) {
        return this.layout(layoutName, item) !== undefined;
    };

    /**
     * Returns the layout based on the layout mapping and the items category,
     * if an item is given.
     */
    this.layout = function(layoutName, item) {
        var category = item ? item.category__ : undefined,
            mappedLayout = layoutMapping[layoutName],
            resolvedLayoutName;

        if (angular.isObject(mappedLayout)) {
            if (angular.isString(category)) {
                resolvedLayoutName = mappedLayout[category] || mappedLayout.default__ || layoutName;
            }
            else {
                resolvedLayoutName = mappedLayout.default__ || layoutName;
            }
        }
        resolvedLayoutName = resolvedLayoutName || layoutMapping[layoutName] || layoutName;
        return dataModelMap.layouts[resolvedLayoutName];
    };

    this.layoutAttributes = function(layoutName, item) {
        var layout = this.layout(layoutName, item);
        return layout ? getLayoutAttributesMap(this, layout).attributes : [];
    };

    this.layoutAttributeNames = function(layoutName, item) {
        var layout = this.layout(layoutName, item);
        return layout ? getLayoutAttributesMap(this, layout).attributeNames : [];
    };

    this.filteredLayoutAttributes = function(layoutName, item) {
        var layout = this.layout(layoutName, item);
        return layout ? getLayoutAttributesMap(this, layout).filteredAttributes : [];
    };

    this.filteredLayoutAttributeNames = function(layoutName, item) {
        var layout = this.layout(layoutName, item);
        return layout ? getLayoutAttributesMap(this, layout).filteredAttributeNames : [];
    };

    this.sectionAttributes = function(layoutName, item) {
        var layout = this.layout(layoutName, item);
        return layout ? getLayoutSectionsMap(this, layout) : [];
    };

    this.sectionAttributeParam = function(layoutName, item, sectionName, attributeName, param) {
        var layout = this.layout(layoutName, item);
        if (!layout) {
            return null;
        }
        var layoutSections = layout.sections.filter(function(section) {
            return section.name == sectionName;
        });
        if (layoutSections.length === 0) {
            return null;
        }
        var attributes = layoutSections[0].attributes.filter(function(attribute) {
            return attribute.name == attributeName;
        });
        if (attributes.length === 0) {
            return null;
        }
        var attribute = attributes[0];
        if (param == 'readonly' && this.isMetaAttribute(attribute)) {
            return true;
        }
        return attribute[param];
    };

    /*
     * Returns the attribute param defined in the given section, if the `sectionName` is not provided
     * it returns the attribute param from any section in the layout. This is helpful in places where we don't
     * have actual sections but we have layouts (ex. mass update).
     */
    this.anySectionAttributeParam = function(layoutName, item, sectionName, attributeName, param) {

        if (!_.isEmpty(sectionName)) {
            return this.sectionAttributeParam(layoutName, item, sectionName, attributeName, param);
        }

        var layout = this.layout(layoutName, item);
        if (!layout) {
            return null;
        }

        var attribute, found = false;
        _.forEach(layout.sections, function(section) {
            _.forEach(section.attributes, function(sectionAttribute) {
                if (sectionAttribute.name === attributeName) {
                    found = true;
                    attribute = sectionAttribute;
                    return false;
                }
            });

            if (found) {
                return false;
            }
        });

        if (_.isNil(attribute)) {
            return null;
        }

        if (param == 'readonly' && this.isMetaAttribute(attribute)) {
            return true;
        }
        return attribute ? attribute[param] : null ;
    };

    this.hasCategories = function() {
        return dataModelMap.categories !== undefined && Object.keys(dataModelMap.categories).length > 0;
    };

    this.hasCategory = function(categoryName, showDeleted) {
        return dataModelMap.categories[categoryName] !== undefined && (showDeleted || dataModelMap.categories[categoryName].modification_status !== 'DELETED');
    };

    this.category = function(categoryName) {
        return dataModelMap.categories[categoryName];
    };

    /**
     * Returns all categories defined in the active data model, i.e. without additional categories.
     */
    this.allCategories = function(showDeleted) {
        var categories = [];
        for ( var categoryName in dataModelMap.categories) {
            var category = this.category(categoryName);
            if (!category.hidden && category.label &&
                (showDeleted || category.modification_status != 'DELETED')) {
                categories.push(category);
            }
        }
        return categories;
    };

    this.categoryAttributes = function(categoryName) {
        return this.hasCategory(categoryName) ? getCategoryAttributesMap(this,
                        categoryName).attributes : [];
    };

    this.categoryAttributeNames = function(categoryName) {
        return this.hasCategory(categoryName) ? getCategoryAttributesMap(this,
                        categoryName).attributeNames : [];
    };

    this.categoryHasAttribute = function(categoryName, attributeName) {
        var categoryAttributeNames = this.categoryAttributeNames(categoryName);
        return categoryAttributeNames.includes(attributeName);
    };

    this.attributeHasCategory = function(attributeName) {
        var allCategories = this.allCategories();
        var attributeInCategory;

        attributeInCategory = allCategories.filter(function(element) {
            return element.attributes.contains(attributeName);
        })[0];

        return attributeInCategory !== undefined;
    };

    /**
     * Returns a list of layout sections for the specified layout where only
     * those attributes are retained which belong to the specified item's category.
     * Additionally the resulting layout sections will hold attribute objects.
     */
    this.filteredSections = function(layout, item, additionalSectionAttributes) {

        var categoryName = item.category__;
        var category = this.category(categoryName);
        layout = _.isObject(layout) ? layout : this.layout(layout, item);
        if (!layout || !category) {
            return [];
        }

        var attributeNames = this.categoryAttributeNames(category.name);
        var layoutSections = getLayoutSectionsMap(this, layout);

        // Filter out sections which are deleted, and remove attributes which are not in the category
        var dataModel = this;
        var filteredSections = layoutSections
          .filter(function(section) {
            return section.modification_status !== 'DELETED';
          })
          .map(function (section) {
            return filterSection(dataModel,
              category,
              layout,
              section,
              attributeNames,
              additionalSectionAttributes);
          });
        return filteredSections;
    };

    function filterSection(dataModel, category, layout, section, attributeNames, additionalSectionAttributes) {

        // Filter out attributes which are not in the category, or are removed in either category or section
        var filteredSection = angular.copy(section);
        filteredSection.attributes = section.attributes.filter(function(attribute) {
            return attributeNames.contains(attribute.name) &&
                !dataModel.attributeIsRemovedInCategory(category, attribute.name) &&
                !dataModel.attributeIsRemovedInSection(section, attribute.name);
        });

        // Add additional attributes for the section
        if (additionalSectionAttributes && section.name) {
            var sectionAttributes = additionalSectionAttributes[section.name] || additionalSectionAttributes[section.name.toLowerCase()];
            if (sectionAttributes) {
                angular.forEach(sectionAttributes, function(sectionAttribute) {

                    var attributeName = angular.isObject(sectionAttribute) ? sectionAttribute.name : sectionAttribute;
                    var attribute = dataModel.attribute(attributeName);
                    if (!attribute) {
                        $log.warn("Unknown additional attribute '" + attributeName + "' in section '" + section.name + "'");
                        return;
                    }

                    // Add flags, if specified
                    if (angular.isObject(sectionAttribute) && Object.keys(sectionAttribute).length > 1) {
                        attribute = angular.copy(attribute);
                        attribute.additionalParams = {};
                        angular.forEach(sectionAttribute, function(paramValue, paramName) {
                            if (paramName != 'name') {
                                attribute.additionalParams[paramName] = paramValue;
                            }
                        });
                    }

                    if (!sectionAttribute.isLayoutFilterMutation) {
                        // In case of additional section attributes, we
                        // remove the attribute first, so it keeps the correct position relative to the other additional attributes
                        filteredSection.attributes = filteredSection.attributes.filter(function(a) {
                            return a.name != attributeName;
                        });
                        filteredSection.attributes.push(attribute);

                    } else {
                        // In case we only want to update/mutate the attribute definition in the item editor, then we
                        // replace the attribute in the section
                        filteredSection.attributes = filteredSection.attributes.map(function(a) {
                            if (a.name === attributeName ) {
                                return attribute;
                            }

                            return a;
                        });
                    }

                });
            }
        }

        return filteredSection;
    }

    /**
     * Returns the QueryParser for a layout.
     */
    this.queryParser = function(layoutName) {

        if (queryParsersMap[layoutName] === undefined) {

            var attributesByName = {};
            var attributes = this.layoutAttributes(layoutName);
            for (var i = 0; i < attributes.length; i++) {
                var attribute = attributes[i];
                attributesByName[attribute.name] = attribute;
            }

            queryParsersMap[layoutName] =
                new QueryParser(attributesByName,
                                EnumAttributeService,
                                $log,
                                $translate);

        }

        return queryParsersMap[layoutName];
    };

    /**
     * Returns the custom filter defined in the data model's customDirectives.
     */
    this.getCustomFilter = function(filterName) {
        // FIXME
    };

    /**
     * Returns the custom filter defined in the data model's customDirectives for multi/single reference attributes.
     */
    this.getUiReferenceFilter = function(filterName) {
        // FIXME
    };

    function getLayoutSectionsMap(dataModel, layout) {

        if (layoutSectionsMap[layout.name] === undefined) {

            var layoutSections;

            // Sort sections by layout sort order
            if (layout.sort_order) {

                layoutSections = layout.sort_order.map(function(sortSectionName) {
                    var filteredSections = layout.sections.filter(function(section) {
                        return section.name == sortSectionName;
                    });
                    if (filteredSections.length === 0) {
                        $log.warn("Could not find section " +
                                  sortSectionName +
                                  " from sort order in layout " +
                                  layout.name);
                    } else {
                        return filteredSections[0];
                    }
                });

                // FIXME: What about sections which are NOT in sort_order?

            } else {
                layoutSections = layout.sections;
            }

            // Make a deep copy of all sections, because we will make changes to them
            layoutSections = angular.copy(layoutSections);

            // Sort attributes in sections by section sort order and replace with complete attribute object
            _.forEach(layoutSections, function(section) {

                if (section.sort_order && section.sort_order.length > 0) {

                    var sortedAttributes = section.sort_order.map(function(sortAttributeName) {
                        var filteredAttributes = section.attributes.filter(function(attribute) {
                            return attribute.name == sortAttributeName;
                        });
                        if (filteredAttributes.length === 0) {
                            $log("Could not find attribute " +
                                 sortAttributeName +
                                 " from sort order in section " +
                                 section.name +
                                 " of layout " +
                                 layout.name);
                        } else {
                            return filteredAttributes[0];
                        }
                    });

                    // Add attributes which are not in sort_order
                    sortedAttributes.concat(section.attributes.filter(function(attribute) {
                        return !section.sort_order.contains(attribute.name);
                    }));

                    section.attributes = sortedAttributes;

                }

                // Replace simple attribute with complete attribute object and filter out non-existing attributes
                section.attributes = section.attributes.map(function(attribute) {
                    return dataModel.attribute(attribute.name);
                }).filter(function(attribute) {
                    return attribute !== undefined;
                });

            });

            layoutSectionsMap[layout.name] = layoutSections;

        }

        return layoutSectionsMap[layout.name];
    }

    function getLayoutAttributesMap(dataModel, layout) {

        if (layoutAttributesMap[layout.name] === undefined) {

            var layoutAttributes = [];
            var layoutAttributeNames = [];
            var filteredAttributes = [];
            var filteredAttributeNames = [];
            var attribute;

            var sections = getLayoutSectionsMap(dataModel, layout);
            for (var i = 0; i < sections.length; i++) {
                var section = sections[i];
                var attributes = section.attributes;
                for (var j = 0; j < attributes.length; j++) {
                    attribute = attributes[j];
                    layoutAttributes.push(attribute);
                    layoutAttributeNames.push(attribute.name);
                    if (!dataModel.attributeIsRemovedInSection(section, attribute.name)) {
                        filteredAttributes.push(attribute);
                        filteredAttributeNames.push(attribute.name);
                    }
                }
            }

            layoutAttributesMap[layout.name] = {
                attributes: layoutAttributes,
                attributeNames: layoutAttributeNames,
                filteredAttributes: filteredAttributes,
                filteredAttributeNames: filteredAttributeNames
            };

        }

        return layoutAttributesMap[layout.name];
    }

    function getCategoryAttributesMap(dataModel, categoryName) {

        if (categoryAttributesMap[categoryName] === undefined) {

            var categoryAttributes = [];
            var categoryAttributeNames = [];

            var category = dataModel.category(categoryName);
            if (category === undefined) {
                $log.warn("Unknown category " + categoryName);
            } else {

                for (var i = 0; i < category.attributes.length; i++) {
                    var attributeName = category.attributes[i];
                    var attribute = dataModel.attribute(attributeName);
                    if (attribute === undefined) {
                        $log.warn("Unknown attribute " + attributeName +
                            " in category " + category.name);
                    } else {
                        categoryAttributes.push(attribute);
                        categoryAttributeNames.push(attributeName);
                    }
                }

            }

            categoryAttributesMap[category.name] = {
                attributes: categoryAttributes,
                attributeNames: categoryAttributeNames
            };

        }

        return categoryAttributesMap[categoryName];
    }

    function getOptionListCacheKey(optionListName) {
        return 'dataModel.optionList.' + optionListName;
    }

    function getCachedOptionListAsync(optionListName, dataModelHash) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.getEntryAsync(
                LocalCacheService.STORES.optionLists.objectStoreName,
                [ optionListName, dataModelHash ]);
            });
    }

    function insertCachedOptionListAsync(optionListName, optionList) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.insertEntryAsync(
                LocalCacheService.STORES.optionLists.objectStoreName,
                optionListName,
                optionList);
            });
    }

    function deleteCachedOptionListAsync(optionListName) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.deleteEntriesByIndexAsync(
                LocalCacheService.STORES.optionLists.objectStoreName,
                LocalCacheService.STORES.optionLists.indexes.optionListName,
                optionListName);
            });
    }

    function fetchAndCacheOptionListAsync(optionList, dataModelHash, groupNames, cacheKey) {

        var deferred = $q.defer();

        // Create and attach empty options array
        options = [];
        optionList.options = [];
        optionList.optionsLoaded = false;

        // Save the promise
        optionList.optionsLoadingPromise = DataModelResource().getOptionListOptions({
            optionListName: optionList.name,
            serviceName: optionList.serviceName
        }, {},
        function(response) {

            _.forEach(response, function(option) {
               optionList.options.push({
                   name: option.name,
                   serviceName: option.serviceName || '',
                   key: option.name,
                   value: option.label,
                   label: option.label,
                   labels: option.labels,
                   icon: option.icon,
                   icons: option.icons,
                   groups: option.groups
               });
            });
            $log.debug("Loaded " + optionList.options.length + " option(s) for option list '" + optionList.name + "' from server");

            // Cache options
            cachedOptionList = {
                name: getOptionListCacheKey(optionList.name),
                dataModelHash: dataModelHash,
                options: optionList.options
            };

            insertCachedOptionListAsync(cacheKey, cachedOptionList)
                .then(function() {
                    $log.debug("Stored option(s) for option list '" + optionList.name + "' in local cache");
                })
                .catch(function(error) {
                    $log.error("Could not store option(s) for option list '" + optionList.name + "' in local cache");
                });

            // Add matching option list options to options array
            addMatchingOptions(optionList.options, options, groupNames);

            deferred.resolve(options);

        }, function(responseError) {
            $log.error(responseError);
            optionList.error = responseError;
            deferred.reject();
        }).$promise;

        return deferred.promise;
    }

    this.getCachedAdditionalCategory = function(additionalCategoryNameOrCode, additionalModuleName, dataModelHash) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.getEntryAsync(
                LocalCacheService.STORES.additionalCategories.objectStoreName,
                [ additionalModuleName, additionalCategoryNameOrCode, dataModelHash ]);
            });
    };

    this.insertCachedAdditionalCategory = function(additionalCategoryNameOrCode, additionalModuleName, dataModelHash, additionalCategory) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.insertEntryAsync(
                LocalCacheService.STORES.additionalCategories.objectStoreName,
                [ additionalModuleName, additionalCategoryNameOrCode, dataModelHash ],
                additionalCategory);
            });
    };

    this.deleteCachedAdditionalCategory = function(additionalCategoryNameOrCode, additionalModuleName) {
        return LocalCacheService.waitForDBInitialization().then(function() {
            return LocalCacheService.deleteEntriesByIndexAsync(
                LocalCacheService.STORES.additionalCategories.objectStoreName,
                LocalCacheService.STORES.additionalCategories.indexes.additionalCategoryNameOrCode,
                [ additionalModuleName, additionalCategoryNameOrCode ]);
            });
    };

}
