angular.module('llax.services')
    .service('SheetJSService', function() {

        this.readFile = function(fileAsArrayBuffer) {
            return XLSX.read(fileAsArrayBuffer, {
                type: 'binary'
            });
        };
        /*
        * Returns an array of a given row cells in a given sheet object with their content.
        */
        this.getRowCells = function(sheet, row) {
            var headers = [];
            var range = XLSX.utils.decode_range(sheet['!ref']);
            var C = null;

            // Walk every column in the range
            for(C = range.s.c; C <= range.e.c; ++C) {
                var location = XLSX.utils.encode_cell({c: C, r: row});
                var cell = sheet[location];

                if(_.isNil(cell)) {
                    // Cell at `location` was not found, maybe it is part of a merge
                    var mergedRange = this.getCellMergedRegion(sheet, row, C);
                    if (!_.isNil(mergedRange)) {
                        // Taking the `s` (start) of a range because the main cell in a merge is the upper left cell
                        location = XLSX.utils.encode_cell({ r: mergedRange.s.r, c: mergedRange.s.c });
                        cell = sheet[location];
                    }
                }

                var value = "";
                if(cell && cell.t) { // Pass empty cells
                    value = XLSX.utils.format_cell(cell);
                    headers.push({
                        location: location,
                        value: value
                    });
                }
            }
            return headers;
        };

        this.sheetToJson = function(sheet) {
            return XLSX.utils.sheet_to_json(sheet);
        };

        this.exportXLSX = function(jsonObject, fileName, sheetName) {
            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.json_to_sheet(jsonObject);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
            XLSX.writeFile(workbook, fileName + '.xlsx');
        };

        this.getCellMergedRegion = function(sheet, row, column) {
            return _.find(sheet['!merges'], function(mergedRange) {
                // `s`: Start, `e`: End, `r`: Row, `c`: Column
                return mergedRange.s.r <= row && mergedRange.s.c <= column &&
                       mergedRange.e.r >= row && mergedRange.e.c >= column;
            });
        };
    });