angular.module('llax')
    .controller('SendRequestsController',
        function($rootScope, $scope, $location, ContactsResource, SentRequestResource, growl) {

            $scope.partners = {};
            $scope.partners.selectedBusinessPartners = [];
            $scope.businessPartners = [];

            $scope.form = {
                questions: []
            };

            $scope.form.questions.push({});

            $scope.addQuestion = function() {
                $scope.form.questions.push({});
            };

            $scope.sendRequest = function() {

                //create Array of ids of selected Organizations:
                var recipients = $scope.partners.selectedBusinessPartners.map(function(contact) {
                    return contact.id;
                });

                $scope.form.recipients = recipients;

                SentRequestResource.save($scope.form,
                    function(response) {
                        console.log($scope.form);
                        growl.success("REQUEST.SUCCESSFULLY_SENT");
                        $location.path("sent-requests");
                    },
                    function(response) {});

            };

            $scope.cancel = function() {
                $location.path("sent-requests");
            };

            ContactsResource.query({},
                function(contacts) {
                    contacts = contacts.filter(function(contact) {
                        return contact.organizationId != undefined;
                    });
                    $scope.businessPartners = contacts.map(function(contact) {
                        return {
                            id: contact.id,
                            text: contact.name
                        };
                    });
                },
                function(response) {}
            );
        }
    );
