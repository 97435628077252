angular.module('llax')
    .controller('AssetFoldersModalUploadController', function ($modalInstance, $rootScope, $scope, data, AssetFoldersService, growl) {
        $scope.isArchiveFile = false;
        $scope.inputModel = {
            extractArchive: false
        };
        $scope.uploading = false;
        $scope.done = false;
        $scope.path = data.path;
        $scope.getUploaderOptions = data.getUploaderOptions;
        $scope.onCancel = data.onCancel;

        $scope.cancel = function () {
            $scope.onCancel();
            $modalInstance.dismiss('cancel');
        };

        $scope.uploadFile = function () {
            if ($scope.uploader.queue.length === 0) {
                return;
            }

            if ($scope.inputModel.extractArchive) {
                _.forEach($scope.uploader.queue, function(uploadItem){
                    uploadItem.url = lax_rest_url('assetFolders/_uploadArchive/' + encodeURI(data.path));
                });
            }

            $scope.uploading = true;
            $scope.uploader.uploadAll();
        };

        $scope.onCompleteAll = function(uploaderScope) {

            $scope.uploading = false;
            $scope.done = true;

            if (!_.isEmpty(uploaderScope.queue)) {
                var hasError = _.filter(uploaderScope.queue, function(item) {
                    return item.isError;
                })[0];

                if (hasError) {
                    return;
                }
            }

            growl.success('ASSET_FOLDER.UPLOADED_SUCCESSFULLY');
            $scope.cancel();
        };

        $scope.onAfterAddingFile = function (uploaderScope) {
            return function(fileItem) {
                $rootScope.$broadcast('filesSelected');
                $rootScope.uploaderHasFiles = true;
                fileItem.alias = fileItem.file.name;
            };
        };

        $scope.onErrorItem = function(item, response, status, headers) {
            if (status === 408) {
                item.error = {
                    message: 'ASSET_FOLDER.TOOK_TOO_LONG'
                };
            } else {
                item.error = {
                    message: 'ASSET_FOLDER.ERROR_OCCURRED'
                };
            }
        };

        $scope.uploaderOptions = AssetFoldersService.getUploader($scope.path, {
            multiple: true,
            autoUpload: false,
            useFilename: true,
            onCompleteAll: $scope.onCompleteAll,
            onAfterAddingFile: $scope.onAfterAddingFile,
            onErrorItem: $scope.onErrorItem
        });

    });
