angular.module('llax')
  .controller('SupplierItemReviewController',
      function ($attrs, $log, $scope, $timeout, $q, CommunicationChannelResource, ContactsResource, GdsnPartiesResource, SupplierReviewResource) {
        var GLN_PATTERN_LENGTH = 13;

        $scope.reviews = [];
        $scope.reviewErrors = {};
        $scope.reviewWarnings = {};
        $scope.temporaryModelForPanels = [];
        $scope.gdsnParties = [];
        $scope.showErrors = false;
        $scope.showWarnings = false;
        $scope.VIEW_BY_ERRORS_WARNINGS = 'ByErrorsWarnings';
        $scope.VIEW_BY_REVIEWER = 'ByReviewer';
        $scope.view = $scope.VIEW_BY_REVIEWER;
        $scope.loading = true;

        // Ugly hack: getting dependencies from parent controller if not available in injected $attrs
        $scope.itemPrimaryKey = $attrs.itemPrimaryKey || $scope.item.primaryKey__;
        $scope.publicationDestination = $attrs.publicationDestination;
        $scope.reviewer = $attrs.reviewer;

        $scope.organizations = [];
        $scope.channels = [];

        (function init() {
            // There are 3 possible ways to fetch a reviewer information:
            //  1- If it's an in-platform review i.e. `review.reviewerId` is set, then the reviewer is an active organization.
            //  2- If that wasn't the case, the review might be from one of the communication channels.
            //  3- Communication channels might not define all the sub channels, for example in GDSN. Therefor, if all previous attempts fail,
            //     we try to fetch the information from the available GDSN parties.

            var reqObj = { primaryKey: $scope.itemPrimaryKey };
            if (!_.isNil($scope.publicationDestination)) {
                reqObj.publicationDestination = $scope.publicationDestination;
            }
            if (!_.isNil($scope.reviewer)) {
                reqObj.reviewer = $scope.reviewer;
            }

            SupplierReviewResource.get(reqObj, {},
                function (response) {
                    $scope.reviews = response;
                    $q.all([
                        loadAllVisibleOrganizations(),
                        loadCommunicationChannels()
                    ]).then(function() {
                        loadReviewersDescriptions();
                        return loadGDSNReviewerDescriptions($scope.reviews);
                    }).then(function() {
                        prepareErrorsAndWarnings();
                        $timeout(function() {
                            $scope.loading = false;
                        }, 0);
                      });
                });

        })();

        function prepareErrorsAndWarnings() {
            var reviewEntityAttributes = ['channelId', 'combinedComment', 'comment', 'customValues', 'date',
                'messagingCreatorType', 'primaryKey', 'reviewStatus', 'reviewer', 'supplier', 'supplierId', 'reviewer__'];

            _.forEach($scope.reviews, function (review) {
                _.forEach(review.reviewErrors, function (error) {
                    var sanitizedReview = _.pick(review, reviewEntityAttributes);
                    if ($scope.reviewErrors[error]) {
                        $scope.reviewErrors[error].push(sanitizedReview);
                    } else {
                        $scope.reviewErrors[error] = [sanitizedReview];
                    }
                });

                _.forEach(review.reviewWarnings, function (warning) {
                    var sanitizedReview = _.pick(review, reviewEntityAttributes);
                    if ($scope.reviewWarnings[warning]) {
                        $scope.reviewWarnings[warning].push(sanitizedReview);
                    } else {
                        $scope.reviewWarnings[warning] = [sanitizedReview];
                    }
                });
            });
        }

        function loadReviewersDescriptions() {
            _.forEach($scope.reviews, function(review) {
                getReviewerDescription(review);
            });
        }

        function loadCommunicationChannels() {
            var deferred = $q.defer();

            CommunicationChannelResource.query({},
                function (response) {
                    $scope.channels = response;
                    deferred.resolve();
                },
                function(response){
                    $log.error(response);
                    deferred.resolve();
                });

            return deferred.promise;
        }

        function loadAllVisibleOrganizations() {
            var deferred = $q.defer();

            ContactsResource.query({},
                function (response) {
                    $scope.organizations = response;
                    deferred.resolve();
                },
                function(response) {
                    $log.error(response);
                    deferred.resolve();
                }
            );

            return deferred.promise;
        }

        $scope.toggleErrors = function() {
            $scope.showErrors = !$scope.showErrors;
        };

        $scope.toggleWarnings = function() {
            $scope.showWarnings = !$scope.showWarnings;
        };

        $scope.getCount = function (obj) {
            return _.keys(obj).length;
        };

        $scope.getAggregatedComments = function (reviews) {
            return _.filter(reviews, function(review) {
                if (review.comment) {
                    return review;
                } else {
                    return false;
                }
            });
        };

        $scope.getReviewerName = function(review) {
            if (!_.isEmpty(review.reviewer__.name)) {
                return review.reviewer__.name;
            } else if (!_.isNil($scope.gdsnParties[review.reviewer__.id])){
                return $scope.gdsnParties[review.reviewer__.id].name;
            }

            return null;
        };

        $scope.scrollToComment = function($event, id) {

            $event.preventDefault();

            var commentSectionId = 'comments-' + id;
            document.getElementById(commentSectionId).scrollIntoView({
                behavior: 'smooth'
            });
            document.getElementById(commentSectionId).classList.add('review-comment-highlighted');
            $timeout(function() {
                document.getElementById(commentSectionId).classList.remove('review-comment-highlighted');
            }, 1000);

        };

        function getReviewerDescription(review) {

            review.reviewer__ = {};

            if (!_.isNil(review.reviewerId)) {
                review.reviewer__.id = review.reviewId;
                var organization = _.find($scope.organizations, function (entry) {
                    // In-platform publications can only exist for organization contacts,
                    // thus we only need to compare the (optional) property 'organizationId'!
                    return entry.organizationId == review.reviewerId;
                });

                if (!_.isNil(organization)) {
                    review.reviewer__.name = organization.name;
                }
            } else {
                review.reviewer__.id = review.reviewer;
                var channel = _.find($scope.channels, function (channel) {
                    if (!_.isNil(channel.subDestinations) && !_.isNil(channel.subDestinations[review.reviewer])) {
                        return true;
                    }
                });

                if (!_.isNil(channel)) {
                    review.reviewer__.name = channel.subDestinations[review.reviewer];
                }
            }
        }

        function loadGDSNReviewerDescriptions(reviews) {
            var idsSet = [];

            _.forEach(reviews, function(review) {
                if (!review.reviewer__.name && review.reviewer__.id.length == GLN_PATTERN_LENGTH) {
                    // We haven't found a name for it, might try with GDSN parties
                    idsSet.push(review.reviewer__.id);
                }
            });

            var deferred = $q.defer();
            getReviewerDescriptionFromGDSNParties(idsSet, function(response) {
                $scope.gdsnParties = _.mapKeys(response, 'gln');
                deferred.resolve();
            });

            return deferred.promise;
        }

        function getReviewerDescriptionFromGDSNParties(ids, callback) {
            GdsnPartiesResource.get({
                gln: ids
            }, function(response) {
                callback(response);
            }, function(error) {
                $log.error(error);
                callback([]);
            });
        }
    }
  );
