angular.module('llax.directives', [])
.directive('fileUpload', ['$translate', '$timeout','$filter','$rootScope', '$parse',
    function($translate, $timeout,$filter, $rootScope, $parse) {
        return {
            restrict: 'A',
            templateUrl: function(elem, attrs) {
                return attrs.templateUrl || 'tpl/file-upload.tpl.html';
            },
            controller: ['$log', '$scope', '$rootScope', '$translate', '$timeout', '$filter', '$modal', 'growl', 'FileUploader', '$window', '$element',
                function($log, $scope, $rootScope, $translate, $timeout, $filter, $modal, growl, FileUploader, $window, $element) {

                    $scope.dropText = $translate.instant('UPLOAD.DROP_FILES');
                    $scope.showFileUpload = false;
                    $scope.filePreviewData = null;
                    $scope.filePreviewError = false;
                    $scope.showThumbnail = false;
                    $scope.isLazyLoaded = false;
                    if ($scope.attribute && $scope.attribute.params && $scope.attribute.params.custom) {
                        $scope.isLazyLoaded = $scope.attribute.params.custom.lazyLoaded;
                    }

                    // FIXME: Due to backend limitations, it is not possible to upload file larger than 32MB!
                    var MAX_FILE_SIZE = 32 * 1024 * 1024;

                    var onUpload = $scope.onUpload();

                    var customFilters = [{
                        name: 'imageFilter',
                        fn: function(item /*{File|FileLikeObject}*/) {
                            var result;
                            if ($filter('isFileType')(item, 'image')) {
                                result = true;
                            } else {
                                growl.error('UPLOAD.NO_IMAGE_ERROR');
                                result = false;
                            }
                            return result;
                        }
                    }, {
                        name: 'noImageFilter',
                        fn: function(item /*{File|FileLikeObject}*/) {
                            var result;
                            if ($filter('isFileType')(item, 'image')) {
                                result = false;
                                growl.error('UPLOAD.IMAGE_ERROR');
                            } else {
                                result = true;
                            }
                            return result;
                        }
                    }, {
                        name: 'zipFilter',
                        fn: function(item /*{File|FileLikeObject}*/) {
                            var result;
                            if ($filter('isFileType')(item, '|zip|')) {
                                result = true;
                            } else {
                                growl.error('UPLOAD.FILE_FORMAT_ERROR', {variables: {type: 'zip'}});
                                result = false;
                            }
                            return result;
                        }
                    }, {
                        name: 'xmlFilter',
                        fn: function(item /*{File|FileLikeObject}*/) {
                            var result;
                            if ($filter('isFileType')(item, '|xml|')) {
                                result = true;
                            } else {
                                growl.error('UPLOAD.FILE_FORMAT_ERROR', {variables: {type: 'xml'}});
                                result = false;
                            }
                            return result;
                        }
                    }, {
                        name: 'maxFileSizeFilter',
                        fn: function(item /*{File|FileLikeObject}*/) {
                            var result;
                            var maxFileSize = onUpload.maxFileSize ? Math.min(onUpload.maxFileSize, MAX_FILE_SIZE) : MAX_FILE_SIZE;
                            if (item.size < maxFileSize) {
                                result = true;
                            } else {
                                var sizeInMegaByte = Math.floor(maxFileSize / 1024 / 1024);
                                growl.error('UPLOAD.FILE_SIZE_ERROR', {variables: {size: sizeInMegaByte + ' MB'}});
                                result = false;
                            }
                            return result;
                        }
                    }];

                    /*
                     angular-file-upload
                     https://github.com/nervgh/angular-file-upload/wiki/Module-API
                     */
                    var uploader = new FileUploader({
                        url: onUpload.url,
                        autoUpload: onUpload.autoUpload || false,
                        formData: onUpload.formData || '',
                        filters: getFilters()
                    });

                    if (onUpload.disableDragAndDrop){
                        uploader.filters.push({
                            name: "disableDragAndDrop",
                            fn: function() {
                                return false;
                            }
                        });
                    }

                    if (onUpload.queueLimit) {
                        uploader = angular.extend(uploader, { 'queueLimit': onUpload.queueLimit });
                    }

                    uploader.onCancelItem = function(response) {
                      if (_.isFunction(onUpload.uploadCancelled)) {
                          onUpload.uploadCancelled(response);
                      }
                      $rootScope.$broadcast('uploadCanceled');
                    };

                    if (onUpload.onErrorItem) {
                        uploader.onErrorItem = onUpload.onErrorItem;
                    } else {
                        uploader.onErrorItem = function(response) {
                            $log.error(response);
                        };
                    }

                    if (onUpload.onAfterAddingFile) {
                        uploader.onAfterAddingFile = function(fileItem) {
                            onUpload.onAfterAddingFile(fileItem);
                        };
                    } else {
                        uploader.onAfterAddingFile = function(fileItem) {
                            if (fileItem.file.type.startsWith('image')) {
                                $scope.croppedImage = '';
                                var reader = new FileReader();
                                reader.onload = function(event) {
                                    $scope.$apply(function() {
                                        $scope.imageToCrop = event.target.result;
                                        $rootScope.$broadcast('setImageToCrop', $scope.imageToCrop);
                                    });
                                };
                                reader.readAsDataURL(fileItem._file);
                            }
                            $rootScope.$broadcast('filesSelected');
                            $rootScope.uploaderHasFiles = true;

                            var multiple = false;
                            var cropImage = false;

                            if($scope.options){
                                multiple = $scope.options.multiple;
                                cropImage = $scope.options.cropImage ? true : false;
                            }

                            if (onUpload.useFilename) {
                                fileItem.alias = fileItem.file.name;
                            }

                            if (multiple || !cropImage) {
                                uploader.uploadAll();
                            }
                        };
                    }

                    uploader.onBeforeUploadItem = function(fileItem) {
                        if (fileItem.file.type.startsWith('image') && onUpload.cropImage) {
                            var blob = dataURItoBlob($scope.croppedImage);
                            fileItem._file = blob;
                        }
                    };

                    $scope.$on('clearAttributeValue', function(data) {
                        $scope.filePreviewData = null;
                    });

                    uploader.onSuccessItem = function(fileItem, response, status, headers) {

                        if (_.isFunction(onUpload.uploadSuccess)) {
                            onUpload.uploadSuccess(response);
                        }

                        if (_.isFunction(onUpload.uploadComplete)) {

                            var deferred = onUpload.uploadComplete(response, fileItem, $scope);

                            if (!_.isNil(deferred) && _.isFunction(deferred.then)) {
                                deferred.then(function(uploadCompleteResponse) {
                                    handleConvertedResponse(uploadCompleteResponse);
                                });
                            } else {
                                handleConvertedResponse(response);
                            }
                        }
                    };

                    function handleConvertedResponse(response) {
                        var filePreviewData;
                        if (_.isArray(response)) {
                            if (response.length === 1) {
                                filePreviewData = angular.copy(response[0]);
                            } else {
                                return;
                            }
                        } else if (_.isObject(response)) {
                            filePreviewData = response;
                        } else {
                            return;
                        }

                        filePreviewData.isImage = false;
                        filePreviewData.url = filePreviewData.url || filePreviewData.publicAssetUrl || filePreviewData.privateAssetUrl;
                        if (!_.isNil(filePreviewData.contentType)) {
                            filePreviewData.isImage = filePreviewData.contentType.indexOf('image') > -1;
                        }

                        if ($scope.options.multiple) {
                            if(!$scope.filePreviewData){
                                $scope.filePreviewData = [];
                            }
                            $scope.filePreviewData.push(filePreviewData);
                        } else {
                            $scope.filePreviewData = filePreviewData;
                        }
                    }

                    if (onUpload.onCompleteAll) {
                        uploader.onCompleteAll = onUpload.onCompleteAll;
                    } else {
                        uploader.onCompleteAll = function() {
                            $timeout(function() {
                                $rootScope.$broadcast('filesUploaded');
                                resetUploader();
                            }, 2000);
                        };
                    }

                    $scope.uploader = uploader;

                    // LAX-1708 bug fix
                    FileUploader.FileSelect.prototype.isEmptyAfterSelection = function() {
                        return false;
                    };

                    function getFilters() {
                        var newFilters = [];
                        var enableFilters = _.union(onUpload.filters, ['maxFileSizeFilter']);
                        newFilters = customFilters.filter(function(elem) {
                            return _.includes(enableFilters, elem.name);
                        });
                        return newFilters;
                    }

                    function dataURItoBlob(dataURI) {
                        var binary = atob(dataURI.split(',')[1]);
                        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                        var array = [];
                        for (var i = 0; i < binary.length; i++) {
                            array.push(binary.charCodeAt(i));
                        }
                        return new Blob([new Uint8Array(array)], {
                            type: mimeString
                        });
                    }

                    $scope.toggleFileUpload = function() {
                        $scope.showFileUpload = !$scope.showFileUpload;
                        $rootScope.$broadcast('toggleFileUpload', $scope.uploadId);
                    };

                    $scope.cancel = function() {
                        resetUploader();
                    };

                    function resetUploader() {
                        $scope.uploader.cancelAll();
                        $scope.uploader.clearQueue();
                        $scope.clearInput();
                        $scope.imageToCrop = '';
                        $scope.toggleFileUpload();
                        $rootScope.uploaderHasFiles = false;
                        $rootScope.reloadAfterDataModelUpdate();
                    }

                    $scope.provideLinkModal = function (attribute, item, model, uploader, filePreviewData) {
                       var modalPromise = $modal.open({
                            templateUrl: 'tpl/provide-link.tpl.html',
                            controller: 'provideLinkController',
                            backdrop: true,
                            resolve: {
                                attribute: function() {
                                    return attribute;
                                },
                                item: function() {
                                    return item;
                                },
                                model: function() {
                                    return model;
                                },
                                uploader: function() {
                                    return uploader;
                                },
                                filePreviewData: function() {
                                    return filePreviewData;
                                }
                            }
                        });

                        modalPromise.result.then(function (result) {
                            //accecpted
                            $scope.filePreviewData = result;
                            $rootScope.$broadcast('filesUploaded', $scope.filePreviewData);
                        }, function (result) {
                            //dismissed
                            console.log("Link modal dismissed");
                        });
                    };
                    $scope.removeAttachement = function(file){
                        _.remove($scope.filePreviewData, {
                            sha1: file.sha1
                        });
                        $rootScope.$broadcast('removeAttachement', file);
                    };

                    $scope.showImage = function (filePreviewData) {
                        $rootScope.additionalModalOpen = true;
                        var modalPromise = $modal.open({
                            templateUrl: 'tpl/editorShowImage.tpl.html',
                            controller: 'showImageController',
                            windowClass: 'editor-show-image',
                            backdrop: true,
                            resolve: {
                                filePreviewData: function () {
                                    return filePreviewData;
                                }
                            }
                        });
                    };

                    $element.on('dragleave', function () {
                        $element.find('.drag-over').removeClass("drag-over");
                    });

                    $scope.openYoutubePlayer = function(videoId) {
                        $rootScope.additionalModalOpen = true;
                        var modalPromise = $modal.open({
                            templateUrl: 'tpl/editorShowYoutube.tpl.html',
                            controller: 'showYoutubeController',
                            windowClass: 'editor-show-youtube',
                            backdrop: true,
                            resolve: {
                                videoId: function () {
                                    return videoId;
                                }
                            }
                        });
                    };

                    $scope.imageInputClick = function (disableField, url) {
                        if (disableField) {
                            var win = $window.open(url, '_blank');
                            win.focus();
                        }
                    };

                    $scope.hiddenInputClick = function($event) {
                        if ($scope.useAssetFolders) {
                            $event.preventDefault();
                            $scope.showAssetFoldersModal();
                        }
                    };

                    $scope.showAssetFoldersModal = function () {
                        $modal.open({
                            templateUrl: 'tpl/asset-folders/modal-asset-folders.tpl.html',
                            controller: 'AssetFoldersController',
                            backdrop: true,
                            size: 'lg',
                            windowClass: 'asset-folders-modal',
                            resolve: {
                                modalParams: function() {
                                    return {
                                        accept: onUpload.accept,
                                        onFileSelected: function(linkedAsset, linkedAssetPath) {
                                            if (_.isFunction(onUpload.onFileSelected)) {
                                                onUpload.onFileSelected(linkedAsset, linkedAssetPath, function(response) {
                                                    $rootScope.$broadcast('filesSelected', {
                                                        scopeId: $scope.$id,
                                                        fileName: linkedAsset.name
                                                    });

                                                    response.url = response.url || response.publicAssetUrl || response.privateAssetUrl;
                                                    uploader.onSuccessItem(linkedAsset, response, 200, {});
                                                    uploader.onCompleteAll();
                                                });
                                            } else {
                                                $log.error('fileUpload: Trying to select asset file but `onFileSelected` was not defined.');
                                            }
                                        }
                                    };
                                }
                            }
                        });
                    };

                    $scope.isUploadQueueInProgressOrDone = function() {
                        return !_.isEmpty(
                            _.find(uploader.queue, function(item) {
                                return item.isUploading || item.isUploaded;
                            })
                        );
                    };
                    $scope.getFileUploaderPlaceholderText = function() {
                        if (onUpload.disableDragAndDrop) {
                            return $translate.instant("UPLOAD.PLACEHOLDER.DISABLED.DND");
                        } else {
                            return $translate.instant("UPLOAD.PLACEHOLDER.ENABLED.DND");
                        }
                    };
                }
            ],
            transclude: true,
            replace: true,
            scope: {
                uploadId: '@uploadId',
                buttonLabel: '@buttonLabel',
                buttonIcon: '@buttonIcon',
                submitLabel: '@submitLabel',
                onUpload: '&onUpload',
                fileType: "=fileType",
                itemObj: "=",
                model:"=",
                modelString:"=",
                attribute: "=",
                inlineInput: "=",
                settings: "=",
                ecIndex: "=",
                uploader: "=",
                useAssetFolders: '='
            },
            link: function (scope, element, attributes) {

                if (!_.isNil(scope.attribute)) {
                    var tmpString = "itemObj." + scope.attribute.name;

                    if (!_.isNil(scope.modelString) && !_.isEqual("item[a.name]", scope.modelString)) {
                        tmpString = scope.modelString.replace("item", "itemObj");
                    }

                    scope.$watch(tmpString, function() {
                        start();
                    });
                }

                scope.$watch('showFileUpload', function() {
                    var userImage = element.parent().find('.user-image');
                    if (scope.showFileUpload) {
                        userImage.hide();
                    } else {
                        userImage.show();
                    }
                });

                var hiddenInput = element.find('[id$=_button_hidden]');
                $timeout(function() {
                    element.find('.fake-upload-button').click(function() {
                        hiddenInput.click();
                    });
                });

                start();

                scope.thumbnailToggle = function () {
                    scope.showThumbnail = true;
                };

                scope.clearInput = function() {
                    // This seems to be the only way to clear the file input!
                    if (hiddenInput && hiddenInput[0] && hiddenInput[0].value) {
                        hiddenInput[0].value = '';
                    }
                };

                function start() {
                    scope.options = {
                        dragDropUpload: angular.isDefined(attributes.dragDropUpload),
                        accept: scope.onUpload().accept,
                        cropImage: scope.onUpload().cropImage,
                        maxFileSize: scope.onUpload().maxFileSize,
                        multiple: angular.isDefined(attributes.multiple)
                    };
                    if (scope.options.multiple) {
                        scope.filePreviewData = [];
                    }

                    var modelEval = $parse(scope.modelString);
                    var modelValue = modelEval(scope);

                    if (scope.model || modelValue) {
                        if (_.isEmpty(scope.model)) {
                            scope.model = modelValue;
                        }
                        var url = scope.model;
                        scope.filePreviewData = {};
                        var type = url.split('.').pop().split(/\?|#/).shift();
                        if (_.includes(url, "/image")) {
                            type = 'image';
                        }
                        scope.filePreviewData.url = url;
                        scope.filePreviewData.isImage = false;
                        scope.filePreviewData.path = url.slice(url.lastIndexOf('/'));
                        switch (type) {
                            case 'svg':
                                scope.filePreviewData.contentType = "image/svg+xml";
                                if (!Modernizr.svg) {
                                    scope.filePreviewData.notSupported = true;
                                }
                                break;
                            case 'webp':
                                scope.filePreviewData.isImage = true;
                                scope.filePreviewData.contentType = "image/webp";
                                if (Modernizr.webp.toString() !== 'true') {
                                    scope.filePreviewData.notSupported = true;
                                }
                                break;
                            case 'tif':
                            case 'xlsx':
                            case 'xls':
                            case 'tiff':
                                scope.filePreviewData.contentType = "image/tiff";
                                scope.filePreviewData.notSupported = true;
                                break;
                            case 'pdf':
                                scope.filePreviewData.contentType = "application/pdf";
                                break;
                            case "jpg":
                            case "jpeg":
                            case "gif":
                            case "png":
                            case "bmp":
                            case "tif":
                            case "tiff":
                            case "image":
                                scope.filePreviewData.isImage = true;
                                scope.filePreviewData.alt = $filter('fileName', scope.filePreviewData.url);
                                break;
                            default:
                                scope.filePreviewData.alt = $filter('fileName', scope.filePreviewData.url);
                                break;
                        }
                    }
                    if (attributes.multiple !== undefined) {
                        hiddenInput.attr('multiple', 'multiple');
                    }
                    if (scope.options.accept) {
                        hiddenInput.attr('accept', scope.options.accept);
                    }
                    if (attributes.buttonInline !== undefined) {
                        scope.buttonInline = true;
                        element.css('display', 'inline-block');
                    }
                }
            }
        };
    }
])
.directive('gridFileDialog', ['$compile', '$injector', function($compile, $injector){
    return {
        restrict: 'A',
        priority: 1000,
        terminal: true,
        link: function(scope, element) {

            var customFileDialog = $injector.has('customGridFileDialogDirective');

            if (customFileDialog) {
                element.removeAttr('data-grid-file-dialog');
                element.attr('data-custom-grid-file-dialog', '');
            } else {
                // Remove self so we won't trigger a loop.
                element.removeAttr('data-grid-file-dialog');
            }

            $compile(element)(scope);
        },
        /*ngInject*/
        controller: function ($scope, $modal) {
            $scope.showGridFileDialog = function(row, col, appScope) {
                $modal.open({
                    templateUrl: 'tpl/grid-file-dialog.tpl.html',
                    controller: showGridFileDialogCtrl,
                    backdrop: true,
                    resolve: {
                        row: function() {
                            return row;
                        },
                        col: function() {
                            return col;
                        },
                        appScope: function() {
                            return appScope;
                        }
                    }
                });

            };
            var showGridFileDialogCtrl = /*@ngInject*/ function ($log, $scope, $rootScope, $modalInstance, row, col, appScope, AssetFoldersService, growl) {

                $scope.item = row.entity;
                $scope.a = col.colDef.attribute;
                $scope.fileType = ($scope.a && $scope.a.typeName) ? $scope.a.typeName.toLowerCase() : '';
                $scope.readonly = col.colDef.readonly || (row.editable === false) || (row.editable === undefined && !appScope.isRowEditable(row));

                var originalValue = $scope.item[$scope.a.name];
                if (!$scope.readonly) {
                    $scope.$parent.$broadcast('laxGridStartCellEdit', row, col);
                }

                $scope.saveAsset = function() {
                    $modalInstance.close('asset saved');
                    $scope.$parent.$broadcast('laxGridStopCellEdit', row, col);
                };

                $scope.cancel = function () {
                    $modalInstance.dismiss('cancel');
                    if (!$scope.readonly) {
                        var newValue = row.entity[col.field];
                        if (!angular.equals(newValue, originalValue)) {
                            row.entity[col.field] = originalValue;
                            // TODO: Delete asset on cancel, when asset was changed
                        }
                        $scope.$parent.$broadcast('laxGridStopCellEdit', row, col);
                    }
                };

                var onUpload = function (row, col, newValue) {
                    row.entity[col.field] = newValue;
                };

                $scope.uploadFileForItem = function() {
                    var config = {};
                    var currentLayout = $rootScope.getCurrentLayout();
                    switch ($scope.a.typeName) {
                        case "Image":
                            config = {
                                autoUpload: true,
                                filters: ['imageFilter'],
                                accept: 'image/jpeg,image/gif,image/png,image/tiff,image/bmp,image/webp,image/svg+xml,application/pdf'
                            };
                            var maxFileSize = $scope.dataModel.sectionAttributeParam(currentLayout, null, $scope.a.name, 'maxFileSize');
                            if (maxFileSize) {
                                config.filters.push('maxFileSizeFilter');
                                config.maxFileSize = maxFileSize;
                            }
                            break;
                        case "Document":
                            config = {
                                autoUpload: true
                            };
                            break;
                    }

                    return angular.extend({
                        url: AssetFoldersService.getDefaultUploadUrl(),
                        reset: true,
                        formData: [],
                        noNameEncoding: true,
                        useFilename:true,
                        uploadComplete: function(response) {
                            return AssetFoldersService.getPublicAssetUrlAsync(response, response.path)
                                .then(function(linkedPublicAsset) {
                                    onUpload(row, col, linkedPublicAsset.publicAssetUrl);
                                    return linkedPublicAsset;
                                })
                                .catch(function(error) {
                                    $log.error(error);
                                });
                        },
                        onErrorItem: function(item, response, status, headers) {
                            $log.error(response);

                            if (!_.isNil(response.errorCode)) {
                                growl.error(response.message, { variables : { name: item.file.name } });
                            } else {
                                growl.error("ASSET_FOLDER.ERROR_OCCURRED");
                            }
                        },
                        onFileSelected: function(linkedAsset, linkedAssetPath, done) {
                            AssetFoldersService.getPublicAssetUrlAsync(linkedAsset, linkedAssetPath)
                                .then(function(linkedPublicAsset) {
                                    onUpload(row, col, linkedPublicAsset.publicAssetUrl);
                                    done(linkedPublicAsset);
                                }, function(error) {
                                    $log.error(error);
                                    done(linkedPublicAsset);
                                });
                        }
                    }, config);
                };

            };

        }
    };
}])
.directive('imagePreview', function($compile, $filter) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            var params = scope.$eval(attrs.imagePreview);
            var image = params.file;
            var type = image.split('.').pop().split(/\?|#/).shift();
            var imageElement;

            function getImageElement(element) {
                if (element == 'image') {
                    imageElement = angular.element('<img class="item-image itemImage-large image-bordered">');
                } else if (element == 'object') {
                    imageElement = angular.element('<object></object>');
                    imageElement.html(params.placeholder ? params.placeholder : '<span class="label label-danger" data-translate>FILE_NOT_FOUND</span>');
                    imageElement.attr('data', image);
                }
                imageElement.attr('id', params.name + '_image');
                imageElement.attr('class', params.class);
                return imageElement;
            }

            switch (type) {
                case 'svg':
                    imageElement = getImageElement('object');
                    imageElement.attr('type', "image/svg+xml");
                    if (!Modernizr.svg) {
                        imageElement.append('<br><span class="label label-warning mt">{{\'NOT_SUPPORTED_BY_BROWSER\' | translate}}</span>');
                    }
                    break;
                case 'webp':
                    imageElement = getImageElement('object');
                    imageElement.attr('type', "image/webp");
                    if (Modernizr.webp.toString() !== 'true') {
                        imageElement.append('<br><span class="label label-warning mt">{{\'NOT_SUPPORTED_BY_BROWSER\' | translate}}</span>');
                    }
                    break;
                case 'tif':
                case 'tiff':
                    imageElement = getImageElement('object');
                    imageElement.attr('type', "image/tiff");
                    imageElement.append('<br><span class="label label-warning mt">{{\'NOT_SUPPORTED_BY_BROWSER\' | translate}}</span>');
                    break;
                case 'pdf':
                    imageElement = getImageElement('object');
                    imageElement.attr('type', "application/pdf");
                    break;
                default:
                    imageElement = getImageElement('image');
                    imageElement.attr('src', image);
                    // imageElement.attr('data-err-src',
                    //     "{type: 'text', value: '<span class=\"label label-danger\">{{'IMAGE_NOT_FOUND' | translate}}</span>'}");
                    imageElement.attr('alt', $filter('fileName', image));
            }
            elem.prepend(imageElement);
            $compile(imageElement)(scope);
        }
    };
})
.directive('filetype', ['$window','$compile', function($window, $compile) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

            var codes = {
                "image"     : ["jpg", "jpeg", "gif", "png", "bmp", "svg", "image"],
                "code"      : ["css", "html", "rb", "js", "json", "xml", "code"],
                "text"      : ["txt", "csv", "rtf", "odt", "text"],
                "zip"       : ["zip", "gz", "archive"],
                "excel"     : ["xls", "xlsx", "excel"],
                "word"      : ["doc", "docx", "word"],
                "powerpoint": ["ppt", "pptx", "powerpoint"],
                "pdf"       : ["pdf"]
            };

            function getFileType(url) {
                if (url === undefined) {
                    return false;
                }
                var fileType = (url).substring((url.lastIndexOf('.') || url.lastIndexOf('/')) + 1);
                return fileType.toLowerCase();
            }

            function getClass(fileType) {
                for (var key in codes) {
                    var val = codes[key];
                    if (val.contains(fileType.toLowerCase())) {
                        return key;
                    }
                }
            }

            function setFileType(filePreviewData) {
                var fileTypeElement;
                var fieldValue = scope.row ? scope.row.entity[scope.$parent.col.field] : null;
                var url = attrs.ngHref ? attrs.ngHref : fieldValue;
                if (filePreviewData) {
                    url = filePreviewData.url;
                }

                if (scope.row && !_.includes(url, scope.row.entity[scope.$parent.col.field])) {
                    url = scope.row.entity[scope.$parent.col.field];
                }

                var fileType;
                var type;

                if (_.isNil(url)) {
                    fileType = getFileType(attrs.filetype);
                    type = getClass(fileType);
                    if (type) {
                        fileTypeElement = '<i class="syncons syncons-file-' + type + '"></i>';
                    } else {
                        fileTypeElement = '<span class="file-wrapper">' +
                                '<span class="filetype-text">' + fileType.toUpperCase().substring(0, 3) + '</span>' +
                                '<i class="syncons syncons-file-raw"></i>' +
                            '</span>';
                    }
                    var childCount = _.get(elem, 'context.childElementCount');
                    if (childCount === undefined || childCount > 0) {
                        elem.empty();
                    }
                    elem.append(fileTypeElement);
                    return;
                }
                var hostname = url.replace('http://','').replace('https://','').split(/[/?#]/)[0];
                var videoId;
                elem.empty();
                angular.element(elem.context.parentElement).find('.image-preview').remove();

                var faviconUrl;

                if (_.includes(hostname, $window.location.hostname)) {
                    // uploaded file
                    fileType = getFileType(attrs.filetype);
                    type = getClass(fileType);
                    if (type) {
                        fileTypeElement = '<i class="syncons syncons-file-' + type + '"></i>';
                    } else {
                        fileTypeElement = '<span class="file-wrapper">' +
                                '<span class="filetype-text">' + fileType.toUpperCase().substring(0, 3) + '</span>' +
                                '<i class="syncons syncons-file-raw"></i>' +
                            '</span>';
                    }
                } else if (_.includes(hostname, "youtube") || _.includes(hostname, "youtu.be")) {

                    videoId = parseYoutubeId(url);
                    if (videoId && (typeof (attrs.filetypeDisableVideoPreview) === "undefined")) {
                        var imgUrl = "https://img.youtube.com/vi/" + videoId + "/0.jpg";
                        fileTypeElement = '<div data-ng-show="!uploader.queue.length" class="image-preview" data-ng-click="openYoutubePlayer(\'' + videoId + '\')"><img class="youtube-thumbnail" src="' + imgUrl + '"></img><i class="glyphicon glyphicon-play youtube-play"></i></div>';
                    } else {
                        faviconUrl = "https://youtube.com/favicon.ico";
                        fileTypeElement = '<span class="file-wrapper">' +
                            '<span class="filetype-text"><img class="favicon" src="' + faviconUrl + '"></img></span>' +
                            '<i class="syncons syncons-file-raw"></i>' +
                            '</span>';
                            videoId = undefined;
                    }

                } else {
                    //external link
                    fileType = getFileType(attrs.filetype);
                    if (!_.includes(url, attrs.filetype)) {
                        fileType = getFileType(url);
                    }
                    type = getClass(fileType);
                    if (type) {
                        fileTypeElement = '<i class="syncons syncons-file-' + type + '"></i>';
                    } else {
                        faviconUrl = "https://"+ hostname + "/favicon.ico";
                        fileTypeElement = '<span class="file-wrapper">' +
                        '<span class="filetype-text"><img class="favicon" src="'+ faviconUrl +'"></img></span>' +
                        '<i class="syncons syncons-file-raw"></i>' +
                        '</span>';
                    }
                }

                if (videoId) {
                    angular.element(elem.context.parentElement).prepend($compile(fileTypeElement)(scope));
                } else {
                    elem.append(fileTypeElement);
                }

            }
            setFileType();

            function parseYoutubeId(url) {
                var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                var match = url.match(regExp);
                if (match && match[2].length == 11) {
                    return match[2];
                } else {
                    return undefined;
                }
            }

            scope.$on('filesUploaded', function(event, filePreviewData) {
                setFileType(filePreviewData);
            });

            scope.$on('changedURL', function(event, filePreviewData) {
                setFileType(filePreviewData);
            });

           scope.$on('reloadBrowseListFileTypes', function(event, result) {
                setFileType();
            });
        }
    };
}])
.directive('shortFilename', [function() {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var name = attrs.shortFilename;
            var maxLength = attrs.maxLength || 10;
            if (name.length > maxLength) {
                var fileTypeArray = _.split(name, '.');
                if (fileTypeArray.length <= 1) {
                    return;
                }
                var filetype = _.last(fileTypeArray);
                if (name.length - (filetype.length + 1) <= 8) {
                    return;
                }
                var cutLength = maxLength - filetype.length;
                cutLength = cutLength < (maxLength- 4) ? (maxLength- 4) : cutLength;
                var shortName = _.truncate(name, {
                    'length': cutLength,
                });
                shortName = shortName + name.substring(name.length - (filetype.length + 1) - 3, name.length - (filetype.length + 1));
                shortName = shortName + '.' + filetype;
                elem.empty();
                elem.append(shortName);
            }
        }
    };
}])
.directive('imageOnError', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {
                setTimeout(function() {
                    scope.filePreviewError = false;
                    scope.$apply();
                  }, 10);
            });
            element.bind('error', function(){
                setTimeout(function() {
                    scope.filePreviewError = true;
                    scope.$apply();
                  }, 10);
            });
        }
    };
})
.directive('ngThumb', ['$window', '$filter',
    function($window, $filter) {
        var helper = {
            support: !!($window.FileReader && $window.CanvasRenderingContext2D),
            isFile: function(item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function(file) {
                return $filter('isFileType')(file, 'image');
            }
        };

        return {
            restrict: 'A',
            template: '<canvas/>',
            link: function(scope, element, attributes) {
                if (!helper.support) return;

                var params = scope.$eval(attributes.ngThumb);

                if (!helper.isFile(params.file)) return;
                if (!helper.isImage(params.file)) return;

                var canvas = element.find('canvas');
                var reader = new FileReader();

                reader.onload = onLoadFile;
                reader.readAsDataURL(params.file);

                function onLoadFile(event) {
                    var img = new Image();
                    img.onload = onLoadImage;
                    img.src = event.target.result;
                }

                function onLoadImage() {
                    var width = params.width || this.width / this.height * params.height;
                    var height = params.height || this.height / this.width * params.width;
                    canvas.attr({
                        width: width,
                        height: height
                    });
                    canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
                }
            }
        };
    }
])
.directive('formInput', function() {
    return {
        replace: true,
        restrict: 'AE',
        scope: {},
        templateUrl: 'tpl/form-input.tpl.html',
        transclude: true,
        link: function(scope, element, attrs) {

            scope.inputId = attrs.formInput;
            scope.icon = attrs.formInputIcon || attrs.icon;
            scope.label = attrs.formInputLabel || attrs.label;
            scope.clearable = attrs.formInputClearable || attrs.clearable;
            scope.isClearable = !_.isNil(scope.clearable) && (scope.clearable !== 'false');

            scope.clearValue = function() {
                if (!_.isNil(scope.inputId)) {
                    // FIXME: Check if 'clearValue' method is defined on $parent!
                    scope.$parent.clearValue(scope.inputId);
                }
            };

        }
    };
})
.directive('physicalAttribute', function($rootScope, EnumAttributeService, PhysicalAttributeService) {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function(scope, element, attributes, ngModelCtrl) {

            var dataModelAttribute = scope.$eval(attributes.physicalAttribute);
            scope.getFilteredUnits = function() {
                // 'getTranslatedOptions' gets key-value pairs of the available options (even for option lists)
                var units = $rootScope.getTranslatedOptions(dataModelAttribute);
                var item = $rootScope.getItemInContext(scope.currentLayout, scope, null, dataModelAttribute);
                var filteredUnits = EnumAttributeService.filterOptions(item, dataModelAttribute, null, null, units);

                scope.units = filteredUnits;
                return filteredUnits;
            };

            scope.parseValue = function(value) {
                value = PhysicalAttributeService.parseValue(value, scope.units);
                ngModelCtrl.$setViewValue(value);
                return value;
            };

            scope.formatValue = function(value) {
                return value ? PhysicalAttributeService.formatValue(value, scope.units) : '';
            };

            if (ngModelCtrl !== undefined) {
                ngModelCtrl.$parsers.push(scope.parseValue);
                ngModelCtrl.$formatters.push(scope.formatValue);
            }

            scope.typeaheadSelected = function(closeEvent) {
                scope.$emit(closeEvent);
            };

            // Prepare units for already populated values
            scope.getFilteredUnits();
        }
    };
})
.directive('resize', function($window, $timeout) {
    return function(scope, elem) {
        var w = angular.element($window);
        var mh = angular.element('.modal-header');
        var mf = angular.element('.modal-footer');
        var mp = angular.element('.modal-dialog');

        var calculateLayout = function() {
            var height = (w.height() - parseInt(mh.css('height'), 10) -
                parseInt(mf.css('height'), 10) -
                parseInt(mp.css('padding-top'), 10) - 125) + 'px';
            elem.css('height', height);
        };

        $timeout(function() {
            calculateLayout();
        });

        w.bind('resize', _.debounce(calculateLayout, 150));

        scope.$on('$destroy()', function() {
            w.off('resize');
        });
    };
})
.directive('autoResizeElement', function($window, $timeout) {
    return function(scope, element, attributes) {

        var w = angular.element($window);
        var resize = function() {
            if (attributes.disabled || attributes.readonly) {
                var e = element[0];
                element.css({ 'resize': 'none'});
                if(e.scrollHeight <= e.clientHeight) {
                    e.style.height = '0px';
                }
                var count = (parseInt(element[0].scrollHeight - 12)/20);
                var rows = attributes.rows ? attributes.rows : 5;
                if (count <= rows) {
                    var diff = 0;
                    e.style.overflow = 'unset';
                    if (e.nodeName == "DIV") {
                        diff = -4;
                    }
                    var h = e.scrollHeight + e.offsetHeight - e.clientHeight + diff;
                    e.style.height = h +'px';
                } else {
                    e.style.height = (rows*20) + 'px';
                    e.style.overflow = 'auto';
                }
            }
        };

        $timeout(function() {
            resize();
            if (_.has(scope, 'selectedDimension') && (attributes.disabled || attributes.readonly)) {
                scope.$watch('selectedDimension', function(x) {
                    resize();
                });
            }
        });

        w.bind('resize', _.debounce(resize, 150));

        scope.$on('$destroy()', function() {
            w.off('resize');
        });
    };
})
.directive('resizeToWindowHeight', function($window, $timeout) {
    return function(scope, elem, attrs) {
        var w = angular.element($window);
        var footer = angular.element('footer');
        var footerHeight = parseInt(footer.css('height'), 10) +
            parseInt(footer.css('padding-top'), 10) +
            parseInt(footer.css('padding-bottom'), 10);
        var toolbar = angular.element('.columnView-tools');
        var buttonsHeight = 75;
        var h_prefix = ['min','max'].indexOf(attrs.resizeToWindowHeight) > -1 ? attrs.resizeToWindowHeight + '-' : "";
        var changeInHeight = parseInt(attrs.changeInHeight);
        changeInHeight = isNaN(changeInHeight) ? 0 : changeInHeight;
        var calculateLayout = function() {
            var height = (w.height() - elem.offset().top - footerHeight - toolbar.outerHeight() - buttonsHeight);
            if (height < 400) {
                height = 400;
            }
            height+= changeInHeight;
            elem.css(h_prefix + 'height', height + 'px');
        };

        $timeout(function() {
            calculateLayout();
        });

        w.bind('resize', _.debounce(calculateLayout, 150));

        scope.$on('$destroy()', function() {
            w.off('resize');
        });
    };
})
.directive('resizeItemGrid', function($window, $timeout) {
    return function(scope, elem) {
        var w = angular.element($window);

        var footer = angular.element('footer');
        var boxContent = angular.element('.box-content');
        var content = angular.element('#content');

        var calculateLayout = function () {
            var boxContentPadding = parseInt(boxContent.css('padding-bottom'), 10);
            var contentPadding = parseInt(content.css('padding-bottom'), 10);
            var filter = angular.element('.filter-rules-wrapper');
            var visibleRowHeight = scope.gridApi.core.getVisibleRows().length * 34;
            var height;
            if (filter.length == 0 || _.includes(filter[0].className, 'ng-hide')) {
                var offset = elem.offset().top + footer.innerHeight() + boxContentPadding + contentPadding;
                height = w.height() - offset;
            } else {
                height = w.height()/2;
            }
            elem.css('height', height);
            scope.gridApi.core.handleWindowResize();

            if (scope.gridApi.core.getVisibleRows().length > 0 && height > visibleRowHeight) {
                scope.loadMore();
            }
        };

        $timeout(function() {
            calculateLayout();
        });

        w.bind('resize', _.debounce(calculateLayout, 150));
        scope.$on('$destroy()', function() {
            w.off('resize');
        });

        scope.$watch("items",
            function() {
                $timeout(function() {
                    calculateLayout();
                });
            }, true);
    };
})
.directive('mainMenuToggleIcons', function ($window) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            var w = angular.element($window);
            function mainMenuClick() {
                if(elem.hasClass('full')) {
                    collapseSidebar();
                } else {
                    expandSidebar();
                }
            }

            function collapseSidebar() {
                    elem.removeClass('full').addClass('minified').find('i').addClass('syncons-double-arrows-right').removeClass('syncons-double-arrows-left');
                    angular.element('body').addClass('sidebar-minified');
                    angular.element('#content').addClass('sidebar-minified');
                    angular.element('#sidebar-left').addClass('minified');
                    angular.element('.dropmenu > .chevron').removeClass('opened').addClass('closed');
                    angular.element('.dropmenu').parent().find('ul').hide();
                    angular.element('#sidebar-left > aside > ul > li > a  > span > .chevron').removeClass('closed').addClass('opened');
                    angular.element('#sidebar-left > aside > ul > li > a').addClass('open');
            }

            function expandSidebar() {
                    elem.removeClass('minified').addClass('full').find('i').addClass('syncons-double-arrows-left').removeClass('syncons-double-arrows-right');
                    angular.element('body').removeClass('sidebar-minified');
                    angular.element('#content').removeClass('sidebar-minified');
                    angular.element('#sidebar-left').removeClass('minified');
                    angular.element('#sidebar-left > aside > ul > li > a > span > .chevron').removeClass('opened').addClass('closed');
                    angular.element('#sidebar-left > aside > ul > li > a').removeClass('open');
            }

            elem.on('click', function() {
                mainMenuClick();
            });

            function expandOrCollapse(w) {
                if (w.width() <= '993' && w.width() >= '768' ) {
                    collapseSidebar();
                } else if (w.width() <= '767') {
                    expandSidebar();
                } else {
                    expandSidebar();
                }
            }

            w.on('resize', function() {
                expandOrCollapse(w);
            });

            setTimeout(function()  {
                 expandOrCollapse(w);
                 angular.element('#sidebar-left').removeClass('hidden');
            });

            scope.$on('$destroy()', function() {
                w.off('resize');
            });

            scope.$on('toggleSidebar', function() {
                mainMenuClick();
            });
        }
    };
})
.directive('mainMenuToggle', function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            scope.mainMenuToggle = function () {
                if(elem.hasClass('open')) {
                    elem.removeClass('open').addClass('close');
                    angular.element('#content').addClass('full');
                    angular.element('.navbar-brand').addClass('noBg');
                    angular.element('#sidebar-left').hide();
                } else {
                    elem.removeClass('close').addClass('open');
                    angular.element('#content').removeClass('full');
                    angular.element('.navbar-brand').removeClass('noBg');
                    angular.element('#sidebar-left').show();
                }
            };
        }
    };
})
.directive('dropMenuToggle', function () {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs) {
            scope.dropMenuClick = function (event) {
                event.preventDefault();

                if(angular.element('#sidebar-left').hasClass('minified')) {
                    if(elem.hasClass('open')) {
                        return;
                    } else {
                        elem.parent().find('ul').first().slideToggle();
                        if(elem.find('.chevron').hasClass('closed')) {
                            elem.find('.chevron').removeClass('closed').addClass('opened');
                        } else {
                            elem.find('.chevron').removeClass('opened').addClass('closed');
                        }
                    }
                } else {
                    elem.parent().find('ul').first().slideToggle();
                    if(elem.find('.chevron').hasClass('closed')) {
                        elem.find('.chevron').removeClass('closed').addClass('opened');
                    } else {
                        elem.find('.chevron').removeClass('opened').addClass('closed');
                    }
                }
            };
        }
    };
})
.directive('activeLink', function($location) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var activeClass = attrs.activeLink;
            var path = attrs.activeHref || attrs.href || attrs.ngHref;
            var exactMatch = scope.$eval(attrs.activeExact) || false;
            scope.$on('$locationChangeSuccess', function(event, newPath) {
                if (exactMatch && _.endsWith(newPath, path) || !exactMatch && _.includes(newPath, path)) {
                    element.parent('li').addClass(activeClass);
                } else {
                    element.parent('li').removeClass(activeClass);
                }
            });
        }
    };
})
.directive('errSrc', function($compile) {
    return {
        terminal: true,
        link: function(scope, element, attrs) {
            element.on('error', function() {

                var params = scope.$eval(attrs.errSrc);
                element.removeAttr(attrs.$attr.errSrc);

                switch (params.type) {
                    case 'icon':
                        var titleString = "";
                        if (element.attr("title")) {
                            titleString = " title= '" + element.attr("title") + "'";
                        }
                        var label = '<span class="' + params.value + '"' + titleString + '></span>';
                        element.parent().prepend(label);
                        element.off('error');
                        element.remove();
                        break;
                    case 'image':
                        element.attr("src", params.value);
                        element.off('error');
                        break;
                    case 'text':
                        var elem = '<span>' + params.value + '</span>';
                        if (params.value.startsWith('<')) {
                            elem = params.value;
                        }
                        element.parent().prepend(elem);
                        $compile(elem)(scope);
                        element.off('error');
                        element.remove();
                        break;
                    default:
                        element.off('error');
                        break;
                }
            });
        }
    };
})
.directive('flotChart', function() {
    return {
        restrict: 'EA',
        link: function(scope, element, attr) {
            var widgetContainer = angular.element(element).closest('.widget-content');
            var cht = widgetContainer.height();
            widgetScope = widgetContainer.scope();
            var statsSpaceRequired = attr.extraHeightRequired;
            var extraHeight = 0;
            if (statsSpaceRequired) {
                extraHeight = statsSpaceRequired;
            }
            element.css('height',(cht-extraHeight)+ 'px');

            widgetScope.$on('widgetResized', function(event, data) {
                var cht = widgetContainer.height();
                element.css('height',(cht-extraHeight)+ 'px');
                jQuery.plot(element, scope.value.data, scope.value.options);
            });

            scope.$watch(attr.myModel, function(x) {
                if ((!x) || (!x.data) || x.data.length<1) {
                    return;
                }
                element.unbind();
                if (x.plotclick) {
                    element.bind("plotclick", x.plotclick);
                }
                jQuery.plot(element, x.data, x.options);
            }, true);
        }
    };
})
.directive('loadingSpinner', function() {
    return {
        restrict: 'A',
        template: '<div class="row"> ' +
            '<div class="loading-spinner" data-ng-class="{\'overlay\': overlay}"> ' +
                '<img class="center-block" src="images/ajax-loader.gif" /> ' +
            '</div>' +
        '</div>',
        link: function(scope, elem, attrs) {
            scope.overlay = attrs.overlay;
        }
    };
})
.directive('wizard', function($window) {
    return {
        restrict: 'A',
        template: '<div class="row clearfix">' +
            '<div class="wizard col-lg-12">' +
                '<ul class="steps clearfix">' +
                    '<stepbadge></stepbadge>' +
                '</ul>' +
                '<div class="col-md-12">' +
                    '<div data-ng-if="steps[currentStepIndex].notification" class="row">' +
                        '<div class="step-notification col-md-12" data-ng-hide="notificationClosed">' +
                            '<span data-translate="{{steps[currentStepIndex].notification}}"></span>' +
                            '<button type="button" class="close" data-ng-click="closeNotification()" aria-hidden="true">×</button>' +
                        '</div>' +
                    '</div>' +
                    '<div class="step-content" data-ng-transclude></div> ' +
                    '<div class="actions"> ' +
                        '<button type="button" ' +
                            'class="btn btn-prev pull-left" ' +
                            'data-ng-if="!isFirstStep()" ' +
                            'data-ng-click="previousStep()"> ' +
                            '<i class="glyphicon glyphicon-chevron-left"></i>' +
                            '<span data-translate>PREVIOUS</span> ' +
                        '</button> ' +
                        '<button type="button" ' +
                            'class="btn btn-primary btn-next" ' +
                            'data-ng-if="!isLastStep()" ' +
                            'data-ng-click="nextStep()"> ' +
                            '<span data-translate>NEXT</span> ' +
                        '</button> ' +
                        '<button type="button"' +
                            'class="btn btn-primary btn-next btn-last-step"' +
                            'data-ng-click="onLastStepAction()"' +
                            'data-ng-if="isLastStep()">' +
                            '<span data-translate>{{onLastStepButtonLabel}}</span>' +
                        '</button>' +
                        '<button type="button" ' +
                            'data-ng-show="hasCancelAction" ' +
                            'class="btn btn-default btn-cancel" ' +
                            'data-ng-click="onCancelAction()"> ' +
                            '<span data-translate>CANCEL</span>' +
                        '</button>' +
                    '</div> ' +
                '</div>' +
            '</div>' +
        '</div>',
        transclude: true,
        replace: true,
        scope: {
            onInit: '&',
            onBeforeStepChange: '&',
            onStepChanging: '&',
            onAfterStepChange: '&',
            onLastStepAction: '&',
            onCancelAction: '&',
            onLastStepButtonLabel: '@'
        },
        link: function(scope, element, attrs) {
            scope.currentStepIndex = 0;
            scope.steps[scope.currentStepIndex].currentStep = true;
            if (attrs.onCancelAction) {
                scope.hasCancelAction = true;
            }
            if (attrs.appendActionsTo) {
                angular.element(attrs.appendActionsTo).append(element.find('.actions'));
            }
            if (attrs.wizard) {
                scope.wizardId = attrs.wizard;
            }
        },
        controller: function controller($scope, $element, $attrs) {
            var LocalStorage = $window.localStorage;

            $scope.steps = [];

            this.registerStep = function(step) {
                $scope.steps.push(step);
            };

            var toggleSteps = function(toStepIndex) {
                var event = {event: {stepId: $scope.steps[$scope.currentStepIndex].id, toStepId: toStepIndex}};

                var goingBackwards = toStepIndex < $scope.currentStepIndex; // when going backwards validation should not be triggered

                if ($scope.onBeforeStepChange) {
                    if (goingBackwards || $scope.onBeforeStepChange(event)) {

                        $scope.steps[$scope.currentStepIndex].currentStep = false;

                        $scope.currentStepIndex = toStepIndex;

                        $scope.steps[$scope.currentStepIndex].currentStep = true;
                    }
                }

                if ($scope.onStepChanging) {
                    $scope.onStepChanging(event);
                }

                if ($scope.onAfterStepChange) {
                    $scope.onAfterStepChange(event);
                }
            };

            $scope.nextStep = function() {
                toggleSteps($scope.currentStepIndex + 1);
            };

            $scope.previousStep = function() {
                toggleSteps($scope.currentStepIndex - 1);
            };

            $scope.isFirstStep = function() {
                return $scope.currentStepIndex === 0;
            };

            $scope.isLastStep = function() {
                return $scope.currentStepIndex === ($scope.steps.length - 1);
            };

            $scope.goToStep = function(stepIndex) {
                if (stepIndex >= $scope.currentStepIndex) {
                    return;
                }
                toggleSteps(stepIndex);
            };

            $scope.closeNotification = function() {
                LocalStorage.setItem("hideNotifications", [$scope.wizardId]);
                $scope.notificationClosed = true;
            };

            $scope.$on('goToStep', function(event, stepIndex) {
                toggleSteps(stepIndex);
            });

            if (!_.isNil($scope.onInit)) {
                $scope.onInit();
            }
        }
    };
})
.directive('step', function() {
    return {
        require: '^wizard',
        restrict: 'A',
        scope: {
            id: '@',
            title: '@'
        },
        template: '<div data-ng-show="currentStep" data-ng-class="{active: currentStep}" ng-transclude></div>',
        transclude: true,
        replace: true,
        link: function(scope, element, attrs, wizardController) {
            scope.title = attrs.title;
            scope.notification = attrs.notification;
            wizardController.registerStep(scope);
        }
    };
})
.directive('stepbadge', function() {
    return {
        require: '^wizard',
        restrict: 'E',
        scope: '=',
        template: '<li data-ng-repeat="step in steps" ' +
            '  class="col-lg-{{::12/steps.length}} col-md-{{::12/steps.length}} col-sm-{{::12/steps.length}} col-xs-{{::12/steps.length}}" ' +
            '  data-ng-class="{ \'active\': currentStepIndex == $index, \'complete\': currentStepIndex > $index }"' +
            '  data-ng-click="goToStep($index)"> ' +
            '  <span class="step-number">' +
            '    <i data-ng-show="currentStepIndex > $index" class="syncons syncons-checkmark"></i>' +
            '    <span data-ng-hide="currentStepIndex > $index">{{$index+1}}</span>' +
            '  </span>' +
            '  <span class="step-title">' +
            '    {{step.title | translate}}' +
            '  </span>' +
            '</li>',
        transclude: true,
        replace: true
    };
})
.directive('laxMatch', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, elem, attrs, ctrl) {
            scope.$watch(function() {
                return (ctrl.$pristine && angular.isUndefined(ctrl.$modelValue)) || scope.$eval(attrs.laxMatch) === ctrl.$modelValue;
            }, function(currentValue) {
                ctrl.$setValidity('match', currentValue);
            });
        }
    };
})
.directive('accordionToggle', function($timeout) {
    return {
        restrict: 'C',
        scope: '=',
        link: function(scope, elem, attrs) {
            scope.$watch('isOpen', function(isOpen) {

                if (!isOpen) {
                    return;
                }

                $timeout(function() {

                    var section = scope.$parent.section;
                    var focusSection = scope.$parent.focusSection;
                    if (!_.isUndefined(section) && _.isFunction(focusSection)) {
                        focusSection(section);
                    }

                }, 1);

            });
        }
    };
})
.directive('persistArea', function($timeout, $window) {
    return {
        restrict: 'A',
        priority: Number.MIN_SAFE_INTEGER, // execute last, after all other directives if any.
        replace: true,
        template: '<div class="panel-heading floatingHeader" data-target="{{::section.name}}">' +
                    '<h4 class="panel-title">' +
                        '<a class="accordion-toggle" data-ng-click="toggleOpen()">' +
                            '<span>{{::section.translatedLabel}}</span>' +
                            '<i class="glyphicon" data-ng-class="{\'glyphicon glyphicon-chevron-down\': section.isOpen, \'glyphicon glyphicon-chevron-right\': !section.isOpen}"></i>' +
                            '<span class="tooltip-icon icon validation-icon" data-ng-show="hasValidations(section)" tooltip-placement="right" ' +
                                'tooltip-append-to-body="true" tooltip-html-unsafe="{{getSectionValidationMessages(section)}}" tooltip-trigger="mouseenter">' +
                                '<i class="syncons syncons-error"></i>' +
                                '<span class="notification red" data-ng-show="section.validationErrorCount > 0">{{section.validationErrorCount}}</span>' +
                                '<span class="notification lower orange" data-ng-show="section.validationWarningCount > 0">{{section.validationWarningCount}}</span>' +
                            '</span>' +
                        '</a>' +
                    '</h4>' +
                '</div>',
        link: function(scope, elem, attrs) {
            var origHeader;
            var w = angular.element($window);
            origHeader = elem.next();

            function initTableHeaders() {

                scope.updateWidth();

                angular.element('.modal-body').on('scroll', function() {
                    scope.UpdateTableHeaders();
                });

                w.on('resize', function() {
                    scope.updateWidth();
                });

                scope.$on('$destroy()', function() {
                    w.off('resize');
                });
            }

            $timeout(function() {
                initTableHeaders();
            }, 0);

            scope.UpdateTableHeaders = function () {

                var offset = origHeader.position(),
                    floatingHeader = elem;

                if ( ( scope.section.isOpen ) && ( offset.top < 0 ) && ( offset.top + origHeader.height() >= 0 ) ) {
                    floatingHeader.show();
                } else {
                    floatingHeader.hide();
                }
            };

            scope.updateWidth = function() {
                elem.css("width", origHeader.innerWidth());
            };

            scope.toggleOpen = function() {
                $timeout(function() {
                    origHeader.find('.accordion-toggle').click();
                }, 10);
            };

        }
    };
})
.directive("laxInput", function($compile) {
    return {
        restrict: "AE",
        terminal: true,
        priority: 1000,
        controller: function($scope, $element) {
            $scope.isReadonlyCapableInput = function(element) {
                return element[0].nodeName === 'INPUT' ||
                       element[0].nodeName === 'TEXTAREA' ||
                       element[0].nodeName === 'SELECT';
            };

            $scope.isPlaceholderCapableInput = function(element) {
                return element[0].nodeName === 'INPUT' ||
                       element[0].nodeName === 'TEXTAREA' ||
                       element[0].nodeName === 'SELECT';
            };

            $scope.isUISelect = function(element) {
                return element[0].nodeName === 'UI-SELECT';
            };
        },
        link: function(scope, elem, attrs) {

            var laxInputName = attrs.laxInput;

            // Remove directive's attribute
            elem.removeAttr(attrs.$attr.laxInput);

            if (!attrs.id) {
                attrs.$set('id', "{{::a.name}}");
            }
            if (!attrs.ngClass) {
                attrs.$set('ngClass', "checkAttributeEditedRemote(a.name);");
            }
            if (!attrs.ngFocus) {
                attrs.$set('ngFocus', "setAttributeUrl(a.name)");
            }
            if (!attrs.ngDisabled) {
                attrs.$set('ngDisabled', "onAddView || onEditView");
            }
            if (!attrs.ngReadonly && scope.isReadonlyCapableInput(elem)) {
                attrs.$set('ngReadonly', "a.readonly");
            }

            if (scope.isPlaceholderCapableInput(elem)) {
                attrs.$set('placeholder', '{{::translatePlaceholder(a.name)}}');
            } else if (scope.isUISelect(elem)) {
                var uiSelectMatch = elem.find('ui-select-match');
                if (uiSelectMatch.length > 0) {
                    uiSelectMatch[0].setAttribute('placeholder', '{{::translatePlaceholder(a.name)}}');
                }
            }

            attrs.$set('laxFocusOn', laxInputName || "{{::a.name}}");

            _.forEach(attrs.$attr, function(value, key) {
                var found = attrs[key].indexOf('a.readonly');
                if (found > -1) {
                    attrs.$set(key, attrs[key] + ' || ' + (attrs[key][found - 1] === '!' ? '!' : '') + 'isAttributeReadonly(a)');
                }
            });
            $compile(elem)(scope);
        }
    };
})
.directive('laxFocusOn', function($timeout, $window) {
    return function(scope, elem, attr) {
        scope.$on('focusOn', function(event, name) {
            if (name === attr.laxFocusOn) {
                $timeout(function() {

                    var focusable;
                    if (elem.is(':focusable')) {
                        focusable = elem;
                    } else {
                        // Find first focusable element, starting from parent of current element.
                        // This way, the directive does not necessarily be set on the exact element!
                        focusable = elem.parent().find(':focusable');
                    }

                    // Find first html element which is not a SPAN.
                    // Unfortunately SPANs are focusable, which leads to problems especially with 'ui-select' components
                    var htmlElement = _.find(focusable, function(htmlElement) {
                        return htmlElement.nodeName !== 'SPAN';
                    });

                    if (htmlElement) {

                        // Focusing element automatically scrolls it into view!
                        htmlElement.focus();

                        // Move cursor to start position, if possible
                        if (_.isFunction(htmlElement.setSelectionRange) && htmlElement.type !== 'radio' && htmlElement.type !== 'checkbox') {
                            htmlElement.setSelectionRange(0, 0);
                        }

                    }

                }, 300);
            }
        });
    };
})
.directive('laxNormalizeValue', function($parse, $rootScope) {
    return {
        require: 'ngModel',
        priority: 1,
        link: function(scope, element, attrs, ngModelCtrl) {

            var valueType = attrs.laxNormalizeValue || '';

            // Normalize value on creation
            var ngModelGet = $parse(attrs.ngModel);
            var value = ngModelGet(scope);
            value = $rootScope.parseValue(value, valueType);
            ngModelGet.assign(scope, value);

            // Add normalizing parser
            ngModelCtrl.$parsers.push(function(viewValue) {
                return $rootScope.parseValue(viewValue, valueType);
            });

            // Re-format value on blur
            function blur(evt) {
                var value = ngModelCtrl.$modelValue;
                for (var i = 0; i < ngModelCtrl.$formatters.length; i++) {
                    value = ngModelCtrl.$formatters[i](value);
                }
                ngModelCtrl.$viewValue = value;
                ngModelCtrl.$render();
            }
            element.bind('blur', blur);

        }
    };
})
.directive('laxDatepicker', function($compile, $parse, $rootScope, $timeout) {
    return {
        restrict: 'A',
        priority: 100,
        terminal: true,
        replace: false,
        link: function(scope, element, attrs) {

            // Remove directive's attribute
            element.removeAttr(attrs.$attr.laxDatepicker);

            // Add wrapped datepicker popup directive
            var datepickerFormat = attrs.laxDatepicker;
            attrs.$set('datepickerPopup', datepickerFormat || '');

            // Set translated button texts
            attrs.$set('currentText', $rootScope.translate('TODAY'));
            attrs.$set('clearText', $rootScope.translate('CLEAR'));
            attrs.$set('closeText', $rootScope.translate('CLOSE'));

            // Add lax normalize value directive
            attrs.$set('laxNormalizeValue', 'date');

            // ui-bootstrap datepicker needs an "is-open" attribute pointing to a state variable.
            // We put this state in a "global" object variable, so we can prevent having multiple
            // datepickers open at the same time!
            var uniqueId = 'datepicker-' + scope.$id + Math.floor(Math.random() * 10000);
            if (attrs.laxDatepickerGrid == undefined) {
                attrs.$set('isOpen', "datepickerOpened['" + uniqueId + "']");

                // Toggle datepicker on click
                attrs.$set('ngClick', "toggleDatepicker($event, '" + uniqueId + "')");

            } else {
                attrs.$set('isOpen', "grid.appScope.datepickerOpened['" + uniqueId + "']");

                // Toggle datepicker on click
                attrs.$set('ngClick', "grid.appScope.toggleDatepicker($event, '" + uniqueId + "')");

                // Add 'datepicker-append-to-body="true"' when in grid, otherwise the datepicker is rendered in the grid itself
                attrs.$set('datepickerAppendToBody', true);

                // Define an event to have the grid listen to, so it can end cell editing
                attrs.$set('laxGridInput', 'laxDatepickerClosed');

            }

            // Emit 'laxDatepickerClosed' when neither element is focused, nor datepicker is open
            var isFocused = true;
            function checkDatepickerClosed() {
                $timeout(function() {
                    var isOpen = (isFocused === true) || ($rootScope.isDatepickerOpen(uniqueId));
                    if (!isOpen) {
                        scope.$emit('laxDatepickerClosed');

                        if (attrs.laxDatepickerOnClose) {
                            scope.$eval(attrs.laxDatepickerOnClose);
                        }
                    }
                }, 1);
            }

            function focused() {
                isFocused = true;
                checkDatepickerClosed();
            }
            element.on('focus', focused);

            function blurred() {
                isFocused = false;
                checkDatepickerClosed();
            }
            element.on('blur', blurred);

            scope.$watch(function() {
                return $rootScope.datepickerOpened[uniqueId];
            }, function() {
                checkDatepickerClosed();
            });

            // Toggle datepicker on click on sibling button, if any
            var component = element.siblings('[data-toggle="datepicker"]');
            if (component.length === 0) {
                component = element.siblings().find('[data-toggle="datepicker"]');
            }
            if (component.length) {
                component.on('click', function (evt) {
                    if ($rootScope.isDatepickerOpen(uniqueId)) {
                        $rootScope.toggleDatepicker(evt, uniqueId);
                        $timeout(function() {
                            element.trigger('focus');
                        }, 1);
                    } else {
                        $timeout(function() {
                            element.trigger('click');
                        }, 1);
                    }
                });
                component.on('focus', focused);
                component.on('blur', blurred);
            }

            $compile(element)(scope);

        }
    };
})
.directive('laxDatetimePicker', function($compile, $parse, $rootScope, $timeout) {
    return {
        restrict: 'A',
        priority: 100,
        terminal: true,
        replace: false,
        link: function(scope, element, attrs) {

            // Remove directive's attribute
            element.removeAttr(attrs.$attr.laxDatetimePicker);

            // Add wrapped datepicker popup directive
            var datepickerFormat = attrs.laxDatetimePicker;
            attrs.$set('datetimePicker', datepickerFormat || '');

            // Set translated button texts
            attrs.$set('currentText', $rootScope.translate('TODAY'));
            attrs.$set('clearText', $rootScope.translate('CLEAR'));
            attrs.$set('closeText', $rootScope.translate('CLOSE'));

            // Add lax normalize value directive
            attrs.$set('laxNormalizeValue', 'dateTime');

            // ui-bootstrap datepicker needs an "is-open" attribute pointing to a state variable.
            // We put this state in a "global" object variable, so we can prevent having multiple
            // datepickers open at the same time!
            var uniqueId = 'datepicker-' + scope.$id + Math.floor(Math.random() * 10000);
            if (attrs.laxDatepickerGrid == undefined) {
                attrs.$set('isOpen', "datepickerOpened['" + uniqueId + "']");

                // Toggle datepicker on click
                attrs.$set('ngClick', "toggleDatepicker($event, '" + uniqueId + "')");

            } else {
                attrs.$set('isOpen', "grid.appScope.datepickerOpened['" + uniqueId + "']");

                // Toggle datepicker on click
                attrs.$set('ngClick', "grid.appScope.toggleDatepicker($event, '" + uniqueId + "')");

                // Add 'datepicker-append-to-body="true"' when in grid, otherwise the datepicker is rendered in the grid itself
                attrs.$set('datepickerAppendToBody', true);

                // Define an event to have the grid listen to, so it can end cell editing
                attrs.$set('laxGridInput', 'laxDatepickerClosed');

            }

            // Emit 'laxDatepickerClosed' when neither element is focused, nor datepicker is open
            var isFocused = true;
            function checkDatepickerClosed() {
                $timeout(function() {
                    var isOpen = (isFocused === true) || ($rootScope.isDatepickerOpen(uniqueId));
                    if (!isOpen) {
                        scope.$emit('laxDatepickerClosed');

                        if (attrs.laxDatepickerOnClose) {
                            scope.$eval(attrs.laxDatepickerOnClose);
                        }
                    }
                }, 1);
            }

            function focused() {
                if (scope.$$childTail) {
                    scope.$$childTail.showPicker = 'date';
                }
                isFocused = true;
                checkDatepickerClosed();
            }
            element.on('focus', focused);

            function blurred() {
                isFocused = false;
                checkDatepickerClosed();
            }
            element.on('blur', blurred);

            scope.$watch(function() {
                return $rootScope.datepickerOpened[uniqueId];
            }, function() {
                checkDatepickerClosed();
            });

            // Toggle datepicker on click on sibling button, if any
            var component = element.siblings('[data-toggle="datepicker"]');
            if (component.length === 0) {
                component = element.siblings().find('[data-toggle="datepicker"]');
            }
            if (component.length) {
                component.on('click', function (evt) {
                    if ($rootScope.isDatepickerOpen(uniqueId)) {
                        $rootScope.toggleDatepicker(evt, uniqueId);
                        $timeout(function() {
                            element.trigger('focus');
                        }, 1);
                    } else {
                        $timeout(function() {
                            element.trigger('click');
                        }, 1);
                    }
                });
                component.on('focus', focused);
                component.on('blur', blurred);
            }

            $compile(element)(scope);

        }
    };
})
.directive('onArrowKeys', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            function keyPressed($event) {
                if ($event.keyCode == 38 && attrs.arrowUp) {
                    $event.preventDefault();
                    scope.$eval(attrs.arrowUp);
                } else if ($event.keyCode == 39 && attrs.arrowRight) {
                    $event.preventDefault();
                    scope.$eval(attrs.arrowRight);
                } else if ($event.keyCode == 40 && attrs.arrowDown) {
                    $event.preventDefault();
                    scope.$eval(attrs.arrowDown);
                } else if ($event.keyCode == 41 && attrs.arrowLeft) {
                    $event.preventDefault();
                    scope.$eval(attrs.arrowLeft);
                }
            }
            element.on('keydown', keyPressed);
        }
    };
})
.directive('laxGridBoolInput', function(uiGridConstants, uiGridEditConstants) {
        return {
            scope: false,
            link: function(scope, element, attrs){

                function leaveField(evt) {
                    if (scope.inputForm && !scope.inputForm.$valid) {
                        evt.stopPropagation();
                        scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                    } else {
                        scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                    }
                }

                function emitCellEditEvent(eventName) {
                    scope.$emit(eventName, scope.row, scope.col);
                }

                element.on('mousedown', function(event) {
                    element.focus();
                    element.select();
                    emitCellEditEvent('laxGridStartCellEdit');

                });

                element.on('click', function(event) {
                    emitCellEditEvent('laxGridStopCellEdit');
                });

                function keydown(evt) {
                    switch (evt.keyCode) {
                        case uiGridConstants.keymap.LEFT:
                        case uiGridConstants.keymap.UP:
                        case uiGridConstants.keymap.RIGHT:
                        case uiGridConstants.keymap.DOWN:
                            evt.stopPropagation();
                            leaveField(evt);
                            break;
                        case uiGridConstants.keymap.ESC:
                            evt.stopPropagation();
                            scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                            break;
                        case uiGridConstants.keymap.ENTER:
                            leaveField(evt);
                            break;
                        case uiGridConstants.keymap.TAB:
                            evt.preventDefault();
                            leaveField(evt);
                            break;
                    }
                    return true;
                }

                element.on('keydown', keydown);
            }
        };
    })
.directive('laxGridUiSelect', function($window, $document, $timeout, uiGridEditConstants, uiGridConstants, uiGridEditService) {
    return {
        require: ['uiSelect', '?^uiGrid', '?^uiGridRenderContainer', 'ngModel'],
        link: function(scope, element, attrs, controllers) {

            if (controllers[0]) { selectCtrl = controllers[0]; }
            if (controllers[1]) { uiGridCtrl = controllers[1]; }
            if (controllers[2]) { renderContainerCtrl = controllers[2]; }
            if (controllers[3]) { ngModel = controllers[3]; }

            function leaveField(evt) {
                if (scope.inputForm && !scope.inputForm.$valid) {
                    scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                } else {
                    scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                }
            }

            function keydown(evt) {
                switch(evt.keyCode) {
                    case uiGridConstants.keymap.TAB:
                        // passes the tab and other events on to the grid
                        evt.uiGridTargetRenderContainerId = renderContainerCtrl.containerId;

                        if (uiGridCtrl.cellNav.handleKeyDown(evt) !== null) {
                            leaveField(evt);
                        }
                        $document.off('keydown', keydown);
                    break;

                    case uiGridConstants.keymap.ESC:
                        scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                        evt.preventDefault();
                        evt.stopPropagation();
                    break;
                }
            }

            selectCtrl.$element.on('keydown', keydown);

            function endCellEdit() {
                scope.$emit('laxGridStopCellEdit', scope.row, scope.col);
                $document.off('keydown', keydown);
            }

            // BEGIN_CELL_EDIT event is already called, when this directive is loaded. So immediately call the laxGridStartCellEdit event
            scope.$emit('laxGridStartCellEdit', scope.row, scope.col);

            scope.$on(uiGridEditConstants.events.CANCEL_CELL_EDIT, function(event) {
                endCellEdit();
            });
            scope.$on(uiGridEditConstants.events.END_CELL_EDIT, function(event) {
                endCellEdit();
            });

            // Function to end the edit mode when the user clicks somewhere else
            clickedInsideGrid = function(evt) {
                if($(evt.target).closest('.ui-select-container').size() === 0) {
                    scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                }
            };
            uiGridCtrl.grid.api.cellNav.on.navigate(scope, clickedInsideGrid);

            scope.$on('uis:close', function() {
                $timeout(function() {
                    scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                }, 0);
            });

            // Function for passing the first keypress to the Ui select and focussing the input field
            var key = "";
            if (uiGridCtrl && uiGridCtrl.grid.api.cellNav) {
                var viewPortKeyDownUnregister = uiGridCtrl.grid.api.cellNav.on.viewPortKeyPress(scope, function (evt, rowCol) {
                    if (uiGridEditService.isStartEditKey(evt)) {
                        var code = typeof evt.which === 'number' ? evt.which : evt.keyCode;
                        key = String.fromCharCode(code);
                    }
                    viewPortKeyDownUnregister();
                });
                // focus the inputfield and use the first key as input
                $timeout(function() {
                    selectCtrl.focusSearchInput(key);
                });
            }
        }
    };
})

.directive('laxGridInput', function($timeout, $document, $rootScope, uiGridEditService, uiGridConstants, uiGridEditConstants) {
        return {
            scope: false,
            require: ['?^uiGrid', '?^uiGridRenderContainer', 'ngModel'],
            link: function(scope, element, attrs, controllers) {

                var uiGridCtrl, renderContainerCtrl, ngModel;
                if (controllers[0]) { uiGridCtrl = controllers[0]; }
                if (controllers[1]) { renderContainerCtrl = controllers[1]; }
                if (controllers[2]) { ngModel = controllers[2]; }

                function leaveField(evt) {
                    if (scope.inputForm && !scope.inputForm.$valid) {
                        scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                    } else {
                        scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                    }
                }
                $timeout(function() {
                    element.focus();
                });

                function keydown(evt) {
                    switch (evt.keyCode) {
                        case uiGridConstants.keymap.LEFT:
                        case uiGridConstants.keymap.UP:
                        case uiGridConstants.keymap.RIGHT:
                        case uiGridConstants.keymap.DOWN:
                            evt.stopPropagation();
                        break;

                        case uiGridConstants.keymap.ESC:
                            evt.stopPropagation();
                            scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
                        break;

                        case uiGridConstants.keymap.ENTER:
                            leaveField(evt);
                        break;

                        case uiGridConstants.keymap.TAB:
                            if (uiGridCtrl && uiGridCtrl.grid.api.cellNav) {
                                evt.uiGridTargetRenderContainerId = renderContainerCtrl.containerId;
                                if (uiGridCtrl.cellNav.handleKeyDown(evt) !== null) {
                                    leaveField(evt);
                                }
                            }
                        break;
                    }
                    return true;
                }

                // Feature to call END_CELL_EDIT when the cell loses focus
                clickedOutsideOfCell = function(evt, stuff) {
                    scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                    $document.off('click', clickedOutsideOfCell);
                };
                $document.on('click', clickedOutsideOfCell);

                element.on('keydown', keydown);

                function click(evt) {
                    evt.stopPropagation();
                }
                element.on('click', click);

                function mousedown(evt) {
                    evt.stopPropagation();
                }
                element.on('mousedown', mousedown);

                function blur(evt) {
                    scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                }

                function emitCellEditEvent(eventName) {
                    scope.$emit(eventName, scope.row, scope.col);
                }

                scope.$on(uiGridEditConstants.events.BEGIN_CELL_EDIT, function(event) {
                    emitCellEditEvent('laxGridStartCellEdit');
                });

                scope.$on(uiGridEditConstants.events.END_CELL_EDIT, function(event) {
                    emitCellEditEvent('laxGridStopCellEdit');
                });

                scope.$on(uiGridEditConstants.events.CANCEL_CELL_EDIT, function(event) {
                    emitCellEditEvent('laxGridStopCellEdit');
                });

                var endCellEditEvent = attrs.laxGridInput;
                if (endCellEditEvent !== '') {
                    scope.$on(endCellEditEvent, function(evt) {
                        scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                    });
                } else {
                    element.on('blur', blur);
                }

                if (uiGridCtrl && uiGridCtrl.grid.api.cellNav) {
                    uiGridCtrl.grid.api.cellNav.on.navigate(scope, clickedOutsideOfCell);
                    var viewPortKeyDownUnregister = uiGridCtrl.grid.api.cellNav.on.viewPortKeyPress(scope, function (evt, rowCol) {
                        if (uiGridEditService.isStartEditKey(evt) && $rootScope.isEmpty(attrs.laxNormalizeValue)) {
                            var code = typeof evt.which === 'number' ? evt.which : evt.keyCode;

                            // Feature to use the first keypress as input
                            key = String.fromCharCode(code);
                            ngModel.$setViewValue(key, evt);
                            ngModel.$render();

                            // Fix for IE setting the cursor to position 0 after focussing an element
                            if (element.length !== 0) {
                                elem = element[0];
                                var caretPos = ngModel.$viewValue.length;
                                if (elem.createTextRange) {
                                    var range = elem.createTextRange();
                                    range.move('character', caretPos);
                                    range.select();
                                } else {
                                    if (elem.setSelectionRange) {
                                        elem.focus();
                                        elem.setSelectionRange(caretPos, caretPos);
                                    } else
                                        elem.focus();
                                }
                            }
                        }
                        viewPortKeyDownUnregister();
                    });
                }

                scope.$on('$destroy', function() {
                    element.off('keydown', keydown);
                    element.off('click', click);
                    element.off('mousedown', mousedown);
                    element.off('blur', blur);
                });
            }
        };
})
.directive('uiGridCell',
function () {
    return {
        priority: -100, // run after the actual uiGridCell directive and ui.grid.edit uiGridCell
        restrict: 'A',
        require: ['^uiGrid', '?^uiGridCellnav'],
        scope: false,
        link: function ($scope, $elm, $attrs, controllers) {
            var uiGridCtrl = controllers[0];

            $elm.off('mousedown');

            //turn on and off for edit events
            if (uiGridCtrl.grid.api.edit) {
                uiGridCtrl.grid.api.edit.on.beginCellEdit($scope, function () {
                    $elm.off('mousedown');
                });

                uiGridCtrl.grid.api.edit.on.afterCellEdit($scope, function () {
                    $elm.off('mousedown');
                });

                uiGridCtrl.grid.api.edit.on.cancelCellEdit($scope, function () {
                    $elm.off('mousedown');
                });
            }
        }
    };
})
.directive('uiGridCustomRowEdit',
function () {
    return {
        priority: -100, // run after the actual uiGridCell directive and ui.grid.edit uiGridCell
        restrict: 'A',
        require: ['^uiGrid', '?^uiGridCellnav'],
        scope: false,
        link: function ($scope, $elm, $attrs, controllers) {
            var uiGridCtrl = controllers[0];

            if (uiGridCtrl.grid.api.edit) {
                uiGridCtrl.grid.api.edit.on.beginCellEdit($scope, function (row) {
                    $scope.$parent.rowEdit = true;
                    $scope.$parent.editingRow = row;
                    row.editing = true;
                    if (!row.oldValue) {
                        row.oldValue = row.value;
                    }
                });
            }
        }
    };
})
.directive('ckEditor', function($timeout) {
    return {
        require: '?ngModel',
        link: function(scope, elm, attrs, ngModel) {
            if (elm[0].id.indexOf('{{') > -1) {
                elm[0].id = scope[elm[0].id.substring(2, elm[0].id.length-2)];
            } else if (attrs.id.indexOf('{{') > -1) {
                elem[0].id = scope[attrs.id.substring(2, attrs.id.length-2)];
            }
            var ck = {};
            var toolbarName = attrs.toolbar || 'basic';
            if (toolbarName == 'inline') {
                CKEDITOR.disableAutoInline = true;
                ck = CKEDITOR.inline(elm[0], scope[attrs.ckEditor][toolbarName]);
            } else {
                ck = CKEDITOR.replace(elm[0], scope[attrs.ckEditor][toolbarName]);
            }

            if (!ngModel) return;

            ck.on('instanceReady', function(event) {
                ck.setData(ngModel.$viewValue);
                toggleToolbar(event, 'hide');
            });

            function updateModel() {
                $timeout(function() {
                    ngModel.$setViewValue(ck.getData());
                }, 0);
            }

            function toggleToolbar(event, toggle) {
                if (toggle == 'show') {
                    angular.element('#cke_' + event.editor.name + ' .cke_top').show();
                    angular.element('#cke_' + event.editor.name + ' .cke_bottom').show();
                } else {
                    angular.element('#cke_' + event.editor.name + ' .cke_top').hide();
                    angular.element('#cke_' + event.editor.name + ' .cke_bottom').hide();
                }
            }

            ck.on('focus', function(event) {
                toggleToolbar(event, 'show');
            });
            ck.on('blur', function(event) {
                toggleToolbar(event, 'hide');
            });

            ck.on('change', updateModel);
            ck.on('key', updateModel);
            ck.on('dataReady', updateModel);

            ngModel.$render = function(value) {
                ck.setData(ngModel.$viewValue);
            };
        }
    };
})
.directive('focusOnLoad', function($timeout) {
    return {
        restrict: 'AC',
        link: function(scope, element) {
            $timeout(function(){
                element[0].focus();
            }, 0);
        }
    };
})
.directive('progressButton', function($translate, $timeout) {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
            value: '&',
            type: '@',
            inProgressText: '@inProgress',
            completionText: '@complete',
            defaultText:    '@default'
        },
        template: '<a class="progress-button"><span class="progress-button-text" data-translate>{{defaultText}}</span><span class="progress-button-bar progress-button-{{type}}"></span></a>',
        link: function(scope, element, attrs) {
            var bar = angular.element(element[0].querySelectorAll('.progress-button-bar'));
            var buttonText = angular.element(element[0].querySelectorAll('.progress-button-text'));

            attrs.$observe('type', function(value) { scope.type = value || 'horizontal'; });
            attrs.$observe('inProgress', function(value) { scope.inProgressText = $translate.instant(value) || $translate.instant('ITEM.IN_PROGRESS'); });
            attrs.$observe('default', function(value) { scope.defaultText = $translate.instant(value) || $translate.instant('SAVE'); });
            attrs.$observe('complete', function(value) { scope.completionText = $translate.instant(value) || $translate.instant('ITEM.FINISHED'); });

            scope.$watch('value()', function(value) {
                if(!value) value = 0;
                if(value > 1.0) value = 1.0;

                if(value === 0.0) {
                    buttonText.attr('data-progress-button-text', scope.defaultText);
                    buttonText.addClass('force-refresh').removeClass('force-refresh');
                    bar.css('display', 'none');
                } else if(value === 1.0) {
                    buttonText.attr('data-progress-button-text', scope.completionText);
                    buttonText.addClass('force-refresh').removeClass('force-refresh');
                    bar.css('display', 'block');

                    $timeout(function() {
                        fadeOut(bar);
                    }, 500);
                } else {
                    buttonText.attr('data-progress-button-text', scope.inProgressText);
                    buttonText.addClass('force-refresh').removeClass('force-refresh');
                    bar.css('display', 'block');
                }

                // Allow â€˜display: blockâ€™ above to be applied before setting the
                // barâ€™s width/height. This allows the CSS transition to happen if
                // the original value was zero.
                $timeout(function() {
                    if(scope.type === 'vertical') {
                        bar.css('height', (value * 100) + '%');
                    } else {
                        bar.css('width', (value * 100) + '%');
                    }
                });
            });

            var fadeOut = function(el, callback) {
                el.css('opacity', 1);

                var last = +new Date();

                var tick = function() {
                    el.css('opacity', +el.css('opacity') - (new Date() - last) / 400);
                    last = +new Date();

                    if (+el.css('opacity') > 0) {
                        if (!(window.requestAnimationFrame && requestAnimationFrame(tick))) {
                            setTimeout(tick, 16);
                        }
                    } else {
                        el.css('display', 'none');
                        el.css('opacity', 1);
                        if (typeof callback === 'function') {
                            callback();
                        }
                    }
                };

                tick();
            };
        }
    };
})
.directive("autofill", function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModel) {
            scope.$on("autofill:update", function() {
                ngModel.$setViewValue(element.val());
            });
        }
    };
})
.directive("clearableInput", function() {
    return {
        restrict: 'EA',
        transclude: true,
        template: '<div class="clearable-input" > ' +
            '<div class="input-container">' +
              '<div data-ng-transclude></div> ' +
            '</div>' +
            '<span data-ng-show="showClearBtn" id="{{::a.name}}_clear" class="clear-item-box clear-item-icon" data-ng-class="{\'close-btn-margin\': (a.isComplexType)}" ' +
                'data-ng-if="!isAttributeReadonly(a) && !isGroup(a)" ' +
                'data-ng-click="clearValue()"> ' +
                '<i class="glyphicon glyphicon-remove"></i> ' +
            '</span> ' +
        '</div>' +
        '<div class="clearfix"></div>',
        link: function(scope, element, attrs) {
            scope.showClearBtn = scope.a && !(scope.a.typeName == 'Collection' || scope.a.typeName == 'MultiDimensional' || scope.a.typeName == 'MultiReference');
            scope.clearValue = function() {
                var attributeName = scope.a.name;
                var item = scope.item;

                // Allowing clear-button to remove sub key
                if (!_.isEmpty(attrs.itemSubKey)) {
                    item = item[scope.a.name];
                    attributeName = attrs.itemSubKey;
                }

                // We cannot use 'delete' here, because then the ItemChangesQueues would not detect any changes!
                var previousValue = item[attributeName];
                if (_.isArray(previousValue)) {
                    item[attributeName] = [];
                    if (scope.gridOptionsMap[attributeName]) {
                        scope.gridOptionsMap[attributeName].minRowsToShow = 1;
                    }
                } else {
                    item[attributeName] = null;
                }
                if (scope.a.typeName === 'AdditionalCategory' && !_.isNil(previousValue)) {
                    scope.updateAdditionalCategoryAttributes(item, [attributeName]);
                }
                // Broadcast down the hierarchy
                scope.$broadcast('clearAttributeValue', attributeName);
                // Emit up the hierarchy
                scope.$emit('clearAttributeValue', attributeName);
            };
        }
    };
}).directive("clearableGroupInput", function() {
    return {
        restrict: 'EA',
        transclude: true,
        template: '<div class="clearable-input" > ' +
            '<div class="input-container">' +
              '<div data-ng-transclude></div> ' +
            '</div>' +
            '<span id="{{::a.name}}_clear" class="clear-item-box clear-item-icon" data-ng-class="{\'close-btn-margin\': (a.isComplexType)}" ' +
                'data-ng-if="!isAttributeReadonly(a) && !isGroup(a) && !a.isComplexType" ' +
                'data-ng-click="clearGroupValue()"> ' +
                '<i class="glyphicon glyphicon-remove"></i> ' +
            '</span> ' +
        '</div>' +
        '<div class="clearfix"></div>',
        link: function(scope, element, attrs) {
            scope.clearGroupValue = function() {
                var attributeName = scope.a.name;
                var reference = scope.$parent.entry;
                var attribute = scope.a;

                if (scope.isAttributeReadonly(attribute)) {
                    return;
                }
                // We cannot use 'delete' here, because then the ItemChangesQueues would not detect any changes!
                var previousValue = reference[attributeName];
                if (_.isArray(previousValue)) {
                        reference[attributeName] = [];
                    if (scope.gridOptionsMap[attributeName]) {
                        scope.gridOptionsMap[attributeName].minRowsToShow = 1;
                    }
                } else {
                    reference[attributeName] = null;
                }
                //to-do:check if this works with group attribute?
                if (attribute.typeName === 'AdditionalCategory' && !_.isNil(previousValue)) {
                    scope.updateAdditionalCategoryAttributes($scope.item, [attributeName]);
                }
                scope.$broadcast('clearAttributeValue', attributeName);
            };
        }
    };
})
.directive('ngCompiledInclude',
    function($compile, $log, $rootScope, $templateCache) {

        var TEMPLATE_ALTERNATIVES = {
            'OpenEnum': 'Enum',
            'OpenEnumSet': 'EnumSet',
            'Codelist' : 'Enum',
            'OpenCodelist': 'Enum',
            'CodelistSet': 'EnumSet',
            'OpenCodelistSet': 'EnumSet'
        };

        function loadTemplate(scope, element, attrs) {

            if (attrs.ngCompiledInclude === '' || attrs.ngCompiledInclude === 'Placeholder') {
                return;
            }
            $rootScope.compiledTemplates = $rootScope.compiledTemplates || {};

            var templateName = attrs.ngCompiledInclude;
            var template = $templateCache.get(templateName);
            if (_.isNil(template)) {
                var alternativeName = TEMPLATE_ALTERNATIVES[templateName];
                if (!_.isEmpty(alternativeName)) {
                    template = $templateCache.get(alternativeName);
                    if (_.isNil(template)) {
                        throw "Alternative template '" + alternativeName + "' for template '" + templateName + "' was not found";
                    }
                } else {
                    throw "Template '" + templateName + "' was not found";
                }
            }

            var customModel = attrs.customModel;
            var customChange = attrs.customChange;
            var customId = attrs.customId;

            if (customModel && _.includes(customModel, "item[a.name]")) {
                template = template.replace(/item\[a\.name\]/g, customModel);
            } else if (customModel) {
                customModelClean = angular.copy(customModel);
                customModelClean = customModelClean.replace(/\'/g,"");
                template = template.replace(/data\-model\=\"item\[a\.name\]\"/g, 'data-model=' + '"' + customModel + '"');
                template = template.replace(/data\-ng\-model\=\"item\[a\.name\]\"/g, 'data-ng-model=' + '"' + customModel + '"');
                template = template.replace(/data\-model\-string\=\'\"item\[a\.name\]\"\'/g, "data-model-string=" + "'" + '"' + customModelClean + '"' + "'");
                template = template.replace(/\"Document\"\, \"item\[a\.name\]\"/g, '"Document", ' + '"' + customModelClean + '"');
                template = template.replace(/\"Image\"\, \"item\[a\.name\]\"/g, '"Image", ' + '"' + customModelClean + '"');
                template = template.replace(/\"item\[a\.name\]\"/g, '"' + customModelClean + '"');
                template = template.replace(/item\[a\.name\]/g, customModel);
            }

            if (customId) {
                template = template.replace(/(id=")([a-z{}:.]+)"/i, '$1{{' + customId + '}}"');
            }

            // put a reference of the source in the template as comment
            template = '<!-- ngCompiledInclude: ' + templateName + ' -->' + template;

            var combinedTemplateName = (customModel ? customModel : 'item[a.name]') + '.' + templateName;

            var compiledTemplate = $rootScope.compiledTemplates[combinedTemplateName];
            if (!compiledTemplate) {
                $log.debug("Compiling template:", templateName);
                compiledTemplate = $compile(template);
                $rootScope.compiledTemplates[combinedTemplateName] = compiledTemplate;
            }

            compiledTemplate(scope, function(clonedElement, scope) {
                var templateElement = clonedElement;
                element.html(templateElement);
            });

        }

        return {
            restrict: 'A',
            priority: 400,
            link: function(scope, element, attrs) {
                if (!_.isUndefined(attrs.compileDynamic)) {
                    scope.$watch(attrs.ngModel, function(newVal) {
                        loadTemplate(scope, element, attrs);
                    });
                }
                loadTemplate(scope, element, attrs);
            }
        };
    }
)
.directive('feature', function(FeatureToggleService) {
    return {
        restrict: 'A',
        priority: 400,
        link: function(scope, element, attrs) {
            var feature = attrs.feature;

            var show = FeatureToggleService.checkFeatureEnabled(feature);

            if (!show) {
                element.addClass('hiddenByFeature');
                element.hide();
            } else {
                element.removeClass('hiddenByFeature');
                element.show();
            }
        }
    };
})
.directive('hideOnFeature', function(FeatureToggleService) {
    return {
        restrict: 'A',
        priority: 400,
        link: function(scope, element, attrs) {
            var feature = attrs.hideOnFeature;

            var show = !FeatureToggleService.checkFeatureEnabled(feature);

            if (!show) {
                element.addClass('hiddenByFeature');
                element.hide();
            } else {
                element.removeClass('hiddenByFeature');
                element.show();
            }
        }
    };
})
.directive('sessionIf', function(SessionDataService) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var key = attrs.sessionIf;

            scope.$watch(function() {
                return SessionDataService.get(key);
            }, function(value) {
                if (!value) {
                    element.addClass('hiddenByFeature');
                    element.hide();
                } else {
                    element.removeClass('hiddenByFeature');
                    element.show();
                }
            });
        }
    };
})
.directive('checkRightsForRoutes', function(Auth, Routes) {

    function hideOrShow(element) {

        var lis = element.find("li");
        _.forEach(lis, function(li_) {

            var li = $(li_);
            var link = angular.element(li).find("a")[0];
            if (link && link.pathname) {
                var url = link.pathname;

                var route = Routes.filter(function(route) {
                    var found = (route.url === url);
                    if (!found && route.urls) {
                        found = route.urls.contains(url);
                    }
                    return found;
                })[0];

                if (route && route.rights) {
                    var hasRights = Auth.hasRights(route.rights);

                    if (!hasRights || li.hasClass("hiddenByFeature")) {
                        li.addClass("hiddenByRoute");
                        li.hide();
                    } else {
                        li.removeClass("hiddenByRoute");
                        if (!li.hasClass("hiddenByHasRights") && !li.hasClass("hiddenByFeature")) {
                            li.show();
                        }
                    }
                }
            }

        });

        var topLevelLis = element.children();
        for (var i = 0; i < topLevelLis.length; i++) {
            var topLevelLi = topLevelLis[i];

            var childs = $(topLevelLi).children().find("li");

            if (childs.length !== 0) { // no childs -> no hiding of top level
                var show = false;
                for (var j = 0; j < childs.length; j++) {
                    var child = childs[j];
                    if (child.className.indexOf("hiddenByRoute") == -1 &&
                        child.className.indexOf("hiddenByHasRights") == -1 &&
                        child.className.indexOf("hiddenByFeature") == -1) {
                        // When there is at least one visible child also show parent
                        show = true;
                        break;
                    }
                }
                if (show) {
                    $(topLevelLi).show();
                } else {
                    $(topLevelLi).hide();
                }
            }
        }
    }

    return {
        restrict: 'A',
        priority: 400,
        link: function(scope, element, attrs){

            hideOrShow(element);
            scope.$on("userRolesLoaded", function() {
                hideOrShow(element);
            });
        }
    };
})
.directive('hasRights', function(Auth) {

    var rights = [];

    function hideOrShow(element) {
        var hasRights = Auth.hasRights(rights);

        if (!hasRights) {
            element.addClass("hiddenByHasRights");
            element.hide();
        } else if (!element.hasClass("hiddenByFeature")){
            element.removeClass("hiddenByHasRights");
            element.show();
        }
    }

    return {
        restrict: 'A',
        priority: 400,
        link: function(scope, element, attrs){

            var rightsString = attrs.hasRights;
            rights = rightsString.split(",");

            hideOrShow(element);
            scope.$on("userRolesLoaded", function() {
                hideOrShow(element);
            });
        }
    };
})
  .directive('dontHasRights', function(Auth) {

      var rights = [];

      function hideOrShow(element) {
          var hasRights = Auth.hasRights(rights);

          if (hasRights) {
              element.addClass("hiddenByHasRights");
              element.hide();
          } else if (!element.hasClass("hiddenByFeature")){
              element.removeClass("hiddenByHasRights");
              element.show();
          }
      }

      return {
          restrict: 'A',
          priority: 400,
          link: function(scope, element, attrs){

              var rightsString = attrs.dontHasRights;
              rights = rightsString.split(",");

              hideOrShow(element);
              scope.$on("userRolesLoaded", function() {
                  hideOrShow(element);
              });
          }
      };
  })
.directive('directCompare', function() {
    return {
        restrict: 'A',
        priority: 10,
        link: function(scope, elm, attrs) {
            var str1 = "";
            var str2 = "";

            function updateDiff() {
                if (str2 == '') {
                    elm.text('-');
                } else if ( (str1 && str2) && (str1 !== str2) && (str1 !== '' && str2 !== '') ) {
                    elm.html(diffString(str1, str2));
                } else {
                    elm.text(str2);
                }
            }

            scope.$watch(attrs.strFirst, function(value) {
                str1 = value;
                updateDiff();
            });

            scope.$watch(attrs.strSecond, function(value) {
                str2 = value;
                updateDiff();
            });
        }
    };
})
.directive('scrollToBottom', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, elm, attrs) {
            scope.$on('scrollTriggered', function() {
                $timeout(function() {
                    elm.scrollTop(elm[0].scrollHeight);
                }, 0);
            });
        }
    };
})
.directive('checkAttributes', function($timeout) {
    return {
        restrict: 'AE',
        link: function(scope, element, attrs) {
            $timeout(function () {
                var container = element.find('.panel-body-content').get(0);

                if (!_.isNil(container)) {
                    var attrCount = container.childElementCount;

                    if (attrCount < 1) {
                        element.addClass('hidden');
                    }
                }
            });
        }
    };
})
.directive('urlParam', function($location, $timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ctrl) {
            var timeout;
            var label = attrs.urlParam;
            var prefix = _.trim(scope.$eval(attrs.urlParamPrefix), '/');

            scope.$watch(attrs.urlParamValue, function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (!_.isUndefined(timeout)) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(function() {
                        changeUrlParam(label, newVal, prefix);
                    }, 1);
                }
            });

            function changeUrlParam(label, value, prefix) {
                var hash = prefix.split('#');

                if (!_.isEmpty(value)) {
                    value = value.split(" ").join("+");

                    // TODO: DIRTY HACK to angularjs converting '+' to '%2B' in url parameters
                    // please see https://github.com/angular/angular.js/issues/3042 for more info
                    var realEncodeURIComponent = window.encodeURIComponent;
                    window.encodeURIComponent = function(input) {
                        return realEncodeURIComponent(input).split("%2B").join("+");
                    };

                    if (hash.length > 1) {
                        $location.displayUrl(hash[0]).search(label, value).hash(hash[1]);
                    } else {
                        $location.displayUrl(prefix).search(label, value);
                    }

                    // return encodeURIComponent to what it was before the hack.
                    window.encodeURIComponent = realEncodeURIComponent;
                } else {
                    $location.search(label, null);
                }
            }
        }
    };
})
.directive('validateEmail', function() {
    var EMAIL_REGEXP = /^([\w-]+(?:\.[\w-]+)*)\+?[\w-]+@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function(viewValue) {
                if (EMAIL_REGEXP.test(viewValue)) {
                    ctrl.$setValidity('validateEmail', true);
                    return viewValue;
                } else {
                    ctrl.$setValidity('validateEmail', false);
                    return undefined;
                }
            });
        }
    };
})
.directive('validateHostname', function($rootScope) {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.validateHostname = function(modelValue, viewValue) {
                if (_.isEmpty(viewValue)) {
                    return true; // Not checking empty hostname
                }
                return $rootScope.isValidHostname(viewValue);
            };
        }
    };
})
.directive('uiGridResizeHeight', function (gridUtil, uiGridConstants) {
    return {
        restrict: 'A',
        require: 'uiGrid',
        link: function($scope, $elm, $attrs, uiGridCtrl) {

            $scope.$watchCollection(uiGridCtrl.grid.options.data, function(newData) {

                var options = uiGridCtrl.grid.options;
                var grid = uiGridCtrl.grid;

                // Initialize scrollbars (TODO: move to controller??)
                uiGridCtrl.scrollbars = [];
                if (!_.isEmpty(newData)) {
                    if (newData.length >= 10) {
                        options.minRowsToShow = 10;
                    } else {
                        options.minRowsToShow = newData.length;
                    }
                } else {
                    options.minRowsToShow = 0;
                }

                // Figure out the new height
                var contentHeight = options.minRowsToShow * options.rowHeight + (options.rowHeight * 0.5);
                var headerHeight = grid.options.hideHeader ? 0 : options.headerRowHeight;
                var footerHeight = grid.options.showFooter ? options.footerRowHeight : 0;
                var scrollbarHeight = grid.options.enableScrollbars ? gridUtil.getScrollbarWidth() : 0;

                // Calculates the maximum number of filters in the columns
                var maxNumberOfFilters = 0;
                angular.forEach(grid.options.columnDefs, function(col) {
                    if (col.hasOwnProperty('filter')) {
                        if (maxNumberOfFilters < 1) {
                                maxNumberOfFilters = 1;
                        }
                    } else if (col.hasOwnProperty('filters')) {
                        if (maxNumberOfFilters < col.filters.length) {
                                maxNumberOfFilters = col.filters.length;
                        }
                    }
                });

                var filterHeight = maxNumberOfFilters * headerHeight;
                var newHeight = headerHeight + contentHeight + footerHeight + scrollbarHeight + filterHeight;

                $elm.css('height', newHeight + 'px');

                grid.gridHeight = $scope.gridHeight = gridUtil.elementHeight($elm);

                // Run initial canvas refresh
                grid.refreshCanvas();

            });
        }
    };
})
.directive('datamodeltemplate', function(ReactBridge) {
    return {
        restrict: 'EA',
        scope: {
            model: '='
        },
        templateUrl: 'tpl/datamodeltemplate.tpl.html',
        replace: true,
        controller: function($modal, $rootScope, $scope, growl, Auth, OrganizationService, UseDatamodelResource) {

            $scope.hasActivatePermisson = Auth.hasAnyPermission(Auth.OBJECT_TYPE_DATAMODEL, 'activate');
            $scope.useDataModel = function(dataModel) {
                UseDatamodelResource.save({
                    modelId: dataModel.id,
                    isSystemDataModel: dataModel.isSystemDataModel
                }, function() {
                    growl.success('MODELEXCHANGE.USE_MODEL_SUCCESSFUL');
                    $scope.$broadcast("closeInfoModel");
                    OrganizationService.update({ isDataModelDeploying: true });
                }, function() {
                    growl.error("MODELEXCHANGE.USE_MODEL_ERROR");
                    OrganizationService.update({ isDataModelDeploying: false });
                });
            };

            var dmInfo = function($scope) {
                var langKey = $rootScope.language;
                var ds = $scope.model.descriptions;
                if (!_.isEmpty(ds)) {
                    $scope.model.translatedDescription = ds[langKey] || ds[''] || "";
                }
                var rn = $scope.model.releaseNotes;
                if (!_.isEmpty(rn)) {
                    $scope.model.translatedReleaseNotes = rn[langKey] || rn[''] || "";
                }
            };

            $scope.showInfo = function() {
                dmInfo($scope);
                var dialog = ReactBridge.mountDialog("DataModelExchangeDialog", "#react-data-model-exchange",
                {
                    dataModelInfo: $scope.model,
                    hasActivatePermisson: $scope.hasActivatePermisson,
                    onClose: function(toWait) {
                       return toWait? ReactBridge.unmountWhenReady(dialog.unmount): dialog.unmount();
                    }
                });
            };

        }
    };
})
.directive('gridLimitContent', function($sce, $rootScope) {
    return {
        restrict: 'A',
        template: '<div class="ui-grid-cell-contents"> ' +
                    '<span data-ng-bind-html="row.entity.summary"></span> ' +
                    '<a data-ng-if="row.entity.extra" ' +
                        'class="pointer mh" ' +
                        'popover-title="{{row.entity.summary}}" ' +
                        'popover="{{row.entity.extra}}" ' +
                        'popover-append-to-body="true" ' +
                        'popover-placement="bottom"> ' +
                        '<i class="syncons syncons-item-detail"></i> ' +
                    '</a> ' +
                '</div>',
        replace: true,
        link: function(scope, element, attrs) {
            scope.$watch('row.entity', function(val) {
                var index = attrs.gridContent.indexOf('\n');
                if (index > 0) {
                    scope.row.entity.summary = attrs.gridContent.substr(0, index);
                    scope.row.entity.extra = attrs.gridContent.substring(index);
                } else {
                    var htmlString = "<span>" + attrs.gridContent + "</span>";
                    scope.row.entity.summary = $sce.trustAsHtml(htmlString);
                }
            });
        }
    };
})
.directive('categoriesTypeahead', function($filter, $translate, DataModelRetrievalResource) {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: '=',
        link: function(scope, elem, attrs, ngModelCtrl) {
            var initValue = scope.item[scope.a.name];

            function initAdditionalCategory(attribute, modelValue) {
                return DataModelRetrievalResource.query({
                        method: 'categories',
                        code: modelValue,
                        dataModel: _.get(attribute.params, 'additionalModule'),
                        extension: _.get(attribute.params, 'extension'),
                        limit: 1
                    }
                ).$promise;
            }

            function formatCategory(modelValue, selectedAdditionalCategory) {
                if (!modelValue) {
                    return;
                }
                var result = modelValue;
                var category = selectedAdditionalCategory || scope.selectedAdditionalCategory;
                if (category) {
                    result = $filter('additionalCategoryFormatter')(category, false);
                }
                return result;
            }

            if (initValue) {
                initAdditionalCategory(scope.a, initValue).then(function(response) {
                    scope.selectedAdditionalCategory = response[0];
                    if (response[0]) {
                        ngModelCtrl.$setViewValue(formatCategory(scope.selectedAdditionalCategory));
                        ngModelCtrl.$render();
                    }
                });
            }

            ngModelCtrl.$formatters.push(function(modelValue) {
                return formatCategory(modelValue);
            });

            ngModelCtrl.$parsers.push(function(modelValue) {
                var found = /\[(\w+)\]/i.exec(modelValue);
                if (found) {
                    return found[found.length - 1];
                }
                else {
                    return modelValue;
                }
            });
        },
        controller: function($attrs, $log, $modal, $rootScope, $scope, DataModelResource, DataModelRetrievalResource) {

            $scope.nodes = [];
            $scope.nodesMap = [];
            $scope.nodeCollapseMap = {};

            var alreadyInitialized = null;

            $scope.getTopLevelCategories = function() {

                $scope.nodeCollapseMap = {};

                var attribute = $scope.$eval($attrs.attribute);
                if (alreadyInitialized == attribute.name) {
                    openModal(attribute);
                    return;
                }

                var entries = [];
                DataModelRetrievalResource.query({
                    method: 'categories',
                    dataModel: attribute.params.additionalModule,
                    extension: attribute.params.extension,
                    parent: attribute.params.additionalCategory,
                    limit: 50
                },
                function(response) {
                    entries = _.map(response, function(item) {
                        return {
                            code: item.code,
                            id: item.id,
                            leaf: item.leaf,
                            level: 0,
                            parent: item.parent,
                            title: item.title
                        };
                    });
                    $scope.nodes = entries;
                    $scope.nodesMap[0] = $scope.nodes[0];
                    alreadyInitialized = attribute.name;
                    openModal(attribute);
                },
                function(response) {
                    $log.error(response);
                });

            };

            function openModal(attribute) {

                var selectedValue = $scope.item[attribute.name];

                if (!_.isNil(selectedValue)) {

                    $scope.displayLoader = true;
                    DataModelResource({}).getCategoryPath({
                        code : selectedValue,
                        dataModel: attribute.params.additionalModule,
                        extension: attribute.params.extension
                    },
                    function(pathNodes) {
                        pathNodes = pathNodes.reverse();
                        var parentNodes = [];
                        _.forEach(pathNodes, function(n) {
                            if (n && n.leaf == 'false') {
                                parentNodes.push(n.id);
                            }
                        });
                        DataModelRetrievalResource.query({
                            method: 'categories',
                            dataModel: attribute.params.additionalModule,
                            extension: attribute.params.extension,
                            parent: parentNodes,
                            limit: 500
                        },
                        function(allLeaves) {
                            var nodes = $scope.nodes;
                            _.forEach(pathNodes, function(n, i) {
                                var leaves;
                                sel_root = _.find(nodes, { code: n.code});
                                if (sel_root) {
                                    $scope.nodesMap[i] = sel_root;
                                    $scope.nodeCollapseMap[sel_root.code] = true;
                                    leaves = _.filter(allLeaves, {parent:n.id});
                                    if (!_.isEmpty(leaves)) {
                                        sel_root.leaves = _.map(leaves, function(sn) {
                                            sn.level = sel_root.level + 1;
                                            return sn;
                                        });
                                    }
                                }
                                nodes = leaves;
                            });
                            $scope.selectedNode = $scope.nodesMap[$scope.nodesMap.length-1];
                            $scope.displayLoader = false;
                        },
                        function(response) {
                            $log.info(response);
                        });
                    },
                    function(response) {
                        $scope.displayLoader = false;
                        $log.info(response);
                    });
                }

                $rootScope.additionalModalOpen = true;
                $modal.open({
                    templateUrl: 'tpl/additionalCategoryNodesRenderer.tpl.html',
                    controller: nodesPopupController,
                    backdrop: 'static',
                    keyboard: true,
                    scope: $scope,
                    windowClass: 'ac-editor'
                });

            }

            function nodesPopupController($scope, $modalInstance, $document) {
                var escapePressed = function (evt) {
                    if (evt.keyCode == 27) {
                        evt.preventDefault();
                        evt.stopPropagation();
                        $scope.cancel();
                    }
                };
                $document.on('keydown keypress', escapePressed);

                $scope.cancel = function() {
                    $document.off('keydown keypress', escapePressed);
                    $rootScope.additionalModalOpen = false;
                    $modalInstance.close();
                };
                $scope.nodeSelected = function(attribute) {
                    updateCategory($scope.selectedNode, attribute);
                    $rootScope.additionalModalOpen = false;
                    $modalInstance.close();
                };
            }

            $scope.updateNodes = function(node, attribute) {
                $scope.nodesMap[node.level] = node;
                $scope.selectedNode = node;
                $scope.resetToCategory(node);
                if (node.leaf == 'false' && _.isEmpty(node.leaves)) {
                    updateSubCategories(node, attribute);
                }
            };

            function updateCategory(node, attribute) {
                if (!node || node.leaf == "false") {
                    return;
                }
                $scope.selectedAdditionalCategory = node;
                $scope.item[attribute.name] = node.code;
                $scope.updateAdditionalCategoryAttributes($scope.item, $scope.a.name);
            }

            function updateSubCategories(node, attribute) {
                if (!node || node.emptyDefault) {
                    $scope.resetToCategory(node);
                } else if (node.leaf == "false") {
                    DataModelRetrievalResource.query({
                        method: 'categories',
                        dataModel: attribute.params.additionalModule,
                        extension: attribute.params.extension,
                        parent: node.id,
                        limit: 500
                    },
                    function(response) {
                        if (response.length > 0) {
                            node.leaves = _.map(response, function(sn) {
                                sn.level = node.level + 1;
                                return sn;
                            });
                            _.forEach($scope.nodesMap, function(item, ind) {
                                if (item.id === node.id) {
                                    $scope.nodesMap[ind + 1] = node.leaves[0];
                                }
                            });
                        } else if ($scope.nodesMap.length > 1) {
                            $scope.resetToCategory(node);
                        }
                        updateCategory(node, attribute);
                    },
                    function(response) {
                        console.log(response);
                    });
                } else {
                    updateCategory(node, attribute);
                }
            }

            $scope.resetToCategory = function(node) {
                if (!node) return;
                $scope.nodesMap = $scope.nodesMap.slice(0, node.level + 1);
            };

        }
    };
})
/*
* http://stackoverflow.com/questions/14968690/sending-event-when-angular-js-finished-loading
*
* Really nice directive to watch on expression on a given element
* used on the item editor to check, whether the item (object) and the editor (DOM Element) have finished loading
*
* when-ready:               execute when element is fully transcluded
* ready-check:              assert expression repeatedly and call whenReady on truthy
* wait-for-interpolation:   fires when all text nodes have been evaluated
*
* With 'requestAnimationFrame' polyfill for phantomjs from https://gist.github.com/paulirish/1579671
*/
.directive('whenReady', ['$interpolate', function($interpolate) {
    return {
        restrict: 'A',
        priority: Number.MIN_SAFE_INTEGER, // execute last, after all other directives if any.
        link: function($scope, $element, $attributes) {

            // BEGIN polyfill
            var lastTime = 0,
                vendors = ['ms', 'moz', 'webkit', 'o'],
                x;

            for (x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
                window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
                    window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] +
                        'CancelRequestAnimationFrame'];
                }

            if (!window.requestAnimationFrame) {
                window.requestAnimationFrame = function (callback, element) {
                    var currTime = new Date().getTime(),
                        timeToCall = Math.max(0, 16 - (currTime - lastTime)),
                        id = window.setTimeout(function () {
                            callback(currTime + timeToCall);
                        }, timeToCall);
                    lastTime = currTime + timeToCall;
                    return id;
                };
            }

            if (!window.cancelAnimationFrame) {
                window.cancelAnimationFrame = function (id) {
                    window.clearTimeout(id);
                };
            }
            // END polyfill

            var expressions = $attributes.whenReady.split(';');
            var waitForInterpolation = false;
            var hasReadyCheckExpression = false;

            function evalExpressions(expressions) {
                expressions.forEach(function(expression) {
                    $scope.$eval(expression);
                });
            }

            if ($attributes.whenReady.trim().length === 0) {
                return;
            }

            if ($attributes.waitForInterpolation && $scope.$eval($attributes.waitForInterpolation)) {
                waitForInterpolation = true;
            }

            if ($attributes.readyCheck) {
                hasReadyCheckExpression = true;
            }

            if (waitForInterpolation || hasReadyCheckExpression) {
                requestAnimationFrame(function checkIfReady() {
                    var isInterpolated = false;
                    var isReadyCheckTrue = false;

                    if (waitForInterpolation && $element.text().indexOf($interpolate.startSymbol()) >= 0) { // if the text still has {{placeholders}}
                        isInterpolated = false;
                    } else {
                        isInterpolated = true;
                    }

                    if (hasReadyCheckExpression && !$scope.$eval($attributes.readyCheck)) { // if the ready check expression returns false
                        isReadyCheckTrue = false;
                    } else {
                        isReadyCheckTrue = true;
                    }

                    if (isInterpolated && isReadyCheckTrue) {
                        evalExpressions(expressions);
                    } else {
                        requestAnimationFrame(checkIfReady);
                    }

                });
            } else {
                evalExpressions(expressions);
            }
        }
    };
}])
.directive('schedulePlan', function($locale, $translate) {
    return {
        restrict: 'AE',
        templateUrl: 'tpl/inputs/SchedulePlanLayout.tpl.html',
        link: function(scope, elem, attrs) {
            scope.labels = scope.$eval(attrs.labels);

            function getDaysOfWeek() {
                return _.chain($locale.DATETIME_FORMATS.SHORTDAY)
                    .map(function(value, index) {
                        return {
                            key: (index === 0) ? 7 : index,
                            value: value
                        };
                    })
                    .sortBy('key')
                    .value();
            }

            var sections = [{
                name: 'IMMEDIATELY',
                label: scope.labels.IMMEDIATELY
            }, {
                name: 'SCHEDULE_PLAN',
                label: scope.labels.SCHEDULE_PLAN,
                attributes: [
                    [{
                        name: 'name',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.name'),
                        typeName: 'String',
                        template: 'String',
                        model: 'planOptions.job[a.name]'
                    }],
                    [{
                        name: 'startDateTime',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.startDateTime'),
                        typeName: 'DateTime',
                        template: 'DateTime',
                        model: 'planOptions.job.schedule[a.name]'
                    }]
                ]
            }, {
                name: 'SCHEDULE_REPEAT',
                label: scope.labels.SCHEDULE_REPEAT,
                attributes: [
                    [{
                        name: 'repeatEvery',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.repeatEvery'),
                        typeName: 'Number',
                        template: 'NumberRangeSelect',
                        model: 'planOptions.job.schedule[a.name]',
                        inputWidth: '4',
                        params: {
                            min: 1,
                            max: 31
                        }
                    }, {
                        name: 'repeatType',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.repeatType'),
                        typeName: 'String',
                        template: 'Enum',
                        model: 'planOptions.job.schedule[a.name]',
                        labelWidth: '0',
                        inputWidth: '4',
                        valuesFormat: ['label'],
                        params: {
                            values: [{
                                key: 'HOUR',
                                value: $translate.instant('DATE.HOUR')
                            },
                            {
                                key: 'DAY',
                                value: $translate.instant('DATE.DAY')
                            }, {
                                key: 'WEEK',
                                value: $translate.instant('DATE.WEEK')
                            }, {
                                key: 'MONTH',
                                value: $translate.instant('DATE.MONTH')
                            }]
                        }
                    }],
                    [{
                        name: 'repeatDaysOfWeek',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.repeatDaysOfWeek'),
                        typeName: 'Number',
                        template: 'CheckboxList',
                        model: 'planOptions.job.schedule[a.name]',
                        hideOn: 'planOptions.job.schedule.repeatType != "WEEK"',
                        params: {
                            values: getDaysOfWeek()
                        }
                    }],
                    [{
                        name: 'repeatDaysOfMonth',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.repeatDaysOfMonth'),
                        typeName: 'Number',
                        template: 'DaysOfMonth',
                        model: 'planOptions.job.schedule[a.name]',
                        hideOn: "planOptions.job.schedule.repeatType != 'MONTH'",
                        params: {
                            min: 1,
                            max: 31
                        }
                    }],
                    [{
                        name: 'endDateTime',
                        label: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.endDateTime'),
                        typeName: 'DateTime',
                        template: 'DateTime',
                        model: 'planOptions.job.schedule[a.name]'
                    }]
                ]
            }];
            scope.layout = {
                sections: _.filter(sections, function(entry) {
                    return !_.isNil(scope.labels[entry.name]);
                })
            };

            scope.toggleDisabled = function(section, options) {
                switch (section) {
                    case 'SCHEDULE_PLAN':
                        return options.action !== section;
                    case 'SCHEDULE_REPEAT':
                        return !(options.action === 'SCHEDULE_PLAN' && options.actionRepeat);
                    default:
                }
            };

            scope.toggleSelection = function(attributeName, option) {
                scope.planOptions.job.schedule[attributeName] = scope.planOptions.job.schedule[attributeName] || [];
                var idx = scope.planOptions.job.schedule[attributeName].indexOf(option);
                if (idx > -1) {
                    scope.planOptions.job.schedule[attributeName].splice(idx, 1);
                } else {
                    scope.planOptions.job.schedule[attributeName].push(option);
                }
            };

            scope.updateStartDateTime = function() {
                scope.planOptions.job.schedule.startDateTime = new Date();
            };

            scope.tagTransform = function(newTag) {
                // FIXME: Needed, because 'repeatType' is based on a normal Enum, which now has 'tagging' enabled.
                // We still have to ensure, that this method is only called for 'repeatType'!
                return { key: newTag, value: newTag };
            };

            (function init() {
                scope.updateStartDateTime();
            })();
        }
    };
})
.directive("validateNewPassword", function() {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, element, attrs, ctrl) {
            ctrl.$parsers.unshift(function(pw) {
                var lower = 0, upper = 0, digit = 0, symbol = 0;
                for (var p = 0; p < pw.length; p++) {
                    var ch = pw[p];
                    if (ch >= 'a' && ch <= 'z') {
                        lower = 1;  // at least one lower case
                    } else if (ch >= 'A' && ch <= 'Z') {
                        upper = 1;  // at least one upper case
                    } else if (ch >= '0' && ch <= '9') {
                        digit = 1;  // at least one digit
                    } else if ("!#$%&'()*+,-./:;<=>?@[]_`{|}´°\"\\".indexOf(ch) > -1) {
                        symbol = 1;  // at least one special character
                    }
                }
                var pattern_okay = (lower+upper+digit+symbol) >= 3;  // three of these criteria is good enough
                var length_okay = (pw.length >= 12);
                ctrl.$setValidity("pattern", pattern_okay);
                ctrl.$setValidity("length", length_okay || !pattern_okay);
                return (length_okay && pattern_okay) ? pw : undefined;
            });
        }
    };
})
.directive('floatFormatter', function($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$render = function() {
                element.val(formatFloatValue(ngModelCtrl.$viewValue));
            };

            function parseFloatValue(viewValue) {
                if (_.isUndefined(viewValue)) return '';

                var cursorPosition = element[0].selectionStart;
                var numberString = viewValue.split(/\s/);
                var transformedInput = numberString[0].replace(/,/, '.');

                if (!attrs.physicalAttribute) {
                    transformedInput = transformedInput.replace(/[^-0-9.,]/g, '');
                }

                ngModelCtrl.$setViewValue(transformedInput);
                ngModelCtrl.$render();

                // Restoring cursor position due to $render() losing it
                element[0].selectionStart = cursorPosition;
                element[0].selectionEnd = cursorPosition;

                return transformedInput;
            }

            function formatFloatValue(modelValue) {
                return $filter('formatFloatValue')(modelValue);
            }

            ngModelCtrl.$parsers.push(parseFloatValue);
            ngModelCtrl.$formatters.push(formatFloatValue);
        }
    };
})
.directive('onScroll', function() {
    return {
        scope: {
            onScroll: '&',
        },
        link: function(scope, element, attrs) {
            element.bind("wheel", function() {
                scope.onScroll();
            });
        }
    };
})
.directive('sectionRepeat', function($rootScope, $timeout) {

    var MAX_ROWS = 10;

    function loadNextRow(scope, count, sectionLoadedFn) {
        $timeout(function() {
            if (scope.sectionRepeat.currentRows < count) {
                scope.sectionRepeat.currentRows += 2;
                loadNextRow(scope, count, sectionLoadedFn);
            } else if (!_.isEmpty(sectionLoadedFn)) {
                if ($rootScope.$$phase) {
                    scope.$eval(sectionLoadedFn);
                } else {
                    scope.$apply(sectionLoadedFn);
                }
            }
        }, 100);
    }

    return {
        restrict: 'A',
        link: function(scope, element, attrs) {

            scope.sectionRepeat = {};
            scope.sectionRepeat.currentRows = 0;

            scope.currentSectionRepeatRows= function() {
                return scope.sectionRepeat.currentRows;
            };

            scope.section.rows.some(function(row) {
                var endLoop = row.some(function(attribute) {
                    if (attribute.typeName === 'Collection' ||
                            attribute.typeName === 'MultiDimensional' ||
                            attribute.typeName === 'MultiReference' ||
                            scope.sectionRepeat.currentRows >= MAX_ROWS) {
                        return true;
                    }
                });
                if (endLoop) {
                    return true;
                } else {
                    scope.sectionRepeat.currentRows++;
                }
            });

            var sectionLoadedFn = attrs.sectionRepeat;

            var count = scope.section.rows.length;
            loadNextRow(scope, count, sectionLoadedFn);

        }
    };
})
.directive('selectInfiniteScroll', function($rootScope) {

    var DEFAULT_INIT_ITEMS = 20;
    var DEFAULT_ADD_ITEMS = 10;
    var DEFAULT_SCROLL_DISTANCE = 2;

    return {
        link: function(scope, elem, attrs) {

            function parseInt(value, defaultValue) {
                var result;
                if (!_.isEmpty(value)) {
                    result = _.parseInt(value);
                }
                if (!_.isInteger(result)) {
                    result = defaultValue;
                }
                return result;
            }

            scope.infiniteScroll = {};
            scope.infiniteScroll.currentItems = parseInt(attrs.selectInfiniteScrollInitItems, DEFAULT_INIT_ITEMS);
            scope.infiniteScroll.addItems = parseInt(attrs.selectInfiniteScrollAddItems, DEFAULT_ADD_ITEMS);

            scope.currentInfiniteScrollItems = function() {
                var selectedOptionsLength = parseInt(attrs.selectedOptionsLength);
                selectedOptionsLength = !selectedOptionsLength ? 0 : selectedOptionsLength;
                return scope.infiniteScroll.currentItems + selectedOptionsLength;
            };
            scope.addInfiniteScrollItems = function() {
                scope.infiniteScroll.currentItems += scope.infiniteScroll.addItems;
            };

            var scrollDistance = parseInt(attrs.selectInfiniteScrollDistance, DEFAULT_SCROLL_DISTANCE);
            var scrollEnabled = attrs.selectInfiniteScrollDisabled !== 'true';
            var scrollFn = attrs.selectInfiniteScroll || 'addInfiniteScrollItems()';

            var handler = function() {
                var container = $(elem.children()[0]);
                var elementBottom = elem.offset().top + elem.height();
                var containerBottom = container.offset().top + container.height();
                var remaining = containerBottom - elementBottom ;
                var shouldScroll = remaining <= elem.height() * scrollDistance;
                if (shouldScroll && scrollEnabled) {
                    if ($rootScope.$$phase) {
                        scope.$eval(scrollFn);
                    } else {
                        scope.$apply(scrollFn);
                    }
                }
            };

            elem.css('overflow-y', 'auto');
            elem.css('overflow-x', 'hidden');
            elem.css('height', 'inherit');
            elem.on('scroll', handler);
            scope.$on('$destroy', function() {
                elem.off('scroll', handler);
            });

        }
    };
})
.directive('objectReloadable', function() {
    var link = function(scope, element, attrs) {
        var currentElement = element;
        var html = '<object type="'+ scope.filePreviewData.contentType +'" data="' + scope.filePreviewData.url + '"></object>';
        var replacementElement = angular.element(html);
        currentElement.replaceWith(replacementElement);
        currentElement = replacementElement;
        scope.$watch('filePreviewData.url', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                var html = '<object type="'+ scope.filePreviewData.contentType +'" data="' + newValue + '"></object>';
                var replacementElement = angular.element(html);
                currentElement.replaceWith(replacementElement);
                currentElement = replacementElement;
            }
        });
    };
    return {
        restrict: 'E',
        scope: false,
        link: link
    };
})
.directive('regexValidator', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('keypress',function(e) {
                var inputpattren = attrs.regexValidator;
                var selectionStart = element.prop("selectionStart");
                var selectionEnd = element.prop("selectionEnd");
                if (inputpattren && inputpattren != '') {
                    var isValid = true;
                    try {
                        inputpattren = new RegExp(inputpattren);
                    } catch(err) {
                        isValid = false;
                    }
                    if (isValid) {
                        var testVal = element.val();
                        var len = testVal.length;
                        if (selectionStart != selectionEnd) {
                            testVal = testVal.substr(0, selectionStart) + testVal.substr(selectionEnd);
                        }
                        testVal = len == selectionEnd ? testVal + e.key : e.key + testVal;
                        if (testVal && testVal.search(inputpattren) === -1) {
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    };
})
.directive('minimizeMultilineContent', function($timeout,$compile) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var max = 300;
            if (attrs.minimizeMultilineContent != '' && !isNaN(attrs.minimizeMultilineContent)) {
                max = parseInt(attrs.minimizeMultilineContent);
            }
            var moreHtml = angular.element('<span></span>');
            var lessHtml = angular.element('<span></span>');
            $timeout(function(){
                var content = element.text();
                if (content.length > max) {
                    element.val(content);
                    element.text('');
                    moreHtml.text(content.substring(0,max) + ' ');
                    moreHtml.append('<span class="see-more">See more..</span>');
                    lessHtml.text(content);
                    lessHtml.append('<span class="see-more">See less</span>');
                    lessHtml.hide();
                    element.append(moreHtml);
                    element.append(lessHtml);
                    moreHtml.on("click", function(){
                        moreHtml.hide();
                        lessHtml.show();
                    });
                    lessHtml.on("click", function(){
                        moreHtml.show();
                        lessHtml.hide();
                    });
                }
            });
        }
    };
})
.directive('limitPrecisionScale', function(OrganizationService) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs, $locale) {
            var params = _.get(scope,'$parent.$parent.a.params');
            function keyPressed(e) {

                var decimalSeparator = '.';
                if (OrganizationService.getOrganizationSnapshot().localeSpecificNumberFormat) {
                    decimalSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                }

                var restrict = false;
                var val = element.val() + e.key;
                if (!_.isNil(params.maxScale)) {
                    var regex = new RegExp('^(\\d+\\.?(\\d{1,' + params.maxScale + '})?)$');
                    restrict = !regex.test(val);
                }
                if (!_.isNil(params.maxPrecision)) {
                    var maxPrecision = val.indexOf(decimalSeparator) > -1 ? (params.maxPrecision + 1) : params.maxPrecision;
                    restrict = restrict || (val.length > maxPrecision);
                }
                if (restrict) {
                    e.preventDefault();
                }
            }
            if (!_.isNil(params)) {
                element.on('keypress paste', keyPressed);
            }
        }
    };
})
.directive('reviewsResizer', function($document) {
    return function($scope, $element, $attrs) {
        $element.on('mousedown', function(event) {
            event.preventDefault();
            $document.on('mousemove', mousemove);
            $document.on('mouseup', mouseup);
        });

        function mousemove(event) {
            var y = window.innerHeight - event.pageY - 36;
            if (y < 520) {
                $element.css({
                    bottom: y + 'px'
                });
                var t_elem = angular.element($attrs.resizerTarget);
                t_elem.css({
                    height: (y-62) + 'px'
                });
            }
        }

        function mouseup() {
            $document.unbind('mousemove', mousemove);
            $document.unbind('mouseup', mouseup);
        }
    };
})
.directive('dividerResizer', function($document, $timeout) {
    return function($scope, $element, $attrs) {
        $element.on('mousedown', function(event) {
            event.preventDefault();
            $document.on('mousemove', mousemove);
            $document.on('mouseup', mouseup);
        });

        var startPoint = 0;
        var widthInPer = 0;
        var content = angular.element($attrs.fullPannel);
        $timeout(function() {
            var width = content.width();
            widthInPer = 100/width;
        });

        function mousemove(event) {
            if (!startPoint) {
                startPoint = event.pageX;
            }
            var movement = event.pageX - startPoint;
            var t_elem = angular.element($attrs.leftPannel);
            var codes_elem = angular.element($attrs.rightPannel);
            t_elem.css({
                width: (25+(movement * widthInPer)) + '%'
            });
            codes_elem.css({
                width: (75-(movement * widthInPer)) + '%'
            });
        }

        function mouseup(event) {
            $document.unbind('mousemove', mousemove);
            $document.unbind('mouseup', mouseup);
        }
    };
})
.directive('laxImageInput', function($document, $window, CheckURLRessource, growl, $filter, $parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, elem, attrs, ctrl) {

            var $scope = scope.$parent.$parent;
            var a = $scope.attribute;
            var item = $scope.itemObj;
            var filePreviewData = $scope.filePreviewData;
            if (_.isNumber($scope.ecIndex)) {
                $scope.modelString = _.replace(scope.modelString, "$index", $scope.ecIndex);
            } else {
                $scope.modelString = checkModel(scope.modelString, a.name);
            }
            var modelEval = $parse($scope.modelString);
            var modelValue = modelEval($scope);
            var oldValue = angular.copy(modelValue);
            if (filePreviewData) {
                $scope.inputValue = $filter('fileName')(filePreviewData.url);
            }

            if (!scope.disableField) {
                scope.disableField = [];
            }
            scope.disableField[scope.$id] = modelEval($scope) ? true : false;

            function checkModel(model, attributeName) {
                if (_.isEqual("item[a.name]", model)) {
                    model = "itemObj['" + attributeName + "']";
                } else if (_.isEqual("customValues[a.name]", model)) {
                    model = "itemObj['" + attributeName + "']";
                } else if (!_.includes(model, "'")) {
                    model = model.replace(/\[/g, "['");
                    model = model.replace(/\]/g, "']");
                    model = model.replace(/\'\d+\'/g, function (x) {
                        return x.replace(/\'/g, "");
                    });
                    model = model.replace(/item/, "itemObj");
                }
                return model;
            }

            scope.enterURL = function (link) {
                //check if link is reachable
                if (!_.isEmpty(link)) {
                    CheckURLRessource.get({url: link},
                    function(result) {
                        scope.urlError = false;
                        if (!filePreviewData) {
                            filePreviewData = {};
                        }
                        var url = link;
                        var type = url.split('.').pop().split(/\?|#/).shift();
                        if(_.includes(url, "/image")){
                            type = 'image';
                        }
                        filePreviewData.url = url;
                        filePreviewData.isImage = false;
                        filePreviewData.path = url.slice(url.lastIndexOf('/'));
                        switch (type) {
                            case 'svg':
                                filePreviewData.contentType = "image/svg+xml";
                                if (!Modernizr.svg) {
                                    filePreviewData.notSupported = true;
                                }
                                break;
                            case 'webp':
                                filePreviewData.isImage = true;
                                filePreviewData.contentType = "image/webp";
                                if (Modernizr.webp.toString() !== 'true') {
                                    filePreviewData.notSupported = true;
                                }
                                break;
                            case 'tif':
                            case 'xlsx':
                            case 'xls':
                            case 'tiff':
                                filePreviewData.contentType = "image/tiff";
                                filePreviewData.notSupported = true;
                                break;
                            case 'pdf':
                                filePreviewData.contentType = "application/pdf";
                                break;
                            case "jpg":
                            case "jpeg":
                            case "gif":
                            case "png":
                            case "bmp":
                            case "tif":
                            case "tiff":
                            case "image":
                                filePreviewData.isImage = true;
                                filePreviewData.alt = $filter('fileName', filePreviewData.url);
                                break;
                            default:
                                filePreviewData.alt = $filter('fileName', filePreviewData.url);
                                break;
                        }
                        modelEval.assign($scope, filePreviewData.url);
                        scope.disableField[scope.$id] = true;
                        scope.filePreviewData = filePreviewData;
                        $scope.filePreviewData = filePreviewData;
                        scope.inputValue = $filter('fileName')(scope.filePreviewData.url);
                        oldValue = filePreviewData.url;
                        if (_.isNumber($scope.ecIndex)) {
                            $scope.inputValue = filePreviewData.url;
                            $scope.model = filePreviewData.url;
                        }
                        $scope.$broadcast('changedURL', filePreviewData);

                    }, function(error){
                        modelEval.assign($scope, link);
                        scope.urlError = true;
                        switch(error.status){
                            case 400:
                                // growl.error("INPUT.MUST_BE_A_VALID_URL_FORMAT");
                                break;
                            default:
                                // growl.error('UPLOAD.PROVIDE_LINK_ERROR');
                                break;
                        }
                        return;
                    });
                } else {
                    modelEval.assign($scope, null);
                    filePreviewData = null;
                    scope.filePreviewData = null;
                    $scope.filePreviewData = null;
                    $scope.$broadcast('clearAttributeValue', a.name);
                }
            };

            elem.on('keydown', function(event) {
                if (event.keyCode == 27) {
                    if (oldValue) {
                        event.preventDefault();
                        event.stopPropagation();
                        scope.inputValue = oldValue;
                        elem.blur();
                    } else {
                        elem.blur();
                    }

                }

                if (scope.disableField[scope.$id]) {
                    event.preventDefault();
                } else if (event.keyCode == 13) {
                    elem.blur();
                    event.preventDefault();
                }
            });

            elem.on('keyup', function (event) {
                if (!scope.inputValue) {
                    scope.urlError = false;
                }
            });

            elem.on('mousedown', function(event) {
                if (scope.disableField[scope.$id]) {
                    event.preventDefault();
                }
            });

            $scope.imageInputFocus = function() {
                if (scope.disableField[scope.$id] && !_.isNil(scope.filePreviewData)) {
                    scope.inputValue = scope.filePreviewData.url;
                    scope.disableField[scope.$id] = false;
                }
            };

            $scope.$on('clearAttributeValue', function (data) {
                if (scope.filePreviewData) {
                    scope.filePreviewData.url = undefined;
                }
                oldValue = undefined;
                scope.inputValue = undefined;
                scope.disableField[scope.$id] = false;
                scope.urlError = false;
            });

            $scope.$on('filesSelected', function(event, data) {
                if (!_.isNil(data)) {
                    var uploaderScopeId = scope.$parent.$parent.$id;
                    var emitterScopeId = data.scopeId;
                    var fileName = data.fileName;

                    // Should ignore the event if it's not meant for the current directive context
                    if (emitterScopeId === uploaderScopeId) {
                        scope.disableField[scope.$id] = true;
                        scope.urlError = false;
                        scope.inputValue = fileName;
                    }
                } else if (scope.$parent.$parent.uploader.queue.length == 1) {
                    scope.disableField[scope.$id] = true;
                    scope.urlError = false;
                    scope.inputValue = scope.$parent.$parent.uploader.queue[0]._file.name;
                }
                scope.filePreviewData = null;
            });

            $scope.$on('filesUploaded',function() {
                if (scope.$parent.$parent.filePreviewData) {
                    scope.filePreviewData = scope.$parent.$parent.filePreviewData;
                    scope.inputValue = $filter('fileName')(scope.filePreviewData.url);
                    oldValue = scope.filePreviewData.url;
                }
            });

            $scope.$on('uploadCanceled', function() {
                scope.filePreviewData = scope.$parent.$parent.filePreviewData;
                scope.inputValue = scope.filePreviewData ? $filter('fileName')(scope.filePreviewData.url) : undefined ;
            });

            $scope.imageInputClick = function () {
                elem.focus();
            };

            $scope.showFullUrl = function() {
                if (!_.isNil(scope.filePreviewData)) {
                    scope.inputValue = scope.filePreviewData.url ;
                }
            };

        }
    };
})
.directive('laxCheckUrl', function(CheckURLRessource) {
    return {
        strict: 'A',
        scope: {
            url: '=',
            row: '=',
            col: '='
        },
        link: function(scope) {
            function checkUrl(url) {

                if (_.isEmpty(url)) {
                    return;
                }

                if (!_.isNil(scope.row) && _.isNil(scope.row.filePreviewError)) {
                    scope.row.filePreviewError = {};
                }

                CheckURLRessource.get({url: url},
                    function() {
                        if (!_.isNil(scope.row) && !_.isNil(scope.col) && !_.isNil(scope.row.filePreviewError)) {
                            scope.row.filePreviewError[scope.col.field] = false;
                        }

                        var $parentScope = scope.$parent;
                        if(!_.isNil($parentScope.filePreviewData)) {
                            $parentScope.filePreviewError = false;
                        }
                    }, function() {
                        if (!_.isNil(scope.row) && !_.isNil(scope.col) && !_.isNil(scope.row.filePreviewError)) {
                            scope.row.filePreviewError[scope.col.field] = true;
                        }

                        var $parentScope = scope.$parent;
                        if(!_.isNil($parentScope.filePreviewData)) {
                            $parentScope.filePreviewError = true;
                        }
                    });
            }

            scope.$watch('url', function(url) {
                checkUrl(url);
            });

            if (!_.isNil(scope.row)) {
                scope.$watch('row', function() {
                    checkUrl(scope.url);
                });
            }
        }
    };
})
.directive('progressbarCustom', function() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        controller: ['$scope', '$attrs', 'progressConfig', function($scope, $attrs, progressConfig) {
            var self = this, animate = angular.isDefined($attrs.animate) ? $scope.$parent.$eval($attrs.animate) : progressConfig.animate;

            this.bars = [];
            $scope.max = angular.isDefined($attrs.max) ? $scope.$parent.$eval($attrs.max) : progressConfig.max;

            this.addBar = function(bar, element) {
                if ( !animate ) {
                    element.css({'transition': 'none'});
                }

                this.bars.push(bar);

                bar.$watch('value', function( value ) {
                    bar.percent = +(100 * value / $scope.max).toFixed(2);
                });

                bar.$watch('max', function( max ) {
                    bar.percent = +(100 * $scope.value / max).toFixed(2);
                });

                bar.$on('$destroy', function() {
                    element = null;
                    self.removeBar(bar);
                });
            };

            this.removeBar = function(bar) {
                this.bars.splice(this.bars.indexOf(bar), 1);
            };
        }],
        scope: {
            max: '=',
            value: '=',
            type: '@'
        },
        templateUrl: 'template/progressbar/progressbar.html',
        link: function(scope, element, attrs, progressCtrl) {
            progressCtrl.addBar(scope, angular.element(element.children()[0]));
        }
    };
})
.directive('framePreview', function($compile) {
    return function($scope, $element) {
      var $body = angular.element($element[0].contentDocument.body),
          template = $compile('<div data-ng-bind-html="contentHtml"></div>')($scope);
      $body.append(template);
    };
})
.directive('laxUiSelectCloseOnTab', function() {
    return {
        require: 'uiSelect',
        link: function(scope, element, attrs, $select) {
            var searchInput = element.querySelectorAll('input.ui-select-search');

            searchInput.on('keydown', {select: $select}, function(event) {
                if(event.keyCode == 9) {
                    event.data.select.close();
                    event.data.select.setFocus();
                }
            });
        }
    };
})
.directive('autofocus', function($timeout){
    return {
        link : function(scope, element) {
            $timeout(function() {
                element[0].focus();
            }, 0);
        }
    };
})
.directive('uiGridBrowserTooltips', function($timeout){
    return {
        priority: -99999, // this needs to run very last
        require: ['^uiGrid'],
        scope: false,
        compile : function() {
            return {
                pre: function() { },
                post: function($scope, $element, $attrs, controllers) {
                    var uiGridCtrl = controllers[0];
                    var grid = uiGridCtrl.grid;

                    function setupBrowserTooltips() {
                        $timeout(function() {
                            $('.ui-grid-cell-contents').each(function() {
                                // Only add tooltip for non-action cells
                                if($(this).find('.actions').length === 0) {
                                    this.title = this.innerText.trim();
                                }
                            });
                        }, 1000);
                    }

                    // Register to the following events on top of what is already registered.
                    grid.api.core.on.scrollEnd($scope, setupBrowserTooltips);
                    grid.api.core.on.rowsRendered( $scope, setupBrowserTooltips);
                }
            };
        }
    };
})
.directive('contextMenu', function() {
    return {
        restrict: 'A',
        scope: {
            onContextMenuOpen: '&'
        },
        compile: function($element, $attributes) {
            return {
                post: function(scope, element, attributes, controller) {
                    var ul = $('#' + attributes.contextMenuTarget),
                        last = null;

                    ul.css({
                        'display': 'none'
                    });

                    $(element).bind('contextmenu', function(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        $('.context-menu').css('display', 'none');
                        var parentModalElement = $(element).closest('.modal-dialog');

                        if (parentModalElement.length > 0) {
                            ul.css({
                                position: "fixed",
                                display: "block",
                                left: event.clientX - parentModalElement.offset().left + 'px',
                                top: event.clientY - parentModalElement.offset().top + 'px'
                            });
                        } else {
                            ul.css({
                                position: "fixed",
                                display: "block",
                                left: event.clientX + 'px',
                                top: event.clientY + 'px'
                            });
                        }
                        last = event.timeStamp;

                        if(!_.isNil(scope.onContextMenuOpen)) {
                            scope.onContextMenuOpen();
                        }
                    });

                    $(document).click(function(event) {
                        var target = $(event.target);
                        if (!target.is(".popover") && !target.parents().is(".popover")) {
                            if (last === event.timeStamp) {
                                return;
                            }
                            ul.css({
                                'display': 'none'
                            });
                            $('.context-menu').css('display', 'none');
                        }
                    });
                }
            };
        }
    };
})
.factory('RecursiveDirectiveHelper', function($compile){
    return {
        /**
         * FIXME: Angular +v5.x supports this natively, but only with `template` not `templateUrl`.
         * Manually compiles the element, fixing recursion loops of the same directive.
         * For more info check -> https://stackoverflow.com/a/18609594/4653326
         * @param element
         * @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
         * @returns An object containing the linking functions.
         */
        compile: function(element, link){
            // Normalize the link parameter
            if(angular.isFunction(link)){
                link = { post: link };
            }

            // Break the recursion loop by removing the contents
            var contents = element.contents().remove();
            var compiledContents;
            return {
                pre: (link && link.pre) ? link.pre : null,
                /**
                 * Compiles and re-adds the contents
                 */
                post: function(scope, element){
                    // Compile the contents
                    if(!compiledContents){
                        compiledContents = $compile(contents);
                    }
                    // Re-add the compiled contents to the element
                    compiledContents(scope, function(clone){
                        element.append(clone);
                    });

                    // Call the post-linking function, if any
                    if(link && link.post){
                        link.post.apply(null, arguments);
                    }
                }
            };
        }
    };
})
.directive('flexResizer', function() {
    /**
     * A resizing component horizontally and vertically of two flex-based components.
     * Adapted from https://codesandbox.io/s/sad-butterfly-1fwo4
     * Usage:
     *    <element-1 class="flex" style="flex-grow: 1"/>
     *    <data-flex-resizer data-axis="x" data-min-grow="0.5" data-max-grow="1.5" class="flex justify-center items-center flex-resizer-x">
     *    </data-flex-resizer>
     *    <element-2 class="flex" style="flex-grow: 3" />
     * Note: Inline flex grow styles should be used for cross compatibility.
     */
    return {
        strict: 'E',
        scope: {
            axis: '@',
            maxGrow: '@',
            minGrow: '@'
        },
        template: '<div></div><i class="syncons syncons-minus"></i>',
        link: function($scope) {
            var AXIS = $scope.axis;
            var MAX_GROW = Number($scope.maxGrow);
            var MIN_GROW = Number($scope.minGrow);
            var TAG_NAME = 'DATA-FLEX-RESIZER';

            function manageResize(md, target, sizeProp, posProp) {
                var r = target;

                var prev = r.previousElementSibling;
                var next = r.nextElementSibling;
                if (!prev || !next) {
                    return;
                }

                md.preventDefault();

                var prevSize = prev[sizeProp];
                var nextSize = next[sizeProp];
                var sumSize = prevSize + nextSize;
                var prevGrow = Number(prev.style.flexGrow);
                var nextGrow = Number(next.style.flexGrow);
                var sumGrow = prevGrow + nextGrow;
                var lastPos = md[posProp];

                function onMouseMove(mm) {
                    var pos = mm[posProp];
                    var d = pos - lastPos;
                    prevSize += d;
                    nextSize -= d;
                    if (prevSize < 0) {
                        nextSize += prevSize;
                        pos -= prevSize;
                        prevSize = 0;
                    }
                    if (nextSize < 0) {
                        prevSize += nextSize;
                        pos += nextSize;
                        nextSize = 0;
                    }

                    var prevGrowNew = sumGrow * (prevSize / sumSize);
                    var nextGrowNew = sumGrow * (nextSize / sumSize);

                    if (prevGrowNew > MAX_GROW || prevGrowNew < MIN_GROW) {
                        return;
                    }

                    prev.style.flexGrow = prevGrowNew;
                    next.style.flexGrow = nextGrowNew;
                    lastPos = pos;
                }

                function onMouseUp(mu) {
                    // Change cursor to signal a state's change: stop resizing.
                    var html = document.querySelector('html');
                    html.style.cursor = 'default';

                    if (posProp === 'pageX') {
                        r.style.cursor = 'ew-resize';
                    } else {
                        r.style.cursor = 'ns-resize';
                    }

                    window.removeEventListener('mousemove', onMouseMove);
                    window.removeEventListener('mouseup', onMouseUp);
                }

                window.addEventListener('mousemove', onMouseMove);
                window.addEventListener('mouseup', onMouseUp);
            }

            document.body.addEventListener('mousedown', function (md) {

                // Used to avoid cursor's flickering
                var html = document.querySelector('html');

                var target = md.target;
                if (target.nodeType !== 1) {
                    return;
                }

                if (target.parentNode.tagName === TAG_NAME) {
                    target = target.parentNode;
                } else if (target.tagName !== TAG_NAME) {
                    return;
                }

                if (_.isEmpty(AXIS)) {
                    return;
                } else if (AXIS === 'x') {
                    // Change cursor to signal a state's change: begin resizing on H.
                    target.style.cursor = 'col-resize';
                    html.style.cursor = 'col-resize'; // avoid cursor's flickering

                    // use offsetWidth versus scrollWidth to avoid splitter's jump on resize when content overflow.
                    manageResize(md, target, 'offsetWidth','pageX');

                } else if (AXIS === 'y') {
                    // Change cursor to signal a state's change: begin resizing on V.
                    target.style.cursor = 'row-resize';
                    html.style.cursor = 'row-resize'; // avoid cursor's flickering

                    manageResize(md, target, 'offsetHeight', 'pageY');
                }
            });
        }
    };
})
.directive('dmDeploymentInProgress', function() {
    return {
        restrict: 'E',
        scope: { },
        templateUrl: 'tpl/datamodel-deployment-in-progress.tpl.html',
        controller: function() { }
    };
})
.directive('widgetTranslatableTitle', function() {
    return {
        restrict: 'A',
        controller: function($scope, $translate) {
            $scope.title = $translate.instant($scope.widget.title);

            $scope.$watch('widget.title', function() {
                $scope.title = $translate.instant($scope.widget.title);
            });
        }
    };
});
